import gtmMappings from './gtmMappings';
const getGTMdataObjects = (tabName, question, value, extraData) => {
  const matchedRows = gtmMappings.filter(
    (mapping) => mapping[0] === tabName
      && mapping[1] === question
      && (mapping[2] === value
        || mapping[2] === '*'
        || (mapping[2].toString().startsWith('*') && mapping[2].toString() !== `*!${value}`)),
  );
  matchedRows.forEach((row) => {
    let formattedValue = value;
    let dataObj = row[3];

   if(extraData)
    {
      dataObj = { ...dataObj, sha256_email_address:extraData.email,
        sha256_phone_number:extraData.phoneNumber,sha256_first_name:extraData.firstName,sha256_last_name:extraData.lastName,sha_256_postal_code:extraData.postCode}  
    }
   
    if (dataObj.event === 'PQCarDetails-CarFactoryOptions') {
      formattedValue = value
        .filter(({ selected }) => selected)
        .map(({ optionName }) => optionName)
        .join(', ');
    } else if (Array.isArray(value)) {
      formattedValue = value.join(', ');
    } else if (
      dataObj.eventLabel === '~selected~name~'
      && value
      && typeof value === 'object'
      && value.name
    ) {
      // eslint-disable-next-line no-nested-ternary
      formattedValue = value.name;
      dataObj.eventLabel = dataObj?.eventLabel?.replace('~selected~name~', formattedValue);
    }
    else if (value && typeof value === 'object' && value.value) {
      formattedValue = value.value;
    } else if (typeof value === 'boolean') {
      formattedValue = value === true ? 'Yes' : 'No';
    }
    dataObj.eventLabel = (value && !isNaN(value))
      ? value
      : (dataObj?.eventLabel?.replace('~selected~value~', formattedValue));
    window.dataLayer.push(dataObj);
  });
};
 const processGTMevent = (tabName, question, value,extraData=undefined) => {
  // calling this with a timeout to prevenet this one delaying the render
  setTimeout(() => {
    try {
      getGTMdataObjects(tabName, question, value, extraData);
    } catch (e) {
      console.error(e);
    }
  }, 100);
};

export default processGTMevent;
