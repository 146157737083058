import {
  LIABILITY_MORTGAGE_OPTION,
  LIABILITY_MORTGAGE_INVEST_OPTION,
  LIABILITY_VEHICAL_LOAN_OPTION,
  LIABILITY_ASSET_LOAN_OPTION,
  LIABILITY_CREDIT_CARD_OPTION,
  LIABILITY_OVERDRAFT_OPTION,
  LIABILITY_DEBT_AGREEMENT_OPTION,
  LIABILITY_TAX_DEBT_OPTION,
  LIABILITY_PERSONAL_LOAN_OPTION,
} from './constants';

const FINANCIER = [
  {
    BankName: 'AMERICAN EXPRESS',
    Mortgage: 'no',
    CreditCard: 'yes',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'AMP BANKING',
    Mortgage: 'no',
    CreditCard: 'yes',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'ANZ',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'BANK OF SOUTH AUSTRALIA',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'BANK OF WESTERN AUSTRALIA',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'BENDIGO BANK',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'BEYOND BANK AUSTRALIA',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'BMW AUSTRALIA FINANCE',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'BANK OF QUEENSLAND',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'CAPITAL FINANCE',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'CASH CONVERTERS',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'COMMONWEALTH BANK OF AUSTRALIA',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'CITIBANK',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'COMMUNITY FIRST CREDIT-UNION',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'CREDIT CORP FINANCIAL SERVICES',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'CREDIT UNION AUSTRALIA',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'DEFENCE BANK LTD',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'DOLLARS DIRECT',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'FAIR GO FINANCE P/L',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'FLECreditCard:IRENT',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'CapFin Direct',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'GE FINANCIAL SERVICES',
    Mortgage: 'no',
    CreditCard: 'yes',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'GENWORTH FINANCIAL',
    Mortgage: 'yes',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'GREATER BLDG SOC NSW',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'HERITAGE BANK',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'HOLDEN FINANCIAL SERVICES',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'HONDA FINANCIAL SERVICES',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'HSBC',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'IMB LENDING SERVICES',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'ING DIRECT',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'KEYSTART LOANS LTD WA',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'KWIK FINANCE PL',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'LATITUDE FINANCE',
    Mortgage: 'no',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'LIBERTY FINANCIAL',
    Mortgage: 'yes',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'LOMBARD FINANCE',
    Mortgage: 'no',
    CreditCard: 'yes',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'MACQUARIE GROUP LIMITED',
    Mortgage: 'yes',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'MCB FINANCE AUSTRALIA PTY LT',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'ME BANK',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'MECU LTD',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'MEMBERS EQUITY',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'MERCEDES-BENZ FINANCIAL SERVICES',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'MONEY 3 CORPORATION',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'MONEYME',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'yes',
  },
  {
    BankName: 'NAB',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'NEWCASTLE PERMANENT BUILDING SOCIETY',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'NISSAN FININANCIAL SERVICES',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'ONCE CREDIT',
    Mortgage: 'no',
    CreditCard: 'yes',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'PEPPER FINANCIAL SERVICES GROUP',
    Mortgage: 'yes',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'QBE LENDERS MORTGAGE INSURANCE',
    Mortgage: 'yes',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'RACV FINANCE',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'RAMS DIRECT',
    Mortgage: 'yes',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'SAFROCK FINANCE CORP (QLD)',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'ST GEORGE BANK',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'SUNCORP',
    Mortgage: 'yes',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'TEACHERS MUTUAL BANK',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'THINKSMART WA',
    Mortgage: 'yes',
    CreditCard: 'no',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'THORN AUSTRALIA PTY LTD.',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'TOYOTA FIN AUSTRALIA LTD*',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'VIRGIN MOBILE',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'VODAFONE DECISION POINT',
    Mortgage: 'no',
    CreditCard: 'yes',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'VOLKSWAGEN FINANCIAL SERVICES AUSTRALIA',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'WALKER STORES',
    Mortgage: 'no',
    CreditCard: 'yes',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'WESTPAC',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'WINGATE FINANCE',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    BankName: 'YAMAHA MOTOR FINANCE AUST PL',
    Mortgage: 'no',
    CreditCard: 'no',
    Secured: 'yes',
    Unsecured: 'no',
  },
  {
    BankName: 'ZIPMONEY P/L',
    Mortgage: 'no',
    CreditCard: 'yes',
    Secured: 'no',
    Unsecured: 'no',
  },
  {
    BankName: 'OTHER',
    Mortgage: 'yes',
    CreditCard: 'yes',
    Secured: 'yes',
    Unsecured: 'yes',
  },
  {
    taxApplicable: 'yes',
    BankName: 'ATO (Australian Taxation Office)',
  },
];

const getFinanceOptions = (liability) =>
  FINANCIER.reduce((accum, option) => {
    let selected = false;
    let taxDebtOption = false;
    switch (liability) {
      case LIABILITY_MORTGAGE_OPTION:
      case LIABILITY_MORTGAGE_INVEST_OPTION:
        if (option.Mortgage === 'yes') {
          selected = true;
        }
        break;
      case LIABILITY_VEHICAL_LOAN_OPTION:
      case LIABILITY_ASSET_LOAN_OPTION:
        if (option.Secured === 'yes') {
          selected = true;
        }
        break;
      case LIABILITY_CREDIT_CARD_OPTION:
      case LIABILITY_OVERDRAFT_OPTION:
      case LIABILITY_DEBT_AGREEMENT_OPTION:
        if (option.CreditCard === 'yes') {
          selected = true;
        }
        break;
      case LIABILITY_PERSONAL_LOAN_OPTION:
        if (option.Unsecured === 'yes') {
          selected = true;
        }
        break;
      case LIABILITY_TAX_DEBT_OPTION:
        if (option.taxApplicable === 'yes') {
          selected = true;
        }

        break;
      default:
        selected = false;
    }

    if (selected) {
      accum.push({
        ...option,
        name: option.BankName,
        value: option.BankName,
      });
    }
    return accum;
  }, []);

export default getFinanceOptions;
