/* eslint-disable react/prop-types */
import React from 'react';
import Form from 'react-bootstrap/Form';

const DropDownSelect = ({
  label,
  fieldName,
  value = '',
  onChange,
  options,
  required,
  errorMsg,
  colxs,
  colsm,
  colmd,
  className = '',
  isDisable
}) => {
  return (
    <Form.Group xs={colxs} sm={colsm} md={colmd} className={className}>
      <Form.Label htmlFor={fieldName}>
        {label}
        <span className="mandatory">{required}</span>
      </Form.Label>
      <Form.Control
        as="select"
        id={fieldName}
        onChange={(ev) => {
          onChange(ev.target.value);
        }}
        onBlur={(ev) => {
          onChange(ev.target.value);
        }}
        name={fieldName}
        value={value || ''}
        isInvalid={errorMsg && errorMsg.length}
        disabled={isDisable}
      >
        {options}
      </Form.Control>
      <Form.Control.Feedback key={fieldName} type="invalid">
        {errorMsg}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default DropDownSelect;
