import { connect } from 'react-redux';
import EmploymentDetails from './EmploymentDetails';
import fieldChanged, { updateValidationErrors } from '../../Actions/FieldChanged';
import { setCompleteTab, setCurrentTab } from '../../Actions/tab';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import { APPLICANT_TYPE } from '../../util/constants';
import { employmentDetailsUpdateVer2, clearAddress, RetrieveOpportunity } from '../../Actions/car';
import { deletePrevEmployment } from '../../Actions/applicants';
import { setApplicationStrucutureStepper } from '../../Actions/stepperDetails';
import { coApplicantIncome, coApplicantIncomeEmp } from '../../Actions/finances';

const CURR_EMPLOYEE_FIELD = 'currEmployments';
const PREV_EMPLOYEE_FIELD = 'prevEmployments';

const mapStateToProps = (state, ownProps) => {
  const { applicantType } = ownProps.match.params || {};
  const isPrimaryApplicant = !applicantType || applicantType === APPLICANT_TYPE.primary;
  const fieldSuffix = isPrimaryApplicant ? '' : `_${applicantType}`;
  return {
    applicantType,
    isPrimaryApplicant,
    fieldSuffix,
    applicantValidationErrors: state.applicants.validationErrors,
    getValidationErrorsForField: getValidationErrorsForFieldFunc(state.applicants.validationErrors),
    applicationStructure: state.applicants.applicationStructure,
    selectedLender: state.applicants.selectedLender,
    manualEnter: state.applicants.manualEnter,
    unitNumber: state.applicants[`unitNumber${fieldSuffix}`],
    streetNumber: state.applicants[`streetNumber${fieldSuffix}`],
    streetName: state.applicants[`streetName${fieldSuffix}`],
    suburb: state.applicants[`suburb${fieldSuffix}`],
    postcode: state.applicants[`postcode${fieldSuffix}`],
    state: state.applicants[`state${fieldSuffix}`],
    EmploymentType: state.applicants[`EmploymentType${fieldSuffix}`],
    employerName: state.applicants[`employerName${fieldSuffix}`],
    employerABN: state.applicants[`employerABN${fieldSuffix}`],
    industry: state.applicants[`industry${fieldSuffix}`],
    occupation: state.applicants[`occupation${fieldSuffix}`],
    employmentStartDate: state.applicants[`employmentStartDate${fieldSuffix}`],
    firstName: state.applicants[`firstName${fieldSuffix}`],
    lastName: state.applicants[`lastName${fieldSuffix}`],
    mobileNumber: state.applicants[`mobileNumber${fieldSuffix}`],
    emailAddress: state.applicants[`emailAddress${fieldSuffix}`],
    employerAddress: state.applicants[`employerAddress${fieldSuffix}`],
    mainEmployment: state.applicants[`mainEmployment${fieldSuffix}`],
    addEmployment: state.applicants[`addEmployment${fieldSuffix}`],
    currentEmpId: state.applicants[`currentEmpId${fieldSuffix}`],
    gstRegistrationdate: state.applicants[`gstRegistrationdate${fieldSuffix}`],
    gstRegistrationstatus: state.applicants[`gstRegistrationstatus${fieldSuffix}`],
    abnNumber: state.applicants[`abnNumber${fieldSuffix}`],
    abnRegistrationDate: state.applicants[`abnRegistrationDate${fieldSuffix}`],
    abnState: state.applicants[`abnState${fieldSuffix}`],
    entityType: state.applicants[`entityType${fieldSuffix}`],
    entityName: state.applicants[`entityName${fieldSuffix}`],
    abnStatus: state.applicants[`abnStatus${fieldSuffix}`],
    acnNumber: state.applicants[`acnNumber${fieldSuffix}`],
    searchAbn: state.applicants[`searchAbn${fieldSuffix}`],
    quoteInProgress: state.applicants[`quoteInProgress${fieldSuffix}`],
    [CURR_EMPLOYEE_FIELD]: state.applicants[`${CURR_EMPLOYEE_FIELD}${fieldSuffix}`] || [],
    [PREV_EMPLOYEE_FIELD]: state.applicants[`${PREV_EMPLOYEE_FIELD}${fieldSuffix}`] || [],
    purchaseUse: state.car.purchaseUse,
    isAbnknown: state.applicants.isAbnknown,
    contactDetails: state.applicants.contactDetails,
    opportunityId: state.applicants.opportunityId,
    partnerIncome: state.applicants.spouseincome || state.applicants.coapplicantincome,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(fieldChanged('APPLICANTS', fieldName, value, scrollToNextFieldOnChage));
  },
  validateDateField: (fieldName, errors) =>
    dispatch(updateValidationErrors('APPLICANTS', fieldName, errors)),
  setTabComplete: (tabName) => {
    dispatch(setCompleteTab(tabName));
  },
  setPrevEmployment: (fieldSuffix, value) => {
    dispatch(fieldChanged('applicants', `${PREV_EMPLOYEE_FIELD}${fieldSuffix}`, value, false));
  },
  setCurrEmployment: (fieldSuffix, value) => {
    dispatch(fieldChanged('applicants', `${CURR_EMPLOYEE_FIELD}${fieldSuffix}`, value, false));
  },
  setTab: () => dispatch(setCurrentTab('employment_details')),
  retrieveOpportunity: () => dispatch(RetrieveOpportunity()),
  spouseIncomeApi: (incomeData) => dispatch(coApplicantIncomeEmp(incomeData)),
  clearAddress: () => dispatch(clearAddress()),
  deletePrevEmployment: (prevEmpId) => dispatch(deletePrevEmployment(prevEmpId)),
  employmentDetailsUpdate: (applicantType) =>
    dispatch(employmentDetailsUpdateVer2(true, applicantType)),
  setApplicationStrucutureStepper: (applicationStructure) =>
    dispatch(setApplicationStrucutureStepper(applicationStructure)),
});

const EmploymentDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(EmploymentDetails);

export default EmploymentDetailsContainer;
