import moment from 'moment';
import { MONTH_OPTION, FORTNIGHT_OPTION } from '../util/constants';

export const amountPerMonth = (amount, freq) => {
  if (freq === MONTH_OPTION) return amount;
  const weeks = freq === FORTNIGHT_OPTION ? 26 : 52;

  return amount && Number.parseFloat((Number(amount) * weeks) / 12).toFixed(2);
};

export const formatNumberValue = (value) => (!Number.isNaN(Number(value)) ? Number(value) : 0);

export const formatAmount = (input) => {
  if (!input) return '';

  const formattedNumber = input.replace(/[^0-9.]/g, '');
  if (!formattedNumber) return '';

  // Convert to currency format
  const convertedInput = new Intl.NumberFormat('en-AU', { maximumFractionDigits: 2 }).format(
    formattedNumber,
  );
  return convertedInput;
};

export const normalizeAmount = (val) => String(val).replace(/,/g, '');

export const formatRepaymentValue = (value) =>
  !Number.isNaN(Number(value))
    ? `$${new Intl.NumberFormat('en-AU', { maximumFractionDigits: 0 }).format(value)}`
    : 0;

export const calculateDuration = (date1, date2) => {
  const a = moment(date1);
  const b = moment(date2);

  const years = a.diff(b, 'year');
  b.add(years, 'years');

  const months = a.diff(b, 'months');
  b.add(months, 'months');

  return `${years} years ${months} months `;
};
