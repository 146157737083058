import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import { useMediaQuery, useTheme } from '@material-ui/core';
import downArrow from '../../../assets/image/down-arrow-expand.svg';
import upArrow from '../../../assets/image/up-arrow-expand.svg';

const DescriptionTextPartner = ({ partnerItem }) => {
    const [checked, setChecked] = React.useState(true);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(768));
    const toggleCollapseView = () => {
        setChecked((prvState) => { return !prvState })
    }

    return (<>
        {mobileView ?
            <Collapse in={checked} collapsedSize={32}>
                <>
                    <div className='partner-desc-header' onClick={toggleCollapseView}>Comparsion Rate Information  <img className='icon-expand' src={checked ? downArrow : upArrow} alt="arw-down" /></div>
                    <div className='partner-desc-content'>
                        {partnerItem?.description}<span className='warning-text'>{' WARNING: '}</span>{partnerItem?.descrptionMore}
                    </div>
                </>
            </Collapse> :
            <div className='partner-desc-content'>
                {partnerItem?.description}<span className='warning-text'>{' WARNING: '}</span>{partnerItem?.descrptionMore}
            </div>}

    </>)

}

export default DescriptionTextPartner;



