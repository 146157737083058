import validateField from '../util/ValidateField';
import scrollToNextField from '../util/ScrollToNextField';
import processGTMevent from '../util/processGTMEvent';

const changeValue = (tabName, fieldName, value) => ({
  type: `CHANGE_VALUE_${tabName.toUpperCase()}`,
  value,
  fieldName,
});

const liteChangeValue = (tabName, fieldName, value) => ({
  type: `LITE_CHANGE_VALUE_${tabName.toUpperCase()}`,
  value,
  fieldName,
});

export const updateValidationErrors = (tabName, fieldName, errors) => ({
  type: `UPDATE_VALIDATION_ERRORS_FOR_FIELD_${tabName}`,
  fieldName,
  errors,
});

export const liteFieldChanged = (tabName, fieldName, value) => async (dispatch) => {
  const tabNameFormatted = tabName.toUpperCase();
  processGTMevent(tabName.toLowerCase(), fieldName, value);
  dispatch(liteChangeValue(tabNameFormatted, fieldName, value));
  return Promise.resolve();
};

const fieldChanged = (
  tabName,
  fieldName,
  value,
  scrollToNextFieldOnChage,
  ignoreHeaderOffset,
) => async (dispatch, getState) => {
  const tabNameFormatted = tabName.toUpperCase();

  const state = getState();
  const { assetType } = state.car;

  const currentStateSlice = state[tabName.toLowerCase()];

  const sharedMapping = currentStateSlice.sharedFields.find(
    (mapping) => mapping.fieldName === fieldName,
  );

  if (sharedMapping) {
    dispatch(changeValue(sharedMapping.tabName.toUpperCase(), fieldName, value));
  } else {
    dispatch(changeValue(tabNameFormatted, fieldName, value));
  }

  const rulesObject = currentStateSlice.validationRules.find(
    (rule) => rule.fieldName === fieldName,
  );

  const rules = rulesObject ? rulesObject.rules : null;

  const errors = validateField(fieldName, value, rules);

  dispatch(updateValidationErrors(tabNameFormatted, fieldName, errors));

  if ((!errors || errors.length < 1) && scrollToNextFieldOnChage) {
    await Promise.resolve(
      setTimeout(() => {
        const nextState = getState();
        const nextStateSlice = nextState[tabName.toLowerCase()];
        const fieldToFocus = nextStateSlice.nextFieldNameToFocus;
        scrollToNextField(fieldToFocus, ignoreHeaderOffset);
      }, 100),
    );
  }
  if (!errors || errors.length < 1) {
    processGTMevent(assetType, fieldName, value);
  }
  return Promise.resolve(true);
};

export default fieldChanged;
