/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import capitalize from 'lodash/capitalize';
import cls from 'classnames';
import Skeleton from '@material-ui/lab/Skeleton';

import FactoryOption from '../components/FactoryOption';

import './pre-populate.scss';

/* const factoryOptionFields = [
  { name: 'Safety Pack', label: 'safetyPack', selected: true },
  { name: 'Sunroof', label: 'sunroof', selected: true },
  { name: 'HeadLight Protector', label: 'headlightProtector', selected: false },
  { name: 'Paint Protection', label: 'paintProtection', selected: false },
  { name: 'Leather Seats', label: 'leatherSeats', selected: false },
]; */
const PrePopulateCarDetails = ({
  onClear,
  onManualEnter,
  transmission,
  factoryOptions,
  vehicleEngine,
  manufacturingYear,
  vehicleMake,
  vehicleModel,
  vehicleSeries,
  vehicleBodyType,
  vehicleVariant,
  carCondition,
  nvicMake,
  nvicModel,
  nvicVariant,
  nvicSeries,
  onInputChange,
  isLoading,
  showFactoryOptions = true,
}) => {
  const updateSelectedOption = (selectedOptions) => {
    onInputChange('factoryOptions', selectedOptions, false);
  };

  const unCheckSelectedOption = (uncheckOption) => {
    const updatedOptions = factoryOptions.map((option) => ({
      ...option,
      selected: option.optionName === uncheckOption.optionName ? false : option.selected,
    }));
    updateSelectedOption(updatedOptions);
  };

  // eslint-disable-next-line no-unused-vars
  const clearSearch = () => {
    onClear(false);
  };
  const manualEnter = () => {
    onManualEnter(true);
  };
  return (
    <>
      <div className={cls('car-grid mb-2 p-3', isLoading && 'car-loading')}>
        {/*  <div className="car-image py-3 px-3">
        <img src={carmodel} width="100" className="img-vehicle" />
      </div> */}
        {isLoading && (
          <div className="skeleton-pulse-container">
            <Skeleton variant="rect" animation="pulse" className="skeleton-pulse-placeholder" />
            <Skeleton variant="rect" animation="pulse" className="skeleton-pulse-placeholder" />
            <Skeleton variant="rect" animation="pulse" className="skeleton-pulse-placeholder" />
          </div>
        )}
        {!isLoading && (
          <div className="car-details px-3 py-3">
            <div className="car-details1 pb-3">
              {`${manufacturingYear}
                ${nvicMake || capitalize(vehicleMake.name)}
                ${nvicModel || capitalize(vehicleModel.name)} ${'-'}
                ${nvicSeries || capitalize(vehicleVariant.name)} ${' '} ${nvicVariant ||
                capitalize(vehicleSeries.name)}`}
            </div>
            <div className=" car-details2 pb-3">{vehicleEngine || ''}</div>
            <div className=" car-details2 pb-3">
              {`${vehicleBodyType || ''} ${' '} ${transmission || ''} `}
            </div>
          </div>
        )}
        <div className="car-list-val ">
          <ul className="px-3 py-2">
            {factoryOptions &&
              factoryOptions.map(
                (option) =>
                  option.selected && (
                    <li className="chip" key={option.optionName}>
                      {option.optionName}
                      <button
                        data-test="delete-factory-options"
                        type="button"
                        className="close"
                        onClick={() => {
                          unCheckSelectedOption(option);
                        }}
                      >
                        &times;
                      </button>
                    </li>
                  ),
              )}
          </ul>
        </div>
      </div>
      <div className="factory-options-manual">
        {!isLoading && carCondition === 'Used' && (
          <div className="car-footer py-2 px-2">
            Not the vehicle you’re looking for? &nbsp;
            <button
              type="button"
              data-test="entermanually-used"
              className="px-1 btn btn-link manual-enter"
              onClick={manualEnter}
            >
              Enter manually
            </button>
          </div>
        )}
        {!isLoading && showFactoryOptions && (
          <div className="factory-option py-2">
            <FactoryOption fields={factoryOptions} onSave={updateSelectedOption} />
          </div>
        )}
      </div>
    </>
  );
};

export default PrePopulateCarDetails;
