import validateTab from '../util/ValidateTab';
import scrollToField from '../util/ScrollToField';

export const updateValidationErrorsTab = (tabName, errors) => ({
  type: `UPDATE_VALIDATION_ERRORS_FOR_TAB_${tabName}`,
  errors,
});

export const navigateToNextTab = (tabName) => ({
  type: `NAVIGATE_TO_NEXT_TAB_${tabName}`,
});

export const enableTab = (tabName) => ({
  type: 'ENABLE_TAB_META',
  tabName,
});

export const resetNavigateFlag = (tabName) => ({
  type: `RESET_NAVIGATE_TAB_${tabName}`,
});

export const navigateAndEnableNextTab = (tabName) => (dispatch, getState) => {
  const tabNameFormatted = tabName.toUpperCase();

  dispatch(navigateToNextTab(tabNameFormatted));

  setTimeout(() => dispatch(resetNavigateFlag(tabNameFormatted)), 1000);

  const { navigateTo } = getState()[tabName.toLowerCase()];

  dispatch(enableTab(navigateTo));
};

export function nextClicked(tabName) {
  return (dispatch, getState) => {
    const tabNameFormatted = tabName.toUpperCase();

    const errors = validateTab(getState(), tabNameFormatted);

    dispatch(updateValidationErrorsTab(tabNameFormatted, errors));

    if (errors.length < 1) {
      dispatch(navigateAndEnableNextTab(tabNameFormatted));

      scrollToField('toTop');
    } else {
      scrollToField(errors[0].fieldName);
    }
  };
}

export const isTabValid = (tabName, getState) => {
  const tabState = getState()[tabName.toLowerCase()];
  return tabState.validationErrors.length < 1;
};
