/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import capitalize from 'lodash/capitalize';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import cls from 'classnames';
import { CircularProgress } from '@material-ui/core';
import Alert from 'react-bootstrap/Alert';
import FormGroup from 'react-bootstrap/FormGroup';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import processGTMevent from '../../util/processGTMEvent';
import '../../components/input-field.scss';
import InputButton from '../../components/InputButton';
import TextField from '../../components/TextField';
import PrePopulateCarDetails from '../PrePopulateCarDetails';
import PrePopulateFactoryOptions from './PrePopulateFactoryOptions';
import {
  STATES,
  ROUTES,
  PURCHASE_SOURCE,
  PURCHASE_USE,
  CAR_CONDITION,
} from '../../util/constants';
import DropDown from '../../components/DropDown';
import FindMakeFilter from '../../components/FindMakeFilter';
import Tooltip from '../../components/Tooltip';
import rightdark from '../../../assets/image/right-dark.svg';
import rightnormal from '../../../assets/image/right-normal.svg';
import AmountRangeSelector from '../../components/AmountRangeSelector';
import './carfinance.scss';

const useStyles = makeStyles(() =>
  createStyles({
    amountRangeRoot: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    sliderContainer: {
      marginLeft: 0,
      marginRight: 0,
      width: '100%',
    },
    sliderThumb: {
      marginLeft: '-4px',
    },
    manualCarQueryContainer: {},
  }),
);

const CarFinance = (props) => {
  const {
    setTab,
    currentTab,
    regoNumber,
    regoState,
    searchCar,
    searchComplete,
    purchaseSource,
    purchaseUse,
    purchasePrice,
    depositAmount,
    carCondition,
    manufacturingYear,
    vehicleMake,
    vehicleModel,
    vehicleSeries,
    vehicleVariant,
    skippedVehicleDetails,
    validationErrors,
    getValidationErrorsForField,
    onInputChange,
    flagChange,
    vehicleMakesData,
    vehicleModelsData,
    manualEnter,
    getAllVehicleMakes,
    purchasePriceComplete,
    getModelForVehicleMakes,
    setTabComplete,
    nextFieldNameToFocus,
    scrollToNext,
    depositPriceComplete,
    createQuote,
    handleHTTPError,
    carDetails,
    regoNumberSearch,
    factoryOptions,
    skipVariant,
    skipSeries,
    skipModel,
    noErrors,
    validationCheck,
    ValidationErrorFlag,
    errorMessage,
    getAllVehicleYears,
    vehicleYears,
    vehicleSeriesdata,
    getSeriesForVehicleModel,
    getVariantsForVehicleSeries,
    vehicleVariantsdata,
    getfactoryOptions,
    factoryOptionsComplete,
    opportunityId,
    nvicMake,
    nvicModel,
    loanDetails,
    quoteInProgress,
    isLoadingcarmakes,
    regoSearchInProgress,
    regoSearchError,
    reQuote,
    purposeOfUseChange,
    carPurposehasChanged,
    resetEmploymentSection,
    deleteAllEmployments,
    termVal,
    onEstimateChange,
    completedTabs,
    carSalesOppCreated,
    acceptPrivacyPolicy,
    isCarsalesAccount,
    carSalesContact,
  } = props;
  const history = useHistory();
  const classes = useStyles({});

  const [hideSeries, setHideSeries] = useState(false); // to be reverted after model fix
  const [isPurchaseUseHidden, setIsPurchaseUseHidden] = useState(false);
  const [reduxPurchaseUse, setReduxPurchaseUse] = useState(purchaseUse);
  useEffect(() => {
    setReduxPurchaseUse(purchaseUse);
  }, []);
  useEffect(() => {
    setIsPurchaseUseHidden(reduxPurchaseUse !== '');
  }, [reduxPurchaseUse]);
  useEffect(() => {
    processGTMevent('car', 'pageLoad', 'carDetailsPageLoad');
    reQuote();
    getAllVehicleYears(carCondition).then(() => {
      getAllVehicleMakes();
      getModelForVehicleMakes();
      getSeriesForVehicleModel();
      getVariantsForVehicleSeries();
      getfactoryOptions();
    });
    if (setTab) {
      if (
        completedTabs.car &&
        completedTabs.estimation &&
        completedTabs.applicants &&
        completedTabs.finance_details
      ) {
        setTab('compare_loan_options');
      } else if (completedTabs.car && completedTabs.estimation && completedTabs.applicants) {
        setTab('finance_details');
      } else {
        setTab('car');
      }
      scrollToNext('car-condition');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextFieldNameToFocus) {
      if (
        nextFieldNameToFocus === 'purchasePrice' &&
        carCondition === 'Unsure' &&
        reduxPurchaseUse
      ) {
        return;
      }
      if (
        nextFieldNameToFocus === 'purchase-price-selector' &&
        carCondition === 'Unsure' &&
        reduxPurchaseUse
      ) {
        return;
      }
      scrollToNext(nextFieldNameToFocus, true, true);
    } else if (!carCondition) {
      scrollToNext('car-condition');
    }
  }, [carCondition, nextFieldNameToFocus, reduxPurchaseUse, scrollToNext]);
  // eslint-disable-next-line max-len
  const vehicleYearsformatted = reverse(sortBy(vehicleYears));

  const currentData = new Date();
  const currentYear = currentData.getFullYear();
  const vehicleYearsMax10 = vehicleYearsformatted.reduce((accum, yrOption, idx) => {
    if (idx > 9) return accum;
    accum.push({ name: String(yrOption), value: yrOption });
    return accum;
  }, []);
  vehicleYearsMax10.push({ name: 'Older', value: String(currentYear - 10) });
  const handleClick = () => {
    processGTMevent('Car', 'termValue', termVal);
    if (carPurposehasChanged) {
      deleteAllEmployments();
      resetEmploymentSection();
      flagChange('carPurposehasChanged', false);
    }

    flagChange('carDetailsChanged', false);
    if (validationErrors && validationErrors.length) {
      validationCheck();
      return;
    }
    if (purchasePrice - depositAmount < 5000) {
      validationCheck('Loan Amount cannot be less than $5000.');
      return;
    }
    noErrors();
    if (opportunityId) {
      carDetails();
      loanDetails()
        .then(() => {
          reQuote();
          setTabComplete(currentTab);
          localStorage.setItem(`pageCompleted${opportunityId}`, 'car');
          if (
            (carSalesOppCreated || isCarsalesAccount) &&
            !carSalesContact &&
            !acceptPrivacyPolicy
          ) {
            history.push(ROUTES.contactDetails);
          } else {
            history.push(ROUTES.personal);
          }
        })
        .catch((error) => {
          handleHTTPError(error, history);
        });
    } else {
      createQuote()
        .then(() => {
          setTabComplete(currentTab);
          history.push(ROUTES.personal);
        })
        .catch((error) => {
          handleHTTPError(error, history);
        });
    }
  };

  const regoVehicleSearch = () => {
    noErrors();
    regoNumberSearch();
  };
  const handleFieldChange = (fieldName, value) => {
    onInputChange(fieldName, value, false);
    if (
      fieldName === 'carCondition' ||
      fieldName === 'purchaseUse' ||
      fieldName === 'purchaseSource' ||
      fieldName === 'purchasePrice' ||
      fieldName === 'depositAmount'
    ) {
      if (opportunityId) {
        reQuote();
      } else {
        createQuote();
      }
    }
    if (fieldName === 'carCondition') {
      getAllVehicleYears(value);
    }
    if (
      fieldName === 'carCondition' &&
      (carCondition === 'New' ||
        manualEnter ||
        carCondition === 'Demo' ||
        carCondition === 'Used' ||
        carCondition === 'Unsure')
    ) {
      flagChange('purchasePrice', 50000);
      flagChange('depositAmount', 0);
    }

    if (carCondition === 'New' || manualEnter || carCondition === 'Demo') {
      noErrors();

      if (fieldName === 'manufacturingYear') {
        getAllVehicleMakes();

        if (opportunityId) {
          reQuote();
        } else {
          createQuote();
        }
      }
      if (fieldName === 'vehicleMake') {
        getModelForVehicleMakes();
      }
      if (fieldName === 'vehicleModel') {
        getSeriesForVehicleModel();
      }
      if (fieldName === 'vehicleSeries') {
        getVariantsForVehicleSeries();
      }
      if (fieldName === 'vehicleVariant') {
        getfactoryOptions();
      }
    }
  };

  const handleSkip = (fieldName) => {
    onInputChange('skippedActionTriggered', true, false);
    onInputChange(fieldName, true, false);
  };

  const selectedfactoryData = find(factoryOptions, ['selected', true]);
  const maxTermVal = 7;
  return (
    <>
      <div>
        {/* <PageHeader tabName="car" /> */}
        <div className="container-section car-applicants-main-container">
          <div
            className="content-body car-applicants-main-tab-container car-subscreen-container"
            id="car"
          >
            <div className="car-details-header">
              <div className="car-details-title">Tell us about the car you're looking to buy</div>
              <div className="car-details-subtitle">
                Providing more information will improve the accuracy of your rate and repayments
              </div>
            </div>
            <hr className="car-details-border" />
            <div className="car-applicants-main-subcontainer car-details-content-block">
              <div className="row mx-0">
                <div className="col-12 car-applicants-main-section px-0">
                  <Form
                    noValidate
                    className={cls(
                      {
                        'car-applicants-footer-wrap':
                          (!!purchasePrice && purchasePriceComplete && carCondition !== 'Unsure') ||
                          (carCondition === 'Unsure' && !!reduxPurchaseUse),
                      },
                      'car-applicants-main-form pb-0',
                    )}
                  >
                    <InputButton
                      label={
                        <>
                          <span className="label">What type of car are you planning to buy?</span>
                        </>
                      }
                      fields={CAR_CONDITION}
                      onChange={handleFieldChange}
                      fieldName="carCondition"
                      value={carCondition}
                      scrollToNextFieldOnChage
                      // formGroupClass="fit-screen"
                      getvalidationerrors={getValidationErrorsForField}
                      id="car-condition"
                    />

                    {carCondition === 'Used' && !manualEnter && (
                      <div className="car-rego-question-container rego">
                        <div className="car-applicants-main-rego-question label">
                          Let's find the car you're planning to buy
                        </div>
                        <div
                          className={cls(
                            classes.manualCarQueryContainer,
                            'car-applicants-main-rego',
                          )}
                        >
                          <TextField
                            fieldName="regoNumber"
                            value={regoNumber}
                            label="Rego number"
                            placeholder="e.g GRF324"
                            className="rego-number"
                            getvalidationerrors={getValidationErrorsForField}
                            onChange={handleFieldChange}
                            inline
                          />
                          <DropDown
                            fieldName="regoState"
                            value={regoState}
                            label="State"
                            getvalidationerrors={getValidationErrorsForField}
                            onChange={handleFieldChange}
                            className="rego-state"
                            options={
                              <>
                                <option value="" hidden>
                                  Choose...
                                </option>
                                {STATES.map((state) => (
                                  <option key={state} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </>
                            }
                          />
                          <div className="search-action">
                            <Button
                              variant="primary"
                              disabled={!regoNumber || !regoState || quoteInProgress}
                              onClick={regoVehicleSearch}
                            >
                              {quoteInProgress ? (
                                <CircularProgress color="inherit" size={24} thickness={2.6} />
                              ) : (
                                'Search'
                              )}
                            </Button>
                          </div>

                          {!regoSearchError && (searchCar || regoSearchInProgress) && (
                            <div
                              className="car-applicants-main-prepopulate car-details-prepopulate col-12 col-sm-9"
                              name="carDetailsPrePopulate"
                            >
                              <PrePopulateCarDetails
                                isLoading={regoSearchInProgress}
                                onClear={() => {
                                  handleFieldChange('searchCar', false, false);
                                  handleFieldChange('regoNumber', null, false);
                                  handleFieldChange('regoState', null, false);
                                }}
                                onManualEnter={() => {
                                  handleFieldChange('searchCar', false, false);
                                  handleFieldChange('regoNumber', null, false);
                                  handleFieldChange('regoState', null, false);
                                  handleFieldChange('isRegoKnown', false, false);
                                  handleFieldChange('manualEnter', true, false);
                                }}
                                {...props}
                              />
                            </div>
                          )}
                        </div>

                        {!searchCar && (
                          <div className="rego-manual">
                            <div className="or-style">Don't know the rego number?</div>
                            <div className="manualenter-style">
                              <button
                                type="button"
                                className="btn manual-entry-btn"
                                onClick={() => {
                                  onInputChange('manualEnter', true, false);
                                }}
                              >
                                Enter car manually
                                {/* <span className="pl-1">
                                <img src={rightdark} alt="right" className="right-icon" />
                              </span> */}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {/* for New */}
                    {(carCondition === 'New' || manualEnter || carCondition === 'Demo') && (
                      <>
                        <FindMakeFilter
                          label={
                            <>
                              <span>When was the car manufactured?</span>
                            </>
                          }
                          fields={vehicleYearsformatted.map((yr) => ({
                            name: String(yr),
                            value: yr,
                          }))}
                          onChange={handleFieldChange}
                          fieldName="manufacturingYear"
                          value={manufacturingYear}
                          rootContainerClass="year-class car-manufactured-year"
                          withSideNav
                          isreturnObjectval
                          loadmore
                          getvalidationerrors={getValidationErrorsForField}
                          id="car-manufactured-year"
                        />

                        {manufacturingYear && !skippedVehicleDetails && (
                          <>
                            <FindMakeFilter
                              label={
                                <>
                                  <span>What's the make of the car?</span>
                                </>
                              }
                              fields={
                                isCarsalesAccount && vehicleMake
                                  ? [{ name: vehicleMake.name, value: vehicleMake.name }]
                                  : (vehicleMakesData &&
                                      vehicleMakesData.length &&
                                      vehicleMakesData) ||
                                    []
                              }
                              onChange={handleFieldChange}
                              fieldName="vehicleMake"
                              rootContainerClass="find-make-container car-make"
                              value={
                                vehicleMake &&
                                (isCarsalesAccount ? vehicleMake.name : vehicleMake.value)
                              }
                              isLoading={isLoadingcarmakes}
                              withSideNav
                              enableSearch
                              filtermake={vehicleMake && vehicleMake.name}
                              getvalidationerrors={getValidationErrorsForField}
                              id="car-make"
                            />
                            <Form.Row
                              className={cls('skip-action-container', vehicleMake && 'hidden')}
                            >
                              <FormGroup as={Col} lg={9} md={10}>
                                <div className="skip-action-section contained-section">
                                  <div className="skip-action-align contained-skip-align">
                                    <span className="notsure">Not sure? </span>
                                    <button
                                      type="button"
                                      className="btn btn-default btn-skip-page"
                                      hidden={vehicleMake}
                                      onClick={() => {
                                        handleSkip('skippedVehicleDetails');
                                      }}
                                    >
                                      Skip
                                      <img
                                        src={rightdark}
                                        alt="skip-details"
                                        className="skip-car-details"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </FormGroup>
                            </Form.Row>
                          </>
                        )}
                        {vehicleMake && !skippedVehicleDetails && !skipModel && (
                          <>
                            <FindMakeFilter
                              label={
                                <>
                                  <span className="label">
                                    What's the model of the&nbsp;
                                    <span className="sub-label">
                                      {`${vehicleMake.name || capitalize(nvicMake)}?`}
                                    </span>
                                  </span>
                                </>
                              }
                              fields={
                                isCarsalesAccount && vehicleModel
                                  ? [{ name: vehicleModel.name, value: vehicleModel.name }]
                                  : (vehicleModelsData &&
                                      vehicleModelsData.length &&
                                      vehicleModelsData) ||
                                    []
                              }
                              onChange={handleFieldChange}
                              fieldName="vehicleModel"
                              rootContainerClass="find-model-container car-model"
                              value={
                                vehicleModel &&
                                (isCarsalesAccount ? vehicleModel.name : vehicleModel.value)
                              }
                              loadmore
                              withSideNav
                              // isreturnObjectval
                              getvalidationerrors={getValidationErrorsForField}
                              id="car-model"
                            />
                            <Form.Row
                              className={cls('skip-action-container', vehicleModel && 'hidden')}
                            >
                              <FormGroup as={Col} lg={9} md={10}>
                                <div className="skip-action-section contained-section">
                                  <div className="skip-action-align contained-skip-align">
                                    <span className="notsure">Not sure? </span>
                                    <button
                                      type="button"
                                      className="btn btn-default btn-skip-page"
                                      hidden={vehicleModel}
                                      onClick={() => {
                                        handleSkip('skipModel');
                                      }}
                                    >
                                      Skip
                                      <img
                                        src={rightdark}
                                        alt="skip-details"
                                        className="skip-car-details"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </FormGroup>
                            </Form.Row>
                          </>
                        )}
                        {hideSeries &&
                          vehicleModel &&
                          !skipModel &&
                          !skippedVehicleDetails &&
                          !skipSeries && (
                            <>
                              <FindMakeFilter
                                label={
                                  <>
                                    <span className="label">
                                      What series is the
                                      <span className="sub-label">
                                        {` ${vehicleMake.name || capitalize(nvicMake)}`}
                                      </span>
                                      <span className="sub-label">
                                        {` ${vehicleModel.name || capitalize(nvicModel)}?`}
                                      </span>
                                    </span>
                                  </>
                                }
                                fields={
                                  (vehicleSeriesdata &&
                                    vehicleSeriesdata.length &&
                                    vehicleSeriesdata) ||
                                  []
                                }
                                onChange={handleFieldChange}
                                fieldName="vehicleSeries"
                                rootContainerClass="find-series-container car-series"
                                value={vehicleSeries && vehicleSeries.value}
                                loadmore
                                withSideNav
                                getvalidationerrors={getValidationErrorsForField}
                                id="car-series"
                              />
                              <Form.Row
                                className={cls('skip-action-container', vehicleSeries && 'hidden')}
                              >
                                <FormGroup as={Col} lg={9} md={10}>
                                  <div className="skip-action-section contained-section">
                                    <div className="skip-action-align contained-skip-align">
                                      <span className="notsure">Not sure? </span>
                                      <button
                                        type="button"
                                        className="btn btn-default btn-skip-page"
                                        hidden={vehicleSeries}
                                        onClick={() => {
                                          handleSkip('skipSeries');
                                        }}
                                      >
                                        Skip
                                        <img
                                          src={rightdark}
                                          alt="skip-details"
                                          className="skip-car-details"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Form.Row>
                            </>
                          )}

                        {hideSeries &&
                          vehicleSeries &&
                          !skipModel &&
                          !skippedVehicleDetails &&
                          !skipSeries &&
                          !skipVariant && (
                            <>
                              <InputButton
                                label={
                                  <>
                                    <span className="label">
                                      What's the variant of the&nbsp;
                                      <span className="sub-label">
                                        {vehicleMake.name || capitalize(nvicMake)}
                                      </span>
                                      &nbsp;
                                      <span className="sub-label">
                                        {`${vehicleModel.name || capitalize(nvicModel)}?`}
                                      </span>
                                    </span>
                                  </>
                                }
                                fields={
                                  (vehicleVariantsdata &&
                                    vehicleVariantsdata.length &&
                                    vehicleVariantsdata) ||
                                  []
                                }
                                onChange={handleFieldChange}
                                fieldName="vehicleVariant"
                                value={vehicleVariant && vehicleVariant.value}
                                returnObject
                                sectionContainerClass="vehicle-variant-options"
                                btnClass="vehicle-variant-nvic"
                                formGroupClass="car-variant"
                                getvalidationerrors={getValidationErrorsForField}
                                id="car-variant"
                              />
                              <div className="prepopulate-scroll-locator" />
                              <Form.Row
                                className={cls('skip-action-container', vehicleVariant && 'hidden')}
                              >
                                <FormGroup as={Col} lg={9} md={10}>
                                  <div className="skip-action-section contained-section">
                                    <div className="skip-action-align contained-skip-align">
                                      <span className="notsure">Not sure? </span>
                                      <button
                                        type="button"
                                        className="btn btn-default btn-skip-page"
                                        hidden={vehicleVariant}
                                        onClick={() => {
                                          handleSkip('skipVariant');
                                        }}
                                      >
                                        Skip
                                        <img
                                          src={rightdark}
                                          alt="skip-details"
                                          className="skip-car-details"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Form.Row>
                              {vehicleVariant &&
                                vehicleVariant.value &&
                                (carCondition === 'New' ||
                                  carCondition === 'Demo' ||
                                  manualEnter) &&
                                !!factoryOptions && (
                                  <PrePopulateFactoryOptions
                                    onClear={() => {
                                      handleFieldChange('searchCar', false, false);
                                      handleFieldChange('regoNumber', null, false);
                                      handleFieldChange('regoState', null, false);
                                    }}
                                    onManualEnter={() => {
                                      handleFieldChange('searchCar', false, false);
                                      handleFieldChange('regoNumber', null, false);
                                      handleFieldChange('regoState', null, false);
                                      handleFieldChange('isRegoKnown', false, false);
                                    }}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...props}
                                    noWhiteBackground
                                    containedSection
                                  />
                                )}
                            </>
                          )}
                      </>
                    )}
                    {carCondition === 'Unsure' && (
                      <div
                        className={cls(
                          'amount-range-container purchase-price-selector car-purchase-price',
                          'scrollable-anchor-tag',
                          // !purchasePriceComplete && 'fit-screen',
                        )}
                      >
                        <a name="car-purchase-price" href="#car-purchase-price">
                          &nbsp;
                        </a>
                        <div className="label">How much you are planning to spend?</div>
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="range-selector-label">Purchase price</span>
                            </>
                          }
                          onChange={handleFieldChange}
                          fieldName="purchasePrice"
                          defaultValue={purchasePrice}
                          colsm={12}
                          colmd={8}
                          // oncreateQuote={createQuote}
                          getvalidationerrors={getValidationErrorsForField}
                          minValue={5000}
                          maxValue={250000}
                          step={1000}
                          // formGroupClass="fit-screen"
                          classes={{
                            root: classes.amountRangeRoot,
                            sliderContainer: classes.sliderContainer,
                          }}
                          sliderClasses={{
                            thumb: classes.sliderThumb,
                          }}
                        />
                      </div>
                    )}
                    {carCondition === 'Unsure' && purchasePriceComplete && (
                      <>
                        <div className="deposit scrollable-anchor-tag">
                          <a name="deposit" href="#deposit">
                            &nbsp;
                          </a>
                        </div>
                        <div
                          className={cls(
                            'amount-range-container deposit-price-selector',
                            depositPriceComplete /* && 'fit-screen' */,
                          )}
                        >
                          <AmountRangeSelector
                            label={
                              <>
                                <span className="range-selector-label">Deposit/Trade-in</span>
                              </>
                            }
                            onChange={handleFieldChange}
                            fieldName="depositAmount"
                            defaultValue={depositAmount}
                            colsm={12}
                            colmd={8}
                            minValue={0}
                            maxValue={purchasePrice - 5000}
                            disabled={purchasePrice - 5000 <= 0}
                            step={1000}
                            tooltip="Enter the combined amount for the trade in value of your current car, and any cash deposit you will be contributing to the purchase"
                            getvalidationerrors={getValidationErrorsForField}
                            classes={{
                              root: classes.amountRangeRoot,
                              sliderContainer: classes.sliderContainer,
                            }}
                            sliderClasses={{
                              thumb: classes.sliderThumb,
                            }}
                          />
                          <div className="form-row pb-4">
                            <div className=" car-applicants-loan-form col-md-5 pr-5 mr-3 py-2">
                              <div className="car-applicants-main-loanamount">
                                <NumberFormat
                                  displayType="text"
                                  value={purchasePrice - depositAmount}
                                  className="loan-amount-value car-details-loan-amt"
                                  id="loan-amount-value"
                                  prefix="$"
                                  thousandSeparator
                                />
                              </div>
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label
                                className="car-applicants-main-loanamount-label car-details-loan-amount-label"
                                htmlFor="loan-amount-value"
                              >
                                Estimated loan amount
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {depositPriceComplete && carCondition === 'Unsure' && (
                      <FindMakeFilter
                        label={
                          <>
                            <span>How old do you estimate the car to be?</span>
                          </>
                        }
                        fields={vehicleYearsMax10}
                        onChange={handleFieldChange}
                        fieldName="manufacturingYear"
                        value={manufacturingYear}
                        rootContainerClass="year-class car-manufactured-year"
                        loadmore
                        isFullScreen
                        isreturnObjectval
                        getvalidationerrors={getValidationErrorsForField}
                        id="car-manufactured-year"
                      />
                    )}

                    {/* Common Fields */}
                    {(searchComplete ||
                      ((carCondition === 'Demo' || manualEnter) &&
                        manufacturingYear &&
                        carCondition !== 'New' &&
                        (skippedVehicleDetails ||
                          skipModel ||
                          skipSeries ||
                          skipVariant ||
                          // to be reverted after model fix
                          // (vehicleVariant && (factoryOptionsComplete || nofactoryOptions)))) ||
                          vehicleModel)) ||
                      (carCondition === 'Unsure' && manufacturingYear)) && (
                      <InputButton
                        label={
                          <>
                            <span>Where are you planning to purchase the car from?</span>
                          </>
                        }
                        fields={PURCHASE_SOURCE}
                        onChange={handleFieldChange}
                        fieldName="purchaseSource"
                        value={purchaseSource}
                        formGroupClass="car-purchase-from"
                        id="car-purchase-from"
                        getvalidationerrors={getValidationErrorsForField}
                      />
                    )}
                    {!isPurchaseUseHidden &&
                      ((purchaseSource && carCondition !== 'New') ||
                        (carCondition === 'New' &&
                          (skippedVehicleDetails ||
                            skipModel ||
                            skipSeries ||
                            skipVariant ||
                            // to be reverted after model fix
                            // (vehicleVariant && (factoryOptionsComplete || nofactoryOptions))))) && (
                            vehicleModel))) && (
                        <InputButton
                          label={
                            <div className="title-with-sub-label">
                              <div className="label">
                                How will the car be used for the majority of the time?
                              </div>
                              <span className="title-sub-label">
                                Select business if you use your car for work more than 50% of the
                                time
                              </span>
                              <Tooltip message="Using your car for business includes carrying tools to multiple job sites, driving between clients etc. You will need to provide proof of business use." />
                            </div>
                          }
                          fields={PURCHASE_USE}
                          onChange={purposeOfUseChange}
                          fieldName="purchaseUse"
                          value={reduxPurchaseUse}
                          formGroupClass="car-usage"
                          getvalidationerrors={getValidationErrorsForField}
                          id="car-usage"
                        />
                      )}
                    {reduxPurchaseUse && carCondition !== 'Unsure' && (
                      <div
                        className={cls(
                          'amount-range-container purchase-price-selector car-purchase-price',
                          'scrollable-anchor-tag',
                          // !purchasePriceComplete && 'fit-screen',
                        )}
                      >
                        <a name="car-purchase-price" href="#car-purchase-price">
                          &nbsp;
                        </a>
                        <div className="label">
                          What is the estimated purchase price of the car?
                        </div>
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="range-selector-label">Purchase price</span>
                            </>
                          }
                          onChange={handleFieldChange}
                          fieldName="purchasePrice"
                          defaultValue={purchasePrice}
                          colsm={12}
                          colmd={8}
                          // oncreateQuote={createQuote}
                          getvalidationerrors={getValidationErrorsForField}
                          minValue={5000}
                          maxValue={250000}
                          step={1000}
                          classes={{
                            root: classes.amountRangeRoot,
                            sliderContainer: classes.sliderContainer,
                          }}
                          sliderClasses={{
                            thumb: classes.sliderThumb,
                          }}
                        />
                      </div>
                    )}
                    {purchasePriceComplete && carCondition !== 'Unsure' && (
                      <>
                        <div className="deposit scrollable-anchor-tag">
                          <a name="deposit" href="#deposit">
                            &nbsp;
                          </a>
                        </div>
                        <div className="amount-range-container deposit-price-selector">
                          <AmountRangeSelector
                            label={
                              <>
                                <span className="range-selector-label">Deposit / Trade-in</span>
                              </>
                            }
                            onChange={handleFieldChange}
                            fieldName="depositAmount"
                            defaultValue={depositAmount}
                            colsm={12}
                            colmd={8}
                            minValue={0}
                            // oncreateQuote={createQuote}
                            maxValue={purchasePrice}
                            step={1000}
                            tooltip="Enter the combined amount for the trade in value of your current car, and any cash deposit you will be contributing to the purchase"
                            getvalidationerrors={getValidationErrorsForField}
                            classes={{
                              root: classes.amountRangeRoot,
                              sliderContainer: classes.sliderContainer,
                            }}
                            sliderClasses={{
                              thumb: classes.sliderThumb,
                            }}
                          />
                          <div className="form-row pb-4">
                            <div className=" car-applicants-loan-form col-md-5 pr-5 ">
                              <div className="car-applicants-main-loanamount">
                                <NumberFormat
                                  displayType="text"
                                  value={purchasePrice - depositAmount}
                                  className="loan-amount-value car-details-loan-amt"
                                  id="loan-amount-value"
                                  prefix="$"
                                  thousandSeparator
                                />
                              </div>
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label
                                className="car-applicants-main-loanamount-label car-details-loan-amount-label"
                                htmlFor="loan-amount-value"
                              >
                                Estimated loan amount
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {depositPriceComplete && reduxPurchaseUse && (
                      <div
                        className={cls(
                          'amount-range-container loan-term-selector car-loan-term',
                          'scrollable-anchor-tag',
                        )}
                      >
                        <div className="label">Over what term do you want the loan?</div>
                        <div className="term scrollable-anchor-tag">
                          <a name="term" href="#term">
                            &nbsp;
                          </a>
                        </div>

                        <div className="amount-range-container term-val-selector">
                          <AmountRangeSelector
                            label={
                              <>
                                <span className="label">Loan term</span>
                              </>
                            }
                            // formGroupClass="estimation-range-selector col-12 col-sm-6 col-md-4"
                            onChange={onEstimateChange}
                            fieldName="termVal"
                            defaultValue={termVal}
                            oncreateQuote={createQuote}
                            minValue={1}
                            maxValue={maxTermVal}
                            step={1}
                            termSlider
                            classes={{
                              root: classes.amountRangeRoot,
                              sliderContainer: classes.sliderContainer,
                            }}
                            sliderClasses={{
                              thumb: classes.sliderThumb,
                            }}
                            getvalidationerrors={getValidationErrorsForField}
                          />
                        </div>
                      </div>
                    )}

                    <div className="footer-actions">
                      <button
                        type="button"
                        className="btn btn-primary btn-next-page"
                        hidden={
                          !carCondition ||
                          ((!searchCar || regoSearchInProgress) &&
                            carCondition === 'Used' &&
                            (!purchasePrice || purchasePrice === 50000) &&
                            !manualEnter) ||
                          ((!purchasePrice ||
                            (purchasePrice === 50000 &&
                              !skippedVehicleDetails &&
                              !skipModel &&
                              !skipSeries &&
                              !skipVariant)) &&
                            (carCondition === 'New' || carCondition === 'Demo' || manualEnter) &&
                            // !vehicleVariant) || to be removed after variant and series fix
                            !reduxPurchaseUse) ||
                          ((carCondition === 'New' || carCondition === 'Demo' || manualEnter) &&
                            !vehicleVariant &&
                            (skippedVehicleDetails || skipModel || skipSeries || skipVariant) &&
                            !reduxPurchaseUse &&
                            !depositAmount) ||
                          ((carCondition === 'New' || carCondition === 'Demo' || manualEnter) &&
                            factoryOptionsComplete &&
                            !reduxPurchaseUse &&
                            !depositAmount) ||
                          (!purchasePrice && carCondition === 'Unsure') ||
                          (searchComplete && !reduxPurchaseUse && !depositAmount) ||
                          (purchasePriceComplete &&
                            carCondition !== 'Unsure' &&
                            depositPriceComplete) ||
                          (depositPriceComplete && carCondition === 'Unsure')
                        }
                        disabled={validationErrors && validationErrors.length}
                        onClick={() => {
                          noErrors();
                          if (searchCar && !searchComplete) {
                            onInputChange('searchComplete', true, false);
                          } else if (
                            vehicleVariant &&
                            !factoryOptionsComplete &&
                            (carCondition === 'New' || carCondition === 'Demo' || manualEnter)
                          ) {
                            onInputChange('factoryOptionsComplete', true, false);
                          } else if (purchasePrice && !purchasePriceComplete) {
                            onInputChange('purchasePriceComplete', true, false);
                            processGTMevent('Car', 'purchasePriceValue', purchasePrice);
                          } else if (
                            (depositAmount || depositAmount === 0 || depositAmount === null) &&
                            !depositPriceComplete
                          ) {
                            onInputChange('depositPriceComplete', true, false);
                            processGTMevent('Car', 'depositAmountValue', depositAmount);
                          } else if (
                            (depositAmount || depositAmount === 0 || depositAmount === null) &&
                            carCondition === 'Unsure'
                          ) {
                            if (purchasePrice - depositAmount < 5000) {
                              validationCheck('Loan Amount cannot be less than $5000.');
                              return false;
                            }
                            onInputChange('depositPriceComplete', true, false);
                            processGTMevent('Car', 'depositAmountValue', depositAmount);
                          }
                        }}
                      >
                        Next
                        <span className="pl-4">
                          <img src={rightnormal} alt="right" className="right-icon" />
                        </span>
                      </button>
                      {((!!purchasePrice &&
                        purchasePriceComplete &&
                        depositPriceComplete &&
                        !!termVal &&
                        carCondition !== 'Unsure') ||
                        (carCondition === 'Unsure' &&
                          depositPriceComplete &&
                          !!reduxPurchaseUse &&
                          !!termVal)) && (
                        <div className="row container-footer  car-details-footer ">
                          <div className="col-12 d-flex justify-content-end px-0 car-details-next-btn">
                            <button
                              type="button"
                              data-test="carDetails-Next"
                              className="btn btn-primary btn-next-page"
                              onClick={handleClick}
                              disabled={quoteInProgress}
                            >
                              {quoteInProgress ? (
                                <CircularProgress color="inherit" size={24} thickness={2.6} />
                              ) : (
                                <>
                                  Next
                                  <span className="pl-4">
                                    <img src={rightnormal} alt="right" className="right-icon" />
                                  </span>
                                </>
                              )}
                            </button>
                            {/*   dummy button addded to make the focus point there */}
                            <input
                              type="button"
                              id="btnsubmit"
                              className="btn-dummy-page"
                              style={{ visibility: 'hidden' }}
                            />
                          </div>
                        </div>
                      )}
                      {ValidationErrorFlag && (
                        <div className="p-0">
                          <Alert variant="danger" className="error-alert py-4 px-2">
                            <div className="error-flex">
                              <div className="error-header-message">
                                {searchCar ? 'We could not identify your details.' : 'Error'}
                              </div>
                              <p className="error-message">{errorMessage}</p>
                            </div>
                          </Alert>
                        </div>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarFinance;
