/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import cls from 'classnames';
import { Auth } from 'aws-amplify';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import findIndex from 'lodash/findIndex';
import Close from '../../../assets/image/Close.svg';
import dashboard from '../../../assets/image/Dashboard.svg';
import fieldChanged, { liteFieldChanged } from '../../Actions/FieldChanged';
import './header.scss';
import Settings from '../../../assets/image/Settings.svg';
import lock from '../../../assets/image/LoginIcon.svg';
import logout from '../../../assets/image/External-Link.svg';
import { ROUTES } from '../../util/constants';
import logo from '../../../assets/image/logo.jpg';
import { resetAllTabs } from '../../Actions/tab';
import MenuIcon from '@material-ui/icons/Menu';

export const headerUseStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        // height: '100%',
        padding: theme.spacing(0, 0, 0, 2),
      },
      title: {
        display: 'inline',
        fontWeight: '400',
        flex: '1',
      },
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2, -2),
        backgroundColor: '#fff',
        padding: theme.spacing(1, 2, 1, 4),
      },
      paperAnchorTop: {
        height: '100%',
      },
      closeButton: {
        position: 'relative',
        width: '46px',
      },
      titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '25px',
        color: '#25282A',
        fontFamily: 'Poppins-SemiBold',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '20px',
        padding: '20px',
      },
      closeIcon: {
        width: 'auto',
        height: '1.5em',
        color: '#330072',
      },
      menuicon: {
        marginRight: '16px',
        '& svg': {
          color: '#330072',
        },
      },
      stepIconNum: {
        top: '0 !important',
      },
    }),
  { name: 'Sidebar-Wrapper' },
);

export const SideBarWrapper = ({ children, showDrawer, closeDrawer, title, enableDrawer }) => {
  const classes = headerUseStyles();

  if (enableDrawer) {
    return (
      <Drawer
        anchor="top"
        open={showDrawer}
        classes={{ paper: classes.root, paperAnchorTop: classes.paperAnchorTop }}
      >
        <div className="dropdown-wrapper">
          <div className={classes.titleWrapper}>
            <div className="title">{title}</div>
            <IconButton
              color="primary"
              aria-label="close"
              component="span"
              onClick={closeDrawer}
              classes={{ root: classes.closeButton }}
            >
              {/* <CancelIcon classes={{ root: classes.closeIcon }} /> */}
              <img src={Close} alt="close" className="close-image" />
            </IconButton>
          </div>
          <div className="sidebar">{children}</div>
        </div>
      </Drawer>
    );
  }

  return <div className="px-3 py-4 sidebar sticky-top">{children}</div>;
};

const Header = ({
  fullName,
  CognitouserName,
  isAuthenticated,
  setAuthenticationlogOut,
  clearAllData,
  errorMessage,
  showErrorModal,
  currentTab,
  isPQProductSelectionGroup,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const enableDrawer = useMediaQuery(theme.breakpoints.down(768));
  let initials;
  let formatteduserName;

  const [showDrawer, toggleDrawer] = useState(false);
  const handleToggleDrawer = () => {
    toggleDrawer(enableDrawer ? !showDrawer : false);
  };

  if (fullName) {
    const nameparts = fullName.split(' ');
    initials = nameparts[0].charAt(0).toUpperCase() + nameparts[1].charAt(0).toUpperCase();
    formatteduserName = fullName
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  async function handleLogout() {
    await Auth.signOut();
    clearAllData();
    setAuthenticationlogOut().then(() => {
      history.push(ROUTES.login);
    });
  }

  const location = useLocation();

  const onLogoClick = () => {
    if (isAuthenticated) {
      history.push(ROUTES.dashboard);
    } else {
      window.open('https://www.strattonfinance.com.au/');
    }
  };
  const showDashboard = () => {
    history.push(ROUTES.dashboard);
  };
  const loginPage = () => {
    history.push(ROUTES.login);
  };
  return (
    <>
      <Navbar
        className={cls({
          'estimation-route-header':
            location.pathname === ROUTES.estimation ||
            location.pathname === ROUTES.initial ||
            location.pathname === ROUTES.initialPersonalDetails ||
            location.pathname === ROUTES.initialFinanceDetails ||
            location.pathname === ROUTES.login ||
            location.pathname === ROUTES.signUp ||
            location.pathname === ROUTES.loginReset ||
            location.pathname === ROUTES.updateLoanAmount ||
            location.pathname === ROUTES.estimateLoading,
        })}
      >
        <div className="container">
          <header className="header py-2 px-0">
            <img src={logo} alt="Stratton" className="stratton-logo" onClick={onLogoClick} />
          </header>
          <div className="header-left-align">
            <div className="call-details">
              <div className="call-us">Speak to an expert</div>
              {isPQProductSelectionGroup ? (
                <div className="phone">1300 416 707</div>
              ) : (
                <div className="phone">1300 787 288</div>
              )}
            </div>

            {!isAuthenticated &&
              location.pathname !== ROUTES.login &&
              location.pathname !== ROUTES.personal &&
              location.pathname !== ROUTES.verifyOTP &&
              location.pathname !== ROUTES.edmenquiry && (
                <div className="login-button">
                  <button onClick={loginPage} data-test="loginpage-header" className="btn-login">
                    <img src={lock} alt="login" className="lock-icon" />
                    <span className="login-label">Login</span>
                  </button>
                </div>
              )}
            {isAuthenticated ? (
              <Navbar.Collapse className="justify-content-end show">
                <NavDropdown
                  className="header-dropdown"
                  onClick={handleToggleDrawer}
                  title={
                    <>
                      {fullName && fullName !== null ? (
                        <>
                          <span className="content-initial">{initials}</span>
                          <span className="content-username">{formatteduserName}</span>
                        </>
                      ) : (
                        <>
                          <AccountCircleIcon className="content-initial account-avatar" />
                          <span className="content-username no-name">{CognitouserName}</span>
                        </>
                      )}
                    </>
                  }
                  id="basic-nav-dropdown"
                >
                  <SideBarWrapper
                    enableDrawer={enableDrawer}
                    showDrawer={showDrawer}
                    closeDrawer={() => {
                      toggleDrawer(false);
                    }}
                    title={
                      <>
                        {fullName && fullName !== null ? (
                          <>
                            <span className="content-initial ">{initials}</span>
                            <span className="content-username">{formatteduserName}</span>
                          </>
                        ) : (
                          <>
                            <AccountCircleIcon className="content-initial account-avatar" />
                            <span className="content-username no-name">{CognitouserName}</span>
                          </>
                        )}
                      </>
                    }
                  >
                    {location.pathname !== ROUTES.dashboard && (
                      <NavDropdown.Item className="dashboard-item py-3" onClick={showDashboard}>
                        <img src={dashboard} alt="dashboard" className="dashboard-img" />
                        <span className="content-Dashboard px-3">Dashboard</span>
                      </NavDropdown.Item>
                    )}
                    <NavDropdown.Item className="content-item py-3" onClick={handleLogout}>
                      <img src={logout} alt="logout" className="content-editimage" />
                      <span className="content-edit px-3">Logout</span>
                    </NavDropdown.Item>
                  </SideBarWrapper>
                </NavDropdown>
              </Navbar.Collapse>
            ) : null}
          </div>
        </div>
      </Navbar>

      {showErrorModal && (
        <Alert variant="danger" className="error-alert">
          <p className="error-message">{errorMessage}</p>
        </Alert>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    SaveQuoteSuccess: state.account.SaveQuoteSuccess,
    SaveAccountLogout: state.account.SaveAccountLogout,
    fullName:
      state.applicants.firstName && `${state.applicants.firstName} ${state.applicants.lastName}`,
    isAuthenticated: state.account.isAuthenticated,
    CognitouserName: state.account.userName,
    completedTabs: state.tab.completedTabs,
    showErrorModal: state.errorHandler.showErrorModal,
    errorMessage: state.errorHandler.errorMessage,
    currentTab: state.tab.tabName,
    isPQProductSelectionGroup: state.applicants.isPQProductSelectionGroup,
  }),
  (dispatch) => ({
    onEditSaveQuote: () => {
      dispatch(liteFieldChanged('account', 'showSaveQuote', true, false));
    },

    /*  onAccountLogout: () => {
      dispatch(liteFieldChanged('account', 'showSaveAccount', true, false));
    }, */
    clearAllData: () => {
      dispatch(resetAllTabs(true));
    },
    setAuthenticationlogOut: () =>
      dispatch(fieldChanged('account', 'isAuthenticated', false, false)),
  }),
)(Header);
