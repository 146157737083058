import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import find from 'lodash/find';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import reduce from 'lodash/reduce';
import round from 'lodash/round';
import unionBy from 'lodash/unionBy';
import filter from 'lodash/filter';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cls from 'classnames';
import rightnormal from '../../../../../assets/image/right-normal.svg';
import LiabilityForm from './LiabilityFormNew';
import { formatAmount } from '../../../../lib/formatter';
import deleteImg from '../../../../../assets/image/delete.svg';
import { Table, Form, Alert } from 'react-bootstrap'
import { Grid, Button, ButtonGroup, CircularProgress } from '@material-ui/core';

import {
  MONTH_OPTION,
  LIABILITIES_TYPES,
  DELETE_CONFIRM,
  LIABILITY_MORTGAGE_OPTION,
  LIABILITY_MORTGAGE_INVEST_OPTION,
  LIABILITY_VEHICAL_LOAN_OPTION,
  LIABILITY_ASSET_LOAN_OPTION,
  HOME_PROPERTY,
  INVESTMENT_PROPERTY,
  RECREATIONAL,
  MOTORVEHICLE,
  AssetTypes,
  LIABILITIES_RELATED_ASSET,
  LIABILITY_TAX_DEBT_OPTION,
  LIABILITY_CREDIT_CARD_OPTION,
  LIABILITY_OVERDRAFT_OPTION,
  FREQ_OPTIONS,
  ASSETS_LABEL_VALUE_MAP,
  LIABILITIES_TYPES_WITHOUT_MORTGAGE,
} from '../../../../util/constants';
import processGTMevent from '../../../../util/processGTMEvent';
import { getNameByValue } from '../../../../util/ContainerUtilities';
import './liabilitydetailsNew.scss';
import '../../finances-common.scss';
import ConfirmDeleteRow from '../../../../components/ConfirmDeleteRow';
import getFinanceOptions from '../../../../util/constantFinances';
import DropDownSelect from '../../../../components/DropDownSelect';
import { isLiabilityValidation } from '../../../../util/helpers';
import NumberInput from '../../../../components/NumberInput';
const ACTION_NEW = 'NEW';

export const getFilteredAssetsByLiability = (assetsLists, relatedLiability) =>
  filter(
    assetsLists,
    (assetData) =>
      assetData.assetType === LIABILITIES_RELATED_ASSET[relatedLiability] && assetData.currentlyFinanced,
  );


const LiabilityDetails = ({
  liabilities,
  assets,
  pendingLiabilitySave,
  initExpList,
  completeLiabDetails,
  addLiability,
  updateLiability,
  deleteLiability,
  validationCheck,
  ValidationErrorFlag,
  errorMessage,
  noErrors,
  deleteLiabApi,
  reQuote,
  purchaseSource,
  assetsLists,
  liabilityNewApi,
  retrieveOpportunity,
  liabilitySubmitInprogress,
  onLiteInputChange
}) => {
  const [showForm, updateShowForm] = useState(false);
  const [liabilityList, updateList] = useState(liabilities || []);
  const [relatedAssets, setRelatedAssets] = useState([]);

  useEffect(() => {
    const filteredList = reduce(
      liabilityList,
      (accum, stateLiability) => {
        const sourceIncome = find(liabilities, ['id', stateLiability.id]);
        if (sourceIncome) {
          accum.push({ ...stateLiability, ...sourceIncome, flagDelete: stateLiability.flagDelete });
        }
        return accum;
      },
      [],
    );
    updateList(unionBy(filteredList, liabilities, 'id'));

    const relatedLiabilities = liabilities.filter((item) => {
      return item.liabilityType === LIABILITY_VEHICAL_LOAN_OPTION || item.liabilityType === LIABILITY_MORTGAGE_OPTION || item.liabilityType === LIABILITY_MORTGAGE_INVEST_OPTION || item.liabilityType === LIABILITY_ASSET_LOAN_OPTION
    })
    let relatedAssetsArr = [];
    relatedLiabilities.forEach(({ liabilityType = '' }) => {
      const relatedAssets = getFilteredAssetsByLiability(
        assetsLists,
        liabilityType || '',
      );
      relatedAssets.length > 0 && relatedAssetsArr.push({
        ...relatedAssets[0], liabilityType: liabilityType
      });
    });
    setRelatedAssets(relatedAssetsArr)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liabilities]);

  useEffect(() => {
    const arrUpdateAssets = [...checkMatches(liabilityList, relatedAssets)]
    updateList(arrUpdateAssets)
  }, [relatedAssets])

  const checkMatches = (arr1, arr2) => {
    for (var i in arr2) {
      for (var j in arr1) {
        if (arr1[j].liabilityType === arr2[i].liabilityType) {
          arr1[j].relatedAsset = arr2[i].id;
          arr1[j].relatable = true
          break;
        }
      }
    }
    return [...arr1];
  }

  const flagToDelete = (id, flag = false) => () => {
    updateList(
      reduce(
        liabilityList,
        (accum, option) => {
          const updatedOption = { ...option };
          if (option.id === id) {
            updatedOption.flagDelete = flag;
          }
          accum.push(updatedOption);
          return accum;
        },
        [],
      ),
    );
  };

  const liabilitytotal = round(
    reduce(
      liabilities,
      (calTotal, liabilityData) =>
        calTotal +
        Number(
          liabilityData.brokerUpdated
            ? liabilityData.verifiedOutstandingBalance
            : liabilityData.outstanding,
        ),
      0,
    ),
  );

  const handleAddLiability = () => {
    if (!isLiabilityValidation(liabilities, updateLiability))
      addLiability({
        liabilityType: "",
        financier: "",
        outstanding: "",
        limit: "",
        repayments: "",
        relatedAsset: "",
        payout: false,
        frequency: "Weekly"
      })
  };


  const handleDeleteLiability = (liabilityData) => () => {
    if (liabilityData?.id?.length > 10) {
      deleteLiabApi({ ...liabilityData })
        .then(() => {
          deleteLiability({ ...liabilityData });
        })
        .catch(() => {
          console.log('error');
        });
    }
    else {
      deleteLiability({ ...liabilityData });
    }
  };

  const handleCancel = () => {
    updateShowForm('');
  };

  const handleUpdate = (liabilityData) => {
    if (showForm === ACTION_NEW) {
      addLiability(liabilityData);
    } else {
      updateLiability(liabilityData);
    }
    handleCancel();
  };

  const handleDeleteFile = (liabilityData) => {
    updateLiability(liabilityData);
    updateShowForm({ ...liabilityData });
  };

  const handleLiabilityDetails = () => {
    processGTMevent('finances', 'totalliabilities', { value: liabilitytotal });
    reQuote();
    const checkAmountval = _.some(liabilities, { outstanding: '0.00' });
    const liabilityMortgage = find(liabilities, ['liabilityType', LIABILITY_MORTGAGE_OPTION]);
    const liabilityInvestment = find(liabilities, ['liabilityType', LIABILITY_MORTGAGE_INVEST_OPTION]);
    const liabilityMotorvehicle = find(liabilities, ['liabilityType', LIABILITY_VEHICAL_LOAN_OPTION]);
    const liabilityRecreational = find(liabilities, ['liabilityType', LIABILITY_ASSET_LOAN_OPTION]);
    const propertyHomeAsset = find(assets, ['liabilityType', HOME_PROPERTY]);
    const investmentPropertyAsset = find(assets, ['liabilityType', INVESTMENT_PROPERTY]);
    const motorVehicleAsset = find(assets, ['liabilityType', MOTORVEHICLE]);
    const recreationalAsset = find(assets, ['liabilityType', RECREATIONAL]);

    if (propertyHomeAsset && propertyHomeAsset.underfinance && !liabilityMortgage) {
      validationCheck(
        `${getNameByValue(
          LIABILITIES_TYPES,
          LIABILITY_MORTGAGE_OPTION,
        )} is mandatory because your ${getNameByValue(
          AssetTypes,
          HOME_PROPERTY,
        )} is currently financed`,
      );
    } else if (
      investmentPropertyAsset &&
      investmentPropertyAsset.underfinance &&
      !liabilityInvestment
    ) {
      validationCheck(
        `${getNameByValue(
          LIABILITIES_TYPES,
          LIABILITY_MORTGAGE_INVEST_OPTION,
        )} is mandatory because your ${getNameByValue(
          AssetTypes,
          INVESTMENT_PROPERTY,
        )} is currently financed`,
      );
    } else if (!liabilityMotorvehicle && motorVehicleAsset && motorVehicleAsset.underfinance) {
      validationCheck(
        `${getNameByValue(
          LIABILITIES_TYPES,
          LIABILITY_VEHICAL_LOAN_OPTION,
        )} is mandatory because your ${getNameByValue(
          AssetTypes,
          MOTORVEHICLE,
        )} is currently financed`,
      );
    } else if (!liabilityRecreational && recreationalAsset && recreationalAsset.underfinance) {
      validationCheck(
        `${getNameByValue(
          LIABILITIES_TYPES,
          LIABILITY_ASSET_LOAN_OPTION,
        )} is mandatory because your ${getNameByValue(
          AssetTypes,
          RECREATIONAL,
        )} is currently financed`,
      );
    } else if (checkAmountval) {
      validationCheck('Amount should be greater than zero');
    } else if (!checkAmountval) {

      if (!isLiabilityValidation(liabilities, updateLiability)) {
        onLiteInputChange('liabilitySubmitInprogress', true, false);
        noErrors();
        liabilityNewApi().then(() => {
          retrieveOpportunity().then(() => {
            completeLiabDetails()
            onLiteInputChange('liabilitySubmitInprogress', false, false);
          })
            .catch(() => {
              completeLiabDetails()
              onLiteInputChange('liabilitySubmitInprogress', false, false);
            })
        })
          .catch(() => {
            onLiteInputChange('liabilitySubmitInprogress', false, false);
            completeLiabDetails();
          })
      }
    }
  };

  const handleOnChangeCategoryLiability = (data, val) => {
    updateLiability({
      ...data,
      liabilityType: val,
      errorMsgliability: ''
    });
  }

  const handleOnChangeCategoryRelLiablity = (data, val) => {
    updateLiability({
      ...data,
      relatedAsset: val,
      errorMsgRelatedAssets: ''
    });
  }

  const handleOnChangeFinancier = (data, val) => {
    updateLiability({
      ...data,
      financier: val,
      errorMsgfinancier: ''
    });
  }

  const handleChangeLimit = (data, values) => {
    updateLiability({
      ...data,
      limit: values.value,
      errorMsgLimit: ''
    });
  }

  const handleChangeOutstanding = (data, values) => {
    updateLiability({
      ...data,
      outstanding: values.value,
      errorMsgOutstanding: ''
    });
  }

  const handleChangeRepayment = (data, values) => {
    updateLiability({
      ...data,
      repayments: values.value,
      errorMsgRepayment: ''
    });
  }

  const onClickFrequency = (data, val) => {
    updateLiability({
      ...data,
      frequency: val,
    });
  }
  const handleCheckboxChange = (data, e) => {
    updateLiability({
      ...data,
      payout: e.target.checked,
    });
  }

  const relatedAssetsOptions = relatedAssets.map((asset, idx) => ({
    value: asset.id,
    name: `${ASSETS_LABEL_VALUE_MAP[asset.assetType]} - $${formatAmount(asset.estimateVal)}`,
    selected: idx === 0,
  }));

  const foundHomePropertyAssets = assets.some(item => item.assetType === HOME_PROPERTY && item.currentlyFinanced === true);
  const smScreen = useMediaQuery('@media (max-width: 767.95px)');

  return (
    <Card className="content-body liability-details finances-details-container liability-details">
      <Card.Header className="col-12 page-header-container finances-details-header-container scrollable-anchor-tag with-sidebar">
        <a name="liability-details" href="#liability-details">
          &nbsp;
        </a>
        <h1 className="finances-details-header">Liability details</h1>
        <div className="finances-details-description">
          We need details about your finances to get you the best and accurate loan options
        </div>
      </Card.Header>
      <Card.Body className={cls('finances-body-container', !ValidationErrorFlag && 'pt-0')}>
        {liabilityList.length === 0 && (
          <div className="netincome">
            <div className="netincome-label">
              <button
                type="button"
                onClick={handleAddLiability}
                className="btn btn-add-income add-liability"
              >
                + Add liability
              </button>
            </div>
          </div>
        )}
        {ValidationErrorFlag && (
          <Alert variant="danger" className="error-alert">
            <p className="error-message">{errorMessage}</p>
          </Alert>
        )}
        <Table hover responsive className="finances-body-table">
          <tbody className="finances-card-table-body row-container">

            {liabilityList.map((task) => (
              <tr className="finances-card-table-row container-row" key={task.id || task.defaultId}>
                <td colSpan={5}>
                  {!(foundHomePropertyAssets && task.liabilityType === LIABILITY_MORTGAGE_OPTION) && <Grid item xs={12} md={12}>
                    {((!task.brokerUpdated || task.payslipNotVerified) && (!task.flagDelete)) && (
                      <div className={cls(
                        'finances-card-table-detail-new',
                        'btn-delete'
                      )}>
                        <img
                          src={deleteImg}
                          alt="delete"
                          onClick={flagToDelete(task.id, true)}
                        />
                        <button
                          type="button"
                          className="px-0 btn btn-link finances-card-table-delete"
                          onClick={flagToDelete(task.id, true)}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                    <ConfirmDeleteRow
                      task={task}
                      deleteConfirmText={DELETE_CONFIRM}
                      onDelete={handleDeleteLiability}
                      flagToDelete={flagToDelete}
                      colSpan={5}
                    />
                  </Grid>}

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      {foundHomePropertyAssets && task.liabilityType === LIABILITY_MORTGAGE_OPTION ? <DropDownSelect
                        fieldName="liabilityType"
                        value={task.liabilityType || ''}
                        label="Liability type"
                        errorMsg={task.errorMsgliability}
                        onChange={(val) => handleOnChangeCategoryLiability(task, val)}
                        isDisable={true}
                        colxs="12"
                        colsm="6"
                        colmd="6"
                        options={
                          <>
                            <option value="">Select</option>
                            {LIABILITIES_TYPES?.map((state) => (
                              <option key={state.value} value={state.value}>
                                {state.name}
                              </option>
                            ))}
                          </>
                        }
                      /> : <DropDownSelect
                        fieldName="liabilityType"
                        value={task.liabilityType || ''}
                        label="Liability type"
                        errorMsg={task.errorMsgliability}
                        onChange={(val) => handleOnChangeCategoryLiability(task, val)}
                        colxs="12"
                        colsm="6"
                        colmd="6"
                        options={
                          <>
                            <option value="">Select</option>
                            {foundHomePropertyAssets ? LIABILITIES_TYPES_WITHOUT_MORTGAGE?.map((state) => (
                              <option key={state.value} value={state.value}>
                                {state.name}
                              </option>
                            )) : LIABILITIES_TYPES?.map((state) => (
                              <option key={state.value} value={state.value}>
                                {state.name}
                              </option>
                            ))}
                          </>
                        }
                      />}

                      {
                        task.liabilityType === LIABILITY_VEHICAL_LOAN_OPTION && purchaseSource === "Dealer" && (
                          <Form.Group className="redux-render-field" controlId="reduxformCheckbox">
                            <div className="redux-render-checkboxfield">
                              <label className={cls('checkbox-container finances-form-label')}>
                                {"Trade In"}
                                <input checked={task.payout} onChange={(val) => handleCheckboxChange(task, val)} type="checkbox" name="payout"
                                  id="payout" />
                                <span className="checkmark ml-2  mt-13" />
                              </label>
                            </div>
                          </Form.Group>
                        )}
                    </Grid>

                    {(!!relatedAssetsOptions.length && task.relatable) && (<Grid item xs={12} md={4}>
                      <DropDownSelect
                        fieldName="relatedAsset"
                        value={task.relatedAsset || ''}
                        label="Related Asset type"
                        errorMsg={task.errorMsgRelatedAssets}
                        isDisable={true}
                        onChange={(val) => handleOnChangeCategoryRelLiablity(task, val)}
                        colxs="12"
                        colsm="6"
                        colmd="6"
                        options={
                          <>
                            <option value="">Select</option>
                            {relatedAssetsOptions?.map((state) => (
                              <option key={state.value} value={state.value}>
                                {state.name}
                              </option>
                            ))}
                          </>
                        }
                      />
                    </Grid>)}

                    <Grid item xs={12} md={4}>
                      <DropDownSelect
                        fieldName="financier"
                        value={task.financier || ''}
                        label="Financier"
                        errorMsg={task.errorMsgfinancier}
                        onChange={(val) => handleOnChangeFinancier(task, val)}
                        colxs="12"
                        colsm="6"
                        colmd="6"
                        disabled={task.category === LIABILITY_TAX_DEBT_OPTION}
                        options={
                          <>
                            <option value="">Select</option>
                            {getFinanceOptions(task.liabilityType)?.map((state) => (
                              <option key={state.value} value={state.value}>
                                {state.name}
                              </option>
                            ))}
                          </>
                        }
                      />
                    </Grid>
                    {(task.liabilityType === LIABILITY_CREDIT_CARD_OPTION ||
                      task.liabilityType === LIABILITY_OVERDRAFT_OPTION) && (
                        <Grid item xs={12} md={4}>
                          <NumberInput name={"Limit"}
                            value={task.limit}
                            onChange={(values) => handleChangeLimit(task, values)}
                            error={task.errorMsgLimit} />
                        </Grid>
                      )}

                    <Grid item xs={12} md={4}>
                      <NumberInput name={"Outstanding balance"}
                        value={task.outstanding}
                        onChange={(values) => handleChangeOutstanding(task, values)}
                        error={task.errorMsgOutstanding} />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <NumberInput name={"Repayments"}
                        value={task.repayments}
                        onChange={(values) => handleChangeRepayment(task, values)}
                        error={task.errorMsgRepayment} />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Form.Group className='button-container'>
                        <Form.Label className="finances-form-label label-padding">Repayments frequency</Form.Label>
                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                          {FREQ_OPTIONS.map((item, index) => {
                            return (<Button onClick={() => { onClickFrequency(task, item.name) }} key={`button_${index}`} className={`btn-freq-group freq-show-finance ${item.name === task.frequency ? 'selectedButton' : ''}`}>{item.name}</Button>)
                          })}
                        </ButtonGroup>
                      </Form.Group>
                    </Grid>
                  </Grid>
                </td>
              </tr>
            ))}

            {liabilities.length > 0 && (
              <tr className="finances-card-table-row">
                <td className="finances-card-table-total label-font" colSpan={(smScreen && 2) || 1}>
                  Total liabilities
                </td>
                <td
                  className="finances-card-table-total finance-card-total-align "
                  colSpan={(smScreen && 2) || 1}
                >
                  <NumberFormat
                    displayType="text"
                    value={liabilitytotal}
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                  />
                </td>
              </tr>
            )}
          </tbody>
          {liabilityList && liabilityList.length !== 0 && (
            <button
              type="button"
              className="btn btn-primary finances-details-button"
              onClick={handleAddLiability}
            >
              <span className="btn-income add-liability">+ Add liability</span>
            </button>
          )}
        </Table>

        {!!showForm && (
          <LiabilityForm
            isAdd={showForm === ACTION_NEW}
            initialValues={showForm === ACTION_NEW ? { frequency: MONTH_OPTION } : showForm}
            liabilityDetails={showForm === ACTION_NEW ? '' : showForm}
            onUpdateLiability={handleUpdate}
            onCancel={handleCancel}
            onDelete={handleDeleteFile}
            purchaseSource={purchaseSource}
          />
        )}
        <div className=" container-footer py-3 px-3  border-top">
          <div className="col-12 d-flex justify-content-end px-0">
            <button
              type="button"
              className="btn btn-primary btn-next-page liability-next-section"
              hidden={initExpList && !pendingLiabilitySave}
              onClick={handleLiabilityDetails}
            >
              {liabilitySubmitInprogress ? (
                <CircularProgress color="inherit" size={24} thickness={2.6} />
              ) : (
                'Next'
              )}

              <span className="px-3">
                <img src={rightnormal} alt="right" className="right-icon" />
              </span>
            </button>
            <input
              type="button"
              id="btnsubmit"
              className="btn-dummy-page"
              style={{ visibility: 'hidden' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LiabilityDetails;
