import { connect } from 'react-redux';
import PolicyInfo from './PolicyInfo';
import { getValidationErrorsForFieldFunc } from '../../../util/ContainerUtilities';
import { foreseeChanges, completeExpenseSection, checkboxChange } from '../../../Actions/finances';
import { setCurrentTab, setCompleteTab } from '../../../Actions/tab';
import {
  validationErrors,
  noErrors,
  handleHTTPError,
  financeValidationerrors,
} from '../../../Actions/errorHandler';
import {
  applicants,
  reQuote,
  updateCreditQuote,
  getSelectProductGroup,
} from '../../../Actions/car';
import fieldChanged from '../../../Actions/FieldChanged';
import { setApplicationStrucutureStepper } from '../../../Actions/stepperDetails';

const mapStateToProps = (state) => ({
  expenseCompleted: state.applicants.expenseCompleted,
  foreseeSelected: state.applicants.foreseeSelected,
  termNconditionChk: state.applicants.termNconditionChk,
  previousFinance: state.applicants.previousFinance,
  liabilities: state.applicants.liabilities || [],
  assets: state.applicants.assets || [],
  income: state.applicants.income,
  brokerUpdatedAsssets: state.applicants.brokerUpdated,
  brokerUpdatedLiability: state.applicants.brokerUpdated,
  expenses: state.applicants.expenses || [],
  residentialStatus: state.applicants.residentialStatus,
  relationshipStatus: state.applicants.relationship,
  isSpousePropOwner: state.applicants.isSpousePropOwner,
  foreseeFinancialChangesDescription: state.applicants.foreseeFinancialChangesDescription,
  financesSubmitInprogress: state.applicants.financesSubmitInprogress,
  updateCreeditQuoteInProgress: state.account.updateCreeditQuoteInProgress,
  progressiveQuoteSubmitted: state.applicants.progressiveQuoteSubmitted,
  getValidationErrorsForField: getValidationErrorsForFieldFunc(),
  selectedLender: state.applicants.selectedLender,
  applicationStructure: state.applicants.applicationStructure,
  opportunityId: state.applicants.opportunityId,
});

const mapDispatchToProps = (dispatch) => ({
  completeExpenseSection: () => {
    dispatch(completeExpenseSection());
  },
  updateCreditQuote: () => dispatch(updateCreditQuote()),
  onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(fieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage));
  },
  onCheckboxChange: (value) => {
    dispatch(checkboxChange(value));
  },
  setTabComplete: (tabName) => {
    dispatch(setCompleteTab(tabName));
  },
  noErrors: () => dispatch(noErrors()),
  validationCheck: (error, category) => dispatch(validationErrors(error, category)),
  applicants: (carSalesFlag = false, pqsubmitVal = true) => dispatch(applicants(carSalesFlag, pqsubmitVal)),
  handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
  financeValidationerrors: (error, props) => dispatch(validationErrors(error, 'INCOMES')),
  reQuote: () => dispatch(reQuote()),
  isSelectProductGroupFetched: () => dispatch(getSelectProductGroup()),
  setApplicationStrucutureStepper: (applicationStructure) =>
    dispatch(setApplicationStrucutureStepper(applicationStructure)),
});

const ExpenseFinanceTerms = connect(mapStateToProps, mapDispatchToProps)(PolicyInfo);

export default ExpenseFinanceTerms;
