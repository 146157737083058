import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { PAGE_TITLE } from '../util/constants';

function PageTitle() {
  const location = useLocation();

  useEffect(() => {
    document.title = PAGE_TITLE[location.pathname] || PAGE_TITLE.default; // lodash version
  }, [location.pathname]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <></>;
}

export default PageTitle;
