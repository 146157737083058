import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { CircularProgress, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Car from '../../../assets/image/Car.svg';
import logo from '../../../assets/image/stratton-logo-transparent.png';
import rightnormal from '../../../assets/image/TArrow-Right.svg';
import listicon from '../../../assets/image/TArrow-RightBlue.svg';
import { setApplicantId } from '../../Actions/authHandler';
import { clearLeadValues } from '../../Actions/car';
import { liteFieldChanged } from '../../Actions/FieldChanged';
import Copyright from '../../components/Copyright';
import InputButton from '../../components/InputButton';
import lock from '../../../assets/image/LoginIcon.svg';
import Header from '../../components/Header/Header';
import {
  ASSET_TYPES,
  CAR_ASSET,
  COMMERCIAL_EQUIPMENT,
  otherVehicle,
  ROUTES,
  UNSECURED_LOAN,
  PARTNER_IMAGE_LISTS,
} from '../../util/constants';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import processGTMevent from '../../util/processGTMEvent';
import queryString from '../../util/queryString';
import './context-pages.scss';
import UserDropDown from '../../components/Header/UserDropdown/UserDropDown';

const EstimateRepayments = ({
  leadId,
  partnername,
  onInputChange,
  onInputChangeCar,
  getValidationErrorsForField,
  assetType,
  setApplicantId,
  isSalesforceCreatedOpportunity,
  clearLeadValues,
  code,
  isAuthenticated,
  isPQProductSelectionGroup,
}) => {
  const [error, setError] = useState(null);
  const [inProgress, setProgress] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(768));

  const contactRedirectPage = () => {
    const params = {
      utm_source: queryString('utm_source'),
      utm_campaign: queryString('utm_campaign'),
      utm_medium: queryString('utm_medium'),
      rcid: queryString('rcid'),
    };
    var queryStringValue = Object.keys(params)
      .filter((key) => params[key] !== null)
      .map((key) => key + '=' + encodeURIComponent(params[key]))
      .join('&');
    window.location.href =
      'https://www.strattonfinance.com.au/contact-us' +
      (queryStringValue ? '?' + queryStringValue : '');
  };
  const onContactClick = () => {
    processGTMevent('PQStart', 'start', 'contact-us');
    contactRedirectPage();
  };
  const findPartnerIcon = (partnername) => {
    const foundPartnerIcon = PARTNER_IMAGE_LISTS.find(
      (e) => e.id.toLowerCase() === partnername?.toLowerCase(),
    );
    if (foundPartnerIcon) {
      return foundPartnerIcon.value;
    }
    return null;
  };
  const partnerflag = findPartnerIcon(partnername) === null ? true : false;

  useEffect(() => {
    if (!isAuthenticated) clearLeadValues();
    if (code) {
      setApplicantId(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const getDomainName = () => {
    var referrer = document.referrer;
    var domainName;
    var currentDomainName = window.location.hostname;
    // Use a regular expression to extract the domain name
    var match = referrer.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    // Create a regular expression pattern to match the specified characters
    var pattern = new RegExp('stratton');
    // Check if the domainName contains the specified characters and return the opposite
    var result = pattern.test(referrer);
    if (
      match != null &&
      match.length > 2 &&
      typeof match[2] === 'string' &&
      match[2].length > 0 &&
      (match[2] !== currentDomainName) & (result == false)
    ) {
      domainName = match[2];
    } else {
      domainName = null;
    }
    return domainName;
  };

  const queryStringVal = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const cookievalue = [
      {
        source: urlParams.get('utm_source'),
        campaign: urlParams.get('utm_campaign'),
        medium: urlParams.get('utm_medium'),
        referrer: getDomainName(),
      },
    ];
    return cookievalue;
  };

  function setCookieWithArray(name, array, daysToExpire) {
    const date = new Date();
    date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    const jsonValue = JSON.stringify(array);
    let cookieDomain =
      window.location.hostname
        .split('.')
        .slice(1)
        .join('.') || window.location.hostname;
    document.cookie = name + '=' + jsonValue + '; ' + expires + ' ;path=/; domain=' + cookieDomain;
  }

  function getCookieArray(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const parts = cookie.split('=');
      if (parts[0].trim() === name) {
        return parts[1];
      }
    }
    return [];
  }

  function checkCookie() {
    let cookieData = getCookieArray('UTM_Data_Cookie');
    if (cookieData.length === 0) {
      setCookieWithArray('UTM_Data_Cookie', queryStringVal(), 365);
    } else if (cookieData.length > 0) {
      cookieData = JSON.parse(cookieData);
      const currentQueryStringValue = queryStringVal();
      if (
        currentQueryStringValue[0].source === null &&
        currentQueryStringValue[0].campaign === null &&
        currentQueryStringValue[0].medium === null
      ) {
        setCookieWithArray('UTM_Data_Cookie', cookieData, 365);
      } else if (currentQueryStringValue[0].referrer === null && cookieData[0].referrer !== null) {
        const cookieDataValue = [
          {
            source: currentQueryStringValue[0].source,
            campaign: currentQueryStringValue[0].campaign,
            medium: currentQueryStringValue[0].campaign,
            referrer: cookieData[0].referrer,
          },
        ];
        setCookieWithArray('UTM_Data_Cookie', cookieDataValue, 365);
      } else {
        setCookieWithArray('UTM_Data_Cookie', currentQueryStringValue, 365);
      }
    }
  }

  window.onload = function() {
    checkCookie();
  };

  useEffect(() => {
    processGTMevent('car', 'pageLoad', 'carStartPageLoad');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  const onLogoClick = () => {
    window.open('https://www.strattonfinance.com.au/');
  };
  const handleClick = () => {
    onInputChangeCar('assetType', 'car', false); //remove after testing is done
    setProgress(true);
    processGTMevent('car', 'start', 'started');

    if (leadId) {
      onInputChange('leadId', leadId, false);

      axios({
        method: 'post',
        url: window.env.REACT_APP_CARSALES_AUTHCHALLENGE_URL,
        data: {
          leadid: leadId,
        },
      })
        .then((response) => {
          setProgress(false);
          onInputChange('tokenId', response.data.SessionId, false);
          history.push(ROUTES.verifyOTP);
          setError(null);
        })
        .catch(() => {
          history.push(ROUTES.login);
          setProgress(false);
        });
    } else {
      history.push(ROUTES.initialDetails);
    }
  };

  const handleAssetChange = (fieldName, value) => {
    processGTMevent('PQ-AssetType', 'PQStart-AssetType', value);
    let navigateToPath = null;
    const isOtherVehicle = otherVehicle.find((e) => e === value) || false;
    const updatedAssetType = isOtherVehicle
      ? isOtherVehicle?.toLowerCase().replace(/\s+/g, '')
      : null;
    onInputChangeCar(fieldName, value, false);

    switch (value) {
      case COMMERCIAL_EQUIPMENT:
        navigateToPath = history.push(ROUTES.commercialAssetType);
        break;
      case UNSECURED_LOAN:
        navigateToPath = history.push(ROUTES.unsecuredloan);
        break;
      case isOtherVehicle:
        navigateToPath = history.push(ROUTES[updatedAssetType.toLowerCase()]);
        break;
      case CAR_ASSET:
        navigateToPath = history.push(`${ROUTES.initialDetails}#${value}`);
        break;

      default:
        navigateToPath = history.push(`${ROUTES.initialDetails}#${value}`);
        break;
    }
    return navigateToPath;
  };

  const loginPage = () => {
    history.push(ROUTES.login);
  };

  return (
    <>
      <div className="estimateRepayments-container">
        <div className="estimateRepayments-section col-12 col-md-4 col-lg-4">
          <div className="stratton-info-nav-container pt-2">
            <img
              src={logo}
              alt="Stratton"
              className={[!partnerflag ? 'stratton-logo' : 'stratton-info-logo']}
              onClick={onLogoClick}
              width="200px"
            />
            {leadId && (
              <div className={partnerflag ? 'partnerheader' : 'partner-logo'}>
                <img
                  src={findPartnerIcon(partnername)}
                  alt="Partner logo"
                  width="120px"
                  height="40px"
                />
              </div>
            )}
            <div className="step-content-section">
              <div className="nav-animation">
                <div className="nav-animation-line">
                  <img src={Car} className="nav-animation-car" alt="car" />
                </div>
              </div>
              <div className="stepper-contents">
                <div className="steps-section">
                  <div className="step enabled">STEP 1</div>
                  <div className="step-message enabled ">Repayments estimate</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 2</div>
                  <div className="step-message disabled">Compare loan options</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 3</div>
                  <div className="step-message disabled">Get approved</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 4</div>
                  <div className="step-message disabled">Prepare for Settlement</div>
                </div>
              </div>
            </div>
          </div>
          <div className="stratton-info-footer-container">
            {/* <div className="estimatedetails_bgImage">
              <img src={estimationBackground} className="nav-estimate" alt="car" />
            </div> */}
            <Copyright />
          </div>
        </div>
        <div className="estimateRepayments-page-details col-12 col-md-8 col-lg-8">
          {!mobileView ? (
            isAuthenticated ? (
              <div className="header-right-align ">
                <div className="call-details">
                  <div className="call-us">Speak to an expert</div>
                  {isPQProductSelectionGroup ? (
                    <div className="phone">1300 416 707</div>
                  ) : (
                    <div className="phone">1300 787 288</div>
                  )}
                </div>
                <div>
                  <UserDropDown />
                </div>
              </div>
            ) : (
              <>
                <div className="header-left-align pt-3">
                  <div className="call-details">
                    <div className="call-us">Call us on</div>
                    <div className="phone">1300 787 288</div>
                  </div>
                  <div className="login-button1">
                    <button onClick={loginPage} data-test="loginpage-header" className="btn-login1">
                      <img src={lock} alt="login" className="lock-icon1" />
                      <span className="login-label1">Login</span>
                    </button>
                  </div>
                </div>
              </>
            )
          ) : (
            <div
              className={
                partnerflag ? 'stratton-info-container' : 'stratton-info-nav-container-mobile-view'
              }
            >
              <div
                className={partnerflag ? 'stratton-logo-container' : 'stratton-logo-mobile-view'}
              >
                <img
                  src={logo}
                  alt="Stratton"
                  className="stratton-logo-mobile-view"
                  onClick={onLogoClick}
                />
              </div>
              {leadId && (
                <div className={partnerflag ? 'partnerheader' : 'partner-logo-mobile-view'}>
                  <img
                    src={findPartnerIcon(partnername)}
                    alt="Partner logo"
                    className="partner-logo-mobile-view"
                  />
                </div>
              )}
              <div className="login-button1 login-button-mobileView">
                <button onClick={loginPage} data-test="loginpage-header" className="btn-login1 ">
                  <img src={lock} alt="login" className="lock-icon1" />
                  <span className="login-label1">Login</span>
                </button>
              </div>
            </div>
          )}
          {isSalesforceCreatedOpportunity ? (
            <div className="estimateRepayments-message">
              <div className="estimateRepayments-name">Let&apos;s get started!</div>

              <div className="estimateRepayments-title">Review your information</div>

              <div className="estimateRepayments-subtitle">
                Please review and confirm important information for your Stratton Finance loan
                application.
              </div>

              <div className="email-quote py-3">
                <button type="button" className="letsgetstarted-btn mb-3" onClick={handleClick}>
                  {inProgress ? (
                    <CircularProgress color="inherit" size={24} thickness={2.6} />
                  ) : (
                    'Click here to continue'
                  )}

                  <span className="pl-4">
                    <img src={rightnormal} alt="right" className="right-icon" />
                  </span>
                </button>
              </div>

              <div className="review-information">
                <div className="estimateRepayments-ratelabel">
                  <CheckCircleOutlineIcon className="check-circle-outline-color" />
                  <span className="getrate">No Impact on credit scrore</span>
                </div>
                <div className="estimateRepayments-ratelabel">
                  <CheckCircleOutlineIcon className="check-circle-outline-color" />
                  <span className="getrate">No upfront fees</span>
                </div>
                <div className="estimateRepayments-ratelabel">
                  <CheckCircleOutlineIcon className="check-circle-outline-color" />
                  <span className="getrate">Quick turn around time</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="estimateRepayments-message">
              <div className="estimateRepayments-name">Let&apos;s get started!</div>
              <div className="estimateRepayments-title">Estimate your repayments</div>
              {leadId ? (
                <>
                  <div className="estimateRepayments-subtitle">
                    It takes less than 2 minutes, we just need some details about the car
                    you&apos;re looking to buy.
                  </div>
                  <div className="estimate-Repayments-quote">
                    <div className="estimateRepayments-ratelabel">
                      <img src={listicon} alt="right" className="right-icon" />
                      <span className="getrate">Car details</span>
                    </div>
                    <div className="estimateRepayments-ratelabel">
                      <img src={listicon} alt="right" className="right-icon" />
                      <span className="getrate">Loan details</span>
                    </div>
                  </div>
                  <div className="email-quote py-3">
                    <button type="button" className="letsgetstarted-btn" onClick={handleClick}>
                      {inProgress ? (
                        <CircularProgress color="inherit" size={24} thickness={2.6} />
                      ) : (
                        'Get started'
                      )}

                      <span className="pl-4">
                        <img src={rightnormal} alt="right" className="right-icon" />
                      </span>
                    </button>
                  </div>
                </>
              ) : (
                <div className="asset-type-selector-container">
                  <InputButton
                    label={
                      <span className="label">What type of asset are you planning to buy?</span>
                    }
                    fields={ASSET_TYPES}
                    onChange={handleAssetChange}
                    fieldName="assetType"
                    value={assetType}
                    btnClass="full-screen-mode"
                    getvalidationerrors={getValidationErrorsForField}
                  />
                  <div className="text-container">
                    <Grid container spacing={3}>
                      <Grid item className="gridItemContainer">
                        <CheckCircleOutlineIcon className="icon" />
                        <span className="label"> No Impact on credit score</span>
                      </Grid>
                      <Grid item className="gridItemContainer">
                        {' '}
                        <CheckCircleOutlineIcon className="icon" />
                        <span className="label"> No upfront fees</span>
                      </Grid>
                      <Grid item className="gridItemContainer">
                        {' '}
                        <CheckCircleOutlineIcon className="icon" />
                        <span className="label"> Quick turn around time</span>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Grid container className="mt-4 pb-5">
                      <Grid item xs={mobileView ? 12 : 7}>
                        <div
                          className={
                            !mobileView
                              ? 'contact-header'
                              : 'contact-header contact-header-mobileView'
                          }
                        >
                          Not ready to get a quote and want to discuss your finance options?
                        </div>
                        <div
                          className={
                            !mobileView
                              ? 'contact-subheading'
                              : 'contact-subheading contact-header-mobileView'
                          }
                        >
                          Leave your details and we’ll be in touch.
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={mobileView ? 12 : 5}
                        className={
                          !mobileView
                            ? 'contact-button-container'
                            : 'contact-button-container contact-button-container-mobileView'
                        }
                      >
                        <Grid className="contact-button">
                          <button
                            type="button"
                            className="btn btn-outline-dark  buttonContainer"
                            onClick={onContactClick}
                          >
                            Contact Us <i className="fa fa-chevron-right pl-2" />
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Copyright mobileFooter />
    </>
  );
};

export default connect(
  (state) => ({
    leadId: state.car.leadId,
    code: state.car.code,
    partnername: state.car.referralChannelSource,
    assetType: state.car.assetType,
    getValidationErrorsForField: getValidationErrorsForFieldFunc(state.applicants.validationErrors),
    leadId: state.car.carSalesLead || state.account.leadId,
    isSalesforceCreatedOpportunity: state.account.isSalesforceCreatedOpportunity,
    isAuthenticated: state.account.isAuthenticated,
    isPQProductSelectionGroup: state.applicants.isPQProductSelectionGroup,
  }),
  (dispatch) => ({
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) =>
      dispatch(liteFieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage)),
    onInputChangeCar: (fieldName, value, scrollToNextFieldOnChage) =>
      dispatch(liteFieldChanged('car', fieldName, value, scrollToNextFieldOnChage)),
    setApplicantId: (token) => dispatch(setApplicantId(token)),
    clearLeadValues: () => dispatch(clearLeadValues()),
  }),
)(withRouter(EstimateRepayments));
