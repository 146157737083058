export const UPPERCASE_CHARS = new RegExp('^(?=.*[A-Z])');
export const LOWERCASE_CHARS = new RegExp('^(?=.*[a-z])');
export const NUMERIC_CHARS = new RegExp('^(?=.*[0-9])');
export const PASSWORD_LENGTH = new RegExp('^(?=.{8,})');
export const MEDIUM_REGEX = new RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})',
);
export const PASSWORD_CHECK_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

export const getMaskedNumber = (number) => {
  const endDigits = number.slice(-3);
  return endDigits.padStart(number.length, '*');
};
