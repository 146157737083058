/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
import capitalize from 'lodash/capitalize';
import remove from 'lodash/remove';
import filter from 'lodash/filter';
import unionBy from 'lodash/unionBy';

import BaseReducer from './BaseReducer';
import requiredRule from '../ValidationRules/RequiredRule';
import {
  POPULATE_CAR_DETAILS,
  POPULATE_REGOVEHICLEDETAILS,
  POPULATE_YEARS,
  POPULATE_CARMAKES,
  POPULATE_CARMODELS,
  POPULATE_CARSERIES,
  POPULATE_CARVARIANTS,
  POPULATE_FACTORYOPTIONS,
  REFERRAL_DATA,
  CLEAR_REGOVEHICLEDETAILS,
  CAR_PURPOSE_CHANGED,
  CLEAR_LEAD_VALUES,
} from '../Actions/car';
import { ASSET_TYPES, otherVehicle, TOP_CAR_MAKES } from '../util/constants';

class Car extends BaseReducer {
  constructor() {
    super('CAR');
  }

  getInitialState() {
    return {
      validationRules: [
        {
          fieldName: 'carCondition',
          rules: [requiredRule('carCondition', 'Please provide value')],
        },
        {
          fieldName: 'regoNumber',
          rules: [requiredRule('regoNumber', 'Required')],
        },
        {
          fieldName: 'regoState',
          rules: [requiredRule('regoState', 'Required')],
        },
        {
          fieldName: 'purchaseSource',
          rules: [requiredRule('purchaseSource', 'Required')],
        },
        {
          fieldName: 'purchaseUse',
          rules: [requiredRule('purchaseUse', 'Required')],
        },
        {
          fieldName: 'purchasePrice',
          rules: [requiredRule('purchasePrice', 'Required')],
        },
        {
          fieldName: 'manufacturingYear',
          rules: [requiredRule('manufacturingYear', 'Required')],
        },
      ],
      progress: 0,
      activeFields: this.getInitialActiveFields(),
      sharedFields: [],
      validationErrors: [],
      maxloanamt: 60000,
      purchasePrice: 50000,
      estimatedLoan: 50000,
      depositAmount: 0,
      carPurposehasChanged: false,
      prefillRecords: false,
    };
  }

  getInitialActiveFields() {
    return [];
  }

  getNextFieldNameToFocus(changedFieldName, newValue, nextState) {
    let nextFieldName = null;

    const isOtherVehicle = otherVehicle.find((e) => e === nextState.assetType) || false;

    //BOAT,CARAVAN,BIKE
    if (isOtherVehicle) {
      switch (changedFieldName) {
        case 'carCondition':
          nextFieldName = 'year-manufactured';
          break;
        case 'skippedManufacturingYear':
        case 'manufacturingYear':
        case 'vehicleMake':
        case 'vehicleModel':
          nextFieldName = 'vehicleMakeModel';
          break;
        case 'skipVehicleMakeModel':
        case 'vehicleMakeModel':
          nextFieldName = 'car-purchase-from';
          break;
        case 'purchaseSource':
          nextFieldName = 'car-usage';
          break;
        case 'purchaseUse':
          nextFieldName = 'car-purchase-price';
          break;
        case 'depositPriceComplete':
          nextFieldName = 'car-loan-term';
          break;
        default:
          break;
      }
    }

    //PERSONAL LOAN
    if (nextState.assetType === 'Unsecured Loan') {
      switch (changedFieldName) {
        case 'estimatedLoanComplete':
          nextFieldName = 'car-loan-term';
          break;
        default:
          break;
      }
    }

    //CAR
    if (nextState.assetType === 'car' || nextState.assetType === 'Car') {
      switch (changedFieldName) {
        case 'carCondition':
          if (newValue === 'Used') {
            nextFieldName = 'rego';
          } else if (newValue === 'New' || newValue === 'Demo') {
            nextFieldName = 'car-manufactured-year';
          } else if (newValue === 'Unsure') {
            nextFieldName = 'car-purchase-price';
          }
          break;
        case 'manualEnter':
          nextFieldName = 'car-manufactured-year';
          break;
        case 'vehicleVariant':
          nextFieldName = 'car-factory-options';
          break;
        case 'factoryOptionsComplete':
        case 'searchComplete':
        case 'nofactoryOptions':
          if (nextState.carCondition === 'New') {
            nextFieldName = 'car-usage';
          } else {
            nextFieldName = 'car-purchase-from';
          }
          break;
        case 'purchaseSource':
          nextFieldName = 'car-usage';
          break;
        case 'searchCar':
          nextFieldName = 'car-applicants-main-rego';
          break;
        case 'purchaseUse':
          if (nextState.carCondition === 'Unsure') {
            nextFieldName = 'car-loan-term';
          } else {
            nextFieldName = 'car-purchase-price';
          }
          break;
        case 'purchasePriceComplete':
          nextFieldName = 'deposit';
          break;
        case 'depositPriceComplete':
          if (nextState.carCondition === 'Unsure') {
            nextFieldName = 'car-manufactured-year';
          } else {
            nextFieldName = 'car-loan-term';
          }
          break;
        case 'manufacturingYear':
          if (nextState.carCondition === 'Unsure') {
            nextFieldName = 'car-purchase-from';
          } else {
            nextFieldName = 'car-make';
          }
          break;
        case 'vehicleMake':
          nextFieldName = 'car-model';
          break;
        case 'vehicleModel':
          // to be reverted after model fix
          // nextFieldName = 'car-series';
          // break;
          if (nextState.carCondition === 'New') {
            nextFieldName = 'purchaseUse';
          } else {
            nextFieldName = 'purchaseSource';
          }
          break;
        case 'vehicleSeries':
          nextFieldName = 'car-variant';
          break;

        case 'skippedVehicleDetails':
        case 'skipModel':
        case 'skipSeries':
        case 'skipVariant':
          if (nextState.carCondition === 'New') {
            nextFieldName = 'purchaseUse';
          } else {
            nextFieldName = 'purchaseSource';
          }
          break;
        default:
          break;
      }
    }

    //COMMERCIAL FINANCE
    if (nextState.assetType === 'Commercial Equipment') {
      switch (changedFieldName) {
        case 'manufacturingYear':
          nextFieldName = 'commercial-type';
          break;
        case 'commercialType':
          nextFieldName = 'make-model';
          break;
        case 'vehicleMake':
        case 'vehicleModel':
          nextFieldName = 'vehicleMakeModel';
          break;
        case 'vehicleMakeModel':
        case 'skipmakeModel':
          nextFieldName = 'purchaseSource';
          break;
        case 'purchaseSource':
          nextFieldName = 'car-purchase-price';
          break;
        case 'purchasePriceComplete':
        case 'depositPriceComplete':
          nextFieldName = 'car-loan-term';
          break;

        default:
          break;
      }
    }
    return nextFieldName;
  }

  getDependentFields(fieldName) {
    let fields;

    switch (fieldName) {
      case 'carCondition':
        fields = [
          'regoNumber',
          'regoState',
          'manufacturingYear',
          'vehicleMake',
          'vehicleModel',
          'vehicleSeries',
          'vehicleEngine',
          'vehicleVariant',
          'transmission',
          'vehicleBodyType',
          'vinNumber',
          'searchCar',
          'searchComplete',
          'odometer',
          'purchaseSource',
          'purchaseUse',
          'purchasePriceComplete',
          'depositPriceComplete',
          'odometerval',
          'skippedVehicleDetails',
          'skipModel',
          'skipSeries',
          'skipVariant',
          'manualEnter',
          'factoryOptionsComplete',
          'nvic',
          'retail',
          'rrp',
          'trade',
        ];
        break;

      case 'searchComplete':
        fields = ['odometer'];
        break;
      case 'odometer':
        fields = ['purchaseSource'];
        break;
      case 'purchaseSource':
        fields = ['purchaseUse'];
        break;
      case 'manualEnter':
        fields = [
          'manufacturingYear',
          'vehicleMake',
          'vehicleModel',
          'vehicleSeries',
          'vehicleEngine',
          'vehicleVariant',
          'skippedVehicleDetails',
          'skipModel',
          'skipSeries',
          'skipVariant',
          'odometer',
          'odometerval',
          'purchaseSource',
          'purchaseUse',
          'purchasePriceComplete',
          'depositPriceComplete',
          'retail',
          'rrp',
          'trade',
          'transmission',
          'vehicleBodyType',
          'vinNumber',
          'nvic',
        ];
        break;
      case 'vehicleMake':
        fields = [
          'vehicleModel',
          'vehicleSeries',
          'vehicleEngine',
          'vehicleVariant',
          'skippedVehicleDetails',
          'skipModel',
          'skipSeries',
          'skipVariant',
          'odometer',
          'odometerval',
          'purchaseSource',
          'purchaseUse',
          'purchasePriceComplete',
          'depositPriceComplete',
          'retail',
          'rrp',
          'trade',
          'transmission',
          'vehicleBodyType',
          'vinNumber',
          'nvic',
        ];
        break;
      case 'vehicleModel':
        fields = [
          'vehicleSeries',
          'vehicleEngine',
          'vehicleVariant',
          'skipModel',
          'skipSeries',
          'skipVariant',
          'purchaseSource',
          'purchaseUse',
          'purchasePriceComplete',
          'depositPriceComplete',
          'retail',
          'rrp',
          'trade',
          'transmission',
          'vehicleBodyType',
          'vinNumber',
          'nvic',
        ];
        break;
      case 'vehicleSeries':
        fields = [
          'vehicleEngine',
          'vehicleVariant',
          'skipSeries',
          'skipVariant',
          'purchaseSource',
          'purchaseUse',
          'purchasePriceComplete',
          'depositPriceComplete',
          'retail',
          'rrp',
          'trade',
          'transmission',
          'vehicleBodyType',
          'vinNumber',
          'nvic',
        ];
        break;

      case 'purchasePrice':
        fields = ['purchasePriceComplete', 'depositPriceComplete', 'manufactureYear'];
        break;
      case 'vehicleVariant':
        fields = [
          'skipVariant',
          'purchaseSource',
          'purchaseUse',
          'factoryOptions',
          'factoryOptionsComplete',
        ];
        break;
      default:
        fields = null;
    }

    return fields;
  }

  getNewActiveFields() {
    return this.getInitialActiveFields();
  }

  getNextTabUrl() {
    return 'loan';
  }

  reduce(state = this.getInitialState(), action) {
    if (action.type === POPULATE_CAR_DETAILS) {
      let searchComplete = false;
      let factoryOptionsComplete = false;
      let purchasePriceComplete = false;
      let depositPriceComplete = false;
      let searchCar = false;
      if (action.carDetails.purchasePrice) {
        purchasePriceComplete = true;
      }

      if (
        action.carDetails.depositAmount === 0 ||
        action.carDetails.depositAmount === null ||
        action.carDetails.depositAmount
        // action.carDetails.vehicleCondition === 'Unsure'
      ) {
        depositPriceComplete = true;
      }
      if (
        action.carDetails.vehicleCondition === 'Used' &&
        (action.carDetails.regoNumber || action.carDetails.regoState)
      ) {
        searchCar = true;
      }

      if (state.searchCar || searchCar) {
        searchComplete = true;
      }
      if (action.carDetails.factoryOptions === null || action.carDetails.factoryOptions) {
        factoryOptionsComplete = true;
      }

      // Populate car details
      let skipVehicleMake = false;
      let skipVehicleModel = false;
      let skipVehicleSeries = false;
      let skipVehicleVariant = false;
      // Used Car enter manually
      const enterManually =
        action.carDetails.vehicleCondition === 'Used' &&
        !action.carDetails.regoNumber &&
        !action.carDetails.regoState;
      // New Car OR Demo car Or Used and Manual
      if (
        ((action.carDetails.vehicleCondition === 'New' ||
          action.carDetails.vehicleCondition === 'Demo') &&
          action.carDetails.year) ||
        enterManually
      ) {
        skipVehicleMake = !action.carDetails.make;
        skipVehicleModel = !action.carDetails.make || !action.carDetails.model;
        skipVehicleSeries = skipVehicleModel || !action.carDetails.series;
        skipVehicleVariant = skipVehicleSeries || !action.carDetails.variant;
      }

      const findAssetType = ASSET_TYPES.find((e) => {
        return e.value?.toUpperCase() == action?.carDetails?.assetType?.toUpperCase();
      });

      return {
        ...state,
        carDetailsChanged: false, // to keep track of completed section
        carCondition: action.carDetails.vehicleCondition,
        assetType: action.carDetails.assetType,
        assetSubType: action.carDetails.assetSubType,
        assetTypeName: capitalize(findAssetType?.name.toLowerCase()),
        factoryOptions:
          action.carDetails.factoryOptions &&
          action.carDetails.factoryOptions.map((option) => ({ ...option, selected: true })),
        manufacturingYear: action.carDetails.year,
        manualEnter: enterManually,
        vehicleMake:
          (action.carDetails.make && {
            name: action.carDetails.make,
            value: action.carDetails.makeCode,
          }) ||
          action.carDetails.make,
        vehicleModel:
          (action.carDetails.model && {
            name: action.carDetails.model,
            value: action.carDetails.modelCode,
          }) ||
          action.carDetails.model,
        vehicleSeries:
          (action.carDetails.series && {
            name: action.carDetails.series,
            value: action.carDetails.series,
          }) ||
          capitalize(action.carDetails.series),
        vehicleVariant:
          (action.carDetails.variant && {
            name: action.carDetails.variant,
            value: action.carDetails.nvic,
          }) ||
          action.carDetails.variant,
        manufacturingYear: action.carDetails?.year,
        transmission: capitalize(action.carDetails.transmission),
        vehicleBodyType: capitalize(action.carDetails.bodyType),
        vehicleEngine: capitalize(action.carDetails.engineType),
        // Cehicle Skipped flags
        skippedVehicleDetails: skipVehicleMake,
        skipModel: skipVehicleModel,
        skipSeries: skipVehicleSeries,
        skipVariant: skipVehicleVariant,
        // odometer: "59456"
        purchaseSource: action.carDetails.purchaseSource,
        regoNumber: action.carDetails.regoNumber,
        regoState: action.carDetails.regoState,
        purchaseUse: action.carDetails.purposeOfUse,
        purchasePrice: !isNaN(action.carDetails.purchasePrice)
          ? Number(action.carDetails.purchasePrice)
          : 50000,
        depositAmount: !isNaN(action.carDetails.depositAmount)
          ? Number(action.carDetails.depositAmount)
          : 0,
        searchComplete,
        searchCar,
        factoryOptionsComplete,
        purchasePriceComplete,
        depositPriceComplete,
        leadSource: action.carDetails.leadSource,
        commercialType: action.carDetails.assetSubType || '',
        skipcommercialType: !!action.carDetails.commercialType,
        skipmakeModel: action.carDetails.make,
        vehicleMakeModel: !!action.carDetails.make && !!action.carDetails.model,
        customDashboard: true,
      };
    }
    if (action.type === POPULATE_REGOVEHICLEDETAILS) {
      return {
        ...state,

        manufacturingYear: action.factorydata.year,
        transmission: capitalize(action.factorydata.transmission),
        vehicleBodyType: capitalize(action.factorydata.body),
        vehicleMake: capitalize(action.factorydata.make),
        vehicleMakeCode: action.factorydata.makeCode?.toUpperCase(),
        vehicleModel: capitalize(action.factorydata.model),
        vehicleModelCode: action.factorydata.modelCode?.toUpperCase(),
        vehicleSeries: capitalize(action.factorydata.series),
        vehicleVariant: capitalize(action.factorydata.variant),
        nvicMake: capitalize(action.factorydata.make),
        nvicModel: capitalize(action.factorydata.model),
        nvicVariant: action.factorydata.series,
        nvicSeries: action.factorydata.variant,
        vehicleEngine: capitalize(action.factorydata.engine),
        nvic: action.factorydata.nvic,
        vinNumber: action.factorydata.vin,
        modelName: capitalize(action.factorydata.modelName),
        trade: action.factorydata.trade,
        rrp: action.factorydata.rrp,
        retail: action.factorydata.retail,
        factoryOptions: action.factorydata.factoryOptions,
      };
    }
    if (action.type === CLEAR_REGOVEHICLEDETAILS) {
      return {
        ...state,
        manufacturingYear: '',
        transmission: '',
        vehicleBodyType: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleSeries: '',
        vehicleVariant: '',
        nvicMake: '',
        nvicModel: '',
        nvicVariant: '',
        nvicSeries: '',
        vehicleEngine: '',
        nvic: '',
        modelName: '',
        trade: '',
        rrp: '',
        retail: '',
        vinNumber: '',
        factoryOptions: [],
      };
    }
    if (action.type === POPULATE_FACTORYOPTIONS) {
      let factoryOptions = [...action.factorydata.factoryOptions];
      if (state.factoryOptions && state.factoryOptions.length) {
        const selectedfactoryData = filter(state.factoryOptions, ['selected', true]);
        factoryOptions = unionBy(selectedfactoryData, factoryOptions, 'optionCode');
      }

      return {
        ...state,
        manufacturingYear: action.factorydata.year,
        transmission: action.factorydata.transmission,
        vehicleBodyType: action.factorydata.body,
        nvicMake: action.factorydata.make,
        nvicModel: action.factorydata.model,
        nvicVariant: action.factorydata.series,
        nvicSeries: action.factorydata.variant,
        vehicleEngine: action.factorydata.engine,
        nvic: action.factorydata.nvic,
        modelName: action.factorydata.modelName,
        trade: action.factorydata.trade,
        rrp: action.factorydata.rrp,
        vinNumber: action.factorydata.vin,
        retail: action.factorydata.retail,
        factoryOptions,
      };
    }
    if (action.type === POPULATE_YEARS) {
      return {
        ...state,
        years: action.yearData,
      };
    }
    if (action.type === POPULATE_CARMAKES) {
      const carmakesList = action.vehicleMakesData;
      const topCarMakes = remove(carmakesList, (c) => TOP_CAR_MAKES.indexOf(c.makeName) > -1);

      const vehicleMakesData = [...topCarMakes, ...carmakesList];
      return {
        ...state,
        makes:
          (vehicleMakesData &&
            vehicleMakesData.map((option) => ({
              name: capitalize(option.makeName),
              value: option.makeCode,
            }))) ||
          [],
      };
    }
    if (action.type === POPULATE_CARMODELS) {
      return {
        ...state,
        models:
          (action.vehicleModelsData &&
            action.vehicleModelsData.map((option) => ({
              name: capitalize(option.modelName),
              value: option.modelCode,
            }))) ||
          [],
      };
    }
    if (action.type === POPULATE_CARSERIES) {
      return {
        ...state,
        series:
          (action.vehicleSeriesdata &&
            action.vehicleSeriesdata.map((option) => ({
              name: capitalize(option.variantName),
              value: option.variantCode,
            }))) ||
          [],
      };
    }
    if (action.type === POPULATE_CARVARIANTS) {
      return {
        ...state,
        variants:
          (action.vehicleVariantsdata &&
            action.vehicleVariantsdata.map((option) => ({
              name: capitalize(option.modelName),
              value: option.nvicCode,
            }))) ||
          [],
      };
    }
    if (action.type === REFERRAL_DATA) {
      return {
        ...state,
        rcid: action.data.rcid || null,
        referralChannelSource: action.data.source || null,
        referralChannelMedium: action.data.medium || null,
        referralChannelCampaign: action.data.campaign || null,
        referralChannelContent: action.data.content || null,
        referralChannelCampaignterm: action.data.campaignterm || null,
        referralChannel: action.data.channel || null,
        gclid: action.data.gclid || null,
        leadId: action.data.leadId || null,
        carSalesLead: action.data.carSalesLead || null,
        savelocalStorage: Boolean(action.data.saveLocalStorage) || null,
        contactId: action.data.contactId || null,
        assetType: action.data.assetType || null,
        code: action.data.code || null,
        condition: action.data.condition || null,
        make: action.data.make || null,
        model: action.data.model || null,
        depositAmountUrl: action.data.depositAmount || null,
        year: action.data.year || null,
        purchasePriceUrl: action.data.purchasePrice || null,
        purchaseSourceUrl: action.data.purchaseSource || null,
        purchaseUseUrl: action.data.purchaseUse || null,
        term: action.data.term || null,
        prefillRecords: Boolean(action.data.make || action.data.model || action.data.year || action.data.condition) || null,
        financeRedegin: action.data.financeRedegin || null,
        finaliseCar: action.data.finaliseCar || null,
      };
    }

    if (action.type === CAR_PURPOSE_CHANGED) {
      return {
        ...state,
        carPurposehasChanged: !state.carPurposehasChanged,
        isCarPurposeChanged: true,
      };
    }

    if (action.type === CLEAR_LEAD_VALUES) {
      return {
        ...this.getInitialState(),
        rcid: state.rcid,
        referralChannelSource: state.referralChannelSource,
        referralChannelMedium: state.referralChannelMedium,
        referralChannelCampaign: state.referralChannelCampaign,
        referralChannelContent: state.referralChannelContent,
        referralChannelCampaignterm: state.referralChannelCampaignterm,
        referralChannel: state.referralChannel,
        gclid: state.gclid,
        customDashboard: state.customDashboard,
        carSalesLead: state.carSalesLead,
        saveLocalStorage: state.saveLocalStorage,
        code: state.code,
        financeRedegin: state.financeRedegin,
      };
    }
    return super.reduce(state, action);
  }
}

export default Car;
