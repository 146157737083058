import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import fieldChanged from '../../Actions/FieldChanged';
import Copyright from '../../components/Copyright';
import PrivacyConsent from '../../components/privacyConsent';
import logo from '../../../assets/image/stratton-logo-transparent.png';
import rightnormal from '../../../assets/image/TArrow-Right.svg';
import listicon from '../../../assets/image/TArrow-RightBlue.svg';
import { ROUTES } from '../../util/constants';
import CheckSuccess from '../../../assets/image/nav-check.svg';
import './context-pages.scss';
import Car from '../../../assets/image/Car.svg';
import personalDetailsBackground from '../../../assets/image/Context_pd.svg';
import { directOpportunityCreation } from '../../Actions/car';
import { noErrors, handleHTTPError } from '../../Actions/errorHandler';
import { setCurrentTab } from '../../Actions/tab';
import processGTMevent from '../../util/processGTMEvent';
import { Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import TextCheckList from '../../components/TextCheckList/TextCheckList';

const PersonalDetailslanding = ({
  acceptPrivacyPolicy,
  onInputChange,
  forceNewApplication,
  directOpportunityCreation,
  handleHTTPError,
  isAuthenticated,
  setTab,
  currentTab,
  personalDetailsContextpage,
  assetType,
}) => {
  useEffect(() => {
    setTab();
    processGTMevent('applicants', 'pageLoad', 'seepersonalDetailsPage');
  }, [currentTab, setTab]);

  const history = useHistory();
  const onLogoClick = () => {
    window.open('https://www.strattonfinance.com.au/');
  };
  const handleClick = () => {
    onInputChange('personalDetailsContextpage', true, false);
    processGTMevent('applicants', 'continue', 'continue');
    if (forceNewApplication && isAuthenticated && !personalDetailsContextpage) {
      directOpportunityCreation()
        .then(() => {
          history.push(ROUTES.personal);
        })
        .catch((err) => {
          handleHTTPError(err);
        });
    } else {
      history.push(ROUTES.personal);
    }
  };
  const handleCheckboxChange = (e) => {
    onInputChange('acceptPrivacyPolicy', e.target.checked, false);
  };
  return (
    <>
      <div className="estimateRepayments-container">
        <div className="estimateRepayments-section personal_details_section col-12 col-md-4 col-lg-4">
          <div className="stratton-info-nav-container">
            <img
              src={logo}
              alt="Stratton"
              className="pt-3 stratton-info-logo"
              onClick={onLogoClick}
            />
            <div className="step-content-section">
              <div className="nav-animation">
                <div className="nav-animation-line">
                  <div className="checksuccess-circle">
                    <img src={CheckSuccess} className="nav-checksuccess" alt="car" />
                  </div>
                  <img src={Car} className="nav-animation-car with-animation" alt="car" />
                </div>
              </div>
              <div className="stepper-contents">
                <div className="steps-section">
                  <div className="step enabled">STEP 1</div>
                  <div className="step-message enabled ">Repayments estimate</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 2</div>
                  <div className="step-message disabled">Compare loan options</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 3</div>
                  <div className="step-message disabled">Get approved</div>
                </div>

                <div className="steps-section">
                  <div className="step disabled">STEP 4</div>
                  <div className="step-message disabled">Prepare for Settlement</div>
                </div>
              </div>
            </div>
          </div>
          <div className="stratton-info-footer-container">
            <div className="personal_details_section">
              {assetType !== 'car' ? (
                <TextCheckList />
              ) : (
                <img src={personalDetailsBackground} className="nav-personal" alt="personal" />
              )}
            </div>
            <Copyright />
          </div>
        </div>

        <div className="estimateRepayments-page-details col-12 col-md-8 col-lg-8">
          <div className="Need-help py-3">
            Call us on
            <div className="number-font">1300 787 288</div>
          </div>
          <div className="estimateRepayments-message">
            <div className="estimateRepayments-name">Great!</div>
            <div className="estimateRepayments-title">Let&apos;s narrow down your loan options</div>

            <div className="estimateRepayments-subtitle">
              We need some details about you so we can match you with the right loan
            </div>
            <div className="estimate-Repayments-quote">
              <div className="estimateRepayments-ratelabel">
                <img src={listicon} alt="right" className="right-icon" />
                <span className="getrate">Who you are</span>
              </div>
              <div className="estimateRepayments-ratelabel">
                <img src={listicon} alt="right" className="right-icon" />
                <span className="getrate">Where you work</span>
              </div>
              <div className="estimateRepayments-ratelabel">
                <img src={listicon} alt="right" className="right-icon" />
                <span className="getrate">Where you live</span>
              </div>
            </div>
            {isAuthenticated && forceNewApplication && (
              <>
                <PrivacyConsent />

                <div className="privacy-policy">
                  <label className="checkbox-container checkbox-color">
                    I accept this
                    <a
                      className="btn-policy-path"
                      href="https://www.strattonfinance.com.au/privacy-policy"
                      target="_blank"
                    >
                      {' '}
                      Privacy & Credit Reporting Policy{' '}
                    </a>
                    and this Privacy Collection Notice and Consent Form. I confirm that I am
                    authorised to provide the personal information presented and I consent to my
                    information being checked by Stratton to confirm my identity.
                    <input
                      type="checkbox"
                      checked={acceptPrivacyPolicy}
                      value={acceptPrivacyPolicy}
                      onChange={(e) => handleCheckboxChange(e)}
                      name="acceptPrivacyPolicy"
                      id="acceptPrivacyPolicy"
                    />
                    <span className="checkmark ml-2 mt-1" />
                  </label>
                </div>
              </>
            )}

            <div className="email-quote py-3">
              <button
                type="button"
                data-test="lets-get-started-btn"
                className="letsgetstarted-btn"
                onClick={handleClick}
                disabled={!acceptPrivacyPolicy}
              >
                Continue
                <span className="pl-4">
                  <img src={rightnormal} alt="right" className="right-icon" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Copyright mobileFooter />
    </>
  );
};

export default connect(
  (state) => ({
    acceptPrivacyPolicy: state.applicants.acceptPrivacyPolicy,
    forceNewApplication: state.account.forceNewApplication,
    isAuthenticated: state.account.isAuthenticated,
    currentTab: state.applicants.tabName,
    personalDetailsContextpage: state.applicants.personalDetailsContextpage,
    assetType: state.car.assetType,
  }),
  (dispatch) => ({
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage));
    },
    directOpportunityCreation: () => dispatch(directOpportunityCreation()),
    noErrors: () => dispatch(noErrors()),
    handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
    setTab: () => dispatch(setCurrentTab('applicants')),
  }),
)(PersonalDetailslanding);
