/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import Form from 'react-bootstrap/Form';
import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import NumberFormat from 'react-number-format';
import cls from 'classnames';
import Tooltip from '../Tooltip';
import './style.scss';

const renderNumberFormat = ({
  input,
  name,
  label,
  type,
  placeholder,
  required,
  PrependComponent,
  marginFlag,
  meta: { touched, error },
}) => (
  <Form.Group
    controlId={name}
    isInvalid={touched && error}
    required={required}
    className="redux-render-field"
  >
    {label && (<Form.Label className="finances-form-label">
      {label}{' '}
      {label && label === 'Payout' ? (
        <Tooltip message="If this car is going to be used as a trade in for this applicants." />
      ) : null}
    </Form.Label>)}

    <InputGroup className={cls(touched && error && 'is-invalid')}>
      {PrependComponent && <PrependComponent />}
      <NumberFormat
        {...input}
        thousandSeparator
        allowNegative={false}
        decimalScale={2}
        name={name}
        type={type}
        placeholder={placeholder}
        isInvalid={touched && error}
        required={required}
        className={cls('form-control', marginFlag && 'mr-4')}
        onValueChange={(values) => {
          input.onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
      />
    </InputGroup>

    {touched && error && (
      <>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </>
    )}
  </Form.Group>
);

export default renderNumberFormat;
