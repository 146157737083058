/* eslint-disable react/prop-types */
import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import getErrorMessageRows from './FormControlFeedback';

const DropDown = ({
  label,
  fieldName,
  value = '',
  onChange,
  options,
  required,
  getvalidationerrors,
  scrollToNextFieldOnChage,
  colxs,
  colsm,
  colmd,
  className,
  defaultVal,
}) => {
  const errorMessages = getErrorMessageRows(getvalidationerrors, fieldName);
  return (
    <Form.Group xs={colxs} sm={colsm} md={colmd} className={className}>
      <Form.Label htmlFor={fieldName}>
        {label}
        <span className="mandatory">{required}</span>
      </Form.Label>
      <Form.Control
        as="select"
        id={fieldName}
        defaultValue={defaultVal}
        onChange={(ev) => {
          onChange(fieldName, ev.target.value, scrollToNextFieldOnChage);
        }}
        onBlur={(ev) => {
          onChange(fieldName, ev.target.value, scrollToNextFieldOnChage);
        }}
        name={fieldName}
        value={value || ''}
        isInvalid={errorMessages && errorMessages.length}
      >
        {options}
      </Form.Control>
      {errorMessages}
    </Form.Group>
  );
};

export default DropDown;
