import { APPLICANT_TYPE } from '../util/constants';

export const SET_STEPPER_DETAILS = 'SET_STEPPER_DETAILS';
export const REMOVE_STEPPER_DETAILS = 'REMOVE_STEPPER_DETAILS';
export const RESET_ALL_STEPPER = 'RESET_ALL_STEPPER';

export const setStepperDetails = (stepperName) => ({ type: SET_STEPPER_DETAILS, stepperName });
export const removeStepperDetails = (stepperName) => ({
  type: REMOVE_STEPPER_DETAILS,
  stepperName,
});
export const setApplicationStrucutureStepper = (applicationStructure) => {
  let stepperName = 'primaryDynamicLabel';
  if (applicationStructure === APPLICANT_TYPE.coApplicant) {
    stepperName = 'coApplicantDynamicLabel';
  } else if (applicationStructure === APPLICANT_TYPE.spouse) {
    stepperName = 'spouseDynamicLabel';
  }
  else if(applicationStructure === APPLICANT_TYPE.primaryUploadDoc)
  {
    stepperName = 'primaryUploadDocLabel';
  }
  return setStepperDetails(stepperName);
};

export const resetAllSteps = () => ({ type: RESET_ALL_STEPPER });
