/* eslint-disable react/prop-types */
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BootStrapTooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import cls from 'classnames';
import './tooltip.scss';

const Tooltip = ({
  placement = 'bottom',
  message = '',
  title = '',
  label = '',
  tooltipIconVal,
  displayWarning,
  onClick = () => {},
}) => {
  const theme = useTheme();
  const tooltipMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <BootStrapTooltip className="tooltip" id={`tooltip-${placement}`}>
          <div className="tooltip-title">{title}</div>
          <div className="tooltip-subtitle">{message}</div>
        </BootStrapTooltip>
      }
    >
      <Button
        onClick={onClick}
        className={cls(
          tooltipMobile && tooltipIconVal === 'partner' && 'tooltip-mobile',
          displayWarning && 'show-warning',
          'btn-tooltip',
        )}
      >
        {(!tooltipIconVal || (tooltipIconVal === 'partner' && !tooltipMobile)) &&
          (label || 'Learn more')}
      </Button>
    </OverlayTrigger>
  );
};
export default Tooltip;
