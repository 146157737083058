/* eslint-disable import/no-cycle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Button, Modal, Row, Col, Form, Container } from 'react-bootstrap';
import remove from 'lodash/remove';
import find from 'lodash/find';
import get from 'lodash/get';
import differenceWith from 'lodash/differenceWith';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import moment from 'moment';
import { coApplicantIncome, coSpouseApplicantIncome } from '../../../Actions/finances';
import { createSpouseCoApplicants } from '../../../Actions/car';
import renderNumberFormat from '../../../components/ReduxRenderField/renderNumberFormat';
import renderSelectField from '../../../components/ReduxRenderField/renderSelectField';
import renderSelectButtonField from '../../../components/ReduxRenderField/renderSelectButtonField';
import { normalizeAmount } from '../../../lib/formatter';

import { PAY_G, SELF_EMPLOYED } from './IncomeDetails';
import './incomedetails.scss';
import processGTMevent from '../../../util/processGTMEvent';
import {
  FREQ_OPTIONS,
  EMPTYPE_SELF,
  EMPSTATUS_RETIRED,
  EMPSTATUS_UNEMPLOYED,
} from '../../../util/constants';

// const imageIsRequired = (value) => (!value ? 'Required' : undefined);
export const formatDates = (value) => (value ? moment(value) : null);

export const normalizeDates = (value) => (value ? value.format('YYYY-MM-DD') : null);

// const spouseCategory = 'spouseprimarynetincome';
class SpouseIncomeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      isSubmitting: false,
      imageFile: (props.spouseincomeDetails && props.spouseincomeDetails.imageFile) || [],
      imageFile2: (props.spouseincomeDetails && props.spouseincomeDetails.imageFile2) || [],
    };

    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.handleOnDropFile2 = this.handleOnDropFile2.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.handleDeleteFile2 = this.handleDeleteFile2.bind(this);
    this.updateIncomeDetails = this.updateIncomeDetails.bind(this);
    this.persistIncome = this.persistIncome.bind(this);
  }

  persistIncome(spouseData) {
    const { onUpdateSpouseIncome } = this.props;
    onUpdateSpouseIncome({
      ...spouseData,
      imageFile: this.state.imageFile,
      imageFile2: this.state.imageFile2,
    });
  }

  updateIncomeDetails(spouseData) {
    const { spouseincomeDetails, spouseIncomeApi } = this.props;
    spouseIncomeApi({ ...spouseincomeDetails, ...spouseData })
      .then((response) => {
        this.persistIncome({ ...spouseData, id: response.data[0].id });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }

  submit(data) {
    processGTMevent('finances', 'spouseIncome', 'addSpouseIncome');
    // const { spouseCoApplicantId, overrideSave, spouseincomeDetails } = this.props;
    const {
      spouseincomeDetails,
      onUpdateSpouseIncome,
      spouseIncomeApi,
      spouseCoApplicantId,
      createSpouseApplicants,
    } = this.props;

    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));
    if (createSpouseApplicants && !spouseCoApplicantId) {
      createSpouseApplicants()
        .then(() => {
          spouseIncomeApi({ ...spouseincomeDetails, ...data })
            .then((response) => {
              onUpdateSpouseIncome({
                ...data,
                id: response.data[0].id,
                imageFile: this.state.imageFile,
                imageFile2: this.state.imageFile2,
              });
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
            });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    } else {
      spouseIncomeApi({ ...spouseincomeDetails, ...data })
        .then((response) => {
          onUpdateSpouseIncome({
            ...data,
            id: response.data[0].id,
            imageFile: this.state.imageFile,
            imageFile2: this.state.imageFile2,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }

  close() {
    const { onCancel } = this.props;
    onCancel();
  }

  handleOnDrop(newImageFile, onChange) {
    const imageFile = [...this.state.imageFile, ...newImageFile];

    this.setState({ imageFile }, () => onChange(imageFile));
  }

  handleOnDropFile2(newImageFile, onChange) {
    const imageFile2 = [...this.state.imageFile2, ...newImageFile];
    this.setState({ imageFile2 }, () => onChange(imageFile2));
  }

  handleDeleteFile(file) {
    const updatedList = [...this.state.imageFile];
    remove(updatedList, { path: file.path, name: file.name });
    this.setState({ ...this.state, imageFile: [...updatedList] });
  }

  handleDeleteFile2(file) {
    const updatedList = [...this.state.imageFile2];
    remove(updatedList, { path: file.path, name: file.name });
    this.setState({ ...this.state, imageFile2: [...updatedList] });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      isAdd,
      category,
      list,
      incomeTypesList,
      doctype,
      spouseEmploymentType,
    } = this.props;
    const { isSubmitting } = this.state;
    const clonedIncomeList = [...incomeTypesList];
    const updatedincomeTypesList = remove(
      clonedIncomeList,
      (allOption) =>
        (allOption.value === PAY_G &&
          (spouseEmploymentType === EMPTYPE_SELF ||
            spouseEmploymentType === EMPSTATUS_UNEMPLOYED ||
            spouseEmploymentType === EMPSTATUS_RETIRED)) ||
        (allOption.value === SELF_EMPLOYED &&
          (spouseEmploymentType !== EMPTYPE_SELF ||
            spouseEmploymentType === EMPSTATUS_UNEMPLOYED ||
            spouseEmploymentType === EMPSTATUS_RETIRED)),
    );

    const availableCategories = differenceWith(
      clonedIncomeList,
      list,
      (allOption, addedOption) =>
        (allOption.value === addedOption.category && addedOption.category === PAY_G) ||
        (allOption.value === addedOption.category && addedOption.category === SELF_EMPLOYED),
    );
    // const availableCategories = differenceWith(
    //   incomeTypesList,
    //   list,
    //   (allOption, addedOption) => allOption.value === addedOption.category,
    // );

    const Title = get(find(incomeTypesList, ['value', category]), 'name', 'Related Document');
    return (
      <>
        <Modal show size="md" onHide={this.close} className="modal-wrapper finances-modal-wrapper">
          <Modal.Header className="finances-header-container">
            <Row className="ml-1">
              <Modal.Title className="finances-header-section">
                {isAdd ? 'Add Partners Income' : Title}
              </Modal.Title>
            </Row>
          </Modal.Header>

          <Modal.Body className="p-0 spouse-form">
            <Container>
              <Row className="show-grid">
                <Col className="income-column">
                  <Form noValidate onSubmit={handleSubmit(this.submit)}>
                    {isAdd && (
                      <>
                        <Form.Group>
                          <Form.Label className="finances-form-label">Category</Form.Label>
                          <Field
                            type="select"
                            placeholder="Select"
                            label="Category"
                            name="category"
                            options={incomeTypesList}
                            component={renderSelectField}
                            required
                          />
                        </Form.Group>
                      </>
                    )}

                    <Field
                      label="Amount"
                      name="amount"
                      type="text"
                      placeholder="$0.00"
                      required
                      component={renderNumberFormat}
                      normalize={normalizeAmount}
                    />
                    <Form.Group>
                      <Form.Label className="finances-form-label">Frequency</Form.Label>
                      <Field
                        label="Frequency"
                        name="frequency"
                        options={FREQ_OPTIONS}
                        component={renderSelectButtonField}
                        classes={{
                          container: 'frequency-select-container',
                          inputButton: 'frequency-select-buttons',
                        }}
                        required
                      />
                    </Form.Group>
                    <hr className="horizantal-line" />
                    <Modal.Footer className="footer-container px-0">
                      {/*   <ButtonToolbar> */}

                      <Button type="button" className="footer-cancel" onClick={this.close}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting || pristine || submitting}
                        className="footer-btn mr-0"
                        block
                      >
                        {isAdd ? 'Save' : 'Update'}
                      </Button>
                      {/*   </ButtonToolbar> */}
                    </Modal.Footer>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

SpouseIncomeForm.defaultProps = {
  overrideSave: false,
};

const EnhancedIncomeForm = reduxForm({
  form: 'spouseincomeDetails',
  validate: (values) => {
    const errors = {};

    if (!values.category) {
      errors.category = 'Required';
    }
    if (!values.amount) {
      errors.amount = 'Required';
    } else if (Number.isNaN(values.amount)) {
      errors.age = 'Must be a number';
    } else if (Number(values.amount) <= 0) {
      errors.age = 'net income value should be greater than zero';
    }
    if (!values.frequency) {
      errors.frequency = 'Required';
    }
    if (!values.imageFile && !values.file2) {
      errors.imageFile = 'Required';
    }
    if (!values.doctype) {
      errors.doctype = 'Required';
    }
    return errors;
  },
})(SpouseIncomeForm);

const selector = formValueSelector('spouseincomeDetails'); // <-- same as form name
export default connect(
  (state) => {
    const category = selector(state, 'category');
    const doctype = selector(state, 'doctype');
    return {
      category,
      doctype,
      spouseCoApplicantId: state.applicants.spouseCoApplicantId,
      spouseEmploymentType: state.applicants.spouseEmploymentType,
    };
  },
  (dispatch) => ({
    spouseIncomeApi: (incomeData) => dispatch(coSpouseApplicantIncome(incomeData)),
    createSpouseApplicants: () => dispatch(createSpouseCoApplicants()),
  }),
)(EnhancedIncomeForm);
