import { Box, Grid } from '@material-ui/core';
import React from 'react';

const FinaliseCarHeader = ({ year, make, model }) => {
  return (
    <>
      <Grid item className="headingText">
        <Box
          sx={{
            fontSize: '32px',
            lineHeight: '48px',
          }}
        >
          Finalise Car
        </Box>
      </Grid>
      <Grid item sx={12} md={12} lg={12} className="finalise-car-details">
        <Box>
          <h6>
            {year && year} - {make && make.name} {model && model?.name}
          </h6>
        </Box>
      </Grid>
      <Grid item sx={12} md={12} lg={12}>
        <Box className="finalise-car-page-description">
          <p>Finalise your Car</p>
          <p>Please enter the details below to finalise your car</p>
        </Box>
      </Grid>
      <Grid item sx={12} md={12} lg={12} className="finalise-car-finalisation-details">
        <h6>CAR FINALISATION DETAILS</h6>
        <p> (All fields are required unless indicated as optional)</p>
      </Grid>
    </>
  );
};

export default FinaliseCarHeader;
