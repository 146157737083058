/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Steps, { Step } from 'rc-steps';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cls from 'classnames';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

const getActiveStepIndex = (stepDetails, attrName, matchWithValue) => {
  let currentIdx = 0;
  let activeIdx = -1;
  let matchFound = false;
  while (currentIdx < stepDetails.length && !matchFound) {

    if (stepDetails[attrName] && stepDetails[attrName] === matchWithValue) {
      matchFound = true;
      activeIdx = currentIdx;
    } else if (stepDetails.children) {
      activeIdx = getActiveStepIndex(stepDetails.children, attrName, matchWithValue);
      matchFound = activeIdx > -1;
    }
    currentIdx += 1;
  }
  return activeIdx;
};

const SubSteps = ({
  currentStep,
  steps,
  substepOnChange,
  disableSubSteps,
  validationErrors,
  completedTabs,
  noErrors,
  noIcon,
}) => {
  const location = useLocation();
  const history = useHistory();

  let currentIndex = getActiveStepIndex(steps, 'path', location.pathname);

  if (currentIndex === -1) {
    currentIndex = getActiveStepIndex(steps, 'active', true);
  }

  if (currentIndex === -1) {
    currentIndex = currentStep;
  }

  const onTabChange = (toPath, idx) => (event) => {
    event.preventDefault();

    if (disableSubSteps) {
      validationErrors();
      return;
    }

    if (noErrors) noErrors();

    history.push(toPath);
    if (substepOnChange) substepOnChange(idx);
  };

  const disableSubStepsTab = (newval) => {

    let disabled = false;
    if (newval === 'Personal' && !completedTabs.car) {
      disabled = true;
    }
    if (newval === 'Finances' && !completedTabs.applicants) {
      disabled = true;
    }
    if (newval === 'Compare' && !completedTabs.finance_details) {
      disabled = true;
    }
    return disabled;
  };

  return (
    <Steps
      current={currentIndex}
      direction="vertical"
      className="sub-steps"
    // onChange={substepOnChange}
    >
      {steps.map((step, idx) => (
        <Step
          key={step.path}
          className={cls(
            'rc-substeps-icon',
            noIcon && 'rc-substep-hidden-icon',
            !!step.children && 'rc-substep-acting-parent',
          )}
          progressDot
          disabled={disableSubStepsTab(step.label) || disableSubSteps || step.disabled}
          title={
            <button
              disabled={disableSubStepsTab(step.label) || disableSubSteps || step.disabled}
              onClick={onTabChange(step.path, idx)}
              type="button"
              className="btn btn-link step-title"
            >
              {step.label}
            </button>
          }
          icon={idx + 1}
          description={
            (step.children &&
              // currentIndex === idx &&
              !disableSubStepsTab(step.label) &&
              !disableSubSteps &&
              !step.disabled) && (
              <SubSteps
                steps={step.children}
                currentStep={step.children.length - 1}
                disableSubSteps={disableSubSteps}
                validationErrors={validationErrors}
                completedTabs={completedTabs}
                noErrors={noErrors}
                noIcon
              />
            )
          }
        />
      ))}
    </Steps>
  );
};

SubSteps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  substepOnChange: PropTypes.func.isRequired,
  disableSubSteps: PropTypes.bool.isRequired,
};

export default SubSteps;
