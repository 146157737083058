/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { useTheme, useMediaQuery, CircularProgress } from '@material-ui/core';
import TextField from '../../components/TextField';
import { formattedCurrentDate, COUNTRY_LIST, MIN_DATE_ERROR_MESSAGE } from '../../util/constants';
import DropDown from '../../components/DropDown';
import InputButton from '../../components/InputButton';
import DatePickerWrapper from '../../components/DatePickerWrapper';
import processGTMevent from '../../util/processGTMEvent';
import ModalComponent from '../../components/ModalComponent';
import CreditCardNumberVerificationDesktopDesign from '../../../assets/pdfFiles/CreditCardNumberVerificationDesktopDesign.pdf';
import CreditCardNumberVerificationMobileview from '../../../assets/pdfFiles/CreditCardNumberVerificationMobileview.pdf';
import rightnormal from '../../../assets/image/right-normal.svg';
import './PersonalDetails.scss';
import Tooltip from '../../components/Tooltip';

const IdentityDetails = ({
  licenceType,
  licenceNumber,
  driversLicencecardNumber,
  dlState,
  dlflag,
  dateOfBirth,
  validateDateField,
  identityDetails,
  applicants,
  validationErrors,
  onScrollToNextClick,
  getValidationErrorsForField,
  onInputChange,
  reQuote,
  currentTab,
  setTab,
  hashPath,
  nextHashPath,
  identityDetailsInProgress,
  disclosedCreditRating,
  opportunityId,
  passportFlag,
  passportNumber,
  passportCountry,
  passportExpiry,
}) => {
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(960));
  useEffect(() => {
    setTab();
  }, [currentTab, setTab]);

  const handleCheckboxChange = (fieldName, e) => {
    onInputChange(fieldName, e.target.checked, false);
    onInputChange('passportFlag', !e.target.checked, false);
    if (!e.target.checked) {
      onInputChange('passportNumber', null, false);
      onInputChange('passportCountry', null, false);
      onInputChange('passportExpiry', null, false);
    }
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleIdentityDetailsInsUpd = () => {
    onInputChange('identityDetailsInProgress', true, false);
    applicants()
      .then(() => {
        localStorage.setItem(`pageCompleted${opportunityId}`, 'drivers-Licence');
        reQuote();
      })
      .catch(() => {
        onInputChange('identityDetails', true, false).then(() => {
          onScrollToNextClick(nextHashPath, [], true, true, true);
        });
      });
    onInputChange('identityDetailsInProgress', false, false);
    onInputChange('identityDetails', true, false)
      .then(() => {
        onScrollToNextClick(nextHashPath, [], true, true, true);
      })
      .catch((error) => {
        onInputChange('identityDetailsInProgress', false, false);
        // eslint-disable-next-line no-console
        console.error(error);
      });
    processGTMevent('applicants', 'inlineNext', 'identityDetails');
  };

  const nextDisabled =
    (validationErrors && validationErrors.length) ||
    !dateOfBirth ||
    (!dlflag && (!licenceNumber || !driversLicencecardNumber || !dlState || !licenceType)) ||
    !disclosedCreditRating ||
    (dlflag && (!passportNumber || !passportCountry || !passportExpiry));

  const LicenceConditionCheckbox = () => (
    <Form.Group as={Col} xs={12} md={6} controlId="dlflag" className="no-license-check">
      <label className="checkbox-container">
        I don&apos;t have an Australian drivers licence
        <input
          type="checkbox"
          onChange={(e) => handleCheckboxChange('dlflag', e)}
          checked={dlflag}
          value={dlflag}
          name="dlflag"
          id="dlflag"
        />
        <span className="checkmark ml-2" />
      </label>
    </Form.Group>
  );

  const licencehelptextfilenamefield = !mobileView
    ? CreditCardNumberVerificationDesktopDesign
    : CreditCardNumberVerificationMobileview;
  const onCreditLearnMoreBtn = () => {
    window.open(
      'https://www.strattonfinance.com.au/newsroom/car-loans-and-credit-scores-the-lowdown/',
      '_blank',
    );
  };
  return (
    <>
      <Card className={`content-body personal-details-container ${hashPath}`} id="applicantDetails">
        <Card.Header className="col-12 page-header-container personal-details-header-container scrollable-anchor-tag with-sidebar">
          <a name={hashPath} href={`#${hashPath}`}>
            &nbsp;
          </a>
          <h1 className="personal-details-header"> Your driver&apos;s licence details</h1>
          <div className="personal-details-description">
            The details will be used to verify your identity
          </div>
        </Card.Header>
        <Card.Body className="pb-0 identity-details-body-container personal-body-container personal-body-section1 personal-body-section2">
          <Form noValidate className="py-2 px-1 identity-form-section">
            <Form.Row className="personal-body-section3">
              {dlflag && (
                <>
                  <LicenceConditionCheckbox />
                  <div className="passportdetails">
                    <TextField
                      placeholder="eg. 2321234"
                      fieldName={`passportNumber`}
                      value={passportNumber}
                      className="passport-number"
                      label="Passport number"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colxs="12"
                      colsm="4"
                      colmd="4"
                      inline
                    />
                    <DropDown
                      fieldName={`passportCountry`}
                      value={passportCountry}
                      label="Passport Country"
                      className="passport-country"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      options={
                        <>
                          {!passportCountry && (
                            <option value="" hidden>
                              Choose...
                            </option>
                          )}
                          {COUNTRY_LIST.map((country) => (
                            <option key={country.id} value={country.value}>
                              {country.value}
                            </option>
                          ))}
                        </>
                      }
                      colxs="12"
                      colsm="4"
                      colmd="4"
                    />
                    <DatePickerWrapper
                      value={passportExpiry}
                      onChange={onInputChange}
                      className="passport-expiry"
                      fieldName={`passportExpiry`}
                      validateDateField={validateDateField}
                      minDtVal={formattedCurrentDate}
                      minDateMessage={MIN_DATE_ERROR_MESSAGE}
                      label="Passport Expiry"
                      format="YYYY-MM-DD"
                      getValidationErrors={getValidationErrorsForField}
                      inline
                      columnCount="4"
                    />
                  </div>
                </>
              )}

              {!dlflag && (
                <>
                  <Form.Group
                    as={Col}
                    xs={12}
                    md={6}
                    className="px-0 license-number"
                    controlId="licenceNumber"
                  >
                    <TextField
                      fieldName="licenceNumber"
                      value={licenceNumber}
                      className="px-0"
                      label="Driver's licence number"
                      placeholder="eg. 123456789"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colmd={12}
                      updateOnChange
                      inline
                    />
                  </Form.Group>
                  <LicenceConditionCheckbox />
                </>
              )}
            </Form.Row>
            <Form.Row className="licence-verification-number">
              {!dlflag && (
                <Form.Group
                  as={Col}
                  xs={12}
                  md={6}
                  className="px-0 m-0 license-verification-number"
                  controlId="driversLicencecardNumber"
                >
                  {!mobileView ? (
                    <>
                      <label htmlFor="driversLicencecardNumber" className="licencecardlabel">
                        Driver&apos;s licence card number
                      </label>
                      <button
                        type="button"
                        className="btn-tooltip btn btn-primary ml-2"
                        onClick={handleShow}
                      >
                        What's this?
                      </button>
                      <TextField
                        fieldName="driversLicencecardNumber"
                        value={driversLicencecardNumber}
                        className="px-0"
                        placeholder="eg. AA000A0000"
                        getvalidationerrors={getValidationErrorsForField}
                        onChange={onInputChange}
                        colmd={12}
                        updateOnChange
                        inline
                      />
                    </>
                  ) : (
                    <>
                      <div className="mt-2">
                        <label htmlFor="driversLicencecardNumber" className="licencecardlabel">
                          Driver&apos;s licence card number
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn-tooltip btn btn-primary"
                        onClick={handleShow}
                      >
                        What's this?
                      </button>
                      <TextField
                        fieldName="driversLicencecardNumber"
                        value={driversLicencecardNumber}
                        className="px-0 pt-3"
                        placeholder="eg. AA000A0000"
                        getvalidationerrors={getValidationErrorsForField}
                        onChange={onInputChange}
                        colmd={12}
                        updateOnChange
                        inline
                      />
                    </>
                  )}
                </Form.Group>
              )}
            </Form.Row>
            {!dlflag && (
              <>
                <InputButton
                  label={
                    <>
                      <span>Driver's licence state</span>
                    </>
                  }
                  fields={[
                    { name: 'NSW', value: 'NSW' },
                    { name: 'QLD', value: 'QLD' },
                    { name: 'SA', value: 'SA' },
                    { name: 'TAS', value: 'TAS' },
                    { name: 'VIC', value: 'VIC' },
                    { name: 'WA', value: 'WA' },
                    { name: 'ACT', value: 'ACT' },
                    { name: 'NT', value: 'NT' },
                  ]}
                  onChange={onInputChange}
                  fieldName="dlState"
                  value={dlState}
                  scrollToNextFieldOnChage
                  ignoreHeaderOffset
                  getvalidationerrors={getValidationErrorsForField}
                />
                <InputButton
                  label={
                    <>
                      <span>Licence type </span>
                    </>
                  }
                  fields={[
                    { name: 'Full', value: 'FULL' },
                    { name: 'Learner', value: 'LEARNER' },
                    { name: 'Probationary', value: 'PROVISIONAL' },
                  ]}
                  onChange={onInputChange}
                  fieldName="licenceType"
                  value={licenceType}
                  scrollToNextFieldOnChage
                  ignoreHeaderOffset
                  getvalidationerrors={getValidationErrorsForField}
                />
              </>
            )}

            <div className="form-row">
              <DatePickerWrapper
                value={dateOfBirth}
                onChange={onInputChange}
                className="date-of-birth"
                fieldName="dateOfBirth"
                validateDateField={validateDateField}
                minDtVal="01/01/1900"
                maxDtVal={formattedCurrentDate}
                label="Date of birth"
                format="YYYY-MM-DD"
                columnCount="4"
                getValidationErrors={getValidationErrorsForField}
                inline
              />
            </div>
            <InputButton
              label={
                <>
                  <span>Credit Rating</span>{' '}
                  <span>
                    <Tooltip
                      label="Learn more"
                      toolTipCommon
                      message="Click here for more information about selecting your credit rating."
                      onClick={onCreditLearnMoreBtn}
                    />
                  </span>
                </>
              }
              fields={[
                { name: 'Excellent', value: 'Excellent' },
                { name: 'Average', value: 'Average' },
                { name: 'Fair', value: 'Fair' },
                { name: 'Poor', value: 'Poor' },
              ]}
              onChange={onInputChange}
              fieldName="disclosedCreditRating"
              value={disclosedCreditRating}
              scrollToNextFieldOnChage
              ignoreHeaderOffset
              getvalidationerrors={getValidationErrorsForField}
            />
          </Form>
        </Card.Body>
        <div className="row container-footer py-3 px-3 mt-2 border-top">
          <div className="col-12 d-flex justify-content-end px-0">
            <button
              type="button"
              data-test="IdentityDetails-Next"
              className="btn btn-primary btn-next-page"
              disabled={nextDisabled}
              enabled={
                (dlflag && (!licenceType || !licenceNumber || !dlState)) ||
                identityDetailsInProgress
              }
              hidden={identityDetails}
              onClick={handleIdentityDetailsInsUpd}
            >
              {identityDetailsInProgress ? (
                <CircularProgress color="inherit" size={24} thickness={2.6} />
              ) : (
                'Next'
              )}
              <span className="px-3">
                <img src={rightnormal} alt="right" className="right-icon" />
              </span>
            </button>
            <input
              type="button"
              id="btnsubmit"
              className="btn-dummy-page"
              style={{ visibility: 'hidden' }}
            />
          </div>
        </div>
      </Card>
      {show && (
        <ModalComponent
          show
          handleClose={handleClose}
          licencehelptextfilename={licencehelptextfilenamefield}
        />
      )}
    </>
  );
};

export default IdentityDetails;
