import offsetParent from 'dom-helpers/offsetParent';
import animateScrollTo from './animatedScrollTo';

const scrollToField = (fieldName) => {
  if (fieldName) {
    setTimeout(() => {
      const scrollNode = document.scrollingElement || document.documentElement || document.body;

      if (fieldName === 'toTop') {
        // scrollNode.scrollTop = 0;
        animateScrollTo(scrollNode, 0, 400);
      }

      const fields = document.getElementsByName(fieldName).length
        ? document.getElementsByName(fieldName)
        : document.getElementsByClassName(fieldName);
      if (fields && fields.length > 0) {
        const rootElement = fields[0].parentElement;
        const rootSiblingElement = fields[0];
        const defaultElement = document.getElementsByClassName('btn-dummy-page');

        const field = rootSiblingElement
          || rootElement
          || (defaultElement && defaultElement[defaultElement.length - 1]);

        const offsetParentElement = offsetParent(field);
        const offsetHeaderElement = document.getElementsByClassName('header-container')[0];

        if (offsetParentElement) {
          const offSet = offsetHeaderElement.offsetTop + offsetParentElement.offsetTop + field.offsetTop;

          animateScrollTo(scrollNode, offSet, 500);
        }
      }
    }, 200);
  }
};

export default scrollToField;
