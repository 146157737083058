/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cls from 'classnames';
import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import rightnormal from '../../../assets/image/right-normal.svg';
import AmountRangeSelector from '../../components/AmountRangeSelector';
import '../../components/input-field.scss';
import { ROUTES } from '../../util/constants';
import processGTMevent from '../../util/processGTMEvent';
import './carfinance.scss';

const useStyles = makeStyles(() =>
  createStyles({
    amountRangeRoot: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    sliderContainer: {
      marginLeft: 0,
      marginRight: 0,
      width: '100%',
    },
    sliderThumb: {
      marginLeft: '-4px',
    },
    manualCarQueryContainer: {},
  }),
);

const PersonalLoanAssetType = (props) => {
  const {
    setTab,
    currentTab,
    searchCar,
    purchaseUse,
    purchasePrice,
    carCondition,
    validationErrors,
    getValidationErrorsForField,
    onInputChange,
    flagChange,
    getAllVehicleMakes,
    purchasePriceComplete,
    getModelForVehicleMakes,
    setTabComplete,
    nextFieldNameToFocus,
    scrollToNext,
    createQuote,
    handleHTTPError,
    carDetails,
    regoNumberSearch,
    noErrors,
    validationCheck,
    ValidationErrorFlag,
    errorMessage,
    getAllVehicleYears,
    getSeriesForVehicleModel,
    getVariantsForVehicleSeries,
    loanDetails,
    quoteInProgress,
    reQuote,
    termVal,
    onEstimateChange,
    completedTabs,
    carSalesOppCreated,
    isCarsalesAccount,
    carSalesContact,
    assetType,
    opportunityId,
    manualEnter,
    getfactoryOptions,
  } = props;

  const history = useHistory();
  const classes = useStyles({});

  const updatedAssetType = assetType?.toLowerCase().replace(/\s+/g, '-');

  useEffect(() => {
    processGTMevent(assetType, 'PQ-PageLoad', 'Page Load');
    reQuote();

    if (setTab) {
      if (
        completedTabs.car &&
        completedTabs.estimation &&
        completedTabs.applicants &&
        completedTabs.finance_details
      ) {
        setTab('compare_loan_options');
      } else if (completedTabs.car && completedTabs.estimation && completedTabs.applicants) {
        setTab('finance_details');
      } else {
        setTab('car');
      }

      scrollToNext(`${updatedAssetType}~fullScreen`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextFieldNameToFocus) {
      scrollToNext(nextFieldNameToFocus, true, true);
    } else if (!carCondition) {
      scrollToNext(`${updatedAssetType}~fullScreen`);
    }
  }, [carCondition, nextFieldNameToFocus, scrollToNext]);
  // eslint-disable-next-line max-len

  const handleClick = () => {
    if (validationErrors && validationErrors.length) {
      validationCheck();
      return;
    }
    processGTMevent(assetType, 'seeContact', 'seeContactPage');
    noErrors();
    if (opportunityId) {
      carDetails();
      loanDetails()
        .then(() => {
          reQuote();
          setTabComplete(currentTab);
          localStorage.setItem(`pageCompleted${opportunityId}`, 'car');
          if ((carSalesOppCreated || isCarsalesAccount) && !carSalesContact) {
            history.push(ROUTES.contactDetails);
          } else {
            history.push(ROUTES.personal);
          }
        })
        .catch((error) => {
          handleHTTPError(error, history);
        });
    } else {
      createQuote()
        .then(() => {
          setTabComplete(currentTab);
          history.push(ROUTES.personal);
        })
        .catch((error) => {
          handleHTTPError(error, history);
        });
    }
  };

  const regoVehicleSearch = () => {
    noErrors();
    regoNumberSearch();
  };
  const handleFieldChange = (fieldName, value) => {
    onInputChange(fieldName, value, false);
    if (
      fieldName === 'carCondition' ||
      fieldName === 'purchaseUse' ||
      fieldName === 'purchaseSource' ||
      fieldName === 'purchasePrice' ||
      fieldName === 'depositAmount'
    ) {
      if (opportunityId) {
        reQuote();
      } else {
        createQuote();
      }
    }
    if (fieldName === 'carCondition') {
      getAllVehicleYears(value);
    }
    if (
      fieldName === 'carCondition' &&
      (carCondition === 'New' ||
        manualEnter ||
        carCondition === 'Demo' ||
        carCondition === 'Used' ||
        carCondition === 'Unsure')
    ) {
      flagChange('purchasePrice', 50000);
      flagChange('depositAmount', 0);
    }

    if (carCondition === 'New' || manualEnter || carCondition === 'Demo') {
      noErrors();

      if (fieldName === 'manufacturingYear') {
        getAllVehicleMakes();

        if (opportunityId) {
          reQuote();
        } else {
          createQuote();
        }
      }
      if (fieldName === 'vehicleMake') {
        getModelForVehicleMakes();
      }
      if (fieldName === 'vehicleModel') {
        getSeriesForVehicleModel();
      }
      if (fieldName === 'vehicleSeries') {
        getVariantsForVehicleSeries();
      }
      if (fieldName === 'vehicleVariant') {
        getfactoryOptions();
      }
    }
  };

  const handleSkip = (fieldName) => {
    onInputChange('skippedActionTriggered', true, false);
    onInputChange(fieldName, true, false);
  };
  const maxTermVal = 7;

  return (
    <>
      <div>
        {/* <PageHeader tabName="car" /> */}
        <div className="container-section car-applicants-main-container">
          <div
            className="content-body car-applicants-main-tab-container car-subscreen-container"
            id="car"
          >
            <div className="car-details-header">
              <div className="car-details-title">Tell us about the car you're looking to buy</div>
              <div className="car-details-subtitle">
                Providing more information will improve the accuracy of your rate and repayments
              </div>
            </div>
            <hr className="car-details-border" />
            <div className="car-applicants-main-subcontainer car-details-content-block">
              <div className="row mx-0">
                <div className="col-12 car-applicants-main-section px-0">
                  <Form
                    noValidate
                    className={cls(
                      {
                        'car-applicants-footer-wrap':
                          (!!purchasePrice && purchasePriceComplete && carCondition !== 'Unsure') ||
                          (carCondition === 'Unsure' && !!purchaseUse),
                      },
                      'car-applicants-main-form pb-0',
                    )}
                  >
                    <div
                      className={cls(
                        'amount-range-container purchase-price-selector car-purchase-price',
                        'scrollable-anchor-tag',
                        // !purchasePriceComplete && 'fit-screen',
                      )}
                    >
                      <a name="car-purchase-price" href="#car-purchase-price">
                        &nbsp;
                      </a>
                      <div className="label">What is the estimated loan amount?</div>
                      <AmountRangeSelector
                        label={
                          <>
                            <span className="range-selector-label"> Estimated loan amount</span>
                          </>
                        }
                        onChange={handleFieldChange}
                        fieldName="purchasePrice"
                        defaultValue={purchasePrice}
                        colsm={12}
                        colmd={8}
                        // oncreateQuote={createQuote}
                        getvalidationerrors={getValidationErrorsForField}
                        minValue={5000}
                        maxValue={250000}
                        step={1000}
                        classes={{
                          root: classes.amountRangeRoot,
                          sliderContainer: classes.sliderContainer,
                        }}
                        sliderClasses={{
                          thumb: classes.sliderThumb,
                        }}
                      />
                    </div>

                    <div
                      className={cls(
                        'amount-range-container loan-term-selector car-loan-term',
                        'scrollable-anchor-tag',
                      )}
                    >
                      <div className="label">Over what term do you want the loan?</div>
                      <div className="term scrollable-anchor-tag">
                        <a name="term" href="#term">
                          &nbsp;
                        </a>
                      </div>

                      <div className="amount-range-container term-val-selector">
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="label">Loan term</span>
                            </>
                          }
                          // formGroupClass="estimation-range-selector col-12 col-sm-6 col-md-4"
                          onChange={onEstimateChange}
                          fieldName="termVal"
                          defaultValue={termVal}
                          oncreateQuote={createQuote}
                          minValue={1}
                          maxValue={maxTermVal}
                          step={1}
                          termSlider
                          classes={{
                            root: classes.amountRangeRoot,
                            sliderContainer: classes.sliderContainer,
                          }}
                          sliderClasses={{
                            thumb: classes.sliderThumb,
                          }}
                          getvalidationerrors={getValidationErrorsForField}
                        />
                      </div>
                    </div>

                    <div className="footer-actions">
                      <div className="row container-footer  car-details-footer ">
                        <div className="col-12 d-flex justify-content-end px-0 car-details-next-btn">
                          <button
                            type="button"
                            data-test="carDetails-Next"
                            className="btn btn-primary btn-next-page"
                            onClick={handleClick}
                            disabled={quoteInProgress}
                          >
                            {quoteInProgress ? (
                              <CircularProgress color="inherit" size={24} thickness={2.6} />
                            ) : (
                              <>
                                Next
                                <span className="pl-4">
                                  <img src={rightnormal} alt="right" className="right-icon" />
                                </span>
                              </>
                            )}
                          </button>
                          {/*   dummy button addded to make the focus point there */}
                          <input
                            type="button"
                            id="btnsubmit"
                            className="btn-dummy-page"
                            style={{ visibility: 'hidden' }}
                          />
                        </div>
                      </div>

                      {ValidationErrorFlag && (
                        <div className="p-0">
                          <Alert variant="danger" className="error-alert py-4 px-2">
                            <div className="error-flex">
                              <div className="error-header-message">
                                {searchCar ? 'We could not identify your details.' : 'Error'}
                              </div>
                              <p className="error-message">{errorMessage}</p>
                            </div>
                          </Alert>
                        </div>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalLoanAssetType;
