import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Login from './containers/LoginDetails/Login';
import InternalServer from './components/ErrorPages/InternalServer/InternalServer';
import DashboardContainer from './containers/Dashboard/index';
import ResetPassword from './containers/LoginDetails/ResetPassword';
import ContentAreaContainer from './containers/ContentAreaContainer';
import EstimateLoadingpage from './containers/Estimation';
import CarFinance from './containers/CarFinance';
import EstimateRepayments from './containers/ContextPages/EstimateRepayments';
import financePageComplete from './containers/ContentPages/financePageComplete';
import fieldChanged from './Actions/FieldChanged';
import { ROUTES, ROUTES_ASSET_TYPES } from './util/constants';
import PersonalDetailslanding from './containers/ContextPages/PersonalDetailslanding';
import FinancialDetailsContextPage from './containers/ContextPages/FinancialDetailsContextPage';
import UpdateLoanOptions from './components/UpateLoanOptions';
import CustomerDashboard from './containers/Dashboard/CustomerDashboard';
import CompareLoanOptionsContainer from './containers/CompareLoanOptions/index';
import VerifyAccountCarsales from './containers/ContextPages/VerifyAccountCarsales';
import DocVerificationContainer from './containers/DocumentVerification/index';
import EmploymentDetails from './containers/EmploymentDetails/index';
import ContactDetails from './containers/ContactDetails/ContactDetails';
import SignUpAccount from './containers/LoginDetails/SignUpAccount';
import EstimateLenderPage from './containers/Estimation/EstimateLenderPage';
import LeadCreationEmailCaptcha from './containers/LeadCreationEmailCaptcha';

const InitRenderer = ({ isInitComplete, completedTabs, forceNewApplication }) => {
  if (isInitComplete && !forceNewApplication) {
    return <ContentAreaContainer />;
  } else {
    return <CarFinance />;
  }
};

const InitRenderWrapper = connect((state) => ({
  isInitComplete: state.estimation.isInitComplete,
  forceNewApplication: state.account.forceNewApplication,
  completedTabs: state.tab.completedTabs,
}))(InitRenderer);
const Routes = ({ isAuthenticated, carCompleted }) => (
  <>
    {/* These Un-Auth routes */}
    {!isAuthenticated && (
      <Switch>
        <Route path={ROUTES.initial} exact component={EstimateRepayments} />
        {ROUTES_ASSET_TYPES.map((path, index) => {
          return <Route path={path} exact component={InitRenderWrapper} key={index} />;
        })}
        {/* <Route path={ROUTES.initialDetails} exact component={InitRenderWrapper} /> */}
        {carCompleted && <Route path={ROUTES.estimation} exact component={EstimateLoadingpage} />}
        <Route path={ROUTES.login} exact component={Login} />
        <Route path={ROUTES.signUp} exact component={SignUpAccount} />
        <Route path={ROUTES.loginReset} exact component={ResetPassword} />
        <Route path={ROUTES.financeComplete} exact component={financePageComplete} />
        <Route path={ROUTES.edmenquiry} exact component={LeadCreationEmailCaptcha} />
        <Route path={ROUTES.initialPersonalDetails} exact component={PersonalDetailslanding} />
        <Route path={ROUTES.compareLoanOptions} exact component={CompareLoanOptionsContainer} />
        <Route path={ROUTES.updateLoanAmount} exact component={UpdateLoanOptions} />
        <Route path={ROUTES.verification} exact component={DocVerificationContainer} />
        <Route path={ROUTES.initialFinanceDetails} exact component={FinancialDetailsContextPage} />
        <Route path={ROUTES.estimateLoading} exact component={EstimateLenderPage} />
        <Route path={ROUTES.verifyOTP} exact component={VerifyAccountCarsales} />
        <Route path={ROUTES.employmentDetails} exact component={EmploymentDetails} />
        <Route path="/500" component={InternalServer} />
        <ContentAreaContainer />
      </Switch>
    )}

    {/* These Authenticated routes */}
    {!!isAuthenticated && (
      <Switch>
        <Route path={ROUTES.dashboard} exact component={DashboardContainer} />
        <Route path={ROUTES.customerdashboard} exact component={CustomerDashboard} />
        <Route path={ROUTES.login} exact component={Login} />
        <Route path={ROUTES.signUp} exact component={SignUpAccount} />
        <Route path={ROUTES.initialFinanceDetails} exact component={FinancialDetailsContextPage} />
        <Route path={ROUTES.financeComplete} exact component={financePageComplete} />
        <Route path={ROUTES.verification} exact component={DocVerificationContainer} />
        <Route path={ROUTES.initialPersonalDetails} exact component={PersonalDetailslanding} />
        <Route path={ROUTES.updateLoanAmount} exact component={UpdateLoanOptions} />
        <Route path={ROUTES.estimateLoading} exact component={EstimateLenderPage} />
        <Route path={ROUTES.contactDetails} exact component={ContactDetails} />
        <Route path={ROUTES.initial} exact component={EstimateRepayments} />
        {ROUTES_ASSET_TYPES.map((path, index) => {
          return <Route path={path} exact component={InitRenderWrapper} key={index} />;
        })}
        <Route path={ROUTES.verifyOTP} exact component={VerifyAccountCarsales} />
        <ContentAreaContainer />
      </Switch>
    )}
  </>
);
Routes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  forceNewApplication: PropTypes.bool,
  carCompleted: PropTypes.bool,
};
InitRenderer.propTypes = {
  isInitComplete: PropTypes.bool.isRequired,
  forceNewApplication: PropTypes.bool,
  completedTabs: PropTypes.shape({
    car: PropTypes.bool,
  }).isRequired,
};

export default connect(
  (state) => ({
    isAuthenticated: state.account.isAuthenticated,
    forceNewApplication: state.account.forceNewApplication,
    carCompleted: state.tab.completedTabs && state.tab.completedTabs.car,
    assetType: state.car.assetType,
  }),
  (dispatch) => ({
    setAuthenticationlogOut: () =>
      dispatch(fieldChanged('account', 'isAuthenticated', false, false)),
  }),
)(Routes);
