/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';


const SaveQuoteSuccess = ({ onSaveQuoteSuccess, setTabComplete }) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    setTabComplete('estimation');
    onSaveQuoteSuccess();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="save-model-layout" centered>
        <Modal.Body className="save-container">
          <Col xs={12} className="success-icon-container">
            <span className="success-icon" />
          </Col>
          <Container>
            <div className="save-title">Quote sent successfully!</div>
            <div className="form-row text-center">
              <div className="col-12">
                <Button type="button" className="btn-close btn-val" onClick={handleClose}>
                  Close
                </Button>
              </div>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SaveQuoteSuccess;
