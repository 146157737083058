/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { CircularProgress } from '@material-ui/core';
import cls from 'classnames';
import find from 'lodash/find';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import '../../components/input-field.scss';
import processGTMevent from '../../util/processGTMEvent';
import rightnormal from '../../../assets/image/right-normal.svg';
import AmountRangeSelector from '../../components/AmountRangeSelector';
import FindMakeFilter from '../../components/FindMakeFilter';
import InputButton from '../../components/InputButton';
import ProgressBar from '../../components/ProgressBar';
import Tooltip from '../../components/Tooltip';
import {
  CAR_ASSET,
  CAR_CONDITION,
  PURCHASE_SOURCE,
  PURCHASE_USE,
  ROUTES,
} from '../../util/constants';
import './carfinance.scss';
import PrePopulateFactoryOptions from './PrePopulateFactoryOptions';
import CustomCard from './CustomCard';
import DescriptionTextPartner from '../../components/DescriptionPartner';
import { findPartnerandDescription } from '../../util/helpers';
import RegoSearch from '../../components/RegoSearch';

const CarFinance = (props) => {
  const {
    setTab,
    currentTab,
    regoNumber,
    regoState,
    searchCar,
    searchComplete,
    purchaseSource,
    purchaseUse,
    purchasePrice,
    depositAmount,
    carCondition,
    manufacturingYear,
    vehicleMake,
    vehicleModel,
    vehicleSeries,
    vehicleVariant,
    skippedVehicleDetails,
    validationErrors,
    getValidationErrorsForField,
    onInputChange,
    flagChange,
    vehicleMakesData,
    vehicleModelsData,
    manualEnter,
    getAllVehicleMakes,
    purchasePriceComplete,
    getModelForVehicleMakes,
    setTabComplete,
    nextFieldNameToFocus,
    scrollToNext,
    depositPriceComplete,
    progressValue,
    createQuote,
    handleHTTPError,
    regoNumberSearch,
    factoryOptions,
    skipVariant,
    skipSeries,
    skipModel,
    noErrors,
    validationCheck,
    ValidationErrorFlag,
    errorMessage,
    getAllVehicleYears,
    vehicleYears,
    vehicleSeriesdata,
    getSeriesForVehicleModel,
    vehicleVariantsdata,
    getfactoryOptions,
    factoryOptionsComplete,
    onEstimateChange,
    quoteInProgress,
    isLoadingcarmakes,
    regoSearchInProgress,
    regoSearchError,
    skippedActionTriggered,
    termVal,
    isAuthenticated,
    forceNewApplication,
    partnername,
    onLoadPageAsset,
    condition,
    make,
    model,
    depositAmountUrl,
    year,
    purchasePriceUrl,
    purchaseSourceUrl,
    purchaseUseUrl,
    term,
    prefillRecords,
  } = props;

  const history = useHistory();
  const [hideSeries, setHideSeries] = useState(false); // to be reverted after model fix
  useEffect(() => {
    noErrors();
    getAllVehicleYears(carCondition);
  }, [carCondition, getAllVehicleYears, manufacturingYear, noErrors]);

  useEffect(() => {
    onLoadPageAsset(CAR_ASSET);
    if (setTab) {
      setTab('car');
      processGTMevent('car', 'pageLoad', 'carDetailsPageLoad');
      scrollToNext('car-condition~fullScreen');
    }
  }, []);
  useEffect(() => {
    if (vehicleMakesData?.length === 0) {
      handleSkip('skippedVehicleDetails');
    }
  }, [vehicleMakesData]);

  useEffect(() => {
    if (!prefillRecords) {
      onInputChange('depositAmount', 0, true);
    }
  }, [purchasePrice]);

  useEffect(() => {
    if (nextFieldNameToFocus) {
      if (
        (nextFieldNameToFocus === 'purchasePrice' ||
          nextFieldNameToFocus === 'purchase-price-selector') &&
        carCondition === 'Unsure' &&
        purchaseUse
      ) {
        return;
      }
      if (nextFieldNameToFocus === 'deposit') {
        window.location.hash = '#deposit';
        return;
      }

      scrollToNext(`${nextFieldNameToFocus}~fullScreen`);
    } else if (!carCondition) {
      scrollToNext('toTop');
    }
  }, [carCondition, nextFieldNameToFocus, purchaseUse, scrollToNext]);

  useEffect(() => {
    if (prefillRecords && make) {
      const makeIndex = vehicleMakesData.findIndex((obj) => obj.value === make);
      if (makeIndex) {
        const [matchObj] = vehicleMakesData.splice(makeIndex, 1);
        vehicleMakesData.unshift(matchObj);
      }
    }
  }, [vehicleMakesData]);

  useEffect(() => {
    if (prefillRecords) {
      flagChange('assetType', 'Car', false);
      if (condition) {
        flagChange('carCondition', condition, false);
        onInputChange('progress', 10, false);
        if (condition === 'Used') {
          onInputChange('manualEnter', true, false);
        }
        if (year) {
          flagChange('manufacturingYear', year, false);
          onInputChange('progress', 20, false);

          getAllVehicleMakes().then(() => {
            if (make) {
              flagChange('vehicleMake', { name: make, value: make }, false);
              onInputChange('progress', 30, false);
            }
            getModelForVehicleMakes().then(() => {
              if (model) {
                flagChange('vehicleModel', { name: model, value: model }, false);
                getSeriesForVehicleModel();
              }
            });
          });

          if (make && model && condition !== 'Unsure') {
            if (purchaseUseUrl) {
              flagChange('purchaseUse', purchaseUseUrl, false);
              onInputChange('progress', 40, false);

              if (purchaseSourceUrl) {
                flagChange('purchaseSource', purchaseSourceUrl, false);
                onInputChange('progress', 50, false);
              }
              if (purchasePriceUrl) {
                flagChange('purchasePrice', parseFloat(purchasePriceUrl));
                onInputChange('progress', 60, false);

                if (term) {
                  onEstimateChange('termVal', term, false);
                  onInputChange('progress', 90, false);
                }
              }
            }
          } else if (condition === 'Unsure') {
            if (purchasePriceUrl) {
              flagChange('purchasePrice', parseFloat(purchasePriceUrl));
              onInputChange('progress', 40, false);
              if (depositAmountUrl) {
                onInputChange('purchasePriceComplete', true, false);
                flagChange('depositAmount', parseFloat(depositAmountUrl));
                onInputChange('depositPriceComplete', true, false);

                if (purchaseSourceUrl) {
                  flagChange('purchaseSource', purchaseSourceUrl, false);
                  onInputChange('progress', 60, false);

                  if (purchaseUseUrl) {
                    flagChange('purchaseUse', purchaseUseUrl, false);
                    onInputChange('progress', 50, false);
                    if (term) {
                      onEstimateChange('termVal', term, false);
                      onInputChange('progress', 90, false);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [
    condition,
    make,
    model,
    depositAmountUrl,
    year,
    purchasePriceUrl,
    purchaseSourceUrl,
    purchaseUseUrl,
    term,
  ]);

  const currentData = new Date();
  const currentYear = currentData.getFullYear();
  const vehicleYearsformatted = reverse(sortBy(vehicleYears));
  const vehicleYearsMax10 = vehicleYearsformatted.reduce((accum, yrOption, idx) => {
    if (idx > 9) return accum;
    accum.push({ name: String(yrOption), value: yrOption });
    return accum;
  }, []);
  vehicleYearsMax10.push({ name: 'Older', value: String(currentYear - 10) });

  const newpartnerflag = findPartnerandDescription(partnername) === null ? true : false;

  const handleClick = () => {
    processGTMevent('Car', 'termValue', termVal);
    flagChange('carDetailsChanged', false);
    if (validationErrors && validationErrors.length) {
      validationCheck();
      return;
    }
    if (purchasePrice - depositAmount < 5000) {
      validationCheck('Loan Amount cannot be less than $5000.');
      return;
    }
    noErrors();
    // 22-matr-2019 removed as it should be accled only for update

    createQuote()
      .then(() => {
        // Add your code here
        setTabComplete(currentTab);

        if (isAuthenticated && forceNewApplication) {
          history.push(ROUTES.estimation);
        } else {
          history.push(ROUTES.contactDetails);
        }
      })
      .catch((error) => {
        handleHTTPError(error, history);
      });
    processGTMevent('car', 'seeContact', 'seeContactPage');
  };

  const regoVehicleSearch = () => {
    noErrors();
    regoNumberSearch();
  };

  const handleFieldChange = (fieldName, value, scrollToNextFieldOnChage) => {
    let updateValue = progressValue;
    switch (fieldName) {
      case 'carCondition':
        updateValue = 10;
        break;
      case 'regoNumber':
        updateValue = 20;
        break;
      case 'regoState':
        updateValue = 25;
        break;
      case 'purchaseSource':
        updateValue = carCondition === 'Unsure' ? 80 : 90;
        break;
      case 'purchaseUse':
        updateValue = carCondition === 'Unsure' ? 100 : 95;
        break;
      case 'purchasePrice':
        updateValue = 55;
        onInputChange('depositAmount', 0, true);
        break;
      case 'depositAmount':
        updateValue = 60;
        break;
      case 'manufacturingYear':
        updateValue = 30;
        break;
      case 'vehicleMake':
        updateValue = 40;
        break;
      case 'vehicleModel':
        updateValue = 50;
        break;
      case 'vehicleSeries':
        updateValue = 60;
        break;
      case 'vehicleVariant':
        updateValue = 80;
        break;
      default:
        updateValue = 70;
        break;
    }
    onInputChange('progress', updateValue, false);
    flagChange('prefillRecords', false, false);
    onInputChange(fieldName, value, scrollToNextFieldOnChage);
    if (
      fieldName === 'carCondition' ||
      fieldName === 'purchaseUse' ||
      fieldName === 'purchaseSource' ||
      fieldName === 'purchasePrice' ||
      fieldName === 'depositAmount'
    ) {
      createQuote();
    }
    if (
      fieldName === 'carCondition' &&
      (carCondition === 'New' ||
        manualEnter ||
        carCondition === 'Demo' ||
        carCondition === 'Used' ||
        carCondition === 'Unsure')
    ) {
      flagChange('depositAmount', 0);
    }
    if (carCondition === 'New' || manualEnter || carCondition === 'Demo') {
      noErrors();
      if (fieldName === 'manufacturingYear') {
        getAllVehicleMakes();
        createQuote();
      }
      if (fieldName === 'vehicleMake') {
        getModelForVehicleMakes();
      }
      if (fieldName === 'vehicleModel') {
        getSeriesForVehicleModel();
      }
      if (fieldName === 'vehicleVariant') {
        getfactoryOptions();
      }
    }
  };

  const handleSkip = (fieldName) => {
    onInputChange('skippedActionTriggered', true, false);
    onInputChange(fieldName, true, false);
  };
  const selectedfactoryData = find(factoryOptions, ['selected', true]);
  const maxTermVal = 7;

  const estimationChange = (fieldName, value) => {
    onEstimateChange(fieldName, value, false);
    flagChange('prefillRecords', false, false);
    flagChange('term', value, false);
  };
  const cardItems = [{ title: 'Be gainfully employed', tooltipText: '', link: '', linkText: '' },
  { title: 'Have a clean credit history.', tooltipText: '', link: '', linkText: '' },
  { title: 'Be older than 18 years of age.', tooltipText: '', link: '', linkText: '' },
  { title: 'Be a resident or citizen of Australia.', tooltipText: '', link: '', linkText: '' }];

  return (
    <>
      <div className="container-section car-applicants-main-container full-width-container">
        <div className="content-body pt-4 car-applicants-main-tab-container" id="car">
          <div className="car-applicants-main-subcontainer px-0">
            <div className="row mx-0">
              <div className="col-12  text-center car-applicants-main-section">
                <Form noValidate className="car-applicants-main-form">
                  <InputButton
                    label={
                      <>
                        <span>What type of car are you planning to buy?</span>
                      </>
                    }
                    fields={CAR_CONDITION}
                    onChange={handleFieldChange}
                    fieldName="carCondition"
                    value={carCondition}
                    // scrollToNextFieldOnChage
                    formGroupClass="car-condition fit-screen"
                    btnClass="full-screen-mode"
                    labelClass="car-full-screen-label"
                    getvalidationerrors={getValidationErrorsForField}
                    id="car-condition"
                  />

                  {carCondition === 'Used' && !manualEnter && (
                    <div
                      className={cls(
                        'car-rego-question-container rego',
                        searchComplete && 'fit-screen',
                      )}
                    >
                      <div className="car-applicants-main-rego-question pb-4 label">
                        Let's find the car you're planning to buy
                      </div>
                      <RegoSearch
                        getValidationErrorsForField={getValidationErrorsForField}
                        handleFieldChange={handleFieldChange}
                        onInputChange={onInputChange}
                        quoteInProgress={quoteInProgress}
                        regoNumber={regoNumber}
                        regoSearchError={regoSearchError}
                        regoSearchInProgress={regoSearchInProgress}
                        regoState={regoState}
                        regoVehicleSearch={regoVehicleSearch}
                        searchCar={searchCar}
                        props={props}
                      />

                      {!searchCar && (
                        <div className="rego-manual">
                          <div className="or-style">Don't know the rego number?</div>
                          <div className="manualenter-style">
                            <button
                              type="button"
                              className="btn manual-entry-btn"
                              onClick={() => {
                                onInputChange('manualEnter', true, false);
                              }}
                            >
                              Enter car manually
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* for New */}
                  {(carCondition === 'New' || manualEnter || carCondition === 'Demo') && (
                    <>
                      <FindMakeFilter
                        label={
                          <>
                            <span>When was the car manufactured?</span>
                            <Tooltip
                              label="Learn more"
                              message="The year has an impact on the rate and typically the newer the car the better the rate. You will be able to come back and change this if you are undecided."
                            />
                          </>
                        }
                        fields={vehicleYearsformatted.map((yr) => ({
                          name: String(yr),
                          value: yr,
                        }))}
                        onChange={handleFieldChange}
                        fieldName="manufacturingYear"
                        value={manufacturingYear}
                        formGroupClass="fit-screen"
                        btnClass="full-screen-mode"
                        labelClass="car-full-screen-label"
                        rootContainerClass="year-class car-manufactured-year"
                        loadmore
                        isFullScreen
                        isreturnObjectval
                        getvalidationerrors={getValidationErrorsForField}
                        id="car-manufactured-year"
                      />
                      {manufacturingYear && !skippedVehicleDetails && (
                        <>
                          <FindMakeFilter
                            label={
                              <>
                                <span>What's the make of the car?</span>
                              </>
                            }
                            fields={
                              (vehicleMakesData && vehicleMakesData.length && vehicleMakesData) ||
                              []
                            }
                            onChange={handleFieldChange}
                            fieldName="vehicleMake"
                            rootContainerClass="find-make-container car-make"
                            value={vehicleMake && vehicleMake.value}
                            formGroupClass="fit-screen"
                            btnClass="full-screen-mode"
                            labelClass="car-full-screen-label"
                            isLoading={isLoadingcarmakes}
                            enableSearch
                            isFullScreen
                            getvalidationerrors={getValidationErrorsForField}
                            id="car-make"
                          />

                          <Form.Row
                            className={cls('skip-action-container', vehicleMake && 'hidden')}
                          >
                            <FormGroup as={Col} lg={9} md={10}>
                              <div className="skip-action-section">
                                <div className="skip-action-align">
                                  <span className="notsure">Not sure? </span>
                                  <button
                                    type="button"
                                    className="btn btn-default btn-skip-page"
                                    hidden={vehicleMake}
                                    onClick={() => {
                                      handleSkip('skippedVehicleDetails');
                                    }}
                                  >
                                    Skip
                                    <i className="fa fa-chevron-right" />
                                  </button>
                                </div>
                              </div>
                            </FormGroup>
                          </Form.Row>
                        </>
                      )}
                      {vehicleMake && !skippedVehicleDetails && !skipModel && (
                        <>
                          <FindMakeFilter
                            label={
                              <>
                                <span>
                                  What's the model of the&nbsp;
                                  <span className="sub-label">{`${vehicleMake.name}?`}</span>
                                </span>
                              </>
                            }
                            fields={
                              (vehicleModelsData &&
                                vehicleModelsData.length &&
                                vehicleModelsData) ||
                              []
                            }
                            onChange={handleFieldChange}
                            fieldName="vehicleModel"
                            btnClass="full-screen-mode"
                            rootContainerClass="find-model-container car-model"
                            value={vehicleModel && vehicleModel.value}
                            formGroupClass="fit-screen"
                            loadmore
                            labelClass="car-full-screen-label"
                            isFullScreen
                            getvalidationerrors={getValidationErrorsForField}
                            id="car-model"
                          />
                          <Form.Row
                            className={cls('skip-action-container', vehicleModel && 'hidden')}
                          >
                            <FormGroup as={Col} lg={9} md={10}>
                              <div className="skip-action-section">
                                <div className="skip-action-align">
                                  <span className="notsure">Not sure? </span>
                                  <button
                                    type="button"
                                    className="btn btn-default btn-skip-page"
                                    hidden={vehicleModel}
                                    onClick={() => {
                                      handleSkip('skipModel');
                                    }}
                                  >
                                    Skip
                                    <i className="fa fa-chevron-right" />
                                  </button>
                                </div>
                              </div>
                            </FormGroup>
                          </Form.Row>
                        </>
                      )}
                      {hideSeries &&
                        vehicleModel &&
                        !skipModel &&
                        !skippedVehicleDetails &&
                        !skipSeries && (
                          <>
                            <FindMakeFilter
                              label={
                                <>
                                  <span>
                                    What series is the
                                    <span className="sub-label">{` ${vehicleMake.name}`}</span>
                                    <span className="sub-label">{` ${vehicleModel.name}?`}</span>
                                  </span>
                                </>
                              }
                              fields={
                                (vehicleSeriesdata &&
                                  vehicleSeriesdata.length &&
                                  vehicleSeriesdata) ||
                                []
                              }
                              onChange={handleFieldChange}
                              fieldName="vehicleSeries"
                              rootContainerClass="find-series-container car-series"
                              value={vehicleSeries && vehicleSeries.value}
                              formGroupClass="fit-screen"
                              btnClass="full-screen-mode"
                              labelClass="car-full-screen-label"
                              loadmore
                              isLoading={isLoadingcarmakes}
                              isFullScreen
                              getvalidationerrors={getValidationErrorsForField}
                              id="car-series"
                            />
                            <Form.Row
                              className={cls('skip-action-container', vehicleSeries && 'hidden')}
                            >
                              <FormGroup as={Col} lg={9} md={10}>
                                <div className="skip-action-section">
                                  <div className="skip-action-align">
                                    <span className="notsure">Not sure? </span>
                                    <button
                                      type="button"
                                      className="btn btn-default btn-skip-page"
                                      hidden={vehicleSeries}
                                      onClick={() => {
                                        handleSkip('skipSeries');
                                      }}
                                    >
                                      Skip
                                      <i className="fa fa-chevron-right" />
                                    </button>
                                  </div>
                                </div>
                              </FormGroup>
                            </Form.Row>
                          </>
                        )}
                      {hideSeries &&
                        vehicleSeries &&
                        !skipModel &&
                        !skippedVehicleDetails &&
                        !skipSeries &&
                        !skipVariant && (
                          <>
                            <InputButton
                              label={
                                <>
                                  <span>
                                    What's the variant of the&nbsp;
                                    <span className="sub-label">{vehicleMake.name}</span>
                                    <span className="sub-label">{` ${vehicleModel.name}?`}</span>
                                  </span>
                                </>
                              }
                              fields={
                                (vehicleVariantsdata &&
                                  vehicleVariantsdata.length &&
                                  vehicleVariantsdata) ||
                                []
                              }
                              onChange={handleFieldChange}
                              fieldName="vehicleVariant"
                              value={vehicleVariant && vehicleVariant.value}
                              returnObject
                              labelClass="car-full-screen-label"
                              sectionContainerClass="vehicle-variant-options"
                              btnClass="vehicle-variant-nvic full-screen-mode"
                              formGroupClass="car-variant"
                              isFullScreen
                              getvalidationerrors={getValidationErrorsForField}
                              id="car-variant"
                            />
                            <div className="prepopulate-scroll-locator scrollable-anchor-tag car-factory-options">
                              <a name="car-factory-options" href="#car-factory-options">
                                &nbsp;
                              </a>
                            </div>
                            <Form.Row
                              className={cls('skip-action-container', vehicleVariant && 'hidden')}
                            >
                              <FormGroup as={Col} lg={9} md={10}>
                                <div className="skip-action-section">
                                  <div className="skip-action-align">
                                    <span className="notsure">Not sure? </span>
                                    <button
                                      type="button"
                                      className="btn btn-default btn-skip-page"
                                      hidden={vehicleVariant}
                                      onClick={() => {
                                        handleSkip('skipVariant');
                                      }}
                                    >
                                      Skip
                                      <i className="fa fa-chevron-right" />
                                    </button>
                                  </div>
                                </div>
                              </FormGroup>
                            </Form.Row>
                            {vehicleVariant &&
                              vehicleVariant.value &&
                              (carCondition === 'New' || carCondition === 'Demo' || manualEnter) &&
                              !!factoryOptions && (
                                <PrePopulateFactoryOptions
                                  onClear={() => {
                                    handleFieldChange('searchCar', false, false);
                                    handleFieldChange('regoNumber', null, false);
                                    handleFieldChange('regoState', null, false);
                                  }}
                                  onManualEnter={() => {
                                    handleFieldChange('searchCar', false, false);
                                    handleFieldChange('regoNumber', null, false);
                                    handleFieldChange('regoState', null, false);
                                    handleFieldChange('isRegoKnown', false, false);
                                  }}
                                  factoryDataAlign
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...props}
                                />
                              )}
                          </>
                        )}
                    </>
                  )}

                  {carCondition === 'Unsure' && (
                    <div
                      className={cls(
                        'amount-range-container purchase-price-selector car-purchase-price',
                        'scrollable-anchor-tag',
                      )}
                    >
                      <a name="car-purchase-price" href="#car-purchase-price">
                        &nbsp;
                      </a>
                      <div className="label car-main-screen-label">
                        What is the estimated purchase price of the car?
                      </div>
                      <div className='margin-custum-card'>
                        <CustomCard
                          className="newCard"
                          title="To be considered for a loan you will need to:"
                          items={cardItems}
                        />
                      </div>
                    </div>
                  )}


                  {carCondition === 'Unsure' && (
                    <div
                      className={cls(
                        'amount-range-container purchase-price-selector car-purchase-price',
                        'scrollable-anchor-tag',
                      )}
                    >
                      <a name="car-purchase-price" href="#car-purchase-price">
                        &nbsp;
                      </a>
                      <div className="label car-main-screen-label">
                        How much you are planning to spend?
                      </div>
                      <AmountRangeSelector
                        label={
                          <>
                            <span className="label ">Purchase price</span>
                          </>
                        }
                        onChange={onInputChange}
                        fieldName="purchasePrice"
                        defaultValue={purchasePrice}
                        colsm={12}
                        colmd={8}
                        oncreateQuote={createQuote}
                        getvalidationerrors={getValidationErrorsForField}
                        minValue={5000}
                        maxValue={250000}
                        step={1000}
                      />
                    </div>
                  )}
                  {carCondition === 'Unsure' && purchasePriceComplete && (
                    <>
                      <div className="deposit scrollable-anchor-tag">
                        <a name="deposit" href="#deposit">
                          &nbsp;
                        </a>
                      </div>
                      <div
                        className={cls(
                          'amount-range-container deposit-price-selector',
                          depositPriceComplete && 'fit-screen',
                        )}
                      >
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="label ">Deposit/Trade-in</span>
                            </>
                          }
                          onChange={onInputChange}
                          fieldName="depositAmount"
                          defaultValue={depositAmount}
                          colsm={12}
                          colmd={8}
                          oncreateQuote={createQuote}
                          minValue={0}
                          maxValue={purchasePrice}
                          step={1000}
                          tooltip="Enter the combined amount for the trade in value of your current car, and any cash deposit you will be contributing to the purchase"
                          getvalidationerrors={getValidationErrorsForField}
                          flagChange={flagChange}
                        />
                        <div className="form-row pb-4">
                          <div className=" car-applicants-loan-form col-md-5">
                            <div className="car-applicants-main-loanamount">
                              <NumberFormat
                                displayType="text"
                                value={purchasePrice - depositAmount}
                                className="loan-amount-value"
                                id="loan-amount-value"
                                prefix="$"
                                thousandSeparator
                              />
                            </div>

                            <label
                              className="car-applicants-main-loanamount-label label"
                              htmlFor="loan-amount-value"
                            >
                              Your estimated loan amount.
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {depositPriceComplete && carCondition === 'Unsure' && (
                    <FindMakeFilter
                      label={
                        <>
                          <span>How old do you estimate the car to be?</span>
                        </>
                      }
                      fields={vehicleYearsMax10}
                      onChange={handleFieldChange}
                      fieldName="manufacturingYear"
                      value={manufacturingYear}
                      formGroupClass="fit-screen"
                      btnClass="full-screen-mode"
                      labelClass="car-full-screen-label"
                      rootContainerClass="year-class car-manufactured-year"
                      loadmore
                      isFullScreen
                      isreturnObjectval
                      getvalidationerrors={getValidationErrorsForField}
                      id="car-manufactured-year"
                    />
                  )}
                  {/* Common Fields */}
                  {(searchComplete ||
                    ((carCondition === 'Demo' || manualEnter) &&
                      manufacturingYear &&
                      carCondition !== 'New' &&
                      (skippedVehicleDetails ||
                        skipModel ||
                        skipSeries ||
                        skipVariant ||
                        // to be reverted after model fix
                        // (vehicleVariant && (factoryOptionsComplete || nofactoryOptions)))) ||
                        vehicleModel)) ||
                    (carCondition === 'Unsure' && manufacturingYear && depositPriceComplete)) && (
                      <InputButton
                        label={
                          <>
                            <span>Where are you planning to purchase the car from?</span>
                          </>
                        }
                        fields={PURCHASE_SOURCE}
                        onChange={handleFieldChange}
                        fieldName="purchaseSource"
                        btnClass="full-screen-mode"
                        value={purchaseSource}
                        labelClass="car-full-screen-label"
                        formGroupClass={`fit-screen ${skippedActionTriggered ? 'skipped-action' : 'car-purchase-from'
                          }`}
                        getvalidationerrors={getValidationErrorsForField}
                        id="car-purchase-from"
                      />
                    )}

                  {((purchaseSource && carCondition !== 'New') ||
                    (carCondition === 'New' &&
                      (skippedVehicleDetails ||
                        skipModel ||
                        skipSeries ||
                        skipVariant ||
                        // to be reverted after model fix
                        // (vehicleVariant && (factoryOptionsComplete || nofactoryOptions))))) && (
                        vehicleModel))) &&
                    manufacturingYear && (
                      <InputButton
                        label={
                          <div className="title-with-sub-label car-details-full-screen">
                            <div className="car-details-main-label">
                              How will the car be used for the majority of the time?
                            </div>
                            <span className="car-details-main-sublabel">
                              Select business if you use your car for work more than 50% of the time
                            </span>
                            <Tooltip message="Using your car for business includes carrying tools to multiple job sites, driving between clients etc. You will need to provide proof of business use." />
                          </div>
                        }
                        fields={PURCHASE_USE}
                        onChange={handleFieldChange}
                        fieldName="purchaseUse"
                        btnClass="full-screen-mode"
                        value={purchaseUse}
                        // scrollToNextFieldOnChage
                        formGroupClass={cls(
                          carCondition !== 'Unsure' && 'fit-screen',
                          skippedActionTriggered && 'skipped-action',
                          'car-usage',
                        )}
                        getvalidationerrors={getValidationErrorsForField}
                        id="car-usage"
                      />
                    )}

                  {purchaseUse && carCondition !== 'Unsure' && (
                    <div
                      className={cls(
                        'amount-range-container purchase-price-selector car-purchase-price',
                        'scrollable-anchor-tag',
                      )}
                    >
                      <a name="car-purchase-price" href="#car-purchase-price">
                        &nbsp;
                      </a>
                      <div className="label car-main-screen-label">
                        What is the estimated purchase price of the car?
                      </div>
                      <div>
                        <CustomCard
                          className="newCard"
                          title="To be considered for a loan you will need to:"
                          items={cardItems}
                        />
                      </div>
                      <AmountRangeSelector
                        label={
                          <>
                            <span className="label ">Purchase price</span>
                          </>
                        }
                        onChange={onInputChange}
                        fieldName="purchasePrice"
                        defaultValue={purchasePrice}
                        colsm={12}
                        colmd={8}
                        oncreateQuote={createQuote}
                        getvalidationerrors={getValidationErrorsForField}
                        minValue={5000}
                        maxValue={250000}
                        step={1000}
                        flagChange={flagChange}
                      />
                    </div>
                  )}

                  {purchasePriceComplete && carCondition !== 'Unsure' && (
                    <>
                      <div className="deposit scrollable-anchor-tag">
                        <a name="deposit" href="#deposit">
                          &nbsp;
                        </a>
                      </div>
                      <div className="amount-range-container deposit-price-selector">
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="label">Deposit / Trade-in</span>
                            </>
                          }
                          onChange={onInputChange}
                          fieldName="depositAmount"
                          defaultValue={depositAmount}
                          colsm={12}
                          colmd={8}
                          minValue={0}
                          tooltip="Enter the combined amount for the trade in value of your current car, and any cash deposit you will be contributing to the purchase"
                          oncreateQuote={createQuote}
                          maxValue={purchasePrice - 5000}
                          step={1000}
                          disabled={purchasePrice - 5000 <= 0}
                          getvalidationerrors={getValidationErrorsForField}
                          flagChange={flagChange}
                        />
                        <div className="form-row pb-4">
                          <div className=" car-applicants-loan-form col-md-5 ">
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}

                            <div className="car-applicants-main-loanamount">
                              <NumberFormat
                                displayType="text"
                                value={purchasePrice - depositAmount}
                                className="loan-amount-value"
                                prefix="$"
                                thousandSeparator
                              />
                            </div>
                            <label htmlFor="car-applicants-main-loanamount-label label">
                              Estimated loan amount
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {depositPriceComplete && purchaseUse && (
                    <div
                      className={cls(
                        'amount-range-container loan-term-selector car-loan-term',
                        'scrollable-anchor-tag',
                      )}
                    >
                      <div className="label car-main-screen-label">
                        Over what term do you want the loan?
                      </div>
                      <div className="term scrollable-anchor-tag">
                        <a name="term" href="#term">
                          &nbsp;
                        </a>
                      </div>

                      <div className="amount-range-container term-val-selector">
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="label">Loan term</span>
                            </>
                          }
                          onChange={estimationChange}
                          fieldName="termVal"
                          defaultValue={termVal}
                          oncreateQuote={createQuote}
                          minValue={1}
                          maxValue={maxTermVal}
                          step={1}
                          termSlider
                          getvalidationerrors={getValidationErrorsForField}
                          flagChange={flagChange}
                        />
                      </div>
                    </div>
                  )}

                  <div className="footer-actions fit-screen">
                    <button
                      type="button"
                      className="btn btn-primary btn-next-page"
                      hidden={
                        !carCondition ||
                        !purchasePrice ||
                        ((!searchCar || regoSearchInProgress) &&
                          carCondition === 'Used' &&
                          (!purchasePrice || purchasePrice === 50000) &&
                          !manualEnter) ||
                        ((!purchasePrice ||
                          (purchasePrice === 50000 &&
                            !skippedVehicleDetails &&
                            !skipModel &&
                            !skipSeries &&
                            !skipVariant)) &&
                          (carCondition === 'New' || carCondition === 'Demo' || manualEnter) &&
                          !purchaseUse) || // to be removed after variant and series fix  !vehicleVariant
                        ((carCondition === 'New' || carCondition === 'Demo' || manualEnter) &&
                          !vehicleVariant &&
                          (skippedVehicleDetails || skipModel || skipSeries || skipVariant) &&
                          !purchaseUse &&
                          !depositAmount) ||
                        ((carCondition === 'New' || carCondition === 'Demo' || manualEnter) &&
                          factoryOptionsComplete &&
                          vehicleVariant &&
                          !purchaseUse &&
                          !depositAmount) ||
                        (!purchasePrice && carCondition === 'Unsure') ||
                        (searchComplete && !purchaseUse && !depositAmount) ||
                        (purchasePriceComplete &&
                          carCondition !== 'Unsure' &&
                          depositPriceComplete) ||
                        (depositPriceComplete && carCondition === 'Unsure')
                      }
                      disabled={
                        (validationErrors && validationErrors.length) ||
                        purchasePrice - depositAmount < 5000
                      }
                      onClick={() => {
                        flagChange('purchasePrice', purchasePrice || 50000);
                        if (!purchasePriceComplete && depositAmount) {
                          onInputChange('depositAmount', 0, true);
                        }
                        if (prefillRecords && depositAmountUrl) {
                          flagChange('depositAmount', parseFloat(depositAmountUrl));
                        }

                        noErrors();
                        if (searchCar && !searchComplete) {
                          onInputChange('searchComplete', true, false);
                        } else if (
                          vehicleVariant &&
                          !factoryOptionsComplete &&
                          (carCondition === 'New' || carCondition === 'Demo' || manualEnter)
                        ) {
                          onInputChange('factoryOptionsComplete', true, false);
                        } else if (purchasePrice && !purchasePriceComplete) {
                          onInputChange('purchasePriceComplete', true, false);
                          processGTMevent('Car', 'purchasePriceValue', purchasePrice);
                        } else if (
                          (depositAmount || depositAmount === 0 || depositAmount === null) &&
                          !depositPriceComplete
                        ) {
                          onInputChange('depositPriceComplete', true, false);
                          processGTMevent('Car', 'depositAmountValue', depositAmount);
                        } else if (
                          (depositAmount || depositAmount === 0 || depositAmount === null) &&
                          carCondition === 'Unsure'
                        ) {
                          if (purchasePrice - depositAmount < 5000) {
                            validationCheck('Loan Amount cannot be less than $5000.');
                            return false;
                          }
                          onInputChange('depositPriceComplete', true, false);
                          processGTMevent('Car', 'depositAmountValue', depositAmount);
                        }
                      }}
                    >
                      Next
                      <span className="pl-4">
                        <img src={rightnormal} alt="right" className="right-icon" />
                      </span>
                    </button>
                    <input
                      type="button"
                      id="btnsubmit"
                      className="btn-dummy-page"
                      style={{ visibility: 'hidden' }}
                    />
                    {((!!purchasePrice &&
                      purchasePriceComplete &&
                      depositPriceComplete &&
                      !!termVal &&
                      carCondition !== 'Unsure') ||
                      (carCondition === 'Unsure' &&
                        depositPriceComplete &&
                        !!purchaseUse &&
                        !!termVal)) && (
                        <button
                          type="button"
                          data-test="carmainscreen-estimatepage"
                          className="btn  btn-navigate-page"
                          onClick={handleClick}
                          disabled={quoteInProgress}
                        >
                          {quoteInProgress ? (
                            <CircularProgress color="inherit" size={24} thickness={2.6} />
                          ) : (
                            <>
                              Next
                              <span className="pl-4">
                                <img src={rightnormal} alt="right" className="right-icon" />
                              </span>
                            </>
                          )}
                        </button>
                      )}
                    {ValidationErrorFlag && (
                      <div className="p-0 container">
                        <Alert variant="danger" className="error-alert py-4 px-2">
                          <div className="error-flex">
                            <div className="error-header-message">
                              {searchCar ? 'We could not identify your details.' : 'Error'}
                            </div>
                            <p className="error-message">{errorMessage}</p>
                          </div>
                        </Alert>
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-cardetails-page container-fluid sticky-bottom">
        {!newpartnerflag && (
          <DescriptionTextPartner partnerItem={findPartnerandDescription(partnername)} />
        )}
        <ProgressBar progressValue={progressValue} />
      </div>
    </>
  );
};

export default CarFinance;
