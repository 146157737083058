import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import TextField from '../../../components/TextField';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InputButton from '../../../components/InputButton';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PURCHASE_SOURCE, SETTLEMENT } from '../../../util/constants';
import { reverse, sortBy } from 'lodash';
import FinaliseCarHeader from './sub-components/FinaliseCar-Header';
import RegoSearch from '../../../components/RegoSearch';
import fieldChanged, { liteFieldChanged } from '../../../Actions/FieldChanged';
import { createQuote, populateCarSeries } from '../../../Actions/car';
import { noErrors } from '../../../Actions/errorHandler';
import { getValidationErrorsForFieldFunc } from '../../../util/ContainerUtilities';
import ManualForm from './sub-components/ManualForm';
import './finalise-car.scss';
import {
  getAllVehicleYears,
  populateCarMakeSettlement,
  populateCarModel,
  regoNumberSearchForSettlement,
} from '../../../Actions/settlement';
import { Alert } from 'react-bootstrap';
import call from '../../../../assets/image/call.svg';

const FinaliseCar = (props) => {
  const {
    year,
    make,
    model,
    models,
    transmission,
    vehicleYears,
    manufacturingYear,
    skippedVehicleDetails,
    vehicleMakesData,
    vehicleModelsData,
    vehicleSeriesdata,
    vehicleVariantsdata,
    progressValue,
    carCondition,
    regoNumber,
    regoState,
    searchCar,
    onInputChange,
    flagChange,
    manualEnter,
    getAllVehicleMakes,
    getModelForVehicleMakes,
    getSeriesForVehicleModel,
    quoteInProgress,
    regoSearchError,
    regoSearchInProgress,
    getValidationErrorsForField,
    isLoadingcarmakes,
    vehicleMake,
    vehicleModel,
    purchaseSource,
    regoNumberSearch,
    vehicleSeries,
    vehicleBodyType,
    vehicleVariant,
    nvicMake,
    nvicModel,
    nvicVariant,
    nvicSeries,
    vehicleEngine,
    getAllVehicleYears,
    ValidationErrorFlag,
    errorMessage,
    sellerFirstName,
    sellerLastName,
    sellerEmail,
    sellerPhoneNumber,
  } = props;
  const vehicleYearsformatted = reverse(sortBy(vehicleYears));
  useEffect(() => {
    getAllVehicleYears('');
  }, []);
  const handleFieldChange = (fieldName, value, scrollToNextFieldOnChage) => {
    let updateValue = progressValue;
    switch (fieldName) {
      case 'regoNumber':
        updateValue = 20;
        break;
      case 'regoState':
        updateValue = 25;
        break;
      case 'manufacturingYear':
        updateValue = 30;
        break;
      case 'vehicleMake':
        updateValue = 40;
        break;
      case 'vehicleModel':
        updateValue = 50;
        break;
      default:
        updateValue = 70;
        break;
    }
    onInputChange('progress', updateValue, false);
    flagChange('prefillRecords', false, false);
    onInputChange(fieldName, value, scrollToNextFieldOnChage);

    if (manualEnter) {
      noErrors();
      if (fieldName === 'manufacturingYear') {
        getAllVehicleMakes();
        createQuote();
      }
      if (fieldName === 'vehicleMake') {
        getModelForVehicleMakes();
      }
      if (fieldName === 'vehicleModel') {
        getSeriesForVehicleModel();
      }
    }
  };
  const regoVehicleSearch = () => {
    noErrors();
    regoNumberSearch();
  };

  return (
    <Grid container style={{ backgroundColor: 'white' }}>
      <FinaliseCarHeader make={make} model={model} year={year} />
      <Grid item sx={12} md={12} lg={12}>
        <Grid container mt={1} className="pa-rl-24 border-tp m-t-1 p-t-2">
          {!manualEnter && (
            <RegoSearch
              regoNumber={regoNumber}
              getValidationErrorsForField={getValidationErrorsForField}
              handleFieldChange={handleFieldChange}
              onInputChange={onInputChange}
              quoteInProgress={quoteInProgress}
              regoSearchError={regoSearchError}
              regoSearchInProgress={regoSearchInProgress}
              regoState={regoState}
              regoVehicleSearch={regoVehicleSearch}
              searchCar={searchCar}
              props={props}
              showFactoryOptions={false}
            />
          )}
        </Grid>
      </Grid>

      <Grid item sm={12} md={12} lg={12} className="mt-2 rego-manual">
        {manualEnter ? (
          <>
            <p>Know the rego number?</p>
            <button
              type="button"
              onClick={() => {
                onInputChange('manualEnter', false, false);
              }}
            >
              Rego Search <ChevronRightIcon />
            </button>
          </>
        ) : (
          <>
            <p>Don't know the rego number?</p>
            <button
              type="button"
              onClick={() => {
                onInputChange('manualEnter', true, false);
              }}
            >
              Enter car manually <ChevronRightIcon />
            </button>
          </>
        )}
      </Grid>

      {ValidationErrorFlag && regoSearchError && !manualEnter && (
        <Grid sm={12} md={12} lg={12} item className="pa-rl-24">
          <div className="p-0 container">
            <Alert variant="danger" className="error-alert py-4 px-2">
              <div className="error-flex">
                <div className="error-header-message">
                  {searchCar ? 'We could not identify your details.' : 'Error'}
                </div>
                <p className="error-message">{errorMessage}</p>
              </div>
            </Alert>
          </div>
        </Grid>
      )}
      {manualEnter && (
        <Grid item sm={12} md={12} lg={12} className="pa-left-24">
          <ManualForm
            getValidationErrorsForField={getValidationErrorsForField}
            handleFieldChange={handleFieldChange}
            manufacturingYear={manufacturingYear}
            vehicleYearsformatted={vehicleYearsformatted}
            isLoadingcarmakes={isLoadingcarmakes}
            vehicleMake={vehicleMake}
            vehicleMakesData={vehicleMakesData}
            vehicleModelsData={models}
            vehicleModel={vehicleModel}
          />
        </Grid>
      )}
      <Grid item sm={12} md={12} lg={12} className="pa-left-24 border-top pt-2">
        <InputButton
          label={
            <>
              <span>Where are you planning to purchase the car from?</span>
            </>
          }
          fields={PURCHASE_SOURCE}
          onChange={handleFieldChange}
          fieldName="purchaseSource"
          value={purchaseSource}
          formGroupClass="car-purchase-from"
          id="car-purchase-from"
          getvalidationerrors={getValidationErrorsForField}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={6} className="common-pa-mg">
        <TextField
          fieldName="sellerFirstName"
          value={sellerFirstName}
          label="Seller First Name"
          placeholder="e.g John"
          getvalidationerrors={getValidationErrorsForField}
          onChange={handleFieldChange}
          inline
        />
      </Grid>
      <Grid item sm={12} md={6} lg={6} className="common-pa-mg">
        <TextField
          fieldName="sellerLastName"
          value={sellerLastName}
          label="Seller Last Name"
          placeholder="e.g Doe"
          getvalidationerrors={getValidationErrorsForField}
          onChange={handleFieldChange}
          inline
        />
      </Grid>
      <Grid item sm={12} md={6} lg={6} className="common-pa-mg">
        <TextField
          fieldName="sellerEmail"
          value={sellerEmail}
          label="Seller Email"
          placeholder="Email address"
          getvalidationerrors={getValidationErrorsForField}
          onChange={handleFieldChange}
          inline
        />
      </Grid>
      <Grid item sm={12} md={6} lg={6} className="common-pa-mg">
        <TextField
          fieldName="sellerPhoneNumber"
          value={sellerPhoneNumber}
          label="Seller Phone Number"
          className="phone-number"
          format="##########"
          placeholder="0400000000"
          image={call}
          getvalidationerrors={getValidationErrorsForField}
          onChange={onInputChange}
          colmd={0}
          type="number"
          inline
          allowLeadingZeros
        />
      </Grid>
      <Grid item sm={12} md={12} lg={12}>
        <div className="next-action-button">
          <button type="button" className="btn btn-primary btn-next-page">
            Next <ChevronRightIcon />
          </button>
        </div>
      </Grid>
    </Grid>
  );
};
export default connect(
  (state) => ({
    currentTab: state.tab.tabName,
    make: state.car.vehicleMake,
    model: state.car.vehicleModel,
    year: state.car.manufacturingYear,
    transmission: state.settlement.transmission,
    // ABOVE DETAILS REQUIRED FOR CAR CHOSEN BY USER.
    vehicleBodyType: state.settlement.vehicleBodyType,
    vehicleYears: state.settlement.years,
    manufacturingYear: state.settlement.manufacturingYear,
    models: state.settlement.models,
    vehicleModel: state.settlement.vehicleModel,
    sellerFirstName: state.settlement.sellerFirstName,
    sellerLastName: state.settlement.sellerLastName,
    sellerEmail: state.settlement.sellerEmail,
    sellerPhoneNumber: state.settlement.sellerPhoneNumber,
    validationErrors: state.applicants.validationErrors,
    quoteInProgress: state.account.quoteInProgress,
    stage: state.applicants.stage,
    assetType: state.car.assetType?.replace(/\s+/g, '').toLowerCase(),
    assetTypeName: state.car.assetTypeName,
    finaliseCar: state.car.finaliseCar,
    vehicleMakesData: state.car.makes,
    vehicleModelsData: state.car.models,
    vehicleSeriesdata: state.car.series,
    vehicleVariantsdata: state.car.variants,
    progressValue: state.car.progress,
    carCondition: state.car.carCondition,
    regoNumber: state.settlement.regoNumber,
    regoState: state.settlement.regoState,
    searchCar: state.settlement.searchCar,
    manualEnter: state.settlement.manualEnter,
    quoteInProgress: state.account.quoteInProgress,
    regoSearchError: state.settlement.regoSearchError,
    regoSearchInProgress: state.settlement.regoSearchInProgress,
    isLoadingcarmakes: state.settlement.isLoadingcarmakes,
    vehicleMake: state.settlement.vehicleMake,
    getValidationErrorsForField: getValidationErrorsForFieldFunc(state.settlement.validationErrors),
    purchaseSource: state.settlement.purchaseSource,
    vehicleEngine: state.settlement.vehicleEngine,
    vehicleSeries: state.car.vehicleSeries,
    vehicleVariant: state.car.vehicleVariant,
    nvicMake: state.settlement.nvicMake,
    nvicModel: state.settlement.nvicModel,
    nvicVariant: state.settlement.nvicVariant,
    nvicSeries: state.settlement.nvicSeries,
    ValidationErrorFlag: state.errorHandler.ValidationErrorFlag,
    errorMessage: state.errorHandler.errorMessage,
  }),
  (dispatch) => ({
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged(SETTLEMENT, fieldName, value, scrollToNextFieldOnChage));
      dispatch(liteFieldChanged(SETTLEMENT, 'settlementDetailsChanged', true, false));
    },
    flagChange: (fieldName, value) => dispatch(liteFieldChanged('settlement', fieldName, value)),
    createQuote: () => dispatch(createQuote()),
    noErrors: () => dispatch(noErrors()),
    getAllVehicleMakes: () => dispatch(populateCarMakeSettlement()),
    getModelForVehicleMakes: () => dispatch(populateCarModel()),
    getSeriesForVehicleModel: () => dispatch(populateCarSeries()),
    regoNumberSearch: () => dispatch(regoNumberSearchForSettlement()),
    getAllVehicleYears: () => dispatch(getAllVehicleYears()),
  }),
)(withRouter(FinaliseCar));
