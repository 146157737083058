/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Form, Col, Modal, Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import OtpInput from 'react-otp-input';
import InputGroup from 'react-bootstrap/InputGroup';
import Email from '../../../assets/image/Email.svg';
import eyeImg from '../../../assets/image/Eye-on.svg';
import { ROUTES } from '../../util/constants';
import risk from '../../../assets/image/risk.svg';
import logo from '../../../assets/image/stratton-logo-transparent.png';
import Copyright from '../../components/Copyright';
import personalDetailsBackground from '../../../assets/image/Context_pd.svg';
import leftArrow from '../../../assets/image/Chevron-Left.svg';
import rightArrow from '../../../assets/image/Arrow-Right.svg';
import check_Success from '../../../assets/image/Check_success.svg';
import { PASSWORD_CHECK_REGEX } from '../../util/regexpConstants';

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      codeSent: false,
      confirmed: false,
      confirmPassword: '',
      showConfirmPassword: false,
      isConfirming: false,
      isSendingCode: false,
      mobileOtp: '',
      error: '',
      showPassword: false,
      showmodal: true,
      isPasswordValid: false,
    };
    this.onLogoClick = this.onLogoClick.bind(this);
    this.getQuoteClick = this.getQuoteClick.bind(this);
    this.validateCodeForm = this.validateCodeForm.bind(this);
    this.validateResetForm = this.validateResetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSendCodeClick = this.handleSendCodeClick.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.renderRequestCodeForm = this.renderRequestCodeForm.bind(this);
    this.renderConfirmationForm = this.renderConfirmationForm.bind(this);
    this.renderSuccessMessage = this.renderSuccessMessage.bind(this);
    this.handleConfirmationSubmit = this.handleConfirmationSubmit.bind(this);
    this.resendConfirmationCode = this.resendConfirmationCode.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.toggleConfirmPassword = this.toggleConfirmPassword.bind(this);
    this.PreviousPageLogin = this.PreviousPageLogin.bind(this);
    this.contactUsLink = this.contactUsLink.bind(this);
    this.loginPage = this.loginPage.bind(this);
    this.emailFieldValueChange = this.emailFieldValueChange.bind(this);
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  getQuoteClick() {
    this.props.history.push(ROUTES.initial);
  }

  onLogoClick() {
    window.open('https://www.strattonfinance.com.au/');
  }

  validateResetForm() {
    return (
      this.state.mobileOtp.length === 6 &&
      this.state.password.length > 0 &&
      this.state.isPasswordValid &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
    if (this.state.password && this.state.password.length >= 8) {
      const str = this.state.password;

      const strongRegex = PASSWORD_CHECK_REGEX;

      const res = strongRegex.test(str);

      if (res) {
        this.setState({ isPasswordValid: true });
      } else {
        this.setState({ isPasswordValid: false });
      }
    }
  }

  emailFieldValueChange(event) {
    const emailValue = event.target.value;
    this.setState({ email: emailValue.toLowerCase() });
  }

  togglePassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  toggleConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }

  contactUsLink() {
    window.open('https://www.strattonfinance.com.au/contact-us ');
  }

  PreviousPageLogin() {
    this.props.history.push(ROUTES.login);
  }

  async handleSendCodeClick(event) {
    event.preventDefault();

    this.setState({ isSendingCode: true });

    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({ codeSent: true });
    } catch (e) {
      window.alert(e.message);
      this.setState({ isSendingCode: false });
    }
  }

  async handleConfirmationSubmit(newVal) {
    this.setState({ mobileOtp: newVal });
  }

  async handleConfirmClick(event) {
    event.preventDefault();

    this.setState({ isConfirming: true });

    try {
      await Auth.forgotPasswordSubmit(this.state.email, this.state.mobileOtp, this.state.password);
      this.setState({ confirmed: true });
    } catch (e) {
      window.alert(e.message);
      this.setState({ isConfirming: false });
    }
  }

  async resendConfirmationCode(event) {
    event.preventDefault();

    this.setState({ isSendingCode: true });

    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({ codeSent: true });
    } catch (e) {
      window.alert(e.message);
      this.setState({ isSendingCode: false });
    }
  }

  loginPage() {
    this.setState({ showmodal: false });
    this.props.history.push(ROUTES.login);
  }

  renderRequestCodeForm() {
    return (
      <div className="login-page-details col-12 col-md-8 col-lg-8">
        <div className="Need-help py-3">
          Call us on
          <div className="number-font">1300 787 288</div>
        </div>
        <div className="login-page-message">
          <div className="forgot-password">
            Reset your&nbsp;
            <span className="caption-highlight">password</span>
          </div>
          <div className="reset-caption">
            We’ll send a verification code to the mobile associated with this account
          </div>
          <Form onSubmit={this.handleSendCodeClick} className="login-form-section">
            <Form.Group className="login-input-group" controlId="email">
              <Form.Label className="input-label-font">Email</Form.Label>
              <InputGroup className="email-input-group-append">
                <InputGroup.Prepend className="email-icon-input-append">
                  <img src={Email} alt="email" />
                </InputGroup.Prepend>
                <Form.Control
                  autoFocus
                  type="email"
                  placeholder="Email address"
                  className="email-border"
                  value={this.state.email}
                  onChange={this.emailFieldValueChange}
                />
              </InputGroup>
            </Form.Group>
            <Button
              block
              type="submit"
              isLoading={this.state.isSendingCode}
              disabled={!this.validateCodeForm()}
              className="btn-primary-call-to-action"
            >
              <span className="button-font">Reset password</span>
            </Button>
            <button className="btn btn-link btn-reset" onClick={this.PreviousPageLogin}>
              <span className="back-btn">
                <img src={leftArrow} alt="left arrow" className="left-arrow" />
                Back
              </span>
            </button>
          </Form>
          <hr className="login-horizantal-line" />
          <div className="login-page-navigation">
            <div className="looking-for"> Looking for finance?</div>
            <div className="get-started">Get started by getting a quote</div>
            <Button type="button" className="btn-primary-get-quote" onClick={this.getQuoteClick}>
              <span className="get-quote-message">
                Get a quote
                <img src={rightArrow} className="right-icon" alt="right-arrow" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderConfirmationForm() {
    return (
      <div className="login-page-details col-12 col-md-8 col-lg-8">
        <div className="Need-help py-3">
          Call us on
          <div className="number-font">1300 787 288</div>
        </div>
        <div className="login-page-message">
          <div className="forgot-password">
            Reset your&nbsp;
            <span className="caption-highlight">password</span>
          </div>
          <div className="reset-caption">Let's set up a new password</div>
          {this.state.codeSent && (
            <div className="code-sent-message">
              <img src={check_Success} alt="success" className="success-icon" />
              <div className="code-sent-text"> 6 digit verification code sent to your mobile</div>
            </div>
          )}
          <Form onSubmit={this.handleConfirmClick} className="login-form-section">
            <Form.Group controlId="code">
              <>
                <div className="verifyyourself-otp">
                  <OtpInput
                    onChange={this.handleConfirmationSubmit}
                    inputStyle="otp-input-field"
                    value={this.state.mobileOtp}
                    shouldAutoFocus
                    hasErrored
                    numInputs="6"
                    errorStyle="error"
                  />
                </div>
                <div className="otp-info-container">
                  <div className="verifyyourself-content6">
                    {/* {'OTP will expire in '}
                    <span className="verifyyourself-content7">3:40</span>
                    {' or '} */}
                    Didn&apos;t recieve the code?
                    <button
                      type="button"
                      className="p-0 btn btn-link"
                      onClick={this.resendConfirmationCode}
                    >
                      <span className="verifyyourself-content8">Resend</span>
                    </button>
                  </div>
                </div>
              </>
            </Form.Group>
            <hr />

            <Form.Group className="login-input-group" controlId="password">
              <Form.Label className="input-label-font">Password</Form.Label>
              <InputGroup className="login-input-group-append">
                <Form.Control
                  value={this.state.password}
                  onChange={this.handleChange}
                  type={this.state.showPassword ? 'text' : 'password'}
                  className="password-border"
                  placeholder="* * * * *"
                />
                <InputGroup.Append
                  className="password-icon-input-append"
                  onClick={this.togglePassword}
                >
                  <img src={eyeImg} alt="eye" />
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <Form.Group className="login-input-group mb-0" controlId="confirmPassword">
              <Form.Label className="input-label-font">Confirm password</Form.Label>
              <InputGroup className="login-input-group-append ">
                <Form.Control
                  value={this.state.confirmPassword}
                  onChange={this.handleChange}
                  type={this.state.showConfirmPassword ? 'text' : 'password'}
                  className="password-border"
                  placeholder="* * * * *"
                />
                <InputGroup.Append
                  className="password-icon-input-append"
                  onClick={this.toggleConfirmPassword}
                >
                  <img src={eyeImg} alt="eye" />
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <div className="password-message">
              Must contain more than 8 characters, combining uppercase, lowercase and numbers
            </div>
            {this.state.error && this.state.error.length !== null && (
              <Alert variant="danger" className="error-alert">
                <img src={risk} className="risk-img" alt="risk" />
                <div className="error-message">{this.state.error}</div>
              </Alert>
            )}
            <Button
              block
              type="submit"
              isLoading={this.state.isConfirming}
              disabled={!this.validateResetForm()}
              className="btn-primary-call-to-action"
            >
              <span className="button-font">Set up my password </span>
            </Button>
          </Form>
          <hr className="login-horizantal-line" />
          <div className="login-page-navigation">
            <div className="looking-for"> Looking for finance?</div>
            <div className="get-started">Get started by getting a quote</div>
            <Button type="button" className="btn-primary-get-quote" onClick={this.getQuoteClick}>
              <span className="get-quote-message">
                Get a quote
                <img src={rightArrow} className="right-icon" alt="right-arrow" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderSuccessMessage() {
    return (
      <>
        <Modal
          dialogClassName="save-model-layout"
          show={this.state.showmodal}
          onHide={this.loginPage}
          centered
        >
          <Modal.Body className="save-container">
            <Col xs={12} className="success-icon-container">
              <span className="success-icon" />
            </Col>
            <Container>
              <div className="save-title">Password has been set up successfully!</div>
              <div className="form-row text-center">
                <div className="col-12">
                  <Button type="button" className="btn-close btn-val" onClick={this.loginPage}>
                    Let&apos;s Log in
                  </Button>
                </div>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  render() {
    return (
      <>
        <div className="login-page-container">
          <div className="login-page-section login_details_section col-12 col-md-4 col-lg-4">
            <div className="stratton-info-nav-container">
              <img
                src={logo}
                alt="Stratton"
                className="pt-3 stratton-info-logo"
                onClick={this.onLogoClick}
              />
            </div>
            <div className="stratton-info-footer-container">
              <div className="login_details_section">
                <img src={personalDetailsBackground} className="nav-personal" alt="personal" />
              </div>
              <Copyright />
            </div>
          </div>
          {!this.state.codeSent
            ? this.renderRequestCodeForm()
            : !this.state.confirmed
            ? this.renderConfirmationForm()
            : this.renderSuccessMessage()}
        </div>
        <Copyright mobileFooter />
      </>
    );
  }
}
