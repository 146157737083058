import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Car from '../../../assets/image/Car.svg';
import financeBackground from '../../../assets/image/Context_fd.svg';
import dashboard from '../../../assets/image/Dashboard.svg';
import logout from '../../../assets/image/External-Link.svg';
import CheckSuccess from '../../../assets/image/nav-check.svg';
import logo from '../../../assets/image/stratton-logo-transparent.png';
import rightnormal from '../../../assets/image/TArrow-Right.svg';
import listicon from '../../../assets/image/TArrow-RightBlue.svg';
import fieldChanged from '../../Actions/FieldChanged';
import { setCompleteTab } from '../../Actions/tab';
import Copyright from '../../components/Copyright';
import { SideBarWrapper } from '../../components/Header/Header';
import TextCheckList from '../../components/TextCheckList/TextCheckList';
import { ROUTES } from '../../util/constants';
import processGTMevent from '../../util/processGTMEvent';
import './context-pages.scss';

const FinancialDetailsContextPage = ({
  loanOptions,
  fullName,
  CognitouserName,
  isAuthenticated,
  setAuthenticationlogOut,
  setTabComplete,
  assetType,
}) => {
  const history = useHistory();
  const onLogoClick = () => {
    window.open('https://www.strattonfinance.com.au/');
  };
  const handleClick = () => {
    processGTMevent('applicants', 'pageLoad', 'seefinanceDetailsPage');
    setTabComplete('applicants');
    history.push(ROUTES.applicants);
  };

  const theme = useTheme();
  const enableDrawer = useMediaQuery(theme.breakpoints.down(768));

  let initials;
  let formatteduserName;

  const [showDrawer, toggleDrawer] = useState(false);
  const handleToggleDrawer = () => {
    toggleDrawer(enableDrawer ? !showDrawer : false);
  };
  if (fullName) {
    const nameparts = fullName.split(' ');
    initials = nameparts[0].charAt(0).toUpperCase() + nameparts[1].charAt(0).toUpperCase();
    formatteduserName = fullName
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }
  async function handleLogout() {
    await Auth.signOut();
    setAuthenticationlogOut().then(() => {
      history.push(ROUTES.login);
    });
  }
  const showDashboard = () => {
    history.push(ROUTES.dashboard);
  };
  const location = useLocation();
  return (
    <>
      <div className="estimateRepayments-container">
        <div className="estimateRepayments-section finance_details_bgImage col-12 col-md-4 col-lg-4">
          <div className="stratton-info-nav-container">
            <img
              src={logo}
              alt="Stratton"
              className="pt-3 stratton-info-logo"
              onClick={onLogoClick}
            />
            <div className="step-content-section">
              <div className="nav-animation">
                <div className="nav-animation-line">
                  <div className="checksuccess-circle">
                    <img src={CheckSuccess} className="nav-checksuccess" alt="car" />
                  </div>
                  <img src={Car} className="nav-animation-car with-animation" alt="car" />
                </div>
              </div>
              <div className="stepper-contents">
                <div className="steps-section">
                  <div className="step enabled">STEP 1</div>
                  <div className="step-message enabled ">Repayments estimate</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 2</div>
                  <div className="step-message disabled">Compare loan options</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 3</div>
                  <div className="step-message disabled">Get approved</div>
                </div>{' '}
                <div className="steps-section">
                  <div className="step disabled">STEP 4</div>
                  <div className="step-message disabled">Prepare for Settlement</div>
                </div>
              </div>
            </div>
          </div>
          <div className="stratton-info-footer-container">
            {assetType !== 'car' ? (
              <TextCheckList />
            ) : (
              <div className="finance_details_bgImage">
                <img src={financeBackground} className="nav-finances" alt="car" />
              </div>
            )}

            <Copyright />
          </div>
        </div>

        <div className="estimateRepayments-page-details col-12 col-md-8 col-lg-8">
          <div className="user-dropdown">
            <div className="Need-help py-3">
              Call us on
              <div className="number-font">1300 787 288</div>
            </div>
            <div className="auth-dropdown py-3">
              {isAuthenticated ? (
                <Navbar.Collapse className="justify-content-end show">
                  <NavDropdown
                    className="header-dropdown"
                    onClick={handleToggleDrawer}
                    title={
                      <>
                        {fullName && fullName !== null ? (
                          <>
                            <span className="content-initial ">{initials}</span>
                            <span className="content-username">{formatteduserName}</span>
                          </>
                        ) : (
                          <>
                            <AccountCircleIcon className="content-initial account-avatar" />
                            <span className="content-username no-name">{CognitouserName}</span>
                          </>
                        )}
                      </>
                    }
                    id="basic-nav-dropdown"
                  >
                    <SideBarWrapper
                      enableDrawer={enableDrawer}
                      showDrawer={showDrawer}
                      closeDrawer={() => {
                        toggleDrawer(false);
                      }}
                      title={
                        <>
                          {fullName && fullName !== null ? (
                            <>
                              <span className="content-initial">{initials}</span>
                              <span className="content-username">{formatteduserName}</span>
                            </>
                          ) : (
                            <>
                              <AccountCircleIcon className="content-initial account-avatar" />
                              <span className="content-username no-name">{CognitouserName}</span>
                            </>
                          )}
                        </>
                      }
                    >
                      {location.pathname !== ROUTES.dashboard && (
                        <NavDropdown.Item className="dashboard-item pb-4" onClick={showDashboard}>
                          <img src={dashboard} alt="dashboard" className="dashboard-img" />
                          <span className="content-Dashboard px-3">Dashboard</span>
                        </NavDropdown.Item>
                      )}
                      <NavDropdown.Item className="content-item pb-4" onClick={handleLogout}>
                        <img src={logout} alt="logout" className="content-editimage" />
                        <span className="content-edit px-3">Logout</span>
                      </NavDropdown.Item>
                    </SideBarWrapper>
                  </NavDropdown>
                </Navbar.Collapse>
              ) : null}
            </div>
          </div>
          <div className="estimateRepayments-message">
            <div className="estimateRepayments-title">
              Let&apos;s calculate how much you can borrow
            </div>

            <div className="estimateRepayments-subtitle">
              Before comparing loan options, we need some details about your financial situation to
              calculate your borrowing capacity
            </div>
            <div className="estimate-Repayments-quote">
              <div className="estimateRepayments-ratelabel">
                <img src={listicon} alt="right" className="right-icon" />
                <span className="getrate">Your income</span>
              </div>
              <div className="estimateRepayments-ratelabel">
                <img src={listicon} alt="right" className="right-icon" />
                <span className="getrate">Your assets</span>
              </div>
              <div className="estimateRepayments-ratelabel">
                <img src={listicon} alt="right" className="right-icon" />
                <span className="getrate">Your liabilities</span>
              </div>
              <div className="estimateRepayments-ratelabel">
                <img src={listicon} alt="right" className="right-icon" />
                <span className="getrate">Your expenses</span>
              </div>
            </div>
            <div className="email-quote py-3">
              <button type="button" className="letsgetstarted-btn" onClick={handleClick}>
                Continue
                <span className="pl-4">
                  <img src={rightnormal} alt="right" className="right-icon" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Copyright mobileFooter />
    </>
  );
};

export default connect(
  (state) => ({
    loanOptions: state.estimation.loanOptions,
    fullName:
      state.applicants.firstName && `${state.applicants.firstName} ${state.applicants.lastName}`,
    isAuthenticated: state.account.isAuthenticated,
    assetType: state.car.assetType,
  }),
  (dispatch) => ({
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage));
    },
    setAuthenticationlogOut: () =>
      dispatch(fieldChanged('account', 'isAuthenticated', false, false)),
    setTabComplete: (tabName) => {
      dispatch(setCompleteTab(tabName));
    },
  }),
)(FinancialDetailsContextPage);
