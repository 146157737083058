import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AddressDetails from './AddressDetails';
import fieldChanged, { updateValidationErrors } from '../../Actions/FieldChanged';
import { setCompleteTab, setCurrentTab } from '../../Actions/tab';
import { handleHTTPError, noErrors } from '../../Actions/errorHandler';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import { clearAddress, livingDetailsUpdate } from '../../Actions/car';
import { APPLICANT_TYPE } from '../../util/constants';
import { deleteGeneric } from '../../Actions/applicants';
import { setApplicationStrucutureStepper } from '../../Actions/stepperDetails';

const PREV_ADDRESSES_FIELD = 'prevAddresses';

const mapStateToProps = (state, ownProps) => {
  const { applicantType } = ownProps?.computedMatch?.params || ownProps?.match?.params || false;
  const isPrimaryApplicant = !applicantType || applicantType === APPLICANT_TYPE.primary;
  const fieldSuffix = isPrimaryApplicant ? '' : `_${applicantType}`;
  return {
    applicantType,
    isPrimaryApplicant,
    fieldSuffix,
    applicationStructure: state.applicants.applicationStructure,
    opportunityId: state.applicants.opportunityId,
    contactDetails: state.applicants.contactDetails,
    validationErrors: state.applicants.validationErrors,
    landlordName: state.applicants[`landlordName${fieldSuffix}`],
    landlordPhone: state.applicants[`landlordPhone${fieldSuffix}`],
    manualEnter: state.applicants.manualEnter,
    unitNumber: state.applicants[`unitNumber${fieldSuffix}`] || state.applicants.unitNumber,
    streetNumber: state.applicants[`streetNumber${fieldSuffix}`] || state.applicants.streetNumber,
    streetName: state.applicants[`streetName${fieldSuffix}`] || state.applicants.streetName,
    suburb: state.applicants[`suburb${fieldSuffix}`] || state.applicants.suburb,
    postcode: state.applicants[`postcode${fieldSuffix}`] || state.applicants.postcode,
    state: state.applicants[`state${fieldSuffix}`] || state.applicants.state,
    country: state.applicants[`country${fieldSuffix}`] || state.applicants.country,
    addressStartDate:
      state.applicants[`addressStartDate${fieldSuffix}`] || state.applicants.addressStartDate,
    addressEndDate: state.applicants[`addressEndDate${fieldSuffix}`],
    address: state.applicants[`address${fieldSuffix}`] || state.applicants.address,
    addressId: state.applicants[`addressId${fieldSuffix}`],
    isRenting: state.applicants.residentialStatus === 'Renting',
    currentTab: state.tab.tabName,
    selectedLender: state.applicants.selectedLender,
    getValidationErrorsForField: getValidationErrorsForFieldFunc(state.applicants.validationErrors),
    [PREV_ADDRESSES_FIELD]: state.applicants[`${PREV_ADDRESSES_FIELD}${fieldSuffix}`] || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(fieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage));
  },
  setPrevAddresses: (fieldSuffix, value) => {
    dispatch(fieldChanged('applicants', `${PREV_ADDRESSES_FIELD}${fieldSuffix}`, value, false));
  },
  setTabComplete: (tabName) => {
    dispatch(setCompleteTab(tabName));
  },
  setCurrentTab: (tabName) => {
    dispatch(setCurrentTab(tabName));
  },
  validateDateField: (fieldName, errors) =>
    dispatch(updateValidationErrors('APPLICANTS', fieldName, errors)),
  livingDetailsUpdate: (applicantType) => dispatch(livingDetailsUpdate(true, applicantType)),
  clearAddress: () => dispatch(clearAddress()),
  deleteAddress: (addressId) => dispatch(deleteGeneric(addressId)),
  setTab: () => dispatch(setCurrentTab('address_details')),
  setApplicationStrucutureStepper: (applicationStructure) =>
    dispatch(setApplicationStrucutureStepper(applicationStructure)),
});

const AddressDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(AddressDetails);

export default AddressDetailsContainer;
