/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import cls from 'classnames';
import InputGroup from 'react-bootstrap/InputGroup';
import getErrorMessageRows from './FormControlFeedback';

class TextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
    };

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateFieldValueOnChange = this.updateFieldValueOnChange.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value || '',
    });
  }

  onBlur(e) {
    this.updateFieldValueOnChange(e.target.value?.trim());
  }

  onChange(e) {
    e.preventDefault();

    const value = this.props.currency ? e.target.value.replace(/,/g, '') : e.target.value;

    if (this.props.fieldName === 'contactPhoneNumber' && value.length > 10) {
      return;
    }

    if (this.props.fieldName === 'employerABN' && value.length > 11) {
      return;
    }
    if (this.props.fieldName === 'employerABN_coApplicant' && value.length > 11) {
      return;
    }
    if (this.props.fieldName === 'postcode' && value.length > 4) {
      return;
    }
    if (this.props.fieldName === 'driversLicencecardNumber' && value.length > 20) {
      return;
    }
    if (this.props.fieldName === 'driversLicenseCardNumber_coApplicant' && value.length > 20) {
      return;
    }
    if (this.props.fieldName === 'driversLicenseNumber_coApplicant' && value.length > 10) {
      return;
    }
    if (this.props.fieldName === 'licenceNumber' && value.length > 10) {
      return;
    }

    this.setState(
      {
        value,
      },
      () => {
        if (this.props.updateOnChange) {
          this.updateFieldValueOnChange(value);
        }
      },
    );
  }

  updateFieldValueOnChange(value) {
    let formattedValue = this.props.currency ? value.replace(/,/g, '') : value;
    if (this.props.emailToLowercase) {
      formattedValue = formattedValue.toLowerCase();
    }

    this.props.onChange(this.props.fieldName, formattedValue, this.props.scrollToNextFieldOnChage);
  }

  render() {
    const errorMessageRows = getErrorMessageRows(
      this.props.getvalidationerrors,
      this.props.fieldName,
    );
    const isInvalid = errorMessageRows && errorMessageRows.length > 0;
    const countval = this.props.colmd;
    const columnCount = this.props.columncount || countval;
    const { isdisabled, colsm, prependIcon, IconPath } = this.props;

    // eslint-disable-next-line no-nested-ternary
    const type = this.props.type ? this.props.type : this.props.currency ? 'tel' : 'text';

    const value = this.state.value ? this.state.value.toString() : '';
    const displayValue = value;
    const inputElement = this.props.currency ? (
      <NumberFormat
        thousandSeparator
        customInput={Form.Control}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={this.onChange}
        value={displayValue} // textbox does not support nulls
        margin="none"
        type={type}
        className="input-root"
        disabled={isdisabled}
        placeholder={this.props.placeholder}
        isInvalid={isInvalid}
        name={this.props.fieldName}
        id={this.props.fieldName}
        format={this.props.format}
      />
    ) : (
      <Form.Control
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={this.onChange}
        value={displayValue}
        margin="none"
        disabled={isdisabled}
        type={type}
        min={0}
        className={cls(prependIcon && 'text-border-remove', 'form-input')}
        placeholder={this.props.placeholder}
        isInvalid={isInvalid}
        name={this.props.fieldName}
        id={this.props.fieldName}
      />
    );

    const coreElement = (
      <Form.Group
        as={Col}
        sm={colsm}
        md={columnCount}
        className={cls(this.props.className, isInvalid && 'is-invalid-control')}
      >
        <Form.Label>
          {this.props.label}
          <span className="mandatory">{this.props.required}</span>
        </Form.Label>
        <InputGroup className="icon-group">
          {prependIcon && (
            <InputGroup.Prepend className={cls('text-input-prepend')}>
              <InputGroup.Text className={cls('text-input-icon', isInvalid && 'is-invalid')}>
                <img className=" p-0" src={IconPath} />
              </InputGroup.Text>
            </InputGroup.Prepend>
          )}

          {inputElement}
        </InputGroup>
        {errorMessageRows}
      </Form.Group>
    );

    let wrapperElement = coreElement;

    if (!this.props.inline) {
      wrapperElement = (
        <div className={`row input-container ${this.props.className}`}>{coreElement}</div>
      );
    }

    return wrapperElement;
  }
}

TextField.propTypes = {
  value: PropTypes.string,
  fieldName: PropTypes.string,
};

export default TextField;
