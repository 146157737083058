import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import cls from 'classnames';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { CircularProgress } from '@material-ui/core';
import Alert from 'react-bootstrap/Alert';
import Tooltip from './Tooltip';
import fieldChanged from '../Actions/FieldChanged';
import { handleHTTPError, noErrors } from '../Actions/errorHandler';
import AmountRangeSelector from './AmountRangeSelector';
import { createQuote, reQuote, loanDetails } from '../Actions/car';
import './update-loan-details.scss';
import processGTMevent from '../util/processGTMEvent';
const MINIMUM_LOAN_AMOUNT = 5000; // use for minimum load amount value in future regrenece to update only one place
const UpdateLoanDetails = ({
  depositAmount,
  purchasePrice,
  onInputChange,
  getValidationErrorsForField,
  termVal,
  purchasePriceUpdated,
  depositUpdated,
  residualSwitch,
  createQuote,
  handleResidualChange,
  quoteInProgress,
  termValUpdated,
  onUpdateChange,
  opportunityId,
  loanDetails,
  purchaseUse,
  assetType,
}) => {
  const [validationError, showError] = useState('');
  const maxTermVal = 7;
  const loanAmount = purchasePriceUpdated - depositUpdated;
  const handleClose = () => {
    onUpdateChange('updateLoanAmt', false, false);
    onUpdateChange('depositUpdated', null, false);
    onUpdateChange('purchasePriceUpdated', null, false);
    onInputChange('termValUpdated', null, false);
  };

  const updateLoanAmount = () => {
    processGTMevent('estimation', 'update', 'UpdateLoanDetails');
    if (loanAmount < MINIMUM_LOAN_AMOUNT) {
      handleHTTPError({ customMsg: `Loan Amount cannot be less than $${MINIMUM_LOAN_AMOUNT}.` });
    } else {
      onUpdateChange('depositAmount', depositUpdated, false);
      onUpdateChange('purchasePrice', purchasePriceUpdated, false);
      onInputChange('termVal', termValUpdated, false);
      onInputChange('updateLoanAmt', false, false);
    }
    if (opportunityId) {
      loanDetails()
        .then(() => {
          reQuote()
            .then(() => {
              handleClose();
            })
            .catch(() => {
              handleClose();
            });
        })
        .catch(() => {
          handleClose();
          showError('Something went wrong, please try again soon.');
        });
    } else {
      createQuote()
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          showError('Something went wrong, please try again soon.');
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
    processGTMevent('estimation', 'updateConfirm', 'UpdatedLoan');
  };
  return (
    <div className="update-loan-details-form">
      <div className="update-loan-details-title">
        Update your <span className="loan-details-highlight">loan details</span>{' '}
      </div>
      <fieldset className="update-loan-details-fieldset">
        <div className="amount-range-selector">
          <AmountRangeSelector
            label={
              <>
                <span className="label">Purchase price</span>
              </>
            }
            onChange={onInputChange}
            fieldName="purchasePriceUpdated"
            getvalidationerrors={getValidationErrorsForField}
            defaultValue={purchasePrice}
            minValue={MINIMUM_LOAN_AMOUNT}
            maxValue={250000}
            step={1000}
            formGroupClass="mx-0 amount-range-slider"
          />
          <AmountRangeSelector
            label={
              <>
                <span className="label">Deposit</span>
              </>
            }
            onChange={onInputChange}
            fieldName="depositUpdated"
            defaultValue={depositAmount}
            minValue={0}
            maxValue={purchasePriceUpdated - MINIMUM_LOAN_AMOUNT}
            step={1000}
            getvalidationerrors={getValidationErrorsForField}
            formGroupClass="mx-0 amount-range-slider"
          />
        </div>
        <div className="estimate-range-slider">
          <div className="estimate-loan-amount col-12 col-sm-6 col-md-6 ">
            <NumberFormat
              displayType="text"
              value={loanAmount}
              thousandSeparator
              prefix="$"
            />
            <div className="loan-amt-label py-2">Loan amount</div>
          </div>
          <AmountRangeSelector
            label={
              <>
                <span className="label">Loan term</span>
              </>
            }
            formGroupClass="estimation-range-selector col-12 col-sm-6 col-md-4"
            onChange={onInputChange}
            fieldName="termValUpdated"
            defaultValue={termVal}
            minValue={1}
            maxValue={maxTermVal}
            step={1}
            getvalidationerrors={getValidationErrorsForField}
            termSlider
          />
        </div>
        {assetType == 'Car' && (
          <div className="estimate-slider col-12">
            <div className="custom-control custom-switch residual-switch residual-switch_update col-12 mt-2">
              <label htmlFor="residualSwitch" className="residual-switch-label">
                <div className="include-residual">
                  <div>Include Residual?</div>
                  <div className="">
                    <Tooltip
                      label="What's this?"
                      message="Adding a residual (or balloon) will reduce your repayments, and at the end of your loan you will pay the residual amount as a lump sum. You may have the option to refinance the residual payment."
                    />
                  </div>
                </div>
              </label>
              <input
                type="checkbox"
                className="custom-control-input "
                checked={residualSwitch}
                value={residualSwitch}
                onChange={handleResidualChange}
                id="residualSwitch"
              />
              <label
                className={cls('custom-control-label', residualSwitch && 'checked')}
                htmlFor="residualSwitch"
              />
            </div>
          </div>
        )}
        {validationError && (
          <Alert variant="danger" className="error-alert mx-2">
            <p className="error-message">{validationError}</p>
          </Alert>
        )}
        <hr className="horizantal" />
        <div className="update-loandetails-footer">
          <Button
            className="update-cancel"
            onClick={() => {
              onInputChange('updateLoanAmt', false, false);
              onInputChange('depositUpdated', null, false);
              onInputChange('purchasePriceUpdated', null, false);
            }}
          >
            Cancel
          </Button>
          <Button className="update-loanamt" onClick={updateLoanAmount} disabled={quoteInProgress || loanAmount<MINIMUM_LOAN_AMOUNT}>
            {quoteInProgress ? (
              <>
                <CircularProgress color="inherit" size={24} thickness={2.6} />
                <span> Updating</span>
              </>
            ) : (
              'Update'
            )}
          </Button>
        </div>
      </fieldset>
    </div>
  );
};

export default connect(
  (state) => ({
    updateLoanAmt: state.estimation.updateLoanAmt,
    depositUpdated:
      state.estimation.depositUpdated ||
      (state.estimation.depositUpdated === 0
        ? state.estimation.depositUpdated
        : state.car.depositAmount),
    purchasePriceUpdated:
      state.estimation.purchasePriceUpdated ||
      (state.estimation.purchasePriceUpdated === 0
        ? state.estimation.purchasePriceUpdated
        : state.car.purchasePrice),
    residualSwitch: state.estimation.residualSwitch,
    quoteInProgress: state.account.quoteInProgress,
    opportunityId: state.applicants.opportunityId,
    termValUpdated: state.estimation.termValUpdated || state.estimation.termVal,
    assetType: state.car.assetType,
  }),

  (dispatch) => ({
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('estimation', fieldName, value, scrollToNextFieldOnChage));
    },
    onUpdateChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('car', fieldName, value, scrollToNextFieldOnChage));
    },
    loanDetails: () => dispatch(loanDetails()),
    createQuote: () => dispatch(createQuote()),
    handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
  }),
)(UpdateLoanDetails);
