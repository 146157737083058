import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './content-page.scss';
import { FINANCE_COMPLETE_TEXT, GET_APPROVED_TEXT } from '../../util/constants';

const FinancePageComplete = (props) => {
  const { firstName, history } = props;
  const docValue = history.location.state?.documentComplete;
  const whyuseBroker = () => {
    window.open('https://www.strattonfinance.com.au/newsroom/why-you-should-use-a-broker');
  };
  return (
    <div className="financeComplete-container container-fluid container">
      {docValue ? (
        <div className="documentComplete-section col-12 col-md-5" />
      ) : (
        <div className="financeComplete-section col-12 col-md-5" />
      )}
      <div className="financeComplete-page-details col-12 col-md-7">
        <div className="financeComplete-message">
          <div className="font-emphasis financeComplete-name">{`Awesome${
            firstName ? ',' : ''
          } ${firstName || '!'}!`}</div>
          {docValue && (
            <div className="font-emphasis documentComplete-message">{GET_APPROVED_TEXT}</div>
          )}
          {docValue ? (
            <span className="documentComplete-desc">
              Your application is being reviewed for Approval.
              <br />
              <br />
              If we require more information/documents a finance expert will be in touch.
            </span>
          ) : (
            <span className="font-emphasis financeComplete-title">{FINANCE_COMPLETE_TEXT}</span>
          )}
          <button type="button" className="btn btn-primary btn-next-page" onClick={whyuseBroker}>
            Why use a broker
          </button>
        </div>
      </div>
    </div>
  );
};

FinancePageComplete.propTypes = {
  firstName: PropTypes.string.isRequired,
};

export default connect((state) => ({
  firstName: state.applicants.firstName,
}))(FinancePageComplete);
