import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { CircularProgress } from '@material-ui/core';
import { APPLICANT_TYPE, APPLICATION_DESC_TEXT, APPLICATION_FOOTER_DESC_TEXT, CALLBACK_APPLICATION_TEXT, DIRECT_APPLICATION_TEXT, DIRECT_APPLICATION_TEXT_SECOND, HELP_LINE_NUMBER, HIGHLIGHTED_DESC_TEXT, LENDER_PEPPER_TYPE, ROUTES, TITTLE_APPLICATON_TEXT, documentFieldMap, DocumentTypeMapping, documentTypeValue, uploadedVerifiedStatus } from '../../util/constants';
import './upload-document.scss';
import DocumentTitle from '../../components/DocumentTitle';
import IdentityDocUpload from '../../components/IdentityDocUpload';
import IncomeDocUpload from '../../components/IncomeDocUpload';
import AssetDocUpload from '../../components/AssetDocUpload';
import FooterNextPrevious from '../../components/FooterNextPrevious';
import processGTMevent from '../../util/processGTMEvent';
import { removeByAttribute } from '../../util/helpers';

const UploadDocument = ({
    incomelistVal,
    getDocumentList,
    pqFinalSubmissionUploadDocument,
    partner,
    dlflag,
    contactDetails,
    applicationStructure,
    opportunityId,
    partnerIncome,
    selectedLender, setTabComplete }) => {

    const history = useHistory();
    const location = useLocation();
    const typeApplicant = location.pathname.split('/').pop();
    const partnerApplicantType = partner?.applicantType_spouse ? 'Spouse' : 'Co-Applicant';
    const [loading, setLoading] = useState(false);

    const [imageFiles, setImage] = useState();
    const [imageError, setImageError] = useState({});
    const [documentList, setDocumentList] = useState([]);
    const [imageFilesPartner, setImagePartner] = useState();
    const [imagePartnerError, setImagePartnerError] = useState({});

    const [documentListPartner, setDocumentListPartner] = useState([]);
    const [otherFileCountPartner, setOtherFileCountPartner] = useState(0);
    const [otherFileCount, setOtherFileCount] = useState(0);

    const [popupApplicatSubmission, setPopupApplicatSubmission] = useState(false);
    const [popupApplicationType, setPopupApplicantionType] = useState('');

    const [additionalDoclistIdentity, setAdditionalDoclistIdentity] = useState([]);
    const [additionalDoclistIdentityPartner, setAdditionalDoclistIdentityPartner] = useState([]);
    const [additionalDoclistIncome, setAdditionalDoclistIncome] = useState([]);
    const [additionalDoclistIncomePartner, setAdditionalDoclistIncomePartner] = useState([]);
    const [additionalDoclistAsset, setAdditionalDoclistAsset] = useState([]);
    const [additionalDoclistAssetPartner, setAdditionalDoclistAssetPartner] = useState([]);
    const [fetchingInprogress, setFetchingInprogress] = useState(true);


    const partnerId = partner?.coApplicantId || partner?.spouseApplicantId;
    const dlflagPartner = partner?.internationalLicense_coApplicant || false;

    const paygIncomeData = filter(incomelistVal, { incomeType: 'PAYG Income' }).sort((a) => a.id);
    const selfEmployedIncomeData = filter(incomelistVal, { incomeType: 'Self-Employed Income' }).sort(
        (a, b) => b.uploadDate - a.uploadDate,
    );
    const paygIncomeDataPartner = filter(partnerIncome, { incomeType: 'PAYG Income' }).sort(
        (a, b) => b.uploadDate - a.uploadDate,
    );

    const selfEmployedIncomeDataPartner = filter(partnerIncome, {
        incomeType: 'Self-Employed Income',
    }).sort((incTyp, incTypNext) => incTyp.uploadDate - incTypNext.uploadDate);

    const documentIdentity = imageFiles ? (dlflag ? (selectedLender === LENDER_PEPPER_TYPE ? (imageFiles[documentFieldMap.passport]?.length && imageFiles[documentFieldMap.internationalDriverLicense]?.length) : imageFiles[documentFieldMap.passport]?.length) : (imageFiles[documentFieldMap.driverLicenseFront]?.length && imageFiles[documentFieldMap.driverLicenseBack]?.length)) : false;
    const documentIncome = imageFiles ? (selfEmployedIncomeData?.length != 0 ? (imageFiles[documentFieldMap.taxReturn]?.length && imageFiles[documentFieldMap.noticeOfAssessment]?.length) : (imageFiles[documentFieldMap.payslip1]?.length && imageFiles[documentFieldMap.payslip2]?.length)) : false;
    const uploadDocumentNextButtonDisable = (paygIncomeData.length === 0 && selfEmployedIncomeData.length === 0 && documentIdentity) ? true : (documentIdentity && documentIncome);


    const progressComplete = contactDetails ? ((typeApplicant !== APPLICANT_TYPE.primary) ? 2 : 1) : 1;
    const progressPending = contactDetails ? ((typeApplicant !== APPLICANT_TYPE.primary) ? 0 : 1) : 0;

    const documentIdentityPartner = imageFilesPartner ? (dlflagPartner ? (selectedLender === LENDER_PEPPER_TYPE ? (imageFilesPartner[documentFieldMap.passport]?.length && imageFilesPartner[documentFieldMap.internationalDriverLicense]?.length) : imageFilesPartner[documentFieldMap.passport]?.length) : (imageFilesPartner[documentFieldMap.driverLicenseFront]?.length && imageFilesPartner[documentFieldMap.driverLicenseBack]?.length)) : false;
    const documentIncomePartner = imageFilesPartner ? (selfEmployedIncomeDataPartner?.length != 0 ? (imageFilesPartner[documentFieldMap.taxReturn]?.length && imageFilesPartner[documentFieldMap.noticeOfAssessment]?.length) : (imageFilesPartner[documentFieldMap.payslip1]?.length && imageFilesPartner[documentFieldMap.payslip2]?.length)) : false;
    const uploadDocumentNextButtonDisablePartner = (paygIncomeDataPartner.length === 0 && selfEmployedIncomeDataPartner.length === 0 && documentIdentityPartner) ? true : (documentIdentityPartner && documentIncomePartner);

    const documentPartnerIncome = imageFilesPartner ? (typeApplicant === APPLICANT_TYPE.spouse) ?
        (selfEmployedIncomeDataPartner?.length != 0) ?
            (imageFilesPartner[documentFieldMap.taxReturn]?.length && imageFilesPartner[documentFieldMap.noticeOfAssessment]?.length) :
            (imageFilesPartner[documentFieldMap.payslip1]?.length && imageFilesPartner[documentFieldMap.payslip2]?.length) :
        uploadDocumentNextButtonDisablePartner : false;

    const nextButtonDisabled = loading ? false : (typeApplicant == APPLICANT_TYPE.primary ? uploadDocumentNextButtonDisable : documentPartnerIncome);

    useEffect(() => {
        setTabComplete('addtional_information');
        fetchDocumentUploadedList();
        if (partnerId) {
            fetchDocumentUploadedList(true);
        }
    }, []);


    useEffect(() => {
        if (typeApplicant === APPLICANT_TYPE.primary) {
            processGTMevent('uploaddocument', 'PQUploads-PageLoad', 'Page Load')
        }
        else if (typeApplicant === APPLICANT_TYPE.spouse) {
            processGTMevent('uploaddocument', 'PQUploads-Spouse-PageLoad', 'Page Load')
        }
        else {
            processGTMevent('uploaddocument', 'PQUploads-CoApp-PageLoad', 'Page Load')
        }
    }, [typeApplicant])


    const fetchDocumentUploadedList = (ispartner = false) => {
        getDocumentList(ispartner).then((res) => {
            const updatedDocList = remapDocType(res.data);
            ispartner ? setDocumentListPartner(updatedDocList) : setDocumentList(updatedDocList);
            const additionalDoclistIdentityMapData = remapIdentityData(res.data);
            ispartner ? setAdditionalDoclistIdentityPartner(additionalDoclistIdentityMapData) : setAdditionalDoclistIdentity(additionalDoclistIdentityMapData);
            const additionalDoclistIncomeMapData = remapIncomeData(updatedDocList);
            ispartner ? setAdditionalDoclistIncomePartner(additionalDoclistIncomeMapData) : setAdditionalDoclistIncome(additionalDoclistIncomeMapData);
            const additionalDoclistAssetMapData = remapAssetData(res.data);
            ispartner ? setAdditionalDoclistAssetPartner(additionalDoclistAssetMapData) : setAdditionalDoclistAsset(additionalDoclistAssetMapData);
            setFetchingInprogress(false);
            handleOtherCount(res.data, ispartner);
            handleTransformDownloadListToimageFiles(updatedDocList, ispartner);
        });
    };

    const remapAssetData = (docList) => {
        const arrayDocList = docList.map((a) => {
            const matchedObject = DocumentTypeMapping.find((obj) => obj.documentType === a.documentType);
            return { ...a, ...matchedObject };
        }).filter((obj) => obj.PQ_Category === 'Asset' &&
            (obj.status === 'Requested' || obj.status === 'Uploaded' || obj.status === 'Required' || obj.status === 'Sent to Lender' || obj.status === 'Broker Verified'))
        return arrayDocList;

    }

    const remapIdentityData = (docList) => {
        const arrayDocList = docList.map((a) => {
            const matchedObject = DocumentTypeMapping.find((obj) => obj.documentType === a.documentType);
            return { ...a, ...matchedObject };
        }).filter((obj) => (obj.PQ_Category === 'Identity') &&
            (obj.status === 'Requested' || obj.status === 'Uploaded' || obj.status === 'Required' || obj.status === 'Sent to Lender' || obj.status === 'Broker Verified'))
        return arrayDocList;
    }

    const remapIncomeData = (docList) => {
        const arrayDocList = docList.map((a) => {
            const matchedObject = DocumentTypeMapping.find((obj) => obj.documentType === a.documentType);
            return { ...a, ...matchedObject };
        }).filter((obj) => (obj.PQ_Category === 'Income') &&
            (obj.status === 'Requested' || obj.status === 'Uploaded' || obj.status === 'Required' || obj.status === 'Sent to Lender' || obj.status === 'Broker Verified'))
        return arrayDocList;
    }

    const handleOtherCount = (documentList, isPartner = false) => {
        const count = filter(documentList, { documentType: 'Other' })?.length;

        if (isPartner) {
            setOtherFileCountPartner(count || 0);
        } else {
            setOtherFileCount(count || 0);
        }
    };

    const remapDocType = (docList, isPartner = false) => {
        let currentLinkedEntityRelatedIncExId = '';
        const arrPaygIncome = isPartner ? paygIncomeDataPartner : paygIncomeData;
        const arrSelfIncome = isPartner ? selfEmployedIncomeDataPartner : selfEmployedIncomeData;
        let arrSelfIncomeList = []
        let arrPayslipList = []
        for (let i = 0; i < arrPaygIncome.length; i++) {
            const arrSorting = sortBy(docList.filter((item) => {
                return (item.documentSubtype !== null && item.linkedEntityRelatedIncExId === arrPaygIncome[i].id)
            }), ['documentSubtype'])
            arrPayslipList.push(...arrSorting)
        }
        for (let i = 0; i < arrSelfIncome.length; i++) {
            const arrSelfIncomeSorting = sortBy(docList.filter((item) => {
                return (item.linkedEntityRelatedIncExId === arrSelfIncome[i].id)
            }), ['documentSubtype'])
            arrSelfIncomeList.push(...arrSelfIncomeSorting)
        }

        if (arrPayslipList?.length !== 0) {
            for (let i = 0; i < documentTypeValue.length; i++) {
                removeByAttribute(docList, 'documentType', documentTypeValue[i]);
            }
        }
        if (arrSelfIncomeList?.length !== 0) {
            for (let i = 0; i < documentTypeValue.length; i++) {
                removeByAttribute(docList, 'documentType', documentTypeValue[i]);
            }
        }
        const docListUpdated = docList.concat(arrPayslipList);
        const docListIncomeUpdated = docListUpdated.concat(arrSelfIncomeList);
        const arrayDocList = (arrPayslipList.length || arrSelfIncomeList.length) ? docListIncomeUpdated : sortBy(sortBy(docListIncomeUpdated, ['uploadedDate']), ['linkedEntityRelatedIncExId']);


        const updatedList = arrayDocList?.map((item) => {
            if (item.documentType === 'Payslip') {
                item.documentType = (item?.documentSubtype !== null) ? item?.documentSubtype : "";
            }
            return { ...item };
        });
        return updatedList;
    };

    const handleTransformDownloadListToimageFiles = (allDocList, isPartner = false) => {
        const uploadedDocList = filter(
            allDocList,
            (doc) =>
                uploadedVerifiedStatus.includes(doc?.status)
        );

        const newImageFiles = {};

        uploadedDocList.forEach((a) => {
            const n = a.fileName.lastIndexOf('.');
            const fileExtension = a.fileName.substring(n + 1);
            const name = a.fileName.substring(0, n);
            let documentType = a.documentType;

            const formattedData = {
                path: a.fileName,
                fileExtension,
                documentId: a.documentId,
                linkedEntityRelatedIncExId: a.linkedEntityRelatedIncExId,
                name,
                documentType,
                status: a.status,
            };
            if (newImageFiles?.[documentType]) {
                newImageFiles[documentType].push({ ...formattedData });
            } else {
                newImageFiles[documentType] = [{ ...formattedData }];
            }
        });
        isPartner ? setImagePartner(newImageFiles) : setImage(newImageFiles);
    };

    const navigateToPrevious = () => {
        if (typeApplicant !== APPLICANT_TYPE.primary) {
            history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.primary}`);
        }
        else {

            if (applicationStructure === APPLICANT_TYPE.coApplicant) {
                history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
            } else if (applicationStructure === APPLICANT_TYPE.spouse) {
                history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.spouse}`);
            }
            else {
                history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.primary}`);
            }
        }
    };

    const employmentNextClick = () => {
        if (typeApplicant === APPLICANT_TYPE.primary) {
            processGTMevent('uploaddocument', 'PQUploads-Next', 'Click')
            localStorage.setItem(`pageCompleted${opportunityId}`, 'upload-document-primary');
            if (contactDetails) {
                if (applicationStructure === APPLICANT_TYPE.coApplicant) {
                    history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
                }
                else {
                    history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.spouse}`);
                }
            }
            else {
                setPopupApplicatSubmission(true)
            }
        }
        else {
            if (typeApplicant === APPLICANT_TYPE.coApplicant) {
                processGTMevent('uploaddocument', 'PQUploads-CoApp-Next', 'Click')
                localStorage.setItem(`pageCompleted${opportunityId}`, 'upload-document-coapplicant');
            }
            else {
                processGTMevent('uploaddocument', 'PQUploads-Spouse-Next', 'Click')
                localStorage.setItem(`pageCompleted${opportunityId}`, 'upload-document-spouse');
            }
            setPopupApplicatSubmission(true)
        }
    };

    const ModalForApplicationConfirmation = () => {
        return (
            <Modal
                onHide={handleCancelPopup}
                dialogClassName="modal-application-wrapper"
                show={popupApplicatSubmission}
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <Container>
                        <div className='warpper-desc'>
                            <div className="row-title">
                                <span className='txt-title'>{TITTLE_APPLICATON_TEXT}</span>
                            </div>
                            {popupApplicationType === '' ? <>
                                <div className='row-highlited'>
                                    <span className='txt-highlighted'>{HIGHLIGHTED_DESC_TEXT}</span>
                                </div>
                                <div className='row-description'>
                                    <span>{APPLICATION_DESC_TEXT}</span>
                                </div>
                                <div className='row-footer'>
                                    <span className='txt-footer'>{APPLICATION_FOOTER_DESC_TEXT}</span>
                                </div>
                                <div className='footer-button-container'>

                                    <button type="button" class="btn footer-button footer-left-Button" data-dismiss="modal" onClick={applicationSubmissionDirectly}>Submit application directly</button>
                                    <button type="button" class="btn footer-button  footer-right-Button" data-dismiss="modal" onClick={requestBockerCallback}>Request broker callback</button>
                                </div>
                            </> : popupApplicationType === 'Directly' ? <><div className='row-description-direct'>
                                {DIRECT_APPLICATION_TEXT}
                            </div>
                                <div className='row-description-direct'><span>{DIRECT_APPLICATION_TEXT_SECOND}</span><span className='text-number-highlight'>{HELP_LINE_NUMBER}</span></div>
                            </> : <div className='row-description-direct'>
                                <span>{CALLBACK_APPLICATION_TEXT}</span><span className='text-number-highlight'>{HELP_LINE_NUMBER}</span>
                            </div>}
                        </div>
                    </Container>
                </Modal.Body>
                {popupApplicationType !== '' && (
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary mr-auto" data-dismiss="modal" onClick={handleCancelPopup}>Cancel</button>
                        {loading ?
                            <CircularProgress color="inherit" size={24} thickness={2.6} />
                            : <button type="button" class="btn btn-primary" onClick={submitApplicationRequest}>Submit</button>}
                    </div>
                )}
            </Modal>)
    }
    const applicationSubmissionDirectly = () => {
        setPopupApplicantionType('Directly')
    }
    const requestBockerCallback = () => {
        setPopupApplicantionType('Callback')
    }

    const handleCancelPopup = () => {
        setPopupApplicantionType('')
        setPopupApplicatSubmission(false)
    }
    const submitApplicationRequest = () => {
        const payload = {
            pqSubmittedToLender: true,
            pqCallbackRequested: popupApplicationType !== 'Directly' ? true : false
        }

        const pqsubmissionVal = popupApplicationType !== 'Directly' ? 'Request a call back' : 'Submit to Lender'

        processGTMevent('pqfinalsubmission', 'selectedPQ', pqsubmissionVal)

        setPopupApplicantionType('')
        setPopupApplicatSubmission(false)
        setLoading(true)
        pqFinalSubmissionUploadDocument(payload)
            .then((res) => {

                setLoading(false)
                setTabComplete('upload_document')
                history.push({
                    pathname: ROUTES.financeComplete,
                    state: {
                        documentComplete: 'Successfully',
                    },
                })
            })
            .catch((error) => {
                setLoading(false)
                console.error('error', error);
            });
    }

    return (
        <div className="upload-document-page">
            <DocumentTitle title={typeApplicant === APPLICANT_TYPE.primary ? 'Primary Applicant' : typeApplicant === APPLICANT_TYPE.spouse ? 'Spouse Applicant' : 'Co-Applicant'} />
            {fetchingInprogress ? (
                <div className='text-center mt-3'>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {typeApplicant === APPLICANT_TYPE.primary ? (
                        <> <IdentityDocUpload
                            loading={loading}
                            setLoading={setLoading}
                            imageFiles={imageFiles}
                            setImage={setImage}
                            setImageError={setImageError}
                            imageError={imageError}
                            documentList={documentList}
                            setDocumentList={setDocumentList}
                            additionalDoclistIdentity={additionalDoclistIdentity}
                            applicantType='Primary'
                        />

                            <IncomeDocUpload
                                loading={loading}
                                setLoading={setLoading}
                                imageFiles={imageFiles}
                                setImage={setImage}
                                imageError={imageError}
                                setImageError={setImageError}
                                documentList={documentList}
                                setDocumentList={setDocumentList}
                                paygIncomeData={paygIncomeData}
                                selfEmployedIncomeData={selfEmployedIncomeData}
                                additionalDoclistIncome={additionalDoclistIncome}
                                applicantType='Primary'
                            />


                            <AssetDocUpload
                                loading={loading}
                                setLoading={setLoading}
                                imageFiles={imageFiles}
                                setImage={setImage}
                                setImageError={setImageError}
                                documentList={documentList}
                                setDocumentList={setDocumentList}
                                imageError={imageError}
                                additionalDoclistAsset={additionalDoclistAsset}
                            />
                        </>
                    ) : <>
                        <IdentityDocUpload
                            loading={loading}
                            setLoading={setLoading}
                            imageFiles={imageFilesPartner}
                            setImage={setImagePartner}
                            setImageError={setImagePartnerError}
                            imageError={imagePartnerError}
                            documentList={documentListPartner}
                            setDocumentList={setDocumentListPartner}
                            isPartner={partnerId ? true : false}
                            additionalDoclistIdentity={additionalDoclistIdentityPartner}
                            applicantType={partnerApplicantType}
                        />
                        <IncomeDocUpload
                            loading={loading}
                            setLoading={setLoading}
                            imageFiles={imageFilesPartner}
                            setImage={setImagePartner}
                            imageError={imagePartnerError}
                            setImageError={setImagePartnerError}
                            documentList={documentListPartner}
                            setDocumentList={setDocumentListPartner}
                            paygIncomeData={paygIncomeDataPartner}
                            selfEmployedIncomeData={selfEmployedIncomeDataPartner}
                            isPartner={partnerId ? true : false}
                            additionalDoclistIncome={additionalDoclistIncomePartner}
                            applicantType={partnerApplicantType} />

                        {/* ASSET SECTION */}
                        <AssetDocUpload
                            loading={loading}
                            setLoading={setLoading}
                            imageFiles={imageFilesPartner}
                            setImage={setImagePartner}
                            setImageError={setImagePartnerError}
                            imageError={imagePartnerError}
                            documentList={documentListPartner}
                            setDocumentList={setDocumentListPartner}
                            isPartner={partnerId ? true : false}
                            additionalDoclistAsset={additionalDoclistAssetPartner}
                        />
                    </>
                    }
                </>
            )}
            <FooterNextPrevious
                loading={loading}
                nextClick={employmentNextClick}
                previousClick={navigateToPrevious}
                progressComplete={progressComplete}
                progressPending={progressPending}
                disabled={nextButtonDisabled} />

            {popupApplicatSubmission && (<ModalForApplicationConfirmation />)}
        </div>
    );
};

export default UploadDocument;
