/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import cookies from 'js-cookie';

import { setAuthenticated, setAuthenticationFailure } from './Actions/authHandler';
import { ROUTES } from './util/constants';
import scrollToNextField from './util/ScrollToNextField';
import { getUrlStringdataValues } from './Actions/car';
import queryString from './util/queryString';
import * as log from './util/log';

const AuthHandler = ({
  setAuthenticationSuccess,
  setAuthenticationError,
  setUrlQueryStringData,
}) => {
  const history = useHistory();
  const location = useLocation();

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signIn':
        setAuthenticationSuccess(true);
        break;
      case 'signIn_failure':
        setAuthenticationError(false);
        break;
      default:
        break;
    }
  });

  async function checkSession() {
    try {
      await Auth.currentSession().then(async (response) => {
        await Auth.signOut().then(() => {
          history.push(ROUTES.login);
          setAuthenticationError('Force Log out');
        });
        return response;
      });
    } catch (err) {
      if (err === 'No current user') {
        // eslint-disable-next-line no-console
        console.error('No Session Error: ', err);
        log.error('No Session Error', err);
        // history.push('/');
      } else {
        // eslint-disable-next-line no-console
        console.error('AuthHanlder Error: ', err);
        log.error('AuthHanlder Error.', err);
      }
    }
  }

  useEffect(() => {
    scrollToNextField('toTop');
  }, [location.pathname]);

  useEffect(() => {
    const data = {
      rcid: queryString('rcid') || cookies.get('rcid'),
      source: queryString('utm_source') || cookies.get('utm_source'),
      medium: queryString('utm_medium') || cookies.get('utm_medium'),
      campaign: queryString('utm_campaign') || cookies.get('utm_campaign'),
      content: queryString('utm_content') || cookies.get('utm_content'),
      campaignterm: queryString('utm_term') || cookies.get('utm_term'),
      channel: queryString('utm_channel') || cookies.get('utm_channel'),
      gclid: queryString('gclid') || cookies.get('gclid'),
      carSalesLead: queryString('leadid') || cookies.get('leadid'),
      saveLocalStorage: queryString('allow_sessionstorage') || cookies.get('allow_sessionstorage'),
      code: queryString('code') || cookies.get('code'),
      contactId: queryString('contact_id') || cookies.get('contact_id'),
      assetType: queryString('asset_type') || cookies.get('asset_type'),
      condition: queryString('condition') || cookies.get('condition'),
      make: queryString('make')?.replace(/%20/g, ' ') || cookies.get('make')?.replace(/%20/g, ' '),
      model:
        queryString('model')?.replace(/%20/g, ' ') || cookies.get('model')?.replace(/%20/g, ' '),
      year: queryString('year') || cookies.get('year'),
      depositAmount: queryString('depositAmount') || cookies.get('depositAmount'),
      purchasePrice: queryString('purchasePrice') || cookies.get('purchasePrice'),
      purchaseSource: queryString('purchaseSource') || cookies.get('purchaseSource'),
      purchaseUse: queryString('purchaseUse') || cookies.get('purchaseUse'),
      term: queryString('term') || cookies.get('term'),
      financeRedegin: queryString('finance-redesign') || cookies.get('finance-redesign'),
      finaliseCar: queryString('finalisecar') || cookies.get('finalisecar'),
    };
    setUrlQueryStringData(data);
    //below code ios commented bcz always it is going to login page or dashboard when failure(getting cached).
    // check the current user when the App component is loaded

    Auth.currentAuthenticatedUser()
      .then((user) => {
        checkSession();
      })
      .catch((err) => {
        log.error('auth error', err);
        setAuthenticationError(err.message);
        // history.push('/');
      });
    // checkSession(); // to be uncommented
  }, []);

  return <></>;
};

AuthHandler.propTypes = {
  setAuthenticationSuccess: PropTypes.func.isRequired,
  setAuthenticationError: PropTypes.func.isRequired,
  setUrlQueryStringData: PropTypes.func.isRequired,
};

export default connect(null, (dispatch) => ({
  setAuthenticationSuccess: (userName) => dispatch(setAuthenticated(userName)),
  setAuthenticationError: (error) => dispatch(setAuthenticationFailure(error)),
  setUrlQueryStringData: (data) => dispatch(getUrlStringdataValues(data)),
}))(AuthHandler);
