/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { CircularProgress } from '@material-ui/core';
import Col from 'react-bootstrap/Col';
import Tooltip from '../../components/Tooltip';
import TextField from '../../components/TextField';
import DropDown from '../../components/DropDown';
import './PersonalDetails.scss';
import InputButton from '../../components/InputButton';
import AddressAutoComplete from '../../components/AddressAutoComplete';
import rightnormal from '../../../assets/image/right-normal.svg';
import { LIVING_SITUATION, BOARDER, RENTER, OTHER, HOME_OWNER, APPLICANT_TYPE } from '../../util/constants';


const DLSTATE = ['NSW', 'ACT', 'VIC', 'QLD', 'WA', 'NT', 'SA', 'TAS'];

const LivingInformation = ({
  address,
  manualEnter,
  nonResident,
  livingDetails,
  unitNumber,
  street,
  suburb,
  postcode,
  streetName,
  state,
  residentialStatus,
  duration,
  validationErrors,
  onScrollToNextClick,
  getValidationErrorsForField,
  onInputChange,
  clearAddress,
  livingDetailsUpdate,
  reQuote,
  hashPath,
  nextHashPath,
  livingDetailsInProgress,
  onLiteInputChange,
  opportunityId,
}) => {
  const handleCheckboxChange = (fieldName, e) => {
    onInputChange(fieldName, e.target.checked, false);
  };

  const handleLivingdetailsInsUpd = () => {
    onInputChange('livingDetailsInProgress', true, false);
  
    livingDetailsUpdate(false)
      .then(() => {
        localStorage.setItem(`pageCompleted${opportunityId}`, 'address-details');
        reQuote();
        onInputChange('livingDetailsInProgress', false, false);
        onInputChange('livingDetails', true, false).then(() => {
          onScrollToNextClick(nextHashPath, [], true, true, true);
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        onInputChange('livingDetailsInProgress', false, false);
      });
  };
  const residentialStatusChange = (fieldName, value) => {
    onInputChange(fieldName, value, false).then(() => {
      if (fieldName === 'residentialStatus' && value === HOME_OWNER) {
        onLiteInputChange('isSpousePropOwner', null, false);
      }
    });
  };

  const updateCurrentFormattedAddress = (formattedAddress) => {
    if (formattedAddress) {
      Object.keys(formattedAddress).forEach((label) => {
        onInputChange(label, formattedAddress[label]);
      });
    }
  };

  const nextDisabled =
    (validationErrors && validationErrors.length) ||
    (!nonResident &&
      !manualEnter &&
      (!address || !residentialStatus || (duration !== true && duration !== false))) ||
    (manualEnter &&
      !nonResident &&
      (!streetName ||
        !suburb ||
        !postcode ||
        !state ||
        !residentialStatus ||
        (duration !== true && duration !== false)));

  // eslint-disable-next-line no-return-assign
  return (
    <Card className={`content-body personal-details-container ${hashPath}`} id="livingdetails">
      <Card.Header className="col-12 page-header-container personal-details-header-container scrollable-anchor-tag with-sidebar">
        <a name={hashPath} href={`#${hashPath}`}>
          &nbsp;
        </a>
        <h1 className="personal-details-header">Your current address</h1>
        <div className="personal-details-description">
          This will be used to verify your identity
        </div>
      </Card.Header>
      <Card.Body className="pb-0 identity-details-body-container personal-body-container personal-body-section1 personal-body-section2">
        <Form noValidate className="col-12 identity-form-section py-2 px-1">
          {!manualEnter && (
            <>
              {!nonResident && (
                <div className="personal-body-section3 address-living-details form-row ">
                  <AddressAutoComplete
                    fieldName="address"
                    className="pr-3"
                    columnCount={8}
                    value={address}
                    label="Search your address"
                    placeholder="Start typing…"
                    getvalidationerrors={getValidationErrorsForField}
                    onChange={onInputChange}
                    onAddressSelection={updateCurrentFormattedAddress}
                    clearAddress={clearAddress}
                  />
                </div>
              )}
              <Form.Group as={Col} className="no-aus-address px-0" controlId="nonResident">
                <label className="checkbox-container">
                  I don&apos;t have an Australian address
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange('nonResident', e)}
                    checked={nonResident}
                    value={nonResident}
                    name="nonResident"
                    id="nonResident"
                  />
                  <span className="checkmark ml-2 " />
                </label>
              </Form.Group>
            </>
          )}

          {!manualEnter && !nonResident && (
            <div className="form-row living-details-manual">
              <button
                type="button"
                data-test="living-details-entermanual"
                className=" btn btn-link"
                onClick={() => onInputChange('manualEnter', true, false)}
              >
                Enter manually
              </button>
            </div>
          )}

          {manualEnter && (
            <>
              {!nonResident && (
                <>
                  <div className="form-row ">
                    <span className="oi oi-magnifying-glass px-2">
                      <button
                        type="button"
                        data-test="livingdetails-Search address"
                        className="px-0 btn btn-link px-2 ml-1 pb-2"
                        onClick={() => {
                          onInputChange('searchAddress', true, false);
                          onInputChange('manualEnter', false, false);
                        }}
                      >
                        Search address
                      </button>
                    </span>
                  </div>
                  <div className="form-row ">
                    <TextField
                      fieldName="unitNumber"
                      value={unitNumber}
                      label="Unit number"
                      placeholder="Unit number"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colmd={4}
                      className="pr-3"
                      inline
                    />
                    <TextField
                      fieldName="streetName"
                      value={streetName}
                      label="Street address"
                      placeholder="Street address"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colmd={4}
                      inline
                    />
                  </div>

                  <div className="form-row ">
                    <TextField
                      fieldName="suburb"
                      value={suburb}
                      label="Suburb"
                      placeholder="Suburb"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colmd={4}
                      className="pr-3"
                      inline
                    />
                    <TextField
                      fieldName="postcode"
                      value={postcode}
                      label="Postcode"
                      placeholder="Postcode"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colmd={4}
                      className="pr-3"
                      inline
                    />
                    <DropDown
                      fieldName="state"
                      value={state}
                      label="State"
                      colxs="12"
                      colsm="6"
                      colmd="6"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      options={
                        <>
                          <option value="" hidden>
                            Select...
                          </option>
                          {DLSTATE.map((optionState) => (
                            <option key={optionState} value={optionState}>
                              {optionState}
                            </option>
                          ))}
                        </>
                      }
                      scrollToNextFieldOnChage
                    />
                  </div>
                </>
              )}
              <div className="form-row ">
                <label className="checkbox-container">
                  I don&apos;t have an Australian address
                  <input
                    type="checkbox"
                    checked={nonResident}
                    onChange={(e) => handleCheckboxChange('nonResident', e)}
                    value={nonResident}
                    name="nonResident"
                    id="nonResident"
                  />
                  <span className="checkmark ml-2 " />
                </label>
              </div>
            </>
          )}
          {!nonResident && (
            <InputButton
              label={
                <>
                  <span>Have you lived here more than 2 years?</span>
                </>
              }
              fields={[
                { name: 'Yes', value: true },
                { name: 'No', value: false },
              ]}
              onChange={onInputChange}
              fieldName="duration"
              scrollToNextFieldOnChage
              value={duration}
              btnClass="duration"
              getvalidationerrors={getValidationErrorsForField}
            />
          )}
          {!nonResident && (
            <InputButton
              label={
                <>
                  <span>What&apos;s your living situation at this address?</span>
                  <Tooltip message="This should be your living arrangement at your primary residence. Select property owner if your name is on the title, even if it's mortgaged." />
                </>
              }
              fields={LIVING_SITUATION}
              onChange={residentialStatusChange}
              fieldName="residentialStatus"
              value={residentialStatus}
              getvalidationerrors={getValidationErrorsForField}
              scrollToNextFieldOnChage
            />
          )}
        </Form>
        <div className="row container-footer py-3 px-3 mt-2 border-top">
          <div className="col-12 d-flex justify-content-end px-0">
            <button
              type="button"
              data-test="LivingDetails-Next"
              className="btn btn-primary btn-next-page"
              disabled={nextDisabled || livingDetailsInProgress}
              hidden={livingDetails}
              onClick={handleLivingdetailsInsUpd}
            >
              {livingDetailsInProgress ? (
                <CircularProgress color="inherit" size={24} thickness={2.6} />
              ) : (
                'Next'
              )}
              <span className="px-3">
                <img src={rightnormal} alt="right" className="right-icon" />
              </span>
            </button>
            <input
              type="button"
              id="btnsubmit"
              className="btn-dummy-page"
              style={{ visibility: 'hidden' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LivingInformation;
