/* eslint-disable import/no-cycle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Button, Modal, Row, Col, Form, Container } from 'react-bootstrap';
import find from 'lodash/find';
import get from 'lodash/get';
import differenceWith from 'lodash/differenceWith';
// import NumberFormat from 'react-number-format';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import InputGroup from 'react-bootstrap/InputGroup';
import renderNumberFormat from '../../../components/ReduxRenderField/renderNumberFormat';
import renderSelectField from '../../../components/ReduxRenderField/renderSelectField';
import { normalizeAmount } from '../../../lib/formatter';
import { ExpenseTypes } from './ExpenseDetails';
import './expensedetails.scss';
import renderSelectButtonField from '../../../components/ReduxRenderField/renderSelectButtonField';
import { expenses } from '../../../Actions/finances';
import { FREQ_OPTIONS } from '../../../util/constants';
import processGTMevent from '../../../util/processGTMEvent';

class ExpenseForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      imageFile: (props.expenseDetails && props.expenseDetails.imageFile) || [],
    };

    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
  }

  close() {
    const { onCancel } = this.props;
    onCancel();
  }

  submit(data) {
    processGTMevent('finances', 'expenses', 'addExpenses');
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));
    const { expenseDetails, onUpdateExpense, expenseApi, opportunityId } = this.props;
    if (expenseApi) {
      expenseApi({ ...expenseDetails, ...data })
        .then((response) => {
          onUpdateExpense({ ...data, id: response.data[0].id });
          localStorage.setItem(`pageCompleted${opportunityId}`, 'expense-details');
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, isAdd } = this.props;
    const { isSubmitting } = this.state;
    const availableCategories = differenceWith(
      ExpenseTypes,
      this.props.expenses,
      (allOption, addedOption) => allOption.value === addedOption.category,
    );
    const Title = get(
      find(ExpenseTypes, ['value', this.props.expenseDetails.category]),
      'name',
      'N/A',
    );
    return (
      <div>
        <Modal show size="md" onHide={this.close} className="modal-wrapper finances-modal-wrapper">
          <Modal.Header closeButton className="finances-header-container">
            <Row className="ml-1">
              <Modal.Title className="finances-header-section">
                {isAdd ? 'Add expense' : Title}
              </Modal.Title>
            </Row>
          </Modal.Header>

          <Modal.Body className="p-0">
            <Container>
              <Row className="show-grid">
                <Col className="income-column">
                  <Form noValidate onSubmit={handleSubmit(this.submit)}>
                    {isAdd && (
                      <>
                        <Form.Group>
                          <Form.Label className="finances-form-label">Category</Form.Label>
                          <Field
                            type="select"
                            placeholder="Select"
                            label="Category"
                            name="category"
                            options={availableCategories}
                            component={renderSelectField}
                            required
                          />
                        </Form.Group>
                      </>
                    )}
                    <Field
                      label="Amount"
                      name="amount"
                      type="text"
                      placeholder="0.00"
                      PrependComponent={() => (
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                        </InputGroup.Prepend>
                      )}
                      required
                      component={renderNumberFormat}
                      normalize={normalizeAmount}
                    />
                    <Form.Group>
                      <Form.Label>Frequency</Form.Label>
                      <Field
                        label="Frequency"
                        name="frequency"
                        options={FREQ_OPTIONS}
                        component={renderSelectButtonField}
                        classes={{
                          container: 'frequency-select-container',
                          inputButton: 'frequency-select-buttons',
                        }}
                        required
                      />
                    </Form.Group>
                    <hr className="horizantal-line" />
                    <Modal.Footer className="footer-container px-0">
                      {/*   <ButtonToolbar> */}

                      <Button type="button" className="footer-cancel" onClick={this.close}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting || pristine || submitting}
                        className="footer-btn mr-0"
                        block
                      >
                        {isAdd ? 'Save' : 'Update'}
                      </Button>
                      {/*   </ButtonToolbar> */}
                    </Modal.Footer>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const EnhancedExpenseForm = reduxForm({
  form: 'expenseDetails',
  validate: (values) => {
    const errors = {};

    if (!values.category) {
      errors.category = 'Required';
    }
    if (!values.amount) {
      errors.amount = 'Required';
    } else if (Number.isNaN(values.amount)) {
      errors.amount = 'Must be a number';
    } else if (Number(values.amount) < 1) {
      errors.amount = 'amount should be greater than one';
    }
    if (!values.frequency) {
      errors.frequency = 'Required';
    }
    return errors;
  },
})(ExpenseForm);

export default connect(
  (state) => ({
    expenses: state.applicants.expenses,
    opportunityId: state.applicants.opportunityId,
    residentialStatus: state.applicants.residentialStatus,
  }),
  (dispatch) => ({
    expenseApi: (expenseData) => dispatch(expenses(expenseData)),
  }),
)(EnhancedExpenseForm);
