/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef } from 'react';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import find from 'lodash/find';
import _ from 'lodash';
import './policyinfo.scss';
import { CircularProgress } from '@material-ui/core';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validateIncomeErrors } from '../../../util/validateIncomeErrors';
import InputButton from '../../../components/InputButton';
import rightnormal from '../../../../assets/image/right-normal.svg';
import CreditQuoteAcceptance from '../../Dashboard/CreditQuoteAcceptance';

import {
  ROUTES,
  LIABILITIES_TYPES,
  LIABILITY_MORTGAGE_OPTION,
  LIABILITY_MORTGAGE_INVEST_OPTION,
  LIABILITY_VEHICAL_LOAN_OPTION,
  LIABILITY_ASSET_LOAN_OPTION,
  RENTER,
  HOME_PROPERTY,
  INVESTMENT_PROPERTY,
  AssetTypes,
  APPLICANT_TYPE,
  SAVINGS,
  RECREATIONAL,
  MOTORVEHICLE,
} from '../../../util/constants';
import processGTMevent from '../../../util/processGTMEvent';
import scrollToNextField from '../../../util/ScrollToNextField';
import { showAskPreviousLoan, showAskPartnerPropDetails } from '../../../lib/utils';

import { getNameByValue } from '../../../util/ContainerUtilities';
import { ExpenseTypes } from '../Expenses/ExpenseDetails';
import { getFilteredLiabilitiesByAsset } from '../Assets/AssetForm';
import { getFilteredAssetsByLiability } from '../Liabilities/LiabilityForm';

const RENT_EXPENSE = 'rent';

const PolicyInfo = ({
  foreseeSelected,
  onInputChange,
  getValidationErrorsForField,
  onCheckboxChange,
  termNconditionChk,
  setTabComplete,
  liabilities,
  previousFinance,
  applicants,
  updateCreeditQuoteInProgress,
  financeValidationerrors,
  foreseeFinancialChangesDescription,
  validationCheck,
  residentialStatus,
  updateCreditQuote,
  assets,
  expenses,
  noErrors,
  reQuote,
  relationshipStatus,
  financesSubmitInprogress,
  isSpousePropOwner,
  progressiveQuoteSubmitted,
  isSelectProductGroupFetched,
  selectedLender,
  brokerUpdatedAsssets,
  brokerUpdatedLiability,
  setApplicationStrucutureStepper,
  applicationStructure,
  opportunityId,
}) => {
  const history = useHistory();
  const store = useStore();
  const [showcreditQuote, setcreditQuoteModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const validateFinances = () => {
    const ValidateIncomeData = validateIncomeErrors({ store });
    if (ValidateIncomeData !== null) {
      onInputChange('financesSubmitInprogress', false, false);
      financeValidationerrors(ValidateIncomeData);
      scrollToNextField('toTop');
      return false;
    }
    const assetHomeproperty = _.some(assets, { category: HOME_PROPERTY });
    const assetSaving = _.some(assets, { category: SAVINGS });
    const checkAssetsAmountval = _.some(assets, { estimateVal: '0.00' });
    const liabilityMortgage = find(liabilities, ['category', LIABILITY_MORTGAGE_OPTION]);
    const liabilityInvestment = find(liabilities, ['category', LIABILITY_MORTGAGE_INVEST_OPTION]);
    const liabilityMotorvehicle = find(liabilities, ['category', LIABILITY_VEHICAL_LOAN_OPTION]);
    const liabilityRecreational = find(liabilities, ['category', LIABILITY_ASSET_LOAN_OPTION]);
    const checkLiabilitiesAmountval = _.some(assets, { estimateVal: '0.00' });
    const propertyHomeAsset = find(assets, ['category', HOME_PROPERTY]);
    const investmentPropertyAsset = find(assets, ['category', INVESTMENT_PROPERTY]);
    const motorVehicleAsset = find(assets, ['category', MOTORVEHICLE]);
    const recreationalAsset = find(assets, ['category', RECREATIONAL]);
    const expenseRenting = find(expenses, ['category', RENT_EXPENSE]);
    const mandatoryExpenses = _.filter(
      expenses,
      (expAmt) =>
        (expAmt.amount === '0.00' || expAmt.amount === '0') && expAmt.category != RENT_EXPENSE,
    );
    if (residentialStatus === 'PropertyOwner' && !assetHomeproperty) {
      validationCheck(
        `${getNameByValue(
          AssetTypes,
          HOME_PROPERTY,
        )} is mandatory due to your curent living Situation`,
        'ASSETS',
      );
      scrollToNextField('asset-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }
    if (!assetSaving) {
      validationCheck(`${getNameByValue(AssetTypes, SAVINGS)} is mandatory`, 'ASSETS');
      scrollToNextField('asset-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }
    if (
      showAskPartnerPropDetails(residentialStatus, relationshipStatus, assets) &&
      typeof isSpousePropOwner !== 'boolean'
    ) {
      validationCheck('Spouse Property details is Mandatory', 'ASSETS');
      scrollToNextField('asset-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }
    if (checkAssetsAmountval) {
      validationCheck('Amount should be greater than zero', 'ASSETS');
      scrollToNextField('asset-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }

    // for (let i = 0; i < assets.length; i += 1) {
    //   if (
    //     !assets[i]?.relatedLiability &&
    //     assets[i]?.category &&
    //     assets[i]?.underfinance &&
    //     getFilteredLiabilitiesByAsset(liabilities, assets[i]?.category).length &&
    //     !brokerUpdatedAsssets
    //   ) {
    //     validationCheck('Related Liability is Mandatory', 'ASSETS');
    //     scrollToNextField('asset-details', true, true);
    //     onInputChange('financesSubmitInprogress', false, false);
    //     return false;
    //   }
    // }
    // property Home
    if (propertyHomeAsset && propertyHomeAsset.underfinance && !liabilityMortgage) {
      validationCheck(
        `${getNameByValue(LIABILITIES_TYPES, LIABILITY_MORTGAGE_OPTION)} is mandatory`,
        'LIABILITIES',
      );
      scrollToNextField('liability-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }

    if (liabilityMortgage && (!propertyHomeAsset || !propertyHomeAsset?.underfinance)) {
      validationCheck(
        `${getNameByValue(AssetTypes, HOME_PROPERTY)} is mandatory and  must be currently financed`,
        'ASSETS',
      );
      scrollToNextField('asset-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }
    // investment property
    if (!liabilityInvestment && investmentPropertyAsset && investmentPropertyAsset.underfinance) {
      validationCheck(
        `${getNameByValue(LIABILITIES_TYPES, LIABILITY_MORTGAGE_INVEST_OPTION)} is mandatory`,
        'LIABILITIES',
      );
      scrollToNextField('liability-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }

    if (
      liabilityInvestment &&
      (!investmentPropertyAsset || !investmentPropertyAsset.underfinance)
    ) {
      validationCheck(
        `${getNameByValue(
          AssetTypes,
          INVESTMENT_PROPERTY,
        )}  is mandatory and must be currently financed `,
        'ASSETS',
      );
      scrollToNextField('asset-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }
    // motor vehicle loan
    if (!liabilityMotorvehicle && motorVehicleAsset && motorVehicleAsset.underfinance) {
      validationCheck(
        `${getNameByValue(LIABILITIES_TYPES, LIABILITY_VEHICAL_LOAN_OPTION)} is mandatory`,
        'LIABILITIES',
      );
      scrollToNextField('liability-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }

    if (liabilityMotorvehicle && (!motorVehicleAsset || !motorVehicleAsset.underfinance)) {
      validationCheck(
        `${getNameByValue(AssetTypes, MOTORVEHICLE)}  is mandatory and must be currently financed `,
        'ASSETS',
      );
      scrollToNextField('asset-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }
    // RECREATIONAL

    if (!liabilityRecreational && recreationalAsset && recreationalAsset.underfinance) {
      validationCheck(
        `${getNameByValue(LIABILITIES_TYPES, LIABILITY_ASSET_LOAN_OPTION)} is mandatory`,
        'LIABILITIES',
      );
      scrollToNextField('liability-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }

    if (liabilityRecreational && (!recreationalAsset || !recreationalAsset.underfinance)) {
      validationCheck(
        `${getNameByValue(AssetTypes, RECREATIONAL)}  is mandatory and must be currently financed `,
        'ASSETS',
      );
      scrollToNextField('asset-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }

    if (checkLiabilitiesAmountval) {
      validationCheck('Amount should be greater than zero');
      scrollToNextField('liability-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }

    if (residentialStatus === RENTER && !expenseRenting) {
      validationCheck(
        `${getNameByValue(
          ExpenseTypes,
          RENT_EXPENSE,
        )} is mandatory due to your current living situation`,
        'EXPENSES',
      );
      scrollToNextField('expense-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }
    if (mandatoryExpenses?.length > 0) {
      validationCheck('Expenses should be greater than zero', 'EXPENSES');
      scrollToNextField('expense-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }
    if (
      residentialStatus === RENTER &&
      expenseRenting &&
      (!expenseRenting.amount || expenseRenting.amount === '0' || expenseRenting.amount === '0.0')
    ) {
      validationCheck(
        `${getNameByValue(ExpenseTypes, RENT_EXPENSE)} expense should be greater than zero`,
        'EXPENSES',
      );
      scrollToNextField('expense-details', true, true);
      onInputChange('financesSubmitInprogress', false, false);
      return false;
    }

    return true;
  };
  const nextDisabled =
    foreseeSelected === null ||
    foreseeSelected === undefined ||
    !termNconditionChk ||
    (showAskPreviousLoan(liabilities) && previousFinance !== true && previousFinance !== false) ||
    (foreseeSelected === true && !foreseeFinancialChangesDescription);

  const nextTabClick = () => {
    onInputChange('financesSubmitInprogress', true, false);
    noErrors();
    if (!validateFinances()) return;
    processGTMevent('finances', 'submit', 'submit');
    onInputChange('financedetailsChanged', true, false);
    setcreditQuoteModal(true);
    // this scenario does not happen as when broker fills application when they accept nccp1 we are nt allowing to resume application until pqselectgroup is true
    if (progressiveQuoteSubmitted) {
      applicants()
        .then((response) => {
          setTabComplete('finance_details');
          localStorage.setItem(`pageCompleted${opportunityId}`, 'policy-confirmation');
          reQuote();
          onInputChange('financesSubmitInprogress', false, false);
          history.push(ROUTES.estimateLoading);
          if (selectedLender) {
            setTabComplete('compare_loan_options');
            setApplicationStrucutureStepper(applicationStructure);
            history.push(`${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.primary}`);
          } else {
            isSelectProductGroupFetched()
              .then((resp) => {
                if (resp.data === true) {
                  history.push(ROUTES.compareLoanOptions);
                } else {
                  history.push(ROUTES.financeComplete);
                }
              })
              .catch((err) => {
                history.push(ROUTES.financeComplete);
              });
          }
        })
        .catch((error) => {
          onInputChange('financesSubmitInprogress', false, false);
        });
    } else {
      setcreditQuoteModal(true);
    }
  };

  const financialDescriptionChanges = (e) => {
    e.preventDefault();
    const { value } = e.target;
    onInputChange('foreseeFinancialChangesDescription', value, false);
  };

  const termsAndCondition = (e) => {
    processGTMevent('finances', 'termNconditionChk', e.target.checked);
    onCheckboxChange(e.target.checked);
  };

  const handleSubmitCreditQuote = () => {
    setSubmitLoading(true);
    onInputChange('financesSubmitInprogress', true, false);
    setTabComplete('finance_details');
    processGTMevent('finances', 'creditquoteaccept', 'creditquoteaccept');
    applicants().then((response) => {
      localStorage.setItem(`pageCompleted${opportunityId}`, 'policy-confirmation');
      reQuote();
      setSubmitLoading(false);
      history.push(ROUTES.estimateLoading);
      if (selectedLender) {
        setTabComplete('compare_loan_options');
        setApplicationStrucutureStepper(applicationStructure);
        history.push(`${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.primary}`);
      } else {
        isSelectProductGroupFetched()
          .then((resp) => {
            if (resp.data === true) {
              history.push(ROUTES.compareLoanOptions);
            } else {
              history.push(ROUTES.financeComplete);
            }
            setcreditQuoteModal(false);
          })
          .catch((err) => {
            setcreditQuoteModal(false);
            history.push(ROUTES.financeComplete);
          });
      }
      onInputChange('financesSubmitInprogress', false, false);
    });
  };

  return (
    <Card className="policy-confirmation-container policy-confirmation finances-details-container">
      <Card.Body className="p-0 scrollable-anchor-tag with-sidebar">
        <a name="policy-confirmation" href="#policy-confirmation">
          &nbsp;
        </a>
        <div className="row py-2 py-0 px-2">
          <div className="col-12  py-0 px-2">
            <Form noValidate className="declaration-check">
              {showAskPreviousLoan(liabilities) && (
                <InputButton
                  label={<span>Have you had any previous loans? </span>}
                  fields={[
                    { name: 'Yes', value: true },
                    { name: 'No', value: false },
                  ]}
                  onChange={onInputChange}
                  fieldName="previousFinance"
                  btnClass="previous-finance"
                  getvalidationerrors={getValidationErrorsForField}
                  value={previousFinance}
                />
              )}
              <InputButton
                label={
                  <span>
                    Do you foresee any significant changes to your income or expenses in the next 12
                    months?
                  </span>
                }
                fields={[
                  { name: 'Yes', value: true },
                  { name: 'No', value: false },
                ]}
                onChange={onInputChange}
                fieldName="foreseeSelected"
                value={foreseeSelected}
                getvalidationerrors={getValidationErrorsForField}
              />
              {foreseeSelected && (
                <Form.Group controlId="ControlTextareaicnomeChanges">
                  <Form.Label>What are these changes to your income or expenses?</Form.Label>
                  <Form.Control
                    as="textarea"
                    className="foreseeDescription"
                    onChange={financialDescriptionChanges}
                    name="foreseeFinancialChangesDescription"
                    value={foreseeFinancialChangesDescription}
                  />
                </Form.Group>
              )}
              <label className="checkbox-container">
                I declare all my finances are correct, and acknowledge this information will be used
                to conduct an assessment for a loan.
                <input
                  type="checkbox"
                  checked={termNconditionChk}
                  onChange={termsAndCondition}
                  value={termNconditionChk}
                  name="termNconditionChk"
                  id="termNconditionChk"
                />
                <span className="checkmark ml-2  mt-1 " />
              </label>
            </Form>
          </div>
        </div>

        <div className="row container-footer py-3 px-3  border-top">
          <div className="col-12 d-flex justify-content-end px-0">
            <button
              type="button"
              data-test="finance-page-complete"
              className="btn btn-primary btn-next-page"
              disabled={nextDisabled || financesSubmitInprogress}
              onClick={nextTabClick}
            >
              {financesSubmitInprogress ? (
                <CircularProgress color="inherit" size={24} thickness={2.6} />
              ) : (
                'Next'
              )}
              <span className="px-3">
                <img src={rightnormal} alt="right" className="right-icon" />
              </span>
            </button>
            <input
              type="button"
              id="btnsubmit"
              className="btn-dummy-page"
              style={{ visibility: 'hidden' }}
            />
          </div>
        </div>
        {!progressiveQuoteSubmitted && (
          <CreditQuoteAcceptance
            show={showcreditQuote}
            onSubmit={handleSubmitCreditQuote}
            submitInProgress={submitLoading}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default PolicyInfo;
