import validateFields from '../util/ValidateFields';
import scrollToNextField from '../util/ScrollToNextField';
import scrollToField from '../util/ScrollToField';
import processGTMevent from '../util/processGTMEvent';

export const updateValidationErrorsTab = (tabName, errors) => ({
  type: `UPDATE_VALIDATION_ERRORS_FOR_TAB_${tabName}`,
  errors,
});

export const navigateToNextTab = (tabName) => ({
  type: `NAVIGATE_TO_NEXT_TAB_${tabName}`,
});

export const resetNavigateFlag = (tabName) => ({
  type: `RESET_NAVIGATE_TAB_${tabName}`,
});

export function scrollToNextClicked(
  tabName,
  fieldName,
  fieldsToValidate,
  ignoreHeaderOffset,
  ignoreParentOffset,
  changeUrl,
) {
  return (dispatch, getState) => {
    const tabNameFormatted = tabName.toUpperCase();

    const errors = validateFields(fieldsToValidate, tabName.toLowerCase(), getState());

    dispatch(updateValidationErrorsTab(tabNameFormatted, errors));
    if (errors.length < 1) {
      scrollToNextField(fieldName, ignoreHeaderOffset, ignoreParentOffset, changeUrl);
      processGTMevent(tabName.toLowerCase(), 'inlineNext', fieldName);
    } else {
      scrollToField(errors[0].fieldName);
    }
  };
}

export const isTabValid = (tabName, getState) => {
  const tabState = getState()[tabName.toLowerCase()];
  return tabState.validationErrors.length < 1;
};
