import React, { useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { CircularProgress } from '@material-ui/core';
import ButtonGroupCustom from '../../components/ButtonGroupCustom';
import TextField from '../../components/TextField';
import fieldChanged, { liteFieldChanged } from '../../Actions/FieldChanged';
import PrivacyConsent from '../../components/privacyConsent';
import { ROUTES } from '../../util/constants';
import rightnormal from '../../../assets/image/right-normal.svg';
import Email from '../../../assets/image/Email.svg';
import call from '../../../assets/image/call.svg';
import processGTMevent from '../../util/processGTMEvent';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import { leadCreation, applicants, reQuote } from '../../Actions/car';
import postcodes from '../../util/PostCodes';
import { noErrors, handleHTTPError } from '../../Actions/errorHandler';
import './contact-details.scss';
import sha256 from 'crypto-js/sha256';
import { regexEmail } from '../../RuleEvaluators/EmailRuleEvaluator';

const ContactDetails = ({
  title,
  firstName,
  lastName,
  email,
  contactPhoneNumber,
  validationErrors,
  getValidationErrorsForField,
  onInputChange,
  editPhoneNumberFlag,
  postcode,
  isAuthenticated,
  isExistingUser,
  leadCreation,
  forceNewApplication,
  postalcode,
  handleHTTPError,
  noErrors,
  isCarsalesAccount,
  leadCreationInProgress,
  acceptPrivacyPolicy,
  consentToContact,
  setTab,
  currentTab,
  applicants,
  opportunityId,
  carSalesOppCreated,
  promocode,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (setTab) {
      setTab('contactDetails');
    }
  }, [currentTab, setTab]);

  useEffect(() => {
    processGTMevent('applicants', 'pageLoad', 'contactDetailsMainPage');
  }, []);
  const formattedEmail = regexEmail.test(email);
  const valueFormatted =
    contactPhoneNumber &&
    contactPhoneNumber
      .trim()
      .replace(/\s|\(|\)|-/g, '')
      .replace(/^\+61/g, '0')
      .replace(/^61/g, '0');

  const rexPhone = new RegExp(/(^\(?(04|4)\)?[\s|-]*\d{2}[\s|-]*([\s|-]*\d[\s|-]*){6}$)/);
  const formattedPhoneNumber = rexPhone.test(valueFormatted);

  const handleCheckboxChange = (e) => {
    onInputChange('acceptPrivacyPolicy', e.target.checked, false);
  };
  const handleConsentToContactCheckbox = (e) => {
    onInputChange('consentToContact', e.target.checked, false);
  };
  const handleLeadCreation = () => {
    onInputChange('leadCreationInProgress', true, false);
    processGTMevent('applicants', 'seeEstimationPage', 'seeEstimationPage');
    noErrors();
    if (!isExistingUser && !isAuthenticated) {
      leadCreation()
        .then((response) => {
          if (response.isExisting) {
            onInputChange('leadCreationInProgress', false, false);
            onInputChange('error', 'An account with this email address already exists.', false);
          }

          const phoneNumber = '+61' + `${contactPhoneNumber}`.replace(/^0+/, '');
          const emailHash = sha256(email).toString();
          const phoneNumberHash = sha256(phoneNumber).toString();
          const firstNameHash = sha256(firstName).toString();
          const lastNameHash = sha256(lastName).toString();

          const extraData = {
            email: emailHash,
            phoneNumber: phoneNumberHash,
            postCode: postcode,
            firstName: firstNameHash,
            lastName: lastNameHash,
          };

          processGTMevent('applicants', 'lead', 'leadCreation', extraData);
          onInputChange('leadCreationInProgress', false, false);
          onInputChange('applicantDetails', true, true);

          history.push(ROUTES.estimation);
        })
        .catch((err) => {
          onInputChange('leadCreationInProgress', false, false);
          handleHTTPError(err);
        });
    }
    if (isAuthenticated && opportunityId) {
      applicants(true)
        .then(() => {
          onInputChange('carSalesContact', true, true);
          onInputChange('leadCreationInProgress', false, false);
          reQuote();
          history.push(ROUTES.estimation);
          onInputChange('applicantDetails', true, true);
        })
        .catch(() => {
          onInputChange('leadCreationInProgress', false, false);
        });
    }
  };
  const nextDisabled =
    (validationErrors && validationErrors.length) ||
    !title ||
    !firstName ||
    !lastName ||
    !email ||
    !contactPhoneNumber ||
    !acceptPrivacyPolicy ||
    !formattedEmail ||
    !formattedPhoneNumber ||
    !postcode ||
    postcodes.indexOf(postcode) < 0;
  let isdisabled = false;
  // eslint-disable-next-line no-return-assign
  return (
    <div className="contact-details">
      <div className="contact-info">Last step, tell us about yourself</div>
      <div className="contact-subheading">
        <div className="contact-strattoninfo">
          We pride ourselves on personalised service and finding products that best suit you
        </div>
      </div>
      <Card className="contact-card">
        <Card.Body className="contact-body">
          {isAuthenticated && !forceNewApplication ? (isdisabled = true) : (isdisabled = false)}
          <div className="contact-detail-info">
            <ButtonGroupCustom
              label={
                <>
                  <span>Title</span>
                </>
              }
              fields={[
                { name: 'Mr', value: 'Mr' },
                { name: 'Mrs', value: 'Mrs' },
                { name: 'Ms', value: 'Ms' },
                { name: 'Miss', value: 'Miss' },
              ]}
              isdisabled={isdisabled && !carSalesOppCreated && !isCarsalesAccount}
              onChange={onInputChange}
              fieldName="title"
              className="title"
              required="*"
              value={title}
              getvalidationerrors={getValidationErrorsForField}
            />
          </div>
          <div className="contact-detail-info">
            <TextField
              fieldName="firstName"
              value={firstName}
              required="*"
              className="first-name"
              isdisabled={isdisabled}
              label="First name"
              placeholder="e.g John"
              getvalidationerrors={getValidationErrorsForField}
              onChange={onInputChange}
              colmd={0}
              inline
            />
            <TextField
              fieldName="lastName"
              value={lastName}
              label="Last name"
              className="last-name"
              required="*"
              isdisabled={isdisabled}
              placeholder="e.g Doe"
              getvalidationerrors={getValidationErrorsForField}
              onChange={onInputChange}
              colmd={0}
              inline
            />
          </div>
          <TextField
            fieldName="email"
            value={email}
            label="Email"
            required="*"
            isdisabled={isdisabled}
            image={Email}
            placeholder="Email address"
            className="email-address px-0"
            getvalidationerrors={getValidationErrorsForField}
            onChange={onInputChange}
            emailToLowercase
            colmd={0}
            inline
          />
          <div className="email-notify">
            Please ensure you enter the correct email address as this will be used for future
            interactions with brokers at Stratton.
          </div>

          <div className="contact-detail-info">
            <TextField
              fieldName="contactPhoneNumber"
              value={editPhoneNumberFlag ? '' : contactPhoneNumber}
              label="Mobile number"
              className="phone-number"
              format="##########"
              placeholder="0400000000"
              isdisabled={isdisabled}
              image={call}
              required="*"
              getvalidationerrors={getValidationErrorsForField}
              onChange={onInputChange}
              colmd={0}
              type="tel"
              inline
              allowLeadingZeros
            />
            <TextField
              fieldName="postcode"
              value={postalcode || postcode}
              label="Postcode"
              placeholder="eg 3000"
              className="post-code "
              required="*"
              isdisabled={isdisabled}
              getvalidationerrors={getValidationErrorsForField}
              onChange={onInputChange}
              colmd={0}
              type="tel"
              inline
            />
            <TextField
              fieldName="promocode"
              value={promocode}
              label="Promocode"
              className="promo-code "
              isdisabled={isdisabled}
              getvalidationerrors={getValidationErrorsForField}
              onChange={onInputChange}
              colmd={0}
              type="tel"
              inline
            />
          </div>
          <div className="privacy_wrapper">
            <PrivacyConsent />
            <div className="privacy-policy contact-details-privacy">
              <label className="checkbox-container checkbox-color">
                <span className="i-accept">
                  I accept this{' '}
                  <a
                    className="btn-policy-path"
                    href="https://www.strattonfinance.com.au/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    Privacy & Credit Reporting Policy{' '}
                  </a>{' '}
                  and this privacy collection Notice and consent form. I confirm that I am
                  authorised to provide the personal information
                </span>
                <input
                  type="checkbox"
                  defaultChecked={acceptPrivacyPolicy}
                  onChange={(e) => handleCheckboxChange(e)}
                  name="acceptPrivacyPolicy"
                  id="acceptPrivacyPolicy"
                />
                <span className="checkmark" />
              </label>
            </div>
          </div>
          <div className="consent_wrapper">
            <div className="privacy-policy contact-details-privacy">
              <label className="checkbox-container checkbox-color">
                <span className="i-accept">
                  By checking this box you are giving us your consent to contact you to discuss
                  comprehensive insurance, and if requested by you , provide you with a quote to
                  ensure your asset is covered.
                </span>
                <input
                  type="checkbox"
                  defaultChecked={consentToContact}
                  onChange={(e) => handleConsentToContactCheckbox(e)}
                  name="consentToContact"
                  id="consentToContact"
                />
                <span className="checkmark" />
              </label>
            </div>
          </div>
          <div className="row container-footer py-3 px-3 mt-2">
            <div className="col-12 d-flex justify-content-end px-0">
              <button
                type="button"
                data-test="applicantDetails-Next"
                className="btn btn-primary btn-next-page"
                disabled={nextDisabled || leadCreationInProgress}
                onClick={handleLeadCreation}
              >
                {leadCreationInProgress ? (
                  <CircularProgress color="inherit" size={24} thickness={2.6} />
                ) : (
                  'See my estimate'
                )}

                <span className="px-3">
                  <img src={rightnormal} alt="right" className="right-icon" />
                </span>
              </button>
              <input
                type="button"
                id="btnsubmit"
                className="btn-dummy-page"
                style={{ visibility: 'hidden' }}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default connect(
  (state) => ({
    title: state.applicants.title,
    firstName: state.applicants.firstName,
    lastName: state.applicants.lastName,
    email: state.applicants.email,
    contactPhoneNumber: state.applicants.contactPhoneNumber || state.applicants.phoneNumber,
    dateOfBirth: state.applicants.dateOfBirth,
    postcode: state.applicants.postcode,
    postalcode: state.applicants.postalcode,
    promocode: state.applicants.promocode,
    applicantDetails: state.applicants.applicantDetails,
    acceptPrivacyPolicy: state.applicants.acceptPrivacyPolicy,
    consentToContact: state.applicants.consentToContact,
    leadCreationInProgress: state.applicants.leadCreationInProgress,
    validationErrors: state.applicants.validationErrors,
    opportunityId: state.applicants.opportunityId,
    isAuthenticated: state.account.isAuthenticated,
    carSalesOppCreated: state.car.carSalesOppCreated,
    isCarsalesAccount: state.account.isCarsalesAccount,
    getValidationErrorsForField: getValidationErrorsForFieldFunc(state.applicants.validationErrors),
    forceNewApplication: state.account.forceNewApplication,
  }),
  (dispatch) => ({
    onInputChange: (fieldName, value, scrollToNextFieldOnChage, ignoreHeaderOffset) => {
      dispatch(liteFieldChanged('applicants', 'personaldetailsChanged', true, false));
      return dispatch(
        fieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage, ignoreHeaderOffset),
      );
    },
    applicants: (carSalesFlag) => dispatch(applicants(carSalesFlag)),
    reQuote: () => dispatch(reQuote()),
    noErrors: () => dispatch(noErrors()),
    leadCreation: () => dispatch(leadCreation()),
    handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
  }),
)(withRouter(ContactDetails));
