import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import _ from 'lodash';
import TextField from '../../components/TextField';
import AddressAutoComplete from '../../components/AddressAutoComplete';
import DatePickerWrapper from '../../components/DatePickerWrapper';
import './address-details.scss';
import rightnormal from '../../../assets/image/right-normal.svg';
import leftnormal from '../../../assets/image/left-normal.svg';
import { ROUTES, APPLICANT_TYPE } from '../../util/constants';
import DropDown from '../../components/DropDown';
import Call from '../../../assets/image/call.svg';
import deleteIcon from '../../../assets/image/delete.svg';
import Progress from '../../components/Progress';
import postcodes from '../../util/PostCodes';
import processGTMevent from '../../util/processGTMEvent';

const DLSTATE = ['NSW', 'ACT', 'VIC', 'QLD', 'WA', 'NT', 'SA', 'TAS'];

export const formattedCurrentDate = moment().format('YYYY-MM-DD');
const MIN_DATE = '01/01/1990';
const MIN_DATE_ERROR_MESSAGE = 'We are in 21st Century';
const MAX_DATE_ERROR_MESSAGE = 'Date cannot be greater than todays date';
const MIN_DATE_MOVEOUT_ERROR_MESSAGE =
  'Move Out date should not be equal to or less than Move In date';
const MAX_DATE_MOVEOUT_ERROR_MESSAGE =
  'Previous address Move Out Date cannot be greater than  Current Address Move In date and less than  previous address Move In date ';
const MOVEIN_DATE_ERROR_MESSAGE =
  'Previous address Move In date cannot be greater than  or equal to Current Address Move In date';

const AddressDetails = ({
  onInputChange,
  getValidationErrorsForField,
  clearAddress,
  landlordName,
  landlordPhone,
  setTabComplete,
  unitNumber,
  streetName,
  suburb,
  state,
  postcode,
  country,
  addressStartDate,
  address,
  isRenting,
  selectedLender,
  validationErrors,
  setTab,
  currentTab,
  streetType,
  streetNumber,
  livingDetailsUpdate,
  validateDateField,
  applicantType,
  isPrimaryApplicant,
  fieldSuffix,
  applicationStructure,
  addressId,
  prevAddresses,
  setPrevAddresses,
  deleteAddress,
  currunitNumber,
  currstreetName,
  currsuburb,
  currpostcode,
  currstate,
  setApplicationStrucutureStepper,
  contactDetails,
  opportunityId,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(768));
  const [noOfYearsStayed, setnoOfdays] = useState();
  const [rerenderDom, triggerDomRerender] = useState(false);
  const [addressUpsertInProgress, setaddressUpsertInProgress] = useState(false);
  const [deleting, deleteInProgress] = useState(false);
  const [addPrevAddress, setAddPrevAddress] = useState(false);
  const [searchAddressforCurrent, setSearchAddressforCurrent] = useState(false);
  const [currentAddressEnerManual, setCurrentAddressEnerManual] = useState(false);
  const [manualEnter, setManualEnter] = useState(false);

  const getAddressSchema = (addressStatus) => ({
    refId: Math.random(),
    addressType: 'Residential Address',
    id: '',
    address: '',
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    suburb: '',
    postcode: '',
    state: '',
    country: '',
    addressStatus,
    addressStartDate: '',
    addressEndDate: '',
    landlordName: '',
    landlordPhone: '',
  });

  useEffect(() => {
    if(applicantType === APPLICANT_TYPE.coApplicant){
        processGTMevent('address_details', 'PQAdditionalQuestions-CoAppAddress-PageLoad', 'Page Load')
    }
    else if(applicantType === APPLICANT_TYPE.primary)
    {
      processGTMevent('address_details', 'PQAdditionalQuestions-PageLoad', 'Page Load')
    }
    
    if (validationErrors?.length) {
      onInputChange('validationErrors', [], false);
    }
  }, []);

  useEffect(() => {
    if (setTab) {
      setTab('address_details');
    }
  }, [setTab]);

  useEffect(() => {
    setTimeout(() => {
      triggerDomRerender(false);
    }, 300);
  }, [prevAddresses]);

  useEffect(() => {
    setApplicationStrucutureStepper(applicationStructure);
  }, [applicationStructure]);

  const validatePrevAddress = () => {
    let isValid = true;
    prevAddresses.forEach((addressData, idx) => {
      if (
        (!manualEnter && !addressData.address) ||
        (manualEnter &&
          (!addressData.streetName ||
            !addressData.suburb ||
            !addressData.postcode ||
            postcodes.indexOf(addressData.postcode) < 0 ||
            !addressData.state)) ||
        !addressData.addressStartDate ||
        !addressData.addressEndDate
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  const nextDisabled =
    (validationErrors && validationErrors.length) ||
    (!currentAddressEnerManual && !address) ||
    (currentAddressEnerManual &&
      (!streetName || !suburb || !postcode || postcodes.indexOf(postcode) < 0 || !state)) ||
    (isRenting && applicantType === APPLICANT_TYPE.primary && (!landlordName || !landlordPhone)) ||
    !addressStartDate ||
    !validatePrevAddress();

  const employmentDetailsSection = () => {
    setaddressUpsertInProgress(true);
    const currentObj = {
      ...getAddressSchema('Current'),
      addressId,
      id: addressId,
      unitNumber,
      streetNumber,
      streetName,
      suburb,
      postcode,
      state,
      country,
      addressStartDate,
      addressEndDate: addressStartDate,
      landlordName,
      landlordPhone,
    };
    const addressList = [currentObj, ...prevAddresses].map((addressData) => {
      // eslint-disable-next-line no-param-reassign
      if (addressData.refId) delete addressData.refId; // Used for react `keys`
      // eslint-disable-next-line no-param-reassign
      addressData.id = addressData.addressId;
      return addressData;
    });
    // onInputChange('addressPostLenderSelect', addressList, false);
    livingDetailsUpdate(applicantType)
      .then(() => {
        setaddressUpsertInProgress(false);
        setTabComplete(currentTab);
        if (applicantType === APPLICANT_TYPE.primary) {
          processGTMevent('address_details', 'PQAdditionalQuestions-NextToEmployment', 'Click')
          localStorage.setItem(`pageCompleted${opportunityId}`, 'post-address-primary');
          setTabComplete('address_details');
          const nextRoute = `${ROUTES.employmentDetailsUri}/${applicantType}`;
          history.push(nextRoute);
        } else {
          localStorage.setItem(`pageCompleted${opportunityId}`, 'post-address-coapplicant');
          if(applicantType === APPLICANT_TYPE.coApplicant){
            processGTMevent('address_details', 'PQAdditionalQuestions-CoAppAddress-Next', 'Click');
          }
          setTabComplete('coApplicant_address_details');
          const nextRoute = `${ROUTES.coApplicantPersonalDetailsUri}/${APPLICANT_TYPE.coApplicant}`;
          history.push(nextRoute);
        }
      })
      .catch((error) => {
        setaddressUpsertInProgress(false);
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const showHideAddressBtn = () => {
    if (noOfYearsStayed > 3) {
      setAddPrevAddress(true);
    } else {
      setAddPrevAddress(false);
    }
  };
  const getNumberofYears = (fromDt, toDate) => Math.abs(fromDt.diff(toDate, 'years', false));

  const updateNoOfDays = () => {
    if (addressStartDate) {
      setnoOfdays(
        getNumberofYears(moment(addressStartDate, 'YYYY/MM/DD'), moment().format('YYYY/MM/DD')),
      );
    } else {
      setnoOfdays();
    }
  };

  useEffect(() => {
    updateNoOfDays();
  }, [addressStartDate]);

  useEffect(() => {
    showHideAddressBtn();
  }, [noOfYearsStayed]);

  useEffect(() => {
    updateNoOfDays();
    showHideAddressBtn();
  }, []);

  const updatePreviousAddressField = (fieldName, value, idx) => {
    if (!value) return;

    const clonePrevAddress = [...prevAddresses];
    // validateDateField(fieldName, []);
    triggerDomRerender(true);
    const addressToUpdate = clonePrevAddress[idx];
    addressToUpdate[fieldName] = value;
    clonePrevAddress.splice(idx, 1, addressToUpdate);
    // setPrevAddresses(fieldSuffix, _.sortBy([...clonePrevAddress], 'addressStartDate'));
    setPrevAddresses(fieldSuffix, [...clonePrevAddress]);
    setnoOfdays(
      getNumberofYears(
        moment(clonePrevAddress[idx].addressStartDate, 'YYYY/MM/DD'),
        moment().format('YYYY/MM/DD'),
      ),
    );
    showHideAddressBtn();
  };

  const updateCurrentFormattedAddress = (formattedAddress) => {
    if (formattedAddress) {
      Object.keys(formattedAddress).forEach((label) => {
        onInputChange(`${label}${fieldSuffix}`, formattedAddress[label]);
      });
    }
  };

  const updatePrevFormattedAddress = (formattedAddress, idx) => {
    if (formattedAddress) {
      const clonePrevAddress = [...prevAddresses];
      const addressToUpdate = clonePrevAddress[idx];
      clonePrevAddress.splice(idx, 1, { ...addressToUpdate, ...formattedAddress });
      setPrevAddresses(fieldSuffix, [...clonePrevAddress]);
    } else {
      const clonePrevAddress = [...prevAddresses];
      const addressToUpdate = {
        ...clonePrevAddress[idx],
        employerAddress: '',
        country: '',
        postalcode: '',
        postcode: '',
        state: '',
        streetName: '',
        buildingName: '',
        streetNumber: '',
        streetType: '',
        suburb: '',
        unitNumber: '',
      };
      clonePrevAddress.splice(idx, 1, { ...addressToUpdate, ...formattedAddress });
      setPrevAddresses(fieldSuffix, [...clonePrevAddress]);
    }
  };

  const handlePreviousAddress = () => {
    setPrevAddresses(fieldSuffix, [...prevAddresses, getAddressSchema('Previous')]);
    setAddPrevAddress(true);
  };

  const navigateToPrevious = () => {
    history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.primary}`);
  };

  const deleteAddressonClick = (prevAddressId, refId) => {
    const clonePrevAddress = [...prevAddresses];

    if (prevAddressId) {
      deleteInProgress(true);
      deleteAddress(prevAddressId)
        .then(() => {
          _.remove(clonePrevAddress, (curr) => curr.addressId === prevAddressId);
          deleteInProgress(false);
        })
        .catch((err) => {
          deleteInProgress(false);
          console.error('delete failed', err); // to be added correctly
        });
    } else {
      _.remove(clonePrevAddress, (curr) => curr.refId === refId);
    }

    setPrevAddresses(fieldSuffix, clonePrevAddress);
    showHideAddressBtn();
  };

  if (
    prevAddresses &&
    prevAddresses.map((addressData) => {
      console.log(
        'Date value: ',
        addressStartDate,
        addressData.addressStartDate,
        addressData.addressEndDate,
      );
    })
  ) {
    return (
      <>
        <div className="address-details-page">
          <div className="address-title-section">
            {applicantType === APPLICANT_TYPE.primary ? (
              <div className="address-details-who">PRIMARY</div>
            ) : applicantType !== APPLICANT_TYPE.primary ? (
              <>
                {applicantType === APPLICANT_TYPE.coApplicant ? (
                  <div className="address-details-who">CO-APPLICANT</div>
                ) : (
                  <div className="address-details-who">SPOUSE</div>
                )}
              </>
            ) : (
              <>
                {applicantType === APPLICANT_TYPE.coApplicant ||
                applicantType === APPLICANT_TYPE.spouse ? (
                  <div className="address-details-who">SPOUSE</div>
                ) : (
                  ''
                )}
              </>
            )}
            <div className="address-details-who" />
            <div className="address-details-title">Address Details </div>
            <div className="address-details-subtitle">
              {selectedLender} requires 3 years of address history for approval.
            </div>
          </div>
          <div className="landlord-details-section">
            <div className="current-landlord-header">CURRENT ADDRESS</div>
            <div className="current-address-subtitle">
              (All fields are required unless indicated as optional)
            </div>
            <div className="current-address-default-section">
              {!currentAddressEnerManual && (
                <AddressAutoComplete
                  fieldName={`address${fieldSuffix}`}
                  addressClass="px-0 currentaddress-select"
                  value={address}
                  label="Address"
                  placeholder="Search..."
                  columnCount={0}
                  getvalidationerrors={getValidationErrorsForField}
                  onChange={onInputChange}
                  onAddressSelection={updateCurrentFormattedAddress}
                  clearAddress={clearAddress}
                />
              )}
              <DatePickerWrapper
                className="current-address-to-date px-0"
                columnCount="0"
                dateFormat="dd/MM/yyyy"
                fieldName={`addressStartDate${fieldSuffix}`}
                getValidationErrors={getValidationErrorsForField}
                inline
                label="Move in date"
                maxDateMessage={MAX_DATE_ERROR_MESSAGE}
                maxDtVal={formattedCurrentDate}
                minDateMessage={MIN_DATE_ERROR_MESSAGE}
                minDtVal={MIN_DATE}
                onChange={onInputChange}
                placeholderVal="DD/MM/YYYY"
                // showYearMonth={['date', 'year', 'month']}
                validateDateField={validateDateField}
                value={addressStartDate}
                postSelect
              />
            </div>
            {!currentAddressEnerManual && (
              <div className="enter-manual-address">
                <span className="could-not-find">
                  Couldn’t find it?
                  <button
                    type="button"
                    className=" btn btn-link enter-manually"
                    onClick={() => setCurrentAddressEnerManual(true)}
                  >
                    <span className="enter-manually-label">Enter manually</span>
                  </button>
                </span>
              </div>
            )}

            {currentAddressEnerManual && (
              <>
                <>
                  <div className="adress-manual-enter ">
                    <span className="search-your-address">
                      You can search your address
                      <button
                        type="button"
                        className=" btn btn-link search-address"
                        onClick={() => {
                          setSearchAddressforCurrent(true);
                          setCurrentAddressEnerManual(false);
                        }}
                      >
                        Search address
                      </button>
                    </span>
                  </div>
                  <div className="form-row ">
                    <TextField
                      fieldName={`unitNumber${fieldSuffix}`}
                      value={unitNumber}
                      label="Unit number"
                      placeholder="Unit number"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colmd={4}
                      className="pr-3"
                      inline
                    />
                    <TextField
                      fieldName={`streetName${fieldSuffix}`}
                      value={streetName}
                      label="Street address"
                      placeholder="Street address"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colmd={4}
                      inline
                    />
                  </div>

                  <div className="form-row ">
                    <TextField
                      fieldName={`suburb${fieldSuffix}`}
                      value={suburb}
                      label="Suburb"
                      placeholder="Suburb"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colmd={4}
                      className="pr-3"
                      inline
                    />
                    <TextField
                      fieldName={`postcode${fieldSuffix}`}
                      value={postcode}
                      label="Postcode"
                      placeholder="Postcode"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colmd={4}
                      className="pr-3"
                      type="number"
                      inline
                    />
                    <DropDown
                      fieldName={`state${fieldSuffix}`}
                      value={state}
                      label="State"
                      colxs="12"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      options={
                        <>
                          <option value="" hidden>
                            Select...
                          </option>
                          {DLSTATE.map((optionState) => (
                            <option key={optionState} value={optionState}>
                              {optionState}
                            </option>
                          ))}
                        </>
                      }
                      colsm={3}
                      colmd={3}
                      scrollToNextFieldOnChage
                    />
                  </div>
                </>
              </>
            )}

            {isRenting && applicantType === APPLICANT_TYPE.primary && (
              <div className="landlord-name-phone">
                <TextField
                  fieldName={`landlordName${fieldSuffix}`}
                  value={landlordName}
                  label="Landlord / Agent Name"
                  placeholder="Enter here"
                  getvalidationerrors={getValidationErrorsForField}
                  onChange={onInputChange}
                  className="landlord-name"
                  inline
                />
                <TextField
                  fieldName={`landlordPhone${fieldSuffix}`}
                  value={landlordPhone}
                  label="Landlord / Agent Phone Number"
                  placeholder="Phone number"
                  getvalidationerrors={getValidationErrorsForField}
                  onChange={onInputChange}
                  IconPath={Call}
                  prependIcon
                  inline
                  className="landlord-phone"
                />
              </div>
            )}
          </div>

          <div className="second-address-section">
            {noOfYearsStayed < 3 && applicantType === APPLICANT_TYPE.primary && !addPrevAddress && (
              <Button className="btn-add-section" onClick={handlePreviousAddress}>
                <span className="add-details">+ Add Previous Address</span>
              </Button>
            )}
            {!!prevAddresses.length && (
              <div>
                <div className="second-address-label">PREVIOUS ADDRESS</div>{' '}
                {prevAddresses.map((addressData, idx) => (
                  <>
                    <div key={addressData.refId}>
                      <div className="prev-delete-block">
                        <Button
                          variant="danger"
                          className="delete-btn"
                          onClick={() =>
                            deleteAddressonClick(addressData.addressId, addressData.refId)
                          }
                        >
                          {deleting ? (
                            <CircularProgress color="inherit" size={24} thickness={2.6} />
                          ) : (
                            'Delete'
                          )}
                        </Button>
                      </div>
                      {!manualEnter ? (
                        <div>
                          <AddressAutoComplete
                            fieldName="address"
                            value={addressData.address}
                            addressClass="px-0 addressselection"
                            label="Search"
                            placeholder="Search..."
                            onChange={(_, value) =>
                              updatePreviousAddressField('address', value, idx)
                            }
                            columnCount={7}
                            getvalidationerrors={getValidationErrorsForField}
                            onAddressSelection={(formattedAddress) => {
                              updatePrevFormattedAddress(formattedAddress, idx);
                            }}
                            clearAddress={(formattedAddress) => {
                              updatePrevFormattedAddress('', idx);
                            }}
                          />

                          <div className="enter-manual-address">
                            <span className="could-not-find">
                              Couldn’t find it?
                              <button
                                type="button"
                                className=" btn btn-link enter-manually"
                                onClick={() => setManualEnter(true)}
                              >
                                <span className="enter-manually-label">Enter manually</span>
                              </button>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="adress-manual-enter ">
                            <span className="search-your-address">
                              You can search your address
                              <button
                                type="button"
                                className=" btn btn-link search-address"
                                onClick={() => {
                                  setManualEnter(false);
                                }}
                              >
                                Search address
                              </button>
                            </span>
                          </div>
                          <div className="adress-unit-streetname ">
                            <TextField
                              fieldName="unitNumber"
                              value={addressData.unitNumber}
                              label="Unit number"
                              placeholder="Unit number"
                              getvalidationerrors={getValidationErrorsForField}
                              onChange={(_, value) =>
                                updatePreviousAddressField('unitNumber', value, idx)
                              }
                              className="unit-number"
                              inline
                            />
                            <TextField
                              fieldName="streetName"
                              value={addressData.streetName}
                              label="Street address"
                              placeholder="Street address"
                              getvalidationerrors={getValidationErrorsForField}
                              onChange={(_, value) =>
                                updatePreviousAddressField('streetName', value, idx)
                              }
                              className="street-name"
                              inline
                            />
                          </div>

                          <div className="adress-suburb-postcode ">
                            <TextField
                              fieldName="suburb"
                              value={addressData.suburb}
                              label="Suburb"
                              placeholder="Suburb"
                              getvalidationerrors={getValidationErrorsForField}
                              onChange={(_, value) =>
                                updatePreviousAddressField('suburb', value, idx)
                              }
                              className="suburb"
                              inline
                            />
                            <TextField
                              fieldName="postcode"
                              value={addressData.postcode}
                              label="Postcode"
                              placeholder="Postcode"
                              getvalidationerrors={getValidationErrorsForField}
                              onChange={(_, value) =>
                                updatePreviousAddressField('postcode', value, idx)
                              }
                              className="postcode"
                              inline
                              type="number"
                            />
                            <DropDown
                              fieldName="state"
                              value={addressData.state}
                              label="State"
                              className="state"
                              getvalidationerrors={getValidationErrorsForField}
                              onChange={(_, value) =>
                                updatePreviousAddressField('state', value, idx)
                              }
                              options={
                                <>
                                  <option value="" hidden>
                                    Select...
                                  </option>
                                  {DLSTATE.map((optionState) => (
                                    <option key={optionState} value={optionState}>
                                      {optionState}
                                    </option>
                                  ))}
                                </>
                              }
                              scrollToNextFieldOnChage
                            />
                          </div>
                        </div>
                      )}
                      {/* {!rerenderDom && ( */}
                      <div className="previous-address-date">
                        <DatePickerWrapper
                          value={addressData.addressStartDate}
                          onChange={(_, value) =>
                            updatePreviousAddressField('addressStartDate', value, idx)
                          }
                          fieldName="addressStartDate"
                          label="Move in date"
                          // showYearMonth={['year', 'month']}

                          placeholderVal="DD/MM/YYYY"
                          validateDateField={validateDateField}
                          minDateMessage={MIN_DATE_ERROR_MESSAGE}
                          maxDateMessage={MOVEIN_DATE_ERROR_MESSAGE}
                          minDtVal={MIN_DATE}
                          maxDtVal={moment(addressStartDate)
                            .subtract(1, 'days')
                            .format('YYYY-MM-DD')}
                          columnCount="4"
                          getValidationErrors={getValidationErrorsForField}
                          className="prev-address-from-date"
                          inline
                          postSelect
                        />
                        <DatePickerWrapper
                          value={addressData.addressEndDate}
                          onChange={(_, value) =>
                            updatePreviousAddressField('addressEndDate', value, idx)
                          }
                          fieldName="addressEndDate"
                          label="Move out date"
                          placeholderVal="DD/MM/YYYY"
                          validateDateField={validateDateField}
                          minDateMessage={MIN_DATE_MOVEOUT_ERROR_MESSAGE}
                          maxDateMessage={MAX_DATE_MOVEOUT_ERROR_MESSAGE}
                          minDtVal={
                            moment(addressData.addressStartDate)
                              .add(1, 'days')
                              .format('YYYY-MM-DD') || null
                          }
                          maxDtVal={addressStartDate}
                          // showYearMonth={['year', 'month']}
                          columnCount="4"
                          className="prev-address-to-date"
                          getValidationErrors={getValidationErrorsForField}
                          inline
                          postSelect
                        />
                      </div>
                      {/* )} */}
                    </div>
                    <hr />
                  </>
                ))}
              </div>
            )}
          </div>

          {mobileView ? (
            <>
              <div className="btn  footer-actions btn-address-next">
                <button
                  type="button"
                  onClick={navigateToPrevious}
                  disabled={applicantType === APPLICANT_TYPE.primary}
                  className="btn btn-primary  btn-prev-page"
                >
                  <span className="pr-2">
                    <img src={leftnormal} alt="right" className="right-icon" />
                  </span>
                  Previous
                </button>

                <button
                  type="button"
                  onClick={employmentDetailsSection}
                  disabled={nextDisabled}
                  className="btn btn-primary btn-next-page"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {addressUpsertInProgress ? (
                    <CircularProgress color="inherit" size={24} thickness={2.6} />
                  ) : (
                    <>
                      Next
                      <img src={rightnormal} alt="right" className="right-icon" />
                    </>
                  )}
                </button>
              </div>
            </>
          ) : (
            <div className="btn  footer-actions btn-address-next">
              <button
                type="button"
                onClick={navigateToPrevious}
                disabled={applicantType === APPLICANT_TYPE.primary}
                className="btn btn-primary  btn-next-page"
              >
                <span className="pr-3">
                  <img src={leftnormal} alt="right" className="right-icon" />
                </span>
                Previous
              </button>

              {applicantType === APPLICANT_TYPE.coApplicant ? (
                <Progress completed={2} pending={3} />
              ) : (
                <Progress completed={1} pending={1} />
              )}

              <button
                type="button"
                onClick={employmentDetailsSection}
                disabled={nextDisabled}
                className="btn btn-primary btn-next-page"
              >
                {addressUpsertInProgress ? (
                  <CircularProgress color="inherit" size={24} thickness={2.6} />
                ) : (
                  'Next'
                )}

                <span className="px-3">
                  <img src={rightnormal} alt="right" className="right-icon" />
                </span>
              </button>
            </div>
          )}
        </div>
      </>
    );
  }
};
export default AddressDetails;
