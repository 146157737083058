/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Button, Modal, Row, Col, Card, Form, Container, ButtonToolbar } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReduxRenderField from '../../components/ReduxRenderField';
import fieldChanged from '../../Actions/FieldChanged';
import SaveQuoteSuccess from './saveQuoteSuccess';
import { setCompleteTab } from '../../Actions/tab';
import renderCheckboxField from '../../components/ReduxRenderField/renderCheckboxField';
import './saveQuote.scss';
import { onSaveQuoteleadCreation } from '../../Actions/car';
import Email from '../../../assets/image/saveEmail.svg';
import call from '../../../assets/image/call.svg';
import emailQuoteImg from '../../../assets/image/SaveQuoteBg.svg';
import lockImg from '../../../assets/image/Lock.svg';
import { ROUTES } from '../../util/constants';
import { EMBED_SRC_LINK, EMBED_SRC_DOCID, EMBED_SRC_DATAREF } from '../../util/embeddedSource';

class SaveQuoteModal extends Component {
  constructor() {
    super();

    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate() {
    const { showForm } = this.props;
    if (showForm) {
      if (document.getElementById(EMBED_SRC_DOCID)) {
        return;
      }
      const js = document.createElement('script');
      js.id = EMBED_SRC_DOCID;
      js.src = EMBED_SRC_LINK;
      document.getElementsByTagName('head')[0].appendChild(js);
    }
  }

  close() {
    const { closeForm } = this.props;
    closeForm();
  }

  submit(data) {
    const { isValid, onSaveQuote } = this.props;
    onSaveQuote(data);
    this.props
      .leadCreation()
      .then((response) => {
        // if (response.isExisting) {
        //   this.props.history.push(ROUTES.login);
        //   this.props.closeForm();
        //   return;
        // }
      })
      .catch((err) => {});
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      showForm,
      saveQuote,
      onSaveQuoteSuccess,
      setTabComplete,
    } = this.props;

    return (
      <div>
        {saveQuote && (
          <SaveQuoteSuccess
            onSaveQuoteSuccess={onSaveQuoteSuccess}
            setTabComplete={setTabComplete}
          />
        )}
        <Modal
          show={showForm}
          size="xl"
          onHide={this.close}
          className="modal-wrapper login-modal-wrapper email-save-quote-modal"
        >
          <Modal.Body className="p-0">
            <div className="show_column">
              <div className="review-section">
                <img src={emailQuoteImg} className="email-quote" alt="email-my-quote" />
                {/* <div className="embedsocial-reviews" data-ref={EMBED_SRC_DATAREF} />
                <Card className="card-review-section">
                  <Card.Body className="card-body">
                    <Card.Title className="card-title">Brian prinsloo</Card.Title>
                    <Card.Text className="card-quote-description">
                      Thank you Eric for making everything so easy and smooth,it was a pleasure
                      dealing with you from start to finish. I cant recommend Eric enough if your
                      looking for honest trustworty advice and direction in your.
                    </Card.Text>
                  </Card.Body>
                </Card> */}
              </div>
              <div className="save-quote-section">
                <div className="save-quote-col">
                  <div className="save-quote-body-h1">We'll email you quote</div>
                  <div className="save-quote-header-desc">
                    This will help you to access your application at anytime.
                  </div>
                </div>
                <Form noValidate className="save-quote-form" onSubmit={handleSubmit(this.submit)}>
                  <div className="save-quote-row">
                    <Field
                      label="First name"
                      name="firstName"
                      type="text"
                      placeholder="First name"
                      required
                      component={ReduxRenderField}
                    />
                    <Field
                      label="Last name"
                      name="lastName"
                      type="text"
                      placeholder="last name"
                      required
                      component={ReduxRenderField}
                    />
                  </div>
                  <Field
                    label="Email address"
                    name="email"
                    type="email"
                    placeholder="Email address"
                    required
                    component={ReduxRenderField}
                    PrependComponent={() => (
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          id="email-icon-prepend"
                          className="icon-prepend email-icon-prepend"
                        >
                          <img src={Email} alt="email icon" />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    )}
                  />
                  <div className="save-quote-row save-modal-mobile">
                    <Field
                      label="Mobile number"
                      name="phoneNumber"
                      type="tel"
                      placeholder="000 000 0000"
                      required
                      component={ReduxRenderField}
                      PrependComponent={() => (
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            id="mobile-icon-prepend"
                            className="icon-prepend mobile-icon-prepend"
                          >
                            <img src={call} alt="mobile icon" />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                      )}
                    />
                  </div>
                  <div className="privacy-policy">
                    <img src={lockImg} alt="lock-image" className="lock-img" />
                    <div>
                      I accept this
                      <a
                        className="btn-policy-path"
                        href="https://www.strattonfinance.com.au/privacy-policy"
                      >
                        &nbsp;Privacy & Credit Reporting Policy&nbsp;
                      </a>
                      and this Privacy Collection Notice and Consent Form. I confirm that I am
                      authorised to provide the personal information presented and I consent to my
                      information being checked by Stratton to confirm my identity.
                    </div>
                  </div>
                  <Modal.Footer className="email-quote-footer">
                    <Button
                      type="submit"
                      disabled={pristine || submitting}
                      variant="primary"
                      block
                      className="savequotesubmit m-0"
                    >
                      Send me Quote
                    </Button>
                    <Button
                      type="button"
                      disabled={submitting}
                      variant="light"
                      block
                      onClick={this.close}
                      className="savequotecancel"
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const SaveQuoteForm = reduxForm({
  form: 'saveQuote',
  validate: (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Required';
    }
    return errors;
  },
})(SaveQuoteModal);

const selector = formValueSelector('saveQuote'); // <-- same as form name
export default connect(
  (state) => {
    // can select values individually
    const email = selector(state, 'email');
    const firstName = selector(state, 'firstName');
    const lastName = selector(state, 'lastName');

    const phoneNumber = selector(state, 'phoneNumber');
    return {
      showForm: state.account.showSaveQuote,
      saveQuote: state.account.saveQuote,
      email,
      firstName,
      lastName,
      phoneNumber,
    };
  },

  (dispatch) => ({
    closeForm: () => {
      dispatch(fieldChanged('account', 'showSaveQuote', false, false));
      dispatch(fieldChanged('account', 'saveQuote', false, false));
    },
    onSaveQuote: (reduxStatedata) => {
      dispatch(fieldChanged('account', 'saveQuote', true, false));
      dispatch(fieldChanged('account', 'loggedIn', true, false));
      dispatch(fieldChanged('account', 'showSaveQuote', false, false));
      dispatch(fieldChanged('account', 'reduxFormvalues', reduxStatedata, false));
      dispatch(fieldChanged('applicants', 'email', reduxStatedata.email, false));
      dispatch(fieldChanged('applicants', 'firstName', reduxStatedata.firstName, false));
      dispatch(fieldChanged('applicants', 'phoneNumber', reduxStatedata.phoneNumber, false));
      dispatch(fieldChanged('applicants', 'lastName', reduxStatedata.lastName, false));
    },
    onSaveQuoteSuccess: () => {
      dispatch(fieldChanged('account', 'SaveQuoteSuccess', true, false));
      dispatch(fieldChanged('account', 'saveQuote', false, false));
    },
    leadCreation: () => dispatch(onSaveQuoteleadCreation()),
    setTabComplete: (tabName) => {
      dispatch(setCompleteTab(tabName));
    },
  }),
)(withRouter(SaveQuoteForm));
