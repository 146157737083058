const gtmMappings = [
  [
    'car',
    'start',
    'started',
    {
      event: 'PQStart-GetStarted',
      eventCategory: 'Start Page',
      eventAction: '2 - Click Get Started',
      eventLabel: 'Get Started',
    },
  ],
  [
    'car',
    'pageLoad',
    'carStartPageLoad',
    {
      event: 'PQStart-PageLoad',
      eventCategory: 'Start Page',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'car',
    'pageLoad',
    'carDetailsPageLoad',
    {
      event: 'PQCarDetails-PageLoad',
      eventCategory: 'Car Details',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'Car',
    'carCondition',
    '*',
    {
      event: 'PQCarDetails-Condition',
      eventCategory: 'Car Details',
      eventAction: '2 - Condition',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'car',
    'regoNumber',
    '*',
    {
      event: 'PQCarDetails-Rego',
      eventCategory: 'Car Details',
      eventAction: '3 - Rego',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'car',
    'regoState',
    '*',
    {
      event: 'PQCarDetails-RegoState',
      eventCategory: 'Car Details',
      eventAction: '4 - Rego State',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Car',
    'manufacturingYear',
    '*',
    {
      event: 'PQCarDetails-Year',
      eventCategory: 'Car Details',
      eventAction: '5 - Car Year',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Car',
    'vehicleMake',
    '*',
    {
      event: 'PQCarDetails-CarMake',
      eventCategory: 'Car Details',
      eventAction: '7 - Car Make',
      eventLabel: '~selected~value~',
    },
  ],

  [
    'Car',
    'vehicleModel',
    '*',
    {
      event: 'PQCarDetails-CarModel',
      eventCategory: 'Car Details',
      eventAction: '8 - Car Model',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'car',
    'vehicleSeries',
    '*',
    {
      event: 'PQCarDetails-CarSeries',
      eventCategory: 'Car Details',
      eventAction: '9 - Car Series',
      eventLabel: '~selected~name~',
    },
  ],
  [
    'car',
    'vehicleVariant',
    '*',
    {
      event: 'PQCarDetails-CarVariant',
      eventCategory: 'Car Details',
      eventAction: '10 - Car Variant',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'car',
    'factoryOptions',
    '*',
    {
      event: 'PQCarDetails-CarFactoryOptions',
      eventCategory: 'Car Details',
      eventAction: '11 - Car Factory Options',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Car',
    'purchaseSource',
    '*',
    {
      event: 'PQCarDetails-PurchaseFrom',
      eventCategory: 'Car Details',
      eventAction: '12 - Car Purchase From',
      eventLabel: '~selected~value~',
    },
  ],

  [
    'Car',
    'purchaseUse',
    '*',
    {
      event: 'PQCarDetails-CarUsage',
      eventCategory: 'Car Details',
      eventAction: '13 - Car Usage',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Car',
    'purchasePriceValue',
    '*',
    {
      event: 'PQCarDetails-PurchasePrice',
      eventCategory: 'Car Details',
      eventAction: '14 - Purchase Price',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Car',
    'depositAmountValue',
    '*',
    {
      event: 'PQCarDetails-DepositAmount',
      eventCategory: 'Car Details',
      eventAction: '15 - Deposit Amount',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Car',
    'termValue',
    '*',
    {
      event: 'PQCarDetails-Term',
      eventCategory: 'Car Details',
      eventAction: '16 - Term',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'car',
    'seeContact',
    'seeContactPage',
    {
      event: 'PQCarDetails-Next',
      eventCategory: 'Car Details',
      eventAction: '17 - Next',
      eventLabel: 'Clicked',
    },
  ],
  [
    'estimation',
    'pageLoad',
    'estimationPageLoad',
    {
      event: 'PQEstimates-PageLoad',
      eventCategory: 'Estimates',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'estimation',
    'loanOptions',
    '*',
    {
      event: 'PQEstimates-NumberOfLoanOptions',
      eventCategory: 'Estimates',
      eventAction: '2.1 - Loan Options',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Repayments',
    'range',
    '*',
    {
      event: 'PQEstimates-RepaymentsRange',
      eventCategory: 'Estimates',
      eventAction: '2.2 - Repayments Range',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Interest',
    'range',
    '*',
    {
      event: 'PQEstimates-InterestRange',
      eventCategory: 'Estimates',
      eventAction: '2.3 - Interest Range',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'estimation',
    'availableLoanOptions',
    '*',
    {
      event: 'PQPersonalDetails-LoanOptions',
      eventCategory: 'Personal Details',
      eventAction: '18 - Available loan options',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'estimation',
    'termVal',
    '*',
    {
      event: 'PQEstimates-LoanTerm',
      eventCategory: 'Estimates',
      eventAction: '2.4 - Loan Term',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'estimation',
    'residualSwitch',
    '*',
    {
      event: 'PQEstimates-Residual',
      eventCategory: 'Estimates',
      eventAction: '2.5 - Include Residual',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'estimation',
    'update',
    'UpdateLoanDetails',
    {
      event: 'PQEstimates-UpdateLoanDetails',
      eventCategory: 'Estimates',
      eventAction: '3 - Update Loan Details',
      eventLabel: 'Clicked',
    },
  ],
  [
    'estimation',
    'updateConfirm',
    'UpdatedLoan',
    {
      event: 'PQEstimates-UpdateLoan',
      eventCategory: 'Estimates',
      eventAction: '4 - Update Loan',
      eventLabel: 'Clicked',
    },
  ],
  [
    'estimation',
    'continue',
    'estimateNext',
    {
      event: 'PQEstimates-Continue',
      eventCategory: 'Estimates',
      eventAction: '5 - Continue',
      eventLabel: 'Clicked',
    },
  ],
  [
    'applicants',
    'PQAdditionalQuestions-SpouseDetails-PageLoad',
    'Page Load',
    {
      event: 'PQAdditionalQuestions-SpouseDetails-PageLoad',
      eventCategory: 'Additional Questions',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'applicants',
    'PQAdditionalQuestions-SpouseDetails-Next',
    'Click',
    {
      event: 'PQAdditionalQuestions-SpouseDetails-Next',
      eventCategory: 'Additional Questions',
      eventAction: '2 - Next',
      eventLabel: 'Click',
    },
  ],
  [
    'applicants',
    'pageLoad',
    'contactDetailsMainPage',
    {
      event: 'PQPersonalDetails-PageLoad',
      eventCategory: 'Personal Details',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'applicants',
    'PQAdditionalQuestions-CoAppPersonal-PageLoad',
    'Page Load',
    {
      event: 'PQAdditionalQuestions-CoAppPersonal-PageLoad',
      eventCategory: 'Additional Questions',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'applicants',
    'PQAdditionalQuestions-CoAppPersonal-Next',
    'Click',
    {
      event: 'PQAdditionalQuestions-CoAppPersonal-Next',
      eventCategory: 'Additional Questions',
      eventAction: '2 - Next',
      eventLabel: 'Click',
    },
  ],
  [
    'applicants',
    'firstName',
    '*',
    {
      event: 'PQPersonalDetails-FirstName',
      eventCategory: 'Personal Details',
      eventAction: '2.1 - First Name',
      eventLabel: 'First Name Entered',
    },
  ],
  [
    'applicants',
    'acceptPrivacyPolicy',
    '*',
    {
      event: 'PQPersonalDetails-PrivacyCheckbox',
      eventCategory: 'Personal Details',
      eventAction: '2.2 - Privacy Checkbox',
      eventLabel: 'Privacy Checkbox Checked',
    },
  ],
  [
    'applicants',
    'seeEstimationPage',
    'seeEstimationPage',
    {
      event: 'PQGetEstimate',
      eventCategory: 'Personal Details',
      eventAction: '3 - Get Estimate',
      eventLabel: 'Clicked',
    },
  ],
  [
    'applicants',
    'lead',
    'leadCreation',
    {
      event: 'PQCreate-Lead',
      eventCategory: 'Lead',
      eventAction: '3.1 - Lead Creation',
      eventLabel: 'Click',
    },
  ],

  [
    'applicants',
    'leadId',
    '*',
    {
      event: 'PQPersonalDetails-LeadID',
      eventCategory: 'Personal Details',
      eventAction: '2 - Lead ID',
      eventLabel: '~selected~value~',
    },
  ],

  [
    'applicants',
    'pageLoad',
    'seepersonalDetailsPage',
    {
      event: 'PQPersonalDetailsStart-PageLoad',
      eventCategory: 'Personal Details Start',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],

  [
    'applicants',
    'continue',
    'continue',
    {
      event: 'PQPersonalDetailsStart-Continue',
      eventCategory: 'Personal Details Start',
      eventAction: '2 - Contnue',
      eventLabel: 'Clicked',
    },
  ],

  [
    'applicants',
    'passwordpageLoad',
    'seepasswordPage',
    {
      event: 'PQCreatePassword-PageLoad',
      eventCategory: 'Create Password',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],

  [
    'applicants',
    'editEmail',
    'editEmail',
    {
      event: 'PQCreatePassword-EditEmail',
      eventCategory: 'Create Password',
      eventAction: '2 - Edit Email',
      eventLabel: 'Clicked',
    },
  ],

  [
    'applicants',
    'passwordCreateNext',
    'passwordCreateNext',
    {
      event: 'PQCreatePassword-Next',
      eventCategory: 'Create Password',
      eventAction: '3 - Next',
      eventLabel: 'Clicked',
    },
  ],

  [
    'applicants',
    'password',
    'password',
    {
      event: 'PQPersonalDetails-CreatePassword',
      eventCategory: 'Personal Details',
      eventAction: '4 - Create Password',
      eventLabel: 'Click',
    },
  ],

  [
    'applicants',
    'passwordpageLoad',
    'seepasswordPage',
    {
      event: 'PQMobileVerification-PageLoad',
      eventCategory: 'Mobile Verification',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'applicants',
    'SMSVerify',
    'SMSVerify',
    {
      event: 'PQMobileVerification-SMSVerify',
      eventCategory: 'Mobile Verification',
      eventAction: '1.1 - SMS Verify',
      eventLabel: 'Code Entered',
    },
  ],
  [
    'applicants',
    'editNumber',
    'editPhoneNumber',
    {
      event: 'PQMobileVerification-EditNumber',
      eventCategory: 'Mobile Verification',
      eventAction: '2 - Edit Number',
      eventLabel: 'Clicked',
    },
  ],
  [
    'applicants',
    'sendVerification',
    'verificationComplete',
    {
      event: 'PQMobileVerification-SendVerification',
      eventCategory: 'Mobile Verification',
      eventAction: '3 - Send Verification',
      eventLabel: 'Clicked',
    },
  ],

  [
    'applicants',
    'verifyPassword',
    'verificationComplete',
    {
      event: 'PQPersonalDetails-SMSVerify',
      eventCategory: 'Personal Details',
      eventAction: '5 - SMS Verification',
      eventLabel: 'Click',
    },
  ],

  [
    'applicants',
    'dlState',
    '*',
    {
      event: 'PQPersonalDetails-LicenseState',
      eventCategory: 'Personal Details',
      eventAction: '6 - Drivers License State',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'applicants',
    'licenceType',
    '*',
    {
      event: 'PQPersonalDetails-LicenseType',
      eventCategory: 'Personal Details',
      eventAction: '7 - License Type',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'applicants',
    'inlineNext',
    'identityDetails',
    {
      event: 'PQPersonalDetails-NextTo',
      eventCategory: 'Personal Details',
      eventAction: '8 - Next (to Address)',
      eventLabel: 'Click',
    },
  ],
  [
    'identityDetails',
    'PQAdditionalQuestions-CoAppIdentity-PageLoad',
    'Page Load',
    {
      event: 'PQAdditionalQuestions-CoAppIdentity-PageLoad',
      eventCategory: 'Additional Questions',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'identityDetails',
    'PQAdditionalQuestions-CoAppIdentity-Next',
    'Click',
    {
      event: 'PQAdditionalQuestions-CoAppIdentity-Next',
      eventCategory: 'Additional Questions',
      eventAction: '4 - Next',
      eventLabel: 'Click',
    },
  ],
  [
    'address_details',
    'PQAdditionalQuestions-CoAppAddress-PageLoad',
    'Page Load',
    {
      event: 'PQAdditionalQuestions-CoAppAddress-PageLoad',
      eventCategory: 'Additional Questions',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'address_details',
    'PQAdditionalQuestions-CoAppAddress-Next',
    'Click',
    {
      event: 'PQAdditionalQuestions-CoAppAddress-Next',
      eventCategory: 'Additional Questions',
      eventAction: '3 - Next',
      eventLabel: 'Click',
    },
  ],
  [
    'living_details',
    'PQAdditionalQuestions-CoAppDetails-PageLoad',
    'Page Load',
    {
      event: 'PQAdditionalQuestions-CoAppDetails-PageLoad',
      eventCategory: 'Additional Questions',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'living_details',
    'PQAdditionalQuestions-CoAppDetails-Next',
    'Click',
    {
      event: 'PQAdditionalQuestions-CoAppDetails-Next',
      eventCategory: 'Additional Questions',
      eventAction: '8 - Next',
      eventLabel: 'Click',
    },
  ],
  [
    'applicants',
    'address',
    '*',
    {
      event: 'PQPersonalDetails-Address',
      eventCategory: 'Personal Details',
      eventAction: '8 - Address',
      eventLabel: 'Address Selected',
    },
  ],
  [
    'address_details',
    'PQAdditionalQuestions-PageLoad',
    'Page Load',
    {
      event: 'PQAdditionalQuestions-PageLoad',
      eventCategory: 'Additional Questions',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'address_details',
    'PQAdditionalQuestions-NextToEmployment',
    'Click',
    {
      event: 'PQAdditionalQuestions-NextToEmployment',
      eventCategory: 'Additional Questions',
      eventAction: '4 - Next to Employment',
      eventLabel: 'Click',
    },
  ],
  [
    'employment_details',
    'PQAdditionalQuestions-EmploymentPageLoad',
    'Page Load',
    {
      event: 'PQAdditionalQuestions-EmploymentPageLoad',
      eventCategory: 'Additional Questions',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'employment_details',
    'PQAdditionalQuestions-NextFinishSingle',
    'Click',
    {
      event: 'PQAdditionalQuestions-NextFinishSingle',
      eventCategory: 'Additional Questions',
      eventAction: '5 - Next Finish Single',
      eventLabel: 'Click',
    },
  ],
  [
    'applicants',
    'duration',
    '*',
    {
      event: 'PQPersonalDetails-AddressDuration',
      eventCategory: 'Personal Details',
      eventAction: '9 - Address Duration',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'applicants',
    'residentialStatus',
    '*',
    {
      event: 'PQPersonalDetails-LivingSituation',
      eventCategory: 'Personal Details',
      eventAction: '10 - Living Situation',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'employment_details',
    'PQAdditionalQuestions-SpouseEmployment-PageLoad',
    'Page Load',
    {
      event: 'PQAdditionalQuestions-SpouseEmployment-PageLoad',
      eventCategory: 'Additional Questions',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'employment_details',
    'PQAdditionalQuestions-SpouseEmployment-Next',
    'Click',
    {
      event: 'PQAdditionalQuestions-SpouseEmployment-Next',
      eventCategory: 'Additional Questions',
      eventAction: '4 - Next',
      eventLabel: 'Click',
    },
  ],
  [
    'applicants',
    'relationship',
    '*',
    {
      event: 'PQPersonalDetails-RelationshipStatus',
      eventCategory: 'Personal Details',
      eventAction: '11 - Relationship Status',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'applicants',
    'dependants',
    '*',
    {
      event: 'PQPersonalDetails-Dependents',
      eventCategory: 'Personal Details',
      eventAction: '12 - Dependents',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'employment_details',
    'PQAdditionalQuestions-CoAppEmp-PageLoad',
    'Page Load',
    {
      event: 'PQAdditionalQuestions-CoAppEmp-PageLoad',
      eventCategory: 'Additional Questions',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'employment_details',
    'PQAdditionalQuestions-CoAppEmp-Next',
    'Click',
    {
      event: 'PQAdditionalQuestions-CoAppEmp-Next',
      eventCategory: 'Additional Questions',
      eventAction: '5 - Next',
      eventLabel: 'Click',
    },
  ],
  [
    'applicants',
    'inlineNext',
    'employmentDetails',
    {
      event: 'PQPersonalDetails-NextToEmployment',
      eventCategory: 'Personal Details',
      eventAction: '13 - Next (To Employment Status)',
      eventLabel: 'Click',
    },
  ],
  [
    'applicants',
    'verifyPassword',
    'verificationComplete',
    {
      event: 'PQPersonalDetails-SMSVerify',
      eventCategory: 'Personal Details',
      eventAction: '5 - SMS Verification',
      eventLabel: 'Click',
    },
  ],
  [
    'applicants',
    'employmentStatus',
    '*',
    {
      event: 'PQPersonalDetails-EmploymentStatus',
      eventCategory: 'Personal Details',
      eventAction: '14 - Employment Status',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'applicants',
    'employeeDuration',
    '*',
    {
      event: 'PQPersonalDetails-EmploymentDuration',
      eventCategory: 'Personal Details',
      eventAction: '15 - EmploymentDuration',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'applicants',
    'abnNumber',
    '*',
    {
      event: 'PQPersonalDetails-ABN',
      eventCategory: 'Personal Details',
      eventAction: '16 - ABN',
      eventLabel: 'ABN Entered',
    },
  ],
  [
    'applicants',
    'inlineNext',
    'employmentDetailsComplete',
    {
      event: 'PQPersonalDetails-NextToAssets',
      eventCategory: 'Personal Details',
      eventAction: '17 - Next to Assets/Liabilities',
      eventLabel: 'Click',
    },
  ],
  [
    'applicants',
    'pageLoad',
    'seefinanceDetailsPage',
    {
      event: 'PQPersonalDetails-NextToFinances',
      eventCategory: 'Personal Details',
      eventAction: '19 - Continue to Finances',
      eventLabel: 'Click',
    },
  ],
  [
    'finances',
    'pageLoad',
    'financesPageLoad',
    {
      event: 'PQFinances-PageLoad',
      eventCategory: 'Finances',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'finances',
    'assets',
    'addAssets',
    {
      event: 'PQFinances-AddAssets',
      eventCategory: 'Finances',
      eventAction: '3 - Add Assets',
      eventLabel: 'Click',
    },
  ],
  [
    'finances',
    'totalassets',
    '*',
    {
      event: 'PQFinances-TotalAssets',
      eventCategory: 'Finances',
      eventAction: '3.1 - Total Assets',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'finances',
    'liabilities',
    'addLiability',
    {
      event: 'PQFinances-AddLiabilities',
      eventCategory: 'Finances',
      eventAction: '4 - Add Liabilities',
      eventLabel: 'Click',
    },
  ],
  [
    'finances',
    'totalliabilities',
    '*',
    {
      event: 'PQFinances-TotalLiabilities',
      eventCategory: 'Finances',
      eventAction: '4.1 - Total Liabilities',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'finances',
    'income',
    'addIncome',
    {
      event: 'PQFinances-Personal-AddIncome',
      eventCategory: 'Finances',
      eventAction: '2 - Personal - Add income',
      eventLabel: 'Click',
    },
  ],
  [
    'finances',
    'totalincome',
    '*',
    {
      event: 'PQFinances-TotalIncome',
      eventCategory: 'Finances',
      eventAction: '2.1 - Total Income',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'finances',
    'spouseIncome',
    'addSpouseIncome',
    {
      event: 'PQFinances-Spouse-AddIncome',
      eventCategory: 'Finances',
      eventAction: '2.2 - Spouse - Add Spouse Income',
      eventLabel: 'Click',
    },
  ],
  [
    'finances',
    'totalspouseIncome',
    '*',
    {
      event: 'PQFinances-TotalSpouseIncome',
      eventCategory: 'Finances',
      eventAction: '2.3 - Total Spouse Income',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'finances',
    'inlineNext',
    'income',
    {
      event: 'OAFinIncSubmit',
      eventCategory: 'Online Application',
      eventAction: 'Finances - Income',
      eventLabel: 'Submitted',
    },
  ],
  [
    'finances',
    'expenses',
    'addExpenses',
    {
      event: 'PQFinances-AddExpenses',
      eventCategory: 'Finances',
      eventAction: '6 - Add Expenses',
      eventLabel: 'Click',
    },
  ],
  [
    'finances',
    'totalexpenses',
    '*',
    {
      event: 'PQFinances-TotalExpenses',
      eventCategory: 'Finances',
      eventAction: '6.1 - Total Expenses',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'finances',
    'termNconditionChk',
    '*',
    {
      event: 'PQFinances-ChangesToIncome',
      eventCategory: 'Finances',
      eventAction: '7 - Changes to Income',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'finances',
    'submit',
    'submit',
    {
      event: 'PQFinances-Submit',
      eventCategory: 'Finances',
      eventAction: '8 - Submit',
      eventLabel: 'Click',
    },
  ],
  [
    'finances',
    'creditquoteaccept',
    'creditquoteaccept',
    {
      event: 'PQCreditQuoteAcceptance-Submit',
      eventCategory: 'Credit Quote',
      eventAction: '01 - Submit',
      eventLabel: 'Click',
    },
  ],
  [
    'compareloanoptions',
    'pageLoad',
    '*',
    {
      event: 'PQCompare-PageLoad',
      eventCategory: 'Compare',
      eventAction: 'Page-Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'uploaddocument',
    'PQUploads-PageLoad',
    'Page Load',
    {
      event: 'PQUploads-PageLoad',
      eventCategory: 'Uploads',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'uploaddocument',
    'PQUploads-Next',
    'Click',
    {
      event: 'PQUploads-Next',
      eventCategory: 'Uploads',
      eventAction: '2 - Next',
      eventLabel: 'Click',
    },
  ],
  [
    'uploaddocument',
    'PQUploads-Spouse-PageLoad',
    'Page Load',
    {
      event: 'PQUploads-Spouse-PageLoad',
      eventCategory: 'Uploads',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'uploaddocument',
    'PQUploads-Spouse-Next',
    'Click',
    {
      event: 'PQUploads-Spouse-Next',
      eventCategory: 'Uploads',
      eventAction: '2 - Next',
      eventLabel: 'Click',
    },
  ],
  [
    'uploaddocument',
    'PQUploads-CoApp-PageLoad',
    'Page Load',
    {
      event: 'PQUploads-CoApp-PageLoad',
      eventCategory: 'Uploads',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'uploaddocument',
    'PQUploads-CoApp-Next',
    'Click',
    {
      event: 'PQUploads-CoApp-Next',
      eventCategory: 'Uploads',
      eventAction: '2 - Next',
      eventLabel: 'Click',
    },
  ],
  [
    'compareloanoptions',
    'selectLender',
    '*',
    {
      event: 'PQCompare-SelectedProduct',
      eventCategory: 'Compare',
      eventAction: '3 - Selected Product',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'pqfinalsubmission',
    'selectedPQ',
    '*',
    {
      event: 'PQFinalSubmission',
      eventCategory: 'Final Submission',
      eventAction: '1 - Final Submissiont',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'PQ-PageLoad',
    'Page Load',
    {
      event: 'PQBikeDetails-PageLoad',
      eventCategory: 'Bike Details',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'Caravan',
    'PQ-PageLoad',
    'Page Load',
    {
      event: 'PQCaravanDetails-PageLoad',
      eventCategory: 'Caravan Details',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'Boat',
    'PQ-PageLoad',
    'Page Load',
    {
      event: 'PQBoatDetails-PageLoad',
      eventCategory: 'Boat Details',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'Commercial Equipment',
    'PQ-PageLoad',
    'Page Load',
    {
      event: 'PQCommercialEquipmentDetails-PageLoad',
      eventCategory: 'Commercial Equipment Details',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'Unsecured Loan',
    'PQ-PageLoad',
    'Page Load',
    {
      event: 'PQUnsecured LoanDetails-PageLoad',
      eventCategory: 'Unsecured Loan Details',
      eventAction: '1 - Page Load',
      eventLabel: 'Page Load',
    },
  ],
  [
    'Boat',
    'carCondition',
    '*',
    {
      event: 'PQBoatDetails-Condition',
      eventCategory: 'Boat Details',
      eventAction: '2 - Condition',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Caravan',
    'carCondition',
    '*',
    {
      event: 'PQCaravanDetails-Condition',
      eventCategory: 'Caravan Details',
      eventAction: '2 - Condition',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'carCondition',
    '*',
    {
      event: 'PQBikeDetails-Condition',
      eventCategory: 'Bike Details',
      eventAction: '2 - Condition',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Boat',
    'manufacturingYear',
    '*',
    {
      event: 'PQBoatDetails-Year',
      eventCategory: 'Boat Details',
      eventAction: '5 - Boat Year',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Caravan',
    'manufacturingYear',
    '*',
    {
      event: 'PQCaravanDetails-Year',
      eventCategory: 'Caravan Details',
      eventAction: '5 - Caravan Year',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'manufacturingYear',
    '*',
    {
      event: 'PQBikeDetails-Year',
      eventCategory: 'Bike Details',
      eventAction: '5 - Bike Year',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Commercial Equipment',
    'manufacturingYear',
    '*',
    {
      event: 'PQCommercialEquipmentDetails-Year',
      eventCategory: 'CommercialEquipment Details',
      eventAction: '5 - Commercial Equipment Year',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'vehicleModelValue',
    '*',
    {
      event: 'PQBikeDetails-BikeModel',
      eventCategory: 'Bike Details',
      eventAction: '8 - Bike Model',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'skipVehicleMakeModel',
    '*',
    {
      event: 'PQBikeDetails-BikeModelSkip',
      eventCategory: 'Bike Details',
      eventAction: '8.1 - Bike Model Skip',
      eventLabel: 'Skip',
    },
  ],
  [
    'Boat',
    'vehicleModelValue',
    '*',
    {
      event: 'PQBoatDetails-BoatModel',
      eventCategory: 'Boat Details',
      eventAction: '8 - Boat Model',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Boat',
    'skipVehicleMakeModel',
    '*',
    {
      event: 'PQBoatDetails-BoatModelSkip',
      eventCategory: 'Boat Details',
      eventAction: '8.1 - Boat Model Skip',
      eventLabel: 'Skip',
    },
  ],
  [
    'Caravan',
    'vehicleModelValue',
    '*',
    {
      event: 'PQCaravanDetails-CaravanModel',
      eventCategory: 'Caravan Details',
      eventAction: '8 - Caravan Model',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Caravan',
    'skipVehicleMakeModel',
    '*',
    {
      event: 'PQCaravanDetails-CaravanModelSkip',
      eventCategory: 'Caravan Details',
      eventAction: '8.1 - Caravan Model Skip',
      eventLabel: 'Skip',
    },
  ],
  [
    'Commercial Equipment',
    'vehicleModelValue',
    '*',
    {
      event: 'PQCommercialEquipmentDetails-CommercialEquipmentModel',
      eventCategory: 'Commercial Equipment Details',
      eventAction: '8 - Commercial Equipment Model',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Commercial Equipment',
    'skipmakeModel',
    '*',
    {
      event: 'PQCommercialEquipmentDetails-CommercialEquipmentModelSkip',
      eventCategory: 'Commercial Equipment Details',
      eventAction: '8.1 - Commercial Equipment Model Skip',
      eventLabel: 'Skip',
    },
  ],
  [
    'Boat',
    'vehicleMakeValue',
    '*',
    {
      event: 'PQBoatDetails-BoatMake',
      eventCategory: 'Boat Details',
      eventAction: '7 - Boat Make',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Caravan',
    'vehicleMakeValue',
    '*',
    {
      event: 'PQCaravanDetails-CaravanMake',
      eventCategory: 'Caravan Details',
      eventAction: '7 - Caravan Make',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'vehicleMakeValue',
    '*',
    {
      event: 'PQBikeDetails-BikeMake',
      eventCategory: 'Bike Details',
      eventAction: '7 - Bike Make',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Commercial Equipment',
    'vehicleMakeValue',
    '*',
    {
      event: 'PQCommercialEquipmentDetails-CommercialFinanceMake',
      eventCategory: 'CommercialEquipment Details',
      eventAction: '7 - Commercial Equipment Make',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Car',
    'skippedVehicleDetails',
    '*',
    {
      event: 'PQCarDetails-CarMakeSkip',
      eventCategory: 'Car Details',
      eventAction: '7.1 - Car Make Skip',
      eventLabel: 'Skip',
    },
  ],
  [
    'Car',
    'skipModel',
    '*',
    {
      event: 'PQCarDetails-CarModelSkip',
      eventCategory: 'Car Details',
      eventAction: '8.1 - Car Model Skip',
      eventLabel: 'Skip',
    },
  ],
  [
    'Boat',
    'purchaseSource',
    '*',
    {
      event: 'PQBoatDetails-PurchaseFrom',
      eventCategory: 'Boat Details',
      eventAction: '12 - Boat Purchase From',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Boat',
    'purchaseUse',
    '*',
    {
      event: 'PQBoatDetails-BoatUsage',
      eventCategory: 'Boat Details',
      eventAction: '13 - Boat Usage',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Caravan',
    'purchaseSource',
    '*',
    {
      event: 'PQCaravanDetails-PurchaseFrom',
      eventCategory: 'Caravan Details',
      eventAction: '12 - Caravan Purchase From',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Caravan',
    'purchaseUse',
    '*',
    {
      event: 'PQCaravanDetails-CaravanUsage',
      eventCategory: 'Caravan Details',
      eventAction: '13 - Caravan Usage',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'purchaseSource',
    '*',
    {
      event: 'PQBikeDetails-PurchaseFrom',
      eventCategory: 'Bike Details',
      eventAction: '12 - Bike Purchase From',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'purchaseUse',
    '*',
    {
      event: 'PQBikeDetails-BikeUsage',
      eventCategory: 'Bike Details',
      eventAction: '13 - Bike Usage',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Commercial Equipment',
    'purchaseSource',
    '*',
    {
      event: 'PQCommercialEquipmentDetails-PurchaseFrom',
      eventCategory: 'Commercial Equipment Details',
      eventAction: '12 - Commercial Equipment Purchase From',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Boat',
    'purchasePriceValue',
    '*',
    {
      event: 'PQBoatDetails-PurchasePrice',
      eventCategory: 'Boat Details',
      eventAction: '14 - Purchase Price',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Boat',
    'depositAmountValue',
    '*',
    {
      event: 'PQBoatDetails-DepositAmount',
      eventCategory: 'Boat Details',
      eventAction: '15 - Deposit Amount',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Boat',
    'termValue',
    '*',
    {
      event: 'PQBoatDetails-Term',
      eventCategory: 'Boat Details',
      eventAction: '16 - Term',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Caravan',
    'purchasePriceValue',
    '*',
    {
      event: 'PQCaravanDetails-PurchasePrice',
      eventCategory: 'Caravan Details',
      eventAction: '14 - Purchase Price',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Caravan',
    'depositAmountValue',
    '*',
    {
      event: 'PQCaravanDetails-DepositAmount',
      eventCategory: 'Caravan Details',
      eventAction: '15 - Deposit Amount',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Caravan',
    'termValue',
    '*',
    {
      event: 'PQCaravanDetails-Term',
      eventCategory: 'Caravan Details',
      eventAction: '16 - Term',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'purchasePriceValue',
    '*',
    {
      event: 'PQBikeDetails-PurchasePrice',
      eventCategory: 'Bike Details',
      eventAction: '14 - Purchase Price',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'depositAmountValue',
    '*',
    {
      event: 'PQBikeDetails-DepositAmount',
      eventCategory: 'Bike Details',
      eventAction: '15 - Deposit Amount',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Bike',
    'termValue',
    '*',
    {
      event: 'PQBikeDetails-Term',
      eventCategory: 'Bike Details',
      eventAction: '16 - Term',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Commercial Equipment',
    'purchasePriceValue',
    '*',
    {
      event: 'PQCommercialEquipmentDetails-PurchasePrice',
      eventCategory: 'Commercial Equipment Details',
      eventAction: '14 - Purchase Price',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Commercial Equipment',
    'depositAmountValue',
    '*',
    {
      event: 'PQCommercialEquipmentDetails-DepositAmount',
      eventCategory: 'Commercial Equipment Details',
      eventAction: '15 - Deposit Amount',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Commercial Equipment',
    'termValue',
    '*',
    {
      event: 'PQCommercialEquipmentDetails-Term',
      eventCategory: 'Commercial Equipment Details',
      eventAction: '16 - Term',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Unsecured Loan',
    'purchasePriceValue',
    '*',
    {
      event: 'PQUnsecuredLoanDetails-PurchasePrice',
      eventCategory: 'Unsecured Loan Details',
      eventAction: '14 - Purchase Price',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Unsecured Loan',
    'termValue',
    '*',
    {
      event: 'PQUnsecuredLoanDetails-Term',
      eventCategory: 'Unsecured Loan Details',
      eventAction: '16 - Term',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'PQ-AssetType',
    'PQStart-AssetType',
    '*',
    {
      event: 'PQStart-AssetType',
      eventCategory: 'Start Page',
      eventAction: '3 - Asset Type',
      eventLabel: '~selected~value~',
    },
  ],
  [
    'Boat',
    'seeContact',
    'seeContactPage',
    {
      event: 'PQBoatDetails-Next',
      eventCategory: 'Boat Details',
      eventAction: '17 - Next',
      eventLabel: 'Clicked',
    },
  ],
  [
    'Caravan',
    'seeContact',
    'seeContactPage',
    {
      event: 'PQCaravanDetails-Next',
      eventCategory: 'Caravan Details',
      eventAction: '17 - Next',
      eventLabel: 'Clicked',
    },
  ],
  [
    'Bike',
    'seeContact',
    'seeContactPage',
    {
      event: 'PQBikeDetails-Next',
      eventCategory: 'Bike Details',
      eventAction: '17 - Next',
      eventLabel: 'Clicked',
    },
  ],
  [
    'Commercial Equipment',
    'seeContact',
    'seeContactPage',
    {
      event: 'PQCommercial EquipmentDetails-Next',
      eventCategory: 'Commercial Equipment Details',
      eventAction: '17 - Next',
      eventLabel: 'Clicked',
    },
  ],
  [
    'Unsecured Loan',
    'seeContact',
    'seeContactPage',
    {
      event: 'PQUnsecured LoanDetails-Next',
      eventCategory: 'Unsecured Loan Details',
      eventAction: '17 - Next',
      eventLabel: 'Clicked',
    },
  ],
  [
    'PQStart',
    'start',
    'contact-us',
    {
      event: 'PQStart-ContactUs',
      eventCategory: 'Start Page',
      eventAction: '1 -Contact Us',
      eventLabel: 'Clicked',
    },
  ],
];

export default gtmMappings;
