import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HelpIcon from '@material-ui/icons/Help';
import ReactTooltip from "react-tooltip";

const CustomCard = ({ className, title, items }) => {
  return (
    <Card className={className}>
      <Typography className="cardBody">{title}</Typography>
      <List className="complete">
        {items.map((item, index) => (
          <ListItem key={index}>
            <span className="tick">{item.title}
              {item?.tooltipText != '' && (<HelpIcon
                data-tip data-for={`registerTip_${index}`}
                style={{
                  height: '18px',
                  width: '18px',
                  marginLeft: '5px',
                  color: '#4F4F4F',
                }}
              />)}</span>
            {item?.tooltipText != '' && (
              <ReactTooltip id={`registerTip_${index}`} backgroundColor={'gray'} place='right' type='dark' delayHide={1000} multiline={true} clickable={true} effect='solid'>
                <span>{item?.tooltipText}</span> {' '}
                <a className='hyper-link' href={item?.link} target="new">{item?.linkText}</a>
              </ReactTooltip>
            )}
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

CustomCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CustomCard;
