import { LIABILITY_CREDIT_CARD_OPTION, LIABILITY_OVERDRAFT_OPTION, NEW_PARTNER_IMAGE_LIST } from './constants';
export function getFileFromBase64(string64, fileName, extension) {
  const imageContent = window.atob(string64);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  const type = extension === 'jpeg' ? 'image/jpeg' : 'application/pdf';
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}

export function capFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function removeByAttribute(arr, attr, value) {
  var i = arr.length;
  while (i--) {
    if (arr[i] && arr[i].hasOwnProperty(attr) && arguments.length > 2 && arr[i][attr] === value) {
      arr.splice(i, 1);
    }
  }
  return arr;
}

export const findPartnerandDescription = (partnername) => {
  const foundPartner = NEW_PARTNER_IMAGE_LIST.find(
    (e) => e.id.toLowerCase() === partnername?.toLowerCase(),
  );
  if (foundPartner) {
    return foundPartner;
  }
  return null;
};
export const resolvebase64 = (fileReader) => {
  return new Promise((resolve) => {
    // Onload of file read the file content
    fileReader.onload = function (fileLoadedEvent) {
      resolve(fileLoadedEvent.target.result); // your base64 string
    };
  });
};

export const formattedNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const isIncomeValidation = (income, updateIncome) => {
  let errorIncome = false
  income.forEach((item) => {
    if (item.category === "") {
      errorIncome = true
      updateIncome({ ...item, errorMsgCatg: 'Required' })
      return errorIncome
    }
    else if (!item.amount) {
      errorIncome = true
      updateIncome({ ...item, errorMsgAmt: 'Required' })
      return errorIncome
    } else if (Number.isNaN(item.amount)) {
      errorIncome = true
      updateIncome({ ...item, errorMsgAmt: 'Must be a number' })
      return errorIncome
    } else if (Number(item.amount) < 1) {
      errorIncome = true
      updateIncome({ ...item, errorMsgAmt: 'Amount should be greater than one' })
      return errorIncome;
    }
  });

  return errorIncome;
}

export const isAssetsValidation = (assets, updateAssets) => {
  let errorAssets = false
  assets.forEach((item) => {
    if (item.assetType === "") {
      errorAssets = true
      updateAssets({ ...item, errorMsgAssets: 'Required' })
      return errorAssets
    }
    else if (!item.estimateVal) {
      errorAssets = true
      updateAssets({ ...item, errorMsgEstmate: 'Required' })
      return errorAssets
    } else if (Number.isNaN(item.estimateVal)) {
      errorAssets = true
      updateAssets({ ...item, errorMsgEstmate: 'Must be a number' })
      return errorAssets
    } else if (Number(item.estimateVal) < 1) {
      errorAssets = true
      updateAssets({ ...item, errorMsgEstmate: 'Amount should be greater than one' })
      return errorAssets;
    }
    else if (item.relatable && item.relatedLiability === "") {
      errorAssets = true
      updateAssets({ ...item, errorMsgRelatedLiability: 'Required' })
      return errorAssets
    }
  });

  return errorAssets;
}

export const isLiabilityValidation = (liabilities, updateLiability) => {
  let errorLiability = false
  liabilities.forEach((item) => {
    if (item.liabilityType === "") {
      errorLiability = true
      updateLiability({ ...item, errorMsgliability: 'Required' })
      return errorLiability
    }
    else if (item.relatable && item.relatedAsset === "") {
      errorLiability = true
      updateLiability({ ...item, errorMsgRelatedAssets: 'Required' })
      return errorLiability
    }
    else if (item.financier === "") {
      errorLiability = true
      updateLiability({ ...item, errorMsgfinancier: 'Required' })
      return errorLiability
    }
    else if ((item.liabilityType === LIABILITY_CREDIT_CARD_OPTION || item.liabilityType === LIABILITY_OVERDRAFT_OPTION) && item.limit === "") {
      errorLiability = true
      updateLiability({ ...item, errorMsgLimit: 'Required' })
      return errorLiability
    } else if ((item.liabilityType === LIABILITY_CREDIT_CARD_OPTION || item.liabilityType === LIABILITY_OVERDRAFT_OPTION) && Number.isNaN(item.limit)) {
      errorLiability = true
      updateLiability({ ...item, errorMsgLimit: 'Must be a number' })
      return errorLiability
    } else if ((item.liabilityType === LIABILITY_CREDIT_CARD_OPTION || item.liabilityType === LIABILITY_OVERDRAFT_OPTION) && Number(item.limit) < 1) {
      errorLiability = true
      updateLiability({ ...item, errorMsgLimit: 'Amount should be greater than one' })
      return errorLiability;
    }
    else if (item.outstanding === "") {
      errorLiability = true
      updateLiability({ ...item, errorMsgOutstanding: 'Required' })
      return errorLiability
    } else if (Number.isNaN(item.outstanding)) {
      errorLiability = true
      updateLiability({ ...item, errorMsgOutstanding: 'Must be a number' })
      return errorLiability
    } else if (Number(item.outstanding) < 1) {
      errorLiability = true
      updateLiability({ ...item, errorMsgOutstanding: 'Amount should be greater than one' })
      return errorLiability;
    }
    else if (item.repayments === "") {
      errorLiability = true
      updateLiability({ ...item, errorMsgRepayment: 'Required' })
      return errorLiability
    } else if (Number.isNaN(item.repayments)) {
      errorLiability = true
      updateLiability({ ...item, errorMsgRepayment: 'Must be a number' })
      return errorLiability
    } else if (Number(item.repayments) < 1) {
      errorLiability = true
      updateLiability({ ...item, errorMsgRepayment: 'Amount should be greater than one' })
      return errorLiability;
    }
  });
  return errorLiability;
}
