import { connect } from 'react-redux';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import DocumentsVerification from './DocumentsVerification';
import { setCurrentTab } from '../../Actions/tab';
import { liteFieldChanged } from '../../Actions/FieldChanged';

const mapStateToProps = (state) => ({
  incomelistVal: state.applicants.income,
  validationErrors: state.applicants.validationErrors,
  doctype: state.applicants.doctype,
  incomedocType: state.applicants.incomedocType,
  getValidationErrorsForField: getValidationErrorsForFieldFunc(state.applicants.validationErrors),
});

const mapDispatchToProps = (dispatch) => ({
  setTab: () => dispatch(setCurrentTab('verification')),
  onInputChange: (fieldName, value, scrollToNextFieldOnChage) =>
    dispatch(liteFieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage)),
});

const DocVerificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentsVerification);

export default DocVerificationContainer;
