/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import cls from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import getErrorMessageRows from './FormControlFeedback';

const styles = {
  adornedCustomEnd: {
    paddingTop: '15px',
    paddingBottom: '14px',
  },
};
class DatePickerWrapper extends Component {
  constructor(props) {
    super(props);
    // state value is a string
    this.state = {
      value: props.value || '',
      isInvalid: false,
    };

    this.onChangeDate = this.onChangeDate.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
  }
  onChangeDate(dateValue) {
    this.setState(
      {
        value: dateValue || '',
        isInvalid: false,
      },
      () => {
        if (!this.state.value) {
          this.handleErrors('Required');
        }
      },
    );

    this.props.onChange(
      this.props.fieldName,
      moment(dateValue).isValid() ? moment(dateValue).format('YYYY-MM-DD') : '',
      this.props.scrollToNextFieldOnChage,
    );

    const currentYearDiff = Math.floor(
      moment(new Date()).diff(moment(dateValue, 'MM/DD/YYYY'), 'years', true),
    );
  }

  handleErrors(error, value) {
    if (this.props.postSelect) {
      this.props.validateDateField(this.props.fieldName, error ? [error] : null);
    }
    if (error) {
      this.props.validateDateField(this.props.fieldName, [error]);
    }
  }

  render() {
    const errorMessageRows = getErrorMessageRows(
      this.props.getValidationErrors,
      this.props.fieldName,
    );
    const isInvalid = (errorMessageRows && errorMessageRows.length) || this.state.isInvalid;
    const columnCount = this.props.columnCount ? this.props.columnCount : 6;

    const nativePicker = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div
          name={this.props.fieldName}
          data-test={this.props.fieldName}
          className={cls(this.props.className, `form-group col-md-${columnCount} col-12`)}
        >
          <Form.Group md={columnCount}>
            <Form.Label htmlFor="date-picker-inline">
              {this.props.label} <span style={{ color: 'red' }}>{this.props.required}</span>
            </Form.Label>
            <KeyboardDatePicker
              className="custom-date-picker"
              variant="inline"
              helperText={errorMessageRows || ''}
              views={this.props.showYearMonth}
              inputVariant="outlined"
              format={this.props.dateFormat || 'dd/MM/yyyy'}
              margin="normal"
              id="date-picker-inline"
              error={isInvalid}
              value={this.state.value || null}
              onError={this.handleErrors}
              placeholder={this.props.placeholderVal || 'DD/MM/YYYY'}
              fullWidth
              minDate={this.props.minDtVal || '01/01/1900'}
              minDateMessage={this.props.minDateMessage}
              maxDate={this.props.maxDtVal}
              maxDateMessage={this.props.maxDateMessage}
              autoOk
              disabled={this.props.isdisabled}
              // InputAdornmentProps={{
              //   classes: { endAdornment: this.props.classes.adornedCustomEnd },
              // }}
              onChange={this.onChangeDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              allowKeyboardControl
              PopoverProps={{ classes: { root: 'date-picker-popover' } }}
            />
            {/* {errorMessageRows} */}
          </Form.Group>
        </div>
      </MuiPickersUtilsProvider>
    );

    let wrapperElement = nativePicker;

    if (!this.props.inline) {
      wrapperElement = <div className="row input-container">{nativePicker}</div>;
    }

    return wrapperElement;
  }
}

DatePickerWrapper.propTypes = {
  onBlur: PropTypes.func,
  value: PropTypes.string,
  questionText: PropTypes.string,
  fieldName: PropTypes.string,
  validator: PropTypes.object,
  id: PropTypes.string,
  placeHolder: PropTypes.string,
  updateValue: PropTypes.bool,
};

export default withStyles(styles)(DatePickerWrapper);
