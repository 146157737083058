/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import AssetDetailsNew from './Assets/AssetsNew';
import LiabilityDetailsNew from './Liabilities/LiabilitiesNew';
import PolicyInfo from './FinanceComplete';
import ExpenseDetailsNew from './Expenses/ExpensesNew';
import IncomeDetailsNew from './Income/NewIncome';

const FinanceDetailsContainer = ({
  initAssetList,
  initLiabList,
  initExpList,
  expenseCompleted
}) => (
  <div className="container-section">
    <div className="sub-container-section tab-container" id="incomeDetails">
      <IncomeDetailsNew />
      {initAssetList && (
        <div className="question-section">
          <AssetDetailsNew />
        </div>
      )}
      {initLiabList && (
        <div className="question-section">
          <LiabilityDetailsNew />
        </div>
      )}
      {initExpList && (
        <div className="question-section">
          <ExpenseDetailsNew />
        </div>
      )}
      {expenseCompleted && (
        <div className="question-section">
          <PolicyInfo />
        </div>
      )}
    </div>
  </div>
);

export default connect((state) => ({
  initAssetList: state.applicants.initAssetList,
  initLiabList: state.applicants.initLiabList,
  initExpList: state.applicants.initExpList,
  expenseCompleted: state.applicants.expenseCompleted,
}))(FinanceDetailsContainer);
