import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { ROUTES} from '../../util/constants';
import { useHistory } from 'react-router';
import { validateEnquiryDetails } from '../../Actions/car';
import './leadCreationEmailCaptcha.scss'

const LeadCreationEmailCaptcha = ({ contactId, validateEnquiryDetails }) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }
        toggleCaptchaBadge(true);

        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${window.env.SITE_KEY}`, function () {
            setLoading(true);
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(window.env.SITE_KEY, { action: 'submit' }).then(token => {
                    if (contactId !== '') {
                        validateRecaptcha(token)
                    }
                    else {
                        setLoading(false);
                        history.push(ROUTES.estimation)
                    }
                });
            });
        });


        return () => toggleCaptchaBadge(false);
    }, []);

    const toggleCaptchaBadge = (show) => {
        const badge = document.getElementsByClassName('grecaptcha-badge')[0];
        if (badge && badge instanceof HTMLElement) {
            badge.style.visibility = show ? 'visible' : 'hidden';
        }
    };

    const validateRecaptcha = async (token) => {
        validateEnquiryDetails(token).then((res) => {
            const { success, isContactExist } = res?.data;
            if (success && isContactExist) {
                window.open("https://www.strattonfinance.com.au/edm/thank-you", "_self")
            }
            else {
                history.push(ROUTES.estimation)
            }
            setLoading(false);
        })
            .catch((err) => {
                setLoading(false);
                history.push(ROUTES.estimation)
            })
    }

    return (
        <>
            {loading && <Card className="request-email-details">
                <div className="request-email-message">Verifying data</div>
                <div className="root">
                    <CircularProgress disableShrink size={45} thickness={4.6} />
                </div>
            </Card>}

        </>
    );
}
export default connect(
    (state) => ({
        contactId: state.car.contactId,
    }),
    (dispatch) => ({
        validateEnquiryDetails: (token) => dispatch(validateEnquiryDetails(token)),
    }),
)(LeadCreationEmailCaptcha);
