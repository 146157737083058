const MergeValidationErrorsImmutable = (stateValidationErrors, newValidationErrors, fieldName) => {
  if (stateValidationErrors.length < 1 && !newValidationErrors) {
    return [];
  }

  if (stateValidationErrors.length < 1) {
    const errors = [
      {
        fieldName,
        messages: newValidationErrors,
      },
    ];

    return errors;
  }

  const clonedErrors = Object.assign([], stateValidationErrors);
  const indexOfCurrentField = clonedErrors.map((element) => element.fieldName).indexOf(fieldName);

  if (indexOfCurrentField > -1) {
    clonedErrors.splice(indexOfCurrentField, 1);
  }

  if (!newValidationErrors) {
    return clonedErrors;
  }

  clonedErrors.push({
    fieldName,
    messages: newValidationErrors,
  });

  return clonedErrors;
};

export default MergeValidationErrorsImmutable;
