import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import { Auth } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import { Button, Form, InputGroup } from 'react-bootstrap';
import fieldChanged from '../../Actions/FieldChanged';
import Copyright from '../../components/Copyright';
import logo from '../../../assets/image/stratton-logo-transparent.png';
import rightArrow from '../../../assets/image/Arrow-Right.svg';
import { ROUTES } from '../../util/constants';
import Email from '../../../assets/image/Email.svg';
import Error from '../../../assets/image/error.svg';
import Check from '../../../assets/image/success.svg';
import { setAuthenticated, setApplicantId } from '../../Actions/authHandler';
import './login-details.scss';
import personalDetailsBackground from '../../../assets/image/Context_pd.svg';
import { updateCoginito } from '../../Actions/car';
import { convertToLowercase } from '../../lib/utils';
import ModalWindow from '../../components/ModalWindow';
import { regexEmail } from '../../RuleEvaluators/EmailRuleEvaluator';

const Login = ({ onInputChange, updateCoginito, setApplicantId, signUpProcessing, code }) => {
  const history = useHistory();
  const onLogoClick = () => {
    window.open('https://www.strattonfinance.com.au/');
  };
  const [username, setUsername] = useState('');
  const [codeSent, setCodesent] = useState(false);
  const [mobileotp, setmobileOtp] = useState('');
  const [cognitoUser, setCognitoUser] = useState('');
  const [show, setShow] = useState(false);
  const [OTPInvalid, isOTPInvalid] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const [resendMessage, setResendMessage] = useState(false);
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const initialSeconds = 0;
  const [seconds, setSeconds] = useState(initialSeconds);
  const convertedUsername = convertToLowercase(username).trim();
  const [showError, setShowError] = useState(false);
  const submitOTP = async (otp) => {
    if (otp && otp.length === 6) {
      try {
        onInputChange('error', null, false);
        onInputChange('signUpProcessing', true, false);
        await Auth.sendCustomChallengeAnswer(cognitoUser, otp)
          .then(async (data) => {
            onInputChange('error', null, false);
            try {
              // This will throw an error if the user is not yet authenticated:
              setCodesent(true);
              await Auth.currentSession();
            } catch {
              setmobileOtp('');
              setCodesent(true);
              isOTPInvalid(true);
              setShow(true);
              return false;
            }
            await Auth.currentCredentials({
              bypassCache: true,
            }).then((user) => {
              updateCoginito(user.identityId)
                .then(() => {
                  history.push(ROUTES.dashboard);
                  onInputChange('signUpProcessing', false, false);
                  setCodesent(true);
                })
                .catch((err) => {
                  onInputChange('error', err.message || err, false);
                  console.error(err);
                  setCodesent(true);
                  history.push(ROUTES.dashboard);
                  onInputChange('signUpProcessing', false, false);
                });
            });
          })
          .catch((err) => {
            onInputChange('error', err.message || err, false);
            isOTPInvalid(true);
            setCodesent(true);
            onInputChange('signUpProcessing', false, false);
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err.message);
      }
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    onInputChange('signUpProcessing', true, false);
    Auth.signIn(convertedUsername)
      .then((user) => {
        setCognitoUser(user);
        setCodesent(true);
        setmobileOtp('');
        onInputChange('signUpProcessing', false, false);
      })
      .catch((err) => {
        onInputChange('signUpProcessing', false, false);
        setInvalidUser(true);
        setShow(true);
      });
  }
  const resendConfirmationCode = async () => {
    Auth.signIn(cognitoUser.username)
      .then((user) => {
        setCognitoUser(user);
        setmobileOtp('');
        setCodesent(true);
        setResendMessage(true);
        setShow(true);
        setisButtonDisabled(true);
        setSeconds(30);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const handleOtpChange = async (newVal) => {
    setmobileOtp(newVal);
    if (newVal.length === 6) {
      await submitOTP(newVal);
    }
  };
  const getQuoteClick = () => {
    history.push(ROUTES.initial);
  };

  const handleClose = () => {
    isOTPInvalid(false);
    setInvalidUser(false);
    setResendMessage(false);
    setShow(false);
    onInputChange('signUpProcessing', false, false);
  };

  useEffect(() => {
    if (code) {
      setApplicantId(code);
    } else {
      history.push(ROUTES.login);
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (isButtonDisabled) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          setisButtonDisabled(false);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  function validateForm() {
    return username && username.length > 0 && regexEmail.test(username);
  }
  const handleBlur = (e) => {
    const email = e.target.value;
    if (email.length > 0 && !regexEmail.test(email.trim())) {
      setShowError(true)
    }
    else {
      setShowError(false)
    }
  }
  return (
    <>
      <div className="login-page-container">
        <div className="login-page-section login_details_section col-12 col-md-4 col-lg-4">
          <div className="stratton-info-nav-container">
            <img
              src={logo}
              alt="Stratton"
              className="pt-3 stratton-info-logo"
              onClick={onLogoClick}
            />
          </div>
          <div className="stratton-info-footer-container">
            <div className="login_details_section">
              <img src={personalDetailsBackground} className="nav-personal" alt="personal" />
            </div>
            <Copyright />
          </div>
        </div>

        <div className="login-page-details col-12 col-md-8 col-lg-8">
          <div className="Need-help py-3">
            Call us on
            <div className="number-font">1300 787 288</div>
          </div>
          <div className="login-page-message">
            <div className="login-page-name">
              Sign in to <span className="logo-highlight">Stratton Finance</span>
            </div>

            {OTPInvalid && (
              <ModalWindow
                message="The Verification code that was entered is either expired or incorrect. Resend/Please try again"
                handleClose={handleClose}
                errorImg={Error}
                showModal={show}
              />
            )}
            {invalidUser && (
              <ModalWindow
                message="There is no account associated with the email you have entered"
                handleClose={handleClose}
                errorImg={Error}
                showModal={show}
              />
            )}

            {resendMessage && (
              <ModalWindow
                message="We have resent 6-digit verification code to your mobile"
                handleClose={handleClose}
                errorImg={Check}
                showModal={show}
              />
            )}

            <Form noValidate onSubmit={handleSubmit} className="login-form-section">
              {codeSent && (
                <Form.Group controlId="code">
                  <div className="code-sent-message mb-3 p-3 ml-0">
                    6 digit verification code is sent to your mobile
                  </div>
                  {signUpProcessing ? (
                    <div className="verification-spinner">
                      <CircularProgress color="inherit" size={48} thickness={4} />
                    </div>
                  ) : (
                    <>
                      <div className="verifyyourself-otp pb-3">
                        <OtpInput
                          onChange={handleOtpChange}
                          inputStyle="otp-input-field"
                          value={mobileotp}
                          shouldAutoFocus
                          hasErrored
                          numInputs="6"
                          errorStyle="error"
                        />
                      </div>
                      <div className="otp-info-container my-2">
                        <div className="verifyyourself-content6">
                          Didn't receive the code?
                          <button
                            type="button"
                            className="btn btn-link btn-resend "
                            onClick={resendConfirmationCode}
                            disabled={isButtonDisabled}
                          >
                            <span className="verifyyourself-content8">Resend OTP</span>
                          </button>
                          &nbsp;
                          <span>
                            {seconds === 0 ? null : (
                              <span>
                                <b>00: {seconds < 10 ? `0${seconds}` : seconds}</b>
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Form.Group>
              )}

              {!codeSent && !signUpProcessing && (
                <>
                  <Form.Group className="login-input-group" controlId="username">
                    <Form.Label className="input-label-font">Email address</Form.Label>
                    <InputGroup className="email-input-group-append">
                      <InputGroup.Prepend className="email-icon-input-append">
                        <img src={Email} alt="email" />
                      </InputGroup.Prepend>
                      <Form.Control
                        type="username"
                        placeholder="Email address"
                        className="email-border"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onBlur={handleBlur}
                      />
                       {showError && (<Form.Control.Feedback type="invalid">
                        Please enter a valid email
                      </Form.Control.Feedback>)}
                    </InputGroup>
                  </Form.Group>
                </>
              )}
              {signUpProcessing && !codeSent ? (
                    <div className="verification-spinner">
                      <CircularProgress color="inherit" size={48} thickness={4} />
                    </div>
                  ) : (
                    !codeSent && <Button
                      block
                      disabled={!validateForm()}
                      type="submit"
                      className="btn-primary-call-to-action"
                    >
                      <span className="button-font">Sign In </span>
                    </Button>
                  )}
            </Form>
            <hr className="login-horizantal-line" />
            <div className="login-page-navigation">
              <div className="looking-for"> Looking for finance?</div>
              <div className="get-started">Get started by getting a quote</div>
              <Button type="button" className="btn-primary-get-quote" onClick={getQuoteClick}>
                <span className="get-quote-message">
                  Get a quote
                  <img src={rightArrow} className="right-icon" alt="right-arrow" />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Copyright mobileFooter />
    </>
  );
};

Login.propTypes = {
  setAuthenticationSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(
  (state) => ({
    signUpProcessing: state.account.signUpProcessing,
    code: state.car.code,
  }),
  (dispatch) => ({
    setAuthenticationSuccess: (userName) => dispatch(setAuthenticated(userName)),
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('account', fieldName, value, scrollToNextFieldOnChage));
    },
    onClearError: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage));
    },
    setApplicantId: (token) => dispatch(setApplicantId(token)),
    updateCoginito: (cognitoId) => dispatch(updateCoginito(cognitoId)),
  }),
)(Login);
