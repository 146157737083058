/* eslint-disable class-methods-use-this */
import BaseReducer from './BaseReducer';

class Loan extends BaseReducer {
  constructor() {
    super('LOAN');
  }

  getInitialState() {
    return {
      initialDefaultValues: [{}],
      validationRules: [],
      activeFields: this.getInitialActiveFields(),
      sharedFields: [],
      validationErrors: [],
    };
  }

  getInitialActiveFields() {
    return [];
  }

  getNewActiveFields() {
    return this.getInitialActiveFields();
  }

  reduce(state = this.getInitialState(), action) {
    if (action.type === 'LOGGED_IN') {
      return {
        ...state,
        selectFreq: action.selectFreq,
      };
    }
    return super.reduce(state, action);
  }
}

export default Loan;
