/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { CircularProgress } from '@material-ui/core';
import cls from 'classnames';
import { withRouter } from 'react-router-dom';
import { liteFieldChanged } from '../../Actions/FieldChanged';

const CreditQuoteAcceptance = ({
  show,
  onInputChange,
  creditQuoteAccepted,
  onSubmit,
  submitInProgress,
  quoteInProgress,
}) => {
  const [showPrivacy, setCreditPrivacy] = useState(false);
  const [scrollDirectionBottom, setScrollDirection] = useState(true);
  const consentElement = useRef(null);

  const handleCheckboxChange = (event) => {
    onInputChange('creditQuoteAccepted', event.target.checked, false);
  };
  const handleScroll = (e) => {
    const element = e.target;
    if (element.scrollHeight - Math.round(element.scrollTop) - 5 <= element.clientHeight) {
      setScrollDirection(false);
      setCreditPrivacy(true);
    } else {
      setScrollDirection(true);
    }
  };
  const scrollByPage = (e) => {
    e.preventDefault();
    const element = consentElement.current;
    element.scrollTo({
      // top: scrollDirectionBottom ? element.scrollTop + element.clientHeight - 5 : 0,
      top: scrollDirectionBottom ? element.scrollHeight - 5 : 0,
      behavior: 'smooth',
    });
  };

  return (
    <Modal show={show} keyboard={false} centered className="dashboard-privacy-container">
      <Modal.Header closeButton={false} className="finances-header-container">
        <Modal.Title className="privacy-header-section">
          <div>Quote for providing Credit Assistance</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={cls('privacy-body-section', quoteInProgress && 'in-progress')}>
        {quoteInProgress ? (
          <CircularProgress color="inherit" size={48} thickness={4} />
        ) : (
          <>
            <fieldset className="fieldset-section" onScroll={handleScroll}>
              <div className="privacy-consent credit-quote-consent" ref={consentElement}>
                <div className="privacy-collection-header">
                  Credit Quote Acceptance Notice and Consent Form
                </div>
                <p className="privacy-content">
                  The maximum value of the fee may vary, as detailed below. Each of these fees are 
                  maximum possible fees, and the exact amount you need to pay will be fully disclosed 
                  on any loan contract before you sign and accept the loan offer.
                </p>
                <p className="privacy-content">
                  As we offer a range of lenders, the maximum fee we may charge you may be dependent 
                  on the specific lender that provides your finance and may also be calculated differently 
                  between each.
                </p>
                <p className="privacy-content">
                  Across our lending panel, the maximum fee we may charge for our services is up to 
                  $2,500 (including GST).
                </p>
                <p className="privacy-content privacy-nextsection">
                  This fee is the maximum possible fees, an the exact amount you need to pay will be fully 
                  disclosed on any loan contract before you sign and accept the loan offer.
                </p>
                <p className="privacy-content">
                  In all cases when comparing loan options, it is important to look at the repayment 
                  amount in each option or the total amount payable at the end of the loan term in order 
                  to compare products appropriately. 
                </p>
                <p className="privacy-content">
                  You do not have to pay us any other fees and charges for assisting you source finance. 
                  However, you may be required to pay fees to the financier. This quote is being provided 
                  by Stratton Finance Pty Ltd (ABN: 63 070 636 903), Australian Credit Licence (ACL) 
                  Number: 364340
                </p>
                <p className="privacy-content privacy-nextsection mb-0">
                  I have read, understood and acknowledge that when the finance is settled, I may be 
                  charged a fee by Stratton Finance. Any fee will be included in the total loan amount and 
                  has already been accounted for in the estimated repayment figure.
                </p>
              </div>
              <button
                className={cls(
                  'scroll-to-up-bottom',
                  scrollDirectionBottom ? 'to-bottom' : 'to-up',
                )}
                type="button"
                onClick={scrollByPage}
              />
            </fieldset>
            {showPrivacy && (
              <div className="privacy-policy">
                <label
                  className="checkbox-container credit-quote-checkbox-color"
                  htmlFor="acceptPrivacyPolicy"
                >
                  I have read, understood and acknowledge that when the finance is settled, I may be
                  charged a fee by Stratton Finance. Any fee will be included in the total loan
                  amount and has already been accounted for in the estimated repayment figure.
                  <input
                    type="checkbox"
                    checked={creditQuoteAccepted}
                    value={creditQuoteAccepted}
                    onChange={handleCheckboxChange}
                    name="acceptPrivacyPolicy"
                    id="acceptPrivacyPolicy"
                  />
                  <span className="checkmark" />
                </label>
              </div>
            )}
            <div className="email-quote py-3">
              <button
                type="button"
                data-test="lets-get-started-btn "
                className="letsgetstarted-btn submit-privacy"
                onClick={onSubmit}
                disabled={!creditQuoteAccepted || submitInProgress}
              >
                {submitInProgress ? (
                  <CircularProgress color="inherit" size={24} thickness={2} />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

CreditQuoteAcceptance.propTypes = {
  show: PropTypes.bool.isRequired,
  submitInProgress: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  quoteInProgress: PropTypes.bool,
};

// export default CreditQuoteAcceptance;
export default connect(
  (state) => ({
    quoteInProgress: state.estimation.quoteInProgress,
    creditQuoteAccepted: state.applicants.creditQuoteAccepted,
  }),
  (dispatch) => ({
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) =>
      dispatch(liteFieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage)),
  }),
)(withRouter(CreditQuoteAcceptance));
