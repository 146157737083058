/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import moment from 'moment';
import { useHistory, withRouter } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import DatePickerWrapper from '../../components/DatePickerWrapper';
import InputButton from '../../components/InputButton';
import {
  CITIZENSHIP_STATUS,
  WORKING_VISA,
  OTHER_VISA,
  DEPENDENTS,
  COAPP_RELATIONSHIP,
  ROUTES,
  APPLICANT_TYPE,
} from '../../util/constants';
import rightnormal from '../../../assets/image/right-normal.svg';
import '../ContactDetails/contact-details.scss';
import { liteFieldChanged, updateValidationErrors } from '../../Actions/FieldChanged';
import { setCompleteTab, setCurrentTab } from '../../Actions/tab';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import { createSpouseCoApplicants } from '../../Actions/car';
import leftnormal from '../../../assets/image/left-normal.svg';
import Progress from '../../components/Progress';
import processGTMevent from '../../util/processGTMEvent';
export const formattedCurrentDate = moment().format('YYYY-MM-DD');
const MIN_DATE_ERROR_MESSAGE =
  'Visa expiry date should be later than today and should not be before today';

const CoApplicantDetails = ({
  getValidationErrorsForField,
  onLiteInputChange,
  opportunityId,
  error,
  coAppCreditHistory,
  coAppCitizenshipstatus,
  coAppRelationship,
  coAppDependents,
  coAppvisaExpiryDt,
  validationErrors,
  setTabComplete,
  validateDateField,
  fieldSuffix,
  saveCoApplicantData,
  applicantType
}) => {
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(768));
  const [coapplicantLicenceInProgress, setcoapplicantLicenceInProgress] = useState(false);

  useEffect(() => {
    processGTMevent('living_details', 'PQAdditionalQuestions-CoAppDetails-PageLoad', 'Page Load')
    if (validationErrors?.length) {
      onLiteInputChange('validationErrors', [], false);
    }
  }, []);

  const saveCoApplicantDetails = () => {
    setcoapplicantLicenceInProgress(true);

    processGTMevent('living_details', 'PQAdditionalQuestions-CoAppDetails-Next', 'Click')

    saveCoApplicantData('coApplicant')
      .then((resp) => {
        localStorage.setItem(`pageCompleted${opportunityId}`, 'coapp-personal-details');
        setcoapplicantLicenceInProgress(false);
        setTabComplete('coApplicant_personalDetails');
        onLiteInputChange('coAppidentityDetails', true, false);
        history.push(`${ROUTES.coApplicantIdentityDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
      })
      .catch((err) => {
        setcoapplicantLicenceInProgress(false);
        console.log('failed to save recrd', err);
      });
  };
  const nextDisabled =
    (validationErrors && validationErrors.length) ||
    !coAppCreditHistory ||
    !coAppRelationship ||
    !coAppCitizenshipstatus ||
    !coAppDependents ||
    ((coAppCitizenshipstatus === WORKING_VISA || coAppCitizenshipstatus === OTHER_VISA) &&
      !coAppvisaExpiryDt);

  const navigateToPrevious = () => {
    history.goBack();
  };

  return (
    <Card className="content-body personal-details-container">
      <Card.Header className="col-12 page-header-container personal-details-header-container scrollable-anchor-tag with-sidebar">
        <h1 className="personal-details-header">Personal details</h1>
        <div className="personal-details-description">
          To support the application we will need to add an additional applicant
        </div>
      </Card.Header>
      <Card.Body className="pb-0 applicant-body-container">
        <div className="row py-2 px-3 applicant-body-section1">
          <div className="col-12 px-2 applicant-body-section2">
            <Form noValidate className="contact-body">
              <div className="title">CO-APPLICANT</div>
              <div className="subtitle">PERSONAL DETAILS</div>
              <div className="form-row">
                <InputButton
                  label={
                    <>
                      <span>Credit Rating?</span>
                    </>
                  }
                  fields={[
                    { name: 'Excellent', value: 'Excellent' },
                    { name: 'Average', value: 'Average' },
                    { name: 'Fair', value: 'Fair' },
                    { name: 'Poor', value: 'Poor' },
                  ]}
                  onChange={onLiteInputChange}
                  fieldName={`creditHistory${fieldSuffix}`}
                  value={coAppCreditHistory}
                  scrollToNextFieldOnChage
                  getvalidationerrors={getValidationErrorsForField}
                />
              </div>

              <InputButton
                label={
                  <>
                    <span>What&apos;s your current Australian residency?</span>
                  </>
                }
                fields={CITIZENSHIP_STATUS}
                onChange={onLiteInputChange}
                fieldName={`citizenshipStatus${fieldSuffix}`}
                value={coAppCitizenshipstatus}
                scrollToNextFieldOnChage
                getvalidationerrors={getValidationErrorsForField}
              />
              {(coAppCitizenshipstatus === WORKING_VISA ||
                coAppCitizenshipstatus === OTHER_VISA) && (
                  <div className="form-row px-0">
                    <DatePickerWrapper
                      value={coAppvisaExpiryDt}
                      onChange={onLiteInputChange}
                      validateDateField={validateDateField}
                      fieldName={`visaExpiryDt${fieldSuffix}`}
                      label="Visa Expiry Date"
                      minDtVal={formattedCurrentDate}
                      minDateMessage={MIN_DATE_ERROR_MESSAGE}
                      dateFormat="dd/MM/yyyy"
                      columnCount="4"
                      getValidationErrors={getValidationErrorsForField}
                      inline
                      className="px-0 visa-expiry-date"
                    />
                  </div>
                )}

              <InputButton
                label={
                  <>
                    <span>What&apos;s your current relationship status?</span>
                  </>
                }
                fields={COAPP_RELATIONSHIP}
                onChange={onLiteInputChange}
                fieldName={`maritalStatus${fieldSuffix}`}
                value={coAppRelationship}
                scrollToNextFieldOnChage
                getvalidationerrors={getValidationErrorsForField}
              />

              <InputButton
                label={
                  <>
                    <span>How many dependants do you have?</span>
                  </>
                }
                fields={DEPENDENTS}
                onChange={onLiteInputChange}
                fieldName={`numberOfDependants${fieldSuffix}`}
                value={coAppDependents}
                scrollToNextFieldOnChage
                getvalidationerrors={getValidationErrorsForField}
              />
            </Form>
          </div>
        </div>
        <div className="btn  footer-actions btn-address-next">
          {mobileView ? (
            <>
              {' '}
              <button
                type="button"
                onClick={navigateToPrevious}
                className="btn btn-primary  btn-next-page"
              >
                <span className="pr-3">
                  <img src={leftnormal} alt="right" className="right-icon ml-0" />
                </span>
                Previous
              </button>
              <button
                type="button"
                onClick={saveCoApplicantDetails}
                disabled={nextDisabled}
                className="btn btn-primary btn-next-page"
              >
                {coapplicantLicenceInProgress ? (
                  <CircularProgress color="inherit" size={24} thickness={2.6} />
                ) : (
                  'Next'
                )}

                <span className="px-3">
                  <img src={rightnormal} alt="right" className="right-icon" />
                </span>
              </button>
            </>
          ) : (
            <>
              {' '}
              <button
                type="button"
                onClick={navigateToPrevious}
                className="btn btn-primary  btn-next-page"
              >
                <span className="pr-3">
                  <img src={leftnormal} alt="right" className="right-icon ml-0" />
                </span>
                Previous
              </button>
              <Progress completed={3} pending={2} />
              <button
                type="button"
                onClick={saveCoApplicantDetails}
                disabled={nextDisabled}
                className="btn btn-primary btn-next-page"
              >
                {coapplicantLicenceInProgress ? (
                  <CircularProgress color="inherit" size={24} thickness={2.6} />
                ) : (
                  'Next'
                )}

                <span className="px-3">
                  <img src={rightnormal} alt="right" className="right-icon" />
                </span>
              </button>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default connect(
  (state) => {
    const fieldSuffix = `_${APPLICANT_TYPE.coApplicant}`;
    return {
      fieldSuffix,
      coAppCreditHistory: state.applicants[`creditHistory${fieldSuffix}`],
      coAppCitizenshipstatus: state.applicants[`citizenshipStatus${fieldSuffix}`],
      coAppRelationship: state.applicants[`maritalStatus${fieldSuffix}`],
      coAppDependents: state.applicants[`numberOfDependants${fieldSuffix}`],
      coAppvisaExpiryDt: state.applicants[`visaExpiryDt${fieldSuffix}`],
      opportunityId: state.applicants.opportunityId,
      validationErrors: state.applicants.validationErrors,
      getValidationErrorsForField: getValidationErrorsForFieldFunc(
        state.applicants.validationErrors,
      ),
    };
  },
  (dispatch) => ({
    onLiteInputChange: (fieldName, value, scrollToNextFieldOnChage) =>
      dispatch(liteFieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage)),
    validateDateField: (fieldName, errors) =>
      dispatch(updateValidationErrors('APPLICANTS', fieldName, errors)),
    saveCoApplicantData: (applicantType) => dispatch(createSpouseCoApplicants(true, applicantType)),
    setTabComplete: (tabName) => {
      dispatch(setCompleteTab(tabName));
    },
  }),
)(withRouter(CoApplicantDetails));
