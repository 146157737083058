/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { useStore } from 'react-redux';
import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Alert from 'react-bootstrap/Alert';
import IncomeTableDetailsNew from './IncomeTableDetailsNew';
import rightnormal from '../../../../../assets/image/right-normal.svg';
import processGTMevent from '../../../../util/processGTMEvent';
import { validateIncomeErrors } from '../../../../util/validateIncomeErrors';
import './incomedetailsNew.scss';
import { isIncomeValidation } from '../../../../util/helpers';
import { EMPTYPE_SELF, HOME_PROPERTY, SAVINGS } from '../../../../util/constants';
import { CircularProgress } from '@material-ui/core';

export const PAY_G = 'PAYG Income';
export const SELF_EMPLOYED = 'Self-Employed Income';
export const RENTAL_INCOME = 'rentalincome';

export const commonPayGIncomeTypes = [
  { name: 'PAYG (After Tax)', value: PAY_G },
  { name: 'Self Employed (NET)', value: SELF_EMPLOYED },
];

export const commonIncomeType = [
  { name: 'Rental Income', value: RENTAL_INCOME },
  { name: 'Government benefits', value: 'government' },
  { name: 'Investments', value: 'Investments' },
  { name: 'Superannuation', value: 'superannuation' },
];

export const DOCTYPE = [
  { name: 'Bank Statement (PDF)', value: 'Bank Statement (PDF)' },
  { name: 'Payslip', value: 'Payslip' },
  { name: 'Tax Return', value: 'Tax Return' },
  { name: 'Notice of Assessment', value: 'Notice of Assessment' },
  { name: 'Rental Statement', value: 'Rental Statement' },
];

export const incomeTypes = [...commonIncomeType, ...commonPayGIncomeTypes];

export const spouseIncomeTypes = [...commonIncomeType, ...commonPayGIncomeTypes];

const tabTypes = Object.freeze({
  primary: 'PRIMARY',
  spouse: 'SPOUSE',
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      textTransform: 'none',
      borderTop: '1px solid #dee2e6',
      paddingLeft: 24,
    },
    appbarRoot: {
      color: '#25282a',
      fontFamily: 'Poppins-SemiBold',
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    tabRoot: {
      '&:active': {
        outline: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    tabWrapper: {
      textTransform: 'none',
      fontFamily: 'Poppins-SemiBold',
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: '16px',
      color: '#25282a',
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',

      '& > button': {
        marginLeft: 8,
        marginBottom: '0 !important',
      },
    },
    tabsIndicator: {
      borderColor: '#00aabb',
      backgroundColor: '#00aabb',
      height: '4px',
    },
    tab: {
      '& .MuiBox-root': {
        padding: '0px',
      },
    },
  }),
);

const IncomeDetailsNew = ({
  income,
  assets,
  addIncome,
  addAsset,
  updateIncome,
  deleteIncome,
  spouseincome,
  addSpouseIncome,
  updateSpouseIncome,
  deleteSpouseIncome,
  setTab,
  residentialStatus,
  pendingIncomeSave,
  initAssetList,
  completeIncomeDetails,
  incomeApi,
  initialiseIncomeDetails,
  ValidationErrorFlag,
  errorMessage,
  noErrors,
  deleteIncomeApi,
  coApplicantdeleteApi,
  reQuote,
  financeValidationerrors,
  employerNameOption1,
  currentEmpId,
  employerNameOption2,
  currentEmpId2,
  spouseCoApplicantId,
  purchaseUse,
  spouseIncomeApi,
  createSpouseApplicants,
  employmentStatus,
  employmentStatus2,
  retrieveOpportunity,
  entityName,
}) => {

  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(tabTypes.primary);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const store = useStore();

  useEffect(() => {
    setTab();
    processGTMevent('finances', 'pageLoad', 'financesPageLoad');
  }, [setTab]);


  let incometotal = 0;
  incometotal = reduce(
    income,
    (calTotal, incomeData) => calTotal + Number(incomeData.amount),
    incometotal,
  );

  let spouseincometotal = 0;
  spouseincometotal = reduce(
    spouseincome,
    (calTotal, incomeData) => calTotal + Number(incomeData.amount),
    incometotal,
  );


  const handleAddIncome = () => {
    const incomeDataObj = {
      amount: "",
      category: "",
      applicantEmployerName: "",
      employmentId: "",
      frequency: "Weekly"
    }
    if (currentTab === tabTypes.primary) {
      if (!isIncomeValidation(income, updateIncome)) {
        addIncome(incomeDataObj);
      }
    } else {
      if (!isIncomeValidation(spouseincome, updateSpouseIncome)) {
        addSpouseIncome(incomeDataObj);
      }
    }
  };

  const employerNameOptions = [];

  if (employerNameOption1 || entityName) {
    employerNameOptions.push({
      name: employmentStatus === EMPTYPE_SELF ? entityName : employerNameOption1,
      value: currentEmpId,
      empStatus: employmentStatus
    });
  }

  if (employerNameOption2) {
    employerNameOptions.push({
      name: employerNameOption2,
      value: currentEmpId2,
      empStatus: employmentStatus2
    });
  }

  useEffect(() => {
    for (let i = 0; i < income.length; i++) {
      const valueExist = employerNameOptions.some(item => item.name === income[i].applicantEmployerName);
      if (!valueExist) {
        const existRecord = income[i];
        if (existRecord?.applicantEmployerName !== "") {
          deleteIncome({ ...existRecord });
          deleteIncomeApi({ ...existRecord })
            .then(() => {
              deleteIncome({ ...existRecord });
            })
            .catch(() => {
              console.log('error');
            });
        }
      }
    }

    for (let i = 0; i < employerNameOptions.length; i++) {
      const valueExist = income.filter((item) => item.applicantEmployerName === employerNameOptions[i].name)
      if (valueExist.length === 0) {
        addIncome({
          amount: "",
          category: (employerNameOptions[i].empStatus === EMPTYPE_SELF ? SELF_EMPLOYED : PAY_G),
          applicantEmployerName: employerNameOptions[i].name,
          employmentId: employerNameOptions[i].value,
          frequency: "Weekly"
        })
      }
    }


    const foundSaving = assets.some(item => item.assetType === SAVINGS);
    if (!foundSaving) {
      addAsset({ assetType: SAVINGS, currentlyFinanced: false, estimateVal: "", relatedLiability: "", underfinance: false });
    }

    const foundHomeProperty = assets.some(item => item.assetType === HOME_PROPERTY);
    if (residentialStatus === 'PropertyOwner' && !foundHomeProperty) {
      addAsset({ assetType: HOME_PROPERTY, currentlyFinanced: false, estimateVal: "", relatedLiability: "", underfinance: false, isNotDelete: true });
    }


  }, [income])

  const handleDeleteIncome = (incomeData) => () => {
    if (currentTab === tabTypes.primary) {
      if (incomeData?.id?.length > 10) {
        deleteIncomeApi({ ...incomeData })
          .then(() => {
            deleteIncome({ ...incomeData });
          })
          .catch(() => {
            console.log('error');
          });
      }
      else {
        deleteIncome({ ...incomeData });
      }

    } else {

      if (incomeData?.id?.length > 10) {
        coApplicantdeleteApi({ ...incomeData })
          .then(() => {
            deleteSpouseIncome({ ...incomeData });
          })
          .catch(() => {
            console.log('error');
          });
      }
      else {
        deleteSpouseIncome({ ...incomeData });
      }
    }
  };

  useEffect(() => {
    if (!spouseincome) {
      initialiseIncomeDetails();
    }
    window.location.hash = '#income-details';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleIncomeNext = () => {
    if (currentTab === tabTypes.primary && income.length > 0) {
      const validateIncomeData = validateIncomeErrors({ store });
      if (validateIncomeData !== null) {
        financeValidationerrors(validateIncomeData);
      }
      else if (!isIncomeValidation(income, updateIncome)) {
        setIsLoading(true)
        financeValidationerrors('');
        noErrors();
        reQuote();
        incomeApi().then(() => {
          retrieveOpportunity().then(() => {
            setIsLoading(false)
            completeIncomeDetails();
          })
            .catch(() => {
              setIsLoading(false)
            })
        })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            setIsLoading(false)
          })

        if (spouseincome.length > 0 && !isIncomeValidation(spouseincome, updateSpouseIncome)) {
          saveSpouseIncome()
        }
      }
      processGTMevent('finances', 'totalincome', { value: incometotal });
    }

    if (currentTab === tabTypes.spouse && spouseincome.length > 0 && income.length > 0) {
      const validateIncomeData = validateIncomeErrors({ store });
      if (!isIncomeValidation(spouseincome, updateSpouseIncome)) {
        if (validateIncomeData !== null) {
          financeValidationerrors(validateIncomeData);
        }
        else {
          setIsLoading(true)
          noErrors();
          reQuote();
          incomeApi().then(() => {
          })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
            })
          saveSpouseIncome()
        }
      }
    }

  };

  const saveSpouseIncome = () => {
    if (createSpouseApplicants && !spouseCoApplicantId) {
      createSpouseApplicants()
        .then(() => {
          spouseIncomeApi()
            .then(() => {
              retrieveOpportunity().then(() => {
                setIsLoading(false)
                completeIncomeDetails();
              })
                .catch(() => {
                  setIsLoading(false)
                })
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
              setIsLoading(false)
            });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          setIsLoading(false)
        });
    } else {
      spouseIncomeApi()
        .then(() => {
          retrieveOpportunity().then(() => {
            setIsLoading(false)
            completeIncomeDetails();
          })
            .catch(() => {
              setIsLoading(false)
            })
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          setIsLoading(false)
        });
    }
  }

  const primaryIncomeTypes = [...incomeTypes];

  return (
    <Card className="content-body finances-details-container finances-details income-details">
      <Card.Header className="col-12 page-header-container finances-details-header-container scrollable-anchor-tag with-sidebar">
        <a name="finance-details" href="#income-details">
          &nbsp;
        </a>
        <h1 className="finances-details-header"> Your income details</h1>
        <div className="finances-details-description">
          We need your income details so we can calculate how much you can borrow
        </div>
        <div className={classes.root}>
          <AppBar position="static" classes={{ root: classes.appbarRoot }}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="simple tabs example"
              classes={{ indicator: classes.tabsIndicator }}
            >
              <Tab
                label="Primary applicant"
                classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }}
                value={tabTypes.primary}
                {...a11yProps(tabTypes.primary)}
              />
              {purchaseUse !== 'Business' && (
                <Tab
                  wrapped
                  label="Spouse details"
                  classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }}
                  value={tabTypes.spouse}
                  {...a11yProps(tabTypes.spouse)}
                />
              )}
            </Tabs>
            {/* )} */}
          </AppBar>
        </div>
      </Card.Header>
      <TabPanel value={currentTab} index={tabTypes.primary} classes={{ root: classes.tab }}>
        {income?.length === 0 && errorMessage && (
          <div id="errormessage">
            <Alert variant="danger" className="error-alert mx-2">
              <p className="error-message">{errorMessage}</p>
            </Alert>
          </div>
        )}
        <Card.Body className="px-0 py-0 finances-body-container">
          {income?.length === 0 && (
            <div className="netincome">
              <div className="netincome-label">
                <button type="button" className="btn btn-add-income" onClick={handleAddIncome}>
                  + Add Income
                </button>
              </div>
            </div>
          )}

          {income?.length !== 0 && (
            <IncomeTableDetailsNew
              incomeData={income}
              updateIncome={updateIncome}
              onDelete={handleDeleteIncome}
              onAddIncome={handleAddIncome}
              spouseCoApplicantId={spouseCoApplicantId}
              incomeTypesList={primaryIncomeTypes}
              validationError={errorMessage}
              ValidationErrorFlag={ValidationErrorFlag}
              currentTab={(employerNameOptions.length > 0 && true)}
            />
          )}
        </Card.Body>
      </TabPanel>
      {purchaseUse !== 'Business' && (
        <TabPanel value={currentTab} index={tabTypes.spouse} classes={{ root: classes.tab }}>
          {spouseincome?.length === 0 && errorMessage && (
            <div id="errormessage">
              <Alert variant="danger" className="error-alert mx-2">
                <p className="error-message">{errorMessage}</p>
              </Alert>
            </div>
          )}
          <Card.Body className="px-0 py-0 finances-body-container">
            {spouseincome?.length === 0 && (
              <div className="netincome">
                <div className="netincome-label">
                  <button type="button" className="btn btn-add-income" onClick={handleAddIncome}>
                    + Add income
                  </button>
                </div>
              </div>
            )}
            {spouseincome?.length !== 0 && (
              <IncomeTableDetailsNew
                incomeData={spouseincome}
                updateIncome={updateSpouseIncome}
                onAddIncome={handleAddIncome}
                onDelete={handleDeleteIncome}
                validationError={errorMessage}
                ValidationErrorFlag={ValidationErrorFlag}
                incomeTypesList={spouseIncomeTypes}
                currentTab={false}
              />
            )}
          </Card.Body>
        </TabPanel>
      )}


      <div className="container-footer py-3 px-3  border-top">
        <div className="col-12 d-flex justify-content-end px-0">
          <button
            type="button"
            className="btn btn-primary btn-next-page income-next-section"
            disabled={incometotal <= 0 || (spouseincometotal <= 0 && currentTab === tabTypes.spouse) || isLoading}
            hidden={initAssetList && !pendingIncomeSave}
            onClick={handleIncomeNext}
          >
            {isLoading ? (
              <>
                <CircularProgress color="inherit" size={24} thickness={2.6} />
                <span className="px-3">
                  <img src={rightnormal} alt="right" className="right-icon" />
                </span>
              </>
            ) : (
              <>
                Next
                <span className="px-3">
                  <img src={rightnormal} alt="right" className="right-icon" />
                </span>
              </>
            )}
          </button>
          <input
            type="button"
            id="btnsubmit"
            className="btn-dummy-page"
            style={{ visibility: 'hidden' }}
          />
        </div>
      </div>
    </Card>
  );
};

export default IncomeDetailsNew;
