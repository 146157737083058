import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';

import RootReducer from './Reducers';

const isDevelopment = process.env.NODE_ENV === 'development';

const composeEnhancers = (isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const middelWare = [thunkMiddleware];

if (isDevelopment) {
  middelWare.push(logger);
}

const store = createStore(RootReducer, composeEnhancers(applyMiddleware(...middelWare)));

export default store;
