import React from 'react';
import FindMakeFilter from '../../../../components/FindMakeFilter';

const ManualForm = ({
  vehicleYearsformatted,
  handleFieldChange,
  manufacturingYear,
  getValidationErrorsForField,
  vehicleMakesData,
  vehicleMake,
  isLoadingcarmakes,
  vehicleModelsData,
  vehicleModel,
}) => {
  return (
    <>
      <FindMakeFilter
        label={<span>When was the car manufactured?</span>}
        fields={vehicleYearsformatted.map((year) => ({
          name: String(year),
          value: year,
        }))}
        onChange={handleFieldChange}
        fieldName="manufacturingYear"
        value={manufacturingYear}
        formGroupClass="fit-screen"
        btnClass="full-screen-mode"
        labelClass="car-full-screen-finalise-car"
        rootContainerClass="year-class car-manufactured-year"
        loadmore
        isreturnObjectval
        getvalidationerrors={getValidationErrorsForField}
        id="car-manufactured-year"
      />
      {manufacturingYear && (
        <FindMakeFilter
          label={
            <>
              <span>What's the make of the car?</span>
            </>
          }
          fields={(vehicleMakesData && vehicleMakesData.length && vehicleMakesData) || []}
          onChange={handleFieldChange}
          fieldName="vehicleMake"
          rootContainerClass="find-make-container car-make"
          value={vehicleMake && vehicleMake.value}
          formGroupClass="fit-screen"
          btnClass="full-screen-mode"
          labelClass="car-full-screen-finalise-car"
          isLoading={isLoadingcarmakes}
          enableSearch
          removeLeftMargin={true}
          getvalidationerrors={getValidationErrorsForField}
          id="car-make"
        />
      )}
      {vehicleMake && (
        <FindMakeFilter
          label={
            <>
              <span>
                What's the model of the&nbsp;
                <span className="sub-label">{`${vehicleMake?.name}?`}</span>
              </span>
            </>
          }
          fields={(vehicleModelsData && vehicleModelsData.length && vehicleModelsData) || []}
          onChange={handleFieldChange}
          fieldName="vehicleModel"
          btnClass="full-screen-mode"
          rootContainerClass="find-model-container car-model"
          value={vehicleModel && vehicleModel.value}
          formGroupClass="fit-screen"
          loadmore
          labelClass="car-full-screen-finalise-car"
          getvalidationerrors={getValidationErrorsForField}
          id="car-model"
        />
      )}
    </>
  );
};

export default ManualForm;
