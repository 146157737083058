const promocodeRuleEvaluator = (rule, value) => {
  const match = ['gift200', 'gift100'];

  if (match.includes(value.toLocaleLowerCase()) || value === '') {
    return null;
  }
  return rule.errorMessage;
};

export default promocodeRuleEvaluator;
