module.exports = {
  QUOTE: '/quotes',
  ADDRESSAUTOCOMPLETE: '/addresses',
  RETRIEVEADDRESS: '/addresses/format',
  LEADCREATION: '/leads',
  VALIDATE_ENQUIRY: (contactId) => `/leads?contactId=${contactId}`,
  DIRECT_OPP_CREATE: '/opportunities/direct',
  OPP_CREATE: '/opportunities',
  GET_APPLICANT: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}`,
  GET_ADDRESS_UPDATE: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/addresses`,
  GET_EMPLOYMENT: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/employment`,
  GET_ASSET: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/asset`,
  GET_LIABILITY: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/liabilities`,
  GET_INCOME: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/income`,
  GET_EXPENSE: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/expenses`,
  DASHBOARDEXISTINGOPP: '/opportunities',
  CHANGESTATUS: '/opportunities/status',
  GET_UPD_CAR_DET: (oppurtunityId) => `/opportunities/${oppurtunityId}/carDetails`,
  GET_UPD_LOAN_DET: (oppurtunityId) => `/opportunities/${oppurtunityId}/loanDetails`,
  GET_OPPORTUNITY_RESP: (oppurtunityId) => `/opportunities/${oppurtunityId}`,
  GET_REGODETAILS: (regoNumber, regoState) => `/vehicles/${regoNumber}/${regoState}`,
  GET_ABNDETAILS: (abnNumber) => `/abns/${abnNumber}`,
  GET_YEARS: '/vehicles/years',
  GET_CARMAKES: (year) => `/vehicles/years/${year}/makes`,
  GET_CARMODELS: (year, make) => `/vehicles/years/${year}/makes/${make}/models`,
  GET_CARSERIES: (year, make, model) =>
    `/vehicles/years/${year}/makes/${make}/models/${model}/variants`,
  GET_CARVARIANTS: '/vehicles/nvics',
  GET_FACTORYOPTIONS: (nvic) => `/vehicles/${nvic}`,
  GET_REQUOTE: (oppurtunityId) => `/opportunities/${oppurtunityId}/quotes/latest`,
  GET_CO_APPLICANT: (oppurtunityId) => `/opportunities/${oppurtunityId}/applicants`,
  GET_CO_APPLICANT_INCOME: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/income`,
  DELETE_GENERIC: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/delete`,
  CREATE_ACCOUNT: (applicantId) => `/opportunities/contact/${applicantId}`,
  UPDATE_PRIVACY: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/privacy`,
  UPDATE_NCCP1: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/DFSCreditQuote`,
  UODATE_LOANDETAILS: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/updateLoanDeatils`,
  IS_PRODSELECT_GRP: (oppurtunityId) => `/opportunities/${oppurtunityId}/isPQProductSelectionGroup`,
  UPDATE_LENDER_PARAMS: (oppurtunityId) => `/opportunities/${oppurtunityId}/selectProdLoanDetails`,
  SELECT_PRODUCT: (oppurtunityId) => `/opportunities/${oppurtunityId}/selectProduct`,
  GET_TOPNLATEST: (oppurtunityId) => `/opportunities/${oppurtunityId}/quotes/topnlatest`,
  UPLOAD_DOCUMENT: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/documents`,
  GET_DOCUMENT_LIST: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/documents`,
  GET_DOCUMENT: (oppurtunityId, applicantId, documentId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/documents/${documentId}`,
  PQ_FINAL_SUBMISSION: (oppurtunityId) => `/opportunities/${oppurtunityId}/PQFinalSubmission`,
  GET_ACTIVE_LEADS: `/opportunities/retrieveLeads`,
  UPDATE_INSURANCE_ACCEPTANCE: (oppurtunityId, applicantId) =>
    `/opportunities/${oppurtunityId}/applicants/${applicantId}/InsuranceConsent`,
};
