import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import _, { filter } from 'lodash';
import Button from 'react-bootstrap/Button';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import Form from 'react-bootstrap/Form';
import remove from 'lodash/remove';
import TextField from '../../components/TextField';
import AddressAutoComplete from '../../components/AddressAutoComplete';
import BasicAutoComplete from '../../components/BasicAutoComplete';
import './employment-details.scss';
import rightnormal from '../../../assets/image/right-normal.svg';
import DropDown from '../../components/DropDown';
import leftnormal from '../../../assets/image/left-normal.svg';
import ABNlookUpDetails from '../Finances/Income/ABNlookUpDetails';

import {
  EMPLOYMENT_STATUS_PERSONAL,
  EMPSTATUS_RETIRED,
  EMPSTATUS_UNEMPLOYED,
  EMPLOYMENT_INDUSTRY,
  EMPSTATUS_FULL,
  EMPSTATUS_PART,
  EMPSTATUS_CASUAL,
  EMPLOYMENT_TYPE,
  EMPTYPE_SELF,
  DLSTATE,
  ROUTES,
  APPLICANT_TYPE,
  EMPLOYER_OCCUPATION,
  PRIMARY_APPLICANT,
  LABELS,
  PAYG_EMPLOYMENT_STATUS,
  SELF_EMPLOYMENT_STATUS,
} from '../../util/constants';
import DatePickerWrapper from '../../components/DatePickerWrapper';
import InputButton from '../../components/InputButton';
import Progress from '../../components/Progress';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import processGTMevent from '../../util/processGTMEvent';

export const formattedCurrentDate = moment().format('YYYY-MM-DD');
const MIN_DATE = '01/01/1990';
const MIN_DATE_PREVIOUS_ERROR_MESSAGE_START_DATE =
  'Previous employment Start date cannot be greater than Current Employment Start date';
const MIN_DATE_PREVIOUS_ERROR_MESSAGE_END_DATE =
  'Previous employment End date cannot be greater than Current Employment Start date or less than Previous Start date';
const MIN_DATE_ERROR_MESSAGE = 'End date should not be equal to or less than start date';
const MAX_DATE_ERROR_MESSAGE = 'Date cannot be greater than or equal to todays date';
const MIN_CURRENT_WORK_DATE_ERROR_MESSAGE = 'Date cannot be greater than todays date';

const EmploymentDetails = ({
  selectedLender,
  onInputChange,
  clearAddress,
  employerName,
  validateDateField,
  setTabComplete,
  applicationStructure,
  applicantType,
  fieldSuffix,
  industry,
  applicantValidationErrors,
  mainEmployment,
  prevEmployments,
  setPrevEmployment,
  employmentDetailsUpdate,
  currEmployments,
  setCurrEmployment,
  purchaseUse,
  isAbnknown,
  deletePrevEmployment,
  abnRegistrationDate,
  contactDetails,
  getValidationErrorsForField,
  opportunityId,
  setApplicationStrucutureStepper,
  employmentStartDate,
  retrieveOpportunity,
  partnerIncome,
  spouseIncomeApi,
  EmploymentType = '',
}) => {
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(768));
  const [noOfYearsStayed, setnoOfdays] = useState();
  const [validationErrors, setValidationErrors] = useState(false);
  const [isPristine, setFormPristine] = useState(true);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [employmentUpsertInProgress, setemploymentUpsertInProgress] = useState(false);
  const [deleting, deleteInProgress] = useState(false);
  const [manualEnter, setManualEnter] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (applicantType === APPLICANT_TYPE.spouse) {
      processGTMevent(
        'employment_details',
        'PQAdditionalQuestions-SpouseEmployment-PageLoad',
        'Page Load',
      );
    } else if (applicantType === APPLICANT_TYPE.coApplicant) {
      processGTMevent('employment_details', 'PQAdditionalQuestions-CoAppEmp-PageLoad', 'Page Load');
    } else if (applicantType === APPLICANT_TYPE.primary) {
      processGTMevent(
        'employment_details',
        'PQAdditionalQuestions-EmploymentPageLoad',
        'Page Load',
      );
    }
    if (applicantValidationErrors?.length) {
      onInputChange('validationErrors', [], false);
    }
  }, []);
  const getEmploymentSchema = (empStatus, fieldSuffixVal, empType = undefined) => ({
    refId: Math.random(),
    id: '',
    IsCurrent: empStatus,
    employmentStartDate: null,
    employerAddress: '',
    EmploymentType: '',
    hasABN:
      purchaseUse ===
      ('Personal' && EmploymentType !== undefined && EmploymentType === EMPTYPE_SELF)
        ? true
        : purchaseUse === 'Business'
        ? isAbnknown
        : false,
    mainEmployment:
      empStatus === 'Current' && fieldSuffixVal !== APPLICANT_TYPE.primary
        ? empType === 'Yes'
          ? 'Yes'
          : ''
        : mainEmployment,
    occupation: '',
    industry: '',
    employerABN: '',
    employerName: '',
    employmentEndDate: null,
    abn: '',
    acn: '',
    abnRegistrationDate: null,
    abnState: '',
    abnStatus: '',
    abrEntityType: '',
    entityType: '',
    entityName: '',
    equifaxOrgId: '',
    gstRegistrationdate: null,
    gstRegistrationstatus: '',
    abnVerified: false,
  });

  const getNumberofYears = (fromDt, toDate) => Math.abs(fromDt.diff(toDate, 'years', false));

  const updateNoOfDays = () => {
    let employmentStartDate = moment();

    currEmployments.forEach((empData) => {
      if (
        empData?.mainEmployment === 'Yes' &&
        (empData?.EmploymentType === EMPSTATUS_FULL ||
          empData?.EmploymentType === EMPSTATUS_PART ||
          empData?.EmploymentType === EMPSTATUS_CASUAL) &&
        moment(empData?.employmentStartDate, 'YYYY/MM/DD').isBefore(employmentStartDate)
      ) {
        employmentStartDate = moment(empData?.employmentStartDate, 'YYYY/MM/DD');
      }
      if (
        empData?.mainEmployment === 'Yes' &&
        empData?.EmploymentType === EMPTYPE_SELF &&
        moment(empData?.employmentStartDate, 'YYYY/MM/DD').isBefore(employmentStartDate)
      ) {
        employmentStartDate = moment(empData.employmentStartDate, 'YYYY/MM/DD');
      }
    });

    if (employmentStartDate) {
      setnoOfdays(
        getNumberofYears(moment(employmentStartDate, 'YYYY/MM/DD'), moment().format('YYYY/MM/DD')),
      );
    }
  };
  useEffect(() => {
    updateNoOfDays();
  }, [currEmployments]);

  useEffect(() => {
    updateNoOfDays();
    if (!currEmployments.length) {
      let partnerCurrentIncome = [];
      for (let i = 0; i < partnerIncome?.length; i++) {
        const empType = i === 0 ? 'Yes' : undefined;
        partnerCurrentIncome.push(getEmploymentSchema('Current', fieldSuffix, empType));
      }
      setCurrEmployment(fieldSuffix, partnerCurrentIncome);
    }
  }, [location]);

  const validateEmploymentDetails = (empData, isPreviousEmploymentTypeArray = false) => {
    const errorList = [];
    if (empData?.EmploymentType === '') {
      errorList.push({
        fieldName: 'employmentType',
        messages: ['Please select one'],
      });
    }
    if (
      empData?.EmploymentType === EMPSTATUS_FULL ||
      empData?.EmploymentType === EMPSTATUS_CASUAL ||
      empData?.EmploymentType === EMPSTATUS_PART
    ) {
      if (!empData?.employmentStartDate) {
        errorList.push({ fieldName: `employmentStartDate${fieldSuffix}`, messages: ['Required'] });
      }
      if (!empData.employerName) {
        errorList.push({ fieldName: `employerName${fieldSuffix}`, messages: ['Required'] });
      }

      if (!isPreviousEmploymentTypeArray) {
        if (!empData.industry) {
          errorList.push({ fieldName: `industry${fieldSuffix}`, messages: ['Required'] });
        }
        if (!empData.occupation) {
          errorList.push({ fieldName: `occupation${fieldSuffix}`, messages: ['Required'] });
        }

        if (empData.employerABN && empData.employerABN.length !== 11) {
          errorList.push({
            fieldName: `employerABN${fieldSuffix}`,
            messages: ['Invalid ABN number'],
          });
        }
      } else if (!empData.employmentEndDate) {
        errorList.push({ fieldName: `employmentEndDate${fieldSuffix}`, messages: ['Required'] });
      }
    }
    if (empData?.EmploymentType === EMPTYPE_SELF && (!empData.abn || empData.abn.length !== 11)) {
      errorList.push({
        fieldName: `abnNumber${fieldSuffix}`,
        messages: ['Required and  ABN number should be 11 digit'],
      });
    }
    return errorList;
  };
  const checkEmpDetails =
    currEmployments[0]?.EmploymentType === EMPTYPE_SELF
      ? !currEmployments[0].abnNumber ||
        !currEmployments[0].entityName ||
        !currEmployments[0].entityType ||
        !currEmployments[0].abnRegistrationDate
      : !currEmployments[0]?.employmentStartDate ||
        (selectedLender.toLowerCase() === 'macquarie' && !currEmployments[0].employerABN) ||
        !currEmployments[0].employerName ||
        !currEmployments[0]?.EmploymentType ||
        !currEmployments[0].industry ||
        !currEmployments[0].occupation ||
        (!currEmployments[0].employerAddress && !manualEnter) ||
        (manualEnter &&
          (!currEmployments[0].streetName ||
            !currEmployments[0].suburb ||
            !currEmployments[0].postcode ||
            !currEmployments[0].state));

  const employmentSaveDisabled = applicantValidationErrors?.length || checkEmpDetails;
  const getValidationDataPopulated = (empData, isPreviousEmploymentTypeArray) => {
    const employmentToUpdate = { ...empData };
    const errors = validateEmploymentDetails(employmentToUpdate, isPreviousEmploymentTypeArray);
    employmentToUpdate.errors = errors;
    return employmentToUpdate;
  };

  const validateFields = () => {
    let isFormValid = true;
    const updatedCurrentEmployment = currEmployments.map((empData) => {
      const employmentToUpdate = getValidationDataPopulated(empData, false);
      if (employmentToUpdate.errors && employmentToUpdate.errors.length) {
        isFormValid = false;
      }
      return employmentToUpdate;
    });
    const updatedPreviousEmployment = prevEmployments.map((empData) => {
      const employmentToUpdate = getValidationDataPopulated(empData, true);
      if (employmentToUpdate.errors && employmentToUpdate.errors.length) {
        isFormValid = false;
      }
      return employmentToUpdate;
    });
    setValidationErrors(!isFormValid);
    setFormPristine(false);
    setCurrEmployment(fieldSuffix, updatedCurrentEmployment);
    setPrevEmployment(fieldSuffix, updatedPreviousEmployment);

    return isFormValid;
  };

  const employmentNextClick = () => {
    setFormSubmitted(true);
    const isFormValid = validateFields();
    if (!validationErrors && isFormValid) {
      setemploymentUpsertInProgress(true);
      setTabComplete('employment_details');
      setApplicationStrucutureStepper(applicationStructure);
      const nextRoute = `${ROUTES.contactDetails}/${applicationStructure}`;
      employmentDetailsUpdate(applicantType)
        .then((response) => {
          if (applicantType === APPLICANT_TYPE.coApplicant) {
            processGTMevent('employment_details', 'PQAdditionalQuestions-CoAppEmp-Next', 'Click');
          } else if (applicantType === APPLICANT_TYPE.primary) {
            processGTMevent(
              'employment_details',
              'PQAdditionalQuestions-NextFinishSingle',
              'Click',
            );
          } else if (applicantType === APPLICANT_TYPE.spouse) {
            processGTMevent(
              'employment_details',
              'PQAdditionalQuestions-SpouseEmployment-Next',
              'Click',
            );
          }

          if (
            applicationStructure !== APPLICANT_TYPE.primary &&
            applicantType === APPLICANT_TYPE.primary
          ) {
            setemploymentUpsertInProgress(false);
            if (contactDetails) {
              if (applicationStructure === APPLICANT_TYPE.coApplicant) {
                history.push(`${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
              } else {
                localStorage.setItem(`pageCompleted${opportunityId}`, 'post-employment-spouse');
                history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.spouse}`);
              }
            } else {
              localStorage.setItem(`pageCompleted${opportunityId}`, 'post-employment-primary');
              history.push(nextRoute);
            }
          } else if (applicationStructure === APPLICANT_TYPE.spouse) {
            localStorage.setItem(`pageCompleted${opportunityId}`, 'post-employment-spouse');
            updateEmployerdetailandNavigation(response?.data?.empId);
          } else if (applicationStructure === APPLICANT_TYPE.coApplicant) {
            localStorage.setItem(`pageCompleted${opportunityId}`, 'post-employment-coapp');
            updateEmployerdetailandNavigation(response?.data?.empId);
          } else if (applicationStructure === APPLICANT_TYPE.primary) {
            localStorage.setItem(`pageCompleted${opportunityId}`, 'post-employment-primary');
            setemploymentUpsertInProgress(false);
            setTabComplete('addtional_information');
            history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.primary}`);
          }
        })
        .catch((err) => {
          setemploymentUpsertInProgress(false);
          history.push(ROUTES.financeComplete);
        });
    }
  };

  const updateEmployerdetailandNavigation = (empIds) => {
    setTabComplete('addtional_information');
    for (let i = 0; i < partnerIncome?.length; i++) {
      spouseIncomeApi({
        id: partnerIncome[i].id || '',
        incomeType: partnerIncome[i].category || '',
        employmentId: empIds[i] || '',
        applicantEmployerName:
          (currEmployments[i].EmploymentType === EMPTYPE_SELF
            ? currEmployments[i].entityName
            : currEmployments[i].employerName) || '',
        amount: partnerIncome[i].amount || '',
        frequency: partnerIncome[i].frequency || '',
      })
        .then(() => {
          if (i === partnerIncome?.length - 1) {
            retrieveOpportunity()
              .then(() => {
                setemploymentUpsertInProgress(false);
                history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.primary}`);
              })
              .catch((error) => {
                setemploymentUpsertInProgress(false);
                history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.primary}`);
              });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  };
  const RESET_DEPENDENT_FIELDS = {
    [`EmploymentType${fieldSuffix}`]: [
      `employerName${fieldSuffix}`,
      `employmentStartDate${fieldSuffix}`,
      `industry${fieldSuffix}`,
      `occupation${fieldSuffix}`,
      `employerABN${fieldSuffix}`,
      `firstName${fieldSuffix}`,
      `lastName${fieldSuffix}`,
      `mobileNumber${fieldSuffix}`,
      `emailAddress${fieldSuffix}`,
      `abnNumber${fieldSuffix}`,
      `acn${fieldSuffix}`,
      `abnRegistrationDate${fieldSuffix}`,
      `abnState${fieldSuffix}`,
      `abnStatus${fieldSuffix}`,
      `abrEntityType${fieldSuffix}`,
      `entityType${fieldSuffix}`,
      `entityName${fieldSuffix}`,
      `equifaxOrgId${fieldSuffix}`,
      `gstRegistrationdate${fieldSuffix}`,
      `gstRegistrationstatus${fieldSuffix}`,
      `employerAddress${fieldSuffix}`,
      `employerAddress${fieldSuffix}`,
      `unitNumber${fieldSuffix}`,
      `streetName${fieldSuffix}`,
      `suburb${fieldSuffix}`,
      `postcode${fieldSuffix}`,
      `state${fieldSuffix}`,
      `country${fieldSuffix}`,
      `abn${fieldSuffix}`,
      `abnRegisteredDate${fieldSuffix}`,
      `gstRegisteredDate${fieldSuffix}`,
      `address${fieldSuffix}`,
    ],

    [`EmploymentType`]: [
      `employerName`,
      `employmentStartDate`,
      `industry`,
      `occupation`,
      `employerABN`,
      `firstName`,
      `lastName`,
      `mobileNumber`,
      `emailAddress`,
      `abnNumber`,
      `abn`,
      `acn`,
      `abnRegistrationDate`,
      `abnRegisteredDate`,
      `gstRegisteredDate`,
      `abnState`,
      `abnStatus`,
      `abrEntityType`,
      `entityType`,
      `entityName`,
      `equifaxOrgId`,
      `gstRegistrationdate`,
      `gstRegistrationstatus`,
      `employerAddress`,
      `unitNumber`,
      `streetName`,
      `suburb`,
      `postcode`,
      `state`,
      `country`,
      `address`,
    ],
  };

  const updateEmploymentDetailsFields = (isPreviousEmploymentTypeArray = false) => (
    fieldName,
    value,
    idx,
  ) => {
    // if (!value) return;
    setFormPristine(false);

    const cloneEmployments = isPreviousEmploymentTypeArray
      ? [...prevEmployments]
      : [...currEmployments];
    let employmentToUpdate = { ...cloneEmployments[idx] };
    employmentToUpdate[fieldName] = value;
    // Reset any dependent fields

    if (RESET_DEPENDENT_FIELDS[fieldName] && applicantType === APPLICANT_TYPE.primary) {
      RESET_DEPENDENT_FIELDS[fieldName].forEach((dependentFieldName) => {
        employmentToUpdate[dependentFieldName] = '';
      });

      employmentToUpdate.employmentStartDateReset = fieldName === 'EmploymentType';
    }

    if (isFormSubmitted) {
      employmentToUpdate = getValidationDataPopulated(
        employmentToUpdate,
        isPreviousEmploymentTypeArray,
      );
      // setValidationErrors(!!(employmentToUpdate.errors && employmentToUpdate.errors.length));
    }
    cloneEmployments.splice(idx, 1, employmentToUpdate);

    const dispatchArrguments = [fieldSuffix, [...cloneEmployments]];

    if (isPreviousEmploymentTypeArray) {
      setPrevEmployment(...dispatchArrguments);
    } else {
      setCurrEmployment(...dispatchArrguments);
    }
    setnoOfdays(
      getNumberofYears(
        moment(cloneEmployments[idx]?.employmentStartDate, 'YYYY/MM/DD'),
        moment().format('YYYY/MM/DD'),
      ),
    );
  };

  const clearField = (fieldName, idx) => {
    updateEmploymentDetailsFields(false)(fieldName, '', idx);
  };

  const updateCurrentEmploymentField = (fieldName, value, idx) => {
    updateEmploymentDetailsFields(false)(fieldName, value, idx);
  };

  const updatePreviousEmploymentField = (fieldName, value, idx) => {
    updateEmploymentDetailsFields(true)(fieldName, value, idx);
  };

  const updateCurrentFormattedAddress = (formattedAddress, idx) => {
    if (formattedAddress) {
      const cloneEmployments = [...currEmployments];

      let employmentToUpdate = { ...cloneEmployments[idx], ...formattedAddress };
      employmentToUpdate = getValidationDataPopulated(employmentToUpdate, false);
      cloneEmployments.splice(idx, 1, employmentToUpdate);

      setCurrEmployment(fieldSuffix, [...cloneEmployments]);
    } else {
      const cloneEmployments = [...currEmployments];

      let employmentToUpdate = {
        ...cloneEmployments[idx],
        employerAddress: '',
        country: '',
        postalcode: '',
        postcode: '',
        state: '',
        streetName: '',
        buildingName: '',
        streetNumber: '',
        streetType: '',
        suburb: '',
        unitNumber: '',
      };
      employmentToUpdate = getValidationDataPopulated(employmentToUpdate, false);
      cloneEmployments.splice(idx, 1, employmentToUpdate);

      setCurrEmployment(fieldSuffix, [...cloneEmployments]);
    }
  };

  const handleABN = (_fieldName, value, idx) => {
    const cloneEmployments = [...currEmployments];

    let employmentToUpdate = { ...cloneEmployments[idx], ...value };
    employmentToUpdate = getValidationDataPopulated(employmentToUpdate, false);
    cloneEmployments.splice(idx, 1, employmentToUpdate);

    setCurrEmployment(fieldSuffix, [...cloneEmployments]);
  };

  const handlePreviousABN = (_fieldName, value, idx) => {
    const cloneEmployments = [...prevEmployments];

    let employmentToUpdate = { ...cloneEmployments[idx], ...value };
    employmentToUpdate = getValidationDataPopulated(employmentToUpdate, true);
    cloneEmployments.splice(idx, 1, employmentToUpdate);

    setPrevEmployment(fieldSuffix, [...cloneEmployments]);
  };

  const applicantTypeDisplay =
    // eslint-disable-next-line no-nested-ternary
    applicantType === APPLICANT_TYPE.spouse
      ? 'SPOUSE'
      : applicantType === APPLICANT_TYPE.coApplicant
      ? 'Co-Applicant'
      : 'Primary Applicant';

  const handlePreviousEmployment = () => {
    setPrevEmployment(fieldSuffix, [...prevEmployments, getEmploymentSchema('Previous')]);
  };

  const navigateToPrevious = () => {
    if (applicantType === APPLICANT_TYPE.spouse) {
      history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.primary}`);
    }
    if (applicantType === APPLICANT_TYPE.coApplicant) {
      history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.primary}`);
    } else if (applicantType === APPLICANT_TYPE.primary) {
      history.push(`${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.primary}`);
    } else {
      history.goBack();
    }
  };

  const deleteEmploymentClick = (prevEmpId, refId) => {
    const clonedPrevEmployments = [...prevEmployments];
    if (prevEmpId) {
      deleteInProgress(true);
      deletePrevEmployment(prevEmpId)
        .then(() => {
          remove(
            clonedPrevEmployments,
            (curr) => curr.currentEmpId === prevEmpId || curr.id === prevEmpId,
          );
          deleteInProgress(false);
        })
        .catch((err) => {
          deleteInProgress(false);
        });
    } else {
      remove(clonedPrevEmployments, (curr) => curr.refId === refId);
    }
    setPrevEmployment(fieldSuffix, clonedPrevEmployments);
  };
  return (
    <>
      <div className="employer-details-page">
        <div className="employer-title-section">
          <div className="employer-details-title">Employer Details </div>
          <div className="employer-details-subtitle">
            {selectedLender} requires 3 years of employment history for approval.
          </div>
        </div>
        <div className="employer-details-section">
          <div className="applicant-type">{applicantTypeDisplay}</div>
          <div className="employer-details-headers">EMPLOYMENT DETAILS</div>

          {currEmployments.map((empData, idx) => {
            const employmentFields =
              applicantType !== APPLICANT_TYPE.primary
                ? partnerIncome[idx]?.incomeType === 'PAYG Income'
                  ? PAYG_EMPLOYMENT_STATUS
                  : partnerIncome[idx]?.incomeType === 'Self-Employed Income'
                  ? SELF_EMPLOYMENT_STATUS
                  : EMPLOYMENT_STATUS_PERSONAL
                : EMPLOYMENT_STATUS_PERSONAL;
            return (
              <>
                <div className="employer-type-wrapper">
                  <span className="employer-header">CURRENT EMPLOYER</span>
                  {applicantType !== APPLICANT_TYPE.primary && (
                    <span className="employer-type">
                      {`${partnerIncome[idx]?.incomeType} ($${partnerIncome[idx]?.amount})`}
                    </span>
                  )}
                </div>
                <span className="employer-field-name">
                  {
                    empData?.errors?.find((e) => {
                      return e?.fieldName === 'employmentType';
                    })?.messages[0]
                  }
                </span>
                <InputButton
                  label={
                    <>
                      <span>How would you describe your current employment?</span>
                    </>
                  }
                  fields={employmentFields}
                  updateOnChange
                  onChange={(_, value) =>
                    updateCurrentEmploymentField('EmploymentType', value, idx)
                  }
                  fieldName={`EmploymentType${fieldSuffix}`}
                  value={empData?.EmploymentType}
                  getvalidationerrors={getValidationErrorsForFieldFunc(
                    !isPristine ? empData.errors : undefined,
                  )}
                  scrollToNextFieldOnChage
                  isdisabled={fieldSuffix === ''}
                />
                {empData?.EmploymentType === EMPTYPE_SELF && (
                  <ABNlookUpDetails
                    fieldName="employmentPrimaryABN"
                    abnRespDetails={(fieldName, value) => handleABN(fieldName, value, idx)}
                    defaultAbnNumber={empData.abn}
                    defaultEntityType={empData.entityType}
                    defaultEntityName={empData.entityName}
                    defaultAbnStatus={empData.abnStatus}
                    isdisabled={fieldSuffix === ''}
                    getValidationErrorsForField={getValidationErrorsForFieldFunc(
                      !isPristine ? empData.errors : undefined,
                    )}
                    defaultGstRegistrationstatus={empData.gstRegistrationstatus}
                  />
                )}
                {(empData?.EmploymentType === EMPSTATUS_FULL ||
                  empData?.EmploymentType === EMPSTATUS_PART ||
                  empData?.EmploymentType === EMPSTATUS_CASUAL) && (
                  <div>
                    <div className="employer-details">
                      <TextField
                        fieldName={`employerName${fieldSuffix}`}
                        value={empData?.employerName}
                        isdisabled={!fieldSuffix && !!empData.employerName}
                        label="Employer name "
                        placeholder="Employer name"
                        getvalidationerrors={getValidationErrorsForFieldFunc(
                          !isPristine ? empData.errors : undefined,
                        )}
                        onChange={(_, value) =>
                          updateCurrentEmploymentField('employerName', value, idx)
                        }
                        colmd={0}
                        className="employer-name"
                        inline
                        updateOnChange
                      />
                      <DatePickerWrapper
                        className="current-emp-date"
                        dateFormat="dd/MM/yyyy"
                        enableAddressScreen
                        fieldName={`employmentStartDate${fieldSuffix}`}
                        getValidationErrors={getValidationErrorsForFieldFunc(
                          !isPristine
                            ? empData.errors || applicantValidationErrors
                            : applicantValidationErrors,
                        )}
                        inline
                        label="Start date"
                        maxDateMessage={MAX_DATE_ERROR_MESSAGE}
                        maxDtVal={formattedCurrentDate}
                        minDateMessage={MIN_CURRENT_WORK_DATE_ERROR_MESSAGE}
                        minDtVal={MIN_DATE}
                        onChange={(_, value) => {
                          updateCurrentEmploymentField('employmentStartDate', value, idx);
                        }}
                        isdisabled={!fieldSuffix && !!empData?.employmentStartDate}
                        placeholderVal="DD/MM/YYYY"
                        updateOnChange
                        validateDateField={validateDateField}
                        value={empData?.employmentStartDate}
                        updateValue={empData?.employmentStartDateReset}
                        postSelect
                      />
                    </div>
                    <div className="employer-occupation-details">
                      <div className="employer-industry-container col">
                        <Form.Label className="row">{LABELS.Industry}</Form.Label>
                        <BasicAutoComplete
                          fieldName={`industry${fieldSuffix}`}
                          value={empData?.industry || ''}
                          className="employer-industry"
                          label={LABELS.Industry}
                          placeholder={LABELS.Industry}
                          columnCount="0"
                          data={EMPLOYMENT_INDUSTRY}
                          onChange={(_, value) =>
                            updateCurrentEmploymentField('industry', value, idx)
                          }
                          clearField={() => clearField('industry', idx)}
                          getvalidationerrors={getValidationErrorsForFieldFunc(
                            !isPristine ? empData.errors : undefined,
                          )}
                        />
                      </div>

                      <div className="employer-occupation-container col">
                        <Form.Label className="row">{LABELS.Occupation}</Form.Label>
                        <BasicAutoComplete
                          fieldName={`occupation${fieldSuffix}`}
                          value={empData?.occupation || ''}
                          className="employer-occupation"
                          label={LABELS.Occupation}
                          placeholder={LABELS.Occupation}
                          columnCount="0"
                          data={EMPLOYER_OCCUPATION}
                          onChange={(_, value) =>
                            updateCurrentEmploymentField('occupation', value, idx)
                          }
                          clearField={() => clearField('occupation', idx)}
                          getvalidationerrors={getValidationErrorsForFieldFunc(
                            !isPristine ? empData.errors : undefined,
                          )}
                        />
                      </div>
                      <TextField
                        fieldName={`employerABN${fieldSuffix}`}
                        value={empData?.employerABN}
                        label="Employer ABN/ACN"
                        placeholder="Employer ABN"
                        className="employer-abn"
                        getvalidationerrors={getValidationErrorsForFieldFunc(
                          !isPristine ? empData.errors : undefined,
                        )}
                        onChange={(_, value) =>
                          updateCurrentEmploymentField('employerABN', value, idx)
                        }
                        colmd={0}
                        inline
                        updateOnChange
                        type="number"
                      />
                    </div>
                    <>
                      {!manualEnter ? (
                        <>
                          <AddressAutoComplete
                            fieldName={`employerAddress${fieldSuffix}`}
                            addressClass="px-0 addressselection"
                            value={empData.employerAddress}
                            label="Employer address"
                            placeholder="Search..."
                            columnCount="0"
                            getvalidationerrors={getValidationErrorsForFieldFunc(
                              !isPristine ? empData.errors : undefined,
                            )}
                            onChange={(_, value) =>
                              updateCurrentEmploymentField('employerAddress', value, idx)
                            }
                            onAddressSelection={(value) =>
                              updateCurrentFormattedAddress(value, idx)
                            }
                            clearAddress={(value) => updateCurrentFormattedAddress('', idx)}
                          />

                          <div className="enter-manual-address">
                            <span className="could-not-find">
                              Couldn’t find it?
                              <button
                                type="button"
                                className=" btn btn-link enter-manually"
                                onClick={() => setManualEnter(true)}
                              >
                                <span className="enter-manually-label">Enter manually</span>
                              </button>
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="pl-2">
                            <div className="adress-manual-enter ">
                              <span className="search-your-address">
                                You can search your address
                                <button
                                  type="button"
                                  className=" btn btn-link search-address"
                                  onClick={() => {
                                    setManualEnter(false);
                                  }}
                                >
                                  Search address
                                </button>
                              </span>
                            </div>
                            <div className="adress-unit-streetname ">
                              <TextField
                                fieldName={`unitNumber${fieldSuffix}`}
                                value={empData.unitNumber}
                                label="Unit number"
                                placeholder="Unit number"
                                getvalidationerrors={getValidationErrorsForFieldFunc(
                                  !isPristine ? empData.errors : undefined,
                                )}
                                onChange={(_, value) =>
                                  updateCurrentEmploymentField('unitNumber', value, idx)
                                }
                                className="unit-number"
                                inline
                                updateOnChange
                              />
                              <TextField
                                fieldName={`streetName${fieldSuffix}`}
                                value={empData.streetName}
                                label="Street address"
                                placeholder="Street address"
                                getvalidationerrors={getValidationErrorsForFieldFunc(
                                  !isPristine ? empData.errors : undefined,
                                )}
                                onChange={(_, value) =>
                                  updateCurrentEmploymentField('streetName', value, idx)
                                }
                                className="street-name"
                                inline
                                updateOnChange
                              />
                            </div>

                            <div className="adress-suburb-postcode ">
                              <TextField
                                fieldName={`suburb${fieldSuffix}`}
                                value={empData.suburb}
                                label="Suburb"
                                placeholder="Suburb"
                                getvalidationerrors={getValidationErrorsForFieldFunc(
                                  !isPristine ? empData.errors : undefined,
                                )}
                                onChange={(_, value) =>
                                  updateCurrentEmploymentField('suburb', value, idx)
                                }
                                className="suburb"
                                inline
                                updateOnChange
                              />
                              <TextField
                                fieldName={`postcode${fieldSuffix}`}
                                value={empData.postcode}
                                label="Postcode"
                                placeholder="Postcode"
                                getvalidationerrors={getValidationErrorsForFieldFunc(
                                  !isPristine ? empData.errors : undefined,
                                )}
                                onChange={(_, value) =>
                                  updateCurrentEmploymentField('postcode', value, idx)
                                }
                                className="postcode"
                                inline
                                updateOnChange
                              />
                              <DropDown
                                fieldName={`state${fieldSuffix}`}
                                value={empData.state}
                                label="State"
                                className="state"
                                getvalidationerrors={getValidationErrorsForFieldFunc(
                                  !isPristine ? empData.errors : undefined,
                                )}
                                onChange={(_, value) =>
                                  updateCurrentEmploymentField('state', value, idx)
                                }
                                options={
                                  <>
                                    <option value="" hidden>
                                      Select...
                                    </option>
                                    {DLSTATE.map((optionState) => (
                                      <option key={optionState} value={optionState}>
                                        {optionState}
                                      </option>
                                    ))}
                                  </>
                                }
                                scrollToNextFieldOnChage
                                updateOnChange
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  </div>
                )}
              </>
            );
          })}

          {noOfYearsStayed < 3 && applicantType !== APPLICANT_TYPE.spouse && (
            <>
              <hr className="border-top-add-employment" />
              <Button
                className="btn-add-section"
                data-test="add employment"
                onClick={handlePreviousEmployment}
              >
                <span className="add-details">+ Add Previous Employment</span>
              </Button>
            </>
          )}

          {applicantType !== APPLICANT_TYPE.spouse && !!prevEmployments.length && (
            <div>
              <div className="applicant-type">PREVIOUS EMPLOYMENT</div>{' '}
              {prevEmployments.map((employeeData, idx) => (
                <>
                  <div key={employeeData.refId}>
                    <div className="prev-delete-block">
                      <Button
                        variant="danger"
                        className="delete-btn"
                        onClick={() =>
                          deleteEmploymentClick(
                            employeeData.currentEmpId || employeeData.id,
                            employeeData.refId,
                          )
                        }
                      >
                        {deleting ? (
                          <CircularProgress color="inherit" size={24} thickness={2.6} />
                        ) : (
                          'Delete'
                        )}
                      </Button>
                    </div>
                    <InputButton
                      label={
                        <>
                          <span>How would you describe your previous employment?</span>
                        </>
                      }
                      fields={EMPLOYMENT_TYPE}
                      onChange={(_, value) =>
                        updatePreviousEmploymentField('EmploymentType', value, idx)
                      }
                      fieldName="EmploymentType"
                      value={employeeData?.EmploymentType}
                      getvalidationerrors={getValidationErrorsForFieldFunc(
                        !isPristine ? employeeData.errors : undefined,
                      )}
                      scrollToNextFieldOnChage
                      updateOnChange
                    />

                    {(employeeData?.EmploymentType === EMPSTATUS_FULL ||
                      employeeData?.EmploymentType === EMPSTATUS_PART ||
                      employeeData?.EmploymentType === EMPSTATUS_CASUAL) && (
                      <div>
                        <div className="employer-details">
                          <TextField
                            fieldName="employerName"
                            value={employeeData.employerName}
                            label="Employer name "
                            placeholder="Employer name"
                            getvalidationerrors={getValidationErrorsForFieldFunc(
                              !isPristine ? employeeData.errors : undefined,
                            )}
                            onChange={(_, value) =>
                              updatePreviousEmploymentField('employerName', value, idx)
                            }
                            colmd={6}
                            className="employer-name"
                            inline
                            updateOnChange
                          />
                          <DatePickerWrapper
                            className="current-emp-date"
                            columnCount="3"
                            dateFormat="dd/MM/yyyy"
                            fieldName="employmentStartDate"
                            getValidationErrors={getValidationErrorsForFieldFunc(
                              !isPristine
                                ? employeeData.errors || applicantValidationErrors
                                : applicantValidationErrors,
                            )}
                            inline
                            label="Start date"
                            maxDateMessage={MIN_DATE_PREVIOUS_ERROR_MESSAGE_START_DATE}
                            maxDtVal={moment(currEmployments[0]?.employmentStartDate)
                              .subtract(1, 'days')
                              .format('YYYY-MM-DD')}
                            minDateMessage={MIN_DATE_PREVIOUS_ERROR_MESSAGE_START_DATE}
                            minDtVal={MIN_DATE}
                            onChange={(fieldName, value) =>
                              updatePreviousEmploymentField(fieldName, value, idx)
                            }
                            placeholderVal="DD/MM/YYYY"
                            showYearMonth={['date', 'year', 'month']}
                            updateOnChange
                            validateDateField={validateDateField}
                            value={employeeData?.employmentStartDate}
                            postSelect
                          />
                          <DatePickerWrapper
                            className="prev-address-to-date form-input"
                            columnCount="3"
                            dateFormat="dd/MM/yyyy"
                            fieldName="employmentEndDate"
                            getValidationErrors={getValidationErrorsForFieldFunc(
                              !isPristine
                                ? employeeData.errors || applicantValidationErrors
                                : applicantValidationErrors,
                            )}
                            inline
                            label="End Date"
                            maxDateMessage={MIN_DATE_PREVIOUS_ERROR_MESSAGE_END_DATE}
                            maxDtVal={moment(currEmployments[0]?.employmentStartDate)
                              .subtract(1, 'days')
                              .format('YYYY-MM-DD')}
                            minDateMessage={MIN_DATE_PREVIOUS_ERROR_MESSAGE_END_DATE}
                            minDtVal={
                              moment(employeeData?.employmentStartDate)
                                .add(1, 'days')
                                .format('YYYY-MM-DD') ||
                              moment(currEmployments[0]?.employmentStartDate)
                                .add(1, 'days')
                                .format('YYYY-MM-DD') ||
                              null
                            }
                            onChange={(fieldName, value) =>
                              updatePreviousEmploymentField(fieldName, value, idx)
                            }
                            placeholderVal="DD/MM/YYYY"
                            showYearMonth={['date', 'year', 'month']}
                            updateOnChange
                            validateDateField={validateDateField}
                            value={employeeData.employmentEndDate}
                            postSelect
                          />
                        </div>
                      </div>
                    )}

                    {employeeData?.EmploymentType === EMPTYPE_SELF && (
                      <ABNlookUpDetails
                        fieldName="employmentPreviousABN"
                        abnRespDetails={(fieldName, value) =>
                          handlePreviousABN(fieldName, value, idx)
                        }
                        defaultAbnNumber={employeeData.abnNumber}
                        defaultEntityType={employeeData.entityType}
                        defaultEntityName={employeeData.entityName}
                        defaultAbnStatus={employeeData.abnStatus}
                        defaultGstRegistrationstatus={employeeData.gstRegistrationstatus}
                      />
                    )}
                  </div>
                </>
              ))}
            </div>
          )}
        </div>

        <div className="btn  footer-actions btn-address-next">
          {mobileView ? (
            <>
              <button
                type="button"
                onClick={navigateToPrevious}
                className="btn btn-primary  btn-next-page"
              >
                <span className="pr-2">
                  <img src={leftnormal} alt="right" className="right-icon" />
                </span>
                Previous
              </button>

              <button
                type="button"
                onClick={employmentNextClick}
                className="btn btn-primary btn-next-page"
                disabled={employmentSaveDisabled}
              >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {employmentUpsertInProgress ? (
                    <CircularProgress color="inherit" size={24} thickness={2.6} />
                  ) : (
                    <>
                      Next
                      <img src={rightnormal} alt="right" className="right-icon" />
                    </>
                  )}
                </div>
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={navigateToPrevious}
                className="btn btn-primary  btn-next-page"
              >
                <span className="pr-3">
                  <img src={leftnormal} alt="right" className="right-icon" />
                </span>
                Previous
              </button>

              {applicantType === APPLICANT_TYPE.coApplicant ? (
                <Progress completed={5} pending={0} />
              ) : (
                <Progress completed={2} pending={0} />
              )}

              <button
                type="button"
                onClick={employmentNextClick}
                className="btn btn-primary btn-next-page"
                disabled={employmentSaveDisabled}
              >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {employmentUpsertInProgress ? (
                    <CircularProgress color="inherit" size={24} thickness={2.6} />
                  ) : (
                    'Next'
                  )}
                  <span className="px-3">
                    <img src={rightnormal} alt="right" className="right-icon" />
                  </span>
                </div>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default EmploymentDetails;
