import find from 'lodash/find';
import filter from 'lodash/filter';

import { EMPTYPE_SELF, EMPSTATUS_UNEMPLOYED, EMPSTATUS_RETIRED } from './constants';
// eslint-disable-next-line import/no-cycle
import { PAY_G } from '../containers/Finances/Income/IncomeDetails';

// eslint-disable-next-line import/prefer-default-export
export const validateIncomeErrors = ({ store }) => {
  const state = store.getState();
  const { purchaseUse } = state.car;
  const {
    employmentStatus,
    income,
    isAbnknown,
    employmentStatus2,
    employerName,
    employerName2,
    entityName,
  } = state.applicants;
  const SELF_EMPLOYED = 'Self-Employed Income';
  const paygIncome = filter(income, ['category', PAY_G]) || [];
  const selfEmployed = find(income, ['category', SELF_EMPLOYED]);
  const incomeWithEmployee1 = find(income, ['applicantEmployerName', employerName]) || false;
  const incomeWithEmployee2 = find(income, ['applicantEmployerName', employerName2]) || false;
  let invalidEmployer = false;
  let invalidIncome = false;
  income.forEach((incomeData) => {
    if (
      incomeData?.applicantEmployerName &&
      incomeData.applicantEmployerName !== employerName &&
      incomeData.applicantEmployerName !== employerName2 &&
      incomeData.applicantEmployerName !== entityName
    ) {
      invalidEmployer = true;
    }

    if (incomeData.category === '' ||
      incomeData.amount === ''
    ) {
      invalidIncome = true;
    }
  });

  const empStatus1 =
    employmentStatus !== EMPTYPE_SELF &&
    employmentStatus !== EMPSTATUS_UNEMPLOYED &&
    employmentStatus !== EMPSTATUS_RETIRED &&
    employerName;

  const empStatus2 =
    employmentStatus2 !== EMPTYPE_SELF &&
    employmentStatus2 !== EMPSTATUS_UNEMPLOYED &&
    employmentStatus2 !== EMPSTATUS_RETIRED &&
    employerName2;

  if (invalidEmployer) {
    return 'An employment record has been updated, please review and update the related income.';
  }

  if (invalidIncome) {
    return 'Please check that all details have been filled out in the Primary applicant tab before pressing next.';
  }

  if (
    purchaseUse === 'Personal' &&
    empStatus1 &&
    !incomeWithEmployee1 &&
    (!paygIncome.length || (empStatus2 && paygIncome.length < 2))
  ) {
    return 'PAYG income type  is Mandatory based on your current employment situation';
  }

  if (
    purchaseUse === 'Personal' &&
    empStatus2 &&
    !incomeWithEmployee2 &&
    (!paygIncome.length || (empStatus1 && paygIncome.length < 2))
  ) {
    return 'PAYG income type  is Mandatory based on your current employment situation';
  }

  if (
    purchaseUse === 'Personal' &&
    (employmentStatus === EMPTYPE_SELF || employmentStatus2 === EMPTYPE_SELF) &&
    !selfEmployed
  ) {
    return 'Self Employed income is mandatory based on your current employment situation';
  }
  if (purchaseUse === 'Business' && isAbnknown && !selfEmployed) {
    return 'Self Employed income is mandatory based on your current employment situation';
  }
  if (
    purchaseUse === 'Business' &&
    isAbnknown === false &&
    employmentStatus !== EMPTYPE_SELF &&
    !paygIncome.length
  ) {
    return 'PAYG  income type is Mandatory based on your current employment situation';
  }

  return null;
};
