const phoneNumberRuleEvaluator = (rule, value) => {
  if (!value) {
    return rule.errorMessage;
  }
  
  const valueFormatted = value
    .trim()
    .replace(/\s|\(|\)|-/g, '')
    .replace(/^\+61/g, '0')
    .replace(/^61/g, '0');

  const rex = new RegExp(/(^\(?(04|04)\)?[\s|-]*\d{2}[\s|-]*([\s|-]*\d[\s|-]*){6}$)/);

  const result = rex.test(valueFormatted);

  if (!result) {
    return rule.errorMessage;
  }

  return null;
};

export default phoneNumberRuleEvaluator;
