import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import cls from 'classnames';

const useStyles = makeStyles(() => createStyles({
  root: {
    width: '116px',
  },
}));

const BoxSkeletons = ({ btnGroupClass, btnClass }) => {
  const classes = useStyles({});

  return Array.from(new Array(3)).map((item, idx) => (
    <ButtonGroup
      key={idx}
      className={cls('select-field-group', 'pr-3', 'pb-3', btnGroupClass)}
      size="lg"
    >
      <Button className={cls('select-field', btnClass)} disabled>
        <Skeleton
          variant="rect"
          animation="pulse"
          className="skeleton-pulse-placeholder"
          classes={{ root: classes.root }}
        />
      </Button>
    </ButtonGroup>
  ));
};

export default BoxSkeletons;
