const error = (message, error) => {
   try {
      let expText = error ? error.toString() : '';
      let errMsg = `${message} ${expText}`.trim();
      let event = getLogEvent(errMsg, 'error')
      submit(event);
   }
   catch (ex) {
      console.warn(ex);
   }
};

const warn = (message) => {
   try {
      let event = getLogEvent(message, 'warning')
      submit(event);
   }
   catch (ex) {
      console.warn(ex);
   }
};

const info = (message) => {
   try {
      let event = getLogEvent(message, 'info')
      submit(event);
   }
   catch (ex) {
      console.warn(ex);
   }
};

const debug = (message) => {
   try {
      let event = getLogEvent(message, 'debug')
      submit(event);
   }
   catch (ex) {
      console.warn(ex);
   }
};

const trace = (message) => {
   try {
      let event = getLogEvent(message, 'trace')
      submit(event);
   }
   catch (ex) {
      console.warn(ex);
   }
};
const submit = (event) => {

   const apiBaseUrl = window.env.REACT_APP_LOGGING_API_BASEURL;

   fetch(apiBaseUrl, {
      method: 'post',
      mode: 'no-cors',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, *same-origin, omit
      headers: {
         'Content-Type': 'application/json'
      },
    
      body: JSON.stringify(event) // body data type must match "Content-Type" header
   })
      .catch(error => {
         console.warn(error)
      });

};

const getLogEvent = (message, logLevel) => {

   let agent = window && window.navigator ? window.navigator.userAgent : 'undefined';
   let loc = window && window.location ? window.location.href : 'undefined';
   message = message + `, user agent: ${agent}, url: ${loc}`;

   return {
      message,
      logGroupName: "app",
      logLevel
   };

};

export { error, debug, warn, trace, info }