import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import { Auth } from 'aws-amplify';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { CircularProgress } from '@material-ui/core';
import fieldChanged from '../../Actions/FieldChanged';
import Copyright from '../../components/Copyright';
import logo from '../../../assets/image/stratton-logo-transparent.png';
import { ROUTES } from '../../util/constants';
import Email from '../../../assets/image/Email.svg';
import { setApplicantId } from '../../Actions/authHandler';
import { updateCoginito } from '../../Actions/car';
import './login-details.scss';
import risk from '../../../assets/image/risk.svg';
import success from '../../../assets/image/success.svg';
import personalDetailsBackground from '../../../assets/image/Context_pd.svg';
import queryString from '../../util/queryString';
import { convertToLowercase } from '../../lib/utils';
import { getMaskedNumber } from '../../util/regexpConstants';
import Error from '../../../assets/image/error.svg';
import ModalWindow from '../../components/ModalWindow';
import { regexEmail } from '../../RuleEvaluators/EmailRuleEvaluator';

const SignUpAccount = ({
  mobileNumber,
  onInputChange,
  username,
  updateCoginito,
  setApplicantId,
  error,
  signUpProcessing,
}) => {
  const history = useHistory();
  const onLogoClick = () => {
    window.open('https://www.strattonfinance.com.au/');
  };
  const [codeSent, setCodesent] = useState(signUpProcessing || false);
  const [cognitoUser, setCognitoUser] = useState('');
  const [mobileotp, setmobileOtp] = useState('');
  const [show, setShow] = useState(false);
  const [OTPInvalid, isOTPInvalid] = useState(false);
  const [resendMessage, setResendMessage] = useState(false);
  const [confirmAccount, setConfirmAccount] = useState(false);
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [showError, setShowError] = useState(false);
  const countrycode = '+61';

  function validateForm() {
    return username && username.length > 0 && regexEmail.test(username);
  }

  let formattedmobileNumber = mobileNumber && mobileNumber.replace(/ /g, '');
  if (mobileNumber && mobileNumber.slice(0, 1) === '+') {
    formattedmobileNumber = formattedmobileNumber.substring(3);
  }
  if (mobileNumber && mobileNumber.slice(0, 1) === '0') {
    formattedmobileNumber = formattedmobileNumber.substring(1);
  }
  function intToHex(nr) {
    return nr.toString(16).padStart(2, '0');
  }
  function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues)
      .map(intToHex)
      .join('');
  }

  const handleClose = () => {
    isOTPInvalid(false);
    setResendMessage(false);
    setConfirmAccount(false);
    setShow(false);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    onInputChange('signUpProcessing', true, false);
    username = username.trim();
    onInputChange('username', username.trim(), true);
    onInputChange('error', null, false);

    await Auth.signUp({
      username,
      password: getRandomString(30),

      attributes: {
        email: username, // optional
        phone_number: countrycode.concat(formattedmobileNumber),
      },
    })
      .then(async (resp) => {
        Auth.signIn(username).then((user) => {
          setCognitoUser(user);
          setCodesent(true);
          onInputChange('cognitoUserStatus', true, false);
          onInputChange('signUpProcessing', false, false);
        });
      })
      .catch((err) => {
        if (err.name === 'UsernameExistsException') {
          onInputChange(
            'error',
            'Account already exists with the email address you have entered.',
            false,
          );
        } else {
          onInputChange('error', err.message, false);
        }
        onInputChange('signUpProcessing', false, false);
      });
  }
  const resendConfirmationCode = async () => {
    onInputChange('signUpProcessing', true, false);
    await Auth.signIn(cognitoUser.username)
      .then((user) => {
        setCognitoUser(user);
        setmobileOtp('');
        setResendMessage(true);
        setShow(true);
        setisButtonDisabled(true);
        setSeconds(30);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    onInputChange('signUpProcessing', false, false);
  };

  const RedirectLoginpage = () => {
    history.push(ROUTES.login);
  };
  const handleConfirmationSubmit = (newVal) => {
    setmobileOtp(newVal);
  };

  const confirmOTP = () => {
    if (mobileotp && mobileotp.length === 6) {
      try {
        onInputChange('error', null, false);
        onInputChange('cognitoUserVerify', true, false);
        onInputChange('signUpProcessing', true, false);

        Auth.sendCustomChallengeAnswer(cognitoUser, mobileotp)
          .then(async (data) => {
            onInputChange('error', null, false);
            onInputChange('cognitoUserVerify', false, false);

            try {
              // This will throw an error if the user is not yet authenticated:
              await Auth.currentSession();
              // This will throw an error if the user is not yet authenticated:
              Auth.currentCredentials({
                bypassCache: true,
              }).then(async (user) => {
                await updateCoginito(user.identityId)
                  .then(() => {
                    onInputChange('error', null, false);
                    history.push(ROUTES.dashboard);
                  })
                  .catch((err) => {
                    onInputChange('error', err.message || err, false);
                    // eslint-disable-next-line no-console
                    console.error(err);
                  });

                onInputChange('signUpProcessing', false, false);
                setConfirmAccount(true);
              });
            } catch {
              setmobileOtp('');
              setCodesent(true);
              isOTPInvalid(true);
              setConfirmAccount(false);
              setShow(true);
              onInputChange('signUpProcessing', false, false);
              return false;
            }
          })
          .catch((err) => {
            onInputChange('error', err.message || err, false);
            onInputChange('cognitoUserVerify', false, false);
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err.message);
      }
    }
  };

  const handleUserNameChnage = (event) => {
    onInputChange('username', convertToLowercase(event.target.value), true);
  };

  useEffect(() => {
    const token = queryString('code');
    if (token) {
      setApplicantId(token);
    } else {
      history.push(ROUTES.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isButtonDisabled) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          setisButtonDisabled(false);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });
  const handleBlur = (e) => {
    const email = e.target.value;
    if (email.length > 0 && !regexEmail.test(email.trim())) {
      setShowError(true)
    }
    else {
      setShowError(false)
    }
  }

  return (
    <>
      <div className="login-page-container">
        <div className="login-page-section login_details_section col-12 col-md-4 col-lg-4">
          <div className="stratton-info-nav-container">
            <img
              src={logo}
              alt="Stratton"
              className="pt-3 stratton-info-logo"
              onClick={onLogoClick}
            />
          </div>
          <div className="stratton-info-footer-container">
            <div className="login_details_section">
              <img src={personalDetailsBackground} className="nav-personal" alt="personal" />
            </div>
            <Copyright />
          </div>
        </div>

        <div className="login-page-details col-12 col-md-8 col-lg-8">
          <div className="Need-help py-3">
            Call us on
            <div className="number-font">1300 787 288</div>
          </div>
          <div className="login-page-message">
            <div className="login-page-name">
              Create account with <span className="logo-highlight">Stratton Finance</span>
            </div>
            {error && error.length !== null && (
              <Alert variant="danger" className="error-alert">
                <img src={risk} className="risk-img" alt="risk" />
                <div className="error-message">{error}</div>
                <Button type="button" className="redirect-login mr-4" onClick={RedirectLoginpage}>
                  Log In
                </Button>
              </Alert>
            )}

            {OTPInvalid && (
              <ModalWindow
                message="The Verification code that was entered is either expired or incorrect. Resend/Please try again"
                handleClose={handleClose}
                errorImg={Error}
                showModal={show}
              />
            )}

            {resendMessage && (
              <ModalWindow
                message="We have resent 6-digit verification code to your mobile"
                handleClose={handleClose}
                errorImg={success}
                showModal={show}
              />
            )}
            {confirmAccount && (
              <ModalWindow
                message="Account created successfully!"
                handleClose={handleClose}
                errorImg={success}
                showModal={confirmAccount}
              />
            )}

            <Form noValidate onSubmit={handleSubmit} className="login-form-section">
              {codeSent && (
                <Form.Group controlId="code">
                  <div className="verifyyourself-content3 pb-3">
                    Let's confirm your account using your mobile number
                  </div>
                  <div className="code-sent-message mb-3 p-3">
                    6 digit verification code is sent to {getMaskedNumber(mobileNumber)}
                  </div>

                  {signUpProcessing && (!error || (error && !error.length)) ? (
                    <div className="verification-spinner">
                      <CircularProgress color="inherit" size={48} thickness={4} />
                    </div>
                  ) : (
                    <>
                      <div className="verifyyourself-otp pb-3">
                        <OtpInput
                          onChange={handleConfirmationSubmit}
                          inputStyle="otp-input-field"
                          value={mobileotp}
                          shouldAutoFocus
                          hasErrored
                          numInputs="6"
                          errorStyle="error"
                        />
                      </div>
                      <div className="otp-info-container my-2">
                        <div className="verifyyourself-content6">
                          Didn't receive the code?
                          <button
                            type="button"
                            className="btn btn-link btn-resend"
                            onClick={resendConfirmationCode}
                            disabled={isButtonDisabled}
                          >
                            <span className="verifyyourself-content8">Resend OTP</span>
                          </button>
                          &nbsp;
                          <span>
                            {seconds === 0 ? null : (
                              <span>
                                <b>00: {seconds < 10 ? `0${seconds}` : seconds}</b>
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      <Button
                        block
                        disabled={!mobileotp || (mobileotp && mobileotp.length < 6)}
                        type="button"
                        onClick={confirmOTP}
                        className="btn-primary-call-to-action"
                      >
                        <span className="button-font">Confirm account</span>
                      </Button>
                    </>
                  )}
                </Form.Group>
              )}
              {!codeSent && (
                <>
                  <Form.Group className="login-input-group" controlId="username">
                    <Form.Label className="input-label-font">Email address</Form.Label>
                    <InputGroup className="email-input-group-append">
                      <InputGroup.Prepend className="email-icon-input-append">
                        <img src={Email} alt="email" />
                      </InputGroup.Prepend>
                      <Form.Control
                        type="username"
                        placeholder="Email Address"
                        className="email-border"
                        value={username}
                        onChange={handleUserNameChnage}
                        onBlur={handleBlur}
                      />
                      {showError && (<Form.Control.Feedback type="invalid">
                        Please enter a valid email
                      </Form.Control.Feedback>)}
                    </InputGroup>
                  </Form.Group>
                  <Button
                    block
                    type="submit"
                    disabled={!validateForm() || showError}
                    className="btn-primary-call-to-action"
                  >
                    {signUpProcessing ? (
                      <CircularProgress color="inherit" size={24} thickness={2.6} />
                    ) : (
                      'Create Account'
                    )}
                  </Button>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
      <Copyright mobileFooter />
    </>
  );
};

SignUpAccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(
  (state) => ({
    username: state.account.username,
    error: state.account.error,
    signUpProcessing: state.account.signUpProcessing,
    mobileNumber: state.account.mobile,
  }),
  (dispatch) => ({
    setApplicantId: (token) => dispatch(setApplicantId(token)),
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('account', fieldName, value, scrollToNextFieldOnChage));
    },
    updateCoginito: (cognitoId) => dispatch(updateCoginito(cognitoId)),
  }),
)(SignUpAccount);
