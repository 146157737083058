import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import {
  styleContainer,
  styleTitle,
  styleUploadSubText,
  styleUploadText,
} from '../IdentityDocUpload';
import CustomDropZoneField from '../FileUpload/CustomDropzoneField';
import {
  DOCUMENT_TYPE,
  allowedFileTypes,
  uploadFileSize,
  uploadedVerifiedStatus,
} from '../../util/constants';
import { removeDataType } from '../../containers/Dashboard/DocumentsVerification';
import { filter, get, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { getDocument, getDocumentList, uploadDocument } from '../../Actions/finances';
import { getFileFromBase64, resolvebase64 } from '../../util/helpers';

const TrustDocUpload = ({
  loading,
  setLoading,
  trustDocList,
  imageFiles,
  setImage,
  documentList,
  setDocumentList,
  imageError,
  setImageError,
  uploadDocument,
  getDocumentList,
  getDocument,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(960));
  const remapDocType = (docList) => {
    let currentLinkedEntityRelatedIncExId = '';
    const arrayDocList = sortBy(docList, ['uploadedDate']);
    const updatedList = sortBy(arrayDocList, ['linkedEntityRelatedIncExId'])?.map((fileObj) => {
      let documentType = fileObj.documentType;
      if (fileObj.documentType === DOCUMENT_TYPE.TRUST_DEED) {
        documentType = fileObj.documentType;
        currentLinkedEntityRelatedIncExId = fileObj.linkedEntityRelatedIncExId;
      }
      return { ...fileObj, documentType };
    });
    return updatedList;
  };
  const handleTransformDownloadListToimageFiles = (allDocList, isPartner = false) => {
    const uploadedDocList = filter(allDocList, (doc) =>
      uploadedVerifiedStatus.includes(doc?.status),
    );
    const newImageFiles = {};
    uploadedDocList.forEach((fileObj) => {
      const indexFile = fileObj.fileName.lastIndexOf('.');
      const fileExtension = fileObj.fileName.substring(indexFile + 1);
      const name = fileObj.fileName.substring(0, indexFile);
      let documentType = fileObj.documentType;

      const formattedData = {
        path: fileObj.fileName,
        fileExtension,
        documentId: fileObj.documentId,
        linkedEntityRelatedIncExId: fileObj.linkedEntityRelatedIncExId,
        name,
        documentType,
        status: fileObj.status,
      };
      if (newImageFiles?.[documentType]) {
        newImageFiles[documentType].push({ ...formattedData });
      } else {
        newImageFiles[documentType] = [{ ...formattedData }];
      }
    });
    setImage(newImageFiles);
  };

  const handleOnUpload = (category, isPartner = false, idx = 0, incomeId = '') => async (
    newimagefile = [],
  ) => {
    const categoryDocList = imageFiles?.[category];
    let updatedList = [...(categoryDocList || []), ...newimagefile];
    setLoading({ category, isPartner, idx });
    if (categoryDocList && categoryDocList.length > idx) {
      updatedList = [...categoryDocList];
      updatedList[idx] = { ...categoryDocList[idx], ...newimagefile, status: 'Uploaded' };
    }
    let fileName = '';
    let base64String = '';
    let approvedFileType = allowedFileTypes.find((e) => e === newimagefile[0]?.type);
    if (newimagefile.length > 0) {
      let fileToLoad = newimagefile[0];
      if (fileToLoad.size < uploadFileSize && approvedFileType !== undefined) {
        setImageError({});
        setImage({ ...imageFiles, [category]: updatedList });
        fileName = fileToLoad.name;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(fileToLoad);
        base64String = await resolvebase64(fileReader);
        let fileTypeExtension = newimagefile[0].type.replace(/(.*)\//g, '');

        const payload = {
          fileName: fileToLoad.name,
          documentType: 'Trust deed',
          documentContents: removeDataType(fileTypeExtension, base64String),
        };
        const doclistByType = filter(documentList, ['documentType', category]);

        payload['documentId'] =
          doclistByType?.[idx]?.status === 'Requested' ||
          doclistByType?.[idx]?.status === 'Required'
            ? doclistByType?.[idx]?.contentDocumentId || ''
            : '';
        payload['linkedEntityRelatedIncExId'] = incomeId;
        uploadDocument(payload, isPartner).then(() => {
          getDocumentList(isPartner).then((res) => {
            const { data } = res;
            const updatedDocList = remapDocType(data);
            setDocumentList(updatedDocList);
            handleTransformDownloadListToimageFiles(updatedDocList, isPartner);
            setLoading(false);
          });
        });
      } else if (fileToLoad.size > uploadFileSize) {
        setLoading(false);
        const newImageErrorFiles = {};
        const formattedData = {
          fileName: fileToLoad.name,
          linkedEntityRelatedIncExId: incomeId,
          category,
          error: 'Document size should be less than 8MB',
        };
        newImageErrorFiles[category] = [{ ...formattedData }];
        setImageError(newImageErrorFiles);
        return;
      } else if (approvedFileType === undefined) {
        setLoading(false);
        const newImageErrorFiles = {};
        const formattedData = {
          fileName: fileToLoad.name,
          linkedEntityRelatedIncExId: incomeId,
          category,
          error: 'Incorrect file format',
        };
        newImageErrorFiles[category] = [{ ...formattedData }];
        setImageError(newImageErrorFiles);
        return;
      }
    }
  };
  const handleOnClick = (fileInfo) => {
    const fileDesc = fileInfo[0];
    getDocument(fileDesc.documentId).then((res) => {
      const base64String = res.data;
      const file = getFileFromBase64(base64String, fileDesc.name, fileDesc.fileExtension);
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(file);
      a.download = `${fileDesc.name}.${
        fileDesc.fileExtension === 'jpeg' || fileDesc.fileExtension === 'jpg'
          ? 'jpg'
          : fileDesc.fileExtension === 'png'
          ? 'png'
          : fileDesc.fileExtension === 'heic'
          ? 'heic'
          : 'pdf'
      }`;
      a.click();
    });
  };
  return (
    <Box sx={styleContainer}>
      <Box sx={styleTitle}>TRUST</Box>
      <div className="additional-document-list-wrapper">
        {trustDocList.map((item) => (
          <Grid item xs={mobileView ? 12 : 6} key={item.documentId}>
            <Box sx={styleUploadText}>{item.PQ_Header_Text}</Box>
            <Box sx={styleUploadSubText}>{item.PQ_Field_Text}</Box>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={11}>
                  <CustomDropZoneField
                    handleOnDrop={handleOnUpload(item.documentType)}
                    handleOnClick={handleOnClick}
                    imagefile={imageFiles?.[item.documentType]}
                    disabled={imageFiles?.[item.documentType]?.length}
                    error={get(imageError, `${item.documentType}[0].error`, false)}
                    loading={loading.category === item.documentType}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </div>
    </Box>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    uploadDocument: (doc, isPartner) => dispatch(uploadDocument(doc, isPartner)),
    getDocumentList: (isPartner) => dispatch(getDocumentList(isPartner)),
    getDocument: (documentId) => dispatch(getDocument(documentId)),
  }),
)(TrustDocUpload);
