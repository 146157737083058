import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { CircularProgress } from '@material-ui/core';

import PrivacyConsent from '../../components/privacyConsent';

const PrivacyAcceptance = ({ show, onSubmit, submitInProgress }) => {
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);

  const handleCheckboxChange = (event) => {
    setAcceptPrivacyPolicy(event.target.checked);
  };

  return (
    <Modal show={show} keyboard={false} centered className="dashboard-privacy-container">
      <Modal.Header closeButton={false} className="finances-header-container">
        <Modal.Title className="privacy-header-section">
          <div className="welcome">Welcome!</div>
          <div>Privacy Policy & Collection Statement</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="privacy-body-section">
        <div className="privacy-subtitle">
          Please review and accept before proceeding with your quote.
        </div>
        <PrivacyConsent />
        <div className="privacy-policy">
          <label className="checkbox-container checkbox-color" htmlFor="acceptPrivacyPolicy">
            Stratton Finance regularly provides information and offers to our customers. By
            submitting this form, you understand that your personal information is collected, used
            and disclosed in accordance with our{' '}
            <a
              className="btn-policy-path"
              href="https://www.strattonfinance.com.au/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Privacy & Credit Reporting Policy{' '}
            </a>
            <input
              type="checkbox"
              checked={acceptPrivacyPolicy}
              value={acceptPrivacyPolicy}
              onChange={handleCheckboxChange}
              name="acceptPrivacyPolicy"
              id="acceptPrivacyPolicy"
            />
            <span className="checkmark" />
          </label>
        </div>
        <div className="email-quote py-3">
          <button
            type="button"
            data-test="lets-get-started-btn "
            className="letsgetstarted-btn submit-privacy"
            onClick={onSubmit}
            disabled={!acceptPrivacyPolicy || submitInProgress}
          >
            {submitInProgress ? (
              <CircularProgress color="inherit" size={24} thickness={2} />
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

PrivacyAcceptance.propTypes = {
  show: PropTypes.bool.isRequired,
  submitInProgress: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default PrivacyAcceptance;
