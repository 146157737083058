import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import './TextCheckList.scss';

const TextCheckList = ({}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div
        style={{
          maxWidth: '15rem',
          textAlign: 'justify',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: 'flex' }} className="gridItemContainer">
            <div className="icon-container">
              <CheckIcon className="icon" />
            </div>

            <span className="label"> No Impact on credit score</span>
          </Grid>
          <Grid item xs={12} className="gridItemContainer">
            {' '}
            <div className="icon-container">
              <CheckIcon className="icon" />
            </div>
            <span className="label"> No upfront fees</span>
          </Grid>
          <Grid item xs={12} className="gridItemContainer">
            {' '}
            <div className="icon-container">
              <CheckIcon className="icon" />
            </div>
            <span className="label"> Quick turn around time</span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TextCheckList;
