import { connect } from 'react-redux';
import IncomeDetailsNew from './IncomeDetailsNew';
import {
  addIncome,
  updateIncome,
  deleteIncome,
  addSpouseIncome,
  updateSpouseIncome,
  deleteSpouseIncome,
  completeIncomeDetails,
  initialiseIncomeDetails,
  income,
  coApplicantIncome,
  coApplicantdeleteApi,
  deleteGeneric,
  addAsset,
} from '../../../../Actions/finances';
import { RetrieveOpportunity, reQuote } from '../../../../Actions/car';
import { validationErrors, noErrors } from '../../../../Actions/errorHandler';
import { setCurrentTab } from '../../../../Actions/tab';
import { createSpouseCoApplicants } from '../../../../Actions/car';
import { scrollToNextClicked } from '../../../../Actions/ScrollToNextClicked';
import scrollToNextField from '../../../../util/ScrollToNextField';

const mapStateToProps = (state) => ({
  income: state.applicants.income || [],
  assets: state.applicants.assets || [],
  spouseincome: state.applicants.spouseincome || [],
  incomeDetails: state.applicants.incomeDetails,
  pendingIncomeSave: state.applicants.pendingIncomeSave,
  initAssetList: state.applicants.initAssetList,
  employmentStatus: state.applicants.employmentStatus,
  errorMessage: state.errorHandler.errorMessage,
  addSpouse: state.applicants.addSpouse,
  currentEmploymentStatus: state.applicants.employmentStatus,
  isAbnknown: state.applicants.isAbnknown,
  purchaseUse: state.car.purchaseUse,
  employerNameOption1: state.applicants.employerName,
  employerNameOption2: state.applicants.employerName2,
  employmentStatus2: state.applicants.employmentStatus2,
  entityName: state.applicants.entityName,
  currentEmpId: state.applicants.currentEmpId,
  currentEmpId2: state.applicants.currentEmpId2,
  spouseCoApplicantId: state.applicants.spouseCoApplicantId,
  payslipNotVerified: state.applicants.payslipNotVerified,
  spouseEmploymentType: state.applicants.spouseEmploymentType,
  residentialStatus: state.applicants.residentialStatus,
  opportunityId: state.applicants.opportunityId,
  ValidationErrorFlag:
    state.errorHandler.ValidationErrorFlag && state.errorHandler.errorCategory === 'INCOMES',
});

const mapDispatchToProps = (dispatch) => ({
  addIncome: (incomeData) => {
    dispatch(addIncome(incomeData));
  },
  updateIncome: (incomeData) => {
    dispatch(updateIncome(incomeData));
  },
  deleteIncome: (incomeData) => {
    dispatch(deleteIncome(incomeData));
  },
  addAsset: (assetData) => {
    dispatch(addAsset(assetData));
  },
  deleteIncomeApi: (deleteincome) => dispatch(deleteGeneric(deleteincome)),
  coApplicantdeleteApi: (deleteCoapplicant) => dispatch(coApplicantdeleteApi(deleteCoapplicant)),
  addSpouseIncome: (spouseincomeData) => {
    dispatch(addSpouseIncome(spouseincomeData));
  },
  updateSpouseIncome: (spouseincomeData) => {
    dispatch(updateSpouseIncome(spouseincomeData));
  },
  reQuote: () => dispatch(reQuote()),
  deleteSpouseIncome: (spouseincomeData) => {
    dispatch(deleteSpouseIncome(spouseincomeData));
  },
  validationCheck: (error) => {
    dispatch(validationErrors(error, 'INCOMES'));
    scrollToNextField('finances-details', true, true);
  },
  completeIncomeDetails: () => {
    dispatch(completeIncomeDetails());
    dispatch(scrollToNextClicked('finance_details', 'asset-details', [], false, false, true));
  },
  setTab: () => dispatch(setCurrentTab('finance_details')),
  initialiseIncomeDetails: () => {
    dispatch(initialiseIncomeDetails());
  },
  retrieveOpportunity: () => dispatch(RetrieveOpportunity()),
  incomeApi: () => dispatch(income()),
  spouseIncomeApi: () => dispatch(coApplicantIncome()),
  createSpouseApplicants: () => dispatch(createSpouseCoApplicants()),
  noErrors: () => dispatch(noErrors()),
  financeValidationerrors: (error, props) => dispatch(validationErrors(error, 'INCOMES')),
});

const IncomeFinanceDetailsNew = connect(mapStateToProps, mapDispatchToProps)(IncomeDetailsNew);

export default IncomeFinanceDetailsNew;
