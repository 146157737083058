import React, { useState, useLayoutEffect, useEffect } from 'react';
import _ from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NavRight from '../../assets/image/right-dark.svg';
import NavPrev from '../../assets/image/Arrow-Left.svg';

import './image-carousel.scss';

const ImageCarousel = ({ images }) => {
  const theme = useTheme();
  let indexval = 6;
  const showtwoImages = useMediaQuery('(max-width:499.95px)');
  const showthreeImages = useMediaQuery('(min-width:500px)');
  const showfourImages = useMediaQuery('(min-width:600px)');
  const showfiveImages = useMediaQuery('(min-width:786px)');
  const showsixImages = useMediaQuery('(min-width:1400px)');

  const getImages = () => {
    if (showsixImages) {
      indexval = 6;
      return _.slice(images, 0, 6);
    }
    if (showfiveImages) {
      indexval = 5;
      return _.slice(images, 0, 5);
    }
    if (showfourImages) {
      indexval = 4;
      return _.slice(images, 0, 4);
    }
    if (showthreeImages) {
      indexval = 3;
      return _.slice(images, 0, 3);
    }
    if (showtwoImages) {
      indexval = 2;
      return _.slice(images, 0, 2);
    }
    return _.slice(images, 0, 3);
  };

  const [imageList, setImageList] = useState(getImages());

  const [index, setIndex] = useState(indexval);

  useEffect(() => {
    setImageList(getImages());
  }, [showthreeImages, showfourImages, showfiveImages, showsixImages, showtwoImages, images]);

  const onClickforward = () => {
    if (index >= images.length) {
      setImageList(getImages());
      setIndex(indexval);
    } else {
      setIndex(index + 1);
    }
  };
  const clickBack = () => {
    if (index <= indexval) {
      setImageList(getImages());
    } else {
      setIndex(index - 1);
    }
  };

  useEffect(() => {
    setImageList(_.slice(images, index - indexval, index));
  }, [index]);

  return (
    <div className="display-vendors">
      <div className="lenders-logo-container">
        <img src={NavPrev} onClick={clickBack} className="right-left-arrw" alt="previous" />
        {imageList.map((img, idx) => (
          <img key={img.id} className="image-carousel" src={img.value} alt="random" />
        ))}
        <img src={NavRight} onClick={onClickforward} alt="next" className="right-left-arrw" />
      </div>
    </div>
  );
};

export default ImageCarousel;
