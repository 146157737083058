import moment from 'moment';

const noFutureDateRuleEvaluator = (rule, value) => {
  if (
    moment()
      .startOf('day')
      .diff(value, 'days') < 0
  ) {
    return rule.errorMessage;
  }
  if (
    moment()
      .startOf('day')
      .diff(value, 'days') > 0
  ) {
    return null;
  }
};

export default noFutureDateRuleEvaluator;
