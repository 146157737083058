/* eslint-disable react/prop-types */
import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';
import cls from 'classnames';

const renderSelectButtonField = ({
  input,
  options,
  label,
  required,
  meta: { touched, error },
  classes = {},
}) => (
  <div className={classes.container}>
    {options.map((o) => (
      <div className="select-button-field" key={o.value}>
        <input
          type="button"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...input}
          onClick={input.onBlur}
          value={o.value}
          checked={o.value === input.value}
          className={cls(
            classes.inputButton,
            o.value === input.value && 'is-selected',
            /*  o.value === input.value && <span className="oi oi-circle-check" /> */
            o.name && o.name.indexOf(' ') === -1 && o.value.length > 7 && 'lengthy-label',
          )}
        />
        {/*   {o.value === input.value && <span className="oi oi-circle-check" />} */}
      </div>
    ))}
    {touched && error && (
      <div
        style={{
          width: '100%',
          marginTop: '0.25rem',
          fontSize: '80%',
          color: '#dc3545',
        }}
      >
        {error}
      </div>
    )}
  </div>
);

export default renderSelectButtonField;
