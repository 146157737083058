/* eslint-disable react/prop-types */
import React from 'react';
import cls from 'classnames';
import filter from 'lodash/filter';
import capitalize from 'lodash/capitalize';
import rightnormal from '../../../assets/image/right-normal.svg';
import FactoryOption from '../../components/FactoryOption';
import './prePopulateFactory.scss';

const PrePopulateFactoryOptions = ({
  factoryOptions,
  vehicleMake,
  vehicleModel,
  onInputChange,
  noWhiteBackground,
  containedSection,
  factoryDataAlign,
  factoryOptionsComplete,
  nvicMake,
  nvicModel,
}) => {
  const updateSelectedOption = (selectedOptions) => {
    onInputChange('factoryOptions', selectedOptions, false);
  };

  const unCheckSelectedOption = (uncheckOption) => {
    const updatedOptions = factoryOptions.map((option) => ({
      ...option,
      selected: option.optionName === uncheckOption.optionName ? false : option.selected,
    }));
    updateSelectedOption(updatedOptions);
  };
  const noFactoryOptions = (e) => {
    e.preventDefault();
    onInputChange('nofactoryOptions', true, false);
  };
  const selectedfactoryData = filter(factoryOptions, ['selected', true]);
  return (
    <div
      className={cls(
        'form-row col-md-10 col-lg-9 col-12  car-details-options-section',
        factoryDataAlign && factoryOptionsComplete && 'fit-screen ',
        containedSection && 'contained-section',
      )}
    >
      <div className={cls(containedSection && 'car-details-any-factory', 'any-factory-options')}>
        Any factory options that you would like to add?
      </div>
      <div className={cls(containedSection && 'car-details-factory-a', 'factory-options-a')}>
        {factoryOptions.length}
        &nbsp;Factory option(s) available for the&nbsp;
        <b>
          {vehicleMake.name || capitalize(nvicMake)}
          {` ${vehicleModel.name || capitalize(nvicModel)}`}
        </b>
      </div>

      {!factoryOptions && (
        <div className="no-factory-options">
          <div className="factory-options-message">
            Our records show that there are no factory/dealer options available for this vehicle.
          </div>
          <button
            type="button"
            className="continue"
            onClick={(e) => {
              noFactoryOptions(e);
            }}
          >
            <span className="continue-name">Continue</span>
            <span className="image">
              <img src={rightnormal} alt="right" className="right-icon" />
            </span>
          </button>
        </div>
      )}
      {!!factoryOptions && (
        <>
          <div
            className={cls(
              'rectangle',
              noWhiteBackground && 'nowhite-background',
              selectedfactoryData && selectedfactoryData.length > 0 && 'rectangle-border',
            )}
          >
            {selectedfactoryData && selectedfactoryData.length > 0 && (
              <div className="order-list-factory-options">
                {factoryOptions &&
                  factoryOptions.map(
                    (option) =>
                      option.selected && (
                        <li className="list-factory-option" key={option.optionName}>
                          <div className="option-name">{option.optionName}</div>
                          <button
                            data-test="factory-options-unselect"
                            type="button"
                            className="close"
                            onClick={() => {
                              unCheckSelectedOption(option);
                            }}
                          >
                            &times;
                          </button>
                        </li>
                      ),
                  )}
              </div>
            )}
            {selectedfactoryData && selectedfactoryData.length === 0 && (
              <FactoryOption
                fields={factoryOptions}
                onSave={updateSelectedOption}
                addFactoryOption
              />
            )}
          </div>
          <div className="factory-options-add">
            {selectedfactoryData && selectedfactoryData.length > 0 && (
              <FactoryOption
                fields={factoryOptions}
                onSave={updateSelectedOption}
                addSelectedItems
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PrePopulateFactoryOptions;
