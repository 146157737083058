import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import orderBy from 'lodash/orderBy';
import React, { useState } from 'react';

const renderSelectField = ({
  input,
  options,
  label,
  id,
  required,
  disabled,
  meta: { touched, error },
}) => {
  const sortedOptions = orderBy(options, ['name', 'value'], ['asc', 'asc']);
  return (
    <Form.Group className="redux-render-field">
      <Form.Control
        {...input}
        id={id}
        as="select"
        disabled={disabled}
        isInvalid={touched && error}
        required={required}
      >
        <option value="">Select</option>
        {sortedOptions.map((type) => {
          if (type.selected) {
            return (
              <option value={type.value} key={type.value} selected>
                {type.name}
              </option>
            );
          }

          return (
            <option value={type.value} key={type.value}>
              {type.name}
            </option>
          );
        })}
      </Form.Control>

      {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  );
};

export default renderSelectField;
