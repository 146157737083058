
export const regexEmail = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const emailRuleEvaluator = (rule, value) => {
  if (!value) {
    return rule.errorMessage;
  }
  if (value.endsWith('.')) {
    return rule.errorMessage;
  }
  if (!regexEmail.test(value)) {
    return rule.errorMessage;
  }
  return null;
};

export default emailRuleEvaluator;
