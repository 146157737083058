import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Card, Container, Col, Button, Modal, CardColumns, Form } from 'react-bootstrap';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import NumberFormat from 'react-number-format';
import { CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import cls from 'classnames';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import Copyright from '../../components/Copyright';
import {
  ROUTES,
  FREQ_OPTIONS,
  TERM,
  YEARS,
  LENDER_IMAGE_LIST,
  APPLICATION_STRUCTURE,
  APPLICANT_TYPE,
} from '../../util/constants';

import CheckSuccess from '../../../assets/image/nav-check.svg';
import Edit from '../../../assets/image/Edit_Dashboard.svg';
import PopulateQuoteDetails from './PopulateQuoteDetails';
import AmountRangeSelector from '../../components/AmountRangeSelector';
import Car from '../../../assets/image/Car.svg';
import Tooltip from '../../components/Tooltip';

import DropDown from '../../components/DropDown';
import TextField from '../../components/TextField';
import NoObligation from './NoObligation';
import './compare-loan-options.scss';
import ImageCarousel from '../../components/ImageCarousel';
import getLenderLogo from '../../util/getLenderLogo';
import processGTMevent from '../../util/processGTMEvent';

const CompareLoanOptions = (props) => {
  const {
    fullName,
    onInputChange,
    depositAmount,
    purchasePrice,
    purchasePriceUpdated,
    setTab,
    carCondition,
    getAllVehicleYears,
    manufacturingYear,
    vehicleYears,
    getValidationErrorsForField,
    termVal,
    selectFreq,
    residualAmount,
    termValUpdated,
    updatedYear,
    onCarFieldChange,
    depositUpdated,
    residualUpdated,
    lenderSelected,
    updateQuoteLenderParams,
    reQuote,
    setTabComplete,
    currentTab,
    handleHTTPError,
    purposeOfuse,
    selectProductDetails,
    topNLatestReQuote,
    setApplicationtructure,
    setApplicationStrucutureStepper,
    residualSwitch,
    onInputChangeSelectedLender,
    opportunityId,
    assetType,
  } = props;

  useEffect(() => {
    processGTMevent('compareloanoptions', 'pageLoad', 'Page Load');
    if (setTab) {
      setTab('compare_loan_options');
    }
  }, []);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      justifyContent: 'center',
      margin: '50px',
    },
  }));

  useEffect(() => {
    getAllVehicleYears('');
  }, [carCondition, getAllVehicleYears, manufacturingYear]);

  const vehicleYearsformatted = reverse(sortBy(vehicleYears));
  const history = useHistory();
  const theme = useTheme();
  const enableDrawer = useMediaQuery(theme.breakpoints.down(768));

  const [showDrawer, toggleDrawer] = useState(false);
  const [editLoanSection, setloanSection] = useState(false);
  const [quoteInProgress, setQuoteInProgress] = useState(false);
  const [selectedQuoteData, setSelectedQuotedata] = useState();
  const [quoteFilteredData, setQuoteFilteredData] = useState([]);
  const [images, setImages] = useState(LENDER_IMAGE_LIST);
  const [residualAmtVal, setResidualAmtVal] = useState(0);
  const [showWarning, setwarning] = useState(false);
  const [residualPrevVal, setResidualPrevVal] = useState(residualAmtVal);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const continueClick = (optionIdx) => {
    setSelectedQuotedata(quoteFilteredData[optionIdx]);

    onInputChange('lenderSelected', true, false);
  };

  const editLoanSummary = () => {
    setloanSection(true);
  };

  const handleClose = () => {
    onInputChange('lenderSelected', false, false);
  };
  const getTopLatestRequote = (retainOldResidualAmount = false) => {
    setQuoteInProgress(true);
    topNLatestReQuote()
      .then((resp) => {
        const quoteData = resp.data.quotes.map((quote) => ({
          ...quote,
          lenderlogo: getLenderLogo(quote.lender),
        }));

        setQuoteFilteredData(quoteData);

        if (!retainOldResidualAmount) {
          const minResidualVal = residualSwitch
            ? resp?.data?.quotes.reduce(
                (min, itemNext) =>
                  itemNext.maxResidualAmount < min ? itemNext.maxResidualAmount : min,
                resp?.data?.quotes[0].maxResidualAmount,
              )
            : 0;
          setResidualAmtVal(minResidualVal);
          onInputChange('residualUpdated', minResidualVal, false);
        }

        setQuoteInProgress(false);
      })
      .catch((e) => {
        history.push(ROUTES.financeComplete);
        setQuoteInProgress(false);
      });
  };

  const acknowledgeSelectedOption = () => {
    // api call to select lender
    setSubmitInProgress(true);
    onInputChangeSelectedLender(selectedQuoteData.lender);
    onInputChange('monthlyRepayments', selectedQuoteData.monthlyRepayments, false);
    processGTMevent('compareloanoptions', 'selectLender', selectedQuoteData.lender);
    let applicationStructure;
    if (
      (selectedQuoteData.singleApplicantPassServ &&
        !selectedQuoteData.spousePassServ &&
        !selectedQuoteData.coApplicantPassServ) ||
      (selectedQuoteData.singleApplicantPassServ && selectedQuoteData.spousePassServ) ||
      (selectedQuoteData.singleApplicantPassServ && selectedQuoteData.coApplicantPassServ)
    ) {
      applicationStructure = APPLICATION_STRUCTURE.singleApplicant;
    } else if (
      !selectedQuoteData.singleApplicantPassServ &&
      selectedQuoteData.spousePassServ &&
      selectedQuoteData.coApplicantPassServ
    ) {
      applicationStructure = APPLICATION_STRUCTURE.singleApplicantSpouse;
    } else if (
      !selectedQuoteData.singleApplicantPassServ &&
      !selectedQuoteData.spousePassServ &&
      selectedQuoteData.coApplicantPassServ
    ) {
      applicationStructure = APPLICATION_STRUCTURE.coApplicant;
    } else {
      applicationStructure = APPLICATION_STRUCTURE.singleApplicant;
    }

    const selectedProduct = {
      selectedLenderProductCode: selectedQuoteData.lenderProductCode,
      applicationstructure: applicationStructure,
    };

    selectProductDetails(selectedProduct)
      .then(() => {
        getTopLatestRequote(true);
        setTabComplete(currentTab);
        setApplicationtructure(applicationStructure);
        setTabComplete('compare_loan_options');
        localStorage.setItem(`pageCompleted${opportunityId}`, 'compare-loan-options');
        setApplicationStrucutureStepper(applicationStructure);
        history.push(`${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.primary}`);
        setSubmitInProgress(false);
      })
      .catch((error) => {
        history.push(ROUTES.financeComplete);
        setSubmitInProgress(false);
        setQuoteInProgress(false);
      });
  };

  useEffect(() => {
    getTopLatestRequote();
  }, []);

  // display only images which are not showed in list
  useEffect(() => {
    const clonedImages = _.clone(images);
    _.remove(clonedImages, (imageData) => _.find(quoteFilteredData, ['lender', imageData.id]));
    setImages(clonedImages);
  }, [quoteFilteredData]);

  const cancelUpdatedValues = () => {
    onInputChange('purchasePriceUpdated', purchasePrice, false);
    onInputChange('depositUpdated', depositAmount, false);
    onInputChange('termValUpdated', termVal, false);
    onCarFieldChange('updatedYear', manufacturingYear, false);
    onInputChange('residualUpdated', residualPrevVal || residualAmtVal, false);
    setloanSection(false);
  };
  const saveUpdatedLoanValues = () => {
    setQuoteInProgress(true);
    setResidualPrevVal(residualUpdated);
    const dataChanged = !!(
      purchasePrice !== purchasePriceUpdated ||
      depositAmount !== depositUpdated ||
      termVal !== termValUpdated ||
      manufacturingYear !== updatedYear ||
      residualAmtVal !== residualUpdated
    );

    onCarFieldChange('purchasePrice', purchasePriceUpdated, false);
    onCarFieldChange('depositAmount', depositUpdated, false);
    onInputChange('termVal', termValUpdated, false);
    onCarFieldChange('manufacturingYear', updatedYear, false);

    let residualDataChanged;
    setwarning(false);
    if (residualAmtVal != residualUpdated) residualDataChanged = true;
    if (residualUpdated >= residualAmtVal && residualDataChanged) {
      setwarning(true);
    }
    setResidualAmtVal(residualUpdated);

    const saveLenderDataValues = [];

    let setMinMaxResidual;

    quoteFilteredData.map((quoteVal) => {
      if (residualUpdated >= quoteVal.maxResidualAmount) {
        setMinMaxResidual = quoteVal.maxResidualAmount;
      } else {
        setMinMaxResidual = residualUpdated;
      }
      if (residualDataChanged) {
        saveLenderDataValues.push({
          lenderProductCode: quoteVal.lenderProductCode,
          lender: quoteVal.lender,
          residualAmount: setMinMaxResidual,
          residualpercent: null,
        });
      }
    });
    if (dataChanged) {
      const qualifyingDetails = {
        loanDetails: {
          loanAmount: String(purchasePriceUpdated - depositUpdated),
          purchasePrice: String(purchasePriceUpdated),
          depositAmount: String(depositUpdated) || '0',
          term: String(termValUpdated && termValUpdated * 12) || '60',
        },
        vehicleDetails: {
          year: updatedYear,
          purposeOfUse: purposeOfuse,
        },
        lenderParamDetails: saveLenderDataValues,
      };

      // api call for updating data
      updateQuoteLenderParams(qualifyingDetails)
        .then(() => {
          getTopLatestRequote(true);
        })
        .catch((error) => {
          history.push(ROUTES.financeComplete);
          setQuoteInProgress(false);
        });
    }

    setloanSection(false);
  };

  const updateAmountFields = (fieldName, value) => {
    const purchasePriceMinVal = 5000;
    const purchasePriceMaxVal = 250000;
    const depositPriceMinVal = 0;
    const depositPriceMaxVal = purchasePriceUpdated - 5000;
    let updatedValue;
    if (fieldName === 'purchasePriceUpdated') {
      if (value < purchasePriceMinVal) {
        updatedValue = purchasePriceMinVal;
      } else if (value > purchasePriceMaxVal) {
        updatedValue = purchasePriceMaxVal;
      } else {
        updatedValue = value;
      }

      onInputChange('purchasePriceUpdated', parseFloat(updatedValue).toFixed(2), false);

      onInputChange(
        'residualUpdated',
        parseFloat(0.4 * (updatedValue > 0 ? updatedValue : purchasePrice)).toFixed(2),
        false,
      );
    }

    if (fieldName === 'depositUpdated') {
      if (value < depositPriceMinVal || !value) {
        updatedValue = depositPriceMinVal;
      } else if (value > depositPriceMaxVal) {
        updatedValue = depositPriceMaxVal;
      } else {
        updatedValue = value;
      }
      onInputChange('depositUpdated', parseFloat(updatedValue).toFixed(2), false);
    }
  };
  {
    const classes = useStyles();
    return (
      <>
        <div className="estimateRepayments-container compare-loan-opt-cont  ">
          <div className="estimateRepayments-section  compare-loan-opt-sec col-12 col-md-4 col-lg-4">
            <div className="stratton-info-nav-container">
              <div className="step-content-section">
                <div className="nav-animation">
                  <div className="nav-animation-line">
                    <div className="checksuccess-circle">
                      <img src={CheckSuccess} className="nav-checksuccess" alt="car" />
                    </div>
                    <img
                      src={Car}
                      className="nav-animation-car with-animation nav-animation-compare-loan"
                      alt="car"
                    />
                  </div>
                </div>
                <div className="stepper-contents">
                  <div className="steps-section">
                    <div className="step enabled">STEP 1</div>
                    <div className="step-message enabled ">Repayments estimate</div>
                  </div>
                  <div className="steps-section">
                    <div className="step enabled">STEP 2</div>
                    <div className="step-message enabled">Compare loan options</div>
                  </div>
                  <div className="steps-section">
                    <div className="step disabled">STEP 3</div>
                    <div className="step-message disabled">Get approved</div>
                  </div>{' '}
                  <div className="steps-section">
                    <div className="step disabled">STEP 4</div>
                    <div className="step-message disabled">Prepare for Settlement</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="stratton-info-footer-container">
              <Copyright />
            </div>
          </div>
          <div className="estimateRepayments-page-details compare-loan-option-details col-12 col-md-8 col-lg-8  ">
            <div className="compare-loan-options-page">
              <div className="compare-options-caption">
                Great!&nbsp;
                <span className="compare-option-heading">Choose loan options</span>
              </div>
              <Card className="compare-loan-card">
                <Card.Header className="compare-loan-header">
                  <div>Loan Summary</div>
                  <button
                    type="button"
                    className="quote-estimate-edit btn btn-link"
                    disabled={editLoanSection || quoteInProgress}
                    onClick={editLoanSummary}
                  >
                    Edit
                  </button>
                </Card.Header>
                <Card.Body className="compare-loan-body">
                  <>
                    <div className="compare-loansummary-section">
                      {!editLoanSection && (
                        <>
                          <div className="compare-loan-section1">
                            <div className="loan-amount-val">
                              <NumberFormat
                                displayType="text"
                                value={purchasePrice}
                                thousandSeparator
                                decimalScale={2}
                                prefix="$"
                              />
                            </div>
                            <div className="compare-loanamt-subtitle">Purchase price</div>
                          </div>
                          <div className="compare-loan-section1">
                            <div className="loan-amount-val">
                              <NumberFormat
                                displayType="text"
                                value={depositAmount}
                                decimalScale={2}
                                thousandSeparator
                                prefix="$"
                              />
                            </div>
                            <div className="compare-loanamt-subtitle">Deposit/Trade in</div>
                          </div>

                          <div className="compare-loan-section1">
                            <div className="loan-amount-val">
                              <NumberFormat
                                displayType="text"
                                value={purchasePrice - depositAmount}
                                thousandSeparator
                                decimalScale={2}
                                prefix="$"
                              />
                            </div>
                            <div className="compare-loanamt-subtitle">Your loan amount</div>
                          </div>
                          <div className="compare-loan-section1">
                            <div className="loan-amount-val">{manufacturingYear}</div>
                            <div className="compare-loanamt-subtitle">Manufacture Year</div>
                          </div>
                          <div className="compare-loan-section1">
                            <div className="loan-amount-val">
                              {termVal}
                              &nbsp;Years
                            </div>
                            <div className="compare-loanamt-subtitle">Loan term</div>
                          </div>
                          <div className="compare-loan-section1">
                            <div className="loan-amount-val">
                              <NumberFormat
                                displayType="text"
                                value={residualUpdated || residualAmtVal}
                                thousandSeparator
                                decimalScale={2}
                                prefix="$"
                              />
                            </div>
                            <div className="compare-loanamt-subtitle">
                              Residual{' '}
                              {showWarning && (
                                <span>
                                  <Tooltip
                                    displayWarning
                                    title="Exceeded residual amount"
                                    message="The residual you entered may be higher than allowed for some products."
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                          <DropDown
                            fieldName="selectFreq"
                            value={selectFreq}
                            getvalidationerrors={getValidationErrorsForField}
                            label="Repayments"
                            className="rego-state"
                            onChange={onInputChange}
                            colxs="12"
                            colsm="6"
                            colmd="6"
                            options={
                              <>
                                <option value="" hidden>
                                  Choose...
                                </option>
                                {FREQ_OPTIONS.map((freq) => (
                                  <option key={freq.name} value={freq.value}>
                                    {freq.name}
                                  </option>
                                ))}
                              </>
                            }
                          />
                        </>
                      )}
                    </div>

                    {editLoanSection && (
                      <>
                        <div className="compare-loansummary-edit-section">
                          <div className="compare-loan-section1">
                            <div className="row input-container loan-amount-val updated-loan-val">
                              <TextField
                                fieldName="purchasePriceUpdated"
                                value={parseFloat(purchasePriceUpdated).toFixed(2)}
                                type="number"
                                className="loan-amount-val updated-loan-val"
                                getvalidationerrors={getValidationErrorsForField}
                                onChange={updateAmountFields}
                                inline
                              />
                            </div>
                            <div className="compare-loanamt-subtitle">Purchase price</div>
                          </div>
                          <div className="compare-loan-section1">
                            <TextField
                              fieldName="depositUpdated"
                              value={parseFloat(depositUpdated).toFixed(2) || 0}
                              type="number"
                              className="loan-amount-val updated-loan-val"
                              getvalidationerrors={getValidationErrorsForField}
                              onChange={updateAmountFields}
                              inline
                            />
                            <div className="compare-loanamt-subtitle">Deposit price/Trade In</div>
                          </div>
                          <div className="compare-loan-section1">
                            <div className="loan-amount-val">
                              <NumberFormat
                                displayType="text"
                                decimalScale={2}
                                value={purchasePriceUpdated - depositUpdated}
                                thousandSeparator
                                prefix="$"
                              />
                            </div>
                            <div className="compare-loanamt-subtitle">Your loan amount</div>
                          </div>
                          <div className="compare-loan-section1">
                            <DropDown
                              fieldName="updatedYear"
                              value={updatedYear}
                              defaultval={manufacturingYear}
                              getvalidationerrors={getValidationErrorsForField}
                              onChange={onCarFieldChange}
                              className="loan-amount-val updated-loan-val"
                              colxs="12"
                              colsm="6"
                              colmd="6"
                              options={
                                <>
                                  <option value="" hidden>
                                    Choose...
                                  </option>
                                  {vehicleYearsformatted.map((years) => (
                                    <option key={years} value={years}>
                                      {years}
                                    </option>
                                  ))}
                                </>
                              }
                            />
                            <div className="compare-loanamt-subtitle">Manufacture Year</div>
                          </div>
                          <div className="compare-loan-section1">
                            <DropDown
                              fieldName="termValUpdated"
                              value={termValUpdated}
                              defaultval={termVal}
                              getvalidationerrors={getValidationErrorsForField}
                              onChange={onInputChange}
                              className="loan-amount-val updated-loan-val"
                              colxs="12"
                              colsm="6"
                              colmd="6"
                              options={
                                <>
                                  <option value="" hidden>
                                    Choose...
                                  </option>
                                  {TERM.map((terms) => (
                                    <option key={terms.name} value={terms.value}>
                                      {terms.name}
                                    </option>
                                  ))}
                                </>
                              }
                            />

                            <div className="compare-loanamt-subtitle">Loan term</div>
                          </div>
                          {/* residual  amount need to be populated and displayed.added dummy value fpor now */}
                          <div className="compare-loan-section1">
                            <AmountRangeSelector
                              onChange={onInputChange}
                              fieldName="residualUpdated"
                              defaultValue={residualUpdated}
                              getvalidationerrors={getValidationErrorsForField}
                              minValue={0}
                              maxValue={
                                0.4 *
                                (purchasePriceUpdated > 0 ? purchasePriceUpdated : purchasePrice)
                              }
                              step={100}
                              formGroupClass="mx-0 p-0"
                              setRangeWidth
                            />
                            <div className="compare-loanamt-subtitle">Residual</div>
                          </div>
                        </div>
                        <div className="update-align-btn">
                          <Button
                            className="lender-btn-canecel"
                            onClick={cancelUpdatedValues}
                            type="button"
                          >
                            Cancel changes
                          </Button>
                          <Button
                            className="lender-btn-canecel lender-btn-save"
                            onClick={saveUpdatedLoanValues}
                            type="button"
                            disabled={purchasePriceUpdated - depositUpdated <= 0}
                          >
                            {quoteInProgress ? (
                              <CircularProgress color="inherit" size={10} thickness={1.6} />
                            ) : (
                              'Save changes'
                            )}
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                </Card.Body>
              </Card>
              {quoteInProgress ? (
                <Card className="update-loan-details">
                  <div className="loan-options-message">
                    Tailoring the best loan options. Please wait!
                  </div>
                  <div className={classes.root}>
                    <CircularProgress disableShrink size={45} thickness={4.6} />
                  </div>
                </Card>
              ) : (
                <div className="populate-lenders">
                  <CardColumns className="lender-card-columns">
                    {quoteFilteredData.map((lenderDetails, idx) => (
                      <PopulateQuoteDetails
                        optionVal={idx}
                        lenderDetails={lenderDetails}
                        onSelect={continueClick}
                        selectFreq={selectFreq}
                        key={lenderDetails.Id}
                      />
                    ))}
                  </CardColumns>
                </div>
              )}

              <ImageCarousel images={images} />
              {lenderSelected && (
                <>
                  <Modal
                    dialogClassName="save-model-layout lender-option-acknowledge"
                    show
                    onHide={handleClose}
                    centered
                  >
                    <Modal.Body className="save-container save-acknowledgement">
                      <Container>
                        <div className=" acknowledgment-title save-title">Next steps</div>
                        <div className="password-remember">
                          <div className=" mb-0 checkbox-container acknowledge-message">
                            In order to submit your application to the lender for approval, we will
                            need additional information and documents to support your application.
                            <br />
                            <br />
                            By selecting a loan option, Stratton Finance will <b>not</b> impact your
                            credit score. Your credit score will only be checked once a Lender has
                            reviewed your application.
                          </div>
                        </div>
                      </Container>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                      <div className="acknowledge-footer">
                        <div className="col-12">
                          <Button className="btn-cancel" onClick={handleClose}>
                            Cancel
                          </Button>
                          <Button
                            type="button"
                            className="btn-close btn-val"
                            onClick={acknowledgeSelectedOption}
                          >
                            {submitInProgress ? (
                              <CircularProgress color="inherit" size={24} thickness={2} />
                            ) : (
                              'Continue'
                            )}
                          </Button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </>
              )}

              <NoObligation />
              <div className="disclaimer-footer">
                <div className="disclaimer">Disclaimer</div>
                <div className="disclaim-repay">
                  The monthly repayment estimate is provided by Stratton Finance Pty Ltd (Australian
                  Credit License no. 364340) based on indicative rates available on commercial loss
                  via its lending panel and is not an offer of finance.
                </div>
              </div>
            </div>
          </div>
          <Copyright mobileFooter />
        </div>
      </>
    );
  }
};

export default CompareLoanOptions;
