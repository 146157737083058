/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import CardColumns from 'react-bootstrap/CardColumns';
import bluecheckbox from '../../../assets/image/bluecheckbox.svg';

const NoObligation = () => (
  <>
    <div className="no-obligation">No Obligation</div>

    <div className="oblig-subtitle">
      By selecting an option you are not tied to a specific vehicle or lender and you will not be
      charged anything.
    </div>
    <CardColumns className="card-columns">
      <Card className="lender-card">
        <div className="oblig-align-row">
          <img className="oblig-img" src={bluecheckbox} alt="bluecheckbox" />
          <Card.Title className="oblig-title">No upfront fees</Card.Title>
        </div>
        <Card.Body className="lender-body">All fees are incorporated into the repayment.</Card.Body>
      </Card>
      <Card className="lender-card">
        <div className="oblig-align-row">
          <img className="oblig-img" src={bluecheckbox} alt="bluecheckbox" />
          <Card.Title className="oblig-title">No impact on credit score</Card.Title>
        </div>
        <Card.Body className="lender-body">
          Selecting an option will not impact your credit score. Once the deal is submitted to a
          lender, they will then put an enquiry on your credit file but we will let you know before
          this happens
        </Card.Body>
      </Card>
      <Card className="lender-card">
        <div className="oblig-align-row">
          <img className="oblig-img" src={bluecheckbox} alt="bluecheckbox" />
          <Card.Title className="oblig-title">Quick turn around time</Card.Title>
        </div>
        <Card.Body className="lender-body">
          If you wish to proceed to an approval we typically have an answer in 1 business day.
        </Card.Body>
      </Card>
    </CardColumns>
  </>
);
export default NoObligation;
