/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import Alert from 'react-bootstrap/Alert';
import NumberFormat from 'react-number-format';
import reduce from 'lodash/reduce';
import round from 'lodash/round';
import unionBy from 'lodash/unionBy';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import cls from 'classnames';

import processGTMevent from '../../../util/processGTMEvent';
import ExpenseForm from './ExpenseForm';
import { amountPerMonth } from '../../../lib/formatter';
import Tooltip from '../../../components/Tooltip';
import './expensedetails.scss';
import editImg from '../../../../assets/image/edit.svg';
import deleteImg from '../../../../assets/image/delete.svg';
import rightnormal from '../../../../assets/image/right-normal.svg';
import { WEEK_OPTION, DELETE_CONFIRM, RENTER } from '../../../util/constants';
import alert from '../../../../assets/image/risk.svg';
import { getNameByValue } from '../../../util/ContainerUtilities';

import '../finances-common.scss';
import ConfirmDeleteRow from '../../../components/ConfirmDeleteRow';

const ACTION_NEW = 'NEW';
const PREFILLED_EXPENSE = { amount: '0.00', frequency: WEEK_OPTION, disableDelete: true };
const RENT_EXPENSE = 'rent';
export const OPTIONAL_EXPENSES = [
  { name: 'Rent', value: RENT_EXPENSE },
  { name: 'Childcare & Education', value: 'child&Education' },
  { name: 'Other', value: 'other' },
];
export const MANDATORY_EXPENSES = [
  { name: 'Rent', value: RENT_EXPENSE },
  { name: 'Groceries', value: 'Groceries' },
  { name: 'Utilities', value: 'Utilities' },
  { name: 'Phone & Internet', value: 'PhoneInternet' },
  { name: 'Entertainment', value: 'Entertainment' },
  { name: 'Personal & Clothing', value: 'PersonalClothing' },
  { name: 'Transportation', value: 'Transportation' },
  { name: 'Insurance', value: 'Insurance' },
];
export const MANDATORY_EXPENSES_RENT = [
  { name: 'Groceries', value: 'Groceries' },
  { name: 'Utilities', value: 'Utilities' },
  { name: 'Phone & Internet', value: 'PhoneInternet' },
  { name: 'Entertainment', value: 'Entertainment' },
  { name: 'Personal & Clothing', value: 'PersonalClothing' },
  { name: 'Transportation', value: 'Transportation' },
  { name: 'Insurance', value: 'Insurance' },
];
export const ExpenseTypes = [...OPTIONAL_EXPENSES, ...MANDATORY_EXPENSES];

const formattedNumber = (brokerUpdated, verifiedMonthlyAmount, amount, amountFreq) => (
  <>
    <NumberFormat
      className={cls(brokerUpdated && 'amount-broker', 'amount-broker-with-old-value')}
      displayType="text"
      value={amount}
      prefix="$"
      // value={round(amountPerMonth(amount, amountFreq))}
      // eslint-disable-next-line no-nested-ternary
      suffix={amountFreq === 'Monthly' ? '/m' : amountFreq === 'Weekly' ? '/wk' : '/ft'}
      thousandSeparator
      decimalScale={2}
    />
    {brokerUpdated && (
      <NumberFormat
        className="amount-broker amount-broker-disabled-edit"
        displayType="text"
        value={verifiedMonthlyAmount}
        prefix="$"
        suffix="/m"
        thousandSeparator
        decimalScale={2}
      />
    )}
  </>
);

const ExpenseDetails = ({
  expenses,
  ValidationErrorFlag,
  addExpense,
  updateExpense,
  deleteExpense,
  expenseCompleted,
  completeExpenseDetails,
  deleteExpApi,
  reQuote,
  liabilities,
  residentialStatus,
  validationCheck,
  errorMessage,
  noErrors,
}) => {
  const [showForm, updateShowForm] = useState(false);
  const [expensesList, updateList] = useState(expenses || []);

  useEffect(() => {
    const filteredList = reduce(
      expensesList,
      (accum, stateExpenses) => {
        const sourceIncome = find(expenses, ['id', stateExpenses.id]);
        if (sourceIncome) {
          accum.push({ ...stateExpenses, ...sourceIncome, flagDelete: stateExpenses.flagDelete });
        }

        return accum;
      },
      [],
    );
    const filtredExpenses = residentialStatus !== 'Renting' ? unionBy(filteredList, expenses, 'id').filter(function (obj) {
      return obj.category !== RENT_EXPENSE;
    }) : unionBy(filteredList, expenses, 'id')

    updateList(filtredExpenses);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenses]);

  const flagToDelete = (id, flag = false) => () => {
    updateList(
      reduce(
        expensesList,
        (accum, option) => {
          const updatedOption = { ...option };
          if (option.id === id) {
            updatedOption.flagDelete = flag;
          }

          accum.push(updatedOption);
          return accum;
        },
        [],
      ),
    );
  };

  const totalLiabilityRepayments = round(
    reduce(
      liabilities,
      (calTotal, liabilityData) =>
        calTotal +
        Number(
          liabilityData.brokerUpdated
            ? liabilityData.verifiedMonthlyAmount
            : amountPerMonth(liabilityData.repayments, liabilityData.frequency),
        ),
      0,
    ),
  );

  const expensetotal = round(
    reduce(
      expenses,
      (calTotal, expenseData) =>
        calTotal +
        Number(
          expenseData.brokerUpdated
            ? expenseData.verifiedMonthlyAmount
            : amountPerMonth(expenseData.amount, expenseData.frequency),
        ),
      0,
    ),
  );
  const handleAddExpense = () => {
    updateShowForm(ACTION_NEW);
  };

  const editExpense = (expenseData) => () => {
    updateShowForm({ ...expenseData });
  };

  const handleDeleteExpense = (expenseData) => () => {
    deleteExpApi({ ...expenseData })
      .then((response) => {
        deleteExpense({ ...expenseData });
      })
      .catch((err) => {
        console.log('error');
      });
  };

  const handleCancel = () => {
    updateShowForm('');
  };

  const handleUpdate = (expenseData) => {
    if (showForm === ACTION_NEW) {
      addExpense(expenseData);
    } else {
      updateExpense(expenseData);
    }
    handleCancel();
  };

  const handleDeleteFile = (expenseData) => {
    updateExpense(expenseData);
    updateShowForm({ ...expenseData });
  };

  const isMandatoryExpense = (category) => find(residentialStatus !== 'Renting' ? MANDATORY_EXPENSES : MANDATORY_EXPENSES_RENT, ['value', category]);
  const handleExpenseDetails = () => {
    // processGTMevent('finances', 'totalexpenses', { value: expensetotal });
    reQuote();
    const expenseRenting = find(expenses, ['category', RENT_EXPENSE]);
    const mandatoryExpenses = filter(
      expenses,
      (expAmt) =>
        (expAmt.amount === '0.00' || expAmt.amount === '0') && isMandatoryExpense(expAmt.category),
    );
    if (residentialStatus === RENTER && !expenseRenting) {
      validationCheck(
        `${getNameByValue(
          ExpenseTypes,
          RENT_EXPENSE,
        )} is mandatory due to your current living situation`,
      );
    } else if (
      residentialStatus === RENTER &&
      expenseRenting &&
      (!expenseRenting.amount || expenseRenting.amount === '0' || expenseRenting.amount === '0.0')
    ) {
      validationCheck(
        `${getNameByValue(ExpenseTypes, RENT_EXPENSE)} expense should be greater than zero`,
      );
    } else if (mandatoryExpenses?.length > 0) {
      validationCheck('Expenses should be greater than zero');
    } else {
      noErrors();
      completeExpenseDetails();
    }
  };
  const smScreen = useMediaQuery('@media (max-width: 767.95px)');
  return (
    <Card className="content-body expense-details finances-details-container expense-details">
      <Card.Header className="col-12 page-header-container finances-details-header-container scrollable-anchor-tag with-sidebar">
        <a name="expense-details" href="#expense-details">
          &nbsp;
        </a>
        <h1 className="finances-details-header">Your house expense details</h1>
        <div className="finances-details-description">
          We need your entire household expenses (including your partner/family)
        </div>
      </Card.Header>
      <Card.Body className={cls('finances-body-container', !ValidationErrorFlag && 'pt-0')}>
        {expenses.length === 0 && liabilities.length === 0 && (
          <div className="netincome">
            <div className="netincome-label">
              <button
                type="button"
                onClick={handleAddExpense}
                className="btn btn-add-income add-expense"
              >
                + Add expense
              </button>
            </div>
          </div>
        )}
        {ValidationErrorFlag && (
          <Alert variant="danger" className="error-alert">
            <p className="error-message">{errorMessage}</p>
          </Alert>
        )}
        <Table hover responsive className="finances-body-table">
          <tbody className="finances-card-table-body">
            {liabilities.length > 0 && (
              <tr className="finances-card-table-row finances-total-liab-repayment">
                <td
                  className="finances-card-table-detail total-liab-repayments"
                  colSpan={(smScreen && 2) || 1}
                >
                  Liability Repayments
                </td>
                <td
                  className="finances-card-table-detail finance-card-total-align"
                  // colSpan={(smScreen && 2) || 1}
                  colSpan={2}
                >
                  <NumberFormat
                    displayType="text"
                    value={totalLiabilityRepayments}
                    prefix="$"
                    suffix=" /m"
                    thousandSeparator
                    decimalScale={2}
                  />
                </td>
                <td className="finances-card-table-detail hidden" colSpan="1" />
                {/* <td className="finances-card-table-detail hidden" colSpan="1" /> */}
              </tr>
            )}
            {expensesList.map((task) => (
              <tr className="finances-card-table-row" key={task.id || task.defaultId}>
                <td className="finances-card-table-detail" colSpan={(smScreen && 4) || 1}>
                  <div className="income-details-section-wrapper">
                    <div className="income-details-content">
                      <div className="income-type-name">
                        {get(find(ExpenseTypes, ['value', task.category]), 'name', 'N/A')}
                      </div>
                      {smScreen && !task.flagDelete && (
                        <div className="income-type-amount">
                          {formattedNumber(
                            task.brokerUpdated,
                            task.verifiedMonthlyAmount,
                            task.amount,
                            task.frequency,
                          )}
                        </div>
                      )}
                    </div>
                    {smScreen && !task.flagDelete && !task.brokerUpdated && (
                      <div className="income-action-container">
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          component="div"
                          onClick={editExpense(task)}
                        >
                          <img src={editImg} alt="edit" />
                        </IconButton>
                        {!isMandatoryExpense(task.category) && (
                          <IconButton
                            color="primary"
                            aria-label="delete"
                            component="div"
                            onClick={flagToDelete(task.id, true)}
                          >
                            <img src={deleteImg} alt="delete" />
                          </IconButton>
                        )}
                      </div>
                    )}

                    {smScreen && (
                      <ConfirmDeleteRow
                        task={task}
                        deleteConfirmText={DELETE_CONFIRM}
                        onDelete={handleDeleteExpense}
                        flagToDelete={flagToDelete}
                      />
                    )}
                  </div>
                </td>
                {!task.flagDelete && (
                  <>
                    <td className="finances-card-table-detail" colSpan={2}>
                      {formattedNumber(
                        task.brokerUpdated,
                        task.verifiedMonthlyAmount,
                        task.amount,
                        task.frequency,
                      )}
                    </td>
                    <td className="finances-card-table-detail">
                      <>
                        {/*  <span className="oi oi-pencil finances-card-table-pencil" /> */}
                        <img src={editImg} alt="edit" />{' '}
                        <button
                          type="button"
                          className="px-0 btn btn-link finances-card-table-edit"
                          onClick={editExpense(task)}
                        >
                          Edit
                        </button>
                      </>
                    </td>
                    <td className="finances-card-table-detail btn-delete">
                      {!isMandatoryExpense(task.category) && (
                        <>
                          <img src={deleteImg} alt="delete" />{' '}
                          <button
                            type="button"
                            className="px-0 btn btn-link finances-card-table-delete"
                            onClick={flagToDelete(task.id, true)}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </td>
                  </>
                )}
                <ConfirmDeleteRow
                  task={task}
                  deleteConfirmText={DELETE_CONFIRM}
                  onDelete={handleDeleteExpense}
                  flagToDelete={flagToDelete}
                />
              </tr>
            ))}
            {expenses.length > 0 && (
              <tr className="finances-card-table-row">
                <td className="finances-card-table-total label-font" colSpan={(smScreen && 2) || 1}>
                  Total expenses
                </td>
                <td
                  className="finances-card-table-total finance-card-total-align"
                  // colSpan={(smScreen && 2) || 1}
                  colSpan={2}
                >
                  <NumberFormat
                    displayType="text"
                    value={expensetotal + totalLiabilityRepayments}
                    prefix="$"
                    suffix=" /m"
                    thousandSeparator
                    decimalScale={2}
                  />
                </td>
                <td className="finances-card-table-total hidden" colSpan="1" />
                {/* <td className="finances-card-table-total hidden" colSpan="1" /> */}
              </tr>
            )}
          </tbody>
          {((expenses && expenses.length !== 0) || (liabilities && liabilities.length > 0)) && (
            <button
              type="button"
              className="btn btn-primary finances-details-button"
              onClick={handleAddExpense}
            >
              <span className="btn-income add-expense">+ Add expense</span>
            </button>
          )}
        </Table>

        {false && (
          <>
            <hr className="border-align-warning" />
            <div className="expense-warning">
              <img src={alert} alt="alerticon" className="alert-icon" />
              <div className="warning-message">
                Your total living expenses appear lower than expected, please review before
                proceeding
              </div>
            </div>
          </>
        )}
        {!!showForm && (
          <ExpenseForm
            isAdd={showForm === ACTION_NEW}
            initialValues={
              showForm === ACTION_NEW
                ? { frequency: WEEK_OPTION, category: '', amount: '' }
                : showForm
            }
            expenseDetails={showForm === ACTION_NEW ? '' : showForm}
            onUpdateExpense={handleUpdate}
            onCancel={handleCancel}
            onDelete={handleDeleteFile}
          />
        )}
        <div className=" container-footer py-3 px-3 border-top">
          <div className="col-12 d-flex justify-content-end px-0">
            <button
              type="button"
              className="btn btn-primary btn-next-page expense-next-section"
              // disabled={expensetotal <= 0}
              hidden={expenseCompleted}
              onClick={handleExpenseDetails}
            >
              Next
              <span className="px-3">
                <img src={rightnormal} alt="right" className="right-icon" />
              </span>
            </button>
            <input
              type="button"
              id="btnsubmit"
              className="btn-dummy-page"
              style={{ visibility: 'hidden' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ExpenseDetails;
