import validateFields from './ValidateFields';

const validateTab = (state, tabName) => {
  const tabNameFormatted = tabName.toLowerCase();

  const fields = state[tabNameFormatted].activeFields;

  const errors = validateFields(fields, tabNameFormatted, state);

  return errors;
};

export default validateTab;
