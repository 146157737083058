/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { CircularProgress } from '@material-ui/core';
import cls from 'classnames';
import find from 'lodash/find';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import '../../components/input-field.scss';
import processGTMevent from '../../util/processGTMEvent';
import rightnormal from '../../../assets/image/right-normal.svg';
import AmountRangeSelector from '../../components/AmountRangeSelector';
import FindMakeFilter from '../../components/FindMakeFilter';
import InputButton from '../../components/InputButton';
import ProgressBar from '../../components/ProgressBar';
import TextField from '../../components/TextField';
import Tooltip from '../../components/Tooltip';
import { CAR_CONDITION, PURCHASE_SOURCE, PURCHASE_USE, ROUTES } from '../../util/constants';
import { capFirstLetter, findPartnerandDescription } from '../../util/helpers';
import './carfinance.scss';
import CustomCard from './CustomCard';
import DescriptionTextPartner from '../../components/DescriptionPartner';

const OtherVehicleAssetTypeFullScreen = (props) => {
  const {
    setTab,
    currentTab,
    searchCar,
    purchaseSource,
    purchaseUse,
    purchasePrice,
    depositAmount,
    carCondition,
    manufacturingYear,
    vehicleMake,
    vehicleModel,
    validationErrors,
    getValidationErrorsForField,
    onInputChange,
    flagChange,
    purchasePriceComplete,
    setTabComplete,
    nextFieldNameToFocus,
    scrollToNext,
    depositPriceComplete,
    progressValue,
    createQuote,
    handleHTTPError,
    factoryOptions,
    noErrors,
    validationCheck,
    ValidationErrorFlag,
    errorMessage,
    getAllVehicleYears,
    vehicleYears,
    onEstimateChange,
    quoteInProgress,
    skippedActionTriggered,
    termVal,
    isAuthenticated,
    forceNewApplication,
    assetType,
    vehicleMakeModel,
    skippedManufacturingYear,
    skipVehicleMakeModel,
    onLoadPageAsset,
    partnername,
    condition,
    make,
    model,
    depositAmountUrl,
    year,
    purchasePriceUrl,
    purchaseSourceUrl,
    purchaseUseUrl,
    term,
    prefillRecords
  } = props;
 
  const history = useHistory();
  let updatedAssetType = assetType?.toLowerCase().replace(/\s+/g, '-');
  const newpartnerflag = findPartnerandDescription(partnername) === null ? true : false;

  useEffect(() => {
    noErrors();
    if (carCondition === 'Unsure') {
      getAllVehicleYears('Used');
    } else {
      getAllVehicleYears(carCondition);
    }
  }, [carCondition, getAllVehicleYears, manufacturingYear, noErrors]);

  useEffect(() => {
    processGTMevent(assetType, 'PQ-PageLoad', 'Page Load');
    //return to main page if no selected asset type
    if (!updatedAssetType) {
      const pathname = history.location.pathname;

      switch (pathname) {
        case ROUTES.boat:
          onLoadPageAsset('Boat');
          break;
        case ROUTES.bike:
          onLoadPageAsset('Bike');
          break;
        case ROUTES.caravan:
          onLoadPageAsset('Caravan');
          break;
        default:
          break;
      }
    }
    if (setTab) {
      setTab('car');
      if (!prefillRecords) {
        scrollToNext(`year-manufactured~fullScreen`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextFieldNameToFocus) {
      if (
        (nextFieldNameToFocus === 'purchasePrice' ||
          nextFieldNameToFocus === 'purchase-price-selector') &&
        carCondition === 'Unsure' &&
        purchaseUse
      ) {
        return;
      }
      if (nextFieldNameToFocus === 'deposit') {
        window.location.hash = '#deposit';
        return;
      }

      if(nextFieldNameToFocus === 'car-purchase-from' && carCondition==='New')
      {
        scrollToNext(`car-usage~fullScreen`);
      }
      else{
        scrollToNext(`${nextFieldNameToFocus}~fullScreen`);
      }

    } else if (!carCondition) {
      scrollToNext('toTop');
    }
  }, [carCondition, nextFieldNameToFocus, purchaseUse, scrollToNext]);

  useEffect(() => {
    if (prefillRecords) {
      const pathname = history.location.pathname;
      switch (pathname) {
        case ROUTES.boat:
          flagChange('assetType', 'Boat', false);
          break;
        case ROUTES.bike:
          flagChange('assetType', 'Bike', false);
          break;
        case ROUTES.caravan:
          flagChange('assetType', 'Caravan', false);
          break;
        default:
          break;
      }

      if (condition) {
        flagChange('carCondition', condition, false);

        if (condition === 'Used') {
          onInputChange('manualEnter', true, false);
        }

        if (year) {
          flagChange('manufacturingYear', year, false);
          onInputChange('progress', 20, false);

          if (make && model) {
            flagChange('vehicleMake', make, false)
            flagChange('vehicleModel', model, false)
            onInputChange('vehicleMakeModel', true, false);

            if (purchaseUseUrl) {
              flagChange('purchaseUse', purchaseUseUrl, false)
              onInputChange('progress', 40, false);
            }

            if (purchaseSourceUrl) {
              flagChange('purchaseSource', purchaseSourceUrl, false)
              onInputChange('progress', 50, false);
            }

            if (purchasePriceUrl) {
              flagChange('purchasePrice', parseFloat(purchasePriceUrl));
              onInputChange('purchasePriceComplete', true, false);
              onInputChange('progress', 60, false);
            }

            if (depositAmountUrl) {
              flagChange('depositAmount', parseFloat(depositAmountUrl));
              onInputChange('depositPriceComplete', true, false);
              onInputChange('progress', 80, false);
            }

            if (term) {
              onEstimateChange('termVal', term, false);
              onInputChange('progress', 90, false);
            }
          }

        }
      }
    }

  }, [condition, make, model, depositAmountUrl, year, purchasePriceUrl, purchaseSourceUrl, purchaseUseUrl, term])


  const currentData = new Date();
  const currentYear = currentData.getFullYear();
  const vehicleYearsformatted = reverse(sortBy(vehicleYears));
  const vehicleYearsMax10 = vehicleYearsformatted.reduce((accum, yrOption, idx) => {
    if (idx > 9) return accum;
    accum.push({ name: String(yrOption), value: yrOption });
    return accum;
  }, []);
  vehicleYearsMax10.push({ name: 'Older', value: String(currentYear - 10) });

  const handleClick = () => {
    processGTMevent(assetType, 'termValue', termVal);
    processGTMevent(assetType, 'seeContact', 'seeContactPage')
    flagChange('carDetailsChanged', false);
    if (validationErrors && validationErrors.length) {
      validationCheck();
      return;
    }
    if (purchasePrice - depositAmount < 5000) {
      validationCheck('Loan Amount cannot be less than $5000.');
      return;
    }
    noErrors();
    createQuote()
      .then(() => {
        // Add your code here
        setTabComplete(currentTab);

        if (isAuthenticated && forceNewApplication) {
          history.push(ROUTES.estimation);
        } else {
          history.push(ROUTES.contactDetails);
        }
      })
      .catch((error) => {
        handleHTTPError(error, history);
      });
  };

  const handleFieldChange = (fieldName, value, scrollToNextFieldOnChage) => {
    let updateValue = progressValue;
    switch (fieldName) {
      case 'manufacturingYear':
        updateValue = 10;
        break;
      case 'commercialType':
        updateValue = 20;
        break;
      case 'vehicleMake':
        updateValue = 25;
        break;
      case 'vehicleModel':
        updateValue = 30;
        break;
      case 'purchaseSource':
        updateValue = 50;
        break;
      case 'purchasePrice':
        updateValue = 55;
        onInputChange('depositAmount', 0, true);
        break;
      case 'depositAmount':
        updateValue = 60;
        break;

      default:
        updateValue = 70;
        break;
    }
    onInputChange('progress', updateValue, false);
    flagChange('prefillRecords', false, false);
    onInputChange(fieldName, value, scrollToNextFieldOnChage);
    if (
      fieldName === 'purchaseUse' ||
      fieldName === 'purchaseSource' ||
      fieldName === 'purchasePrice' ||
      fieldName === 'depositAmount'
    ) {
      createQuote();
    }
  };

  const handleSkip = (fieldName) => {
    onInputChange('skippedActionTriggered', true, false);
    onInputChange(fieldName, true, false);
  };

  const selectedfactoryData = find(factoryOptions, ['selected', true]);
  const maxTermVal = 7;

  const hideNextButton = () => {
    const ifSkipMakeModel = skipVehicleMakeModel || vehicleMakeModel;
    const ifSkipmanufacturingYear = skippedManufacturingYear || manufacturingYear;
    return (
      !ifSkipMakeModel ||
      !ifSkipmanufacturingYear ||
      !purchaseUse ||
      depositPriceComplete
    );
  };

  const onHandleNextClick = () => {

    if (vehicleMake && vehicleModel && !vehicleMakeModel) {
      onInputChange('vehicleMakeModel', true, false);
    } else if (purchasePrice && !purchasePriceComplete) {
      onInputChange('purchasePriceComplete', true, false);
      processGTMevent(assetType, 'purchasePriceValue', purchasePrice);
    }
    else if (
      (depositAmount || depositAmount === 0 || depositAmount === null) &&
      !depositPriceComplete
    ) {
      onInputChange('depositPriceComplete', true, false);
      processGTMevent(assetType, 'depositAmountValue', depositAmount);
    }

    if (!purchasePriceComplete && depositAmount) {
      onInputChange('depositAmount', 0, true);
    }

    if (prefillRecords && depositAmountUrl) {
      flagChange('depositAmount', parseFloat(depositAmountUrl));
    }
  };

  const showSubmitButton = () => {
    return depositPriceComplete && termVal !== 0;
  };

  const estimationChange = (fieldName, value) => {
    onEstimateChange(fieldName, value, false);
    flagChange('prefillRecords', false, false);
    flagChange('term', value, false);
  }

  const cardItems = [{ title: 'Be gainfully employed', tooltipText: '', link: '', linkText: '' },
  { title: 'Have a clean credit history.', tooltipText: '', link: '', linkText: '' },
  { title: 'Be older than 18 years of age.', tooltipText: '', link: '', linkText: '' },
  { title: 'Be a resident or citizen of Australia.', tooltipText: '', link: '', linkText: '' }];

  return (
    <>
      <div className="container-section car-applicants-main-container full-width-container">
        <div className="content-body pt-4 car-applicants-main-tab-container" id="car">
          <div className="car-applicants-main-subcontainer px-0">
            <div className="row mx-0">
              <div className="col-12  text-center car-applicants-main-section">
                <Form noValidate className="car-applicants-main-form">
                  <InputButton
                    label={
                      <>
                        <span>What type of {assetType} are you planning to buy?</span>
                      </>
                    }
                    fields={CAR_CONDITION}
                    onChange={handleFieldChange}
                    fieldName="carCondition"
                    value={carCondition}
                    // scrollToNextFieldOnChage
                    formGroupClass="car-condition fit-screen"
                    btnClass="full-screen-mode"
                    labelClass="car-full-screen-label"
                    getvalidationerrors={getValidationErrorsForField}
                    id="car-condition"
                  />

                  {carCondition && (
                    <>
                      <div className="year-manufactured">
                        <FindMakeFilter
                          label={
                            <>
                              <span className="text">When was the {assetType} manufactured?</span>
                              <Tooltip
                                message="The year has an impact on the rate and typically the newer the car the better the rate. You will be able to come back and change this if you are undecided."
                              />
                            </>
                          }
                          fields={vehicleYearsformatted.map((yr) => ({
                            name: String(yr),
                            value: yr,
                          }))}
                          onChange={handleFieldChange}
                          fieldName="manufacturingYear"
                          value={manufacturingYear}
                          formGroupClass="fit-screen year-manufactured"
                          btnClass="full-screen-mode"
                          labelClass="car-full-screen-label"
                          isFullScreen
                          isreturnObjectval
                          getvalidationerrors={getValidationErrorsForField}
                          loadmore
                          id="year-manufactured"
                        />
                      </div>
                    </>
                  )}

                  {manufacturingYear && (
                    <>
                      <div className="centered-field small vehicleMakeModel ">
                        <div className="title">What's the make and model of the {assetType}?</div>
                        <div className="container small fit-screen">
                          <br />
                          <TextField
                            fieldName={`vehicleMake`}
                            value={vehicleMake}
                            label={`${capFirstLetter(assetType)} make`}
                            placeholder="Enter make"
                            getvalidationerrors={getValidationErrorsForField}
                            onChange={handleFieldChange}
                            inline
                            updateOnChange
                            id="vehicleMake"
                          />
                          <br />
                          <TextField
                            fieldName={`vehicleModel`}
                            value={vehicleModel}
                            label={`${capFirstLetter(assetType)} model`}
                            placeholder="Enter model"
                            getvalidationerrors={getValidationErrorsForField}
                            onChange={handleFieldChange}
                            inline
                            updateOnChange
                            id="vehicleModel"
                          />
                          <br />

                          <div className="button-container">
                            <button
                              type="button"
                              className="btn btn-primary btn-next-page"
                              onClick={() => {
                                onInputChange('vehicleMakeModel', true, false);
                                processGTMevent(assetType, 'vehicleMakeValue', vehicleMake);
                                processGTMevent(assetType, 'vehicleModelValue', vehicleModel);
                              }}
                              disabled={!vehicleMake || !vehicleModel}
                            >
                              Next
                              <span className="pl-4">
                                <img src={rightnormal} alt="right" className="right-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      {!skipVehicleMakeModel && !vehicleMake && !vehicleModel && (
                        <Form.Row className={cls('skip-action-container')}>
                          <FormGroup as={Col} lg={9} md={10}>
                            <div className="skip-action-section">
                              <div className="skip-action-align">
                                <span className="notsure">Not sure? </span>
                                <button
                                  type="button"
                                  className="btn btn-default btn-skip-page"
                                  hidden={vehicleMake || vehicleModel}
                                  onClick={() => {
                                    onInputChange('skipVehicleMakeModel', true, false);
                                  }}
                                >
                                  Skip
                                  <i className="fa fa-chevron-right" />
                                </button>
                              </div>
                            </div>
                          </FormGroup>
                        </Form.Row>
                      )}
                    </>
                  )}

                  {((carCondition !== 'New') && (vehicleMakeModel || skipVehicleMakeModel)) && (
                    <InputButton
                      label={
                        <>
                          <span>Where are you planning to purchase the {assetType} from?</span>
                        </>
                      }
                      fields={PURCHASE_SOURCE}
                      onChange={handleFieldChange}
                      fieldName="purchaseSource"
                      btnClass="full-screen-mode"
                      value={purchaseSource}
                      labelClass="car-full-screen-label"
                      formGroupClass={`fit-screen car-purchase-from`}
                      getvalidationerrors={getValidationErrorsForField}
                      id="car-purchase-from"
                    />
                  )}

                  {(manufacturingYear && (carCondition === 'New' && vehicleMakeModel || skipVehicleMakeModel) || (purchaseSource && carCondition !== 'New')) && (
                    <div className="car-usage">
                      <InputButton
                        label={
                          <div className="title-with-sub-label car-details-full-screen">
                            <div className="car-details-main-label">
                              How will the {assetType} be used for the majority of the time?
                            </div>
                            <span className="car-details-main-sublabel">
                              Select business if you use your car for work more than 50% of the time
                            </span>
                            <Tooltip message="Using your car for business includes carrying tools to multiple job sites, driving between clients etc. You will need to provide proof of business use." />
                          </div>
                        }
                        fields={PURCHASE_USE}
                        onChange={handleFieldChange}
                        fieldName="purchaseUse"
                        btnClass="full-screen-mode"
                        value={purchaseUse}
                        // scrollToNextFieldOnChage
                        formGroupClass={cls(
                          'fit-screen',
                          skippedActionTriggered && 'skipped-action',
                          'car-usage',
                        )}
                        getvalidationerrors={getValidationErrorsForField}
                        id="car-usage"
                      />
                    </div>
                  )}

                  {purchaseUse && (
                    <div
                      className={cls(
                        'amount-range-container purchase-price-selector car-purchase-price',
                        'scrollable-anchor-tag',
                      )}
                    >
                      <a name="car-purchase-price" href="#car-purchase-price">
                        &nbsp;
                      </a>
                      <div className="label car-main-screen-label">
                        What is the estimated purchase price of the {updatedAssetType}?
                      </div>
                      <div>
                        <CustomCard
                          className="newCard"
                          title="To be considered for a loan you will need to:"
                          items={cardItems}
                        />
                      </div>
                      <AmountRangeSelector
                        label={
                          <>
                            <span className="label ">Purchase price</span>
                          </>
                        }
                        onChange={onInputChange}
                        fieldName="purchasePrice"
                        defaultValue={purchasePrice}
                        colsm={12}
                        colmd={8}
                        oncreateQuote={createQuote}
                        getvalidationerrors={getValidationErrorsForField}
                        minValue={5000}
                        maxValue={250000}
                        step={1000}
                      />
                    </div>
                  )}
                  {purchasePriceComplete && (
                    <>
                      <div className="deposit scrollable-anchor-tag">
                        <a name="deposit" href="#deposit">
                          &nbsp;
                        </a>
                      </div>
                      <div className="amount-range-container deposit-price-selector">
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="label">Deposit / Trade-in</span>
                            </>
                          }
                          onChange={onInputChange}
                          fieldName="depositAmount"
                          defaultValue={depositAmount}
                          colsm={12}
                          colmd={8}
                          minValue={0}
                          tooltip="Enter the combined amount for the trade in value of your current car, and any cash deposit you will be contributing to the purchase"
                          oncreateQuote={createQuote}
                          maxValue={purchasePrice - 5000}
                          step={1000}
                          disabled={purchasePrice - 5000 <= 0}
                          getvalidationerrors={getValidationErrorsForField}
                        />
                        <div className="form-row pb-4">
                          <div className=" car-applicants-loan-form col-md-5 ">
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}

                            <div className="car-applicants-main-loanamount">
                              <NumberFormat
                                displayType="text"
                                value={purchasePrice - depositAmount}
                                className="loan-amount-value"
                                prefix="$"
                                thousandSeparator
                              />
                            </div>
                            <label htmlFor="car-applicants-main-loanamount-label label">
                              Estimated loan amount
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {depositPriceComplete && (
                    <div
                      className={cls(
                        'amount-range-container loan-term-selector car-loan-term',
                        'scrollable-anchor-tag',
                      )}
                    >
                      <div className="label car-main-screen-label">
                        Over what term do you want the loan?
                      </div>
                      <div className="term scrollable-anchor-tag">
                        <a name="term" href="#term">
                          &nbsp;
                        </a>
                      </div>

                      <div className="amount-range-container term-val-selector">
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="label">Loan term</span>
                            </>
                          }
                          onChange={estimationChange}
                          fieldName="termVal"
                          defaultValue={termVal}
                          oncreateQuote={createQuote}
                          minValue={1}
                          maxValue={maxTermVal}
                          step={1}
                          termSlider
                          getvalidationerrors={getValidationErrorsForField}
                        />
                      </div>
                    </div>
                  )}

                  <div className="footer-actions fit-screen">
                    <button
                      type="button"
                      className="btn btn-primary btn-next-page"
                      hidden={hideNextButton()}
                      disabled={validationErrors && validationErrors.length || (purchasePrice - depositAmount < 5000)}
                      onClick={onHandleNextClick}
                    >
                      Next
                      <span className="pl-4">
                        <img src={rightnormal} alt="right" className="right-icon" />
                      </span>
                    </button>
                    <input
                      type="button"
                      id="btnsubmit"
                      className="btn-dummy-page"
                      style={{ visibility: 'hidden' }}
                    />

                    {showSubmitButton() && (
                      <button
                        type="button"
                        data-test="carmainscreen-estimatepage"
                        className="btn  btn-navigate-page"
                        onClick={handleClick}
                        disabled={quoteInProgress}
                      >
                        {quoteInProgress ? (
                          <CircularProgress color="inherit" size={24} thickness={2.6} />
                        ) : (
                          <>
                            Next
                            <span className="pl-4">
                              <img src={rightnormal} alt="right" className="right-icon" />
                            </span>
                          </>
                        )}
                      </button>
                    )}

                    {ValidationErrorFlag && (
                      <div className="p-0 container">
                        <Alert variant="danger" className="error-alert py-4 px-2">
                          <div className="error-flex">
                            <div className="error-header-message">
                              {searchCar ? 'We could not identify your details.' : 'Error'}
                            </div>
                            <p className="error-message">{errorMessage}</p>
                          </div>
                        </Alert>
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-cardetails-page sticky-bottom">
        {!newpartnerflag && (<DescriptionTextPartner partnerItem={findPartnerandDescription(partnername)} />)}
        <ProgressBar progressValue={progressValue} />
      </div>
    </>
  );
};

export default OtherVehicleAssetTypeFullScreen;
