import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useHistory, withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import cls from 'classnames';
import {
  Grid,
  InputBase,
  NativeSelect,
  useMediaQuery,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { liteFieldChanged } from '../../Actions/FieldChanged';
import DocumentVerification from './DocumentsVerification';
import arrowLeft from '../../../assets/image/Arrow-Left.svg';
import { LENDER_IMAGE_LIST, ROUTES, APPLICANT_TYPE, PRIMARY_APPLICANT } from '../../util/constants';
import Stepper from '../../components/Stepper';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import rightnormal from '../../../assets/image/TArrow-Right.svg';
import phoneIcon from '../../../assets/image/phone-icon.svg';
import { borderStyling } from '../../../styles/constant';
import { HASH_PATHS } from '../PersonalDetails/PersonalDetailsContainer';
import { setApplicationStrucutureStepper } from '../../Actions/stepperDetails';
import toUpper from 'lodash/toUpper';
import { uploadDocument, getDocumentList, getDocument } from '../../Actions/finances';
import { size } from 'lodash';
import FinaliseCar from '../PrepareForSettlement/FinaliseCar/FinaliseCar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: { margin: '0px 43px' },
  appBar: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: '#F1F2F2',
    boxShadow: 'none',
    flex: 1,
    zIndex: 1,
    [theme.breakpoints.up(960)]: {
      marginBottom: '20px',
    },
  },
  wrapper: {
    textTransform: 'none',
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#25282A',
    '&$flexContainer': {
      backgroundColor: '#f1f2f2',
    },
  },
  containerWrapper: {
    flexBasis: 'calc(70% - 30px)',
    backgroundColor: '#fff',
  },
  flexContainer: {},
  textColorInherit: {
    color: '#25282A',
  },
  selected: {
    fontFamily: 'Poppins-Bold',
    textTransform: 'none',
    // fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#25282A',
  },
  tabsIndicator: {
    borderColor: '#00aabb',
    backgroundColor: '#00aabb',
    height: '4px',
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '8px',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 0,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const CustomerDashboard = (props) => {
  const {
    setApplicationStrucutureStepperMenu,
    completedTabs,
    year,
    make,
    model,
    vehicleBodyType,
    transmission,
    maxRepaymentVal,
    minRepaymentVal,
    maxInterestVal,
    minInterestVal,
    termVal,
    selectFreq,
    dfsAccepted,
    isPQProductSelectionGroup,
    selectedLender,
    purposeOfUse,
    incomelistVal,
    assetListVal,
    customDashboard,
    residualAmount,
    customerRate,
    monthlyRepayments,
    quoteInProgress,
    loanAmount,
    applicationStructure,
    contactDetails,
    minResidualVal,
    maxResidualVal,
    opportunityId,
    leadSource,
    onAccountUpdate,
    onCarDetailsChange,
    assetType,
    acceptPrivacyPolicy,
    assetTypeName,
    applicantType,
    pqsubmitToLenderDate,
    partner,
    getDocumentList,
    finaliseCar,
    stage,
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(768));
  const [documentListPartner, setDocumentListPartner] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const partnerId = partner?.coApplicantId || partner?.spouseApplicantId;
  const [fetchingInprogress, setFetchingInprogress] = useState(true);

  const handleChange = (event, newValue) => {
    if (newValue || newValue === 0) {
      setValue(newValue);
    } else {
      setValue(parseInt(event.target.value));
    }
  };

  const dashboardPage = () => {
    history.push(ROUTES.dashboard);
  };
  useEffect(() => {
    setApplicationStrucutureStepperMenu(applicationStructure);
  }, [applicationStructure]);

  useEffect(() => {
    fetchDocumentUploadedList();
    if (partnerId) {
      fetchDocumentUploadedList(true);
    }
  }, []);

  const fetchDocumentUploadedList = (ispartner = false) => {
    getDocumentList(ispartner).then((res) => {
      const updatedDocList = remapDocType(res.data);
      ispartner ? setDocumentListPartner(updatedDocList) : setDocumentList(updatedDocList);
      setFetchingInprogress(false);
    });
  };

  const remapDocType = (docList) => {
    const arrayDocList = docList.filter(
      (obj) =>
        obj.status === 'Requested' ||
        obj.status === 'Uploaded' ||
        obj.status === 'Required' ||
        obj.status === 'Sent to Lender' ||
        obj.status === 'Broker Verified',
    );
    return arrayDocList;
  };

  const showDocumentTab = partnerId
    ? size(documentListPartner) > 0 && size(documentList) > 0
      ? true
      : false
    : size(documentList)
    ? true
    : false;

  const navigatepage = () => {
    const navigateTo = ROUTES[assetType];

    onCarDetailsChange('carSalesOppCreated', false, false);
    const filteredLeadSource = toUpper(leadSource).indexOf('CARSALES') > -1;
    const isCarsalesAccount = filteredLeadSource;
    if (filteredLeadSource) {
      onAccountUpdate('isCarsalesAccount', isCarsalesAccount, true);
    }
    if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'car' && acceptPrivacyPolicy) {
      history.push(`${ROUTES.personal}#${HASH_PATHS.identityDetails}`);
    } else if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'drivers-Licence') {
      history.push(`${ROUTES.personal}#${HASH_PATHS.livingDetails}`);
      setTimeout(() => {
        document.querySelectorAll(`[name="${HASH_PATHS.livingDetails}"]`)[0].scrollIntoView();
      }, 1000);
    } else if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'address-details') {
      history.push(`${ROUTES.personal}#${HASH_PATHS.livingArrangements}`);
      setTimeout(() => {
        document.querySelectorAll(`[name="${HASH_PATHS.livingArrangements}"]`)[0].scrollIntoView();
      }, 1000);
    } else if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'dependent-details') {
      history.push(`${ROUTES.personal}#${HASH_PATHS.employmentDetails}`);
      setTimeout(() => {
        document.querySelectorAll(`[name="${HASH_PATHS.employmentDetails}"]`)[0].scrollIntoView();
      }, 1000);
    } else if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'employment-details') {
      history.push(`${ROUTES.applicants}#${HASH_PATHS.incomeDetails}`);
    } else if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'income-details') {
      history.push(`${ROUTES.applicants}#${HASH_PATHS.assetDetails}`);
      setTimeout(() => {
        document.querySelectorAll(`[name="${HASH_PATHS.assetDetails}"]`)[0].scrollIntoView();
      }, 1000);
    } else if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'asset-details') {
      history.push(`${ROUTES.applicants}#${HASH_PATHS.liabilityDetails}`);
      setTimeout(() => {
        document.querySelectorAll(`[name="${HASH_PATHS.liabilityDetails}"]`)[0].scrollIntoView();
      }, 1000);
    } else if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'liabilities-details') {
      history.push(`${ROUTES.applicants}#${HASH_PATHS.expenseDetails}`);
      setTimeout(() => {
        document.querySelectorAll(`[name="${HASH_PATHS.expenseDetails}"]`)[0].scrollIntoView();
      }, 1000);
    } else if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'expense-details') {
      history.push(`${ROUTES.applicants}#${HASH_PATHS.policyConfirmation}`);
      setTimeout(() => {
        document.querySelectorAll(`[name="${HASH_PATHS.policyConfirmation}"]`)[0].scrollIntoView();
      }, 1000);
    } else if (
      localStorage.getItem(`pageCompleted${opportunityId}`) === 'policy-confirmation' &&
      isPQProductSelectionGroup &&
      !selectedLender
    ) {
      history.push(ROUTES.compareLoanOptions);
    } else if (isPQProductSelectionGroup && selectedLender) {
      if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'compare-loan-options') {
        history.push(`${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.primary}`);
      } else if (localStorage.getItem(`pageCompleted${opportunityId}`) === 'post-address-primary') {
        history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.primary}`);
      } else if (
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'post-employment-primary' &&
        contactDetails
      ) {
        if (contactDetails) {
          if (applicationStructure === APPLICANT_TYPE.coApplicant) {
            history.push(`${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
          } else if (applicationStructure === APPLICANT_TYPE.spouse) {
            history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.spouse}`);
          }
        } else {
          history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.primary}`);
        }
      } else if (
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'post-employment-primary' &&
        !contactDetails
      ) {
        history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.primary}`);
      } else if (
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'contact-for-coApplicant'
      ) {
        history.push(`${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
      } else if (
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'contact-for-spouse' ||
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'post-employment-spouse'
      ) {
        history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.primary}`);
      } else if (
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'upload-document-primary'
      ) {
        if (contactDetails) {
          if (applicationStructure === APPLICANT_TYPE.coApplicant) {
            history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
          } else {
            history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.spouse}`);
          }
        } else {
          history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.primary}`);
        }
      } else if (
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'upload-document-spouse'
      ) {
        if (contactDetails) {
          history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.spouse}`);
        } else {
          history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.primary}`);
        }
      } else if (
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'upload-document-coapplicant'
      ) {
        if (contactDetails) {
          history.push(`${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
        } else {
          history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.primary}`);
        }
      } else if (
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'post-address-coapplicant'
      ) {
        history.push(`${ROUTES.coApplicantPersonalDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
      } else if (
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'coapp-personal-details'
      ) {
        history.push(`${ROUTES.coApplicantIdentityDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
      } else if (
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'coapp-licence-details' ||
        localStorage.getItem(`pageCompleted${opportunityId}`) === 'post-employment-coapp'
      ) {
        history.push(`${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
      } else {
        history.push(`${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.primary}`);
      }
    } else {
      history.push(navigateTo);
    }
  };

  const findLenderIcon = (selectedLender) => {
    const foundLenderIcon = LENDER_IMAGE_LIST.find((e) => e.id === selectedLender);
    if (foundLenderIcon) {
      return foundLenderIcon.value;
    }

    return undefined;
  };
  // NEW UI
  // customDashboard
  const resumeAppFlag =
    isPQProductSelectionGroup ||
    (!dfsAccepted && purposeOfUse === 'Personal') ||
    (!dfsAccepted &&
      purposeOfUse === 'Business' &&
      !incomelistVal.length &&
      !dfsAccepted &&
      purposeOfUse === 'Business' &&
      !assetListVal.length &&
      applicantType === PRIMARY_APPLICANT);

  const resumeApplicationFlag =
    resumeAppFlag && (pqsubmitToLenderDate === '' || pqsubmitToLenderDate === null);

  return (
    <div className="container" style={{ marginTop: '21px' }}>
      <div>
        <button className=" btn btn-link nav-dashboard" type="button" onClick={dashboardPage}>
          <img src={arrowLeft} alt="previouspage" className="leftarrow-img" />
          <span className="dashboard-label">Dashboard</span>
        </button>
      </div>
      {fetchingInprogress ? (
        <div className="text-center mt-5">
          <CircularProgress color="primary" size={54} thickness={4} />
        </div>
      ) : (
        <>
          {/* // DESKTOP */}
          {!mobileView ? (
            <div className="customer-dashboard">
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <div
                      className={cls('stepper-dashboard')}
                      style={{ margin: '0px', marginTop: '67px', height: '92%' }}
                    >
                      <div className="application-process-label">APPLICATION PROCESS</div>
                      <Stepper
                        className="custom-dashboard-stepper"
                        disableSteps
                        disableSubSteps
                        hideSidebar={false}
                        forceCollapse
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <AppBar position="static" className={classes.appBar} color="inherit">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        classes={{
                          indicator: classes.tabsIndicator,
                        }}
                        scrollButtons="auto"
                        variant="scrollable"
                      >
                        <Tab
                          label="Loan details"
                          classes={{
                            root: classes.wrapper,
                            selected: classes.selected,
                          }}
                          {...a11yProps(0)}
                        />
                        {/* <Tab
                        label="Verification"
                        disabled
                        className={classes.wrapper}
                        {...a11yProps(1)}
                      /> */}
                        {applicantType === PRIMARY_APPLICANT &&
                          acceptPrivacyPolicy &&
                          showDocumentTab && (
                            <Tab
                              label="Upload Documents"
                              className={classes.wrapper}
                              {...a11yProps(1)}
                            />
                          )}
                        {finaliseCar &&
                          assetType === 'car' &&
                          purposeOfUse === 'Personal' &&
                          showDocumentTab &&
                          (stage === 'Approved' || stage === 'Settlement') && (
                            <Tab
                              label="Prepare for Settlement"
                              className={classes.wrapper}
                              {...a11yProps(1)}
                            />
                          )}
                      </Tabs>
                    </AppBar>
                    <div>
                      <TabPanel
                        value={value}
                        index={0}
                        className={cls(classes.containerWrapper, 'customer-dashboard-container')}
                      >
                        <>
                          <div className="default-car-details">
                            <div className="row flex-column-reverse flex-md-row">
                              <div className="col-sm">
                                <div className="section-details-label">
                                  {assetTypeName?.toUpperCase()} DETAILS
                                </div>
                              </div>
                              {resumeApplicationFlag && (
                                <div className="col-sm">
                                  <div className="email-quote start-new-application text-right mt-0 mb-4">
                                    <button
                                      type="button"
                                      className="letsgetstarted-btn continue-existing-application"
                                      onClick={navigatepage}
                                    >
                                      Resume Application
                                      <span className="image-align">
                                        <img src={rightnormal} alt="right" className="right-icon" />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="car-details">
                              <div>
                                <div className="year-make">
                                  {`${year && year} - ${make && make.name} ${model && model.name}`}
                                </div>
                                <div className="engine-bodytype">
                                  {`${vehicleBodyType || ''} ${' '} ${transmission || ''} `}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="line-across" />
                          <div className="default-car-details-loan">
                            <div className="section-details-label loan-summary-text">
                              LOAN SUMMARY
                            </div>
                            <div className="loan-summary-container">
                              <div className="loan-summary-section">
                                <Grid container spacing={3}>
                                  {selectedLender && (
                                    <>
                                      <Grid item xs={12} md={4}>
                                        <div className="info-row">
                                          {selectedLender ? (
                                            <div className="info-details-label">
                                              <NumberFormat
                                                value={monthlyRepayments}
                                                displayType="text"
                                                prefix="$"
                                                thousandSeparator
                                              />
                                            </div>
                                          ) : (
                                            <div className="info-details-label">
                                              <NumberFormat
                                                value={minRepaymentVal}
                                                displayType="text"
                                                prefix="$"
                                                thousandSeparator
                                              />
                                              <span className="mx-2">-</span>
                                              <NumberFormat
                                                displayType="text"
                                                value={maxRepaymentVal}
                                                prefix="$"
                                                thousandSeparator
                                              />
                                            </div>
                                          )}
                                          <div className="info-details-value">
                                            {selectFreq}
                                            &nbsp;
                                            <span>repayments</span>
                                          </div>
                                        </div>
                                      </Grid>
                                      {purposeOfUse !== 'Business' && (
                                        <Grid item xs={12} md={4}>
                                          <div className="info-row info-row-align">
                                            {selectedLender ? (
                                              <div className="info-details-label">
                                                <NumberFormat
                                                  value={customerRate}
                                                  displayType="text"
                                                  suffix="%"
                                                  thousandSeparator
                                                />
                                              </div>
                                            ) : (
                                              <div className="info-details-label">
                                                <NumberFormat
                                                  value={minInterestVal}
                                                  displayType="text"
                                                  suffix="%"
                                                  thousandSeparator
                                                />
                                                <span className="mx-2">-</span>
                                                <NumberFormat
                                                  displayType="text"
                                                  value={maxInterestVal}
                                                  suffix="%"
                                                  thousandSeparator
                                                />
                                              </div>
                                            )}
                                            <div className="info-details-value">Interest rates</div>
                                          </div>
                                        </Grid>
                                      )}
                                    </>
                                  )}
                                  <Grid item xs={12} md={4}>
                                    <div className="info-row">
                                      <div className="info-details-label">
                                        <NumberFormat
                                          displayType="text"
                                          value={loanAmount}
                                          thousandSeparator
                                          prefix="$"
                                          decimalScale={0}
                                        />
                                      </div>
                                      <div className="info-details-value">Loan amount</div>
                                    </div>
                                  </Grid>
                                  {assetType == 'Car' && (
                                    <Grid item xs={12} md={4}>
                                      <div className="info-row info-row-align">
                                        {selectedLender ? (
                                          <div className="info-details-label">
                                            <NumberFormat
                                              value={residualAmount}
                                              displayType="text"
                                              prefix="$"
                                              thousandSeparator
                                            />
                                          </div>
                                        ) : (
                                          <div className="info-details-label">
                                            <NumberFormat
                                              value={minResidualVal}
                                              displayType="text"
                                              prefix="$"
                                              thousandSeparator
                                            />
                                            <span className="mx-2">-</span>
                                            <NumberFormat
                                              displayType="text"
                                              value={maxResidualVal}
                                              prefix="$"
                                              thousandSeparator
                                            />
                                          </div>
                                        )}
                                        <div className="info-details-value">Residual</div>
                                      </div>
                                    </Grid>
                                  )}
                                  <Grid item xs={12} md={4}>
                                    <div className="info-row info-row-align">
                                      <div className="info-details-label">
                                        {termVal}
                                        &nbsp; Months
                                      </div>
                                      <div className="info-details-value">Loan term</div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={5}>
                                    {selectedLender && findLenderIcon(selectedLender) && (
                                      <img
                                        src={findLenderIcon(selectedLender)}
                                        className="car-icon"
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <Box sx={{ padding: '2rem' }}>
                              <div className="details-application-text">
                                Need to change details on your Application?
                              </div>
                              <div className="details-application-description">
                                No problem! If you need to change any of your application details,
                                contact one of our brokers directly on:
                              </div>

                              <div className="details-application-number">
                                <img src={phoneIcon} className="car-icon" />
                                {isPQProductSelectionGroup ? (
                                  <div className="details-application-number-text">
                                    1300 416 707
                                  </div>
                                ) : (
                                  <div className="details-application-number-text">
                                    1300 787 288
                                  </div>
                                )}
                              </div>
                            </Box>
                          </div>
                        </>
                      </TabPanel>
                      <TabPanel
                        value={value}
                        index={1}
                        className={cls(
                          classes.containerWrapper,
                          'customer-dashboard-container customer-dashboard-doc-Verification-sec',
                        )}
                      >
                        <DocumentVerification {...props} />
                      </TabPanel>
                      <TabPanel
                        value={value}
                        index={2}
                        className={cls(
                          classes.containerWrapper,
                          'customer-dashboard-container customer-dashboard-doc-Verification-sec',
                        )}
                      >
                        <FinaliseCar />
                      </TabPanel>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            <>
              {/* MOBILE VIEW */}
              <NativeSelect
                id="demo-customized-select-native"
                value={value}
                onChange={handleChange}
                input={<BootstrapInput />}
              >
                <option value={0}>Loan Details</option>

                {acceptPrivacyPolicy && showDocumentTab && (
                  <option value={1}>Upload Documents</option>
                )}
                {showDocumentTab &&
                  finaliseCar &&
                  (stage === 'Approved' || stage === 'Settlement') && (
                    <option value={2}>Prepare for Settlement</option>
                  )}
              </NativeSelect>

              {/* LOAN DETAILS */}
              {value === 0 && (
                <Box
                  sx={{
                    backgroundColor: 'white',
                  }}
                >
                  <Box
                    sx={{ padding: '12px 12px 12px 22px', fontSize: '14px', lineHeight: '20px ' }}
                  >
                    {assetTypeName?.toUpperCase()} DETAILS
                  </Box>
                  <Box
                    sx={{
                      padding: '12px 20px 20px 24px',
                    }}
                  >
                    <Box sx={{ fontSize: '22px', lineHeight: '22px', fontWeight: 700 }}>
                      {`${year && year} - ${make && make.name} ${model && model.name}`}
                    </Box>
                    <Box sx={{ fontSize: '14px', lineHeight: '21px', marginTop: '5px' }}>
                      {`${vehicleBodyType || ''} ${' '} ${transmission || ''} `}
                    </Box>
                  </Box>
                  <Box sx={{ padding: '12px 12px 12px 22px' }}>LOAN SUMMARY</Box>
                  <Grid container spacing={3}>
                    {selectedLender && (
                      <>
                        <Grid item xs={12}>
                          <Box sx={{ paddingLeft: '12px', borderBottom: borderStyling }}>
                            <Box sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '33px' }}>
                              <NumberFormat
                                displayType="text"
                                value={monthlyRepayments}
                                prefix="$"
                                thousandSeparator
                              />
                            </Box>
                            <Box sx={{ fontSize: '12px', lineHeight: '33px' }}>
                              {selectFreq} Repayments
                            </Box>
                          </Box>
                        </Grid>
                        {purposeOfUse !== 'Business' && (
                          <Grid item xs={12}>
                            <Box sx={{ paddingLeft: '12px', borderBottom: borderStyling }}>
                              <Box sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '33px' }}>
                                {selectedLender ? (
                                  <NumberFormat
                                    value={customerRate}
                                    displayType="text"
                                    suffix="%"
                                    thousandSeparator
                                  />
                                ) : (
                                  <>
                                    <NumberFormat
                                      value={minInterestVal}
                                      displayType="text"
                                      suffix="%"
                                      thousandSeparator
                                    />
                                    <span className="mx-2">-</span>
                                    <NumberFormat
                                      displayType="text"
                                      value={maxInterestVal}
                                      suffix="%"
                                      thousandSeparator
                                    />
                                  </>
                                )}
                              </Box>
                              <Box sx={{ fontSize: '12px', lineHeight: '33px' }}>Interest rate</Box>
                            </Box>
                          </Grid>
                        )}
                      </>
                    )}
                    {/* //////////////////////////////////////// */}
                    <Grid item xs={12}>
                      <Box sx={{ paddingLeft: '12px', borderBottom: borderStyling }}>
                        <Box sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '33px' }}>
                          <NumberFormat
                            displayType="text"
                            value={loanAmount}
                            thousandSeparator
                            prefix="$"
                            decimalScale={0}
                          />
                        </Box>
                        <Box sx={{ fontSize: '12px', lineHeight: '33px' }}>Loan Amount</Box>
                      </Box>
                    </Grid>
                    {/* //////////////////////////////////////// */}
                    {assetType == 'Car' && (
                      <Grid item xs={12}>
                        <Box sx={{ paddingLeft: '12px', borderBottom: borderStyling }}>
                          <Box sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '33px' }}>
                            {selectedLender ? (
                              <NumberFormat
                                value={residualAmount}
                                displayType="text"
                                prefix="$"
                                thousandSeparator
                              />
                            ) : (
                              <>
                                {' '}
                                <NumberFormat
                                  value={minResidualVal}
                                  displayType="text"
                                  prefix="$"
                                  thousandSeparator
                                />
                                <span className="mx-2">-</span>
                                <NumberFormat
                                  displayType="text"
                                  value={maxResidualVal}
                                  prefix="$"
                                  thousandSeparator
                                />
                              </>
                            )}
                          </Box>
                          <Box sx={{ fontSize: '12px', lineHeight: '33px' }}>Residual</Box>
                        </Box>
                      </Grid>
                    )}
                    {/* //////////////////////////////////////// */}
                    <Grid item xs={12}>
                      <Box sx={{ paddingLeft: '12px', borderBottom: borderStyling }}>
                        <Box sx={{ fontWeight: 700, fontSize: '22px', lineHeight: '33px' }}>
                          {termVal}
                          &nbsp; Months
                        </Box>
                        <Box sx={{ fontSize: '12px', lineHeight: '33px' }}>Loan Term</Box>
                      </Box>
                    </Grid>
                    {/* //////////////////////////////////////// */}
                    <Grid item xs={12}>
                      {selectedLender && findLenderIcon(selectedLender) && (
                        <Box>
                          <img src={findLenderIcon(selectedLender)} className="car-icon" />
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} style={{ borderTop: borderStyling }}>
                      <Box sx={{ padding: '12px 12px 12px 22px' }}>
                        <Box sx={{ fontSize: '15px', fontWeight: 600, lineHeight: '22px' }}>
                          Need to change details on your Application?
                        </Box>
                        <Box
                          sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            marginTop: '1rem',
                            marginBottom: '1rem',
                          }}
                        >
                          No problem! If you need to change any of your application details, contact
                          one of our brokers directly on:
                        </Box>
                        <div className="details-application-number">
                          <img src={phoneIcon} className="car-icon" />
                          {isPQProductSelectionGroup ? (
                            <div className="details-application-number-text">1300 416 707</div>
                          ) : (
                            <div className="details-application-number-text">1300 787 288</div>
                          )}
                        </div>
                      </Box>
                    </Grid>
                    {completedTabs && completedTabs.car && (
                      <Grid item xs={12}>
                        {resumeApplicationFlag && (
                          <div className="email-quote start-new-application text-right mt-0">
                            <button
                              type="button"
                              className="letsgetstarted-btn continue-existing-application"
                              onClick={navigatepage}
                              style={{
                                height: '48px',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              Resume Application <ArrowForwardIcon />
                            </button>
                          </div>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}

              {/* UPLOAD DOCUMENTS */}
              {value === 1 && <DocumentVerification {...props} />}
              {value === 2 && finaliseCar && (stage === 'Approved' || stage === 'Settlement') && (
                <FinaliseCar {...props} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
  //}
  // return (
  //   <ContainerLayoutWrapper>
  //     <div>
  //       <button className=" btn btn-link nav-dashboard" type="button" onClick={dashboardPage}>
  //         <img src={arrowLeft} alt="previouspage" className="leftarrow-img" />
  //         <span className="dashboard-label">Dashboard</span>
  //       </button>
  //     </div>
  //     {quoteInProgress ? (
  //       <Card className="update-loan-details">
  //         <div className="loan-options-message">fetching data. Please wait!</div>
  //         <div className={classes.root}>
  //           <CircularProgress disableShrink size={45} thickness={4.6} />
  //         </div>
  //       </Card>
  //     ) : (
  //       <div className="customer-dashboard">
  //         <div className={classes.root}>
  //           <AppBar position="static" className={classes.appBar} color="inherit">
  //             <Tabs
  //               value={value}
  //               onChange={handleChange}
  //               aria-label="simple tabs example"
  //               classes={{
  //                 indicator: classes.tabsIndicator,
  //                 textColorInherit: classes.textColorInherit,
  //               }}
  //               scrollButtons="auto"
  //               variant="scrollable"
  //             >
  //               <Tab
  //                 label="Loan details"
  //                 classes={{
  //                   root: classes.wrapper,
  //                   selected: classes.selected,
  //                 }}
  //                 {...a11yProps(0)}
  //               />
  //             </Tabs>
  //           </AppBar>
  //           <div className="customer-dashboard-wrapper">
  //             <TabPanel
  //               value={value}
  //               index={0}
  //               className={cls(classes.containerWrapper, 'customer-dashboard-container')}
  //             >
  //               <>
  //                 {completedTabs && completedTabs.car && (
  //                   <>
  //                     <div className="default-car-details">
  //                       <div className="row flex-column-reverse flex-md-row">
  //                         <div className="col-sm">
  //                           <div className="section-details-label">
  //                             {assetTypeName.toUpperCase()} DETAILS
  //                           </div>
  //                         </div>
  //                         {(isPQProductSelectionGroup ||
  //                           (!dfsAccepted && purposeOfUse === 'Personal') ||
  //                           (!dfsAccepted &&
  //                             purposeOfUse === 'Business' &&
  //                             (!incomelistVal.length || !assetListVal.length))) && (
  //                             applicantType === PRIMARY_APPLICANT && (!(pqsubmitToLenderDate !== "" && pqsubmitToLenderDate !== null) && <div className="col-sm">
  //                               <div className="email-quote start-new-application text-right mt-0 mb-4">
  //                                 <button
  //                                   type="button"
  //                                   className="letsgetstarted-btn continue-existing-application"
  //                                   onClick={navigatepage}
  //                                 >
  //                                   Resume Application
  //                                   <span className="image-align">
  //                                     <img src={rightnormal} alt="right" className="right-icon" />
  //                                   </span>
  //                                 </button>
  //                               </div>
  //                             </div>)

  //                           )}
  //                       </div>
  //                       <div className="car-details">
  //                         <div>
  //                           <div className="year-make">
  //                             {`${year && year} - ${make && make.name} ${model && model.name}`}
  //                           </div>
  //                           <div className="engine-bodytype">
  //                             {`${vehicleBodyType || ''} ${' '} ${transmission || ''} `}
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                     <hr />
  //                     <div className="default-car-details">
  //                       <div className="section-details-label">LOAN SUMMARY</div>
  //                       {selectedLender && (
  //                         <div className="loan-summary-section">
  //                           <div className="info-row">
  //                             {selectedLender ? (
  //                               <div className="info-details-label">
  //                                 <NumberFormat
  //                                   value={monthlyRepayments}
  //                                   displayType="text"
  //                                   prefix="$"
  //                                   thousandSeparator
  //                                 />
  //                               </div>
  //                             ) : (
  //                               <div className="info-details-label">
  //                                 <NumberFormat
  //                                   value={minRepaymentVal}
  //                                   displayType="text"
  //                                   prefix="$"
  //                                   thousandSeparator
  //                                 />
  //                                 <span className="mx-2">-</span>
  //                                 <NumberFormat
  //                                   displayType="text"
  //                                   value={maxRepaymentVal}
  //                                   prefix="$"
  //                                   thousandSeparator
  //                                 />
  //                               </div>
  //                             )}
  //                             <div className="info-details-value">
  //                               {selectFreq}
  //                               &nbsp;
  //                               <span>repayments</span>
  //                             </div>
  //                           </div>
  //                           <div className="info-row info-row-align">
  //                             {selectedLender ? (
  //                               <div className="info-details-label">
  //                                 <NumberFormat
  //                                   value={customerRate}
  //                                   displayType="text"
  //                                   suffix="%"
  //                                   thousandSeparator
  //                                 />
  //                               </div>
  //                             ) : (
  //                               <div className="info-details-label">
  //                                 <NumberFormat
  //                                   value={minInterestVal}
  //                                   displayType="text"
  //                                   suffix="%"
  //                                   thousandSeparator
  //                                 />
  //                                 <span className="mx-2">-</span>
  //                                 <NumberFormat
  //                                   displayType="text"
  //                                   value={maxInterestVal}
  //                                   suffix="%"
  //                                   thousandSeparator
  //                                 />
  //                               </div>
  //                             )}
  //                             <div className="info-details-value">Interest rates</div>
  //                           </div>
  //                         </div>
  //                       )}

  //                       <div className="loan-summary-section">
  //                         <div className="info-row">
  //                           <div className="info-details-label">
  //                             <NumberFormat
  //                               displayType="text"
  //                               value={loanAmount}
  //                               thousandSeparator
  //                               decimalScale={0}
  //                               prefix="$"
  //                             />
  //                           </div>
  //                           <div className="info-details-value">Loan amount</div>
  //                         </div>
  //                         <div className="info-row info-row-align">
  //                           <div className="info-details-label">
  //                             {termVal}
  //                             &nbsp; Months
  //                           </div>
  //                           <div className="info-details-value">Loan term</div>
  //                         </div>
  //                       </div>
  //                       {assetType == 'Car' && (
  //                         <div className="loan-summary-section">
  //                           <div className="info-row info-row-align">
  //                             {selectedLender ? (
  //                               <div className="info-details-label">
  //                                 <NumberFormat
  //                                   value={residualAmount}
  //                                   displayType="text"
  //                                   prefix="$"
  //                                   thousandSeparator
  //                                 />
  //                               </div>
  //                             ) : (
  //                               <div className="info-details-label">
  //                                 <NumberFormat
  //                                   value={minResidualVal}
  //                                   displayType="text"
  //                                   prefix="$"
  //                                   thousandSeparator
  //                                 />
  //                                 <span className="mx-2">-</span>
  //                                 <NumberFormat
  //                                   displayType="text"
  //                                   value={maxResidualVal}
  //                                   prefix="$"
  //                                   thousandSeparator
  //                                 />
  //                               </div>
  //                             )}
  //                             <div className="info-details-value">Residual</div>
  //                           </div>
  //                         </div>
  //                       )}
  //                     </div>
  //                   </>
  //                 )}
  //               </>
  //             </TabPanel>
  //             <TabPanel
  //               value={value}
  //               index={1}
  //               className={cls(
  //                 classes.containerWrapper,
  //                 'customer-dashboard-container customer-dashboard-doc-Verification-sec',
  //               )}
  //             >
  //               <DocumentVerification {...props} />
  //             </TabPanel>
  //             <TabPanel
  //               value={value}
  //               index={2}
  //               className={cls(
  //                 classes.containerWrapper,
  //                 'customer-dashboard-container customer-dashboard-doc-Verification-sec',
  //               )}
  //             >
  //               <DocumentList />
  //             </TabPanel>
  //             <div className={cls(value === 0 ? 'stepper-dashboard' : 'hide-stepper-dashboard')}>
  //               <div className="application-process-label">APPLICATION PROCESS</div>
  //               <Stepper
  //                 className="custom-dashboard-stepper"
  //                 disableSteps
  //                 disableSubSteps
  //                 hideSidebar={false}
  //                 forceCollapse
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </ContainerLayoutWrapper>
  // );
};
export default connect(
  (state) => ({
    currentTab: state.tab.tabName,
    make: state.car.vehicleMake,
    model: state.car.vehicleModel,
    year: state.car.manufacturingYear,
    vehicleBodyType: state.car.vehicleBodyType,
    transmission: state.car.transmission,
    purposeOfUse: state.car.purchaseUse,
    maxRepaymentVal: state.estimation.maxRepaymentVal,
    minRepaymentVal: state.estimation.minRepaymentVal,
    minResidualVal: state.estimation.minResidualVal,
    maxResidualVal: state.estimation.maxResidualVal,
    purchasePrice: state.car.purchasePrice,
    maxInterestVal: state.estimation.maxInterestVal,
    minInterestVal: state.estimation.minInterestVal,
    termVal: state.estimation.termVal * 12,
    depositAmount: state.car.depositAmount,
    completedTabs: state.tab.completedTabs,
    personaldetailserror: state.applicants.validationErrors,
    cardetailserror: state.car.validationErrors,
    financedetailserror: state.applicants.validationErrors,
    AcnNumber: state.applicants.AcnNumber,
    abnNumber: state.applicants.abnNumber,
    gstfromDT: state.applicants.gstfromDT,
    selectedLender: state.applicants.selectedLender,
    residualUpdated: state.estimation.residualUpdated,
    prevEmpEndDt: state.applicants.prevEmpEndDt,
    isInitComplete: state.estimation.isInitComplete,
    maxloanamt: state.car.maxloanamt,
    isAuthenticated: state.account.isAuthenticated,
    selectFreq: state.estimation.selectFreq,
    forceNewApplication: state.account.forceNewApplication,
    estimationCompleted: state.tab.completedTabs && state.tab.completedTabs.estimation,
    disableSideBar: true,
    incomelistVal: state.applicants.income,
    assetListVal: state.applicants.assets,
    validationErrors: state.applicants.validationErrors,
    doctype: state.applicants.doctype,
    dfsAccepted: state.applicants.termNconditionChk,
    incomedocType: state.applicants.incomedocType,
    partner: state.applicants.partner,
    customDashboard: state.car.customDashboard,
    monthlyRepayments: state.estimation.monthlyRepayments,
    customerRate: state.estimation.customerRate,
    residualAmount: state.estimation.residualAmount,
    maxResidualpercent: state.estimation.maxResidualpercent,
    quoteInProgress: state.account.quoteInProgress,
    licenceNumber: state.applicants.licenceNumber,
    licenceState: state.applicants.licenceState,
    licenceType: state.applicants.licenceType,
    dlflag: state.applicants.dlflag,
    income: state.applicants.income,
    loanAmount: state.estimation.loanAmount,
    leadSource: state.applicants.leadSource,
    isPQProductSelectionGroup: state.applicants.isPQProductSelectionGroup,
    stage: state.applicants.stage,
    applicationStructure: state.applicants.applicationStructure,
    contactDetails: state.applicants.contactDetails,
    opportunityId: state.applicants.opportunityId,
    acceptPrivacyPolicy: state.applicants.acceptPrivacyPolicy,
    leadSource: state.applicants.leadSource,
    applicantType: state.applicants.applicantType || PRIMARY_APPLICANT,
    assetType: state.car.assetType?.replace(/\s+/g, '').toLowerCase(),
    assetTypeName: state.car.assetTypeName,
    partnerIncome: state.applicants.spouseincome || state.applicants.coapplicantincome,
    passportNumber: state.applicants.passportNumber,
    passportCountry: state.applicants.passportCountry,
    passportExpiry: state.applicants.passportExpiry,
    pqsubmitToLenderDate: state.applicants.pqsubmitToLenderDate,
    getValidationErrorsForField: getValidationErrorsForFieldFunc(state.applicants.validationErrors),
    finaliseCar: state.car.finaliseCar,
  }),
  (dispatch) => ({
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) =>
      dispatch(liteFieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage)),
    onCarDetailsChange: (fieldName, value, scrollToNextFieldOnChage) =>
      dispatch(liteFieldChanged('car', fieldName, value, scrollToNextFieldOnChage)),
    onAccountUpdate: (fieldName, value, scrollToNextFieldOnChage) =>
      dispatch(liteFieldChanged('account', fieldName, value, scrollToNextFieldOnChage)),
    uploadDocument: (doc, isPartner) => dispatch(uploadDocument(doc, isPartner)),
    getDocumentList: (isPartner) => dispatch(getDocumentList(isPartner)),
    getDocument: (documentId) => dispatch(getDocument(documentId)),
    setApplicationStrucutureStepperMenu: (applicationStructure) =>
      dispatch(setApplicationStrucutureStepper(applicationStructure)),
  }),
)(withRouter(CustomerDashboard));
