const numberRuleEvaluator = (rule, value) => {
  if (!value) {
    return null;
  }


  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) {
    return rule.errorMessage;
  }
  if ((value.length) > 9) {
    return rule.errorMessage;
  }
  return null;
};

export default numberRuleEvaluator;
