import Form from 'react-bootstrap/Form';
import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import NumberFormat from 'react-number-format';
const NumberInput = ({
    name,
    value,
    onChange,
    error,
    inputRef = null
}) => (
    <>
        <Form.Label className="finances-form-label">
            {name}
        </Form.Label>
        <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <NumberFormat
                type="text"
                ref={inputRef}
                placeholder="0.00"
                className="form-control"
                allowNegative={false}
                defaultValue={value}
                thousandSeparator
                onValueChange={(values) => { onChange(values); }}
            />
            {error && <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>}
        </InputGroup>
    </>
);

export default NumberInput;
