/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import Form from 'react-bootstrap/Form';
import React from 'react';
import cls from 'classnames';

const renderCheckboxField = ({ input, name, label, meta: { touched, error }, disabled, type }) => (
  <Form.Group className="redux-render-field" controlId="reduxformCheckbox">
    <div className="redux-render-checkboxfield">
      <label className={cls('checkbox-container finances-form-label')}>
        {label}
        <input {...input} type={type} disabled={disabled} name={name} id={name} />
        <span className="checkmark ml-2  mt-1 " />
      </label>
    </div>
    {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
  </Form.Group>
);
export default renderCheckboxField;
