/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import cls from 'classnames';
import NumberFormat from 'react-number-format';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { CircularProgress, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Car from '../../../assets/image/Car.svg';
import Tooltip from '../../components/Tooltip';
import { ROUTES, FREQ_OPTIONS } from '../../util/constants';
import Email from '../../../assets/image/Email.svg';
import logo from '../../../assets/image/stratton-logo-transparent.png';
import UpdateLoanDetails from '../../components/UpdateLoanDetails';
import Copyright from '../../components/Copyright';
import rightnormal from '../../../assets/image/TArrow-Right.svg';
import loanOptionsImgSrc from '../../../assets/image/loan-option.svg';
import processGTMevent from '../../util/processGTMEvent';
import './estimation_details.scss';
import CheckIcon from '@material-ui/icons/Check';
import TextCheckList from '../../components/TextCheckList/TextCheckList';

const EstimationDetails = (props) => {
  const {
    setTab,
    setTabComplete,
    currentTab,
    depositAmount,
    purchasePrice,
    onInputChange,
    selectFreq,
    termVal,
    depositUpdated,
    purchasePriceUpdated,
    residualSwitch,
    minRepaymentVal,
    maxRepaymentVal,
    minInterestVal,
    maxInterestVal,
    createQuote,
    updateLoanAmt,
    onUpdateChange,
    quoteInProgress,
    handleHTTPError,
    clearHTTPErrors,
    loanOptions,
    termValUpdated,
    onSaveQuote,
    purchaseUse,
    assetType,
  } = props;

  useEffect(() => {
    if (setTab) {
      setTab('estimation');
    }
    processGTMevent('estimation', 'pageLoad', 'estimationPageLoad');
  }, [currentTab, setTab]);

  useEffect(() => {
    // Reset flag on mount of component
    onInputChange('isInitComplete', false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const handleSelect = (selectedObject) => {
    onInputChange('selectFreq', selectedObject.value, false);

    createQuote()
      .then(() => {
        // onInputChange('selectFreq', selectedObject.value, false);
      })
      .catch((error) => {
        handleHTTPError(error);
      });
  };
  const handleResidualChange = (e) => {
    onInputChange('residualSwitch', e.target.checked, false).then(() => {
      createQuote()
        .then(() => { })
        .catch((error) => {
          handleHTTPError(error);
        });
    });
  };
  const handleClick = () => {
    onInputChange('isInitComplete', true, false);
    setTabComplete(currentTab);
    processGTMevent('estimation', 'continue', 'estimateNext');
    history.push(ROUTES.initialPersonalDetails);
  };
  const onLogoClick = () => {
    window.open('https://www.strattonfinance.com.au/');
  };
  const updateLoanAmount = () => {
    processGTMevent('estimation', 'update', 'UpdateLoanDetails');
    if (purchasePriceUpdated - depositUpdated < 5000) {
      handleHTTPError({ customMsg: 'Loan Amount cannot be less than $5000.' });
    } else {
      onUpdateChange('depositAmount', depositUpdated, false);
      onUpdateChange('purchasePrice', purchasePriceUpdated, false);
      onInputChange('termVal', termValUpdated, false);
      createQuote()
        .then(() => {
          onInputChange('updateLoanAmt', false, false);
        })
        .catch((error) => {
          handleHTTPError(error);
        });
    }
    processGTMevent('estimation', 'updateConfirm', 'UpdatedLoan');
  };
  return (
    <>
      <div className="estimationComplete-container">
        <div className="estimationComplete-section col-12 col-md-4 col-lg-4">
          <div className="stratton-info-nav-container">
            <img
              src={logo}
              alt="Stratton"
              className="pt-3 stratton-info-logo"
              onClick={onLogoClick}
            />
            <div className="step-content-section">
              <div className="nav-animation">
                <div className="nav-animation-line">
                  <img src={Car} className="nav-animation-car" alt="car" />
                </div>
              </div>
              <div className="stepper-contents">
                <div className="steps-section">
                  <div className="step enabled">STEP 1</div>
                  <div className="step-message enabled ">Repayments estimate</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 2</div>
                  <div className="step-message disabled">Compare loan options</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 3</div>
                  <div className="step-message disabled">Get approved</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 4</div>
                  <div className="step-message disabled">Prepare for Settlement</div>
                </div>
              </div>
            </div>
          </div>
          <div className="stratton-info-footer-container">
            <div className="estimatedetails_loanImage">
              {assetType.toLowerCase() !== 'car' ? (
                <TextCheckList />
              ) : (
                <img src={loanOptionsImgSrc} className="nav-loan" alt="loan" />
              )}
            </div>
            <Copyright />
          </div>
        </div>{' '}
        <div className="estimationComplete-page-details col-12 col-md-8 col-lg-8">
          <div className="Need-help py-3">
            Call us on
            <div className="number-font">1300 787 288</div>
          </div>
          {!updateLoanAmt && (
            <div className="estimationComplete-message">
              <div className="font-emphasis estimationComplete-name">Thank you!</div>
              <div className="font-emphasis estimationComplete-title">
                Based on the supplied information, and assuming you have good credit, here is an
                estimated repayment and interest rate range.
              </div>
              <fieldset className="Quote-estimate-section">
                <legend className="quote-estimate-label px-2">Quote Estimate</legend>
                <button
                  type="button"
                  className="quote-estimate-edit btn btn-link"
                  onClick={() => onInputChange('updateLoanAmt', true, false)}
                >
                  Edit
                </button>
                {FREQ_OPTIONS.map((radioField) => (
                  <ButtonGroup className={cls(' mb-2', 'btn-group-freg')} key={radioField.name}>
                    <Button
                      onClick={() => handleSelect(radioField)}
                      className={cls('btn-freq', selectFreq === radioField.value && 'is-selected')}
                    >
                      <span className="freq-name">{radioField.name}</span>
                    </Button>
                  </ButtonGroup>
                ))}
                <div className="estimation-header">
                  <div className="pl-1 estimate-payment-range col-12 col-lg-6">
                    <div className="repayment-range">
                      <NumberFormat
                        displayType="text"
                        value={minRepaymentVal}
                        thousandSeparator
                        prefix="$"
                      />
                      <div className="mx-2">-</div>
                      <NumberFormat
                        displayType="text"
                        value={maxRepaymentVal}
                        thousandSeparator
                        prefix="$"
                      />
                    </div>
                    <div>Estimated Repayments</div>
                  </div>
                  <div className="pl-1 estimate-interest-range col-12 col-lg-6">
                    <div className="intrest-range">
                      <NumberFormat displayType="text" value={minInterestVal} suffix="%" />
                      <span className='pa-text-change'> p.a.</span>
                      <div className="mx-2">-</div>
                      <NumberFormat displayType="text" value={maxInterestVal} suffix="%" />
                      <span className='pa-text-change'> p.a.</span>
                    </div>
                    <div>Estimated Interest Rates</div>
                  </div>
                </div>
                <div className="estimate-slider">
                  <div className="estimate-loan-amount col-12 col-sm-6 col-lg-6 col-md-12 pl-0">
                    <NumberFormat
                      displayType="text"
                      value={purchasePrice - depositAmount}
                      thousandSeparator
                      prefix="$"
                    />
                    <div className="loan-amt-label py-2">Loan Amount</div>
                  </div>
                  <div className="pl-1 estimate-loan-term col-12 col-sm-6 col-lg-6 col-md-12 mt-2 ">
                    <div className="term-val">
                      <NumberFormat displayType="text" value={termVal} />
                      <span>&nbsp;Years</span>
                    </div>
                    <div className="loan-amt-label py-2">Loan Term</div>
                  </div>
                </div>

                {assetType.toLowerCase() == 'car' && (
                  <div className="custom-control custom-switch residual-switch col-12 col-md-6">
                    <label htmlFor="residualSwitch" className="residual-switch-label">
                      <div className="include-residual">
                        <div>Include Residual?</div>
                        <div className="">
                          <Tooltip
                            label="What's this?"
                            message="Adding a residual (or balloon) will reduce your repayments, and at the end of your loan you will pay the residual amount as a lump sum. You may have the option to refinance the residual payment."
                          />
                        </div>
                      </div>
                    </label>
                    <input
                      type="checkbox"
                      className="custom-control-input "
                      checked={residualSwitch}
                      value={residualSwitch}
                      onChange={(e) => handleResidualChange(e)}
                      id="residualSwitch"
                    />
                    <label
                      className={cls('custom-control-label', residualSwitch && 'checked')}
                      htmlFor="residualSwitch"
                    />
                  </div>
                )}
              </fieldset>
              <div className="details-header">Next steps</div>
              <div className="details-body">
                One of our expert brokers will review your information and contact you shortly. To
                explore your loan options and determine your borrowing capacity, please provide
                additional details on the following screens.
              </div>
              <div className="email-quote py-3">
                {/* <button type="button" className="email-quote-btn" onClick={() => onSaveQuote()}>
                  <img src={Email} alt="email" className="email-icon" />
                  Email Quote
                </button> */}
                <button type="button" className="letsgetstarted-btn" onClick={handleClick}>
                  Continue
                  <span className="right-icon-wrapper">
                    <img src={rightnormal} alt="right" className="right-icon" />
                  </span>
                </button>
              </div>
            </div>
          )}
          {updateLoanAmt && (
            <>
              <UpdateLoanDetails {...props} handleResidualChange={handleResidualChange} />
            </>
          )}
          <div className="disclaimer">
            <b>Disclaimer:&nbsp; </b>The estimated repayments and interest rates are a guide only
            based on the basic information you provided (including loan amount and loan term) and
            assuming a good credit history. It is not a quote or approval for credit and you should
            not enter into commitments based on it. It is not a suggestion or recommendation of any
            particular loan product or lender. The results do not take into account loan
            establishment or monthly administration fees which may be applicable. Applications for
            credit are subject to the lender's credit assessment, loan eligibility and lending
            limits. Terms, conditions, fees and charges apply.
          </div>
        </div>
      </div>
      <Copyright mobileFooter />
    </>
  );
};

export default EstimationDetails;
