import React from 'react';
import PropTypes from 'prop-types';
import Dropzone, { useDropzone } from 'react-dropzone';
import prettyBytes from 'pretty-bytes';
import Table from 'react-bootstrap/Table';

const DropZoneField = ({ handleOnDrop, imagefile, handleDeleteFile, label, disabled }) => (
  <div className="preview-container">
    <Dropzone
      accept="image/jpeg, image/png, image/gif, image/bmp"
      className="upload-container"
      onDrop={handleOnDrop}
      multiple={false}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <div className="container px-0">
          <div className="document-label">{label || 'Related document'}</div>
          <div
            {...getRootProps({
              className: 'dropzone' /* , onClick: (event) => console.log(event)  */,
            })}
          >
            <input {...getInputProps()} />
            <div className="document-box">
              <button className="btn btn-link drag-doc-btn">
                <img
                  src={require('../../../assets/image/FileUpload.svg')}
                  alt="attachment"
                  className="file-upload-img"
                />
                <span className="drag-documents">Drag here or upload documents</span>
              </button>
            </div>
          </div>
          <aside className="documents-attachment-section">
            <div responsive className="attachment-table-section">
              {' '}
              <div className="files-attached">
                {imagefile &&
                  imagefile.map((file) => (
                    <div className="files-row">
                      <img
                        src={require('../../../assets/image/File.svg')}
                        alt="folder"
                        className="files-img"
                      />

                      <div className="file-name">{file.path}</div>

                      <img
                        src={require('../../../assets/image/delete.svg')}
                        onClick={() => {
                          handleDeleteFile(file);
                        }}
                        alt="delete"
                        className="delete-img"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </aside>
        </div>
      )}
    </Dropzone>

    {/* {touched && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>} */}
  </div>
);

DropZoneField.propTypes = {
  error: PropTypes.string,
  handleOnDrop: PropTypes.func.isRequired,
  imagefile: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.file,
      name: PropTypes.string,
      size: PropTypes.number,
    }),
  ),
  onChange: PropTypes.func,
  touched: PropTypes.bool,
};

export default DropZoneField;
