import offsetParent from 'dom-helpers/offsetParent';
import animateScrollTo from './animatedScrollTo';

const animateDuration = 500;

const scrollToNextField = (
  scrollTo,
  ignoreHeaderOffset = false,
  ignoreParentOffset = false,
  changeUrl = false,
) => {
  if (scrollTo) {
    setTimeout(() => {
      const scrollNode = document.scrollingElement || document.documentElement || document.body;
      let fullScreen = false;
      let fieldName = scrollTo;

      if (fieldName === 'toTop') {
        // scrollNode.scrollTop = 0;
        // animateScrollTo(scrollNode, 0, 400);
        scrollNode.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else if (fieldName.indexOf('~') > -1) {
        [fieldName] = fieldName.split('~');
        fullScreen = true;
      }

      const listOfElements = document.getElementsByName(fieldName);
      // eslint-disable-next-line no-nested-ternary
      const scrollableElement = listOfElements.length
        ? listOfElements[0].nodeName === 'A'
          ? listOfElements[1]
          : listOfElements[0]
        : false;

      const selectedElement = scrollableElement
        || (document.getElementsByClassName(fieldName).length
          && document.getElementsByClassName(fieldName)[0]);
      if (selectedElement) {
        const rootElement = selectedElement.parentElement;
        const rootSiblingElement = selectedElement;
        const defaultElement = document.getElementsByClassName('btn-dummy-page');

        const field = rootSiblingElement
          || rootElement
          || (defaultElement && defaultElement[defaultElement.length - 1]);

        const offsetParentElement = offsetParent(field);
        const offsetHeaderElement = document.getElementsByClassName('header-container')[0];

        if (offsetParentElement) {
          const offSet = (ignoreHeaderOffset || fullScreen ? 0 : offsetHeaderElement.offsetTop)
            + (ignoreParentOffset || fullScreen ? 0 : offsetParentElement.offsetTop)
            + field.offsetTop;

          animateScrollTo(scrollNode, offSet, animateDuration);

          if (fieldName && changeUrl) {
            setTimeout(() => {
              window.location.hash = `#${fieldName}`;
            }, animateDuration + 20);
          }
        }
      }
    }, 200);
  }
};

export default scrollToNextField;
