/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import capitalize from 'lodash/capitalize';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import cls from 'classnames';
import { CircularProgress } from '@material-ui/core';
import FormGroup from 'react-bootstrap/FormGroup';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import InputButton from '../../components/InputButton';
import TextField from '../../components/TextField';
import { ROUTES, COMMERCIAL_EQUIPMENT_TYPES, PURCHASE_SOURCE } from '../../util/constants';
import AmountRangeSelector from '../../components/AmountRangeSelector';
import FindMakeFilter from '../../components/FindMakeFilter';
import processGTMevent from '../../util/processGTMEvent';
import rightnormal from '../../../assets/image/right-normal.svg';
import '../../components/input-field.scss';
import './carfinance.scss';

const useStyles = makeStyles(() =>
  createStyles({
    amountRangeRoot: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    sliderContainer: {
      marginLeft: 0,
      marginRight: 0,
      width: '100%',
    },
    sliderThumb: {
      marginLeft: '-4px',
    },
    manualCarQueryContainer: {},
  }),
);
const CommercialEquipmentAssetType = (props) => {
  const {
    setTab,
    currentTab,
    assetType,
    isAuthenticated,
    forceNewApplication,
    manufacturingYear,
    getValidationErrorsForField,
    getAllVehicleYears,
    noErrors,
    vehicleYears,
    createQuote,
    purchaseSource,
    depositAmount,
    purchasePrice,
    onInputChange,
    scrollToNext,
    validationErrors,
    setTabComplete,
    handleHTTPError,
    commercialType,
    vehicleMake,
    vehicleModel,
    validationCheck,
    onEstimateChange,
    termVal,
    quoteInProgress,
    vehicleMakeModel,
    purchasePriceComplete,
    depositPriceComplete,
    nextFieldNameToFocus,
    progressValue,
    reQuote,
    completedTabs,
    carDetails,
    loanDetails,
    opportunityId,
    flagChange,
    skipcommercialType,
    carCondition
  } = props;
  const history = useHistory();
  const classes = useStyles({});
  const [skipmakeModel, setSkipmakemodel] = useState(false);

  useEffect(() => {
    processGTMevent(assetType, 'PQ-PageLoad', 'Page Load');
    reQuote();
    getAllVehicleYears('').then(() => {});
    if (setTab) {
      if (
        completedTabs.car &&
        completedTabs.estimation &&
        completedTabs.applicants &&
        completedTabs.finance_details
      ) {
        setTab('compare_loan_options');
      } else if (completedTabs.car && completedTabs.estimation && completedTabs.applicants) {
        setTab('finance_details');
      } else {
        setTab('car');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextFieldNameToFocus) {
      if (
        (nextFieldNameToFocus === 'purchasePrice' ||
          nextFieldNameToFocus === 'purchase-price-selector') &&
        purchaseSource
      ) {
        return;
      }
      if (nextFieldNameToFocus === 'deposit') {
        window.location.hash = '#deposit';
        return;
      }

      scrollToNext(nextFieldNameToFocus, true, true);
    }
  }, [nextFieldNameToFocus, purchaseSource, scrollToNext]);

  const currentData = new Date();
  const currentYear = currentData.getFullYear();
  const vehicleYearsformatted = reverse(sortBy(vehicleYears));
  const vehicleYearsMax10 = vehicleYearsformatted.reduce((accum, yrOption, idx) => {
    if (idx > 9) return accum;
    accum.push({ name: String(yrOption), value: yrOption });
    return accum;
  }, []);
  vehicleYearsMax10.push({ name: 'Older', value: String(currentYear - 10) });

  const handleClick = () => {
    flagChange('carDetailsChanged', false);
    processGTMevent(assetType,'termValue', termVal);
    processGTMevent('PQCommercialFinance-Details-Next','PQCommercialFinanceDetails-Next','Clicked');
    if (validationErrors && validationErrors.length) {
      validationCheck();
      return;
    }
    if (purchasePrice - depositAmount < 5000) {
      validationCheck('Loan Amount cannot be less than $5000.');
      return;
    }
    noErrors();
    if (opportunityId) {
      carDetails();
      loanDetails()
        .then(() => {
          reQuote();
          setTabComplete(currentTab);
          localStorage.setItem(`pageCompleted${opportunityId}`, 'car');
          history.push(ROUTES.personal);
        })
        .catch((error) => {
          handleHTTPError(error, history);
        });
    } else {
      createQuote()
        .then(() => {
          setTabComplete(currentTab);
          history.push(ROUTES.personal);
        })
        .catch((error) => {
          handleHTTPError(error, history);
        });
    }
  };

  const handleFieldChange = (fieldName, value, scrollToNextFieldOnChage) => {
    onInputChange(fieldName, value, scrollToNextFieldOnChage);
    if (
      fieldName === 'purchaseUse' ||
      fieldName === 'purchaseSource' ||
      fieldName === 'purchasePrice' ||
      fieldName === 'depositAmount'
    ) {
      createQuote();
    }
  };

  const handleSkip = (fieldName) => {
    onInputChange(fieldName, true, false);
  };
  const maxTermVal = 7;
  return (
    <>
      <div>
        <div className="container-section car-applicants-main-container">
          <div
            className="content-body car-applicants-main-tab-container car-subscreen-container"
            id="car"
          >
            <div className="car-details-header">
              <div className="car-details-title">Tell us about the asset you're looking to buy</div>
              <div className="car-details-subtitle">
                Providing more information will improve the accuracy of your rate and repayments
              </div>
            </div>
            <hr className="car-details-border" />
            <div className="car-applicants-main-subcontainer car-details-content-block">
              <div className="row mx-0">
                <div className="col-12 car-applicants-main-section px-0">
                  <Form noValidate className="car-applicants-main-form">
                    {/* for New */}
                    <FindMakeFilter
                      label={
                        <>
                          <span>When was the {assetType.toLowerCase()} manufactured?</span>
                        </>
                      }
                      fields={vehicleYearsformatted.map((yr) => ({
                        name: String(yr),
                        value: yr,
                      }))}
                      onChange={handleFieldChange}
                      fieldName="manufacturingYear"
                      value={manufacturingYear}
                      btnClass="full-screen-mode"
                      labelClass="car-full-screen-label"
                      rootContainerClass="year-class car-manufactured-year"
                      loadmore
                      isFullScreen
                      isreturnObjectval
                      getvalidationerrors={getValidationErrorsForField}
                      id="car-manufactured-year"
                    />
                    {manufacturingYear && !skipcommercialType && (
                      <>
                        <FindMakeFilter
                          label={
                            <>
                              <span>What is the commercial asset type?</span>
                            </>
                          }
                          fields={COMMERCIAL_EQUIPMENT_TYPES}
                          onChange={handleFieldChange}
                          fieldName="commercialType"
                          rootContainerClass="find-make-container commercial-type"
                          value={(commercialType && commercialType.value) || commercialType}
                          labelClass="car-full-screen-label"
                          enableSearch
                          getvalidationerrors={getValidationErrorsForField}
                          id="commercial-type"
                          loadmore
                        />

                        <Form.Row
                          className={cls('skip-action-container', commercialType && 'hidden')}
                        >
                          <FormGroup as={Col} lg={9} md={10}>
                            <div className="skip-action-section">
                              <div className="skip-action-align">
                                <span className="notsure">Not sure? </span>
                                <button
                                  type="button"
                                  className="btn btn-default btn-skip-page"
                                  hidden={commercialType}
                                  onClick={() => {
                                    onInputChange('skipcommercialType', true, false);
                                  }}
                                >
                                  Skip
                                  <i className="fa fa-chevron-right" />
                                </button>
                              </div>
                            </div>
                          </FormGroup>
                        </Form.Row>
                      </>
                    )}

                    {(commercialType || skipcommercialType) && !skipmakeModel && (
                      <>
                        <div
                          className="car-rego-question-container rego
                         "
                        >
                          <div className="label car-main-screen-label pb-4 make-model">
                            What's the make and model of the {assetType.toLowerCase()}?
                          </div>

                          <TextField
                            fieldName="vehicleMake"
                            value = {vehicleMake?.name ? vehicleMake?.name : vehicleMake}
                            label={`${capitalize(assetType)} Make`}
                            placeholder="Enter make"
                            getvalidationerrors={getValidationErrorsForField}
                            onChange={handleFieldChange}
                            inline
                            id="vehicleMake"
                          />

                          <TextField
                            fieldName="vehicleModel"
                            value={vehicleModel?.name ? vehicleModel?.name : vehicleModel}
                            label={`${capitalize(assetType)} Model`}
                            placeholder="Enter model"
                            getvalidationerrors={getValidationErrorsForField}
                            onChange={handleFieldChange}
                            inline
                            id="vehicleModel"
                          />

                          <div className="button-container">
                            <button
                              type="button"
                              hidden={vehicleMake && vehicleModel && vehicleMakeModel}
                              className="btn btn-primary btn-next-page"
                              onClick={() => {
                                onInputChange('vehicleMakeModel', true, false);
                                processGTMevent('Commercial Equipment','vehicleMakeValue',vehicleMake);
                                processGTMevent('Commercial Finance','vehicleModelValue',vehicleModel);
                              }}
                            >
                              Next
                              <span className="pl-4">
                                <img src={rightnormal} alt="right" className="right-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                        <Form.Row
                          className={cls(
                            'skip-action-container',
                            (vehicleMake || vehicleModel) && 'hidden',
                          )}
                        >
                          <FormGroup as={Col} lg={9} md={10}>
                            <div className="skip-action-section">
                              <div className="skip-action-align">
                                <span className="notsure">Not sure? </span>
                                <button
                                  type="button"
                                  className="btn btn-default btn-skip-page"
                                  hidden={vehicleMake && vehicleModel}
                                  onClick={() => {
                                    setSkipmakemodel(true);
                                    onInputChange('skipmakeModel', true, false);
                                  }}
                                >
                                  Skip
                                  <i className="fa fa-chevron-right" />
                                </button>
                              </div>
                            </div>
                          </FormGroup>
                        </Form.Row>
                      </>
                    )}
                    {(carCondition !== 'New' && skipmakeModel || vehicleMakeModel) && (
                      <InputButton
                        label={
                          <>
                            <span>Where are you planning to purchase the car from?</span>
                          </>
                        }
                        fields={PURCHASE_SOURCE}
                        onChange={handleFieldChange}
                        fieldName="purchaseSource"
                        value={purchaseSource}
                        formGroupClass="car-purchase-from"
                        getvalidationerrors={getValidationErrorsForField}
                        id="car-purchase-from"
                      />
                    )}
                    {purchaseSource && (
                      <>
                        <div
                          className={cls(
                            'amount-range-container purchase-price-selector car-purchase-price',
                            'scrollable-anchor-tag',
                          )}
                        >
                          <a name="car-purchase-price" href="#car-purchase-price">
                            &nbsp;
                          </a>
                          <div className="label car-main-screen-label">
                            What is the estimated purchase price of the car?
                          </div>
                          <AmountRangeSelector
                            label={
                              <>
                                <span className="label ">Purchase price</span>
                              </>
                            }
                            onChange={onInputChange}
                            fieldName="purchasePrice"
                            defaultValue={purchasePrice}
                            colsm={12}
                            colmd={8}
                            oncreateQuote={createQuote}
                            getvalidationerrors={getValidationErrorsForField}
                            minValue={5000}
                            maxValue={250000}
                            step={1000}
                            classes={{
                              root: classes.amountRangeRoot,
                              sliderContainer: classes.sliderContainer,
                            }}
                            sliderClasses={{
                              thumb: classes.sliderThumb,
                            }}
                          />
                        </div>

                        <div className="button-container">
                          <button
                            type="button"
                            className="btn btn-primary btn-next-page"
                            hidden={purchasePriceComplete}
                            onClick={() => {
                              onInputChange('purchasePriceComplete', true, false);
                              processGTMevent(assetType,'purchasePriceValue', purchasePrice);
                            }}
                          >
                            Next
                            <span className="pl-4">
                              <img src={rightnormal} alt="right" className="right-icon" />
                            </span>
                          </button>
                        </div>
                      </>
                    )}
                    {purchasePriceComplete && (
                      <>
                        <div className="deposit scrollable-anchor-tag">
                          <a name="deposit" href="#deposit">
                            &nbsp;
                          </a>
                        </div>
                        <div className="amount-range-container deposit-price-selector">
                          <AmountRangeSelector
                            label={
                              <>
                                <span className="label">Deposit / Trade-in</span>
                              </>
                            }
                            onChange={onInputChange}
                            fieldName="depositAmount"
                            defaultValue={depositAmount}
                            colsm={12}
                            colmd={8}
                            minValue={0}
                            tooltip="Enter the combined amount for the trade in value of your current car, and any cash deposit you will be contributing to the purchase"
                            oncreateQuote={createQuote}
                            maxValue={purchasePrice - 5000}
                            step={1000}
                            disabled={purchasePrice - 5000 <= 0}
                            getvalidationerrors={getValidationErrorsForField}
                            classes={{
                              root: classes.amountRangeRoot,
                              sliderContainer: classes.sliderContainer,
                            }}
                            sliderClasses={{
                              thumb: classes.sliderThumb,
                            }}
                          />
                          <div className="form-row pb-4">
                            <div className=" car-applicants-loan-form col-md-5 ">
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}

                              <div className="car-applicants-main-loanamount mt-4">
                                <NumberFormat
                                  displayType="text"
                                  value={purchasePrice - depositAmount}
                                  className="loan-amount-value"
                                  prefix="$"
                                  thousandSeparator
                                />
                              </div>
                              <label htmlFor="car-applicants-main-loanamount-label label">
                                Estimated loan amount
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="button-container">
                          <button
                            type="button"
                            className="btn btn-primary btn-next-page"
                            hidden={depositPriceComplete}
                            onClick={() => {
                              onInputChange('depositPriceComplete', true, false);
                              processGTMevent(assetType,'depositAmountValue', depositAmount);
                            }}
                          >
                            Next
                            <span className="pl-4">
                              <img src={rightnormal} alt="right" className="right-icon" />
                            </span>
                          </button>
                        </div>
                      </>
                    )}
                    {depositPriceComplete && purchaseSource && (
                      <div
                        className={cls(
                          'amount-range-container loan-term-selector car-loan-term',
                          'scrollable-anchor-tag',
                        )}
                      >
                        <div className="label ">Over what term do you want the loan?</div>
                        <div className="term scrollable-anchor-tag">
                          <a name="term" href="#term">
                            &nbsp;
                          </a>
                        </div>

                        <div className="amount-range-container term-val-selector">
                          <AmountRangeSelector
                            label={
                              <>
                                <span className="label">Loan term</span>
                              </>
                            }
                            onChange={onEstimateChange}
                            fieldName="termVal"
                            defaultValue={termVal}
                            oncreateQuote={createQuote}
                            minValue={1}
                            maxValue={maxTermVal}
                            step={1}
                            termSlider
                            getvalidationerrors={getValidationErrorsForField}
                            classes={{
                              root: classes.amountRangeRoot,
                              sliderContainer: classes.sliderContainer,
                            }}
                            sliderClasses={{
                              thumb: classes.sliderThumb,
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="footer-actions">
                      <input
                        type="button"
                        id="btnsubmit"
                        className="btn-dummy-page"
                        style={{ visibility: 'hidden' }}
                      />
                      {!!purchasePrice &&
                        purchasePriceComplete &&
                        depositPriceComplete &&
                        !!termVal &&
                        !!purchaseSource && (
                          <button
                            type="button"
                            data-test="carmainscreen-estimatepage"
                            className="btn  btn-navigate-page"
                            onClick={handleClick}
                            disabled={quoteInProgress}
                          >
                            {quoteInProgress ? (
                              <CircularProgress color="inherit" size={24} thickness={2.6} />
                            ) : (
                              <>
                                Next
                                <span className="pl-4">
                                  <img src={rightnormal} alt="right" className="right-icon" />
                                </span>
                              </>
                            )}
                          </button>
                        )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommercialEquipmentAssetType;
