import React, { Component } from 'react';
import * as log from '../../util/log.js';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
    log.error('componentDidCatch', error);
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <div
            className="container"
            style={{
              marginTop: '5rem',
              marginBottom: '12rem',
              minHeight: '80%',
              textAlign: 'center',
            }}
          >
            <img
              className="center-block"
              style={{ width: '12rem', height: '12rem', marginBottom: '3rem' }}
              src="https://assets.stratton.com.au/quickapp/error.svg"
            />
            <h3>Something went wrong!</h3>
            {/*  <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details> */}
            <p style={{ paddingTop: '1rem', lineHeight: '1.8' }}>
              Refresh page or contact us on
              <a href="tel:1300787288" style={{ whiteSpace: 'nowrap' }}>
                {' '}
                1300 787 288
              </a>
            </p>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
