/* eslint-disable import/no-cycle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Modal, Row, Col, Form, Container } from 'react-bootstrap';
import remove from 'lodash/remove';
import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import InputGroup from 'react-bootstrap/InputGroup';
import renderNumberFormat from '../../../components/ReduxRenderField/renderNumberFormat';
import { formatAmount, normalizeAmount } from '../../../lib/formatter';
import renderCheckboxField from '../../../components/ReduxRenderField/renderCheckboxField';
import renderSelectField from '../../../components/ReduxRenderField/renderSelectField';

import {
  FREQ_OPTIONS,
  LIABILITIES_TYPES,
  LIABILITY_CREDIT_CARD_OPTION,
  LIABILITY_OVERDRAFT_OPTION,
  LIABILITY_VEHICAL_LOAN_OPTION,
  LIABILITY_TAX_DEBT_OPTION,
  LIABILITIES_RELATED_ASSET,
  ASSETS_LABEL_VALUE_MAP,
} from '../../../util/constants';
import './liabilitydetails.scss';
import { RetrieveOpportunity } from '../../../Actions/car';
import { liabilities } from '../../../Actions/finances';
import renderSelectButtonField from '../../../components/ReduxRenderField/renderSelectButtonField';
import getFinanceOptions from '../../../util/constantFinances';
import processGTMevent from '../../../util/processGTMEvent';
// const imageIsRequired = (value) => (!value ? 'Required' : undefined);

export const getFilteredAssetsByLiability = (assetsLists, relatedLiability) =>
  filter(
    assetsLists,
    (assetData) =>
      assetData.category === LIABILITIES_RELATED_ASSET[relatedLiability] && assetData.underfinance,
  );

const LiabilityForm = ({
  liabilityDetails,
  assetsLists,
  onCancel,
  onUpdateLiability,
  liabilityApi,
  // onDelete,
  handleSubmit,
  pristine,
  submitting,
  isAdd,
  category,
  setFieldValue,
  retrieveOpportunity,
  opportunityId,
  purchaseSource,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [applicants, setApplicants] = useState({outstanding:0, repayment:0})
  const Title = get(find(LIABILITIES_TYPES, ['value', liabilityDetails.category]), 'name', 'N/A');
  const relatedAssets = getFilteredAssetsByLiability(
    assetsLists,
    liabilityDetails?.category || category,
  );
  const relatedAssetsOptions = relatedAssets.map((asset, idx) => ({
    value: asset.id,
    name: `${ASSETS_LABEL_VALUE_MAP[asset.category]} - $${formatAmount(asset.estimateVal)}`,
    selected: idx === 0,
  }));

  const submit = (data) => {
    processGTMevent('finances', 'liabilities', 'addLiability');
    setSubmitting(true);
    // onUpdateLiability({ ...liabilityDetails, ...data });
    if (liabilityApi) {
      liabilityApi({ ...liabilityDetails, ...data })
        .then((response) => {
          onUpdateLiability({
            ...data,
            id: response.data[0].id,
          });
          retrieveOpportunity();
          localStorage.setItem(`pageCompleted${opportunityId}`, 'liabilities-details');
          setSubmitting(false);
          // addAssetId(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          setSubmitting(false);
        });
    }
  };

  const handlerepayment =(event) => {
    setApplicants(prev =>({...prev, repayment:event.target.value}));
  }

  const handleOutstanding =(event) => {
    setApplicants(prev =>({...prev, outstanding:event.target.value}));
  }

  const handleFinancierOption = (event) => {
    if (event?.target?.value === LIABILITY_TAX_DEBT_OPTION) {
      setFieldValue('financier', 'ATO (Australian Taxation Office)');
    }
  };

  return (
    <div>
      <Modal show size="md" onHide={onCancel} className="modal-wrapper finances-modal-wrapper">
        <Modal.Header closeButton className="finances-header-container">
          <Row className="ml-1">
            <Modal.Title className="finances-header-section">
              {isAdd ? 'Add liability' : Title}
            </Modal.Title>
          </Row>
        </Modal.Header>

        <Modal.Body className="p-0">
          <Container>
            <Row className="show-grid">
              <Col className="income-column">
                <Form noValidate onSubmit={handleSubmit(submit)}>
                  {isAdd && (
                    <>
                      <Form.Group>
                        <Form.Label className="finances-form-label">Liability type</Form.Label>
                        <Field
                          type="select"
                          placeholder="Select"
                          label="Liability type"
                          name="category"
                          options={LIABILITIES_TYPES}
                          component={renderSelectField}
                          onChange={handleFinancierOption}
                          required
                        />
                      </Form.Group>
                    </>
                  )}
                  {!!relatedAssetsOptions.length && (
                    <Form.Group>
                      <Form.Label className="finances-form-label">Related Asset type</Form.Label>
                      <Field
                        type="select"
                        placeholder="Select"
                        label="Related Asset type"
                        name="relatedAsset"
                        options={relatedAssetsOptions}
                        component={renderSelectField}
                        required
                      />
                    </Form.Group>
                  )}
                  <Form.Group>
                    <Form.Label className="finances-form-label">Financier</Form.Label>
                    <Field
                      type="select"
                      placeholder="Select"
                      label="Financier"
                      name="financier"
                      options={getFinanceOptions(category)}
                      component={renderSelectField}
                      required
                      disabled={category === LIABILITY_TAX_DEBT_OPTION}
                    />
                  </Form.Group>
                  {(category === LIABILITY_CREDIT_CARD_OPTION ||
                    category === LIABILITY_OVERDRAFT_OPTION) && (
                    <Field
                      label="Limit"
                      name="limit"
                      type="text"
                      placeholder="0.00"
                      PrependComponent={() => (
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                        </InputGroup.Prepend>
                      )}
                      required
                      component={renderNumberFormat}
                      normalize={normalizeAmount}
                    />
                  )}
                  <Field
                    label="Outstanding balance"
                    name="outstanding"
                    type="text"
                    placeholder="0.00"
                    PrependComponent={() => (
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                      </InputGroup.Prepend>
                    )}
                    required
                    component={renderNumberFormat}
                    normalize={normalizeAmount}
                    onChange={handleOutstanding}
                  />
                  <Field
                    label="Repayments"
                    name="repayments"
                    type="text"
                    placeholder="0.00"
                    PrependComponent={() => (
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                      </InputGroup.Prepend>
                    )}
                    required
                    component={renderNumberFormat}
                    normalize={normalizeAmount}
                    onChange={handlerepayment}
                  />
                  <Form.Group>
                    <Form.Label className="finances-form-label">Repayments frequency</Form.Label>
                    <Field
                      label="Repayments frequency"
                      name="frequency"
                      options={FREQ_OPTIONS}
                      component={renderSelectButtonField}
                      classes={{
                        container: 'frequency-select-container',
                        inputButton: 'frequency-select-buttons',
                      }}
                      required
                    />
                  </Form.Group>
                  {
                  category === LIABILITY_VEHICAL_LOAN_OPTION && purchaseSource === "Dealer" && (
                    <Field
                      label="Trade In"
                      name="payout"
                      type="checkbox"
                      component={renderCheckboxField}
                    />
                  )}

                  <hr className="horizantal-line" />
                  <Modal.Footer className="footer-container px-0">
                    {/*   <ButtonToolbar> */}

                    <Button type="button" className="footer-cancel" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={ parseInt(applicants.outstanding) > 0 && (parseInt(applicants.repayment) === 0 || applicants.repayment === "")|| isSubmitting || pristine || submitting}
                      className="footer-btn mr-0"
                      block
                    >
                      {isAdd ? 'Save' : 'Update'}
                    </Button>
                    {/*   </ButtonToolbar> */}
                  </Modal.Footer>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const FORM_NAME = 'liabilityDetails';

const EnhancedLiabilityForm = reduxForm({
  form: FORM_NAME,
  validate: (values, { assetsLists, liabilityDetails }) => {
    const relatedAssets = getFilteredAssetsByLiability(
      assetsLists,
      liabilityDetails?.category || values.category,
    );
    const errors = {};

    if (!values.category) {
      errors.category = 'Required';
    }
    if (!values.financier) {
      errors.financier = 'Required';
    }
    if (!values.outstanding) {
      errors.outstanding = 'Required';
    } else if (Number.isNaN(values.outstanding)) {
      errors.age = 'Must be a number';
    } else if (Number(values.outstanding) < 1) {
      errors.age = 'outstanding balance cannot be less than one';
    }
    if (!values.limit) {
      errors.limit = 'Required';
    } else if (Number.isNaN(values.limit)) {
      errors.age = 'Must be a number';
    } else if (Number(values.limit) < 1) {
      errors.age = 'limit amount should be greater than one';
    }
    if (!values.repayments) {
      errors.repayments = 'Required';
    } else if (Number.isNaN(values.repayments)) {
      errors.age = 'Must be a number';
    } else if (Number(values.repayments) < 1) {
      errors.age = 'net liability value should be greater than one';
    }
    if (!values.frequency) {
      errors.frequency = 'Required';
    }
    if (relatedAssets.length && !values.relatedAsset) {
      errors.relatedAsset = 'Required';
    }
    return errors;
  },
})(LiabilityForm);

const selector = formValueSelector(FORM_NAME); // <-- same as form name

export default connect(
  (state) => {
    // can select values individually
    const category = selector(state, 'category');
    return {
      assetsLists: state.applicants.assets,
      liabilities: state.applicants.liabilities,
      category,
    };
  },
  (dispatch) => ({
    liabilityApi: (liabData) => dispatch(liabilities(liabData)),
    setFieldValue: (fieldName, fieldValue) => dispatch(change(FORM_NAME, fieldName, fieldValue)),
    retrieveOpportunity: () => dispatch(RetrieveOpportunity()),
  }),
)(EnhancedLiabilityForm);
