import { API } from 'aws-amplify';
import find from 'lodash/find';
import moment from 'moment';
import { API_NAMES } from '../../awsconfig';
import apiUris from '../../apiUris';
import { isLessThanTwo } from '../lib/utils';
import { liteFieldChanged } from './FieldChanged';
import { EMPTYPE_SELF } from '../util/constants';
import { deleteIncome, income, updateIncome } from './finances';
import { PAY_G, SELF_EMPLOYED } from '../containers/Finances/Income/IncomeDetails';
import * as log from '../util/log';

export const DELETE_CURRENT_EMPLOYMENT_SECONDARY = 'DELETE_CURRENT_EMPLOYMENT_SECONDARY_APPLICANTS';
export const DELETE_PREV_EMPLOYMENT = 'DELETE_PREV_EMPLOYMENT_APPLICANTS';
export const DELETE_ALL_EMPLOYMENTS = 'DELETE_ALL_EMPLOYMENTS_APPLICANTS';
export const SET_APPLICATION_STRUCTURE = 'SET_APPLICATION_STRUCTURE_APPLICANTS';
export const RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM = 'RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM';

export const setApplicationtructure = (applicationStructure) => ({
  type: SET_APPLICATION_STRUCTURE,
  applicationStructure,
});
export const updateInsuranceConsent = (value) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;
  const myInit = {
    response: value,
    body: {
      insuranceConsentAccepted: value,
    },
    headers: {},
  };
  return API.post(
    API_NAMES.UPDATE_INSURANCE_ACCEPTANCE,
    apiUris.UPDATE_INSURANCE_ACCEPTANCE(opportunityId, applicantId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.UPDATE_INSURANCE_ACCEPTANCE}. opportunityId: ${opportunityId}`,
      );
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.UPDATE_INSURANCE_ACCEPTANCE}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const deleteGeneric = (deleteId) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;
  const myInit = {
    response: true,
    body: {
      id: deleteId || null,
    },

    headers: {},
  };
  return API.post(
    API_NAMES.DELETE_GENERIC,
    apiUris.DELETE_GENERIC(opportunityId, applicantId),
    myInit,
  )
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.DELETE_GENERIC}. opportunityId: ${opportunityId}`);
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.DELETE_GENERIC}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const resetAllApplicationDataForNewForm = { type: RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM };

export const shouldUpdateIncome = (incomeList, empName) => {
  if (!empName) return false;
  const paygIncomeExist = find(incomeList, ['category', PAY_G]);
  const incomedata = find(incomeList, ['applicantEmployerName', empName]);

  return (!!paygIncomeExist && incomedata) || false;
};

export const updateEmployerIncome = (empName, employmentId) => async (dispatch, getState) => {
  const state = getState();
  const { income: incomeList } = state.applicants;

  const incomedata = shouldUpdateIncome(incomeList, empName);
  if (incomedata) {
    const updateIncomeData = { ...incomedata, employmentId };
    await dispatch(income(updateIncomeData));
    dispatch(updateIncome(updateIncomeData));
  }

  return Promise.resolve();
};

export const deleteEmployerIncome = (empName, selfEmployed = false) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { income: incomeList } = state.applicants;

  let incomedata = shouldUpdateIncome(incomeList, empName);

  if (selfEmployed) {
    incomedata = find(incomeList, ['category', SELF_EMPLOYED]);
  }

  if (incomedata) {
    await dispatch(deleteGeneric(incomedata.id));
    dispatch(deleteIncome(incomedata));
  }

  return Promise.resolve();
};

export const deleteCurrEmploymentSecondary = () => async (dispatch, getState) => {
  const state = getState();
  const { currentEmpId2, prevEmpId, employmentStartDate2, employerName2 } = state.applicants;
  const isDeletePrev = !isLessThanTwo(employmentStartDate2);
  const employerName = employerName2;
  await Promise.all([
    currentEmpId2 ? dispatch(deleteGeneric(currentEmpId2)) : Promise.resolve(),
    isDeletePrev && prevEmpId ? dispatch(deleteGeneric(prevEmpId)) : Promise.resolve(),
  ]);

  dispatch({ type: DELETE_CURRENT_EMPLOYMENT_SECONDARY, payload: { isDeletePrev } });
  await dispatch(deleteEmployerIncome(employerName));
  return Promise.resolve();
};

export const deletePrevEmployment = (prevEmpId) => (dispatch, getState) => {
  return dispatch(deleteGeneric(prevEmpId)).then(
    () => {
      dispatch({ type: DELETE_PREV_EMPLOYMENT });
      return Promise.resolve();
    },
    () => Promise.reject(),
  );
};

// export const deleteCurrEmploymentSecondary = () => async (dispatch, getState) => {
//   const state = getState();
//   const { currentEmpId2, prevEmpId, employmentStartDate2, mainEmployment } = state.applicants;
//   const isDeletePrev = isLessThanTwo(employmentStartDate2);
//   await Promise.all([
//     isDeletePrev && mainEmployment === 'Yes' ? dispatch(deletePrevEmployment()) : Promise.resolve(),
//     currentEmpId2 ? dispatch(deleteGeneric(currentEmpId2)) : Promise.resolve(),
//   ]);
//   dispatch({ type: DELETE_CURRENT_EMPLOYMENT_SECONDARY, payload: { isDeletePrev } });

//   return Promise.resolve();
// };

export const deleteAllEmployments = (clearIncome) => async (dispatch, getState) => {
  const state = getState();
  const { currentEmpId, employerName, currentEmpId2, employerName2, prevEmpId } = state.applicants;
  await Promise.all([
    currentEmpId ? dispatch(deleteGeneric(currentEmpId)) : Promise.resolve(),
    currentEmpId2 ? dispatch(deleteGeneric(currentEmpId2)) : Promise.resolve(),
    prevEmpId ? dispatch(deleteGeneric(prevEmpId)) : Promise.resolve(),
  ]);

  if (clearIncome) {
    await dispatch(deleteEmployerIncome(employerName));
    await dispatch(deleteEmployerIncome(employerName2));
    await dispatch(deleteEmployerIncome(null, true));
  }

  dispatch({ type: DELETE_ALL_EMPLOYMENTS });

  return Promise.resolve();
};

export const employmentInsUpd = () => (dispatch, getState) => {
  const state = getState();
  const {
    employmentStatus,
    employmentStartDate,
    behalfOfCompany,
    trusteeType,
    lastFYNetProfit,
    currentEmpId,
    isAbnknown,
    abnNumber,
    acn,
    entityName,
    abnRegistrationDate,
    abnStatus,
    entityType,
    gstRegistrationstatus,
    gstRegistrationdate,
    abnState,
    abrEntityType,
    equifaxOrgId,
    abnVerified,
    employerName,
    addEmployment,
    currentEmpId2,
    employmentStatus2,
    employmentStartDate2,
    employerName2,
    mainEmployment,
    employeeDuration,
    prevEmpId,
    prevEmpType,
    prevEmpStartDt,
    prevEmpEndDt,
  } = state.applicants;

  const { opportunityId, applicantId } = state.applicants;
  const { purchaseUse } = state.car;

  let filteredEntityType;
  if (entityType === 'Individual/Sole Trader') {
    filteredEntityType = 'Sole Trader';
  } else {
    filteredEntityType = entityType;
  }
  let EMPLOYMENT_PAYLOAD = [];
  let abnDetails = {};

  abnDetails = {
    abn: abnNumber,
    acn,
    abnRegistrationDate: abnRegistrationDate || null,
    abnState,
    abnStatus,
    abrEntityType,
    abnVerified,
    entityType: filteredEntityType || entityType,
    entityName,
    equifaxOrgId,
    gstRegistrationdate: gstRegistrationdate || null,
    gstRegistrationstatus,
  };

  EMPLOYMENT_PAYLOAD = [
    {
      id: currentEmpId || '',
      mainEmployment: mainEmployment === 'No' || !mainEmployment ? 'Yes' : 'No',
      EmploymentType: purchaseUse === 'Business' && isAbnknown ? EMPTYPE_SELF : employmentStatus,
      IsCurrent: 'Current',
      employmentStartDate: employmentStartDate || null,
      behalfOfCompany,
      trusteeType: trusteeType || null,
      lastFYNetProfit: lastFYNetProfit || null,
      hasABN:
        purchaseUse === 'Personal' && employmentStatus === EMPTYPE_SELF
          ? true
          : purchaseUse === 'Business'
          ? isAbnknown
          : false,
      employerName: employerName || null,
      ...(employmentStatus === EMPTYPE_SELF || isAbnknown ? abnDetails : {}),
    },
  ];
  if (addEmployment) {
    EMPLOYMENT_PAYLOAD.push({
      id: currentEmpId2 || '',
      mainEmployment: mainEmployment === 'Yes' ? 'Yes' : 'No',
      EmploymentType: employmentStatus2,
      IsCurrent: 'Current',
      employmentStartDate: employmentStartDate2 || null,
      hasABN: !!(purchaseUse === 'Personal' && employmentStatus2 === EMPTYPE_SELF),
      employerName: employerName2 || null,
      ...(employmentStatus2 === EMPTYPE_SELF ? abnDetails : {}),
    });
  }
  // commented as we are not capturing prev employments in pre select screens
  const now = moment();
  const emp1FormattedStDate = moment(employmentStartDate, 'YYYY-MM-DD');
  const emp2FormattedStDate = moment(employmentStartDate2, 'YYYY-MM-DD');

  const emp1Duration = now.diff(emp1FormattedStDate, 'years', true);
  const emp2Duration = now.diff(emp2FormattedStDate, 'years', true);
  if (
    !!prevEmpType &&
    ((mainEmployment && mainEmployment === 'Yes' && employmentStartDate2 && emp2Duration < 2) ||
      ((!mainEmployment || mainEmployment === 'No') && employmentStartDate && emp1Duration < 2) ||
      (abnRegistrationDate &&
        mainEmployment &&
        mainEmployment === 'Yes' &&
        employmentStatus === 'EMPTYPE_SELF') ||
      ((!mainEmployment || mainEmployment === 'No') &&
        abnRegistrationDate &&
        employmentStatus2 === 'EMPTYPE_SELF'))
  ) {
    EMPLOYMENT_PAYLOAD.push({
      id: prevEmpId || '',
      EmploymentType: prevEmpType,
      IsCurrent: 'Previous',
      employmentStartDate: prevEmpStartDt || null,
      employmentEndDate: prevEmpEndDt || null,
    });
  }
  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Primary Applicant',
        employment: EMPLOYMENT_PAYLOAD,
        postLenderSelect: true,
      },
    ],
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));
  return API.post(
    API_NAMES.OPP_EMPLOYMENT_UPD,
    apiUris.GET_EMPLOYMENT(opportunityId, applicantId),
    myInit,
  )
    .then(async (response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_EMPLOYMENT_UPD}. opportunityId: ${opportunityId}`,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      dispatch(liteFieldChanged('applicants', 'currentEmpId', response.data.empId[0], false));
      if (employerName) {
        dispatch(liteFieldChanged('applicants', 'isSavedEmployerName', true, false));
        await dispatch(updateEmployerIncome(employerName, response.data.empId[0]));
      }

      if (addEmployment) {
        dispatch(liteFieldChanged('applicants', 'currentEmpId2', response.data.empId[1], false));
        if (employerName2) {
          dispatch(liteFieldChanged('applicants', 'isSavedEmployerName2', true, false));
          await dispatch(updateEmployerIncome(employerName2, response.data.empId[1]));
        }
      }

      if (employeeDuration) {
        dispatch(
          liteFieldChanged(
            'applicants',
            'prevEmpId',
            response.data.empId[response.data.empId.length - 1],
            false,
          ),
        );
      }
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_EMPLOYMENT_UPD}. opportunityId: ${opportunityId}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};

export const spouseEmploymentInsUpd = () => (dispatch, getState) => {
  const state = getState();
  const {
    spouseEmploymentType,
    spouseEmpCurrStartDt,
    SpousecurrentEmpId,
    spouseEmployerName,
    opportunityId,
    spouseCoApplicantId,
    spouseOccupation,
  } = state.applicants;
  const EMPLOYMENT_PAYLOAD = [
    {
      id: SpousecurrentEmpId || '',
      mainEmployment: null,
      EmploymentType: spouseEmploymentType,
      IsCurrent: 'Current',
      employmentStartDate: spouseEmpCurrStartDt || null,
      hasABN: spouseEmploymentType === EMPTYPE_SELF,
      employerName: spouseEmployerName || null,
      occupation: spouseOccupation || null,
    },
  ];
  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Spouse',
        employment: EMPLOYMENT_PAYLOAD,
      },
    ],
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged('account', 'quoteInProgress', true, false));
  return API.post(
    API_NAMES.OPP_EMPLOYMENT_UPD,
    apiUris.GET_EMPLOYMENT(opportunityId, spouseCoApplicantId),
    myInit,
  )
    .then(async (response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_EMPLOYMENT_UPD}. opportunityId: ${opportunityId}`,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      dispatch(liteFieldChanged('applicants', 'SpousecurrentEmpId', response.data.empId[0], false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_EMPLOYMENT_UPD}. opportunityId: ${opportunityId}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false, false));
      return Promise.reject(error.message);
    });
};
