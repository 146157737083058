/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import TextField from '../../components/TextField';
import { formattedCurrentDate, ROUTES, APPLICANT_TYPE, COUNTRY_LIST, MIN_DATE_ERROR_MESSAGE } from '../../util/constants';
import fieldChanged, { liteFieldChanged, updateValidationErrors } from '../../Actions/FieldChanged';
import InputButton from '../../components/InputButton';
import rightnormal from '../../../assets/image/right-normal.svg';
import DatePickerWrapper from '../../components/DatePickerWrapper';
import DropDown from '../../components/DropDown';
import ModalComponent from '../../components/ModalComponent';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import { reQuote, createSpouseCoApplicants } from '../../Actions/car';
import leftnormal from '../../../assets/image/left-normal.svg';
import CreditCardNumberVerificationDesktopDesign from '../../../assets/pdfFiles/CreditCardNumberVerificationDesktopDesign.pdf'
import CreditCardNumberVerificationMobileview from '../../../assets/pdfFiles/CreditCardNumberVerificationMobileview.pdf'
import processGTMevent from '../../util/processGTMEvent';
import '../ContactDetails/contact-details.scss';
import Progress from '../../components/Progress';

const CoApplicantLicenceDetails = ({
  coAppinternationalLicense,
  coApplicenceNumber,
  coApplicenceCardNumber,
  coAppdlState,
  coApplicenceType,
  coAppdateOfBirth,
  getValidationErrorsForField,
  onInputChange,
  validateDateField,
  fieldSuffix,
  saveCoapplicantLicenceDetails,
  passportNumber,
  passportCountry,
  passportExpiry,
  applicationStructure,
  validationErrors,
  opportunityId,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(768));
  const [coapplicantdetailsInProgress, setcoapplicantdetailsInProgress] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
     processGTMevent('identityDetails', 'PQAdditionalQuestions-CoAppIdentity-PageLoad', 'Page Load');
    if (validationErrors?.length) {
      onInputChange('validationErrors', [], false);
    }
  }, []);

  const handleShow = () => {
    setShow(true);
 }

 const handleClose = () => {
  setShow(false);
};

  const saveIdentityDetails = () => {
    setcoapplicantdetailsInProgress(true);
    processGTMevent('identityDetails', 'PQAdditionalQuestions-CoAppIdentity-Next', 'Click');
    saveCoapplicantLicenceDetails('coApplicant')
      .then((resp) => {
        localStorage.setItem(`pageCompleted${opportunityId}`, 'coapp-licence-details');
        onInputChange('identityDetails', true, false);
        const nextRoute = `${ROUTES.employmentDetailsUri}/${applicationStructure}`;
        history.push(nextRoute);
        setcoapplicantdetailsInProgress(false);
      })
      .catch((err) => {
        setcoapplicantdetailsInProgress(false);
        console.log('failed to save recrd', err);
      });
  };



  const handleCheckboxChange = (fieldName, e) => {
    onInputChange(fieldName, e.target.checked, false);
  };
  const navigateToPrevious = () => {
    history.goBack();
  };
  const nextDisabled =
    !coAppdateOfBirth ||
    (!coAppinternationalLicense && (!coApplicenceNumber || !coApplicenceCardNumber || !coAppdlState || !coApplicenceType)) ||
    (coAppinternationalLicense && (!passportNumber || !passportCountry || !passportExpiry));

    const licencehelptextfilenamefield = !mobileView ? CreditCardNumberVerificationDesktopDesign : CreditCardNumberVerificationMobileview;
 
  return (
    <Card className="content-body personal-details-container">
      <Card.Header className="col-12 page-header-container personal-details-header-container scrollable-anchor-tag with-sidebar">
        <h1 className="personal-details-header">Personal details</h1>
        <div className="personal-details-description">
          To support the application we will need to add an additional applicant
        </div>
      </Card.Header>
      <Card.Body className="pb-0 applicant-body-container">
        <div className="row py-2 px-3 applicant-body-section1">
          <div className="col-12 px-2 applicant-body-section2">
            <Form noValidate className="py-2 px-1 identity-form-section">
              <div className=" personal-body-section3">
                {!coAppinternationalLicense && (
                  <Form.Group as={Col} xs={12} md={6} className="px-0 license-number">
                    <TextField
                      fieldName={`driversLicenseNumber${fieldSuffix}`}
                      value={coApplicenceNumber}
                      className="px-0"
                      label="Driver's licence number"
                      placeholder="eg. 123456789"
                      getvalidationerrors={getValidationErrorsForField}
                      onChange={onInputChange}
                      colmd={12}
                      updateOnChange
                      inline
                    />
                  </Form.Group>
                )}

                <Form.Group as={Col} xs={12} md={6} className="no-license-check my-auto">
                  <label className="checkbox-container my-auto">
                    I don&apos;t have an Australian drivers licence
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        handleCheckboxChange(`internationalLicense${fieldSuffix}`, e)
                      }
                      checked={coAppinternationalLicense}
                      value={coAppinternationalLicense}
                      name="internationalLicenseCoApplicant"
                      id="internationalLicenseCoApplicant"
                    />
                    <span className={[mobileView ? 'm-0 checkmark' : 'ml-2 checkmark' ]} />
                  </label>
                </Form.Group>
              </div>
              <Form.Row className='licence-card-number'>
              {!coAppinternationalLicense && (
                <Form.Group
                  as={Col}
                  xs={12}
                  md={6}
                  className="px-0 m-0 license-card-number"
                  controlId="driversLicencecardNumber"
                >
                  {!mobileView ? (
                    <>
                      <label htmlFor="driversLicencecardNumber" className="licencecardlabel">Driver&apos;s licence card number</label>
                      <button type="button" class="btn-tooltip btn btn-primary ml-2" onClick={handleShow}>What's this?</button>
                      <TextField
                        fieldName={`driversLicenseCardNumber${fieldSuffix}`}
                        value={coApplicenceCardNumber}
                        className="px-0"
                        placeholder="eg. AA000A0000"
                        getvalidationerrors={getValidationErrorsForField}
                        onChange={onInputChange}
                        colmd={12}
                        updateOnChange
                        inline
                      />,

                    </>
                  ) : (
                    <>
                      <div className='mt-2'>
                        <label htmlFor={`driversLicenseCardNumber${fieldSuffix}`} className="licencecardlabel">Driver&apos;s licence card number</label>
                      </div>
                      <button type="button" class="btn-tooltip btn btn-primary" onClick={handleShow}>What's this?</button>
                      <TextField
                        fieldName={`driversLicenseCardNumber${fieldSuffix}`}
                        value={coApplicenceCardNumber}
                        className="px-0 pt-3"
                        placeholder="eg. AA000A0000"
                        getvalidationerrors={getValidationErrorsForField}
                        onChange={onInputChange}
                        colmd={12}
                        updateOnChange
                        inline
                      />
                    </>
                  )
                  }
                </Form.Group>
              )}
            </Form.Row>
              {coAppinternationalLicense && (
                <div className="coapp-passportdetails">
                  <TextField
                    fieldName={`passportNumber${fieldSuffix}`}
                    value={passportNumber}
                    className="pl-0 pr-3"
                    label="Passport number"
                    getvalidationerrors={getValidationErrorsForField}
                    onChange={onInputChange}
                    colxs="12"
                    colsm="4"
                    colmd="4"
                    inline
                  />
                  <DropDown
                    fieldName={`passportCountry${fieldSuffix}`}
                    value={passportCountry}
                    label="Passport Country"
                    getvalidationerrors={getValidationErrorsForField}
                    onChange={onInputChange}
                    options={
                      <>
                        <option value="" hidden>
                          Choose...
                        </option>
                        {COUNTRY_LIST.map((country) => (
                          <option key={country.id} value={country.value}>
                            {country.value}
                          </option>
                        ))}
                      </>
                    }
                    colxs="12"
                    colsm="4"
                    colmd="4"
                  />
                  <DatePickerWrapper
                    value={passportExpiry}
                    onChange={onInputChange}
                    className="pl-2"
                    fieldName={`passportExpiry${fieldSuffix}`}
                    validateDateField={validateDateField}
                    minDtVal={formattedCurrentDate}
                    minDateMessage={MIN_DATE_ERROR_MESSAGE}
                    label="Passport Expiry"
                    format="YYYY-MM-DD"
                    getValidationErrors={getValidationErrorsForField}
                    inline
                    columnCount="4"
                  />
                </div>
              )}
              {!coAppinternationalLicense && (
                <>
                  <InputButton
                    label={
                      <>
                        <span>Driver's licence state</span>
                      </>
                    }
                    fields={[
                      { name: 'NSW', value: 'NSW' },
                      { name: 'QLD', value: 'QLD' },
                      { name: 'SA', value: 'SA' },
                      { name: 'TAS', value: 'TAS' },
                      { name: 'VIC', value: 'VIC' },
                      { name: 'WA', value: 'WA' },
                      { name: 'ACT', value: 'ACT' },
                      { name: 'NT', value: 'NT' },
                    ]}
                    onChange={onInputChange}
                    fieldName={`driversLicenseState${fieldSuffix}`}
                    value={coAppdlState}
                    scrollToNextFieldOnChage
                    ignoreHeaderOffset
                    getvalidationerrors={getValidationErrorsForField}
                  />
                  <InputButton
                    label={
                      <>
                        <span>Licence type </span>
                      </>
                    }
                    fields={[
                      { name: 'Full', value: 'FULL' },
                      { name: 'Learner', value: 'LEARNER' },
                      { name: 'Probationary', value: 'PROVISIONAL' },
                    ]}
                    onChange={onInputChange}
                    fieldName={`driversLicenseType${fieldSuffix}`}
                    value={coApplicenceType}
                    scrollToNextFieldOnChage
                    ignoreHeaderOffset
                    getvalidationerrors={getValidationErrorsForField}
                  />
                </>
              )}
              <div className="form-row">
                <DatePickerWrapper
                  value={coAppdateOfBirth}
                  onChange={onInputChange}
                  className="date-of-birth"
                  fieldName={`dateOfBirth${fieldSuffix}`}
                  validateDateField={validateDateField}
                  minDtVal="01/01/1900"
                  maxDtVal={formattedCurrentDate}
                  label="Date of birth"
                  format="YYYY-MM-DD"
                  columnCount="4"
                  getValidationErrors={getValidationErrorsForField}
                  inline
                />
              </div>
            </Form>
          </div>
        </div>
        <div className="btn  footer-actions btn-address-next">
          {mobileView ? (
            <>
              <button
                type="button"
                onClick={navigateToPrevious}
                className="btn btn-primary  btn-next-page"
              >
                <span className="pr-3">
                  <img src={leftnormal} alt="right" className="right-icon ml-0" />
                </span>
                Previous
              </button>
              <button
                type="button"
                onClick={saveIdentityDetails}
                disabled={nextDisabled}
                className="btn btn-primary btn-next-page"
              >
                {coapplicantdetailsInProgress ? (
                  <CircularProgress color="inherit" size={24} thickness={2.6} />
                ) : (
                  'Next'
                )}
                <span className="px-3">
                  <img src={rightnormal} alt="right" className="right-icon" />
                </span>
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={navigateToPrevious}
                className="btn btn-primary  btn-next-page"
              >
                <span className="pr-3">
                  <img src={leftnormal} alt="right" className="right-icon ml-0" />
                </span>
                Previous
              </button>

              <Progress completed={4} pending={1} />
              <button
                type="button"
                onClick={saveIdentityDetails}
                disabled={nextDisabled}
                className="btn btn-primary btn-next-page"
              >
                {coapplicantdetailsInProgress ? (
                  <CircularProgress color="inherit" size={24} thickness={2.6} />
                ) : (
                  'Next'
                )}
                <span className="px-3">
                  <img src={rightnormal} alt="right" className="right-icon" />
                </span>
              </button>
            </>
          )}
        </div>
      </Card.Body>
      {
        show && <ModalComponent show handleClose={handleClose} licencehelptextfilename={licencehelptextfilenamefield} />
      }
    </Card>
  );
};

export default connect(
  (state, ownProps) => {
    const fieldSuffix = `_${APPLICANT_TYPE.coApplicant}`;
    return {
      fieldSuffix,
      coApplicenceNumber: state.applicants[`driversLicenseNumber${fieldSuffix}`],
      coApplicenceCardNumber: state.applicants[`driversLicenseCardNumber${fieldSuffix}`],
      coAppinternationalLicense: state.applicants[`internationalLicense${fieldSuffix}`],
      coAppdlState: state.applicants[`driversLicenseState${fieldSuffix}`],
      coApplicenceType: state.applicants[`driversLicenseType${fieldSuffix}`],
      coAppdateOfBirth: state.applicants[`dateOfBirth${fieldSuffix}`],
      passportNumber: state.applicants[`passportNumber${fieldSuffix}`],
      passportCountry: state.applicants[`passportCountry${fieldSuffix}`],
      passportExpiry: state.applicants[`passportExpiry${fieldSuffix}`],
      applicationStructure: state.applicants.applicationStructure,
      validationErrors: state.applicants.validationErrors,
      opportunityId: state.applicants.opportunityId,
      getValidationErrorsForField: getValidationErrorsForFieldFunc(
        state.applicants.validationErrors,
      ),
    };
  },
  (dispatch) => ({
    reQuote: () => dispatch(reQuote()),
    onInputChange: (fieldName, value, scrollToNextFieldOnChage, ignoreHeaderOffset) => {
      dispatch(liteFieldChanged('applicants', 'personaldetailsChanged', true, false));
      return dispatch(
        fieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage, ignoreHeaderOffset),
      );
    },
    saveCoapplicantLicenceDetails: (applicantType) =>
      dispatch(createSpouseCoApplicants(true, applicantType)),
    validateDateField: (fieldName, errors) =>
      dispatch(updateValidationErrors('APPLICANTS', fieldName, errors)),
  }),
)(withRouter(CoApplicantLicenceDetails));
