/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import remove from 'lodash/remove';
import find from 'lodash/find';
import cls from 'classnames';
import './doc-verify.scss';
import { DOCVERIFYLIST } from '../../util/docVerificationList';
import { IdentityDoc } from '../../util/constants';
import DropDown from '../../components/DropDown';
import DropZoneField from '../../components/FileUpload/DropzoneField';
import InputButton from '../../components/InputButton';
import rightnormal from '../../../assets/image/right-normal.svg';

const DocumentsVerification = ({
  incomelistVal,
  uploadIdentityDoc,
  uploadIncomeDoc,
  onInputChange,
  getValidationErrorsForField,
  setTab,
  doctype,
  incomedocType,
}) => {
  // const incomelistVal = [
  //   {
  //     amount: '100.0',
  //     category: 'PAYG Income',
  //     frequency: 'Weekly',
  //     id: 'c8905f21-4561-4746-be94-fb2e0dab82e3',
  //   },
  //   {
  //     amount: '3500.0',
  //     category: 'Self-Employed Income',
  //     frequency: 'Weekly',
  //     id: '15bc69c6-e297-43c6-b964-8de8bd50e6a8',
  //   },
  // ];
  const [imageFiles, setImage] = useState({ identityDoc: [] });
  const [incomeTypes, setIncomeType] = useState({});

  const handleDeleteFile = (category) => (file) => {
    const updatedList = [...(imageFiles[category] || [])];
    remove(updatedList, { path: file.path, name: file.name });
    setImage({ ...imageFiles, [category]: updatedList });
    // this.props.onDelete({ ...this.props.document, imageFiles: [...updatedList] });
  };

  const handleOnDrop = (category) => (newimagefile) => {
    const updatedList = [...(imageFiles[category] || []), ...newimagefile];
    setImage({ ...imageFiles, [category]: updatedList });
  };

  const handleIncomeSelected = (fieldName, fieldObj) => {
    setIncomeType({ ...incomeTypes, [fieldName]: fieldObj });
  };

  let updateList = [...DOCVERIFYLIST];
  updateList = remove(updateList, (docCat) => find(incomelistVal, ['category', docCat.type]));
  return (
    <div className="dashboard-doc-verification-container">
      <div className="doc-title-section">
        <div className="doc-details-title">Verifications</div>
        <div className="doc-details-subtitle">
          Upload high resulution PNG, JPG, PDF’s only, no larger than 5MB.
        </div>
      </div>
      <div className="dashboard-doc-identification-container">
        <div className="identity-documents-block">
          <div className="identity-documents-label">IDENTITY IDENTIFICATION</div>
          <div className="doc-status">Pending</div>
        </div>
        <InputButton
          label={
            <>
              <span>Select the document type</span>
            </>
          }
          fields={IdentityDoc}
          onChange={onInputChange}
          fieldName="doctype"
          scrollToNextFieldOnChage
          value={doctype}
          btnClass="doctype"
          getvalidationerrors={getValidationErrorsForField}
        />

        {doctype && (
          <div className="multiple-doc">
            <DropZoneField
              handleOnDrop={handleOnDrop('Frontpage')}
              handleDeleteFile={handleDeleteFile('Frontpage')}
              imagefile={imageFiles.Frontpage}
              label="Front page"
              disabled={imageFiles?.Frontpage?.length}
            />
            <DropZoneField
              handleOnDrop={handleOnDrop('Backpage')}
              handleDeleteFile={handleDeleteFile('Backpage')}
              imagefile={imageFiles.Backpage}
              label="Back page"
              disabled={imageFiles?.Backpage?.length}
            />
          </div>
        )}
      </div>
      {incomelistVal && incomelistVal.length > 1 && (
        <div className="dashboard-doc-identification-container">
          <div className="identity-documents-block">
            <div className="identity-documents-label ">INCOME VERIFICATION</div>
          </div>
          {updateList &&
            updateList.map((income, idx) => {
              if (income.options.length === 1) {
                const docTypeName = `${income.value}_${income.options[0].value}`;

                return (
                  <React.Fragment key={docTypeName}>
                    <div className="income-type-flex">
                      <div className="income-type-label">{income.type}</div>
                      <div className="doc-status">Pending</div>
                    </div>
                    <DropZoneField
                      handleOnDrop={handleOnDrop(docTypeName)}
                      handleDeleteFile={handleDeleteFile(docTypeName)}
                      imagefile={imageFiles[docTypeName]}
                      label={income.options[0].documents[0].name || income.options[0].name}
                      disabled={imageFiles[docTypeName]?.length}
                    />
                  </React.Fragment>
                );
              }

              return (
                <React.Fragment key={income.value}>
                  <div className="income-type-flex">
                    <div className="income-type-label">{income.type}</div>
                    <div className="doc-status">Pending</div>
                  </div>
                  <InputButton
                    label={
                      <>
                        <span>Select the document type</span>
                      </>
                    }
                    fields={income.options}
                    onChange={handleIncomeSelected}
                    fieldName={income.value}
                    scrollToNextFieldOnChage
                    value={incomeTypes[income.value] && incomeTypes[income.value].value}
                    btnClass="incomedocType"
                    returnObject
                    getvalidationerrors={getValidationErrorsForField}
                  />

                  <div
                    className={cls(
                      incomeTypes[income.value] &&
                        incomeTypes[income.value].documents &&
                        incomeTypes[income.value].documents.length > 1 &&
                        'multiple-doc',
                    )}
                  >
                    {incomeTypes[income.value] &&
                      incomeTypes[income.value].documents &&
                      incomeTypes[income.value].documents.map((docTypeOptions) => {
                        const docTypeName = `${income.value}_${docTypeOptions.value}`;

                        return (
                          <DropZoneField
                            handleOnDrop={handleOnDrop(docTypeName)}
                            handleDeleteFile={handleDeleteFile(docTypeName)}
                            imagefile={imageFiles[docTypeName]}
                            label={docTypeOptions.name || incomeTypes[income.value].name}
                            disabled={imageFiles[docTypeName]?.length}
                          />
                        );
                      })}
                  </div>
                  {income.options.length - 1 !== idx && <hr className="document-line-separator" />}
                </React.Fragment>
              );
            })}
        </div>
      )}
      <div className="btn  footer-actions btn-address-next">
        <button
          type="button"
          //onClick={employmentNextClick}
          className="btn btn-primary btn-next-page"
        >
          Next
          <img src={rightnormal} alt="right" className="right-icon" />
        </button>
      </div>
    </div>
  );
};

export default DocumentsVerification;
