const formateQueryString = (searchQuery = '') => {
  const formattedQuery = searchQuery.substring(1);
  const formattedQueryObject = formattedQuery.split('&').reduce((reducedParam, searchParam) => {
    const [queryKey, queryValue] = searchParam.split('=');
    return {
      ...reducedParam,
      [queryKey]: queryValue,
    };
  }, {});
  return formattedQueryObject;
};
const cacheFormattedQuery = formateQueryString(window.location.search);
const queryString = (queryName) => cacheFormattedQuery[queryName] || null;
export default queryString;
