import requiredRuleEvaluator from '../RuleEvaluators/RequiredRuleEvaluator';
import minmaxRuleEvaluator from '../RuleEvaluators/MinMaxRuleEvaluator';
import postcodeRuleEvaluator from '../RuleEvaluators/PostcodeRuleEvaluator';
import phoneNumberRuleEvaluator from '../RuleEvaluators/PhoneNumberRuleEvaluator';
import numberRuleEvaluator from '../RuleEvaluators/NumberRuleEvaluator';
import emailRuleEvaluator from '../RuleEvaluators/EmailRuleEvaluator';
import noFutureDateRuleEvaluator from '../RuleEvaluators/NoFutureDateRuleEvaluator';
import date18YearsRuleEvaluator from '../RuleEvaluators/Date18YearsRuleEvaluator';
import nameFieldsRuleEvaluator from '../RuleEvaluators/NameFieldsRuleEvaluator';
import LicenceCardRuleEvaluator from '../RuleEvaluators/LicenceCardRuleEvaluator';
import promocodeRuleEvaluator from '../RuleEvaluators/PromocodeRuleEvaluator';

const getRuleEvaluator = (ruleType) => {
  switch (ruleType) {
    case 'REQUIRED':
      return requiredRuleEvaluator;

    case 'MINMAX':
      return minmaxRuleEvaluator;

    case 'POSTCODE':
      return postcodeRuleEvaluator;
    case 'PROMOCODE':
      return promocodeRuleEvaluator;
    case 'PHONENUMBER':
      return phoneNumberRuleEvaluator;

    case 'EMAIL':
      return emailRuleEvaluator;

    case 'NUMBER':
      return numberRuleEvaluator;

    case 'NO_FUTURE_DATE':
      return noFutureDateRuleEvaluator;

    case 'DATE_18_YEARS':
      return date18YearsRuleEvaluator;

    case 'USERNAME':
      return nameFieldsRuleEvaluator;

    case 'LICENCECARDNUMBER':
      return LicenceCardRuleEvaluator;

    default:
      return () => {};
  }
};

const evaluateRule = (rule, value) => {
  const ruleEvaluator = getRuleEvaluator(rule.type);

  return ruleEvaluator(rule, value);
};

const validateField = (fieldName, value, validationRules) => {
  if (!validationRules || validationRules.length < 1) {
    return null;
  }

  const errors = [];

  validationRules.forEach((rule) => {
    if (errors.length > 0) return;
    // we need to have only 1 validation error per field.
    // Note: return only returns from the current iteration

    const error = evaluateRule(rule, value);

    if (error) {
      errors.push(error);
    }
  });

  if (errors.length > 0) {
    return errors;
  }
  return null;
};

export default validateField;
