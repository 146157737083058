/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import Form from 'react-bootstrap/Form';
import cls from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from './Tooltip';

const iOSBoxShadow =
  '0px 1px 23px rgba(82, 175, 119,0.61), 0 0px 101px rgba(82, 175, 119,0.61), 0 0 0 1px rgba(82,175,119,0.61)';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 320 + theme.spacing(3) * 2,
      padding: theme.spacing(3),
      margin: 'auto',
      [theme.breakpoints.down(992)]: {
        maxWidth: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '70vw',
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
      },
    },
    rootAutowidth: {
      width: 'auto',
      padding: '7px',
      margin: 'auto',
    },
    sliderContainer: {
      width: 'auto',
      margin: 0,
    },
    input: {
      width: '10px',
      borderRadius: '4px',
      backgroundColor: '#ffffff',
      color: '#25282a',
      padding: '10px 10px 10px 10px',
      border: 'none',
      position: 'relative',
      textAlign: 'center',
    },
    adornedEnd: {
      backgroundColor: '#ffffff',
      color: '#25282a',

      '& > p': {
        color: '#25282a',
      },
    },
    label: {
      textAlign: 'left',
      fontFamily: 'Poppins-Medium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '22px',
    },
  }),
  { name: 'AmountRangeSelector' },
);

const PrettoSlider = withStyles({
  root: {
    color: '#00CD76',
    height: 8,
    margin: '0 auto',
    marginLeft: 0,
    merginRight: 0,
    padding: '8px',
    paddingLeft: 0,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '2px solid #00CD76',
    color: '#00CD76',
    marginTop: -4,
    '&:focus,&:hover,&$active': {
      boxShadow: iOSBoxShadow,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
    '&.Mui-disabled': {
      height: 18,
      width: 18,
      marginTop: -6,
      marginLeft: -12,
      backgroundColor: '#ddd',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
  },
})(Slider);

let timerSliderChange = '';

export default function AmountRangeSelector({
  onChange,
  getvalidationerrors,
  scrollToNextFieldOnChage,
  fieldName,
  formGroupClass,
  labelClass,
  label,
  termVal,
  step,
  required,
  defaultValue,
  minValue = 0,
  maxValue,
  interestSlider,
  rootContainerClass,
  oncreateQuote,
  inProgress,
  sliderClasses,
  tooltip,
  termSlider,
  disabled,
  setRangeWidth,
  flagChange = (()=>{}),
  ...restProps
}) {
  const classes = useStyles(restProps);
  const [value, setValue] = useState(defaultValue || minValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const refreshCreateQuote = () => {
    if (timerSliderChange) {
      clearTimeout(timerSliderChange);
    }

    timerSliderChange = setTimeout(() => {
      if (oncreateQuote) {
        oncreateQuote();
      }
    }, 2000);
  };

  const isInvalidMin = minValue && (!value || value < minValue);
  const isValidMin = minValue && (!value || value === minValue);
  useEffect(() => {
    onChange(fieldName, value, scrollToNextFieldOnChage);
    if (value && value >= minValue) {
      refreshCreateQuote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSliderChange = (event, newValue, maxvalue) => {
    flagChange('prefillRecords', false, false);
    if (newValue <= maxvalue) {
      setValue(newValue);
    }
  };

  const handleInputChange = ({ value: numericValue }) => {
    setValue(Number(numericValue));
  };

  // eslint-disable-next-line max-len
  const handleValidate = ({ value: numericValue }) =>
    Number(numericValue) >= 0 && Number(numericValue) <= maxValue;

  return (
    <>
      <div className={cls(setRangeWidth && classes.rootAutowidth, classes.root, formGroupClass)}>
        <Typography
          id="continuous-slider"
          className="mb-0"
          gutterBottom
          classes={{ root: classes.label }}
        >
          {label} {tooltip && <Tooltip message={tooltip} />}
        </Typography>
        {!termSlider && (
          <NumberFormat
            thousandSeparator
            customInput={Form.Control}
            onValueChange={handleInputChange}
            value={value.toString()} // textbox does not support nulls
            margin="none"
            decimalScale={2}
            type="tel"
            prefix="$"
            isAllowed={handleValidate}
            step={step}
            min={minValue}
            max={maxValue}
            className={cls('input-root price-slider-input', isInvalidMin && 'invalid-min')}
            // isInvalid={isInvalid}
            name={fieldName}
            id={fieldName}
            disabled={disabled}
          />
        )}
        {termSlider && (
          <NumberFormat
            customInput={Form.Control}
            onValueChange={handleInputChange}
            value={value.toString()} // textbox does not support nulls
            margin="none"
            suffix=" Years"
            type="tel"
            allowLeadingZeros={false}
            isAllowed={handleValidate}
            step={step}
            min={minValue}
            max={maxValue}
            decimalScale={0}
            className={cls(
              'input-root price-slider-input term-slider-input',
              isInvalidMin && 'invalid-min',
            )}
            name={fieldName}
            id={fieldName}
            disabled={disabled}
          />
        )}
        <Grid
          container
          spacing={2}
          className={cls(classes.sliderContainer, 'pretto-slider-container')}
        >
          <PrettoSlider
            value={!value || isNaN(Number(value)) ? 0 : Number(value)}
            onChangeCommitted={(e, newvalue) => handleSliderChange(e, newvalue, maxValue)}
            step={step}
            min={minValue}
            max={maxValue}
            classes={{ ...sliderClasses }}
            disabled={disabled}
          />
        </Grid>
        {!!isInvalidMin && (
          <Typography id="continuous-slider-error" className="mb-0" gutterBottom color="error">
            Value can&apos;t be less than {!termSlider ? '$' : ''}
            {minValue} {!termSlider ? '' : 'Year'}
          </Typography>
        )}
        {!!isValidMin && !termSlider && (
          <Typography id="continuous-slider-error" className="mb-0" gutterBottom color="error">
            Note: Stratton Finance cannot assist with financing requests for amounts less than&nbsp;
            {!termSlider ? '$' : ''}
            {minValue}
          </Typography>
        )}
      </div>
    </>
  );
}
