/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { capitalize } from 'lodash';
import { useHistory, withRouter } from 'react-router-dom';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import TextField from '../../components/TextField';
import './PersonalDetails.scss';
import rightnormal from '../../../assets/image/right-normal.svg';
import Email from '../../../assets/image/Email.svg';
import call from '../../../assets/image/call.svg';
import ButtonGroupCustom from '../../components/ButtonGroupCustom';
import '../ContactDetails/contact-details.scss';
import fieldChanged, { liteFieldChanged } from '../../Actions/FieldChanged';
import processGTMevent from '../../util/processGTMEvent';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import { setCurrentTab, setCompleteTab } from '../../Actions/tab';
import { APPLICANT_TYPE, ROUTES } from '../../util/constants';
import { createSpouseCoApplicants, RetrieveOpportunity } from '../../Actions/car';
import leftnormal from '../../../assets/image/left-normal.svg';
import Progress from '../../components/Progress';

export const TITLE = ['Mr', 'Mrs', 'Miss', 'Ms'];


const PersonalDetails = ({
  title,
  firstName,
  lastName,
  email,
  phoneNumber,
  currentTab,
  setTab,
  getValidationErrorsForField,
  validationErrors,
  onLiteInputChange,
  error,
  contactSaveInProgress,
  applicantType,
  fieldSuffix,
  spouseCoApplicantCreation,
  contactDetails,
  RetrieveOpportunity,
  onInputChange,
  opportunityId,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(768));

  useEffect(() => {
    if(applicantType === APPLICANT_TYPE.spouse) {
      processGTMevent('applicants', 'PQAdditionalQuestions-SpouseDetails-PageLoad', 'Page Load')
    }
    else if (applicantType === APPLICANT_TYPE.coApplicant) {
      processGTMevent('applicants', 'PQAdditionalQuestions-CoAppPersonal-PageLoad', 'Page Load');
    }
    if (validationErrors?.length) {
      onLiteInputChange('validationErrors', [], false);
    }
  }, []);

  const rex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@+"]+(\.[^<>()[\]\\.,;:\s@+"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  const formattedEmail = rex.test(email);
  const valueFormatted =
    phoneNumber &&
    phoneNumber
      .trim()
      .replace(/\s|\(|\)|-/g, '')
      .replace(/^\+61/g, '0')
      .replace(/^61/g, '0');

  const rexPhone = new RegExp(/(^\(?(04|4)\)?[\s|-]*\d{2}[\s|-]*([\s|-]*\d[\s|-]*){6}$)/);

  const formattedPhoneNumber = rexPhone.test(valueFormatted);

  const contactCreation = () => {
    onLiteInputChange('contactSaveInProgress', true, false);
    spouseCoApplicantCreation(true, applicantType)
      .then((resp) => {
        onLiteInputChange('contactSaveInProgress', false, false);
        RetrieveOpportunity()
          .then((resp) => {
            if (applicantType === APPLICANT_TYPE.coApplicant) {
              processGTMevent('applicants', 'PQAdditionalQuestions-CoAppPersonal-Next', 'Click');
              localStorage.setItem(`pageCompleted${opportunityId}`, 'contact-for-coApplicant');
              history.push(`${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.coApplicant}`);
            } else {
             
              if(applicantType === APPLICANT_TYPE.spouse) {
                processGTMevent('applicants', 'PQAdditionalQuestions-SpouseDetails-Next', 'Click')
              }
              localStorage.setItem(`pageCompleted${opportunityId}`, 'contact-for-spouse');
              const nextRoute = `${ROUTES.employmentDetailsUri}/${applicantType}`;
              history.push(nextRoute);
            }
          })
          .catch((err) => {
            console.log('error while retrieving', err);
          });
      })
      .catch((err) => {
        console.log('error is', err);
        onLiteInputChange('contactSaveInProgress', false, false);
        history.push(ROUTES.financeComplete);
      });
  };

  const navigateToPrevious = () => {
    history.goBack();
  };
  const nextDisabled =
    (validationErrors && validationErrors.length) ||
    !title ||
    !firstName ||
    !lastName ||
    !email ||
    !phoneNumber ||
    !formattedEmail ||
    !formattedPhoneNumber;

  const disableFields = contactDetails;
  return (
    <>
      <Card className="content-body personal-details-container">
        {mobileView && (
          <div className="step-header">
            <div className="step-header-text-container">
              <div className="numberCircle">3</div>
              <div className="step-header-text">
                <div className="step-header-text-sub">Get Approved</div>
                <div className="step-header-text-main">Additional Information</div>
                {applicantType !== APPLICANT_TYPE.primary ? (
                  <>
                    {applicantType === APPLICANT_TYPE.coApplicant ? (
                      <div className="step-header-text-main">(Co-Applicant)</div>
                    ) : (
                      <div className="step-header-text-main">(Spouse)</div>
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="step-header-container">
              {applicantType === APPLICANT_TYPE.coApplicant ? (
                <Progress completed={1} pending={3} />
              ) : (
                <Progress completed={1} pending={3} />
              )}
            </div>
          </div>
        )}
        <Card.Header className="col-12 page-header-container personal-details-header-container scrollable-anchor-tag with-sidebar">
          <h1 className="personal-details-header">Personal details</h1>
          <div className="personal-details-description">
            To support the application we will need to add an additional applicant
          </div>
        </Card.Header>
        <Card.Body className="pb-0 applicant-body-container">
          <div className="row py-2 px-3 applicant-body-section1">
            <div className="col-12 px-2 applicant-body-section2">
              <Form noValidate className="contact-body">
                <div className="title">{capitalize(applicantType)}</div>
                <div className="subtitle">PERSONAL DETAILS</div>
                <div className="form-row">
                  <ButtonGroupCustom
                    label={
                      <>
                        <span>Title</span>
                      </>
                    }
                    fields={[
                      { name: 'Mr', value: 'Mr' },
                      { name: 'Mrs', value: 'Mrs' },
                      { name: 'Ms', value: 'Ms' },
                      { name: 'Miss', value: 'Miss' },
                    ]}
                    onChange={onLiteInputChange}
                    fieldName={`title${fieldSuffix}`}
                    className="title"
                    required="*"
                    // isdisabled={disableFields}
                    value={title}
                    getvalidationerrors={getValidationErrorsForField}
                  />
                </div>
                <div className="form-row">
                  <TextField
                    fieldName={`firstName${fieldSuffix}`}
                    value={firstName}
                    required="*"
                    className="first-name"
                    label="First name"
                    placeholder="e.g John"
                    getvalidationerrors={getValidationErrorsForField}
                    onChange={onInputChange}
                    colmd={0}
                    inline
                  />
                  <TextField
                    fieldName={`lastName${fieldSuffix}`}
                    value={lastName}
                    label="Last name"
                    className="last-name"
                    required="*"
                    placeholder="e.g Doe"
                    getvalidationerrors={getValidationErrorsForField}
                    onChange={onInputChange}
                    colmd={0}
                    inline
                  />

                  <div className="form-row" />
                  <TextField
                    fieldName={`email${fieldSuffix}`}
                    value={email}
                    label="Email"
                    required="*"
                    image={Email}
                    placeholder="Email address"
                    className="email-address pb-3"
                    getvalidationerrors={getValidationErrorsForField}
                    onChange={onInputChange}
                    emailToLowercase
                    colmd={0}
                    inline
                  />
                  <TextField
                    fieldName={`phoneNumber${fieldSuffix}`}
                    value={phoneNumber}
                    label="Mobile number"
                    className="phone-number"
                    format="##########"
                    placeholder="0400000000"
                    image={call}
                    required="*"
                    getvalidationerrors={getValidationErrorsForField}
                    onChange={onInputChange}
                    colmd={0}
                    type="tel"
                    inline
                  />
                </div>
              </Form>
            </div>
          </div>
          <div className="btn  footer-actions btn-address-next">
            {mobileView ? (
              <>
                <button
                  type="button"
                  onClick={navigateToPrevious}
                  className="btn btn-primary  btn-prev-page"
                >
                  <span className="pr-2">
                    <img src={leftnormal} alt="right" className="right-icon" />
                  </span>
                  Previous
                </button>

                <button
                  type="button"
                  onClick={contactCreation}
                  disabled={nextDisabled}
                  className="btn btn-primary btn-next-page"
                >
                  {contactSaveInProgress ? (
                    <CircularProgress color="inherit" size={24} thickness={2.6} />
                  ) : (
                    <>
                      Next
                      <img src={rightnormal} alt="right" className="right-icon" />
                    </>
                  )}
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  onClick={navigateToPrevious}
                  className="btn btn-primary  btn-next-page"
                >
                  <span className="pr-3">
                    <img src={leftnormal} alt="right" className="right-icon" />
                  </span>
                  Previous
                </button>

                {applicantType === APPLICANT_TYPE.coApplicant ? (
                  <Progress completed={1} pending={3} />
                ) : (
                  <Progress completed={1} pending={1} />
                )}

                <button
                  type="button"
                  onClick={contactCreation}
                  disabled={nextDisabled}
                  className="btn btn-primary btn-next-page"
                >
                  {contactSaveInProgress ? (
                    <CircularProgress color="inherit" size={24} thickness={2.6} />
                  ) : (
                    'Next'
                  )}

                  <span className="px-3">
                    <img src={rightnormal} alt="right" className="right-icon" />
                  </span>
                </button>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default connect(
  (state, ownProps) => {
    const { applicantType } = ownProps.match.params || {};
    const isPrimaryApplicant = !applicantType || applicantType === APPLICANT_TYPE.primary;
    const fieldSuffix = isPrimaryApplicant ? '' : `_${applicantType}`;
    return {
      applicantType,
      fieldSuffix,
      isPrimaryApplicant,
      validationErrors: state.applicants.validationErrors,
      title: state.applicants[`title${fieldSuffix}`],
      firstName: state.applicants[`firstName${fieldSuffix}`],
      lastName: state.applicants[`lastName${fieldSuffix}`],
      email: state.applicants[`email${fieldSuffix}`],
      phoneNumber: state.applicants[`phoneNumber${fieldSuffix}`],
      contactSaveInProgress: state.applicants.contactSaveInProgress,
      contactDetails: state.applicants.contactDetails,
      opportunityId: state.applicants.opportunityId,
      getValidationErrorsForField: getValidationErrorsForFieldFunc(
        state.applicants.validationErrors,
      ),
    };
  },
  (dispatch) => ({
    onLiteInputChange: (fieldName, value, scrollToNextFieldOnChage) =>
      dispatch(liteFieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage)),
    setTab: () => dispatch(setCurrentTab('applicants')),
    onInputChange: (fieldName, value, scrollToNextFieldOnChage, ignoreHeaderOffset) => {
      dispatch(liteFieldChanged('applicants', 'personaldetailsChanged', true, false));
      return dispatch(
        fieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage, ignoreHeaderOffset),
      );
    },
    spouseCoApplicantCreation: (postLenderSelect, applicantType) =>
      dispatch(createSpouseCoApplicants(postLenderSelect, applicantType)),
    setTabComplete: (tabName) => {
      dispatch(setCompleteTab(tabName));
    },
    RetrieveOpportunity: () => dispatch(RetrieveOpportunity()),
  }),
)(withRouter(PersonalDetails));
