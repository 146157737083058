/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import { Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'react-bootstrap/Button';
import processGTMevent from '../../util/processGTMEvent';
import Error from '../../../assets/image/error.svg';
import Check from '../../../assets/image/success.svg';
import call from '../../../assets/image/call.svg';
import './PersonalDetails.scss';
import { ROUTES } from '../../util/constants';
import ModalWindow from '../../components/ModalWindow';

const PersonalDetailsVerification = ({
  phoneNumber,
  // phoneNumberStatus = 'PENDING',
  onInputChange,
  editPhoneNumberFlag,
  submittedMobileOtp,
  onScrollToNextClick,
  email,
  password,
  passwordCreated,
  setAuthenticationSuccess,
  cognitoUserVerify,
  createOpportunity,
  onLiteInputChange,
  nextHashPath,
  leadCreation,
  noErrors,
  isAuthenticated,
  handleHTTPError,
  isExistingUser,
  cognitoUserStatus,
  leadCreationInProgress,
  error,
  setTab,
  currentTab,
  loanDetails,
  opportunityId,
  contactPhoneNumber,
}) => {
  useEffect(() => {
    onInputChange('phoneNumber', contactPhoneNumber, false);
  }, []);
  const [mobileotp, setmobileOtp] = useState('');
  const [OTPInvalid, isOTPInvalid] = useState(false);
  const [sessionTimeOut, setSessionTimeout] = useState(false);
  const [show, setShow] = useState(false);
  const [changeMobile, setChangedMobile] = useState(false);
  const [resendMessage, setResendMessage] = useState(false);
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const countrycode = '+61';
  const history = useHistory();

  const [cognitoUser, setCognitoUser] = useState('');
  let formattedphoneNumber = phoneNumber && phoneNumber.replace(/ /g, '');
  if (phoneNumber && phoneNumber.slice(0, 1) === '+') {
    formattedphoneNumber = formattedphoneNumber.substring(3);
  }
  if (phoneNumber && phoneNumber.slice(0, 1) === '0') {
    formattedphoneNumber = formattedphoneNumber.substring(1);
  }
  const valueFormatted =
    phoneNumber &&
    phoneNumber
      .trim()
      .replace(/\s|\(|\)|-/g, '')
      .replace(/^\+61/g, '0')
      .replace(/^61/g, '0');

  const rexPhone = new RegExp(/(^\(?(04|4)\)?[\s|-]*\d{2}[\s|-]*([\s|-]*\d[\s|-]*){6}$)/);

  const formattedNumber = rexPhone.test(valueFormatted);
  useEffect(() => {
    setTab();
    processGTMevent('applicants', 'passwordpageLoad', 'seepasswordPage');
  }, [currentTab, setTab]);

  const handleConfirmationSubmit = async (newVal) => {
    processGTMevent('applicants', 'SMSVerify', 'SMSVerify');
    setmobileOtp(newVal);

    if (newVal && newVal.length === 6) {
      onInputChange('submittedMobileOtp', newVal, true);
      try {
        onLiteInputChange('cognitoUserVerify', true, false);
        await Auth.sendCustomChallengeAnswer(cognitoUser, newVal).then(async (data) => {
          onLiteInputChange('cognitoUserVerify', false, false);

          try {
            // This will throw an error if the user is not yet authenticated:
            await Auth.currentSession();
            setAuthenticationSuccess(email);
            Auth.currentCredentials({
              bypassCache: true,
            }).then(async (user) => {
              await createOpportunity()
                .then(async () => {
                  localStorage.setItem(`pageCompleted${opportunityId}`, 'car');
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(err);
                });

              setTimeout(() => {
                loanDetails()
                  .then(async () => {
                    onInputChange('passwordCreated', true, false).then(() => {
                      onScrollToNextClick(nextHashPath, [], true, true, true);
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }, 5000);
              onLiteInputChange('cognitoUserVerify', false, false);
            });
          } catch {
            onLiteInputChange('cognitoUserStatus', true, false);
            setmobileOtp('');
            isOTPInvalid(true);
            setShow(true);
            onLiteInputChange('cognitoUserVerify', false, false);
            return false;
          }
        });
      } catch (err) {
        onLiteInputChange('cognitoUserVerify', false, false);
        // eslint-disable-next-line no-console
        console.error('inside catch block', err.message);
        setmobileOtp('');
        setSessionTimeout(true);
        setShow(true);
      }
      processGTMevent('applicants', 'verifyPassword', 'verificationComplete');
    }
  };

  const handleClose = () => {
    isOTPInvalid(false);
    setResendMessage(false);
    setShow(false);
    setSessionTimeout(false);
  };
  function intToHex(nr) {
    return nr.toString(16).padStart(2, '0');
  }
  function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues)
      .map(intToHex)
      .join('');
  }
  const resendConfirmationCode = async () => {
    onInputChange('submittedMobileOtp', null, true);
    await Auth.signIn(cognitoUser.username)
      .then((user) => {
        setCognitoUser(user);
        setmobileOtp('');
        setResendMessage(true);
        setShow(true);
        setisButtonDisabled(true);
        setSeconds(30);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const phoneNumberUpdate = (e) => {
    const mobileValue = e.target.value;
    if (!mobileValue) {
      onInputChange('error', 'Mobile Number field is mandatory', false);
      return;
    }
    onInputChange('phoneNumber', mobileValue, false);
    noErrors();

    if (isAuthenticated) {
      onInputChange('applicantDetails', true, true);
    }
  };
  const fieldChange = (e) => {
    processGTMevent('applicants', 'editNumber', 'editPhoneNumber');
    const mobileValue = e.target.value;
    onInputChange('phoneNumber', mobileValue, false);
    setTimeout(setChangedMobile(true), 3000);
  };

  const sendVerificationCode = () => {
    onInputChange('leadCreationInProgress', true, false);
    processGTMevent('applicants', 'sendVerification', 'verificationComplete');
    if (contactPhoneNumber != phoneNumber) {
      //creating lead if contact hone number is changed
      leadCreation()
        .then((response) => {})
        .catch((err) => {});
    }
    Auth.signUp({
      username: email,
      password: getRandomString(30),

      attributes: {
        email, // optional
        phone_number: countrycode.concat(formattedphoneNumber),
      },
    })
      .then(async () => {
        Auth.signIn(email).then((user) => {
          onInputChange('cognitoUserStatus', true, false);
          setCognitoUser(user);
          onInputChange('leadCreationInProgress', false, false);
        });
      })

      .catch((err) => {
        onInputChange('leadCreationInProgress', false, false);

        if (err.name === 'UsernameExistsException') {
          onInputChange(
            'error',
            'Account already exists with the email address you have entered.',
            false,
          );
          onInputChange('cognitoUserStatus', false, false);
        } else {
          onInputChange('error', err.message, false);
        }
      });
  };

  useEffect(() => {
    if (isButtonDisabled) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          setisButtonDisabled(false);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  return (
    <>
      {OTPInvalid && (
        <ModalWindow
          message="The Verification code that was entered is either expired or incorrect. Please try again"
          handleClose={handleClose}
          errorImg={Error}
          showModal={show}
        />
      )}

      {resendMessage && (
        <ModalWindow
          message="We have resent 6-digit verification code to your mobile"
          handleClose={handleClose}
          errorImg={Check}
          showModal={show}
        />
      )}
      {sessionTimeOut && (
        <ModalWindow
          message="Session timed out. Resend OTP"
          handleClose={handleClose}
          errorImg={Check}
          showModal={show}
        />
      )}
      {!passwordCreated && !editPhoneNumberFlag && (
        <Card className="content-body personal-details-container verifyyourself-body-section">
          <Card.Header className="col-12 page-header-container personal-details-header-container ">
            <h1 className="personal-details-header">Mobile verification</h1>
            <div className="personal-details-description">
              Protecting your information is our number one priority
            </div>
          </Card.Header>
          <Card.Body className="pb-0 applicant-body-container">
            <div className="row  applicant-body-section1">
              <div className="col-12  p-0 applicant-body-section2">
                {!cognitoUserStatus && (
                  <>
                    <div className="email-password verify-password">
                      <Form.Group className="login-input-group" controlId="email">
                        <Form.Label className="input-label-font">Mobile</Form.Label>
                        <InputGroup className="email-input-group-append">
                          <InputGroup.Prepend className="email-icon-input-append">
                            <img src={call} alt="phone" />
                          </InputGroup.Prepend>
                          <Form.Control
                            autoFocus
                            type="tel"
                            placeholder="0444467854"
                            defaultValue={phoneNumber}
                            className="email-border mobile-border"
                            onChange={fieldChange}
                            onBlur={phoneNumberUpdate}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Button
                        disabled={!phoneNumber || !formattedNumber || leadCreationInProgress}
                        onClick={sendVerificationCode}
                        className="btn btn-primary send-code"
                        type="button"
                      >
                        {leadCreationInProgress ? (
                          <CircularProgress color="inherit" size={48} thickness={4} />
                        ) : (
                          'Send code'
                        )}
                      </Button>
                    </div>
                    <div className="error-message">
                      <div className="invalid-feedback">{error}</div>
                    </div>
                  </>
                )}
                {cognitoUserStatus && (
                  <div className="verifyyourself-body-container">
                    <div className="details-container-wrapper ">
                      <div className="details-container-verify">
                        <div className="verifyyourself-content1 ">
                          We&apos;re sending you an SMS with a 6 digit code on{' '}
                          <span className="mobile-number">{phoneNumber}</span>
                        </div>
                      </div>
                    </div>
                    {mobileotp.length !== 6 && (
                      <>
                        <div className="verifyyourself-otp">
                          <OtpInput
                            onChange={handleConfirmationSubmit}
                            inputStyle="otp-input-field"
                            value={mobileotp}
                            shouldAutoFocus
                            hasErrored
                            numInputs="6"
                            errorStyle="error"
                          />
                        </div>
                        <div className="otp-info-container">
                          <div className="verifyyourself-content6">
                            Didn’t receive code?
                            <button
                              type="button"
                              data-test="resend code"
                              className="btn btn-link"
                              onClick={resendConfirmationCode}
                              disabled={isButtonDisabled}
                            >
                              <span className="verifyyourself-content8">Resend OTP</span>
                            </button>
                            &nbsp;
                            <span>
                              {seconds === 0 ? null : (
                                <span>
                                  <b>00: {seconds < 10 ? `0${seconds}` : seconds}</b>
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    {submittedMobileOtp && submittedMobileOtp.length === 6 && (
                      <div className="otp-verify-container">
                        {cognitoUserVerify && (
                          <CircularProgress size={60} thickness={4} className="otp-progress" />
                        )}
                        {cognitoUserVerify === false && error === '' && (
                          <>
                            <div xs={12} className="verified-icon-container">
                              <span className="success-icon" />
                            </div>
                            <div className="verified">Verified.</div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default PersonalDetailsVerification;
