import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes, { number } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import round from 'lodash/round';
import uniqBy from 'lodash/uniqBy';
import NumberFormat from 'react-number-format';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import { calculateDuration, amountPerMonth } from '../../lib/formatter';
import {
  EMPTYPE_SELF,
  ExpenseTypes,
  LIABILITIES_TYPES,
  INCOME_TYPES,
  AssetTypes,
  LIABILITY_CREDIT_CARD_OPTION,
} from '../../util/constants';

import './declarationFinancialSituation.scss';

const FormattedNumber = ({ amountValue, amountFreq }) => (
  <NumberFormat
    displayType="text"
    value={amountValue}
    prefix="$"
    thousandSeparator
    decimalScale={2}
    suffix="/m"
  />
);

FormattedNumber.defaultProps = {
  amountFreq: '',
};

FormattedNumber.propTypes = {
  amountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountFreq: PropTypes.string,
};

const FormattedNumberWithOutSuffix = ({ amountValue, amountFreq }) => (
  <NumberFormat
    displayType="text"
    value={amountValue}
    prefix="$"
    thousandSeparator
    decimalScale={2}
  />
);

FormattedNumberWithOutSuffix.defaultProps = {
  amountFreq: '',
};

FormattedNumberWithOutSuffix.propTypes = {
  amountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountFreq: PropTypes.string,
};

const DeclarationFinancialSituation = ({
  show,
  inProgress,
  onSubmit,
  assetsList,
  incomeList,
  expensesList,
  liabilityList,
  dependants,
  employerName,
  employmentStatus,
  empCurrDuration,
  foreseeSelected,
  foreseeFinancialChangesDescription,
  submitInProgress,
  netMonthlySurplus,
  netWorth,
  verifiedTotalNetMonthlyInc,
  verifiedMonthlyExpensesincRepayments,
  verifiedTotalAssets,
  verifiedTotalLiabilitiesRepayments,
  verifiedTotalLiabilities,
  verifiedTotalCreditCardLimit,
  relationshipStatus,
}) => {
  const [acceptDFS, setAcceptDFS] = useState(false);

  const handleCheckboxChange = (event) => {
    setAcceptDFS(event.target.checked);
  };

  const filterCreditlist = filter(liabilityList, ['category', LIABILITY_CREDIT_CARD_OPTION]);
  return (
    <Modal
      dialogClassName="modal-95w"
      show={show}
      keyboard={false}
      centered
      className="dashboard-dfs-docform"
    >
      <Modal.Header closeButton={false} className="finances-header-container">
        <Modal.Title className="dfs-docform-header-section">
          <div>Declaration of Financial Situation</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="dfs-docform-body-section">
        {inProgress ? (
          <div className="quote-spinner">
            <CircularProgress color="inherit" size={54} thickness={4} />
          </div>
        ) : (
          <>
            <fieldset className="dfs-docform-fieldset border p-3 rounded mb-4">
              <legend className="dfs-docform-label w-auto px-2">Income</legend>
              <Table hover responsive className="finances-table-section">
                <tbody className="finances-card-table-body">
                  {incomeList.map((task) => (
                    <tr className="finances-card-table-row" key={task.id || task.defaultId}>
                      <td className="finances-card-table-detail">
                        {get(find(INCOME_TYPES, ['value', task.category]), 'name', task.category)}
                      </td>
                      <td className="finances-card-table-amount">
                        <FormattedNumber amountValue={task.amount} />
                      </td>
                    </tr>
                  ))}

                  <tr className="finances-card-table-row">
                    <td className="finances-card-table-total label-font">
                      Total Net Monthly Income
                    </td>
                    <td className="finances-card-table-total finance-card-total-align">
                      <FormattedNumber amountValue={verifiedTotalNetMonthlyInc} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </fieldset>
            <fieldset className="dfs-docform-fieldset border p-3 rounded mb-4">
              <legend className="dfs-docform-label w-auto px-2">Assets</legend>
              <Table hover responsive className="finances-table-section">
                <tbody className="finances-card-table-body">
                  {assetsList.map((task) => (
                    <tr className="finances-card-table-row" key={task.id || task.defaultId}>
                      <td className="finances-card-table-detail">
                        {get(find(AssetTypes, ['value', task.category]), 'name', 'N/A')}
                      </td>
                      <td className="finances-card-table-amount">
                        <FormattedNumberWithOutSuffix amountValue={task.verifiedMonthlyAmount} />
                      </td>
                    </tr>
                  ))}

                  <tr className="finances-card-table-row">
                    <td className="finances-card-table-total label-font">Total Assets</td>
                    <td className="finances-card-table-total finance-card-total-align">
                      <FormattedNumberWithOutSuffix amountValue={verifiedTotalAssets} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </fieldset>
            <fieldset className="dfs-docform-fieldset border p-3 rounded mb-4">
              <legend className="dfs-docform-label w-auto px-2">Liabilities</legend>
              <Table hover responsive className="finances-table-section">
                <tbody className="finances-card-table-body">
                  {liabilityList.map((task) => (
                    <tr className="finances-card-table-row" key={task.id || task.defaultId}>
                      <td className="finances-card-table-detail">
                        {get(find(LIABILITIES_TYPES, ['value', task.category]), 'name', 'N/A')}
                      </td>
                      <td className="finances-card-table-amount">
                        <FormattedNumberWithOutSuffix amountValue={task.outstanding} />
                      </td>
                    </tr>
                  ))}

                  <tr className="finances-card-table-row">
                    <td className="finances-card-table-total label-font">Total Liabilities</td>
                    <td className="finances-card-table-total finance-card-total-align">
                      <FormattedNumberWithOutSuffix amountValue={verifiedTotalLiabilities} />
                    </td>
                  </tr>
                </tbody>
                {filterCreditlist && filterCreditlist.length > 0 && (
                  <>
                    <tr className="finances-card-table-row">
                      <td className="finances-card-table-detail no-border">
                        Total Credit card Limit:{' '}
                      </td>
                      <td className="finances-card-table-total finance-card-total-align no-border">
                        <FormattedNumberWithOutSuffix amountValue={verifiedTotalCreditCardLimit} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="dfs-question no-border">
                        <Alert variant="secondary" className="credit-card-note">
                          The credit card limit has not been taken into account when calculating
                          total liabilities, but is required in order to assess your financial
                          situation.
                        </Alert>
                      </td>
                    </tr>
                  </>
                )}
              </Table>
            </fieldset>
            <fieldset className="dfs-docform-fieldset border p-3 rounded mb-4">
              <legend className="dfs-docform-label w-auto px-2">Expenses</legend>
              <Table hover responsive className="finances-table-section">
                <tbody className="finances-card-table-body">
                  {expensesList.map((task) => (
                    <tr className="finances-card-table-row" key={task.id || task.defaultId}>
                      <td className="finances-card-table-detail">
                        {get(find(ExpenseTypes, ['value', task.category]), 'name', 'N/A')}
                      </td>
                      <td className="finances-card-table-amount">
                        <FormattedNumber amountValue={task.verifiedMonthlyAmount} />
                      </td>
                    </tr>
                  ))}

                  {liabilityList.map((task) => (
                    <tr className="finances-card-table-row" key={task.id || task.defaultId}>
                      <td className="finances-card-table-detail">
                        {get(find(LIABILITIES_TYPES, ['value', task.category]), 'name', 'N/A')}
                      </td>
                      <td className="finances-card-table-amount">
                        <FormattedNumber amountValue={task.verifiedMonthlyAmount} />
                      </td>
                    </tr>
                  ))}

                  <tr className="finances-card-table-row">
                    <td className="finances-card-table-total label-font">Total Expenses</td>
                    <td className="finances-card-table-total finance-card-total-align">
                      <FormattedNumber amountValue={verifiedMonthlyExpensesincRepayments} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </fieldset>
            <fieldset className="dfs-docform-fieldset border p-3 rounded mb-4">
              <legend className="dfs-docform-label w-auto px-2">Financial Situation</legend>
              <Table hover responsive className="finances-table-section">
                <tbody className="finances-card-table-body">
                  <tr className="finances-card-table-row">
                    <td className="finances-card-table-detail">Net Monthly Surplus</td>
                    <td className="finances-card-table-amount">
                      <NumberFormat
                        displayType="text"
                        value={netMonthlySurplus}
                        prefix="$"
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                  </tr>

                  <tr className="finances-card-table-row">
                    <td className="finances-card-table-total label-font">Net Worth</td>
                    <td className="finances-card-table-total finance-card-total-align">
                      <NumberFormat
                        displayType="text"
                        value={netWorth}
                        prefix="$"
                        thousandSeparator
                        decimalScale={2}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </fieldset>
            <fieldset className="dfs-docform-fieldset border p-3 rounded mb-4">
              <legend className="dfs-docform-label w-auto px-2">Your details</legend>
              <Table responsive className="finances-table-section">
                <tbody className="finances-card-table-body">
                  <tr className="dfs-question-table-row">
                    <td className="dfs-question">
                      What is your relationship status?
                      <span className="dfs-answer">{relationshipStatus}</span>
                    </td>
                  </tr>
                  <tr className="dfs-question-table-row">
                    <td className="dfs-question">
                      How many dependents do you have?
                      <span className="dfs-answer">{dependants}</span>
                    </td>
                  </tr>
                  {employerName && (
                    <tr className="dfs-question-table-row">
                      <td className="dfs-question">
                        What is the name of your current employer?
                        <span className="dfs-answer">{employerName}</span>
                      </td>
                    </tr>
                  )}
                  <tr className="dfs-question-table-row">
                    <td className="dfs-question">
                      What is the length of current employment?
                      <span className="dfs-answer">{empCurrDuration}</span>
                    </td>
                  </tr>
                  <tr className="dfs-question-table-row">
                    <td className="dfs-question">
                      What is the status of your current employment?
                      <span className="dfs-answer">{employmentStatus}</span>
                    </td>
                  </tr>
                  <tr className="dfs-question-table-row">
                    <td className="dfs-question">
                      Would you foresee any significant change in income in the next 12 months?
                      <br />
                      If so, explain.
                      <span className="dfs-answer mb-2">
                        {foreseeSelected === true ? 'Yes' : 'No'}
                      </span>
                      <span className="dfs-answer">{foreseeFinancialChangesDescription}</span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </fieldset>
            <div className="dfs-acceptance">
              <label className="checkbox-container checkbox-color" htmlFor="acceptDFS">
                I declared that all the assets, liabilities, income and expenses are correct and
                acknowledged that this information has been provided to Stratton Finance Pty Ltd to
                conduct a preliminary assessment of my consumer finance application.
                <input
                  type="checkbox"
                  checked={acceptDFS}
                  value={acceptDFS}
                  onChange={handleCheckboxChange}
                  name="acceptDFS"
                  id="acceptDFS"
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="dfs-submit-action email-quote py-3">
              <button
                type="button"
                data-test="lets-get-started-btn "
                className="letsgetstarted-btn submit-privacy"
                onClick={onSubmit}
                disabled={!acceptDFS || submitInProgress}
              >
                {submitInProgress ? (
                  <CircularProgress color="inherit" size={24} thickness={2} />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

DeclarationFinancialSituation.propTypes = {
  show: PropTypes.bool.isRequired,
  submitInProgress: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  assetsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      estimateVal: PropTypes.number.isRequired,
    }),
  ).isRequired,
  incomeList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ).isRequired,
  liabilityList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      frequency: PropTypes.number.isRequired,
    }),
  ).isRequired,
  expensesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  dependants: PropTypes.string,
  relationshipStatus: PropTypes.string,
  inProgress: PropTypes.bool,
  employerName: PropTypes.string,
  empCurrDuration: PropTypes.string.isRequired,
  employmentStatus: PropTypes.string,
  foreseeSelected: PropTypes.bool,
  foreseeFinancialChangesDescription: PropTypes.string,
  netMonthlySurplus: PropTypes.string,
  netWorth: PropTypes.string,
  verifiedTotalNetMonthlyInc: PropTypes.string,
  verifiedMonthlyExpensesincRepayments: PropTypes.string,
  verifiedTotalAssets: PropTypes.string,
  verifiedTotalLiabilities: PropTypes.string,
  verifiedTotalCreditCardLimit: PropTypes.string,
};

export default connect((state) => {
  let { employerName, employmentStartDate, employmentStatus } = state.applicants;
  const { abnRegistrationDate } = state.applicants;
  let empCurrDuration;
  if (state.applicants.mainEmployment === 'Yes') {
    employerName = state.applicants.employerName2;
    employmentStartDate = state.applicants.employmentStartDate2;
    employmentStatus = state.applicants.employmentStatus2;
  }

  if (employmentStartDate && employmentStatus !== EMPTYPE_SELF) {
    empCurrDuration = calculateDuration(new Date(), employmentStartDate);
  } else if (employmentStatus === EMPTYPE_SELF && abnRegistrationDate) {
    empCurrDuration = calculateDuration(new Date(), abnRegistrationDate);
  } else {
    empCurrDuration = '';
  }

  const formttedIncome = (uniqBy(state.applicants.income, 'incomeType') || []).map((income) => {
    let amount = 0;
    switch (income.incomeType) {
      case 'rentalincome':
        amount = state.applicants.verifiedTotalNetMonthlyRentalInc;
        break;
      case 'government':
        amount = state.applicants.verifiedTotalNetMonthlyGovtInc;
        break;
      case 'Investments':
        amount = state.applicants.verifiedTotalNetMonthlyInvestmentInc;
        break;
      case 'superannuation':
        amount = state.applicants.verifiedTotalNetMonthlySuperAnnualInc;
        break;
      case 'PAYG Income':
        amount = state.applicants.verifiedTotalNetMonthlyPAGYInc;
        break;
      case 'Self-Employed Income':
        amount = state.applicants.verifiedTotalNetMonthlySelfEmployedInc;
        break;
      default:
        break;
    }

    return { ...income, amount };
  });

  const formttedLiabilities = (uniqBy(state.applicants.liabilities, 'liabilityType') || []).map(
    (liability) => {
      let outstanding = 0;
      switch (liability.liabilityType) {
        case 'Property(Home) Mortgage':
          outstanding = state.applicants.verifiedTotalMortgageHomeLiabilities;
          break;
        case 'Property(Investment)Mortgage':
          outstanding = state.applicants.verifiedTotalInvestmentLiabilities;
          break;
        case 'Personal loan':
          outstanding = state.applicants.verifiedTotalPersonalLoanLiabilities;
          break;
        case 'Credit Card':
          outstanding = state.applicants.verifiedTotalCreditCardLiabilities;
          break;
        case 'Overdraft':
          outstanding = state.applicants.verifiedTotalOverdraftLiabilities;
          break;
        case 'Motor Vehicle loan':
          outstanding = state.applicants.verifiedTotalMotorVehicleLoanLiabilities;
          break;
        case 'Recreational asset loan':
          outstanding = state.applicants.verifiedTotalRecreationalLiabilities;
          break;
        case 'Debt agreement':
          outstanding = state.applicants.verifiedTotalDebtAgreementLiabilities;
          break;
        case 'Tax debt':
          outstanding = state.applicants.verifiedTotalTaxDebtLiabilities;
          break;
        default:
          break;
      }

      return { ...liability, outstanding };
    },
  );

  return {
    assetsList: state.applicants.assets,
    incomeList: formttedIncome,
    liabilityList: formttedLiabilities,
    expensesList: state.applicants.expenses,
    dependants: state.applicants.dependants,
    relationshipStatus: state.applicants.relationship,
    foreseeSelected: state.applicants.foreseeSelected,
    netMonthlySurplus: state.applicants.netMonthlySurplus,
    netWorth: state.applicants.netWorth,
    // Income Verified
    verifiedTotalNetMonthlyInc: state.applicants.verifiedTotalNetMonthlyInc,
    // Assets Verified
    verifiedTotalAssets: state.applicants.verifiedTotalAssets,
    // Expenses Verified
    verifiedMonthlyExpensesincRepayments: state.applicants.verifiedMonthlyExpensesincRepayments,
    // Liabilities Verified
    verifiedTotalCreditCardLimit: state.applicants.verifiedTotalCreditCardLimit,
    verifiedTotalLiabilities: state.applicants.verifiedTotalLiabilities,
    foreseeFinancialChangesDescription: state.applicants.foreseeFinancialChangesDescription,
    employerName,
    empCurrDuration,
    employmentStatus,
  };
})(DeclarationFinancialSituation);
