/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import './PersonalDetails.scss';
import { CircularProgress } from '@material-ui/core';
import InputButton from '../../components/InputButton';
import rightnormal from '../../../assets/image/right-normal.svg';
import DatePickerWrapper from '../../components/DatePickerWrapper';
import processGTMevent from '../../util/processGTMEvent';
import {
  CITIZENSHIP_STATUS,
  WORKING_VISA,
  OTHER_VISA,
  DEPENDENTS,
  RELATIONSHIP,
} from '../../util/constants';

const LivingArrangement = ({
  citizenshipStatus,
  employmentDetails,
  relationship,
  dependants,
  currentTab,
  setTab,
  validateDateField,
  visaExpiryDt,
  validationErrors,
  onScrollToNextClick,
  getValidationErrorsForField,
  onInputChange,
  applicants,
  reQuote,
  handleHTTPError,
  hashPath,
  nextHashPath,
  livingArrangementInProgress,
  opportunityId,
}) => {
  useEffect(() => {
    setTab();
  }, [currentTab, setTab]);

  const handleLivingArrangementInsert = () => {
    onInputChange('livingArrangementInProgress', true, false);
    applicants()
      .then(() => {
        localStorage.setItem(`pageCompleted${opportunityId}`, 'dependent-details');
        reQuote();
        onInputChange('livingArrangementInProgress', false, false);
        onInputChange('employmentDetails', true, false).then(() => {
          onScrollToNextClick(nextHashPath, [], true, true, true);
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        onInputChange('livingArrangementInProgress', false, false);
        console.error(error);
      });
    processGTMevent('applicants', 'inlineNext', 'employmentDetails');
  };
  const nextDisabled =
    (validationErrors && validationErrors.length) ||
    !citizenshipStatus ||
    ((citizenshipStatus === WORKING_VISA || citizenshipStatus === OTHER_VISA) && !visaExpiryDt) ||
    !relationship ||
    (!dependants && dependants !== 0);
  return (
    <Card className={`content-body personal-details-container ${hashPath}`} id="livingarrangement">
      <Card.Header className="col-12 page-header-container personal-details-header-container scrollable-anchor-tag with-sidebar">
        <a name={hashPath} href={`#${hashPath}`}>
          &nbsp;
        </a>
        <h1 className="personal-details-header">Your living arrangements</h1>
        <div className="personal-details-description">
          We&apos;ll use this information to help calculate how much you can borrow
        </div>
      </Card.Header>
      <Card.Body className="pb-0 identity-details-body-container personal-body-container personal-body-section1 personal-body-section2">
        <Form noValidate className="col-12 identity-form-section py-2 px-1">
          <InputButton
            label={
              <>
                <span>What&apos;s your current Australian residency status?</span>
              </>
            }
            fields={CITIZENSHIP_STATUS}
            onChange={onInputChange}
            fieldName="citizenshipStatus"
            value={citizenshipStatus}
            scrollToNextFieldOnChage
            getvalidationerrors={getValidationErrorsForField}
          />

          {(citizenshipStatus === WORKING_VISA || citizenshipStatus === OTHER_VISA) && (
            <div className="form-row px-0">
              <DatePickerWrapper
                value={visaExpiryDt}
                onChange={onInputChange}
                validateDateField={validateDateField}
                fieldName="visaExpiryDt"
                label="Visa Expiry Date"
                minDtVal="01/01/1900"
                format="DD/MM/YYYY"
                columnCount="4"
                getValidationErrors={getValidationErrorsForField}
                inline
                className="px-0 visa-expiry-date"
              />
            </div>
          )}
          <InputButton
            label={
              <>
                <span>What&apos;s your current relationship status?</span>
              </>
            }
            fields={RELATIONSHIP}
            onChange={onInputChange}
            fieldName="relationship"
            value={relationship}
            scrollToNextFieldOnChage
            getvalidationerrors={getValidationErrorsForField}
          />

          <InputButton
            label={
              <>
                <span>How many dependants do you have?</span>
              </>
            }
            fields={DEPENDENTS}
            onChange={onInputChange}
            fieldName="dependants"
            value={dependants}
            getvalidationerrors={getValidationErrorsForField}
          />
        </Form>

        <div className="row container-footer py-3 px-3 mt-2 border-top">
          <div className="col-12 d-flex justify-content-end px-0">
            <button
              type="button"
              data-test="LivingArrangement-Next"
              className="btn btn-primary btn-next-page"
              hidden={employmentDetails}
              disabled={nextDisabled || livingArrangementInProgress}
              onClick={handleLivingArrangementInsert}
            >
              {livingArrangementInProgress ? (
                <CircularProgress color="inherit" size={24} thickness={2.6} />
              ) : (
                'Next'
              )}
              <span className="px-3">
                <img src={rightnormal} alt="right" className="right-icon" />
              </span>
            </button>

            <input
              type="button"
              id="btnsubmit"
              className="btn-dummy-page"
              style={{ visibility: 'hidden' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LivingArrangement;
