import { connect } from 'react-redux';
import map from 'lodash/map';
import find from 'lodash/find';
import Dashboard from './Dashboard';
import { liteFieldChanged } from '../../Actions/FieldChanged';
import {
  fetchOpportunity,
  RetrieveOpportunity,
  directOpportunityCreation,
  createOpportunity,
  reQuote,
  updatePrivacyDoc,
  updateDFSDoc,
  updateCreditQuote,
} from '../../Actions/car';
import { uploadIdentityDoc, uploadIncomeDoc } from '../../Actions/finances';
import { PRIMARY_APPLICANT } from '../../util/constants';
import { resetAllTabs } from '../../Actions/tab';
import { noErrors, handleHTTPError } from '../../Actions/errorHandler';
import {
  resetAllApplicationDataForNewForm,
  updateInsuranceConsent,
} from '../../Actions/applicants';

const mapStateToProps = (state) => ({
  currentTab: state.tab.tabName,
  depositAmount: state.car.depositAmount,
  maxRepaymentVal: state.estimation.maxRepaymentVal,
  minRepaymentVal: state.estimation.minRepaymentVal,
  purchasePrice: state.car.purchasePrice,
  maxInterestVal: state.estimation.maxInterestVal,
  minInterestVal: state.estimation.minInterestVal,
  freqVal: state.estimation.freqVal,
  value: state.account.selectedOpportunity,
  selectedOpportunity: state.account.selectedOpportunity,
  isExistingUser: state.applicants.isExistingUser,
  opportunityId: state.applicants.opportunityId,
  opportunityList: state.account.opportunityList,
  forceNewApplication: state.account.forceNewApplication,
  isAuthenticated: state.account.isAuthenticated,
  cognitoId: state.account.cognitoId,
  signUp: state.account.signUp,
  applicantId: state.account.applicantId,
  applicantType: state.applicants.applicantType || PRIMARY_APPLICANT,
  fetchOppurtunityInProgress: state.account.fetchOppurtunityInProgress,
  quoteInProgress: state.account.quoteInProgress,
  opportunityFetched: state.account.opportunityFetched,
  identitydoclist: state.applicants.identitydoclist,
  updatePrivacyInProgress: state.account.updatePrivacyInProgress,
  updateDFSDocInProgress: state.account.updateDFSDocInProgress,
  updateCreeditQuoteInProgress: state.account.updateCreeditQuoteInProgress,
  dfsAccepted: state.applicants.termNconditionChk,
  dfsRequested: state.applicants.dfsRequested,
  isPQProductSelectionGroup: state.applicants.isPQProductSelectionGroup,
  incomeverifydoclist: map(state.applicants.incomeverifydoclist, (incomeData) => {
    const incomeList = find(state.applicants.income, ['category', incomeData.category]);
    return {
      ...incomeData,
      incomeImageFile: incomeList && incomeList.imageFile,
      totalImages:
        ((incomeList && incomeList.imageFile && incomeList.imageFile.length) || 0) +
        ((incomeList && incomeData.imageFile && incomeData.imageFile.length) || 0),
    };
  }),
  title: state.applicants.title,
  acceptPrivacyPolicy: state.applicants.acceptPrivacyPolicy,
});

const mapDispatchToProps = (dispatch) => ({
  updatePrivacyDoc: () => dispatch(updatePrivacyDoc()),
  updateDFSDoc: () => dispatch(updateDFSDoc()),
  updateCreditQuote: () => dispatch(updateCreditQuote()),
  onInputChange: (fieldName, value, scrollToNextFieldOnChage) =>
    dispatch(liteFieldChanged('account', fieldName, value, scrollToNextFieldOnChage)),
  onCarDetailsChange: (fieldName, value, scrollToNextFieldOnChage) =>
    dispatch(liteFieldChanged('car', fieldName, value, scrollToNextFieldOnChage)),
  onInitComplete: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(liteFieldChanged('estimation', fieldName, value, scrollToNextFieldOnChage));
  },
  onOpportunityUpdate: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(liteFieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage));
  },
  fetchOpportunity: (cognitoId) => dispatch(fetchOpportunity(cognitoId)),
  // setAuthenticationError: (error) => dispatch(setAuthenticationFailure(error)),
  setAuthenticationError: () =>
    dispatch(liteFieldChanged('estimation', 'isInitComplete', false, false)),
  RetrieveOpportunity: () => dispatch(RetrieveOpportunity()),
  resetAllTabs: () => dispatch(resetAllTabs()),
  directOpportunityCreation: () => dispatch(directOpportunityCreation()),
  noErrors: () => dispatch(noErrors()),
  handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
  createOpportunity: () => dispatch(createOpportunity()),
  reQuote: () => dispatch(reQuote()),
  uploadIdentityDoc: (document) => {
    dispatch(uploadIdentityDoc(document));
  },
  uploadIncomeDoc: (document) => {
    dispatch(uploadIncomeDoc(document));
  },
  resetAllApplicationDataForNewForm: () => {
    dispatch(resetAllApplicationDataForNewForm);
  },
  updateInsuranceConsent: (opportunityId, applicantId, value) =>
    dispatch(updateInsuranceConsent(opportunityId, applicantId, value)),
});

const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardContainer;
