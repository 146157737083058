/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Table, Form, InputGroup, Alert } from 'react-bootstrap'
import { Grid, Button, ButtonGroup } from '@material-ui/core';
import reduce from 'lodash/reduce';
import round from 'lodash/round';
import find from 'lodash/find';
import unionBy from 'lodash/unionBy';
import NumberFormat from 'react-number-format';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cls from 'classnames';
import { amountPerMonth } from '../../../../lib/formatter';
import deleteImg from '../../../../../assets/image/delete.svg';
import { DELETE_CONFIRM, FREQ_OPTIONS } from '../../../../util/constants';
// eslint-disable-next-line import/no-cycle
import { PAY_G, SELF_EMPLOYED } from '../IncomeDetails';
import '../../finances-common.scss';
import ConfirmDeleteRow from '../../../../components/ConfirmDeleteRow';
import DropDownSelect from '../../../../components/DropDownSelect';
import { commonIncomeType } from './IncomeDetailsNew';
import NumberInput from '../../../../components/NumberInput';

const IncomeTableDetailsNew = ({
  incomeData,
  onDelete,
  incomeTypesList,
  validationError,
  ValidationErrorFlag,
  onAddIncome,
  showSMDesign,
  updateIncome,
  currentTab
}) => {

  const [incomeList, updateList] = useState(incomeData || []);
  const inputRef = useRef(null);

  useEffect(() => {
    const filteredList = reduce(
      incomeList,
      (accum, stateIncome) => {
        const sourceIncome = find(incomeData, ['id', stateIncome.id]);
        if (sourceIncome) {
          accum.push({ ...stateIncome, ...sourceIncome, flagDelete: stateIncome.flagDelete });
        }
        return accum;
      },
      [],
    );
    updateList(unionBy(filteredList, incomeData, 'id'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeData]);


  // useEffect(() => {
  //   inputRef && inputRef.current.focus()
  // }, [inputRef])

  const flagToDelete = (id, flag = false) => () => {
    updateList(
      reduce(
        incomeList,
        (accum, income) => {
          const updatedIncome = { ...income };
          if (income.id === id) {
            updatedIncome.flagDelete = flag;
          }

          accum.push(updatedIncome);
          return accum;
        },
        [],
      ),
    );
  };

  const incometotal = round(
    reduce(
      incomeData,
      // eslint-disable-next-line max-len
      (calTotal, incomeData) =>
        calTotal +
        Number(
          incomeData.brokerUpdated || incomeData.payslipNotVerified
            ? incomeData.verifiedMonthlyAmount
            : amountPerMonth(incomeData.amount, incomeData.frequency),
        ),
      0,
    ),
  );

  const handleOnChangeCategory = (data, val) => {
    updateIncome({
      ...data,
      category: val,
      errorMsgCatg: ''
    });
  }

  const handleChange = (data, values) => {
    updateIncome({
      ...data,
      amount: values.value,
      errorMsgAmt: ''
    });
  }

  const onClickFrequency = (data, val) => {
    updateIncome({
      ...data,
      frequency: val,
    });
  }

  const smScreen = useMediaQuery('@media (max-width: 767.95px)') || showSMDesign;
  return (
    <>
      {ValidationErrorFlag && (
        <div id="errormessage">
          <Alert variant="danger" className="error-alert mx-2">
            <p className="error-message">{validationError}</p>
          </Alert>
        </div>
      )}
      <Table hover responsive className="finances-body-table">
        <tbody className="finances-card-table-body row-container">
          {incomeList.map((task, index) => (
            <tr className="finances-card-table-row container-row" key={task.id || task.defaultId}>
              <td colSpan={5}>
                {!((PAY_G === task.category || SELF_EMPLOYED === task.category) && currentTab) && (<Grid item xs={12} md={12}>
                  {((!task.brokerUpdated || task.payslipNotVerified) && (!task.flagDelete)) && (
                    <div className={cls(
                      'finances-card-table-detail-new',
                      'btn-delete'
                    )}>
                      <img
                        src={deleteImg}
                        alt="delete"
                        onClick={flagToDelete(task.id, true)}
                      />
                      <button
                        type="button"
                        className="px-0 btn btn-link finances-card-table-delete"
                        onClick={flagToDelete(task.id, true)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                  <ConfirmDeleteRow
                    task={task}
                    deleteConfirmText={DELETE_CONFIRM}
                    onDelete={onDelete}
                    flagToDelete={flagToDelete}
                    colSpan={5}
                  />
                </Grid>)}
                   
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <DropDownSelect
                      fieldName="category"
                      value={task.category || ''}
                      label="Category"
                      errorMsg={task.errorMsgCatg}
                      isDisable={(PAY_G === task.category || SELF_EMPLOYED === task.category) && currentTab}
                      onChange={(val) => handleOnChangeCategory(task, val)}
                      className=''
                      colxs="12"
                      colsm="6"
                      colmd="6"
                      options={
                        <>
                          <option value="">Select</option>
                          {(task.category === PAY_G || task.category === SELF_EMPLOYED) ? incomeTypesList?.map((state) => (
                            <option key={state.value} value={state.value}>
                              {state.name}
                            </option>
                          )) : currentTab ? commonIncomeType?.map((state) => (
                            <option key={state.value} value={state.value}>
                              {state.name}
                            </option>
                          )) : incomeTypesList?.map((state) => (
                            <option key={state.value} value={state.value}>
                              {state.name}
                            </option>
                          ))}
                        </>
                      }
                    />
                  </Grid>
                  {(task.category === PAY_G || task.category === SELF_EMPLOYED) && currentTab && (<Grid item xs={12} md={4}>
                    <Form.Label className="finances-form-label">
                      Employer
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Employer Name"
                        aria-label="employer"
                        value={task.applicantEmployerName}
                        index={index}
                        disabled={true}
                      />
                    </InputGroup>
                  </Grid>)}

                  {index === 0 ? <Grid item xs={12} md={4}>
                    <NumberInput name={"Amount"}
                      value={task.amount}
                      onChange={(values) => handleChange(task, values)}
                      error={task.errorMsgAmt}
                      inputRef={inputRef}
                    />
                  </Grid> : <Grid item xs={12} md={4}>
                    <NumberInput name={"Amount"}
                      value={task.amount}
                      onChange={(values) => handleChange(task, values)}
                      error={task.errorMsgAmt} />
                  </Grid>}
                  <Grid item xs={12} md={4}>
                    <Form.Group className='button-container'>
                      <Form.Label className="finances-form-label label-padding">Frequency</Form.Label>
                      <ButtonGroup variant="outlined" aria-label="outlined button group">
                        {FREQ_OPTIONS.map((item, index) => {
                          return (<Button onClick={() => { onClickFrequency(task, item.name) }} key={`button_${index}`} className={`btn-freq-group freq-show-finance ${item.name === task.frequency ? 'selectedButton' : ''}`}>{item.name}</Button>)
                        })}
                      </ButtonGroup>
                    </Form.Group>
                  </Grid>
                </Grid>
              </td>
            </tr>
          ))}

          {incometotal > 0 && (
            <tr className="finances-card-table-row">
              <td
                className={cls(
                  'finances-card-table-total label-font',
                  showSMDesign && 'no-x-padding',
                )}
                colSpan={(smScreen && 2) || 2}
              >
                Total income
              </td>
              <td
                className={cls(
                  'finances-card-table-total finance-card-total-align',
                  showSMDesign && 'no-x-padding',
                )}
                colSpan={(smScreen && 2) || 2}
              >
                <NumberFormat
                  displayType="text"
                  value={incometotal}
                  prefix="$"
                  suffix=" /m"
                  thousandSeparator
                  decimalScale={2}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <button
        type="button"
        className={cls('btn btn-primary finances-details-button', showSMDesign && 'no-x-margin')}
        onClick={onAddIncome}
      >
        <span className="btn-income add-income">+ Add income</span>
      </button>
    </>
  );
};

export default IncomeTableDetailsNew;