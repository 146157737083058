import { RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM } from '../Actions/applicants';
import {
  HTTP_404_ERROR,
  HTTP_500_ERROR,
  HTTP_OTHER_ERROR,
  VALIDATION_ERRORS,
  FINANCE_VALIDATION_ERRORS,
  NO_ERRORS,
} from '../Actions/errorHandler';

const initialState = {
  showErrorModal: false,
  errorMessage: '',
};

const execute404 = (state, action) => {
  action.props.push('/404');
  return { ...state };
};

const execute500 = (state, action) => {
  action.props.push('/500');
  return { ...state };
};

const executeOtherError = (state, action) => ({
  ...state,
  showErrorModal: true,
  /*  errorMessage: action.error.data.message, */
  errorMessage:
    (action.error && action.error.customMsg) || 'Something went wrong, please try again soon.',
});
const validationErrors = (state, action) => ({
  ...state,
  ValidationErrorFlag: true,
  errorCategory: action.category,
  errorMessage: action.error
    ? action.error
    : 'There are items that require your  attention.Please correct',
});

const validateIncome = (state, action) => ({
  ...state,
  ValidationErrorFlag: true,
  errorCategory: action.category,
  errorMessage: action.error,
});

const noErrors = (state) => ({
  ...state,
  ValidationErrorFlag: false,
  errorMessage: '',
  errorCategory: '',
  showErrorModal: false,
});
const errorHandlerReducer = (state = initialState, action) => {
  switch (action.type) {
    case HTTP_404_ERROR:
      return execute404(state, action);
    case HTTP_500_ERROR:
      return execute500(state, action);
    case HTTP_OTHER_ERROR:
      return executeOtherError(state, action);
    case VALIDATION_ERRORS:
      return validationErrors(state, action);
    case FINANCE_VALIDATION_ERRORS:
      return validateIncome(state, action);
    case NO_ERRORS:
      return noErrors(state, action);
    case RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM:
      return noErrors(initialState, action);
    default:
      return state;
  }
};

export default errorHandlerReducer;
