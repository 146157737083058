/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EstimationDetails from './Estimation';
import EstimateLenderPage from './EstimateLenderPage';
import fieldChanged from '../../Actions/FieldChanged';
import ProgressBar from '../../components/ProgressBar';

class EstimateLoadingpage extends React.Component {
  constructor(props) {
    super(props);
    this.enableMessage = this.enableMessage.bind(this);

    this.state = {
      displayMessage: false,
    };

    this.timer = setTimeout(this.enableMessage, 3000);
  }

  componentDidUpdate() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.displayMessage) {
      clearTimeout(this.timer);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  enableMessage() {
    const { setProgressComplete } = this.props;
    setProgressComplete();
    this.setState({ displayMessage: true });
  }

  render() {
    const { displayMessage } = this.state;
    const { progressValue, ...restProps } = this.props;
    return (
      <>
        {!displayMessage ? (
          <EstimateLenderPage {...restProps} />
        ) : (
          <EstimationDetails {...restProps} progressValue={progressValue} />
        )}
      </>
    );
  }
}

EstimateLoadingpage.propTypes = {
  setProgressComplete: PropTypes.func.isRequired,
  progressValue: PropTypes.number.isRequired,
};

export default connect(
  (state) => ({
    progressValue: state.car.progress,
    assetType: state.car.assetType,
  }),
  (dispatch) => ({
    setProgressComplete: () => {
      dispatch(fieldChanged('car', 'progress', 100, false));
    },
  }),
)(EstimateLoadingpage);
