import { connect } from 'react-redux';
import UploadDocument from './UploadDocument';
import { setCompleteTab, setCurrentTab } from '../../Actions/tab';
import { setApplicationStrucutureStepper } from '../../Actions/stepperDetails';
import { getDocumentList, pqFinalSubmissionUploadDocument } from '../../Actions/finances';

const mapStateToProps = (state, ownProps) => {
    return {
        incomelistVal: state.applicants.income,
        partner: state.applicants.partner,
        partnerIncome: state.applicants.spouseincome || state.applicants.coapplicantincome,
        dlflag: state.applicants.dlflag,
        opportunityId: state.applicants.opportunityId,
        contactDetails: state.applicants.contactDetails,
        applicationStructure: state.applicants.applicationStructure,
        selectedLender: state.applicants.selectedLender,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setTab: () => dispatch(setCurrentTab('upload_document')),
    setTabComplete: (tabName) => {
        dispatch(setCompleteTab(tabName));
      },
    setApplicationStrucutureStepper: (applicationStructure) =>
        dispatch(setApplicationStrucutureStepper(applicationStructure)),
    getDocumentList: (isPartner) => dispatch(getDocumentList(isPartner)),
    pqFinalSubmissionUploadDocument: (payload) => dispatch(pqFinalSubmissionUploadDocument(payload)),
});

const UploadDoDocumentContainer = connect(mapStateToProps, mapDispatchToProps)(UploadDocument);
export default UploadDoDocumentContainer;
