import find from 'lodash/find';
import round from 'lodash/round';
import BaseReducer from './BaseReducer';
import {
  POPULATE_LOAN_DETAILS,
  REPOPULATE_LOAN_DETAILS,
} from '../Actions/car';
import processGTMevent from '../util/processGTMEvent';

export function findMinMax(quoteResp) {
  let min = quoteResp[0].objVal;
  let max = quoteResp[0].objVal;

  for (let i = 1; i < quoteResp.length; i + 1) {
    const v = quoteResp[i].objVal;
    min = v < min ? v : min;
    max = v > max ? v : max;
  }

  return { min, max };
}
class Estimation extends BaseReducer {
  constructor() {
    super('ESTIMATION');
  }

  getInitialState() {
    return {
      initialDefaultValues: [{ termVal: '5' }, { selectFreq: 'Monthly' }],
      validationRules: [],
      activeFields: this.getInitialActiveFields(),
      sharedFields: [],
      validationErrors: [],
      isInitComplete: false,
      termVal: '5',
      minRepaymentVal: '',
      maxRepaymentVal: '',
      minInterestVal: '',
      maxInterestVal: '',
      depositUpdated: '',
      purchasePriceUpdated: '',
      selectFreq: 'Monthly',
      residualSwitch: true,
      inProgress: false,
      loanOptions: '32',
      updateLoanAmt: false,
      selectedFreq: 'Weekly',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getInitialActiveFields() {
    return ['selectFreq'];
  }

  getNewActiveFields() {
    return this.getInitialActiveFields();
  }

  reduce(state = this.getInitialState(), action) {
    if (action.type === 'POPULATE_ESTIMATION') {
      const quoteResponseMinRateObj = find(action.quoteResponse.quotes, ['rangeStatus', 'minRate']);
      const quoteResponseMaxRateObj = find(action.quoteResponse.quotes, ['rangeStatus', 'maxRate']);
      const quoteResponseMinRepaymentsObj = find(action.quoteResponse.quotes, [
        'rangeStatus',
        'minRepayment',
      ]);
      const quoteResponseMaxRepaymentsObj = find(action.quoteResponse.quotes, [
        'rangeStatus',
        'maxRepayment',
      ]);
      let repaymentKey = 'weeklyRepayments';

      if (state.selectFreq === 'Monthly') {
        repaymentKey = 'monthlyRepayments';
      }
      if (state.selectFreq === 'Weekly') {
        repaymentKey = 'weeklyRepayments';
      }
      if (state.selectFreq === 'Fortnightly') {
        repaymentKey = 'fortnightlyRepayments';
      }
      const minRepaymentVal =
        quoteResponseMinRepaymentsObj[repaymentKey] &&
        round(quoteResponseMinRepaymentsObj[repaymentKey], 2).toFixed(0);
      const maxRepaymentVal =
        quoteResponseMaxRepaymentsObj[repaymentKey] &&
        round(quoteResponseMaxRepaymentsObj[repaymentKey], 2).toFixed(0);
      const minInterestVal = quoteResponseMinRateObj.customerRate.toFixed(2);
      const maxInterestVal = quoteResponseMaxRateObj.customerRate.toFixed(2);

      processGTMevent('Repayments', 'range', `${minRepaymentVal} - ${maxRepaymentVal}`);
      processGTMevent('Interest', 'range', `${minInterestVal} - ${maxInterestVal}`);

      processGTMevent('estimation', 'loanOptions', `${action.quoteResponse.eligibleOptions}`);
      return {
        ...state,
        loanOptions: action.quoteResponse.eligibleOptions,
        minRepaymentVal,
        maxRepaymentVal,
        minInterestVal,
        maxInterestVal,
      };
    }
    if (action.type === POPULATE_LOAN_DETAILS) {
      return {
        ...state,
        termVal: Math.round(Number(action.loanDetails.term)) / 12,
        loanAmount: action.loanDetails.loanAmount,
        residualSwitch: action.loanDetails.includeResidual,
      };
    }
    if (action.type === REPOPULATE_LOAN_DETAILS) {
      const finalSelectedQuote = find(action.quoteResponse.quotes, ['rangeStatus', null]);
      const quoteResponseMinRateObj = find(action.quoteResponse.quotes, ['rangeStatus', 'minRate']);
      const quoteResponseMaxRateObj = find(action.quoteResponse.quotes, ['rangeStatus', 'maxRate']);
      const quoteResponseMinRepaymentsObj = find(action.quoteResponse.quotes, [
        'rangeStatus',
        'minRepayment',
      ]);
      const quoteResponseMaxRepaymentsObj = find(action.quoteResponse.quotes, [
        'rangeStatus',
        'maxRepayment',
      ]);
      const quoteResponseMaxResidualObj = find(action.quoteResponse.quotes, [
        'rangeStatus',
        'maxResidual',
      ]);
      let repaymentKey = 'weeklyRepayments';

      if (state.selectFreq === 'Monthly') {
        repaymentKey = 'monthlyRepayments';
      }
      if (state.selectFreq === 'Weekly') {
        repaymentKey = 'weeklyRepayments';
      }
      if (state.selectFreq === 'Fortnightly') {
        repaymentKey = 'fortnightlyRepayments';
      }
      const minRepaymentVal =
        quoteResponseMinRepaymentsObj &&
        quoteResponseMinRepaymentsObj[repaymentKey] &&
        round(quoteResponseMinRepaymentsObj[repaymentKey], 2).toFixed(0);
      const maxRepaymentVal =
        quoteResponseMaxRepaymentsObj &&
        quoteResponseMaxRepaymentsObj[repaymentKey] &&
        round(quoteResponseMaxRepaymentsObj[repaymentKey], 2).toFixed(0);
      const minInterestVal =
        quoteResponseMinRateObj && quoteResponseMinRateObj.customerRate.toFixed(2);
      const maxInterestVal =
        quoteResponseMaxRateObj && quoteResponseMaxRateObj.customerRate.toFixed(2);
      const minResidualVal = 0;
      const maxResidualVal =
        quoteResponseMaxResidualObj && quoteResponseMaxResidualObj.residualAmount;
      processGTMevent('Repayments', 'range', `${minRepaymentVal} - ${maxRepaymentVal}`);
      processGTMevent('Interest', 'range', `${minInterestVal} - ${maxInterestVal}`);
      processGTMevent('estimation', 'availableLoanOptions', `${action.eligibleOptions}`);
      return {
        ...state,
        loanOptions: action.quoteResponse.eligibleOptions,
        maxBorrowCapacity:
          action.quoteResponse.maxBorrowCapacity && action.quoteResponse.maxBorrowCapacity - 2000,
        dealOriginationFeeIncGst: finalSelectedQuote && finalSelectedQuote.dealOriginationFeeIncGst,
        minRepaymentVal,
        maxRepaymentVal,
        minInterestVal,
        maxInterestVal,
        minResidualVal,
        maxResidualVal,
        weeklyRepayments: finalSelectedQuote?.weeklyRepayments,
        monthlyRepayments: finalSelectedQuote?.monthlyRepayments,
        fortnightlyRepayments: finalSelectedQuote?.fortnightlyRepayments,
        maxResidualAmount: finalSelectedQuote?.maxResidualAmount,
        maxResidualpercent: finalSelectedQuote?.maxResidualPercent,
        residualAmount: finalSelectedQuote?.residualAmount,
        customerRate: finalSelectedQuote?.customerRate,
      };
    }
    if (action.type === 'POPULATE_QUOTE_DETAILS') {
      const applicationStructure = action.requoteResponse.quotes[0].singleApplicantPassServ
        ? 'SingleApplicant'
        : action.requoteResponse.quotes[0].spousePassServ
        ? 'Spouse'
        : 'CoApplicant';
      return {
        ...state,
        quotes: action.requoteResponse,
        applicationStructure,
      };
    }
    return super.reduce(state, action);
  }
}

export default Estimation;
