/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cls from 'classnames';
import NumberFormat from 'react-number-format';
import { Auth } from 'aws-amplify';
import find from 'lodash/find';
import toUpper from 'lodash/toUpper';
import { CircularProgress } from '@material-ui/core';
import { ROUTES, PRIMARY_APPLICANT } from '../../util/constants';
import ContainerLayoutWrapper from '../Layouts/ContainerLayoutWrapper';
import PrivacyAcceptance from './PrivacyAcceptance';
import DeclarationFinancialSituation from './DeclarationFinancialSituation';
import CreditQuoteAcceptance from './CreditQuoteAcceptance';
import ServerErrorPage from '../../components/ServerErrorPage';
import rightnormal from '../../../assets/image/TArrow-Right.svg';
import InsurancePrivacyConsentModal from './InsurancePrivacyConsentModal';
import './dashboard.scss';

const Dashboard = ({
  onInputChange,
  value,
  fetchOpportunity,
  isExistingUser,
  setAuthenticationError,
  RetrieveOpportunity,
  fetchOppurtunityInProgress,
  opportunityFetched,
  onInitComplete,
  opportunityId,
  createOpportunity,
  onOpportunityUpdate,
  resetAllTabs,
  forceNewApplication,
  isAuthenticated,
  handleHTTPError,
  reQuote,
  updatePrivacyDoc,
  updateDFSDoc,
  updateCreditQuote,
  updatePrivacyInProgress,
  updateDFSDocInProgress,
  updateCreeditQuoteInProgress,
  applicantType,
  dfsAccepted,
  dfsRequested,
  isPQProductSelectionGroup,
  resetAllApplicationDataForNewForm,
  onCarDetailsChange,
  title,
  acceptPrivacyPolicy,
  quoteInProgress,
  updateInsuranceConsent,
}) => {
  const history = useHistory();
  const [fetchOpportunityval, setfetchOpportunityval] = useState('');
  const [showPrivacyConsentModal, setShowPrivacyConsentModal] = useState(false);
  const [Opportunityflag, setOpportunityflag] = useState(false);
  const [show, setShow] = useState(false);
  const [newApplication, setNewApplication] = useState(false);
  const [showPrivacy, setPrivacyPolicyModal] = useState(false);
  const [showDFS, setDFSModal] = useState(false);
  const [showcreditQuote, setcreditQuoteModal] = useState(false);
  const [showfailureError, setfailureError] = useState(false);
  const [countShowPqOpp, setCountShowPqOpp] = useState(0);
  const [cognitoId, setCognitoId] = useState('');
  useEffect(() => {
    onInputChange('opportunityFetched', false, false);
    // check the current user when the App component is loaded
    Auth.currentCredentials({
      // Optional, By default is false. If set to true,
      // this call will send a request to Cognito to get the latest user data
      bypassCache: true,
    })
      .then((user) => {
        setCognitoId(user.identityId);
        onInputChange('cognitoId', user.identityId, false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retreiveOpputunitues = () => {
    onInputChange('fetchOppurtunityInProgress', true, false);
    setfetchOpportunityval('');
    fetchOpportunity(cognitoId)
      .then((response) => {
        onInputChange('fetchOppurtunityInProgress', false, false);
        setfetchOpportunityval(response.data);
        if (response.data[0]?.id.startsWith('003')) {
          setOpportunityflag(false);
          setNewApplication(true);
        } else {
          setOpportunityflag(true);
        }
        if (isExistingUser) {
          setShow(true);
        }
      })
      .catch((err) => {
        onInputChange('fetchOppurtunityInProgress', false, false);
        setNewApplication(true);
      });
  };

  useEffect(() => {
    if (cognitoId) {
      retreiveOpputunitues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoId, isExistingUser]);

  const gotoDashboard = (noRedirect) => {
    if (!noRedirect && !quoteInProgress) {
      history.push(ROUTES.customerdashboard);
    }
  };

  const reviewApplication = (oppId, noRedirect) => {
    resetAllApplicationDataForNewForm();
    if (opportunityFetched && opportunityId === oppId) {
      gotoDashboard(noRedirect);
    }
    onInputChange('fetchOppurtunityInProgress', true, false);
    onOpportunityUpdate('opportunityId', oppId, false);
    if (oppId) {
      reQuote();
    }
    RetrieveOpportunity()
      .then(() => {
        onInputChange('opportunityFetched', true, false);
        onInputChange('fetchOppurtunityInProgress', false, false);
        onInitComplete('isInitComplete', true, false);
        onInputChange('error', null, false);
        gotoDashboard(noRedirect);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('error', error);
        // handleHTTPError(error, history);
      });
  };

  useEffect(() => {
    if (fetchOpportunityval && cognitoId) {
      const sourceTofind = 'CARSALES';
      let countDontShowPq = 0;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < fetchOpportunityval.length; i++) {
        const opp = fetchOpportunityval[i];
        if (opp?.dontShowOppinPQ === 'true') {
          countDontShowPq++;
        }

        const oppId = fetchOpportunityval[i]?.id;
        if (oppId.startsWith('003')) {
          onOpportunityUpdate('firstName', opp.firstName, false);
          onOpportunityUpdate('lastName', opp.lastName, false);
          onOpportunityUpdate('email', opp.email, false);
          onOpportunityUpdate('phoneNumber', opp.mobile, false);
          onOpportunityUpdate('title', opp.title, false);
          onOpportunityUpdate('postcode', opp.postCode, false);
        } else {
          const applicantType = find(opp.financedAsset[0].applicant, {
            /* applicantId, */ cognitoId,
            cognitoId: cognitoId,
          });
          const applicantDetails = find(opp.financedAsset[0].applicant, {
            /* applicantId, */ cognitoId,
            privacyRequired: true,
          });
          const applicantDetailsDFS = find(opp.financedAsset[0].applicant, {
            /* applicantId, */ cognitoId,
            DFSRequired: true,
          });
          const applicantDetailsCreditQuote = find(opp.financedAsset[0].applicant, {
            /* applicantId, */ cognitoId,
            creditQuoteRequired: true,
          });
          const applicantInsurnaceConsentRequired = find(opp.financedAsset[0].applicant, {
            cognitoId,
            insuranceConsentRequired: true,
          });
          // const carSalesLeadSource = toUpper(opp.financedAsset[0].leadSource);
          const filteredLeadSource =
            toUpper(opp.financedAsset[0].leadSource).indexOf(sourceTofind) > -1;
          const isCarsalesAccount = filteredLeadSource && (!title || !acceptPrivacyPolicy);
          if (filteredLeadSource) {
            onInputChange('isCarsalesAccount', isCarsalesAccount, true);
          }
          if (applicantDetails) {
            onOpportunityUpdate('opportunityId', opp.id, false);
            onOpportunityUpdate('applicantId', applicantDetails.applicantId, false);
            onOpportunityUpdate('applicantType', applicantDetails.applicantType, false);
            setPrivacyPolicyModal(true);
            break;
          }
          if (applicantDetailsDFS) {
            onOpportunityUpdate('applicantId', applicantDetailsDFS.applicantId, false);
            onOpportunityUpdate('applicantType', applicantDetailsDFS.applicantType, false);
            onOpportunityUpdate('dfsRequested', applicantDetailsDFS.DFSRequired, false);
            reviewApplication(opp.id, true);
            setDFSModal(true);
            break;
          }
          if (applicantDetailsCreditQuote) {
            onOpportunityUpdate('applicantId', applicantDetailsCreditQuote.applicantId, false);
            onOpportunityUpdate('applicantType', applicantDetailsCreditQuote.applicantType, false);
            reviewApplication(opp.id, true);
            setcreditQuoteModal(true);
            break;
          }
          if (applicantType) {
            onOpportunityUpdate('applicantType', applicantType.applicantType, false);
          }
          if (applicantInsurnaceConsentRequired) {
            onOpportunityUpdate('opportunityId', opp.id, false);
            onOpportunityUpdate(
              'applicantType',
              applicantInsurnaceConsentRequired.applicantType,
              false,
            );
            onOpportunityUpdate(
              'applicantId',
              applicantInsurnaceConsentRequired.applicantId,
              false,
            );
            setShowPrivacyConsentModal(true);
            break;
          }
        }
      }
      setCountShowPqOpp(countDontShowPq)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOpportunityval]);

  const continueNewApplication = () => {
    localStorage.removeItem('pageCompleted');
    const applicationStateNew = true;
    setNewApplication(applicationStateNew);
    if (cognitoId) {
      if (applicationStateNew) {
        onInputChange('forceNewApplication', true, false);
        setAuthenticationError(false);
        // resetAllTabs();
        onInitComplete('isInitComplete', false, false);
        onInputChange('isAuthenticated', true, false);
        //onl for testing
        // onCarDetailsChange('allAsset', true, false);
        // onCarDetailsChange('assetType', 'car', false); //this is not required if we show asset type question
        history.push(ROUTES.initial);
      }
    }
  };

  const handleSubmitPrivacy = () => {
    updatePrivacyDoc()
      .then(() => {
        setPrivacyPolicyModal(false);
        retreiveOpputunitues();
      })
      .catch((err) => {
        handleHTTPError(err);
      });
  };
  const handleSubmitDFSDoc = () => {
    updateDFSDoc()
      .then(() => {
        setDFSModal(false);
        retreiveOpputunitues();
      })
      .catch((err) => {
        handleHTTPError(err);
      });
  };

  const handleSubmitCreditQuote = () => {
    updateCreditQuote()
      .then(() => {
        setcreditQuoteModal(false);
        retreiveOpputunitues();
      })
      .catch((err) => {
        handleHTTPError(err);
      });
  };
  const handleClosePrivacyConsentModal = () => {
    setShowPrivacyConsentModal(false);
  };
  const onSubmitPrivacyConsent = () => {
    updateInsuranceConsent(true)
      .then((response) => { })
      .catch((error) => console.log('error', error));
    setShowPrivacyConsentModal(false);
  };
  const handleDeclinePrivacyConsentModal = () => {
    updateInsuranceConsent(false)
      .then((response) => { })
      .catch((error) => console.log('error', error));
    setShowPrivacyConsentModal(false);
  };
  return (
    <>
      {showfailureError && <ServerErrorPage />}
      <PrivacyAcceptance
        show={showPrivacy}
        onSubmit={handleSubmitPrivacy}
        submitInProgress={updatePrivacyInProgress}
      />
      <DeclarationFinancialSituation
        show={showDFS}
        onSubmit={handleSubmitDFSDoc}
        inProgress={fetchOppurtunityInProgress}
        submitInProgress={updateDFSDocInProgress}
      />
      <InsurancePrivacyConsentModal
        onSubmit={onSubmitPrivacyConsent}
        onClose={handleClosePrivacyConsentModal}
        show={showPrivacyConsentModal}
        onDicline={handleDeclinePrivacyConsentModal}
      />
      <CreditQuoteAcceptance
        show={showcreditQuote}
        onSubmit={handleSubmitCreditQuote}
        submitInProgress={updateCreeditQuoteInProgress}
      />
      <ContainerLayoutWrapper>
        {/* {!showfailureError && ( */}
        {countShowPqOpp !== fetchOpportunityval.length ? (<>
          <div className="application-header">Loan Applications</div>
          <div className="applciation-description">
            You can review your application(s) at anytime below.
          </div>
          {fetchOppurtunityInProgress ? (
            <div className="quote-spinner dashboard-spinner">
              <CircularProgress color="primary" size={54} thickness={4} />
            </div>
          ) : (
            <div
              className={cls(
                applicantType !== PRIMARY_APPLICANT && ' applicant co-applicant-align',
                'applicant',
              )}
            >
              {Opportunityflag &&
                fetchOpportunityval.length > 0 && !fetchOpportunityval[0]?.dontShowOppInPQ &&
                fetchOpportunityval.map((radioField) => (
                  <button
                    key={radioField.id}
                    type="button"
                    data-test="resume-application"
                    className={cls(
                      applicantType !== PRIMARY_APPLICANT &&
                      ' existing-application-block co-applicant-align',
                      'existing-application-block',
                    )}
                    onClick={() => {
                      reviewApplication(radioField.id, false);
                    }}
                    disabled={applicantType !== PRIMARY_APPLICANT}
                  >
                    <div className="existing-app-card" key={radioField.id}>
                      <div className="p-0">
                        <div className="opp-make-model-display">
                          <div className="opp-year">{radioField.financedAsset[0].year}</div>
                          <div className="opp-make-model">
                            {radioField.financedAsset[0].assetDescription &&
                              radioField.financedAsset[0].assetDescription.toLowerCase()}
                          </div>
                        </div>
                        <div className="opp-stage">{radioField.financedAsset[0].tabName}</div>
                        <hr className="progress-bar" />
                        <div className="dashboard-loandetails">
                          <div className="loan-amount-val">
                            <NumberFormat
                              displayType="text"
                              value={radioField.financedAsset[0].amount}
                              thousandSeparator
                              prefix="$"
                              decimalScale={0}
                            />
                          </div>
                          <div className="label-color">Loan amount</div>
                        </div>

                        {applicantType && applicantType === PRIMARY_APPLICANT && (
                          <div className="email-quote start-new-application">
                            <button
                              onClick={() => {
                                reviewApplication(radioField.id, false);
                              }}
                              type="button"
                              className="letsgetstarted-btn"
                            >
                              Review
                              <span className="image-align">
                                <img src={rightnormal} alt="right" className="right-icon" />
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </button>
                ))}
              {(newApplication) && (
                <div className="newapplication">
                  <div className="newapplication-section">
                    <div className="newapplication-title">New application</div>
                    <div className="newapplication-subtitle">
                      By starting new application, we will withdraw other application
                    </div>
                    <div className="email-quote start-new-application">
                      <button
                        type="button"
                        data-test="start-new-application"
                        className="letsgetstarted-btn"
                        onClick={continueNewApplication}
                      >
                        Start new application
                        <span className="image-align">
                          <img src={rightnormal} alt="right" className="right-icon" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>) : <>{
          fetchOppurtunityInProgress ? (
            <div className="quote-spinner dashboard-spinner">
              <CircularProgress color="primary" size={54} thickness={4} />
            </div>
          ) :
            <div className="message-title">
              Thank you for accepting the documents, your broker will be in touch shortly
            </div>}
        </>}
      </ContainerLayoutWrapper>
    </>
  );
};
export default Dashboard;
