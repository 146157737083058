import React from 'react';
import Form from 'react-bootstrap/Form';

const getErrorMessageRows = (getValidationErrorsFunc, fieldName) => {
  const errorMessageRows = getValidationErrorsFunc(fieldName)?.map((errorMessage) => (
    <Form.Control.Feedback key={fieldName} type="invalid">
      {errorMessage}
    </Form.Control.Feedback>
  ));

  return errorMessageRows;
};

export default getErrorMessageRows;
