// reducer
import { RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM } from '../Actions/applicants';
import {
  SET_CURRENT_TAB,
  SET_COMPLETE_TAB,
  SET_INCOMPLETE_TAB,
  RESET_ALL_TAB,
} from '../Actions/tab';

const initialState = {
  tabName: 'car',
  completedTabs: {},
};

function tab(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_TAB:
      return { ...state, tabName: action.tabName };
    case RESET_ALL_TAB:
    case RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM:
      return { ...initialState };
    case SET_COMPLETE_TAB:
      return { ...state, completedTabs: { ...state.completedTabs, [action.tabName]: true } };
    case SET_INCOMPLETE_TAB:
      return { ...state, completedTabs: { ...state.completedTabs, [action.tabName]: false } };
    default:
      return state;
  }
}

export default tab;
