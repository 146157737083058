import React from 'react';
import PropTypes from 'prop-types';
import Dropzone, { useDropzone } from 'react-dropzone';
import prettyBytes from 'pretty-bytes';
import Table from 'react-bootstrap/Table';
import { Box } from '@material-ui/core';
import Form from 'react-bootstrap/Form';
import { get } from 'lodash';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import './customDropzoneField.scss';

const CustomDropZoneField = ({
  handleOnClick,
  handleOnDrop,
  imagefile,
  handleDeleteFile,
  label,
  disabled,
  error,
  loading = false,
  isDisable = false,
}) => {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop: handleOnDrop,
    disabled,
    multiple: true,
    maxFiles: 2,
    // accept: {
    //   'image/jpeg': [],
    //   'application/pdf': [],
    // },
  });
  const imageData = get(imagefile, 'imagefile[0]?.size', false);
  const isUploadedImageAvailable = imageData && imageData?.status === 'Uploaded';
  return (
    <div className="preview-container">
      <div className="upload-container">
        <div className="container px-0">
          <div className="doc-label">{label}</div>

          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {get(imagefile, 'imagefile[0]?.size', !imagefile) && !isDisable && (
                <div
                  {...getRootProps({
                    className: 'dropzone' /* , onClick: (event) => console.log(event)  */,
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="btn-link-box-custom">
                    <button className="btn btn-link-custom drag-doc-btn">
                      <img
                        src={require('../../../assets/image/FileUpload.svg')}
                        alt="attachment"
                        className="file-upload-img"
                      />
                      <span className="drag-documents">Drag here or upload document</span>
                    </button>
                  </div>
                </div>
              )}

              {isDisable && (
                <div className="btn-link-box-custom disable-Uploadbox">
                  <button className="btn btn-link-custom drag-doc-btn">
                    <img
                      src={require('../../../assets/image/FileUpload.svg')}
                      alt="attachment"
                      className="file-upload-img"
                    />
                    <span className="drag-documents">Drag here or upload document</span>
                  </button>
                </div>
              )}

              <aside className="documents-attachment-section">
                <div responsive className="attachment-table-section">
                  <div className="files-attached">
                    {imagefile &&
                      imagefile?.map((file) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '10px',
                            height: '38px',
                            background: '#F3F7FA',
                          }}
                          key={file?.documentId}
                        >
                          <Box sx={{ display: 'flex' }}>
                            <Box sx={{ marginRight: '1rem' }}>
                              <img
                                src={require('../../../assets/image/File.svg')}
                                alt="folder"
                                className="files-img"
                              />
                            </Box>

                            <div className="file-name">{file?.path ? file.path : file?.name}</div>
                          </Box>
                          <Box sx={{ '&:hover': { cursor: 'pointer' } }}>
                            <img
                              src={require('../../../assets/image/Download_Icon.png')}
                              alt="download"
                              style={{ height: '1rem' }}
                              onClick={() => {
                                handleOnClick(imagefile);
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                  </div>
                </div>
              </aside>
            </>
          )}
        </div>
      </div>

      {fileRejections.length > 0 && (
        <Form.Control.Feedback type="invalid">
          {fileRejections[0]?.errors[0]?.message}
        </Form.Control.Feedback>
      )}
      {error && (
        <Box
          sx={{
            padding: '10px',
            backgroundColor: '#FFE5EA',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
            borderRadius: '4px',
          }}
        >
          <Box sx={{ color: '#E12F55' }}>
            <ErrorIcon />
          </Box>
          <Box sx={{ marginLeft: '9px' }}>{error}</Box>
        </Box>
      )}
    </div>
  );
};

CustomDropZoneField.propTypes = {
  error: PropTypes.string || PropTypes.bool,
  handleOnDrop: PropTypes.func.isRequired,
  imagefile: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.file,
      name: PropTypes.string,
      size: PropTypes.number,
    }),
  ),
  onChange: PropTypes.func,
  touched: PropTypes.bool,
};

export default CustomDropZoneField;
