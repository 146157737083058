import Modal from 'react-bootstrap/Modal';
import React from 'react';
import './ModalComponent.scss';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';

const ModalComponent = ({ show, handleClose, licencehelptextfilename }) => {
  const theme = useTheme();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" className="modalview">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalbody">
        <div className="PdfContainer">
          <Document
            file={licencehelptextfilename}
            className="PDFDocument"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className="PDFPage PDFPageOne"
              />
            ))}
          </Document>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModalComponent;
