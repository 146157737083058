/* eslint-disable import/no-cycle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Button, Modal, Row, Col, Form, Container } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import remove from 'lodash/remove';
import find from 'lodash/find';
import get from 'lodash/get';
import differenceWith from 'lodash/differenceWith';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { incomePrimary } from '../../../Actions/finances';
import renderNumberFormat from '../../../components/ReduxRenderField/renderNumberFormat';
import renderSelectField from '../../../components/ReduxRenderField/renderSelectField';
import { PAY_G, SELF_EMPLOYED } from './IncomeDetails';
import renderSelectButtonField from '../../../components/ReduxRenderField/renderSelectButtonField';
import {
  FREQ_OPTIONS,
  EMPSTATUS_FULL,
  EMPSTATUS_PART,
  EMPSTATUS_CASUAL,
  EMPTYPE_SELF,
} from '../../../util/constants';
import processGTMevent from '../../../util/processGTMEvent';
import { normalizeAmount } from '../../../lib/formatter';
import './incomedetails.scss';

class IncomeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile: (props.incomeDetails && props.incomeDetails.imageFile) || [],
      imageFile2: (props.incomeDetails && props.incomeDetails.imageFile2) || [],
      isSubmitting: false,
    };

    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.handleOnDropFile2 = this.handleOnDropFile2.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.handleDeleteFile2 = this.handleDeleteFile2.bind(this);
  }

  handleOnDrop(newImageFile, onChange) {
    const imageFile = [...this.state.imageFile, ...newImageFile];

    this.setState({ imageFile }, () => onChange(imageFile));
  }

  handleOnDropFile2(newImageFile, onChange) {
    const imageFile2 = [...this.state.imageFile2, ...newImageFile];
    this.setState({ imageFile2 }, () => onChange(imageFile2));
  }

  handleDeleteFile(file) {
    const updatedList = [...this.state.imageFile];
    remove(updatedList, { path: file.path, name: file.name });
    this.setState({ ...this.state, imageFile: [...updatedList] });
    this.props.onDelete({ ...this.props.incomeDetails, imageFile: [...updatedList] });
  }

  handleDeleteFile2(file) {
    const updatedList = [...this.state.imageFile2];
    remove(updatedList, { path: file.path, name: file.name });
    this.setState({ ...this.state, imageFile2: [...updatedList] });
    this.props.onDelete({ ...this.props.document, imageFile2: [...updatedList] });
  }

  close() {
    const { onCancel } = this.props;
    onCancel();
  }

  submit(data) {
    processGTMevent('finances', 'income', 'addIncome');
    const { incomeDetails, onUpdateIncome, incomeApi, opportunityId } = this.props;
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));

    let applicantEmployerDetails = {};

    if (data.applicantEmployerDetails) {
      const selectedEmployer = JSON.parse(data.applicantEmployerDetails);
      applicantEmployerDetails = {
        applicantEmployerName: selectedEmployer.applicantEmployerName,
        employmentId: selectedEmployer.employmentId,
      };
    }

    if (incomeApi) {
      incomeApi({
        ...incomeDetails,
        ...data,
        applicantEmployerDetails: undefined,
        ...applicantEmployerDetails,
      })
        .then((response) => {
          onUpdateIncome({
            ...data,
            id: response.data[0].id,
            ...applicantEmployerDetails,
            imageFile: this.state.imageFile,
            imageFile2: this.state.imageFile2,
          });
          localStorage.setItem(`pageCompleted${opportunityId}`, 'income-details');
          // addAssetId(response.data);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      isAdd,
      category,
      incomeData,
      incomeTypesList,
      incomeDetails,
      employmentStatus2,
      employmentStatus,
      employerNameOptions,
    } = this.props;
    const { isSubmitting } = this.state;
    const availableEmployerNames = differenceWith(
      employerNameOptions,
      incomeData,
      (allOption, addedOption) =>
        incomeDetails.applicantEmployerName !== allOption.name &&
        allOption.name === addedOption.applicantEmployerName,
    );
    remove(availableEmployerNames, (elemremoved) => {
      return elemremoved.name === undefined;
    });

    const availableCategories = incomeTypesList;
    const Title = get(find(incomeTypesList, ['value', category]), 'name', 'Related Document');

    return (
      <div>
        <Modal show size="md" onHide={this.close} className="modal-wrapper finances-modal-wrapper">
          <Modal.Header closeButton className="finances-header-container">
            <Row className="ml-1">
              <Modal.Title className="finances-header-section">
                {isAdd ? 'Add income' : Title}
              </Modal.Title>
            </Row>
          </Modal.Header>

          <Modal.Body className="p-0">
            <Container>
              <Row className="show-grid">
                <Col className="income-column">
                  <Form noValidate onSubmit={handleSubmit(this.submit)}>
                    {isAdd && (
                      <Form.Group>
                        <Form.Label className="finances-form-label">Category</Form.Label>
                        <Field
                          type="select"
                          placeholder="Select"
                          label="Category"
                          name="category"
                          options={availableCategories}
                          component={renderSelectField}
                          required
                        />
                      </Form.Group>
                    )}
                    {((category === PAY_G &&
                      (employmentStatus === EMPSTATUS_FULL ||
                        employmentStatus === EMPSTATUS_PART ||
                        employmentStatus === EMPSTATUS_CASUAL ||
                        employmentStatus2 === EMPSTATUS_FULL ||
                        employmentStatus2 === EMPSTATUS_PART ||
                        employmentStatus2 === EMPSTATUS_CASUAL)) ||
                      (category === SELF_EMPLOYED &&
                        (employmentStatus === EMPTYPE_SELF ||
                          employmentStatus2 === EMPTYPE_SELF))) && (
                        <Form.Group>
                          <Form.Label className="finances-form-label">Employer Name</Form.Label>
                          <Field
                            type="select"
                            placeholder="Select"
                            label="Employer Name"
                            name="applicantEmployerDetails"
                            id="applicantEmployerDetails"
                            options={availableEmployerNames}
                            component={renderSelectField}
                            required
                          />
                        </Form.Group>
                      )}
                    <Field
                      label="Amount"
                      name="amount"
                      type="text"
                      placeholder="0.00"
                      required
                      PrependComponent={() => (
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                        </InputGroup.Prepend>
                      )}
                      component={renderNumberFormat}
                      normalize={normalizeAmount}
                    />
                    <Form.Group>
                      <Form.Label className="finances-form-label">Frequency</Form.Label>
                      <Field
                        label="Frequency"
                        name="frequency"
                        options={FREQ_OPTIONS}
                        component={renderSelectButtonField}
                        classes={{
                          container: 'frequency-select-container',
                          inputButton: 'frequency-select-buttons',
                        }}
                        required
                      />
                    </Form.Group>

                    <hr className="horizantal-line" />
                    <Modal.Footer className="footer-container px-0">
                      <Button type="button" className="footer-cancel" onClick={this.close}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting || pristine || submitting}
                        className="footer-btn mr-0"
                        block
                      >
                        {isAdd ? 'Save' : 'Update'}
                      </Button>
                    </Modal.Footer>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const EnhancedIncomeForm = reduxForm({
  form: 'incomeDetails',
  validate: (values) => {
    const errors = {};

    if (!values.category) {
      errors.category = 'Required';
    }
    if (!values.applicantEmployerDetails) {
      errors.applicantEmployerDetails = 'Required';
    }

    if (!values.amount) {
      errors.amount = 'Required';
    } else if (Number.isNaN(values.amount)) {
      errors.amount = 'Must be a number';
    } else if (Number(values.amount) < 1) {
      errors.amount = 'amount  should be greater than one';
    }
    if (!values.frequency) {
      errors.frequency = 'Required';
    }
    return errors;
  },
})(IncomeForm);

const selector = formValueSelector('incomeDetails'); // <-- same as form name
export default connect(
  (state, props) => {
    const category = selector(state, 'category');
    const doctype = selector(state, 'doctype');
    const {
      employmentStatus,
      employerName,
      currentEmpId,
      employmentStatus2,
      employerName2,
      mainEmployment,
      currentEmpId2,
    } = state.applicants;
    let employerNameSelected;
    let employerId;
    let employmentStatusSelected;
    let employerNameSelected2;
    let employerId2;
    let employmentStatusSelected2;

    if (
      ((category === SELF_EMPLOYED && employmentStatus === EMPTYPE_SELF) ||
        (category === PAY_G &&
          (employmentStatus === EMPSTATUS_FULL ||
            employmentStatus === EMPSTATUS_PART ||
            employmentStatus === EMPSTATUS_CASUAL))) &&
      employerName
    ) {
      employerNameSelected = employerName;
      employerId = currentEmpId;
      employmentStatusSelected = employmentStatus;
    }

    if (
      ((category === SELF_EMPLOYED && employmentStatus2 === EMPTYPE_SELF) ||
        (category === PAY_G &&
          (employmentStatus2 === EMPSTATUS_FULL ||
            employmentStatus2 === EMPSTATUS_PART ||
            employmentStatus2 === EMPSTATUS_CASUAL))) &&
      employerName2
    ) {
      employerNameSelected2 = employerName2;
      employerId2 = currentEmpId2;
      employmentStatusSelected2 = employmentStatus2;
    }

    const employerNameOptions = [
      {
        name: employerNameSelected,
        value: JSON.stringify({
          applicantEmployerName: employerNameSelected,
          employmentId: employerId,
        }),
      },
      {
        name: employerNameSelected2,
        value: JSON.stringify({
          applicantEmployerName: employerNameSelected2,
          employmentId: employerId2,
        }),
      },
    ];

    let applicantEmployerDetails = '';

    if (props.incomeDetails) {
      applicantEmployerDetails = JSON.stringify({
        applicantEmployerName: props.incomeDetails.applicantEmployerName,
        employmentId: props.incomeDetails.employmentId,
      });
    }
    return {
      category,
      doctype,
      employerName: employerNameSelected,
      employerId,
      employmentStatus: employmentStatusSelected,
      employerNameOptions,
      employmentStatus2,
      initialValues: {
        frequency: FREQ_OPTIONS[0].value,
        ...(props.incomeDetails || {}),
        applicantEmployerDetails,
      },
    };
  },
  (dispatch) => ({
    incomeApi: (incomeData) => dispatch(incomePrimary(incomeData)),
  }),
)(EnhancedIncomeForm);
