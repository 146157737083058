import React from 'react';
import {
    Box,
} from '@material-ui/core';
import { borderStyling, secondaryTextColor } from '../../../styles/constant';

const styleContainer = { padding: '24px', borderBottom: borderStyling }
const styleTitle = { fontSize: '15px', lineHeight: '22px', fontWeight: 600 }
const styleTextDesc = {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    color: secondaryTextColor,
    marginTop: '12px',
}
const DocumentTitle = ({title=""}) => {
    return (
        <Box>
            <Box sx={styleContainer}>
                <Box sx={styleTitle}>{title} Documents</Box>
                <Box
                    sx={styleTextDesc}
                >
                    Upload high resolution JPG or PDF's only, no larger than 8MB
                </Box>
            </Box>
        </Box>
    );
}
export default DocumentTitle;