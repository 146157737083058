/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import NumberFormat from 'react-number-format';
import cls from 'classnames';

const PopulateQuoteDetails = ({
  lenderDetails: {
    customerRate,
    residualAmount,
    lenderEstablishmentFee,
    dealOriginationFeeIncGst,
    lenderAccountKeepingFee,
    monthlyRepayments,
    weeklyRepayments,
    fortnightlyRepayments,
    lenderlogo,
    lender,
  },
  onSelect,
  optionVal,
  selectFreq,
}) => (
  <Card className="lender-card">
    <Card.Body className="lender-body">
      <div className={cls('repayments', optionVal === 0 && 'best-repayments')}>
        <div className="option-val">
          Option &nbsp;
          {optionVal + 1}
        </div>
        {optionVal === 0 && <div className="option-val"> Lowest Repayment</div>}
      </div>
      <div className="repayment-section">
        <div className="lender-data">
          {selectFreq === 'Monthly' ? (
            <div className="lender-data-val">${monthlyRepayments}</div>
          ) : selectFreq === 'Weekly' ? (
            <div className="lender-data-val">${weeklyRepayments}</div>
          ) : (
            <div className="lender-data-val">${fortnightlyRepayments}</div>
          )}
          <div className="lender-data-freq">Repayments</div>
        </div>
        <div className="lender-data">
          <div className="lender-data-val">{customerRate}%</div>
          <div className="lender-data-freq">Interest rate</div>
        </div>
        <div className="lender-data">
          <div className="lender-data-val">
            <NumberFormat displayType="text" value={residualAmount} thousandSeparator prefix="$" />
          </div>
          <div className="lender-data-freq">Residual</div>
        </div>
      </div>
      <hr className="lender-options-marker" />
      <div className="quote-det-multiple">
        <li>
          <span className="quote-det">Stratton Fees ${dealOriginationFeeIncGst}</span>
        </li>
        <li>
          <span className="quote-det">Lender establishment fee ${lenderEstablishmentFee}</span>
        </li>
        <li>
          <span className="quote-det">Lender account keeping fee ${lenderAccountKeepingFee}</span>
        </li>
      </div>
      <hr className="lender-options-marker" />
      {/* <div className="display-logo">
        <img className="lender-logo" src={lenderlogo} alt={lender} />
      </div> */}
      <Button className="lender-btn-continue" onClick={() => onSelect(optionVal)} type="button">
        Select
      </Button>
    </Card.Body>
  </Card>
);
export default PopulateQuoteDetails;
