/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { CircularProgress } from '@material-ui/core';
import cls from 'classnames';
import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import '../../components/input-field.scss';
import processGTMevent from '../../util/processGTMEvent';
import rightnormal from '../../../assets/image/right-normal.svg';
import AmountRangeSelector from '../../components/AmountRangeSelector';
import ProgressBar from '../../components/ProgressBar';
import { ROUTES, UNSECURED_LOAN } from '../../util/constants';
import './carfinance.scss';
import CustomCard from './CustomCard';
import DescriptionTextPartner from '../../components/DescriptionPartner';
import { findPartnerandDescription } from '../../util/helpers';

const PersonalLoanAssetTypeFullScreen = (props) => {
  const {
    setTab,
    currentTab,
    purchasePrice,
    depositAmount,
    manufacturingYear,
    validationErrors,
    getValidationErrorsForField,
    onInputChange,
    flagChange,
    setTabComplete,
    nextFieldNameToFocus,
    scrollToNext,
    progressValue,
    createQuote,
    handleHTTPError,
    noErrors,
    validationCheck,
    getAllVehicleYears,
    onEstimateChange,
    quoteInProgress,
    termVal,
    isAuthenticated,
    forceNewApplication,
    assetType,
    estimatedLoan,
    estimatedLoanComplete,
    onLoadPageAsset,
    partnername
  } = props;
  const history = useHistory();

  const updatedAssetType = assetType?.toLowerCase().replace(/\s+/g, '-');
  const newpartnerflag = findPartnerandDescription(partnername) === null ? true : false;
  useEffect(() => {
    noErrors();
  }, [getAllVehicleYears, manufacturingYear, noErrors]);

  useEffect(() => {
    processGTMevent(assetType, 'PQ-PageLoad', 'Page Load');
    //return to main page if no selected asset type
    if (!updatedAssetType) {
      onLoadPageAsset(UNSECURED_LOAN);
    }

    if (setTab) {
      setTab('car');
      scrollToNext(`loan-amount~fullScreen`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextFieldNameToFocus) {
      scrollToNext(`${nextFieldNameToFocus}~fullScreen`);
    } 
  }, [nextFieldNameToFocus, scrollToNext]);

  const handleClick = () => {
    flagChange('carDetailsChanged', false);
    processGTMevent(assetType, 'termValue', termVal);
    if (validationErrors && validationErrors.length) {
      validationCheck();
      return;
    }
    if (purchasePrice - depositAmount < 5000) {
      validationCheck('Loan Amount cannot be less than $5000.');
      return;
    }
    noErrors();
    createQuote()
      .then(() => {
        // Add your code here
        setTabComplete(currentTab);

        if (isAuthenticated && forceNewApplication) {
          history.push(ROUTES.estimation);
        } else {
          history.push(ROUTES.contactDetails);
        }
      })
      .catch((error) => {
        handleHTTPError(error, history);
      });
    processGTMevent(assetType, 'seeContact', 'seeContactPage');

  };

  const maxTermVal = 7;

  const hideNextButton = () => {
    return estimatedLoanComplete;
  };

  const onHandleNextClick = () => {
    processGTMevent(assetType, 'purchasePriceValue', purchasePrice);
    if (
      (estimatedLoan || estimatedLoan === 0 || estimatedLoan === null) &&
      !estimatedLoanComplete
    ) {
      processGTMevent('personal-loan-details', 'PQPersonalLoanDetails-Next', 'Clicked')
      onInputChange('purchaseUse', 'Personal', false);
      onInputChange('estimatedLoanComplete', true, false);
      return false;
    }
  };

  const showSubmitButton = () => {
    return estimatedLoanComplete;
  };

  const cardItems = [{ title: 'Be employed for at least 12 months.',tooltipText:'',link:'',linkText:'' },
  { title: 'Have a clean credit history.',tooltipText:'', link:'',linkText:'' },
  { title: 'Be older than 18 years of age.',tooltipText:'',link:'',linkText:'' },
  { title: 'Be a resident/citizen of Australia or have a long-term working Visa.',tooltipText:'',link:'',linkText:'' },
  {title: 'Have no more than five defaults or missed repayments on your credit report in the last 3 months.',tooltipText:'Repayments not made on time on existing loans and credit cards that may have impacted your credit report. To find out more and how to get a',link:'https://url.au.m.mimecastprotect.com/s/_Kh3CJyBBWtqvqQvUVJLx8?domain=creditsmart.org.au/',linkText:'Free Credit Report Check, visit CreditSmart.'},
  {title: 'Have no more than two active payday loans.',tooltipText:'A small amount loan that lets you borrow up to $2000.',link:'',linkText:''}];

  return (
    <>
      <div className="container-section car-applicants-main-container full-width-container">
        <div className=" pt-4 car-applicants-main-tab-container" id="car">
          <div className="car-applicants-main-subcontainer px-0">
            <div className="row mx-0">
              <div className="col-12  text-center car-applicants-main-section">
                <Form noValidate className="car-applicants-main-form">
                  <div
                    className={cls(
                      'amount-range-container purchase-price-selector',
                      'scrollable-anchor-tag loan-amount',
                      updatedAssetType === 'commercial-equipment' ? 'fit-screen' : '',
                      'loan-estimation',
                    )}
                  >
                    <div className="label car-main-screen-label">
                      What is the estimated loan amount?
                    </div>
                    <div>
                      <CustomCard
                        className="newCard"
                        title="Our panel of lenders require the following eligibility criteria:"
                        items={cardItems}
                      />
                    </div>
                    <AmountRangeSelector
                      label={
                        <>
                          <span className="label ">Loan Amount</span>
                        </>
                      }
                      onChange={onInputChange}
                      fieldName="purchasePrice"
                      defaultValue={purchasePrice}
                      colsm={12}
                      colmd={8}
                      // oncreateQuote={createQuote}
                      getvalidationerrors={getValidationErrorsForField}
                      minValue={5000}
                      maxValue={250000}
                      step={1000}
                    />
                  </div>

                  {estimatedLoanComplete && (
                    <div
                      className={cls(
                        'amount-range-container loan-term-selector car-loan-term',
                        'scrollable-anchor-tag',
                      )}
                    >
                      <div className="label car-main-screen-label">
                        Over what term do you want the loan?
                      </div>
                      <div className="term scrollable-anchor-tag">
                        <a name="term" href="#term">
                          &nbsp;
                        </a>
                      </div>

                      <div className="amount-range-container term-val-selector">
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="label">Loan term</span>
                            </>
                          }
                          onChange={onEstimateChange}
                          fieldName="termVal"
                          defaultValue={termVal}
                          oncreateQuote={createQuote}
                          minValue={1}
                          maxValue={maxTermVal}
                          step={1}
                          termSlider
                          getvalidationerrors={getValidationErrorsForField}
                        />
                      </div>
                    </div>
                  )}

                  <div className="footer-actions fit-screen">
                    <button
                      type="button"
                      className="btn btn-primary btn-next-page"
                      hidden={hideNextButton()}
                      disabled={
                        (validationErrors && validationErrors.length) ||
                        purchasePrice - depositAmount < 5000
                      }
                      onClick={onHandleNextClick}
                    >
                      Next
                      <span className="pl-4">
                        <img src={rightnormal} alt="right" className="right-icon" />
                      </span>
                    </button>
                    <input
                      type="button"
                      id="btnsubmit"
                      className="btn-dummy-page"
                      style={{ visibility: 'hidden' }}
                    />

                    {showSubmitButton() && (
                      <button
                        type="button"
                        data-test="carmainscreen-estimatepage"
                        className="btn  btn-navigate-page"
                        onClick={handleClick}
                        disabled={quoteInProgress}
                      >
                        {quoteInProgress ? (
                          <CircularProgress color="inherit" size={24} thickness={2.6} />
                        ) : (
                          <>
                            Next
                            <span className="pl-4">
                              <img src={rightnormal} alt="right" className="right-icon" />
                            </span>
                          </>
                        )}
                      </button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-cardetails-page sticky-bottom">
        {!newpartnerflag && (<DescriptionTextPartner partnerItem={findPartnerandDescription(partnername)} />)}
        <ProgressBar progressValue={progressValue} />
      </div>
    </>
  );
};

export default PersonalLoanAssetTypeFullScreen;
