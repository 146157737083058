import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Box, Button, Typography } from '@material-ui/core';

const InsurancePrivacyConsentModal = ({ show, onClose, onSubmit, onDicline }) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      className="dashboard-privacy-container"
    >
      <Modal.Header className="finances-header-container">
        <Modal.Title className="privacy-header-section">
          <div>Insurance Privacy Consent</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '1.2rem' }}>
        <Typography variant="body1">
          Do you agree to give your consent to contact you to discuss comprehensive insurance, and
          if requested by you, provide you with a quote to ensure your asset is covered.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <div>
            <button className="btn btn-outline-dark  buttonContainer" onClick={onDicline}>
              No thanks
            </button>
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{ padding: '1rem', marginTop: '1.2rem' }}
            onClick={onSubmit}
          >
            Yes, agree
          </Button>
        </Box>
      </Modal.Body>
    </Modal>
  );
};
InsurancePrivacyConsentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default InsurancePrivacyConsentModal;
