/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
import reduce from 'lodash/reduce';
import last from 'lodash/last';
import find from 'lodash/find';
import _ from 'lodash';
import remove from 'lodash/remove';

import BaseReducer from './BaseReducer';
import requiredRule from '../ValidationRules/RequiredRule';
import phoneNumberRule from '../ValidationRules/PhoneNumberRule';
import emailRule from '../ValidationRules/EmailRule';
import licenceCardRule from '../ValidationRules/licenceCardRule';
import date18YearsRule from '../ValidationRules/Date18YearsRule';
import NoFutureDateRule from '../ValidationRules/NoFutureDateRule';
import postCodeRule from '../ValidationRules/PostCodeRule';
import nameFieldsRule from '../ValidationRules/NameFieldsRule';

import {
  ADD_ASSETID,
  ADD_LIABID,
  ADD_EXPID,
  ADD_INCOMEID,
  POPULATE_ABN_DETAILS,
  ADD_SPOUSEINCOMEID,
  ADD_INCOME_APPLICANTS,
  DELETE_INCOME_APPLICANTS,
  UPDATE_INCOME_APPLICANTS,
  ADD_SPOUSEINCOME_APPLICANTS,
  DELETE_SPOUSEINCOME_APPLICANTS,
  UPDATE_SPOUSEINCOME_APPLICANTS,
  INIT_ASSET_APPLICANTS,
  ADD_ASSET_APPLICANTS,
  DELETE_ASSET_APPLICANTS,
  UPDATE_ASSET_APPLICANTS,
  INIT_LIAB_APPLICANTS,
  ADD_LIAB_APPLICANTS,
  DELETE_LIAB_APPLICANTS,
  PREV_FINANCE_APPLICANTS,
  UPDATE_LIAB_APPLICANTS,
  INIT_EXPENSE_APPLICANTS,
  ADD_EXP_APPLICANTS,
  DELETE_EXP_APPLICANTS,
  UPDATE_EXP_APPLICANTS,
  INIT_POLICY_APPLICANTS,
  FORESEE_SELECTED_APPLICANTS,
  TERMSNCONDITION_CHECKED_APPLICANTS,
  UPLOAD_IDENTITY_APPLICANTS,
  UPLOAD_INCOMEDOC_APPLICANTS,
  INIT_INCOME_APPLICANTS,
  RESET_EMPLOYMENT_DATA,
} from '../Actions/finances';
import {
  DELETE_CURRENT_EMPLOYMENT_SECONDARY,
  DELETE_PREV_EMPLOYMENT,
  DELETE_ALL_EMPLOYMENTS,
  SET_APPLICATION_STRUCTURE,
} from '../Actions/applicants';
import {
  POPULATE_FINANCES,
  RETRIVE_ADDRRESS_APPLICANTS,
  POPULATE_APPLICANTS,
  POPULATE_CONTACT_DETAILS,
  POPULATE_ABN_SAVED_DATA,
  CLEAR_ADDRRESS_APPLICANTS,
  POPULATE_OPP_DETAILS,
  POPULATE_POSTLENDERSELECT_DETAILS,
} from '../Actions/car';
import {
  EMPSTATUS_FULL,
  EMPSTATUS_PART,
  EMPSTATUS_CASUAL,
  EMPTYPE_SELF,
  WORKING_VISA,
  DEFACTO,
  MARRIED,
  CO_APPLICANT,
  PRIMARY_APPLICANT,
  SPOUSE_APPLICANT,
  APPLICATION_STRUCTURE,
  APPLICANT_TYPE,
} from '../util/constants';
import { MANDATORY_EXPENSES } from '../containers/Finances/Expenses/ExpensesNew/ExpenseDetailsNew';
import promoCodeRule from '../ValidationRules/PromoCode';

const formatAddressObj = (addr, fieldSuffix = '') => ({
  [`addressId${fieldSuffix}`]: addr.id,
  [`refId${fieldSuffix}`]: addr.id,
  [`addressStartDate${fieldSuffix}`]: addr.addressStartDate,
  [`addressEndDate${fieldSuffix}`]: addr.addressEndDate,
  [`nonResident${fieldSuffix}`]: addr.internationalAddress,
  [`unitNumber${fieldSuffix}`]: addr.unitNumber,
  [`streetNumber${fieldSuffix}`]: addr.streetNumber,
  [`streetName${fieldSuffix}`]: addr.streetName,
  [`suburb${fieldSuffix}`]: addr.suburb,
  [`duration${fieldSuffix}`]: addr.livedOverTwoYears,
  [`residentialStatus${fieldSuffix}`]: addr.residentialStatus,
  [`postcode${fieldSuffix}`]: addr.postcode,
  [`state${fieldSuffix}`]: addr.state,
  [`country${fieldSuffix}`]: addr.country,
  [`landlordName${fieldSuffix}`]: addr.landlordName,
  [`landlordPhone${fieldSuffix}`]: addr.landlordPhone,
  [`address${fieldSuffix}`]: `${addr.unitNumber ? `${addr.unitNumber}/` : ''}${
    addr.streetNumber
  }${' '}${addr.streetName},${' '}${addr.suburb},${' '}${addr.postcode},${' '}${addr.state},${' '}${
    addr.country
  }`,
  [`employerAddress${fieldSuffix}`]: `${addr.unitNumber ? `${addr.unitNumber}/` : ''}${
    addr.streetNumber
  }${' '}${addr.streetName},${' '}${addr.suburb},${' '}${addr.postcode},${' '}${addr.state},${' '}${
    addr.country
  }`,
});

const expenses = MANDATORY_EXPENSES.map((expData, idx) => ({
  id: String(idx + 1),
  category: expData.value,
  amount: '0.00',
  frequency: 'Weekly',
}));

class Applicants extends BaseReducer {
  constructor() {
    super('APPLICANTS');
  }

  getInitialState() {
    return {
      validationRules: [
        {
          fieldName: 'firstName',
          rules: [
            requiredRule('firstName', 'Required'),
            nameFieldsRule('firstName', 'Invalid Firstname'),
          ],
        },
        {
          fieldName: 'lastName',
          rules: [
            requiredRule('lastName', 'Required'),
            nameFieldsRule('lastName', 'Invalid Surname '),
          ],
        },
        {
          fieldName: 'dateOfBirth_coApplicant',
          rules: [
            requiredRule('dateOfBirth_coApplicant', 'Must be in DD/MM/YYYY format'),
            date18YearsRule('dateOfBirth_coApplicant', 'Should be more than 18 years old'),
            NoFutureDateRule('dateOfBirth_coApplicant', 'Please enter date less than todays date'),
          ],
        },
        {
          fieldName: 'dateOfBirth',
          rules: [
            requiredRule('dateOfBirth', 'Must be in DD/MM/YYYY format'),
            date18YearsRule('dateOfBirth', 'Should be more than 18 years old'),
            NoFutureDateRule('dateOfBirth', 'Please enter date less than todays date'),
          ],
        },
        {
          fieldName: 'email',
          rules: [requiredRule('email', 'Required'), emailRule('email', 'Email is invalid')],
        },
        {
          fieldName: 'contactPhoneNumber',
          rules: [
            requiredRule('contactPhoneNumber', 'Required'),
            phoneNumberRule('mobile', 'Please enter a valid Australian mobile number'),
          ],
        },
        {
          fieldName: 'firstName_spouse',
          rules: [
            requiredRule('firstName_spouse', 'Required'),
            nameFieldsRule('firstName_spouse', 'Invalid Firstname'),
          ],
        },
        {
          fieldName: 'lastName_spouse',
          rules: [
            requiredRule('lastName_spouse', 'Required'),
            nameFieldsRule('lastName_spouse', 'Invalid Surname '),
          ],
        },
        {
          fieldName: 'email_spouse',
          rules: [requiredRule('email_spouse', 'Required'), emailRule('email', 'Email is invalid')],
        },
        {
          fieldName: 'phoneNumber_spouse',
          rules: [
            requiredRule('phoneNumber_spouse', 'Required'),
            phoneNumberRule('phoneNumber_spouse', 'Please enter a valid Australian mobile number'),
          ],
        },
        {
          fieldName: 'firstName_coApplicant',
          rules: [
            requiredRule('firstName_coApplicant', 'Required'),
            nameFieldsRule('firstName_coApplicant', 'Invalid Firstname'),
          ],
        },
        {
          fieldName: 'lastName_coApplicant',
          rules: [
            requiredRule('lastName_coApplicant', 'Required'),
            nameFieldsRule('lastName_coApplicant', 'Invalid Surname '),
          ],
        },
        {
          fieldName: 'email_coApplicant',
          rules: [
            requiredRule('email_coApplicant', 'Required'),
            emailRule('email_coApplicant', 'Email is invalid'),
          ],
        },
        {
          fieldName: 'phoneNumber_coApplicant',
          rules: [
            requiredRule('phoneNumber_coApplicant', 'Required'),
            phoneNumberRule(
              'phoneNumber_coApplicant',
              'Please enter a valid Australian mobile number',
            ),
          ],
        },
        {
          fieldName: 'passportNumber',
          rules: [requiredRule('passportNumber', 'Required')],
        },
        {
          fieldName: 'passportCountry',
          rules: [requiredRule('passportCountry', 'Required')],
        },
        {
          fieldName: 'passportExpiry',
          rules: [requiredRule('passportExpiry', 'Required')],
        },
        {
          fieldName: 'licenceType',
          rules: [requiredRule('licenceType', 'Required')],
        },
        {
          fieldName: 'licenceNumber',
          rules: [
            requiredRule('licenceNumber', 'Required'),
            licenceCardRule('licenceNumber', 'Please enter the valid driver’s licence number'),
          ],
        },
        {
          fieldName: 'driversLicencecardNumber',
          rules: [
            requiredRule('driversLicencecardNumber', 'Required'),
            licenceCardRule(
              'driversLicencecardNumber',
              'Please enter the valid licence card number',
            ),
          ],
        },
        {
          fieldName: 'driversLicenseNumber_coApplicant',
          rules: [
            requiredRule('driversLicenseNumber_coApplicant', 'Required'),
            licenceCardRule(
              'driversLicenseNumber_coApplicant',
              'Please enter the valid driver’s licence number',
            ),
          ],
        },
        {
          fieldName: 'driversLicenseCardNumber_coApplicant',
          rules: [
            requiredRule('driversLicenseCardNumber_coApplicant', 'Required'),
            licenceCardRule(
              'driversLicencecardNumber',
              'Please enter the valid licence card number',
            ),
          ],
        },
        {
          fieldName: 'dlState',
          rules: [requiredRule('dlState', 'Required')],
        },
        {
          fieldName: 'postcode',
          rules: [
            requiredRule('postcode', 'Required'),
            postCodeRule('postcode', 'Please enter the valid  4 digits postcode'),
          ],
        },
        {
          fieldName: 'promocode',
          rules: [promoCodeRule('promocode', 'Promocode is not valid')],
        },
        {
          fieldName: 'duration',
          rules: [requiredRule('duration', 'Required')],
        },
        {
          fieldName: 'residentialStatus',
          rules: [requiredRule('residentialStatus', 'Required')],
        },
        {
          fieldName: 'creditHistory',
          rules: [requiredRule('creditHistory', 'Required')],
        },
        {
          fieldName: 'citizenshipStatus',
          rules: [requiredRule('citizenshipStatus', 'Required')],
        },
        {
          fieldName: 'relationship',
          rules: [requiredRule('relationship', 'Required')],
        },
        {
          fieldName: 'dependants',
          rules: [requiredRule('dependants', 'Required')],
        },
        {
          fieldName: 'employmentStatus',
          rules: [requiredRule('employmentStatus', 'Required')],
        },
        {
          fieldName: 'employmentStartDate',
          rules: [requiredRule('employmentStartDate', 'Required')],
        },
        {
          fieldName: 'prevEmpStartDt',
          rules: [requiredRule('prevEmpStartDt', 'Required')],
        },
        {
          fieldName: 'visaExpiryDt',
          rules: [requiredRule('visaExpiryDt', 'Required')],
        },

        {
          fieldName: 'prevEmpEndDt',
          rules: [requiredRule('prevEmpEndDt', 'Required')],
        },
        {
          fieldName: 'employmentType',
          rules: [requiredRule('employmentType', 'Required')],
        },
        {
          fieldName: 'isAbnknown',
          rules: [requiredRule('isAbnknown', 'Required'), requiredRule('abnNumber', 'Required')],
        },
      ],
      imageFile: [],
      activeFields: this.getInitialActiveFields(),
      sharedFields: [],
      validationErrors: [],

      validationRemoveDependantFields: [
        {
          parentField: 'dlflag',
          dependentFields: ['licenceNumber', 'driversLicencecardNumber', 'dlState', 'licenceType'],
        },
        {
          parentField: 'passportFlag',
          dependentFields: ['passportNumber', 'passportCountry', 'passportExpiry'],
        },
      ],
      income: [],
      spouseincome: [],
      pendingIncomeSave: false,
      initAssetList: false,
      pendingAssetSave: false,
      assets: [],
      liabilities: [],
      initLiabList: false,
      pendingLiabilitySave: false,
      expenses,
      identitydoclist: [
        {
          id: 1,
          category: 'drivinglicence',
          status: 'VERIFIED',
        },
      ],
      incomeverifydoclist: [
        {
          id: 1,
          category: 'primarynetincome',
          status: 'PENDING',
        },
        {
          id: 2,
          category: 'spouseprimarynetincome',
          status: 'WAITING FOR APPROVAL',
        },
      ],
      passportFlag: false,
      initExpList: false,
      expenseCompleted: false,
      empdetailscomplete: false,
      personaldetailsChanged: false,
      addSpouse: false,
      currentEmpId: null,
      prevEmpId: null,
      consentToContact: true,
    };
  }

  getInitialActiveFields() {
    return ['title', 'firstName', 'lastName', 'email', 'phoneNumber'];
  }

  getNextFieldNameToFocus(changedFieldName, newValue, nextState) {
    let nextFieldName = null;

    switch (changedFieldName) {
      case 'title':
        nextFieldName = 'firstName';
        break;
      case 'firstName':
        nextFieldName = 'lastName';
        break;
      case 'lastName':
      case 'error':
        nextFieldName = 'email';
        break;
      case 'email':
        nextFieldName = 'phoneNumber';
        break;
      case 'phoneNumber':
        nextFieldName = 'dateOfBirth';
        break;
      case 'licenceNumber':
        nextFieldName = 'driversLicencecardNumber';
        break;
      case 'driversLicencecardNumber':
        nextFieldName = 'dlState';
        break;
      case 'dlState':
        nextFieldName = 'licenceType';
        break;
      case 'licenceType':
      case 'dlflag':
        nextFieldName = 'dateOfBirth';
        break;
      case 'identityDetails':
        nextFieldName = 'address';
        break;

      case 'passportFlag':
        nextFieldName = 'passportNumber';
        break;
      case 'passportNumber':
        nextFieldName = 'passportCountry';
        break;
      case 'passportCountry':
        nextFieldName = 'passportExpiry';
        break;
      case 'passportExpiry':

      case 'address':
        nextFieldName = 'duration';
        break;
      case 'duration':
        nextFieldName = 'residentialStatus';
        break;
      case 'unitNumber':
        nextFieldName = 'street';
        break;
      case 'street':
        nextFieldName = 'suburb';
        break;
      case 'suburb':
        nextFieldName = 'postcode';
        break;
      case 'postcode':
        nextFieldName = 'state';
        break;
      case 'creditHistory':
        nextFieldName = 'creditInfo';
        break;
      case 'livingDetails':
        nextFieldName = 'citizenshipStatus';
        break;
      case 'citizenshipStatus':
        if (newValue === WORKING_VISA) {
          nextFieldName = 'visaExpiryDt';
        } else {
          nextFieldName = 'relationship';
        }
        break;
      case 'relationship':
        nextFieldName = 'dependants';
        break;
      case 'employmentStatus':
        nextFieldName = 'employerName';

        break;
      case 'employerName':
      case 'employmentStartDate':
        nextFieldName = 'addEmployment';
        break;
      case 'addEmployment':
        nextFieldName = 'employmentStatus2';
        break;
      case 'employmentStatus2':
        nextFieldName = 'mainEmployment';
        break;
      // case 'employeeDuration':
      //     nextFieldName = 'prevEmpType';
      //     break;
      case 'mainEmployment':
        nextFieldName = 'employerName2';
        break;
      case 'prevEmpType':
        nextFieldName = 'prevEmpStartDt';
        break;
      case 'prevEmpStartDt':
        nextFieldName = 'prevEmpEndDt';
        break;

      default:
        break;
    }

    return nextFieldName;
  }

  getDependentFields(fieldName) {
    let fields;

    switch (fieldName) {
      case 'title':
      case 'firstName':
      case 'lastName':
      case 'email':
      case 'phoneNumber':
      case 'marketingSubscription':
      case 'acceptPrivacyPolicy':
        fields = [
          'applicantDetails',
          'verificationComplete',
          'identityDetails',
          'livingDetails',
          'employmentDetails',
          'empdetailscomplete',
          'creditcomplete',
          'editPhoneNumberFlag',
          'error',
          'isExistingUser',
        ];
        break;
      case 'error':
        fields = [
          'applicantDetails',
          'verificationComplete',
          'identityDetails',
          'livingDetails',
          'employmentDetails',
          'empdetailscomplete',
          'creditcomplete',
          'editPhoneNumberFlag',
        ];
        break;
      case 'passportCountry':
      case 'passportExpiry':
      case 'licenceNumber':
      case 'driversLicencecardNumber':
      case 'dlState':
      case 'licenceType':
      case 'dateOfBirth':
      case 'disclosedCreditRating':
        fields = [
          'identityDetails',
          'livingDetails',
          'employmentDetails',
          'empdetailscomplete',
          'creditcomplete',
        ];
        break;
      case 'dlflag':
        fields = [
          'licenceNumber',
          'driversLicencecardNumber',
          'dlState',
          'licenceType',
          'identityDetails',
          'livingDetails',
        ];
        break;
      case 'passportFlag':
        fields = [
          'passportNumber',
          'passportCountry',
          'passportExpiry',
          'identityDetails',
          'livingDetails',
        ];
        break;
      case 'residentialStatus':
        fields = ['isPropertyOwn', 'livingDetails', 'employmentDetails'];
        break;
      case 'address':
      case 'nonResident':
      case 'duration':

      case 'isPropertyOwn':
        fields = ['livingDetails', 'employmentDetails'];
        break;
      case 'manualEnter':
        fields = [
          'unitNumber',
          'streetName',
          'suburb',
          'postcode',
          'state',
          'address',
          'livingDetails',
          'employmentDetails',
        ];
        break;
      case 'searchAddress':
        fields = ['unitNumber', 'streetName', 'suburb', 'postcode', 'state'];
        break;
      case 'unitNumber':
      case 'streetName':
      case 'suburb':
      case 'postcode':
      case 'state':
        fields = ['livingDetails', 'employmentDetails'];
        break;
      case 'isAbnknown':
        fields = [
          'abnNumber',
          'abnfromDt',
          'searchAbn',
          'employmentType',
          'prevEmpStartDt',
          'employmentStatus',
          'prevEmpEndDt',
          'AcnNumber',
          'financialYear',
          'employerName',
          'empDocType',
          'imageFile',
          'empdetailscomplete',
          'InvalidAbn',
          'serverError',
          'InvalidAbnNumber',
          'behalfOfCompany',
          'trusteeType',
          'lastFYNetProfit',
          'abnRegistrationDate',
          'abnState',
          'abnStatus',
          'abrEntityType',
          'entityType',
          'entityName',
          'equifaxOrgId',
          'gstRegistrationdate',
          'gstRegistrationstatus',
          'mainEmployment',
          'employmentStatus2',
          'employerName2',
          // 'employmentStartDate2',
          'prevEmpType',
          'prevEmpStartDt',
          'prevEmpEndDt',
          'addEmployment',
          'isSavedEmployerName',
          'isSavedEmployerName2',
        ];
        break;
      case 'citizenshipStatus':
      case 'relationship':
      case 'dependants':
        fields = ['employmentDetails', 'isSpousePropOwner'];
        break;

      case 'employmentStatus':
        fields = [
          'abnNumber',
          'abnfromDt',
          'searchAbn',
          'employmentType',
          'AcnNumber',
          'financialYear',
          'empDocType',
          'imageFile',
          'empdetailscomplete',
          'InvalidAbn',
          'serverError',
          'InvalidAbnNumber',
          'behalfOfCompany',
          'trusteeType',
          'lastFYNetProfit',
          'abnRegistrationDate',
          'abnState',
          'abnStatus',
          'abrEntityType',
          'entityType',
          'entityName',
          'equifaxOrgId',
          'gstRegistrationdate',
          'gstRegistrationstatus',
          'prevEmpType',
          'prevEmpStartDt',
          'prevEmpEndDt',
          'employerName',
          // 'employmentStartDate',
          'isSavedEmployerName',
        ];
        break;
      case 'employmentStatus2':
        fields = [
          'prevEmpType',
          'prevEmpStartDt',
          'prevEmpEndDt',
          'employerName2',
          // 'employmentStartDate2',

          'isSavedEmployerName2',
        ];
        break;
      case 'employmentStartDate':
      case 'employmentStartDate2':
        fields = ['prevEmpType', 'prevEmpStartDt', 'prevEmpEndDt', 'empdetailscomplete'];
        break;
      case 'prevEmpStartDt':
      case 'prevEmpEndDt':
        fields = ['empdetailscomplete'];
        break;
      case 'employmentType':
        fields = ['AcnNumber'];
        break;
      case 'abnNumber':
        fields = [
          'searchAbn',
          'serverError',
          'InvalidAbnNumber',
          'behalfOfCompany',
          'trusteeType',
          'lastFYNetProfit',
          'empdetailscomplete',
        ];
        break;
      case 'behalfOfCompany':
        fields = ['lastFYNetProfit'];
        break;
      case 'isSpousePropOwner':
        fields = ['initLiabList'];
        break;
      default:
        fields = null;
    }

    return fields;
  }

  getNewActiveFields() {
    return this.getInitialActiveFields();
  }

  getNextTabUrl() {
    return 'loan';
  }

  reduce(state = this.getInitialState(), action) {
    if (action.type === 'RECEIVED_CONTACT_DETAILS_APPLICANTS') {
      return {
        ...state,
        title: action.title,
        firstName: action.firstName,
        lastName: action.lastName,
        phoneNumber: action.phoneNumber,
        email: action.email,
      };
    }
    if (action.type === INIT_INCOME_APPLICANTS) {
      if (state.prepopulateSpouseIncome || state.spouseincome) {
        return state;
      }

      const { relationship } = action.payload;
      const updatedSpouseIncome = reduce(
        state.spouseincome,
        (updatedincomelist, incomeData) => {
          let disableDelete = false;
          let pustToList = false;
          switch (incomeData.category) {
            case 'spouseprimarynetincome':
              disableDelete =
                relationship && (relationship === MARRIED || relationship === DEFACTO);
              pustToList = relationship && (relationship === MARRIED || relationship === DEFACTO);
              break;
            default:
              break;
          }

          if (pustToList) {
            updatedincomelist.push({
              ...incomeData,
              disableDelete,
              pustToList,
            });
          }

          return updatedincomelist;
        },
        [],
      );

      return { ...state, spouseincome: [...updatedSpouseIncome] };
    }
    if (action.type === ADD_INCOME_APPLICANTS) {
      const lastData = last(state.income);
      const id = (lastData && lastData.id + 1) || 1;
      return {
        ...state,
        pendingIncomeSave: true,
        income: [
          ...state.income,
          {
            id,
            added: true,
            ...action.payload,
          },
        ],
      };
    }
    if (action.type === DELETE_INCOME_APPLICANTS) {
      const updateIncomeList = [...state.income];
      remove(updateIncomeList, ['id', action.payload.id]);
      return {
        ...state,
        pendingIncomeSave: true,
        income: [...updateIncomeList],
      };
    }
    if (action.type === UPDATE_INCOME_APPLICANTS) {
      const incomeData = reduce(
        state.income,
        (updatedList, data) => {
          if (
            data.id === action.payload.id ||
            (data.defaultId && data.defaultId === action.payload.defaultId)
          ) {
            updatedList.push({ ...action.payload });
          } else {
            updatedList.push({ ...data });
          }

          return updatedList;
        },
        [],
      );
      return {
        ...state,
        income: [...incomeData],
        pendingIncomeSave: true,
      };
    }
    if (action.type === ADD_INCOMEID) {
      return {
        ...state,
        income: state.income.map((incomedata) => ({
          ...incomedata,
          id: action.payload[0].incomeid,
        })),
      };
    }

    if (action.type === ADD_SPOUSEINCOME_APPLICANTS) {
      const spouselastData = last(state.spouseincome);
      const id = (spouselastData && spouselastData.id + 1) || 1;
      return {
        ...state,
        pendingIncomeSave: true,
        spouseincome: [
          ...state.spouseincome,
          {
            id,
            added: true,
            ...action.payload,
          },
        ],
      };
    }
    if (action.type === DELETE_SPOUSEINCOME_APPLICANTS) {
      const updateSpouseIncomeList = [...state.spouseincome];
      remove(updateSpouseIncomeList, ['id', action.payload.id]);
      return {
        ...state,
        pendingIncomeSave: true,
        spouseincome: [...updateSpouseIncomeList],
      };
    }
    if (action.type === UPDATE_SPOUSEINCOME_APPLICANTS) {
      const spouseincomeData = reduce(
        state.spouseincome,
        (updatedList, data) => {
          if (
            data.id === action.payload.id ||
            (data.defaultId && data.defaultId === action.payload.defaultId)
          ) {
            updatedList.push({ ...action.payload });
          } else {
            updatedList.push({ ...data });
          }
          return updatedList;
        },
        [],
      );
      return { ...state, pendingIncomeSave: true, spouseincome: [...spouseincomeData] };
    }

    if (action.type === ADD_SPOUSEINCOMEID) {
      return {
        ...state,
        spouseincome: state.spouseincome.map((spouseincomedata) => ({
          ...spouseincomedata,
          id: action.payload[0].spouseincomeid,
        })),
      };
    }

    if (action.type === INIT_ASSET_APPLICANTS) {
      if (state.prepopulateAssets || !!state.assets) {
        return { ...state, initAssetList: true, pendingIncomeSave: false };
      }

      const { residentialStatus } = action.payload;
      let { assets } = state;

      assets = reduce(
        assets,
        (updatedAssetList, assetData) => {
          let disableDelete = false;
          const hideCurrentFinance = false;
          let pustToList = false;
          switch (assetData.category) {
            case 'homeproperty':
              disableDelete = residentialStatus === 'PropertyOwner';
              pustToList = residentialStatus === 'PropertyOwner';
              // pustToList = true;
              disableDelete = true;
              break;
            /*  case 'savings':
            case 'householditems':
              hideCurrentFinance = true;
              disableDelete = true;
              pustToList = true;
              break; */
            default:
              break;
          }

          if (pustToList) {
            updatedAssetList.push({
              ...assetData,
              disableDelete,
              hideCurrentFinance,
              pustToList,
            });
          }

          return updatedAssetList;
        },
        [],
      );

      return {
        ...state,
        initAssetList: true,
        pendingIncomeSave: false,
        assets,
      };
    }
    if (action.type === ADD_ASSET_APPLICANTS) {
      const lastAssetData = last(state.assets);
      const id = (lastAssetData && lastAssetData.id + 1) || 1;
      return {
        ...state,
        pendingAssetSave: true,
        assets: [
          ...state.assets,
          {
            added: true,
            id,
            ...action.payload,
          },
        ],
      };
    }
    if (action.type === DELETE_ASSET_APPLICANTS) {
      const updateAssetList = [...state.assets];
      remove(updateAssetList, ['id', action.payload.id]);
      return {
        ...state,
        pendingAssetSave: true,
        assets: [...updateAssetList],
      };
    }
    if (action.type === UPDATE_ASSET_APPLICANTS) {
      const assetData = reduce(
        state.assets,
        (updatedassetList, data) => {
          if (
            data.id === action.payload.id ||
            (data.defaultId && data.defaultId === action.payload.defaultId)
          ) {
            updatedassetList.push({ ...action.payload });
          } else {
            updatedassetList.push({ ...data });
          }

          return updatedassetList;
        },
        [],
      );
      return {
        ...state,
        assets: [...assetData],
        pendingAssetSave: true,
      };
    }
    if (action.type === ADD_ASSETID) {
      return {
        ...state,
        assets: state.assets.map((asset) => ({ ...asset, id: action.payload[0].assetid })),
      };
    }
    if (action.type === INIT_LIAB_APPLICANTS) {
      if (state.prepopulateLiability) {
        return { ...state, initLiabList: true, pendingAssetSave: false };
      }
      const { livingismortgage } = action.payload;
      let { liabilities } = state;

      liabilities = reduce(
        liabilities,
        (updatedLiabList, expenseData) => {
          let disableDelete = false;
          let enablePayout = false;
          let limitval = false;
          let pushLiabList = false;
          switch (expenseData.category) {
            case 'Property(Home) Mortgage':
              disableDelete =
                livingismortgage === 'PropertyOwner' ||
                (find(state.assets, ['category', 'homeproperty']) &&
                  find(state.assets, ['category', 'homeproperty']).underfinance);
              pushLiabList =
                livingismortgage === 'PropertyOwner' ||
                (find(state.assets, ['category', 'homeproperty']) &&
                  find(state.assets, ['category', 'homeproperty']).underfinance);
              break;
            case 'Property(Investment)Mortgage':
              disableDelete =
                find(state.assets, ['category', 'investmentproperty']) &&
                find(state.assets, ['category', 'investmentproperty']).underfinance;
              pushLiabList =
                find(state.assets, ['category', 'investmentproperty']) &&
                find(state.assets, ['category', 'investmentproperty']).underfinance;
              break;
            case 'Motor Vehicle loan':
              disableDelete =
                find(state.assets, ['category', 'motorvehicle']) &&
                find(state.assets, ['category', 'motorvehicle']).underfinance;
              enablePayout = true;
              pushLiabList =
                find(state.assets, ['category', 'motorvehicle']) &&
                find(state.assets, ['category', 'motorvehicle']).underfinance;
              break;
            case 'Recreational asset loan':
              disableDelete =
                find(state.assets, ['category', 'recreational']) &&
                find(state.assets, ['category', 'recreational']).underfinance;
              pushLiabList =
                find(state.assets, ['category', 'recreational']) &&
                find(state.assets, ['category', 'recreational']).underfinance;
              break;
            case 'Credit Card':
            case 'Overdraft':
              limitval = true;
              pushLiabList = true;
              break;
            default:
              break;
          }
          if (pushLiabList) {
            updatedLiabList.push({
              ...expenseData,
              disableDelete,
              enablePayout,
              limitval,
            });
          }
          return updatedLiabList;
        },
        [],
      );

      return {
        ...state,
        initLiabList: true,
        pendingAssetSave: false,
        liabilities,
      };
    }
    if (action.type === ADD_LIAB_APPLICANTS) {
      const lastLiabData = last(state.liabilities);
      const id = (lastLiabData && lastLiabData.id + 1) || 1;
      return {
        ...state,
        pendingLiabilitySave: true,
        liabilities: [
          ...state.liabilities,
          {
            added: true,
            id,
            ...action.payload,
          },
        ],
      };
    }
    if (action.type === DELETE_LIAB_APPLICANTS) {
      const updateLiabList = [...state.liabilities];
      remove(updateLiabList, ['id', action.payload.id]);
      return {
        ...state,
        pendingLiabilitySave: true,
        liabilities: [...updateLiabList],
      };
    }
    if (action.type === PREV_FINANCE_APPLICANTS) {
      return {
        ...state,
        initExpList: false,
        previousFinance: action.payload,
      };
    }
    if (action.type === UPDATE_LIAB_APPLICANTS) {
      const liabData = reduce(
        state.liabilities,
        (updatedLiabList, data) => {
          if (
            data.id === action.payload.id ||
            (data.defaultId && data.defaultId === action.payload.defaultId)
          ) {
            updatedLiabList.push({ ...action.payload });
          } else {
            updatedLiabList.push({ ...data });
          }

          return updatedLiabList;
        },
        [],
      );
      return {
        ...state,
        liabilities: [...liabData],
        pendingLiabilitySave: true,
      };
    }
    if (action.type === ADD_LIABID) {
      return {
        ...state,
        liabilities: state.liabilities.map((liabdata) => ({
          ...liabdata,
          id: action.payload[0].liabilityid,
        })),
      };
    }

    if (action.type === INIT_EXPENSE_APPLICANTS) {
      if (state.prepopulateExpenses || state.expenses) {
        return { ...state, initExpList: true, pendingLiabilitySave: false };
      }

      return {
        ...state,
        initExpList: true,
        pendingLiabilitySave: false,
        expenses,
      };
    }

    if (action.type === ADD_EXP_APPLICANTS) {
      const lastExpData = last(state.expenses);
      const id = (lastExpData && lastExpData.id + 1) || 1;
      return {
        ...state,
        expenseCompleted: false,
        expenses: [
          ...state.expenses,
          {
            added: true,
            id,
            ...action.payload,
          },
        ],
      };
    }
    if (action.type === DELETE_EXP_APPLICANTS) {
      const updateExpList = [...state.expenses];
      remove(updateExpList, ['id', action.payload.id]);
      return {
        ...state,
        expenseCompleted: false,
        expenses: [...updateExpList],
      };
    }
    if (action.type === UPDATE_EXP_APPLICANTS) {
      const expData = reduce(
        state.expenses,
        (updatedExpList, data) => {
          if (
            data.id === action.payload.id ||
            (data.defaultId && data.defaultId === action.payload.defaultId) ||
            data.category === action.payload.category
          ) {
            updatedExpList.push({ ...action.payload });
          } else {
            updatedExpList.push({ ...data });
          }

          return updatedExpList;
        },
        [],
      );
      return { ...state, expenses: [...expData], expenseCompleted: false };
    }

    if (action.type === ADD_EXPID) {
      return {
        ...state,
        expenses: state.expenses.map((expdata) => ({
          ...expdata,
          id: action.payload[0].expenseid,
        })),
      };
    }

    if (action.type === DELETE_ALL_EMPLOYMENTS) {
      return {
        ...state,
        currentEmpId: null,
        prevEmpId: null,
        currentEmpId2: null,
      };
    }
    if (action.type === DELETE_CURRENT_EMPLOYMENT_SECONDARY) {
      return {
        ...state,
        addEmployment: false,
        mainEmployment: null,
        currentEmpId2: null,
        employmentStatus2: null,
        employerName2: null,
        employmentStartDate2: null,
        ...(action.payload && action.payload.isDeletePrev
          ? {
              prevEmpId: null,
              prevEmpType: null,
              prevEmpStartDt: null,
              prevEmpEndDt: null,
            }
          : {}),
      };
    }
    if (action.type === DELETE_PREV_EMPLOYMENT) {
      return {
        ...state,
        prevEmpId: null,
        prevEmpType: null,
        prevEmpStartDt: null,
        prevEmpEndDt: null,
      };
    }

    if (action.type === INIT_POLICY_APPLICANTS) {
      return {
        ...state,
        expenseCompleted: true,
      };
    }
    if (action.type === FORESEE_SELECTED_APPLICANTS) {
      return {
        ...state,
        foreseeSelected: action.payload,
      };
    }
    if (action.type === TERMSNCONDITION_CHECKED_APPLICANTS) {
      return {
        ...state,
        termNconditionChk: action.payload,
      };
    }
    if (action.type === UPLOAD_IDENTITY_APPLICANTS) {
      const identityData = reduce(
        state.identitydoclist,
        (uploadedList, data) => {
          if (data.id === action.payload.id) {
            uploadedList.push({ ...action.payload });
          } else {
            uploadedList.push({ ...data });
          }

          return uploadedList;
        },
        [],
      );
      return { ...state, identitydoclist: [...identityData] };
    }
    if (action.type === UPLOAD_INCOMEDOC_APPLICANTS) {
      const incomeDocData = reduce(
        state.incomeverifydoclist,
        (uploadedList, data) => {
          if (data.id === action.payload.id) {
            uploadedList.push({ ...action.payload });
          } else {
            uploadedList.push({ ...data });
          }

          return uploadedList;
        },
        [],
      );
      return { ...state, incomeverifydoclist: [...incomeDocData] };
    }
    if (action.type === POPULATE_CONTACT_DETAILS) {
      return {
        ...state,
        title: action.personalDetails.title,
        firstName: action.personalDetails.firstName,
        lastName: action.personalDetails.lastName,
        dateOfBirth: action.personalDetails.dateOfBirth,
        email: action.personalDetails.emailAddress,
        phoneNumber: action.personalDetails.mobileNumber,
        marketingSubscription: action.personalDetails.marketingCommunicationOptIn,
        postalcode: Number(action.personalDetails.postCode),
      };
    }
    if (action.type === POPULATE_OPP_DETAILS) {
      return {
        ...state,
        subStage: action.oppDetails.subStage,
        stage: action.oppDetails.stage,
        pqsubmitToLenderDate: action.oppDetails.PQSubmitToLenderDate,
        pqcallbackRequestedDate: action.oppDetails.PQCallbackRequestedDate,
        progressiveQuoteSubmittedDate: action.oppDetails.progressiveQuoteSubmittedDate,
        progressiveQuoteSubmitted: action.oppDetails.progressiveQuoteSubmitted,
        selectedLender: action.oppDetails.selectedLender,
        isPQProductSelectionGroup: action.oppDetails.isPQProductSelectionGroup,
        leadSource: action.oppDetails.leadSource,
        applicationStructure:
          action.oppDetails.applicationStructure === APPLICATION_STRUCTURE.coApplicant
            ? APPLICANT_TYPE.coApplicant
            : action.oppDetails.applicationStructure === APPLICATION_STRUCTURE.singleApplicantSpouse
            ? APPLICANT_TYPE.spouse
            : APPLICANT_TYPE.primary,
      };
    }
    if (action.type === POPULATE_ABN_SAVED_DATA) {
      let abnObj;
      if (
        action.abnDetails?.entityName ||
        (action.abnDetails?.entityName === '' && action.abnDetails?.abn)
      ) {
        abnObj = {
          abnNumber: action.abnDetails.abn || null,
          acn: action.abnDetails.acn || null,
          abnRegistrationDate: action.abnDetails.abnRegistrationDate,
          abnState: action.abnDetails.abnState,
          abnStatus: action.abnDetails.abnStatus,
          abrEntityType: action.abnDetails.abrEntityType,
          entityType:
            action.abnDetails.entityType === 'Sole Trader'
              ? 'Individual/Sole Trader'
              : action.abnDetails.entityType,
          entityName: action.abnDetails.entityName,
          equifaxOrgId: action.abnDetails.equifaxOrgId,
          gstRegistrationdate: action.abnDetails.gstRegistrationdate,
          gstRegistrationstatus: action.abnDetails.gstRegistrationstatus,
        };
      }
      return {
        ...state,
        ...abnObj,
      };
    }
    if (action.type === POPULATE_APPLICANTS) {
      const primaryApplicantData = find(action.applicants, ['applicantType', PRIMARY_APPLICANT]);
      const spouseApplicant = find(action.applicants, ['applicantType', SPOUSE_APPLICANT]);
      const coApplicant = find(action.applicants, ['applicantType', CO_APPLICANT]);

      const primaryApplicant =
        find(action.applicants, ['Id', state.applicantId]) || primaryApplicantData;
      let addressObj = {
        postcode: primaryApplicant.postcode,
        livingDetails: false,
      };
      const employmentObj = {
        empdetailscomplete: false,
        employeeDuration: false,
      };

      const prevAddresses = [];

      if (primaryApplicant.addresses && primaryApplicant.addresses.length) {
        primaryApplicant.addresses.forEach((addr, idx) => {
          if (addr.addressStatus === 'Current' && addr.addressType === 'Residential Address') {
            addressObj.livingDetails = true;
            addressObj.isPropertyOwn = primaryApplicant.isPropertyOwner;
            addressObj = { ...addressObj, ...formatAddressObj(addr) };
            addressObj.postcode = addr.postcode || primaryApplicant.postcode;
          } else {
            prevAddresses.push(formatAddressObj(addr));
          }
        });
      }

      let coApplicantObj = {};
      const fieldSuffixCoapp = `_${APPLICANT_TYPE.coApplicant}`;
      if (coApplicant) {
        coApplicantObj = {
          [`Id${fieldSuffixCoapp}`]: coApplicant.Id,
          [`applicantType${fieldSuffixCoapp}`]: coApplicant.applicantType,
          [`firstName${fieldSuffixCoapp}`]: coApplicant.firstName,
          [`lastName${fieldSuffixCoapp}`]: coApplicant.lastName,
          [`email${fieldSuffixCoapp}`]: coApplicant.emailAddress,
          [`phoneNumber${fieldSuffixCoapp}`]: coApplicant.mobileNumber,
          [`dateOfBirth${fieldSuffixCoapp}`]: coApplicant.dateOfBirth,
          [`creditHistory${fieldSuffixCoapp}`]: coApplicant.creditHistory,
          [`privacyAcceptance${fieldSuffixCoapp}`]: coApplicant.privacyAcceptance,
          [`privacyAcceptanceDate${fieldSuffixCoapp}`]: coApplicant.privacyAcceptanceDate,
          [`driversLicenseNumber${fieldSuffixCoapp}`]: coApplicant.driversLicenseNumber,
          [`driversLicenseType${fieldSuffixCoapp}`]: coApplicant.driversLicenseType,
          [`disclosedCreditRating${fieldSuffixCoapp}`]: coApplicant.disclosedCreditRating,
          [`driversLicenseState${fieldSuffixCoapp}`]: coApplicant.driversLicenseState,
          [`internationalLicense${fieldSuffixCoapp}`]: coApplicant.internationalLicense,
          [`citizenshipStatus${fieldSuffixCoapp}`]: coApplicant.citizenshipStatus,
          [`visaExpiryDate${fieldSuffixCoapp}`]: coApplicant.visaExpiryDate,
          [`maritalStatus${fieldSuffixCoapp}`]: coApplicant.maritalStatus,
          [`numberOfDependants${fieldSuffixCoapp}`]: coApplicant.numberOfDependants,
          [`passportNumber${fieldSuffixCoapp}`]: coApplicant.passportNumber,
          [`passportCountry${fieldSuffixCoapp}`]: coApplicant.passportCountry,
          [`passportExpiry${fieldSuffixCoapp}`]: coApplicant.passportExpiry,
          coApplicantId: coApplicant?.Id,
        };
      }

      let spouseApplicantObj = {};
      const fieldSuffixSpouse = `_${APPLICANT_TYPE.spouse}`;
      if (spouseApplicant) {
        spouseApplicantObj = {
          [`Id${fieldSuffixSpouse}`]: spouseApplicant.Id,
          [`applicantType${fieldSuffixSpouse}`]: spouseApplicant.applicantType,
          [`firstName${fieldSuffixSpouse}`]: spouseApplicant.firstName,
          [`lastName${fieldSuffixSpouse}`]: spouseApplicant.lastName,
          [`email${fieldSuffixSpouse}`]: spouseApplicant.emailAddress,
          [`phoneNumber${fieldSuffixSpouse}`]: spouseApplicant.mobileNumber,
          [`dateOfBirth${fieldSuffixSpouse}`]: spouseApplicant.dateOfBirth,
          spouseApplicantId: spouseApplicant?.Id,
        };
      }
      const prevAddressesSpouse = [];
      let addressObjSpouse = {};

      if (spouseApplicant?.addresses && spouseApplicant?.addresses.length) {
        spouseApplicant.addresses.forEach((addr, idx) => {
          if (addr.addressStatus === 'Current' && addr.addressType === 'Residential Address') {
            addressObjSpouse = {
              ...addressObjSpouse,
              ...formatAddressObj(addr, `_${APPLICANT_TYPE.spouse}`),
            };
          } else {
            prevAddressesSpouse.push(formatAddressObj(addr));
          }
        });
      }

      const prevAddressesCoApplicant = [];
      let addressObjCoApplicant = {};

      if (coApplicant?.addresses && coApplicant?.addresses.length) {
        coApplicant.addresses.forEach((addr, idx) => {
          if (addr.addressStatus === 'Current' && addr.addressType === 'Residential Address') {
            addressObjCoApplicant = {
              ...addressObjCoApplicant,
              ...formatAddressObj(addr, `_${APPLICANT_TYPE.coApplicant}`),
            };
          } else {
            prevAddressesCoApplicant.push(formatAddressObj(addr));
          }
        });
      }

      if (primaryApplicant.employment && primaryApplicant.employment.length) {
        // eslint-disable-next-line max-len
        const currEmployments = _.filter(primaryApplicant.employment, ['IsCurrent', 'Current']);
        const prevEmployment = _.find(primaryApplicant.employment, ['IsCurrent', 'Previous']);
        const { purposeOfUse } = action.carDetails;

        currEmployments.forEach((currEmployment, idx) => {
          if (currEmployment) {
            employmentObj.empdetailscomplete = true;
            employmentObj.personaldetailsChanged = false;

            employmentObj[idx ? `currentEmpId${idx + 1}` : 'currentEmpId'] =
              currEmployment.id || '';
            employmentObj[idx ? `employerName${idx + 1}` : 'employerName'] =
              currEmployment.employerName || null;
            employmentObj[
              idx ? `isSavedEmployerName${idx + 1}` : 'isSavedEmployerName'
            ] = !!currEmployment.employerName;
            employmentObj[idx ? `employmentStatus${idx + 1}` : 'employmentStatus'] =
              currEmployment.EmploymentType;
            employmentObj[idx ? `employmentStartDate${idx + 1}` : 'employmentStartDate'] =
              currEmployment.employmentStartDate || null;

            if (idx === 0) {
              if (
                currEmployment.hasABN === true &&
                purposeOfUse === 'Business' &&
                currEmployment.EmploymentType !== EMPTYPE_SELF
              ) {
                employmentObj.isAbnknown = currEmployment.hasABN;
                employmentObj.searchAbn = true;
              } else if (
                currEmployment.hasABN === false &&
                currEmployment.EmploymentType !== EMPTYPE_SELF
              ) {
                employmentObj.isAbnknown = false;
              } else if (
                currEmployment.hasABN === true &&
                currEmployment.EmploymentType === EMPTYPE_SELF
              ) {
                // employmentObj.isAbnknown = currEmployment.hasABN;
                employmentObj.searchAbn = true;
              }
            } else {
              employmentObj.addEmployment = true;
              employmentObj.mainEmployment = currEmployment.mainEmployment === 'Yes' ? 'Yes' : 'No';
              if (
                currEmployment.hasABN === true &&
                currEmployment.EmploymentType === EMPTYPE_SELF
              ) {
                // employmentObj.isAbnknown = currEmployment.hasABN;
                employmentObj.searchAbn = true;
              }
            }
          }
        });

        if (prevEmployment) {
          employmentObj.employeeDuration = true;
          employmentObj.empdetailscomplete = true;
          employmentObj.personaldetailsChanged = false;
          employmentObj.prevEmpStartDt = prevEmployment.employmentStartDate || null;
          employmentObj.prevEmpEndDt = prevEmployment.employmentEndDate || null;
          employmentObj.prevEmpType = prevEmployment.EmploymentType || null;
          employmentObj.prevEmpId = prevEmployment.id || null;
        }
      }
      const spouseEmpObj = {};
      if (spouseApplicant) {
        if (spouseApplicant.employment && spouseApplicant.employment.length) {
          const spouseDetails = spouseApplicant.employment && spouseApplicant.employment[0];
          spouseEmpObj.spouseEmploymentType = spouseDetails.EmploymentType;
          spouseEmpObj.spouseEmployerName = spouseDetails.employerName;
          spouseEmpObj.spouseEmpCurrStartDt = spouseDetails.employmentStartDate;
          spouseEmpObj.SpousecurrentEmpId = spouseDetails.id;
          spouseEmpObj.spouseOccupation = spouseDetails.occupation;
        }
      }

      return {
        ...state,
        partner: spouseApplicant ? spouseApplicantObj : coApplicantObj,
        applicantType: primaryApplicant.applicantType,
        creditHistory: primaryApplicant.creditHistory,
        applicantId: primaryApplicant.Id,
        acceptPrivacyPolicy: primaryApplicant.privacyAcceptance,
        privacyAcceptanceDate: primaryApplicant.privacyAcceptanceDate,
        passportFlag: primaryApplicant.passportFlag,
        passportNumber: primaryApplicant.passportNumber,
        passportCountry: primaryApplicant.passportCountry,
        passportExpiry: primaryApplicant.passportExpiry,
        licenceNumber: primaryApplicant.driversLicenseNumber,
        driversLicencecardNumber: primaryApplicant.driversLicencecardNumber,
        licenceType: primaryApplicant.driversLicenseType,
        licenceState: primaryApplicant.driversLicenseState,
        disclosedCreditRating: primaryApplicant.disclosedCreditRating,
        dlState: primaryApplicant.driversLicenseState,
        dlflag: primaryApplicant.internationalLicense,
        citizenshipStatus: primaryApplicant.citizenshipStatus,
        visaExpiryDt: primaryApplicant.visaExpiryDate,
        applicantDetails: true,
        netMonthlySurplus: primaryApplicant.netMonthlySurplus || 0,
        netWorth: primaryApplicant.netWorth || 0,
        applicationStructure:
          primaryApplicant.applicationStructure === APPLICATION_STRUCTURE.coApplicant
            ? APPLICANT_TYPE.coApplicant
            : primaryApplicant.applicationStructure === APPLICATION_STRUCTURE.singleApplicantSpouse
            ? APPLICANT_TYPE.spouse
            : APPLICANT_TYPE.primary,
        stage: 'Quote',
        'sub Stage': 'applicants',
        addressType: 'Primary Borrower',
        relationship: primaryApplicant.maritalStatus,
        dependants:
          primaryApplicant.numberOfDependants &&
          String(Number(primaryApplicant.numberOfDependants)),
        passwordCreated: true,
        identityDetails:
          !!primaryApplicant.driversLicenseType || primaryApplicant.internationalLicense,
        isSpousePropOwner:
          primaryApplicant.isSpousePropertyOwner === 'false'
            ? false
            : primaryApplicant.isSpousePropertyOwner === 'true'
            ? true
            : null,
        foreseeSelected: primaryApplicant.foreseeSelected,
        foreseeFinancialChangesDescription: primaryApplicant.foreseeFinancialChangesDescription,
        previousFinance: primaryApplicant.previousFinance,
        termNconditionChk: primaryApplicant.termAndConditionCheck,
        dfsAcceptedDt:
          primaryApplicant.termAndConditionCheck === true ? primaryApplicant.dfsAcceptedDate : null,
        creditQuoteAccepted: primaryApplicant.creditQuoteAccepted,
        creditQuoteAcceptedDate: primaryApplicant.creditQuoteAcceptedDate,
        behalfOfCompany: primaryApplicant.behalfOfCompany,
        trusteeType: primaryApplicant.trusteeType,
        lastFYNetProfit: primaryApplicant.lastFYNetProfit,
        verifiedMonthlyExpensesincRepayments: primaryApplicant.verifiedMonthlyExpensesincRepayments,
        verifiedTotalAssets: primaryApplicant.verifiedTotalAssets,
        verifiedTotalCreditCardLiabilities: primaryApplicant.verifiedTotalCreditCardLiabilities,
        verifiedTotalCreditCardLimit: primaryApplicant.verifiedTotalCreditCardLimit,
        verifiedTotalDebtAgreementLiabilities:
          primaryApplicant.verifiedTotalDebtAgreementLiabilities,
        verifiedTotalInvestmentLiabilities: primaryApplicant.verifiedTotalInvestmentLiabilities,
        verifiedTotalLiabilities: primaryApplicant.verifiedTotalLiabilities,
        verifiedTotalMortgageHomeLiabilities: primaryApplicant.verifiedTotalMortgageHomeLiabilities,
        verifiedTotalMotorVehicleLoanLiabilities:
          primaryApplicant.verifiedTotalMotorVehicleLoanLiabilities,
        verifiedTotalNetMonthlyGovtInc: primaryApplicant.verifiedTotalNetMonthlyGovtInc,
        verifiedTotalNetMonthlyInc: primaryApplicant.verifiedTotalNetMonthlyInc,
        verifiedTotalNetMonthlyInvestmentInc: primaryApplicant.verifiedTotalNetMonthlyInvestmentInc,
        verifiedTotalNetMonthlyPAGYInc: primaryApplicant.verifiedTotalNetMonthlyPAGYInc,
        verifiedTotalNetMonthlyRentalInc: primaryApplicant.verifiedTotalNetMonthlyRentalInc,
        verifiedTotalNetMonthlySelfEmployedInc:
          primaryApplicant.verifiedTotalNetMonthlySelfEmployedInc,
        verifiedTotalNetMonthlySuperAnnualInc:
          primaryApplicant.verifiedTotalNetMonthlySuperAnnualInc,
        verifiedTotalOverdraftLiabilities: primaryApplicant.verifiedTotalOverdraftLiabilities,
        verifiedTotalPersonalLoanLiabilities: primaryApplicant.verifiedTotalPersonalLoanLiabilities,
        verifiedTotalRecreationalLiabilities: primaryApplicant.verifiedTotalRecreationalLiabilities,
        verifiedTotalTaxDebtLiabilities: primaryApplicant.verifiedTotalTaxDebtLiabilities,
        employmentDetails:
          !!primaryApplicant.numberOfDependants &&
          String(Number(!!primaryApplicant.numberOfDependants)),
        ...addressObj,
        prevAddresses,
        ...employmentObj,
        spouseCoApplicantId: spouseApplicant?.Id || coApplicant?.Id,
        ...spouseApplicantObj,
        ...spouseEmpObj,
        ...addressObjSpouse,
        [`prevAddresses${APPLICANT_TYPE.spouse}`]: prevAddressesSpouse,
        ...coApplicantObj,
        ...addressObjCoApplicant,
        [`prevAddresses${APPLICANT_TYPE.coApplicant}`]: prevAddressesCoApplicant,
        // temporary fix to be removed later
        firstName: primaryApplicant.applicantType === CO_APPLICANT ? '' : state.firstName,
        lastName: primaryApplicant.applicantType === CO_APPLICANT ? '' : state.lastName,
        progressiveQuoteSubmitted: primaryApplicant.progressiveQuoteSubmitted,
        isPQProductSelectionGroup: primaryApplicant.isPQProductSelectionGroup,
        selectedLender: primaryApplicant.selectedLender,
      };
    }
    if (action.type === SET_APPLICATION_STRUCTURE) {
      return {
        ...state,
        applicationStructure:
          action.applicationStructure === APPLICATION_STRUCTURE.coApplicant
            ? APPLICANT_TYPE.coApplicant
            : action.applicationStructure === APPLICATION_STRUCTURE.singleApplicantSpouse
            ? APPLICANT_TYPE.spouse
            : APPLICANT_TYPE.primary,
      };
    }
    if (action.type === POPULATE_FINANCES) {
      const primaryApplicantFinancesData = find(action.finances, [
        'applicantType',
        PRIMARY_APPLICANT,
      ]);
      const primaryApplicantFinances =
        find(action.finances, ['Id', state.applicantId]) || primaryApplicantFinancesData;
      const incomelistObj =
        primaryApplicantFinances.income &&
        primaryApplicantFinances.income.map((incomeData) => {
          const isVerified =
            incomeData.verifiedStatus && incomeData.verifiedStatus.toLowerCase() === 'verified';
          const payslipNotVerified =
            incomeData.verifiedStatus && incomeData.verifiedStatus.toLowerCase() === 'unverified';
          return {
            ...incomeData,
            amount:
              isVerified && incomeData.verifiedMonthlyAmount === incomeData.amount
                ? ''
                : incomeData.amount == ''
                ? payslipNotVerified && incomeData.verifiedMonthlyAmount
                  ? incomeData.verifiedMonthlyAmount
                  : incomeData.amount
                : incomeData.amount,
            category: incomeData.incomeType,
            brokerUpdated: isVerified,
            payslipNotVerified,
          };
        });
      const assetlistObj =
        primaryApplicantFinances.assets &&
        primaryApplicantFinances.assets.map((assetsData) => {
          const isVerified = assetsData.verifiedMonthlyAmount != '';
          return {
            ...assetsData,
            category: assetsData.assetType,
            estimateVal:
              isVerified && assetsData.verifiedMonthlyAmount === assetsData.value
                ? ''
                : assetsData.value,
            underfinance:
              assetsData.currentlyFinanced === true || assetsData.currentlyFinanced === 'true',
            brokerUpdated: isVerified,
            relatedLiability: assetsData.relatedLiability,
          };
        });
      const liabilitieslistObj =
        primaryApplicantFinances.liabilities &&
        primaryApplicantFinances.liabilities.map((liabilitiesData) => {
          const isVerified = liabilitiesData.verifiedOutstandingBalance != '';
          return {
            ...liabilitiesData,
            category: liabilitiesData.liabilityType,
            outstanding:
              isVerified &&
              liabilitiesData.verifiedOutstandingBalance === liabilitiesData.outstandingBalance
                ? ''
                : liabilitiesData.outstandingBalance,

            repayments: liabilitiesData.repaymentAmount,
            frequency: liabilitiesData.repaymentFrequency,
            limit: liabilitiesData.toBePaidOutLimit,
            payout: liabilitiesData.payout === 'true',
            brokerUpdated: isVerified,
            relatedAsset: liabilitiesData.relatedAsset,
          };
        });

      const expenseObj =
        primaryApplicantFinances.expenses &&
        primaryApplicantFinances.expenses.map((expensesData) => {
          const isVerified =
            (expensesData.verifiedStatus &&
              expensesData.verifiedStatus.toLowerCase() === 'verified') ||
            expensesData.verifiedMonthlyAmount !== '';

          return {
            ...expensesData,
            amount:
              isVerified && expensesData.verifiedMonthlyAmount === (expensesData.amount || 0)
                ? expensesData.amount
                : expensesData.amount || expensesData.verifiedMonthlyAmount || 0,
            category: expensesData.expenseType,
            brokerUpdated: isVerified,
            frequency: expensesData.reasonForChange ? 'Monthly' : expensesData.frequency,
          };
        });

      const defaultExpensesMerged = expenseObj?.length > 0 ? [...expenseObj] : [];
      expenses.forEach((mandatoryExpense) => {
        if (!find(expenseObj, ['category', mandatoryExpense.category])) {
          defaultExpensesMerged.push(mandatoryExpense);
        }
      });

      // Populate Spouse details
      const spouseApplicant = find(action.finances, ['applicantType', SPOUSE_APPLICANT]);
      const coApplicant = find(action.finances, ['applicantType', CO_APPLICANT]);
      const spouseCoApplicant = spouseApplicant || coApplicant;

      const spouseIncomelistObj =
        spouseCoApplicant &&
        spouseCoApplicant.income &&
        spouseCoApplicant.income.map((incomeData) => {
          const isVerified =
            incomeData.verifiedStatus && incomeData.verifiedStatus.toLowerCase() === 'verified';
          const payslipNotVerified =
            incomeData.verifiedStatus && incomeData.verifiedStatus.toLowerCase() === 'unverified';
          return {
            ...incomeData,
            amount:
              isVerified && incomeData.verifiedMonthlyAmount === incomeData.amount
                ? ''
                : incomeData.amount == '' && payslipNotVerified && incomeData.verifiedMonthlyAmount
                ? incomeData.verifiedMonthlyAmount
                : incomeData.amount,

            category: incomeData.incomeType,
            brokerUpdated: isVerified,
            payslipNotVerified,
          };
        });
      return {
        ...state,
        income: incomelistObj || [],
        prepopulateIncome: !!incomelistObj,
        spouseincome: spouseIncomelistObj || state.spouseincome || [],
        prepopulateSpouseIncome: !!spouseIncomelistObj,
        assets: assetlistObj || [],
        prepopulateAssets: !!assetlistObj,
        liabilities: liabilitieslistObj || [],
        prepopulateLiability: !!liabilitieslistObj,
        expenses: defaultExpensesMerged || state.expenses || [],
        prepopulateExpenses: !!expenseObj,
        initAssetList: !!incomelistObj.length,
        initExpList:
          (!!liabilitieslistObj.length || !!assetlistObj.length) && !!incomelistObj.length,
        initLiabList: !!assetlistObj.length && !!incomelistObj.length,
        expenseCompleted: !!expenseObj.length,
        foreseeSelected: primaryApplicantFinances.foreseeSelected,
        foreseeFinancialChangesDescription:
          primaryApplicantFinances.foreseeFinancialChangesDescription,
        termNconditionChk: primaryApplicantFinances.termAndConditionCheck,
        spouseCoApplicantId: spouseCoApplicant && spouseCoApplicant.Id,
        spouseApplicantId: spouseApplicant?.Id,
        coApplicantId: coApplicant?.Id,
        financedetailsChanged: !!primaryApplicantFinances.termAndConditionCheck,
      };
    }
    if (action.type === POPULATE_ABN_DETAILS) {
      // eslint-disable-next-line max-len
      return {
        ...state,
        [`entityName${action.fieldSuffix}`]: action.abnDetails.entityName,
        [`abnRegistrationDate${action.fieldSuffix}`]: action.abnDetails.abnRegisteredDate,
        [`abnStatus${action.fieldSuffix}`]: action.abnDetails.abnStatus,
        [`entityType${action.fieldSuffix}`]: action.abnDetails.entityType,
        [`gstRegistrationstatus${action.fieldSuffix}`]: action.abnDetails.gstStatus,
        [`acn${action.fieldSuffix}`]: action.abnDetails.acn,
        [`gstRegistrationdate${action.fieldSuffix}`]: action.abnDetails.gstRegisteredDate,
        [`abnState${action.fieldSuffix}`]: action.abnDetails.abnState,
        [`abrEntityType${action.fieldSuffix}`]: action.abnDetails.abrEntityType,
        [`equifaxOrgId${action.fieldSuffix}`]: action.abnDetails.equifaxOrgId,
        [` abnVerified${action.fieldSuffix}`]: !!action.abnDetails || false,
      };
    }

    if (action.type === RETRIVE_ADDRRESS_APPLICANTS) {
      return {
        ...state,
        country: action.payload.country,
        postalcode: action.payload.postCode,
        postcode: action.payload.postCode,
        state: action.payload.state,
        streetName: action.payload.streetName,
        buildingName: action.payload.building,
        streetNumber: action.payload.streetNumber,
        streetType: action.payload.streetType,
        suburb: action.payload.suburb,
        unitNumber: action.payload.unitNumber,
      };
    }

    if (action.type === CLEAR_ADDRRESS_APPLICANTS) {
      return {
        ...state,
        country: '',
        postalcode: '',
        postcode: '',
        state: '',
        streetName: '',
        buildingName: '',
        streetNumber: '',
        streetType: '',
        suburb: '',
        unitNumber: '',
        address: '',
        livingDetails: null,
      };
    }
    if (action.type === RESET_EMPLOYMENT_DATA) {
      return {
        ...state,
        hasABN: false,
        isAbnknown: null,
        abnNumber: null,
        abnfromDt: null,
        searchAbn: null,
        employmentType: null,
        employmentStartDate: null,
        prevEmpStartDt: null,
        employeeDuration: false,
        employmentStatus: null,
        prevEmpEndDt: null,
        AcnNumber: null,
        financialYear: null,
        behalfOfCompany: null,
        trusteeType: null,
        empDocType: null,
        imageFile: null,
        empdetailscomplete: null,
        InvalidAbn: null,
        serverError: null,
        InvalidAbnNumber: null,
        lastFYNetProfit: null,
        abnRegistrationDate: null,
        abnState: null,
        abnStatus: null,
        acn: null,
        abrEntityType: null,
        entityType: null,
        entityName: null,
        equifaxOrgId: null,
        gstRegistrationdate: null,
        gstRegistrationstatus: null,
        currentEmpId: null,
        prevEmpId: null,
        employerName: null,
        addEmployment: false,
        mainEmployment: null,
        employmentStatus2: null,
        employerName2: null,
        employmentStartDate2: null,
        prevEmpType: null,
      };
    }

    // post lenderselect query
    if (action.type === POPULATE_POSTLENDERSELECT_DETAILS) {
      const primaryApplicantData = find(action.applicants, ['applicantType', PRIMARY_APPLICANT]);
      const spouseApplicant = find(action.applicants, ['applicantType', SPOUSE_APPLICANT]);
      const coApplicant = find(action.applicants, ['applicantType', CO_APPLICANT]);

      const primaryApplicant =
        find(action.applicants, ['Id', state.applicantId]) || primaryApplicantData;
      let addressObj = {
        postcode: primaryApplicant.postcode,
        livingDetails: false,
      };
      let employeeAddressObj = {};

      const prevAddresses = [];

      if (primaryApplicant.addresses && primaryApplicant.addresses.length) {
        primaryApplicant.addresses.forEach((addr, idx) => {
          if (addr.addressStatus === 'Current' && addr.addressType === 'Residential Address') {
            addressObj.livingDetails = true;
            addressObj.isPropertyOwn = primaryApplicant.isPropertyOwner;
            addressObj = { ...addressObj, ...formatAddressObj(addr) };
            addressObj.postcode = addr.postcode || primaryApplicant.postcode;
          }
          if (addr.addressStatus === 'Previous' && addr.addressType === 'Residential Address') {
            prevAddresses.push(formatAddressObj(addr));
          }
          if (addr.addressStatus === 'Current' && addr.addressType === 'Employer Address') {
            employeeAddressObj = {
              ...employeeAddressObj,
              ...formatAddressObj(addr),
            };
          }
        });
      }

      let coApplicantObj = {};
      const fieldSuffixCoapp = `_${APPLICANT_TYPE.coApplicant}`;
      if (coApplicant) {
        coApplicantObj = {
          [`Id${fieldSuffixCoapp}`]: coApplicant.Id,
          [`applicantType${fieldSuffixCoapp}`]: coApplicant.applicantType,
          [`firstName${fieldSuffixCoapp}`]: coApplicant.firstName,
          [`lastName${fieldSuffixCoapp}`]: coApplicant.lastName,
          [`email${fieldSuffixCoapp}`]: coApplicant.emailAddress,
          [`phoneNumber${fieldSuffixCoapp}`]: coApplicant.mobileNumber,
          [`dateOfBirth${fieldSuffixCoapp}`]: coApplicant.dateOfBirth,
          [`creditHistory${fieldSuffixCoapp}`]: coApplicant.creditHistory,
          [`privacyAcceptance${fieldSuffixCoapp}`]: coApplicant.privacyAcceptance,
          [`privacyAcceptanceDate${fieldSuffixCoapp}`]: coApplicant.privacyAcceptanceDate,
          [`driversLicenseNumber${fieldSuffixCoapp}`]: coApplicant.driversLicenseNumber,
          [`driversLicenseCardNumber${fieldSuffixCoapp}`]: coApplicant.driversLicencecardNumber,
          [`driversLicenseType${fieldSuffixCoapp}`]: coApplicant.driversLicenseType,
          [`disclosedCreditRating${fieldSuffixCoapp}`]: coApplicant.disclosedCreditRating,
          [`driversLicenseState${fieldSuffixCoapp}`]: coApplicant.driversLicenseState,
          [`internationalLicense${fieldSuffixCoapp}`]: coApplicant.internationalLicense,
          [`citizenshipStatus${fieldSuffixCoapp}`]: coApplicant.citizenshipStatus,
          [`visaExpiryDate${fieldSuffixCoapp}`]: coApplicant.visaExpiryDate,
          [`maritalStatus${fieldSuffixCoapp}`]: coApplicant.maritalStatus,
          [`numberOfDependants${fieldSuffixCoapp}`]: coApplicant.numberOfDependants,
          [`passportNumber${fieldSuffixCoapp}`]: coApplicant.passportNumber,
          [`passportCountry${fieldSuffixCoapp}`]: coApplicant.passportCountry,
          [`passportExpiry${fieldSuffixCoapp}`]: coApplicant.passportExpiry,
          coApplicantId: coApplicant?.Id,
        };
      }

      let spouseApplicantObj = {};
      const fieldSuffixSpouse = `_${APPLICANT_TYPE.spouse}`;
      if (spouseApplicant) {
        spouseApplicantObj = {
          [`applicantType${fieldSuffixSpouse}`]: spouseApplicant.applicantType,
          [`firstName${fieldSuffixSpouse}`]: spouseApplicant.firstName,
          [`lastName${fieldSuffixSpouse}`]: spouseApplicant.lastName,
          [`email${fieldSuffixSpouse}`]: spouseApplicant.emailAddress,
          [`phoneNumber${fieldSuffixSpouse}`]: spouseApplicant.mobileNumber,
          [`dateOfBirth${fieldSuffixSpouse}`]: spouseApplicant.dateOfBirth,
          spouseApplicantId: spouseApplicant?.Id,
        };
      }
      let addressObjSpouse = {};
      let employeeSpouseAddressObj = {};

      if (spouseApplicant?.addresses && spouseApplicant?.addresses.length) {
        spouseApplicant.addresses.forEach((addr, idx) => {
          if (addr.addressStatus === 'Current' && addr.addressType === 'Residential Address') {
            addressObjSpouse = {
              ...addressObjSpouse,
              ...formatAddressObj(addr, `_${APPLICANT_TYPE.spouse}`),
            };
          }
          if (addr.addressStatus === 'Current' && addr.addressType === 'Employer Address') {
            employeeSpouseAddressObj = {
              ...employeeSpouseAddressObj,
              ...formatAddressObj(addr),
            };
          }
        });
      }

      let addressObjCoApplicant = {};
      let employeeCoApplicantAddressObj = {};

      if (coApplicant?.addresses && coApplicant?.addresses.length) {
        coApplicant.addresses.forEach((addr, idx) => {
          if (addr.addressStatus === 'Current' && addr.addressType === 'Residential Address') {
            addressObjCoApplicant = {
              ...addressObjCoApplicant,
              ...formatAddressObj(addr, `_${APPLICANT_TYPE.coApplicant}`),
            };
          }
          if (addr.addressStatus === 'Current' && addr.addressType === 'Employer Address') {
            employeeCoApplicantAddressObj = {
              ...employeeCoApplicantAddressObj,
              ...formatAddressObj(addr),
            };
          }
        });
      }

      const employmentObj = {
        empdetailscomplete: false,
        employeeDuration: false,
        mainEmployment: 'No',
      };

      let abnDetails = {};
      let currEmployments;
      let prevEmployments;

      if (primaryApplicant.employment && primaryApplicant.employment.length) {
        // eslint-disable-next-line max-len
        currEmployments = _.sortBy(
          _.filter(primaryApplicant.employment, ['IsCurrent', 'Current']),
          // ,
          // [(o) => o.mainEmployment !== 'Yes'],
        );

        currEmployments = _.map(currEmployments, (empData) => ({
          ...empData,
          ...employeeAddressObj,
          abnNumber: empData.abn,
          refId: empData.id,
        }));
        prevEmployments = _.filter(primaryApplicant.employment, ['IsCurrent', 'Previous']);
        prevEmployments = _.map(prevEmployments, (prevEmpData) => ({
          ...prevEmpData,
          abnNumber: prevEmpData.abn,
          refId: prevEmpData.id,
        }));

        const { purposeOfUse } = action.carDetails;
        currEmployments.forEach((currEmployment, idx) => {
          if (currEmployment) {
            employmentObj.empdetailscomplete = true;
            employmentObj.personaldetailsChanged = false;

            employmentObj[idx ? `currentEmpId${idx + 1}` : 'currentEmpId'] =
              currEmployment.id || null;
            employmentObj[idx ? `employerName${idx + 1}` : 'employerName'] =
              currEmployment.employerName || null;
            employmentObj[
              idx ? `isSavedEmployerName${idx + 1}` : 'isSavedEmployerName'
            ] = !!currEmployment.employerName;
            employmentObj[idx ? `employmentStatus${idx + 1}` : 'employmentStatus'] =
              currEmployment.EmploymentType;
            employmentObj[idx ? `employmentStartDate${idx + 1}` : 'employmentStartDate'] =
              currEmployment.employmentStartDate || null;

            if (idx === 0) {
              if (
                currEmployment.hasABN === true &&
                purposeOfUse === 'Business' &&
                currEmployment.EmploymentType !== EMPTYPE_SELF
              ) {
                employmentObj.isAbnknown = currEmployment.hasABN;
                employmentObj.searchAbn = true;
              } else if (
                currEmployment.hasABN === false &&
                currEmployment.EmploymentType !== EMPTYPE_SELF
              ) {
                employmentObj.isAbnknown = false;
              } else if (
                currEmployment.hasABN === true &&
                currEmployment.EmploymentType === EMPTYPE_SELF
              ) {
                // employmentObj.isAbnknown = currEmployment.hasABN;
                employmentObj.searchAbn = true;
              }
            } else {
              employmentObj.addEmployment = true;
              employmentObj.mainEmployment =
                currEmployment.mainEmployment === 'Yes' ? 'Yes' : employmentObj.mainEmployment;
              if (
                currEmployment.hasABN === true &&
                currEmployment.EmploymentType === EMPTYPE_SELF
              ) {
                // employmentObj.isAbnknown = currEmployment.hasABN;
                employmentObj.searchAbn = true;
              }
            }
            abnDetails = {
              abnNumber: currEmployment.abn || null,
              acn: currEmployment.acn || null,
              abnRegistrationDate: currEmployment.abnRegistrationDate || null,
              abnState: currEmployment.abnState,
              abnStatus: currEmployment.abnStatus,
              abrEntityType: currEmployment.abrEntityType,
              entityType:
                currEmployment.entityType === 'Sole Trader'
                  ? 'Individual/Sole Trader'
                  : currEmployment.entityType,
              entityName: currEmployment.entityName,
              equifaxOrgId: currEmployment.equifaxOrgId,
              gstRegistrationdate: currEmployment.gstRegistrationdate || null,
              gstRegistrationstatus: currEmployment.gstRegistrationstatus,
            };
            if (
              currEmployment?.entityName ||
              (currEmployment?.entityName === '' && currEmployment?.abn)
            ) {
              employmentObj.abnNumber = currEmployment.abn || null;
              employmentObj.acn = currEmployment.acn || null;
              employmentObj.abnRegistrationDate = currEmployment.abnRegistrationDate || null;
              employmentObj.abnState = currEmployment.abnState;
              employmentObj.abnStatus = currEmployment.abnStatus;
              employmentObj.abrEntityType = currEmployment.abrEntityType;
              employmentObj.entityType =
                currEmployment.entityType === 'Sole Trader'
                  ? 'Individual/Sole Trader'
                  : currEmployment.entityType;
              employmentObj.entityName = currEmployment.entityName;
              employmentObj.equifaxOrgId = currEmployment.equifaxOrgId;
              employmentObj.gstRegistrationdate = currEmployment.gstRegistrationdate || null;
              employmentObj.gstRegistrationstatus = currEmployment.gstRegistrationstatus;
            }
          }
        });
      }
      // spouse employment details population

      let abnDetailsSpouse = {};
      let currEmploymentsSpouse;

      if (spouseApplicant?.employment && spouseApplicant?.employment.length) {
        // eslint-disable-next-line max-len
        currEmploymentsSpouse = _.sortBy(
          _.filter(spouseApplicant.employment, ['IsCurrent', 'Current']),
          [(o) => o.mainEmployment !== 'Yes'],
        );
        currEmploymentsSpouse = _.map(currEmploymentsSpouse, (empData) => ({
          ...empData,
          ...employeeSpouseAddressObj,
          abnNumber: empData.abn,
          refId: empData.id,
        }));

        currEmploymentsSpouse.forEach((currEmployment, idx) => {
          if (currEmployment) {
            abnDetailsSpouse = {
              abnNumber: currEmployment.abn || null,
              acn: currEmployment.acn || null,
              abnRegistrationDate: currEmployment.abnRegistrationDate || null,
              abnState: currEmployment.abnState,
              abnStatus: currEmployment.abnStatus,
              abrEntityType: currEmployment.abrEntityType,
              entityType:
                currEmployment.entityType === 'Sole Trader'
                  ? 'Individual/Sole Trader'
                  : currEmployment.entityType,
              entityName: currEmployment.entityName,
              equifaxOrgId: currEmployment.equifaxOrgId,
              gstRegistrationdate: currEmployment.gstRegistrationdate || null,
              gstRegistrationstatus: currEmployment.gstRegistrationstatus,
            };
          }
        });
      }

      // coapplicant employmengt detaiks population

      let abnDetailsCoApplicant = {};
      let currEmploymentsCoApplicant;
      let prevEmploymentsCoApplicant;

      if (coApplicant?.employment && coApplicant?.employment.length) {
        // eslint-disable-next-line max-len
        currEmploymentsCoApplicant = _.sortBy(
          _.filter(coApplicant.employment, ['IsCurrent', 'Current']),
          [(o) => o.mainEmployment !== 'Yes'],
        );
        currEmploymentsCoApplicant = _.map(currEmploymentsCoApplicant, (empData) => ({
          ...empData,
          ...employeeCoApplicantAddressObj,
          abnNumber: empData.abn,
          refId: empData.id,
        }));

        prevEmploymentsCoApplicant = _.filter(coApplicant.employment, ['IsCurrent', 'Previous']);
        prevEmploymentsCoApplicant = _.map(prevEmploymentsCoApplicant, (prevEmpData) => ({
          ...prevEmpData,
          abnNumber: prevEmpData.abn,
          refId: prevEmpData.id,
        }));
        currEmploymentsCoApplicant.forEach((currEmployment, idx) => {
          if (currEmployment) {
            abnDetailsCoApplicant = {
              abnNumber: currEmployment.abn || null,
              acn: currEmployment.acn || null,
              abnRegistrationDate: currEmployment.abnRegistrationDate || null,
              abnState: currEmployment.abnState,
              abnStatus: currEmployment.abnStatus,
              abrEntityType: currEmployment.abrEntityType,
              entityType:
                currEmployment.entityType === 'Sole Trader'
                  ? 'Individual/Sole Trader'
                  : currEmployment.entityType,
              entityName: currEmployment.entityName,
              equifaxOrgId: currEmployment.equifaxOrgId,
              gstRegistrationdate: currEmployment.gstRegistrationdate || null,
              gstRegistrationstatus: currEmployment.gstRegistrationstatus,
            };
          }
        });
      }

      return {
        ...state,
        title: primaryApplicant.title,
        firstName: primaryApplicant.firstName,
        lastName: primaryApplicant.lastName,
        dateOfBirth: primaryApplicant.dateOfBirth,
        email: primaryApplicant.emailAddress,
        phoneNumber: primaryApplicant.mobileNumber,
        marketingSubscription: primaryApplicant.marketingCommunicationOptIn,
        postalcode: Number(primaryApplicant.postCode),
        applicantType: primaryApplicant.applicantType,
        creditHistory: primaryApplicant.creditHistory,
        applicantId: primaryApplicant.Id,
        acceptPrivacyPolicy: primaryApplicant.privacyAcceptance,
        privacyAcceptanceDate: primaryApplicant.privacyAcceptanceDate,
        passportFlag: primaryApplicant?.passportFlag,
        passportNumber: primaryApplicant?.passportNumber,
        passportCountry: primaryApplicant?.passportCountry,
        passportExpiry: primaryApplicant?.passportExpiry,
        licenceNumber: primaryApplicant.driversLicenseNumber,
        driversLicencecardNumber: primaryApplicant.driversLicencecardNumber,
        licenceType: primaryApplicant.driversLicenseType,
        disclosedCreditRating: primaryApplicant.disclosedCreditRating,
        dlState: primaryApplicant.driversLicenseState,
        dlflag: primaryApplicant.internationalLicense,
        citizenshipStatus: primaryApplicant.citizenshipStatus,
        visaExpiryDt: primaryApplicant.visaExpiryDate,
        applicantDetails: true,
        netMonthlySurplus: primaryApplicant.netMonthlySurplus || 0,
        netWorth: primaryApplicant.netWorth || 0,
        relationship: primaryApplicant.maritalStatus,
        dependants:
          primaryApplicant.numberOfDependants &&
          String(Number(primaryApplicant.numberOfDependants)),
        passwordCreated: true,
        identityDetails:
          !!primaryApplicant.driversLicenseType || primaryApplicant.internationalLicense,
        isSpousePropOwner:
          primaryApplicant.isSpousePropertyOwner === 'false'
            ? false
            : primaryApplicant.isSpousePropertyOwner === 'true'
            ? true
            : null,
        foreseeSelected: primaryApplicant.foreseeSelected,
        foreseeFinancialChangesDescription: primaryApplicant.foreseeFinancialChangesDescription,
        previousFinance: primaryApplicant.previousFinance,
        termNconditionChk: primaryApplicant.termAndConditionCheck,
        dfsAcceptedDt:
          primaryApplicant.termAndConditionCheck === true ? primaryApplicant.dfsAcceptedDate : null,
        creditQuoteAccepted: primaryApplicant.creditQuoteAccepted,
        creditQuoteAcceptedDate: primaryApplicant.creditQuoteAcceptedDate,
        behalfOfCompany: primaryApplicant.behalfOfCompany,
        trusteeType: primaryApplicant.trusteeType,
        lastFYNetProfit: primaryApplicant.lastFYNetProfit,
        verifiedMonthlyExpensesincRepayments: primaryApplicant.verifiedMonthlyExpensesincRepayments,
        verifiedTotalAssets: primaryApplicant.verifiedTotalAssets,
        verifiedTotalCreditCardLiabilities: primaryApplicant.verifiedTotalCreditCardLiabilities,
        verifiedTotalCreditCardLimit: primaryApplicant.verifiedTotalCreditCardLimit,
        verifiedTotalDebtAgreementLiabilities:
          primaryApplicant.verifiedTotalDebtAgreementLiabilities,
        verifiedTotalInvestmentLiabilities: primaryApplicant.verifiedTotalInvestmentLiabilities,
        verifiedTotalLiabilities: primaryApplicant.verifiedTotalLiabilities,
        verifiedTotalMortgageHomeLiabilities: primaryApplicant.verifiedTotalMortgageHomeLiabilities,
        verifiedTotalMotorVehicleLoanLiabilities:
          primaryApplicant.verifiedTotalMotorVehicleLoanLiabilities,
        verifiedTotalNetMonthlyGovtInc: primaryApplicant.verifiedTotalNetMonthlyGovtInc,
        verifiedTotalNetMonthlyInc: primaryApplicant.verifiedTotalNetMonthlyInc,
        verifiedTotalNetMonthlyInvestmentInc: primaryApplicant.verifiedTotalNetMonthlyInvestmentInc,
        verifiedTotalNetMonthlyPAGYInc: primaryApplicant.verifiedTotalNetMonthlyPAGYInc,
        verifiedTotalNetMonthlyRentalInc: primaryApplicant.verifiedTotalNetMonthlyRentalInc,
        verifiedTotalNetMonthlySelfEmployedInc:
          primaryApplicant.verifiedTotalNetMonthlySelfEmployedInc,
        verifiedTotalNetMonthlySuperAnnualInc:
          primaryApplicant.verifiedTotalNetMonthlySuperAnnualInc,
        verifiedTotalOverdraftLiabilities: primaryApplicant.verifiedTotalOverdraftLiabilities,
        verifiedTotalPersonalLoanLiabilities: primaryApplicant.verifiedTotalPersonalLoanLiabilities,
        verifiedTotalRecreationalLiabilities: primaryApplicant.verifiedTotalRecreationalLiabilities,
        verifiedTotalTaxDebtLiabilities: primaryApplicant.verifiedTotalTaxDebtLiabilities,
        employmentDetails:
          !!primaryApplicant.numberOfDependants &&
          String(Number(!!primaryApplicant.numberOfDependants)),
        ...addressObj,
        prevAddresses,
        ...employmentObj,
        // ...abnDetails,
        currEmployments,
        prevEmployments,
        spouseCoApplicantId: spouseApplicant?.Id || coApplicant?.Id,
        contactDetails:
          !!(
            coApplicant?.firstName &&
            coApplicant?.lastName &&
            coApplicant?.emailAddress &&
            coApplicant?.mobileNumber
          ) ||
          !!(
            spouseApplicant?.firstName &&
            spouseApplicant?.lastName &&
            spouseApplicant?.emailAddress &&
            spouseApplicant?.mobileNumber
          ),
        ...spouseApplicantObj,
        ...addressObjSpouse,
        ...coApplicantObj,
        ...addressObjCoApplicant,
        currEmployments_coApplicant: currEmploymentsCoApplicant,
        currEmployments_spouse: currEmploymentsSpouse,
        prevEmployments_coApplicant: prevEmploymentsCoApplicant,
        partner: spouseApplicant ? spouseApplicantObj : coApplicantObj,
      };
    }
    return super.reduce(state, action);
  }
}

export default Applicants;
