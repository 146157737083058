import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './input-field.scss';
import cls from 'classnames';

const FactoryOption = ({ fields, onSave, addFactoryOption, addSelectedItems }) => {
  const [show, setShow] = useState(false);
  const [checkedItems, updateCheckedItems] = useState(fields);

  const handleClose = () => {
    setShow(false);
    updateCheckedItems(fields);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    updateCheckedItems(fields);
  }, [fields]);

  const handleCheckboxChange = (event) => {
    updateCheckedItems(
      checkedItems.map((option) => ({
        ...option,
        selected: option.optionName === event.target.name ? event.target.checked : option.selected,
      })),
    );
  };

  const saveList = () => {
    onSave(checkedItems);
    handleClose();
  };

  return (
    <>
      <button
        type="button"
        data-test="add-factory-option"
        className={cls(
          'btn btn-link manual-enter',
          addFactoryOption && 'add-factory-option-btn',
          addSelectedItems && 'add-selected-options',
        )}
        /* "btn btn-link manual-enter" */
        onClick={handleShow}
      >
        + Factory Options
      </button>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add factory options</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(50vh - 210px)', overflowY: 'auto' }}>
          <Container>
            <Row className="show-grid">
              {checkedItems &&
                checkedItems.map((option) => (
                  <Col xs={12} sm={6} key={option.optionName}>
                    <div className="checkbox-section">
                      <label htmlFor={option.optionName} className="checkbox-container">
                        {option.optionName}
                        <input
                          type="checkbox"
                          checked={option.selected}
                          name={option.optionName}
                          value={option.optionName}
                          onChange={handleCheckboxChange}
                          id={option.optionName}
                        />
                        <span className="checkmark ml-2  mt-1 " />
                      </label>
                    </div>
                  </Col>
                ))}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button className="factory-footer" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveList}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FactoryOption;
