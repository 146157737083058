/* eslint-disable class-methods-use-this */
import BaseReducer from './BaseReducer';
import {
  SET_SIGNUP_DETAILS_ACCOUNT,
  SET_SIGNUP_ERROR_ACCOUNT,
  SET_SIGNUP_COGNITOID_ACCOUNT,
} from '../Actions/authHandler';

class Account extends BaseReducer {
  constructor() {
    super('ACCOUNT');
  }

  getInitialState() {
    return {
      initialDefaultValues: [{ loggedIn: false }],
      validationRules: [],
      activeFields: this.getInitialActiveFields(),
      sharedFields: [],
      validationErrors: [],
      isAuthenticated: false,
      loginError: false,
      userName: '',
      mobile: '',
      applicantId: '',
      signUp: false,
      cognitoId: '',
    };
  }

  getInitialActiveFields() {
    return [];
  }

  getNewActiveFields() {
    return this.getInitialActiveFields();
  }

  reduce(state = this.getInitialState(), action) {
    switch (action.type) {
      case 'LOGGED_IN':
        return {
          ...state,
          selectFreq: action.selectFreq,
        };
      case SET_SIGNUP_DETAILS_ACCOUNT:
        return {
          ...state,
          mobile: action.payload.mobile,
          applicantId: action.payload.applicantId,
          leadId: action.payload.leadId,
          isSalesforceCreatedOpportunity: action.payload.isSalesforceCreatedOpportunity,
        };
      case SET_SIGNUP_ERROR_ACCOUNT:
        return {
          ...state,
          mobile: '',
          applicantId: '',
        };
      case SET_SIGNUP_COGNITOID_ACCOUNT:
        return {
          ...state,
          signUp: true,
          cognitoId: action.id,
        };
      default:
        return super.reduce(state, action);
    }
  }
}

export default Account;
