/* eslint-disable import/prefer-default-export */
import UnionImg from '../../assets/image/Union.svg';
import DocumentImg from '../../assets/image/Document.svg';

export const DOCVERIFYLIST = [
  {
    type: 'PAYG Income',
    value: 'payg',
    options: [
      {
        name: 'Payslips',
        value: 'Payslips',
        image: UnionImg,
        documents: [
          { name: 'Payslip 1', value: 'payslip1' },
          { name: 'Payslip 2', value: 'payslip2' },
        ],
      },
      {
        name: 'Employment Contract',
        value: 'Employment Contract',
        image: DocumentImg,
        noOfDocuments: 1,
        documents: [{ value: 'employmentContract' }],
      },
    ],
  },
  {
    type: 'Self-Employed Income',
    value: 'selfEmployed',
    options: [
      {
        name: 'Notice of Assessment',
        value: 'Notice of Assessment',
        image: DocumentImg,
        documents: [{ value: 'noticeOfAssessment' }],
      },
      {
        name: 'Tax Return',
        value: 'Tax Return',
        image: DocumentImg,
        documents: [{ value: 'taxReturn' }],
      },
    ],
  },
  {
    type: 'rentalincome',
    value: 'rentalincome',
    options: [
      {
        name: 'Rent contract',
        value: 'Rent contract',
        image: DocumentImg,
        documents: [{ value: 'rentContract' }],
      },
      {
        name: 'Tax Return',
        value: 'Tax Return',
        image: DocumentImg,
        documents: [{ value: 'taxReturn' }],
      },
    ],
  },
  {
    type: 'Investments',
    value: 'investments',
    options: [
      {
        name: 'Tax Return',
        value: 'Tax Return',
        image: DocumentImg,
        documents: [{ value: 'taxReturn' }],
      },
    ],
  },
  {
    type: 'government',
    value: 'government',
    options: [
      {
        name: 'Benefit Statement',
        value: 'Benefit Statement',
        image: DocumentImg,
        documents: [{ value: 'benefitStatement' }],
      },
    ],
  },
  {
    type: 'superannuation',
    value: 'superannuation',
    options: [
      {
        name: 'Superannuation Statement',
        value: 'Superannuation Statement',
        image: DocumentImg,
        documents: [{ value: 'superStatement' }],
      },
      {
        name: 'Tax Return',
        value: 'Tax Return',
        image: DocumentImg,
        documents: [{ value: 'taxReturn' }],
      },
    ],
  },
];
