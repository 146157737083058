const apiBaseUrl = window.env.REACT_APP_API_BASEURL;

export const API_NAMES = Object.freeze({
  QUOTE: 'MyQuoteAPI',
  ADDRESS: 'AddressAPI',
  FORMATADDRESS: 'FormatAddressAPI',
  LEADCREATION: 'LeadCreation',
  DIRECT_OPP_CREATE: 'DirectOpportunityCreation',
  OPP_CREATE: 'OppCreation',
  APPLICANTS: 'Applicants',
  OPP_UPDLIVING_DET: 'AddressCreation',
  OPP_EMPLOYMENT_UPD: 'EmploymentCreation',
  OPP_ASSET_CREATE: 'AssetCreation',
  OPP_LIAB_CREATE: 'liabilityCreation',
  OPP_INCOME_CREATE: 'incomeCreation',
  OPP_EXPENSE_CREATE: 'expenseCreation',
  OPP_EXIST_DASHBOARD: 'existingOpportunity',
  UPDATE_CAR_dETAILS: 'updateCarDetails',
  UPDATE_LOAN_dETAILS: 'updateLoanDetails',
  REGOVEHICLEDETAILS: 'regoVehicleDetails',
  OPPORTUNITY_RESP: 'retrieveOpportunity',
  OPP_EXIST_STATUS: 'opportunityStatus',
  ABNLOOKUP_DETAILS: 'abnLookUp',
  GET_YEARS: 'getYears',
  GET_CARMAKES: 'vehiclemakes',
  GET_CARMODELS: 'vehiclemodels',
  GET_CARSERIES: 'vehicleSeries',
  GET_CARVARIANTS: 'vehicleVariants',
  NVIC: 'factoryOptions',
  REQUOTE: 'reQuote',
  CO_APPLICANTS: 'coapplicant',
  CO_APPLICANT_INCOME: 'coApplicantIncome',
  DELETE_GENERIC: 'deleteIncome',
  CREATE_ACCOUNT: 'createAccount',
  ACCEPT_PRIVACY: 'acceptPrivacy',
  ACCEPT_DFS: 'acceptDFS',
  ACCEPT_CREDITSCORE: 'acceptCreditScore',
  GET_ISSELECTPRODUCTGRP: 'isSelectProductEnabled',
  UPDATE_LENDERPARAMS: 'updateLenderparams',
  SELECT_PRODUCT: 'selectProduct',
  TOPNLATEST: 'topNLatest',
  UPLOAD_DOCUMENT: 'uploadDocument',
  GET_DOCUMENT_LIST: 'getDocumentList',
  GET_DOCUMENT: 'getDocument',
  VALIDATE_ENQUIRY: 'leads',
  PQ_FINAL_SUBMISSION: 'PQFinalSubmission',
  GET_ACTIVE_LEAD_RECORD: 'getActiveLeads',
  UPDATE_INSURANCE_ACCEPTANCE: 'insuranceConsent',
});

export default {
  // OPTIONAL - if your API requires authentication
  Auth: {
    mandatorySignIn: false,
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: window.env.REACT_APP_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: 'ap-southeast-2',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: window.env.REACT_APP_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: window.env.REACT_APP_USER_POOL_CLIENT_ID,
    authenticationFlowType: 'CUSTOM_AUTH',
  },
  API: {
    endpoints: [
      {
        name: API_NAMES.QUOTE,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.UPDATE_CAR_dETAILS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.ADDRESS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.FORMATADDRESS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.LEADCREATION,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.DIRECT_OPP_CREATE,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.OPP_CREATE,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.APPLICANTS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.OPP_UPDLIVING_DET,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.OPP_EMPLOYMENT_UPD,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.OPP_ASSET_CREATE,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.OPP_LIAB_CREATE,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.OPP_INCOME_CREATE,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.OPP_EXPENSE_CREATE,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.OPP_EXIST_DASHBOARD,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.REGOVEHICLEDETAILS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.OPPORTUNITY_RESP,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.ABNLOOKUP_DETAILS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.GET_YEARS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.GET_CARMAKES,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.GET_CARMODELS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.GET_CARSERIES,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.GET_CARVARIANTS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.NVIC,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.REQUOTE,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.UPDATE_LOAN_dETAILS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.OPP_EXIST_STATUS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.CO_APPLICANTS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.CO_APPLICANT_INCOME,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.DELETE_GENERIC,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.CREATE_ACCOUNT,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.ACCEPT_PRIVACY,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.ACCEPT_DFS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.ACCEPT_CREDITSCORE,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.GET_ISSELECTPRODUCTGRP,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.UPDATE_LENDERPARAMS,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.SELECT_PRODUCT,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.TOPNLATEST,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.UPLOAD_DOCUMENT,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.GET_DOCUMENT_LIST,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.GET_DOCUMENT,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.VALIDATE_ENQUIRY,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.PQ_FINAL_SUBMISSION,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.GET_ACTIVE_LEAD_RECORD,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
      {
        name: API_NAMES.UPDATE_INSURANCE_ACCEPTANCE,
        endpoint: apiBaseUrl,
        region: 'ap-southeast-2',
      },
    ],
  },
};
