import { connect } from 'react-redux';
import PersonalDetailsContainer from './PersonalDetailsContainer';
import fieldChanged, { liteFieldChanged, updateValidationErrors } from '../../Actions/FieldChanged';
import { nextClicked } from '../../Actions/NextClicked';
import { scrollToNextClicked } from '../../Actions/ScrollToNextClicked';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import { setCurrentTab, setCompleteTab } from '../../Actions/tab';
import {
  leadCreation,
  createOpportunity,
  applicants,
  livingDetailsUpdate,
  carDetails,
  reQuote,
  clearAddress,
  loanDetails,
  RetrieveOpportunity,
} from '../../Actions/car';
import {
  employmentInsUpd,
  deleteCurrEmploymentSecondary,
  deleteAllEmployments,
  deleteEmployerIncome,
} from '../../Actions/applicants';
import { setAuthenticated, setAuthenticationFailure } from '../../Actions/authHandler';

import { validationErrors, noErrors, handleHTTPError } from '../../Actions/errorHandler';

const mapStateToProps = (state) => ({
  title: state.applicants.title,
  firstName: state.applicants.firstName,
  lastName: state.applicants.lastName,
  email: state.applicants.email,
  phoneNumber: state.applicants.phoneNumber,
  dateOfBirth: state.applicants.dateOfBirth,
  applicantDetails: state.applicants.applicantDetails,
  licenceType: state.applicants.licenceType,
  licenceNumber: state.applicants.licenceNumber,
  driversLicencecardNumber: state.applicants.driversLicencecardNumber,
  dlState: state.applicants.dlState,
  dlflag: state.applicants.dlflag,
  nonResident: state.applicants.nonResident,
  manualEnter: state.applicants.manualEnter,
  address: state.applicants.address,
  searchAddress: state.applicants.searchAddress,
  unitNumber: state.applicants.unitNumber,
  street: state.applicants.street,
  streetName: state.applicants.streetName,
  suburb: state.applicants.suburb,
  postcode: state.applicants.postcode,
  postalcode: state.applicants.postalcode,
  state: state.applicants.state,
  duration: state.applicants.duration,
  citizenshipStatus: state.applicants.citizenshipStatus,
  livingDetails: state.applicants.livingDetails,
  relationship: state.applicants.relationship,
  livingArrangement: state.applicants.livingArrangement,
  dependants: state.applicants.dependants,
  employmentDetails: state.applicants.employmentDetails,
  employmentStatus: state.applicants.employmentStatus,
  employmentType: state.applicants.employmentType,
  employmentStartDate: state.applicants.employmentStartDate,
  prevEmpType: state.applicants.prevEmpType,
  prevEmpEndDt: state.applicants.prevEmpEndDt,
  prevEmpStartDt: state.applicants.prevEmpStartDt,
  creditHistory: state.applicants.creditHistory,
  residentialStatus: state.applicants.residentialStatus,
  creditInfo: state.applicants.creditInfo,
  submittedMobileOtp: state.applicants.submittedMobileOtp,
  isAbnknown: state.applicants.isAbnknown,
  abnfromDt: state.applicants.abnfromDt,
  AcnNumber: state.applicants.AcnNumber,
  searchAbn: state.applicants.searchAbn,
  currentTab: state.applicants.tabName,
  privacy: state.applicants.privacy,
  submitsuccess: state.applicants.submitsuccess,
  identityDetails: state.applicants.identityDetails,
  editPhoneNumberFlag: state.applicants.editPhoneNumberFlag,
  backToVerification: state.applicants.backToVerification,
  creditcomplete: state.applicants.creditcomplete,
  livingArrangeComplete: state.applicants.livingArrangeComplete,
  personalDetailsComplete: state.applicants.personalDetailsComplete,
  marketingSubscription: state.applicants.marketingSubscription,
  validationErrors: state.applicants.validationErrors,
  code: state.applicants.code,
  password: state.applicants.password,
  emailStatus: state.applicants.emailStatus,
  phoneNumberStatus: state.applicants.phoneNumberStatus,
  editEmailFlag: state.applicants.editEmailFlag,
  verificationComplete: state.applicants.verificationComplete,
  creditinfo: state.applicants.creditinfo,
  empdetailscomplete: state.applicants.empdetailscomplete,
  employeeDuration: state.applicants.employeeDuration,
  purchaseUse: state.car.purchaseUse,
  imageFile: state.applicants.imageFile,
  empDocType: state.applicants.empDocType,
  lastFYNetProfit: state.applicants.lastFYNetProfit,
  netProfitYear: state.applicants.netProfitYear,
  passwordCreated: state.applicants.passwordCreated,
  confirmationCode: state.applicants.confirmationCode,
  cognitoUserStatus: state.applicants.cognitoUserStatus,
  isAuthenticated: state.account.isAuthenticated,
  forceNewApplication: state.account.forceNewApplication,
  isExistingUser: state.applicants.isExistingUser,
  visaExpiryDt: state.applicants.visaExpiryDt,
  error: state.applicants.error,
  buildingName: state.applicants.buildingName,
  abnNumber: state.applicants.abnNumber,
  abnNumberPrevious: state.applicants.abnNumberPrevious,
  entityName: state.applicants.entityName,
  entityNamePrevious: state.applicants.entityNamePrevious,
  abnStatus: state.applicants.abnStatus,
  abnStatusPrevious: state.applicants.abnStatusPrevious,
  entityType: state.applicants.entityType,
  entityTypePrevious: state.applicants.entityTypePrevious,
  gstRegistrationdate: state.applicants.gstRegistrationdate,
  gstRegistrationstatus: state.applicants.gstRegistrationstatus,
  gstRegistrationstatusPrevious: state.applicants.gstRegistrationstatusPrevious,
  abnRegistrationDate: state.applicants.abnRegistrationDate,
  income: state.applicants.income,
  isPropertyOwn: state.applicants.isPropertyOwn,
  cognitoUserVerify: state.applicants.cognitoUserVerify,
  personalDetailsContextpage: state.applicants.personalDetailsContextpage,
  behalfOfCompany: state.applicants.behalfOfCompany,
  trusteeType: state.applicants.trusteeType,
  quoteInProgress: state.account.quoteInProgress,
  abnSearchInProgress: state.applicants.abnSearchInProgress,
  prevEmpId: state.applicants.prevEmpId,
  currentEmpId: state.applicants.currentEmpId,
  serverError: state.applicants.serverError,
  employerName: state.applicants.employerName,
  isSavedEmployerName: state.applicants.isSavedEmployerName,
  cognitoUserVerifyStatus: state.applicants.cognitoUserVerifyStatus,
  employmentStatus2: state.applicants.employmentStatus2,
  employerName2: state.applicants.employerName2,
  isSavedEmployerName2: state.applicants.isSavedEmployerName2,
  employmentStartDate2: state.applicants.employmentStartDate2,
  employmentType2: state.applicants.employmentType2,
  addEmployment: state.applicants.addEmployment,
  mainEmployment: state.applicants.mainEmployment,
  currentEmpId2: state.applicants.currentEmpId2,
  leadCreationInProgress: state.applicants.leadCreationInProgress,
  identityDetailsInProgress: state.applicants.identityDetailsInProgress,
  livingArrangementInProgress: state.applicants.livingArrangementInProgress,
  employmentCreationInProgress: state.applicants.employmentCreationInProgress,
  livingDetailsInProgress: state.applicants.livingDetailsInProgress,
  disclosedCreditRating: state.applicants.disclosedCreditRating,
  mobileVerficationPage: state.applicants.mobileVerficationPage,
  opportunityId: state.applicants.opportunityId,
  contactPhoneNumber: state.applicants.contactPhoneNumber,
  passportFlag: state.applicants.passportFlag,
  passportNumber: state.applicants.passportNumber,
  passportCountry: state.applicants.passportCountry,
  passportExpiry: state.applicants.passportExpiry,
  getValidationErrorsForField: getValidationErrorsForFieldFunc(state.applicants.validationErrors),
  dateOfBirth: state.applicants.dateOfBirth,
  assetType: state.car.assetType,
});

const mapDispatchToProps = (dispatch) => ({
  nextButtonClick: () => {
    dispatch(nextClicked('applicants'));
  },
  onInputChange: (fieldName, value, scrollToNextFieldOnChage, ignoreHeaderOffset) => {
    dispatch(liteFieldChanged('applicants', 'personaldetailsChanged', true, false));
    return dispatch(
      fieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage, ignoreHeaderOffset),
    );
  },
  validateDateField: (fieldName, errors) =>
    dispatch(updateValidationErrors('APPLICANTS', fieldName, errors)),
  onLiteInputChange: (fieldName, value, scrollToNextFieldOnChage) =>
    dispatch(liteFieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage)),
  onScrollToNextClick: (
    fieldName,
    fieldsToValidate,
    ignoreHeaderOffset,
    ignoreParentOffset,
    changeUrl,
  ) => {
    dispatch(
      scrollToNextClicked(
        'applicants',
        fieldName,
        fieldsToValidate,
        ignoreHeaderOffset,
        ignoreParentOffset,
        changeUrl,
      ),
    );
  },
  setTab: () => dispatch(setCurrentTab('applicants')),
  applicants: () => dispatch(applicants()),
  carDetails: () => dispatch(carDetails()),

  setTabComplete: (tabName) => {
    dispatch(setCompleteTab(tabName));
  },
  leadCreation: () => dispatch(leadCreation()),
  createOpportunity: () => dispatch(createOpportunity()),
  loanDetails: () => dispatch(loanDetails()),
  livingDetailsUpdate: (isPostlenderSelect) => dispatch(livingDetailsUpdate(isPostlenderSelect)),
  clearAddress: () => dispatch(clearAddress()),
  validationCheck: (error) => dispatch(validationErrors(error)),
  employmentInsUpd: () => dispatch(employmentInsUpd()),
  noErrors: () => dispatch(noErrors()),
  handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
  setAuthenticationSuccess: (userName) => dispatch(setAuthenticated(userName)),
  reQuote: () => dispatch(reQuote()),
  deleteCurrEmploymentSecondary: () => dispatch(deleteCurrEmploymentSecondary()),
  deleteAllEmployments: () => dispatch(deleteAllEmployments()),
  deleteEmployerIncome: (employerName, selfEmployed) =>
    dispatch(deleteEmployerIncome(employerName, selfEmployed)),
  retrieveOpportunity: () => dispatch(RetrieveOpportunity()),
});

const PersonalDetails = connect(mapStateToProps, mapDispatchToProps)(PersonalDetailsContainer);

export default PersonalDetails;
