import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import logout from '../../../../assets/image/External-Link.svg';
import { ROUTES } from '../../../util/constants';
import { SideBarWrapper, headerUseStyles } from '../Header';
import { useTheme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import fieldChanged from '../../../Actions/FieldChanged';
import dashboard from '../../../../assets/image/Dashboard.svg';
import './style.scss';

const UserDropDown = ({
  fullName,
  CognitouserName,
  isAuthenticated,
  setAuthenticationlogOut,
  clearAllData,
  errorMessage,
  showErrorModal,
  currentTab,
  isPQProductSelectionGroup,
}) => {
  const theme = useTheme();
  const enableDrawer = useMediaQuery(theme.breakpoints.down(768));
  const history = useHistory();
  const [showDrawer, toggleDrawer] = useState(false);
  const classes = headerUseStyles();
  const location = useLocation();

  const handleToggleDrawer = () => {
    toggleDrawer(enableDrawer ? !showDrawer : false);
  };
  let initials;
  let formatteduserName;
  if (fullName) {
    const nameparts = fullName.split(' ');
    initials = nameparts[0].charAt(0).toUpperCase() + nameparts[1].charAt(0).toUpperCase();
    formatteduserName = fullName
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }
  const showDashboard = () => {
    history.push(ROUTES.dashboard);
  };
  async function handleLogout() {
    await Auth.signOut();
    clearAllData();
    setAuthenticationlogOut().then(() => {
      history.push(ROUTES.login);
    });
  }
  return (
    <Navbar.Collapse className="justify-content-end show">
      <NavDropdown
        className="header-dropdown"
        onClick={handleToggleDrawer}
        title={
          <>
            {fullName && fullName !== null ? (
              <>
                <span className="content-initial">{initials}</span>{' '}
                <span className="content-username">{formatteduserName}</span>
              </>
            ) : (
              <>
                <AccountCircleIcon className="content-initial account-avatar" />
                <span className="content-username no-name">{CognitouserName}</span>
              </>
            )}
          </>
        }
        id="basic-nav-dropdown"
      >
        <SideBarWrapper
          enableDrawer={enableDrawer}
          showDrawer={showDrawer}
          closeDrawer={() => {
            toggleDrawer(false);
          }}
          title={
            <>
              {fullName && fullName !== null ? (
                <>
                  <span className="content-initial ">{initials}</span>
                  <span className="content-username">{formatteduserName}</span>
                </>
              ) : (
                <>
                  <AccountCircleIcon className="content-initial account-avatar" color="inherit" />
                  <span className="content-username no-name">{CognitouserName}</span>
                </>
              )}
            </>
          }
        >
          {location.pathname !== ROUTES.dashboard && (
            <NavDropdown.Item className="dashboard-item py-3" onClick={showDashboard}>
              <img src={dashboard} alt="dashboard" className="dashboard-img" />
              <span className="content-Dashboard px-3">Dashboard</span>
            </NavDropdown.Item>
          )}
          <NavDropdown.Item className="content-item py-3" onClick={handleLogout}>
            <img src={logout} alt="logout" className="content-editimage" />
            <span className="content-edit px-3">Logout</span>
          </NavDropdown.Item>
        </SideBarWrapper>
      </NavDropdown>
    </Navbar.Collapse>
  );
};

export default connect(
  (state) => ({
    fullName:
      state.applicants.firstName && `${state.applicants.firstName} ${state.applicants.lastName}`,
    isAuthenticated: state.account.isAuthenticated,
    CognitouserName: state.account.userName,
    showErrorModal: state.errorHandler.showErrorModal,
    errorMessage: state.errorHandler.errorMessage,
  }),
  (dispatch) => ({
    setAuthenticationlogOut: () =>
      dispatch(fieldChanged('account', 'isAuthenticated', false, false)),
  }),
)(UserDropDown);
