/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Error from '../../../assets/image/error.svg';
import Check from '../../../assets/image/success.svg';
import '../PersonalDetails/PersonalDetails.scss';
import ModalWindow from '../../components/ModalWindow';
import { updateCoginito } from '../../Actions/car';
import fieldChanged from '../../Actions/FieldChanged';
import {
  createOpportunity,
  getOpportunityDataForCarsales,
  validateIfLeadExist,
  fetchOpportunity,
} from '../../Actions/car';
import { ROUTES } from '../../util/constants';

const VerifyAccountCarsales = ({
  onInputChange,
  submittedMobileOtp,
  token,
  error,
  createOpportunityAPI,
  getOpportunityData,
  leadId,
  cognitoUserVerify,
  obtainLeadRecAPI,
  checkIfCognitoExist,
  isSalesforceCreatedOpportunity,
  updateCoginito,
}) => {
  const [mobileOTP, setmobileOtp] = useState('');
  const [OTPInvalid, isOTPInvalid] = useState(false);
  const [sessionTimeOut, setSessionTimeout] = useState(false);
  const [show, setShow] = useState(false);
  const [resendMessage, setResendMessage] = useState(false);
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const history = useHistory();
 
  const RedirectLoginpage = () => {
    history.push(ROUTES.login);
  };

  const createOpportunityBasedonLeadStatus = () => {
    onInputChange('car', 'cognitoUserVerify', true, false);
    obtainLeadRecAPI()
      .then((response) => {
        createOpportunityAPI()
          .then(() => {
            getOpportunityData()
              .then((oppData) => {
                onInputChange('car', 'cognitoUserVerify', false, false);
                history.push(ROUTES.car);
              })
              .catch((oppdataerr) => {
                history.push(ROUTES.dashboard);
              });
          })
          .catch((err) => {
            history.push(ROUTES.dashboard);
          });
      })
      .catch((err) => {
        history.push(ROUTES.dashboard);
      });
  };

  const handleConfirmationSubmit = async (newVal) => {
    setmobileOtp(newVal);

    if (newVal && newVal.length === 6) {
      onInputChange('car', 'submittedMobileOtp', newVal, false);
      onInputChange('car', 'cognitoUserVerify', true, false);
      try {
        axios({
          method: 'post',
          url: window.env.REACT_APP_CARSALES_AUTH_TOKEN_URL,
          data: {
            SessionId: token,
            OneTimePassword: newVal,
            LeadId: leadId,
          },
        })
          .then((response) => {
            Auth.federatedSignIn(
              `cognito-idp.ap-southeast-2.amazonaws.com/${window.env.REACT_APP_USER_POOL_ID}`, // domain name needs change
              {
                token: response.data.AuthenticationResult.IdToken,
              },
              'upper@example.com', // get userid required field
            ).then((cred) => {
              // If success, you will get the AWS credentials

              Auth.currentAuthenticatedUser()
                .then((user) => {
                  //update cognitoId for SF opps
                  if (isSalesforceCreatedOpportunity) {
                    updateCoginito(user.id)
                      .then(() => {
                        history.push(ROUTES.dashboard);
                      })
                      .catch((err) => {
                        console.error(err);
                        history.push(ROUTES.dashboard);
                      });
                  } else {
                    onInputChange('car', 'carSalesOppCreated', true, false);
                    onInputChange('account', 'isCarsalesAccount', true, true);
                    checkIfCognitoExist()
                      .then((response) => {
                        createOpportunityBasedonLeadStatus();
                      })
                      .catch((err) => {
                        createOpportunityBasedonLeadStatus();
                      });
                  }
                })
                .catch((user) => {
                  console.log(user);
                });
            });
          })
          .catch(() => {
            setmobileOtp('');
            isOTPInvalid(true);
            setShow(true);
            onInputChange('car', 'cognitoUserVerify', false, false);
            return false;
          });
      } catch (err) {
        setmobileOtp('');
        setSessionTimeout(true);
        setShow(true);
        onInputChange('car', 'cognitoUserVerify', false, false);
      }
    }
  };

  const handleClose = () => {
    isOTPInvalid(false);
    setResendMessage(false);
    setShow(false);
    setSessionTimeout(false);
  };

  const resendConfirmationCode = async () => {
  onInputChange('applicants', 'tokenId', '', false);
    onInputChange('car', 'submittedMobileOtp', null, true);
    axios({
      method: 'post',
      url: window.env.REACT_APP_CARSALES_AUTHCHALLENGE_URL,
      data: {
        leadid: leadId,
      },
    })
      .then((response) => {
        onInputChange('applicants', 'tokenId', response.data.SessionId, false);
        setmobileOtp('');
        setResendMessage(true);
        setShow(true);
        setisButtonDisabled(true);
        setSeconds(30);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (isButtonDisabled) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          setisButtonDisabled(false);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  return (
    <>
      {OTPInvalid && (
        <ModalWindow
          message="The Verification code that was entered is either expired or incorrect. Please try again"
          handleClose={handleClose}
          errorImg={Error}
          showModal={show}
        />
      )}

      {resendMessage && (
        <ModalWindow
          message="We have resent 6-digit verification code to your mobile"
          handleClose={handleClose}
          errorImg={Check}
          showModal={show}
        />
      )}
      {sessionTimeOut && (
        <ModalWindow
          message="Session timed out. Resend OTP"
          handleClose={handleClose}
          errorImg={Check}
          showModal={show}
        />
      )}
      <Card className="content-body personal-details-container verifyyourself-body-section">
        <Card.Header className="col-12 page-header-container personal-details-header-container ">
          <h1 className="personal-details-header">Mobile verification</h1>
          <div className="personal-details-description">
            Protecting your information is our number one priority
          </div>
        </Card.Header>
        <Card.Body className="pb-0 applicant-body-container">
          <div className="row  applicant-body-section1">
            <div className="col-12  p-0 applicant-body-section2">
              <div className="verifyyourself-body-container">
                <div className="details-container-wrapper ">
                  <div className="details-container-verify">
                    <div className="verifyyourself-content1 ">
                      We&apos;re sending you an SMS with a 6 digit code to your mobile
                    </div>
                  </div>
                </div>

                {mobileOTP.length !== 6 && !cognitoUserVerify && (
                  <>
                    <div className="verifyyourself-otp">
                      <OtpInput
                        onChange={handleConfirmationSubmit}
                        inputStyle="otp-input-field"
                        value={mobileOTP}
                        shouldAutoFocus
                        hasErrored
                        numInputs="6"
                        errorStyle="error"
                      />
                    </div>
                    <div className="otp-info-container mb-2">
                      <div className="verifyyourself-content6">
                        Didn’t receive code?
                        <button
                          type="button"
                          data-test="resend code"
                          className="btn btn-link"
                          onClick={resendConfirmationCode}
                          disabled={isButtonDisabled}
                        >
                          <span className="verifyyourself-content8">Resend OTP</span>
                        </button>
                        &nbsp;
                        <span>
                          {seconds === 0 ? null : (
                            <span>
                              <b>00: {seconds < 10 ? `0${seconds}` : seconds}</b>
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                {submittedMobileOtp && submittedMobileOtp.length === 6 && (
                  <div className="otp-verify-container">
                    {cognitoUserVerify && (
                      <CircularProgress size={60} thickness={4} className="otp-progress" />
                    )}
                    {cognitoUserVerify === false && error === '' && (
                      <>
                        <div xs={12} className="verified-icon-container">
                          <span className="success-icon" />
                        </div>
                        <div className="verified">Verified.</div>
                      </>
                    )}
                    <div className="error-message p-0">
                      {error && (
                        <div className="invalid-feedback">
                          {error}

                          <button
                            type="button"
                            onClick={RedirectLoginpage}
                            className="btn btn-link account-exist-login"
                          >
                            Login?
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default connect(
  (state) => ({
    submittedMobileOtp: state.car.submittedMobileOtp,
    token: state.applicants.tokenId || state.car.tokenId,
    leadId: state.car.carSalesLead || state.account.leadId || state.applicants.leadId,
    cognitoUserVerify: state.car.cognitoUserVerify,
    isSalesforceCreatedOpportunity: state.account.isSalesforceCreatedOpportunity,
    error: state.account.error,
    assetType: state.car.assetType,
  }),
  (dispatch) => ({
    onInputChange: (type, fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged(type, fieldName, value, scrollToNextFieldOnChage));
    },
    createOpportunityAPI: () => dispatch(createOpportunity()),
    getOpportunityData: () => dispatch(getOpportunityDataForCarsales()),
    obtainLeadRecAPI: () => dispatch(validateIfLeadExist()),
    checkIfCognitoExist: (cognitoId) => dispatch(fetchOpportunity(cognitoId)),
    updateCoginito: (cognitoId) => dispatch(updateCoginito(cognitoId)),
  }),
)(VerifyAccountCarsales);
