import React, { Fragment } from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import { size } from 'lodash';
import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  borderStyling,
  primaryTextColor,
  secondaryTextColor,
  greyTextColor,
} from '../../../styles/constant';
import CustomDropZoneField from '../FileUpload/CustomDropzoneField';
import {
  documentFieldMap,
  uploadFileSize,
  uploadedVerifiedStatus,
  allowedFileTypes,
  DOCUMENT_TYPE,
} from '../../util/constants';
import { getFileFromBase64 } from '../../util/helpers';
import { getDocument, getDocumentList, uploadDocument } from '../../Actions/finances';
import '../../containers/UploadDocumentContainer/upload-document.scss';

export const styleContainer = {
  padding: '48px 24px',
  paddingTop: '24px',
  borderBottom: borderStyling,
};
export const styleTitle = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  color: secondaryTextColor,
};

export const styleUploadText = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 600,
  color: primaryTextColor,
  marginTop: '18px',
};
export const styleUploadSubText = {
  fontSize: '15px',
  lineHeight: '22.5px',
  fontWeight: 500,
  color: greyTextColor,
  marginTop: '18px',
};

const IdentityDocUpload = ({
  uploadDocument,
  getDocumentList,
  getDocument,
  loading,
  setLoading,
  dlflag,
  imageFiles,
  setImage,
  imageError,
  setImageError,
  documentList,
  setDocumentList,
  selectedLender,
  isPartner = false,
  additionalDoclistIdentity,
  applicantType,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(960));
  additionalDoclistIdentity = sortBy(additionalDoclistIdentity, ['order']);
  const additionalDocListFlag = size(additionalDoclistIdentity) > 0 ? true : false;

  const onClickDownloadFile = (fileInfo) => {
    const fileDesc = fileInfo[0];
    // convert base 64 string into file

    // call api to get document details and base64 string
    getDocument(fileDesc.documentId).then((res) => {
      const base64String = res.data;
      const file = getFileFromBase64(base64String, fileDesc.name, fileDesc.fileExtension);

      // after converting . download the file to user
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(file);
      a.download = `${fileDesc.name}.${
        fileDesc.fileExtension === 'jpeg' || fileDesc.fileExtension === 'jpg'
          ? 'jpg'
          : fileDesc.fileExtension === 'png'
          ? 'png'
          : fileDesc.fileExtension === 'heic'
          ? 'heic'
          : 'pdf'
      }`;
      a.click();
    });
  };

  const handleTransformDownloadListToimageFiles = (allDocList, isPartner = false) => {
    //basically just transform it on how it is being used for the dropzone components
    // const newImageFiles = { ...(isPartner ? imageFilesPartner : imageFiles) };
    const uploadedDocList = filter(allDocList, (doc) =>
      uploadedVerifiedStatus.includes(doc?.status),
    );

    const newImageFiles = {};

    uploadedDocList.forEach((fileObj) => {
      const indexFile = fileObj.fileName.lastIndexOf('.');
      const fileExtension = fileObj.fileName.substring(indexFile + 1);
      const name = fileObj.fileName.substring(0, indexFile);
      let documentType = fileObj.documentType;

      const formattedData = {
        path: fileObj.fileName,
        fileExtension,
        documentId: fileObj.documentId,
        linkedEntityRelatedIncExId: fileObj.linkedEntityRelatedIncExId,
        name,
        documentType,
        status: fileObj.status,
      };
      if (newImageFiles?.[documentType]) {
        newImageFiles[documentType].push({ ...formattedData });
      } else {
        newImageFiles[documentType] = [{ ...formattedData }];
      }
    });
    setImage(newImageFiles);
  };

  const removeDataType = (fileType, base64String) => {
    if (fileType === 'jpeg' || fileType === 'jpg') {
      return base64String.replace('data:image/jpeg;base64,', '');
    } else if (fileType === 'png') {
      return base64String.replace('data:image/png;base64,', '');
    } else if (fileType === 'heic') {
      return base64String.replace(/^data:application\/[^;]+;base64,/, '');
    } else {
      return base64String.replace('data:application/pdf;base64,', '');
    }
  };

  const handleOnDrop = (
    category,
    isOther = false,
    isPartner = false,
    idx = 0,
    incomeId = '',
  ) => async (newimagefile = []) => {
    const categoryDocList = imageFiles?.[category];

    let updatedList = [...(categoryDocList || []), ...newimagefile];
    setLoading({ category, isPartner, idx });
    if (categoryDocList && categoryDocList.length > idx) {
      updatedList = [...categoryDocList];
      updatedList[idx] = { ...categoryDocList[idx], ...newimagefile, status: 'Uploaded' };
    }

    let fileName = '';
    let base64String = '';
    let approvedFileType = allowedFileTypes.find((e) => e === newimagefile[0]?.type);

    //convert file to base64
    if (newimagefile.length > 0) {
      // Select the very first file from list
      let fileToLoad = newimagefile[0];

      if (fileToLoad.size < uploadFileSize && approvedFileType !== undefined) {
        setImageError({});
        setImage({ ...imageFiles, [category]: updatedList });
        fileName = fileToLoad.name;
        // FileReader function for read the file.
        let fileReader = new FileReader();

        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);

        base64String = await resolvebase64(fileReader);

        let fileTypeExtension = newimagefile[0].type.replace(/(.*)\//g, '');

        //PDF
        const payload = {
          fileName: fileToLoad.name,
          documentType:
            category === documentFieldMap.payslip1 || category === documentFieldMap.payslip2
              ? DOCUMENT_TYPE.PAYSLIP
              : documentFieldMap.category || category,

          documentContents: removeDataType(fileTypeExtension, base64String),
        };

        //sups changes
        const doclistByType = filter(documentList, ['documentType', category]);

        payload['documentId'] =
          doclistByType?.[idx]?.status === 'Requested' ||
          doclistByType?.[idx]?.status === 'Required'
            ? doclistByType?.[idx]?.contentDocumentId || ''
            : '';
        payload['linkedEntityRelatedIncExId'] = incomeId;

        //API call
        uploadDocument(payload, isPartner).then(() => {
          getDocumentList(isPartner).then((res) => {
            const { data } = res;
            const updatedDocList = remapDocType(data);
            setDocumentList(updatedDocList);
            handleTransformDownloadListToimageFiles(updatedDocList, isPartner);
            setLoading(false);
          });
        });
      } else if (fileToLoad.size > uploadFileSize) {
        setLoading(false);
        const newImageErrorFiles = {};
        const formattedData = {
          fileName: fileToLoad.name,
          linkedEntityRelatedIncExId: incomeId,
          category,
          error: 'Document size should be less than 8MB',
        };
        newImageErrorFiles[category] = [{ ...formattedData }];
        setImageError(newImageErrorFiles);
        return;
      } else if (approvedFileType === undefined) {
        setLoading(false);
        const newImageErrorFiles = {};
        const formattedData = {
          fileName: fileToLoad.name,
          linkedEntityRelatedIncExId: incomeId,
          category,
          error: 'Incorrect file format',
        };
        newImageErrorFiles[category] = [{ ...formattedData }];
        setImageError(newImageErrorFiles);
        return;
      } else {
        return;
      }
    }
  };
  const handleDeleteFile = (category) => (file) => {
    const updatedList = [...(imageFiles?.[category] || [])];
    remove(updatedList, { path: file.path, name: file.name });
    setImage({ ...imageFiles, [category]: updatedList });
  };
  const remapDocType = (docList) => {
    let payslipCounter = 0;
    let currentLinkedEntityRelatedIncExId = '';
    const arrayDocList = sortBy(docList, ['uploadedDate']);
    const updatedList = sortBy(arrayDocList, ['linkedEntityRelatedIncExId'])?.map((fileObj) => {
      let documentType = fileObj.documentType;
      if (fileObj.documentType === DOCUMENT_TYPE.PAYSLIP) {
        documentType = fileObj.documentSubtype;
        payslipCounter++;
        currentLinkedEntityRelatedIncExId = fileObj.linkedEntityRelatedIncExId;
      }
      return { ...fileObj, documentType };
    });
    return updatedList;
  };
  function resolvebase64(fileReader) {
    return new Promise((resolve) => {
      // Onload of file read the file content
      fileReader.onload = function(fileLoadedEvent) {
        resolve(fileLoadedEvent.target.result); // your base64 string
      };
    });
  }
  return (
    <>
      {additionalDocListFlag && (
        <Box sx={styleContainer}>
          <Box sx={styleTitle}>IDENTITY</Box>
          <div className="additional-document-list-wrapper">
            {additionalDoclistIdentity.map((item) => (
              <Fragment key={item.documentId}>
                {item.documentType === documentFieldMap.driverLicenseFront && (
                  <Grid item xs={12}>
                    <Box sx={styleUploadText}>{item.PQ_Main_Header_Text}</Box>
                  </Grid>
                )}

                <Grid item xs={mobileView ? 12 : 6}>
                  <Box sx={styleUploadText}>{item.PQ_Header_Text}</Box>
                  <Box sx={styleUploadSubText}>{item.PQ_Field_Text}</Box>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={11}>
                        <CustomDropZoneField
                          handleOnDrop={handleOnDrop(item.documentType, false, isPartner)}
                          handleOnClick={onClickDownloadFile}
                          handleDeleteFile={handleDeleteFile(item.documentType, false, isPartner)}
                          imagefile={imageFiles?.[item.documentType]}
                          disabled={imageFiles?.[item.documentType]?.length}
                          error={get(imageError, `${item.documentType}[0].error`, false)}
                          loading={
                            loading.category === item.documentType &&
                            loading.isPartner === isPartner
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Fragment>
            ))}
          </div>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedLender: state.applicants.selectedLender,
  };
};

const mapDispatchToProps = (dispatch) => ({
  uploadDocument: (doc, isPartner) => dispatch(uploadDocument(doc, isPartner)),
  getDocumentList: (isPartner) => dispatch(getDocumentList(isPartner)),
  getDocument: (documentId) => dispatch(getDocument(documentId)),
});

IdentityDocUpload.propTypes = {
  imageFiles: PropTypes.object,
  loading: PropTypes.bool,
  isPartner: PropTypes.bool,
  imageError: PropTypes.object,
  documentList: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentityDocUpload);
