/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cls from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { APPLICANT_TYPE, ROUTES } from '../../util/constants';
import './side-bar.scss';
import Progress from '../Progress';

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        // height: '100%',
        padding: theme.spacing(0, 0, 0, 2),
      },
      title: {
        display: 'inline',
        fontWeight: '400',
        flex: '1',
        color: '#66696C',
        fontFamily: 'Poppins-Regular',
        fontSize: '12px',
        letterSpacing: 0,
        lineHeight: '18px',
      },
      subTitle: {
        color: '#25282A',
        fontSize: '14px',
        letterSpacing: 0,
        lineHeight: '21px',
        fontWeight: 600,
      },
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2, -2),
        backgroundColor: '#fff',
        padding: '6px 18px 6px 12px',
        boxShadow: '0 -1px 17px 0 #e5e7e7',

        [theme.breakpoints.down(1099)]: {
          marginTop: 0,
          marginBottom: 10,
        },

        [theme.breakpoints.down(600)]: {
          position: 'sticky',
          top: '64px',
          zIndex: 150,
        },
      },
      closeButton: {
        position: 'relative',
        left: 'calc(100% - 48px)',
        width: '46px',
      },
      closeIcon: {
        width: 'auto',
        height: '1.5em',
        color: '#330072',
      },
      menuicon: {
        marginRight: '0',
        color: '#25282A',
      },
      stepIconWrapper: {
        width: '30px !important',
        height: '30px !important',
      },
      stepIconNum: {
        top: '0 !important',
      },
    }),
  { name: 'Sidebar-Wrapper' },
);

const SideBarWrapper = ({
  children,
  stepNumber,
  currentStep,
  currentSubStep,
  pathName,
  hideSidebar,
  customSidebar,
  wrapperClass,
  applicantType,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isCompareLoanOptionsScreen = location.pathname === ROUTES.compareLoanOptions;

  const theme = useTheme();
  const queryKey = isCompareLoanOptionsScreen ? '(max-width:1099px)' : theme.breakpoints.down('sm');

  const mobileView = useMediaQuery(theme.breakpoints.down(960));
  const showDrawer = useMediaQuery(queryKey);

  const [show, toggleDrawer] = useState(false);

  // Hide on path change as path change is triggered on selection of path in modal
  useEffect(() => {
    toggleDrawer(false);
  }, [pathName, showDrawer]);

  if (showDrawer) {
    return (
      <>
        {mobileView && (
          <div
            role="button"
            onClick={() => {
              toggleDrawer(!show);
            }}
            className={cls(classes.wrapper, 'navigation-stepper', 'step-header')}
          >
            <div className="step-header-text-container">
              <div className="numberCircle">{stepNumber}</div>
              <div className="step-header-text">
                <div className="step-header-text-sub"> {currentStep && currentStep.label}</div>
                <div className="step-header-text-main">
                  {' '}
                  {currentSubStep && currentSubStep.label}
                </div>
                {applicantType && (
                  <>
                    {' '}
                    {applicantType !== APPLICANT_TYPE.primary ? (
                      <>
                        {applicantType === APPLICANT_TYPE.coApplicant ? (
                          <div className="step-header-text-main">(Co-Applicant)</div>
                        ) : (
                          <div className="step-header-text-main">(Spouse)</div>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </div>
            {currentSubStep?.completed && (
              <div className="step-header-container">
                {' '}
                {applicantType === APPLICANT_TYPE.coApplicant ? (
                  <Progress
                    completed={currentSubStep?.completed}
                    pending={currentSubStep?.pending}
                  />
                ) : (
                  <Progress
                    completed={currentSubStep?.completed}
                    pending={currentSubStep?.pending}
                  />
                )}
              </div>
            )}
          </div>
        )}

        <Drawer
          anchor="top"
          open={show}
          classes={{ paper: classes.root }}
          onClose={() => {
            toggleDrawer(!show);
          }}
        >
          <IconButton
            color="primary"
            aria-label="close"
            component="span"
            onClick={() => {
              toggleDrawer(false);
            }}
            classes={{ root: classes.closeButton }}
          >
            <CancelIcon classes={{ root: classes.closeIcon }} />
          </IconButton>
          <div
            className={cls(
              hideSidebar && 'side-bar-wrapper-hide',
              !customSidebar && 'sidebar',
              'side-bar-wrapper',
              wrapperClass,
            )}
          >
            {children}
          </div>
        </Drawer>
      </>
    );
  }

  return (
    <div
      className={cls(
        (isCompareLoanOptionsScreen || hideSidebar) && 'side-bar-wrapper-hide',
        !customSidebar && 'py-4 sidebar sticky-top',
        'side-bar-wrapper',
        wrapperClass,
      )}
    >
      {children}
    </div>
  );
};

export default SideBarWrapper;
