import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const Copyright = ({ mobileFooter }) => (
  <div className={cls('stratton-footer-message', mobileFooter && 'mobile-screen')}>
    <div className="copyright pt-3 pb-2">© Stratton All rights reserved</div>

    <div className="footer-copyright">
      <div className="copyright-licence">Australian Credit licence No.364340 &nbsp;</div>
      <div className="copyright">
        <a className="copyright-privacy" href="https://www.strattonfinance.com.au/privacy-policy">
          Privacy Policy &nbsp;
        </a>

        <a
          className="copyright-privacy"
          href="https://www.strattonfinance.com.au/about-stratton/website-terms-copyright/"
        >
          Terms of use
        </a>
      </div>
    </div>
  </div>
);

Copyright.defaultProps = {
  mobileFooter: false,
};

Copyright.propTypes = {
  mobileFooter: PropTypes.bool,
};

export default Copyright;
