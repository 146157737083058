import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cls from 'classnames';
import './PartnerHeader.scss';
import { ROUTES, PARTNER_IMAGE_LIST, NEW_PARTNER_IMAGE_LIST } from '../../util/constants';
import { useLocation } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { noErrors, handleHTTPError } from '../../Actions/errorHandler';
import { connect } from 'react-redux';
import { findPartnerandDescription } from '../../util/helpers';

const PartnerHeader = ({ assetType, partnername, isAuthenticated }) => {
  const location = useLocation();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(960));

  const findPartnerIcon = (partnername) => {
    const foundPartnerIcon = PARTNER_IMAGE_LIST.find(
      (e) => e.id.toLowerCase() === partnername?.toLowerCase(),
    );
    if (foundPartnerIcon) {
      return foundPartnerIcon.value;
    }
    return null;
  };

  const headerflagdetails = () => {
    if (
      (location.pathname === ROUTES.initialDetails ||
        location.pathname === ROUTES.boat ||
        location.pathname === ROUTES.bike ||
        location.pathname === ROUTES.caravan ||
        location.pathname === ROUTES.commercialAssetType ||
        location.pathname === ROUTES.unsecuredloan) &&
      !isAuthenticated
    ) {
      return true;
    } else {
      return false;
    }
  };

  const partnerflag = findPartnerIcon(partnername) === null ? true : false;
  const newpartnerflag = findPartnerandDescription(partnername) === null ? true : false;

  const classname = !partnerflag || !newpartnerflag ? 'partnerhead' : 'partnerheader';
  const imgSrc = !partnerflag
    ? findPartnerIcon(partnername)
    : findPartnerandDescription(partnername)?.imageSrc;
  return (
    <Container className={[!headerflagdetails() ? 'estimation-route-header' : '', classname]}>
      {/* // DESKTOP */}
      {!mobileView ? (
        <Row>
          <Col className="partner-logo" xs={2}>
            {imgSrc && (
              <img
                src={imgSrc}
                alt="partner-logo"
                className="partner-logo-img"
                width="150px"
                height="67px"
              />
            )}
          </Col>
          <Col className="estimateRepayments-message" xs={10}>
            <Row className="estimateRepayments-title">Estimate My Repayments</Row>
            <Row className="estimateRepayments-subtitle">
              It takes less than 2 minutes, we just need some details about the{' '}
              {assetType?.toLowerCase()} you’re looking to buy.
            </Row>
            {!newpartnerflag && (
              <Row className="estimateRepayments-subtitle">
                {findPartnerandDescription(partnername)?.headerText}
              </Row>
            )}
          </Col>
        </Row>
      ) : (
        <div className="partner-header pl-3">
          <Row className="partner-logo">
            {imgSrc && (
              <img
                src={imgSrc}
                alt="partner-logo"
                className="partner-logo"
                width="120px"
                height="40px"
              />
            )}
          </Row>
          <Row className="estimateRepayments-title header-font">Estimate My Repayments</Row>

          {!newpartnerflag && (
            <Row className="estimateRepayments-subtitle subtitle-font py-2">
              It takes less than 2 minutes, we just need some details about the{' '}
              {assetType?.toLowerCase()} you’re looking to buy.
            </Row>
          )}

          {!newpartnerflag && (
            <Row className="estimateRepayments-subtitle subtitle-font py-2">
              {findPartnerandDescription(partnername)?.headerText}
            </Row>
          )}
        </div>
      )}
    </Container>
  );
};
export default connect(
  (state) => ({
    assetType: state.car.assetType,
    partnername: state.car.referralChannelSource,
    isAuthenticated: state.account.isAuthenticated,
  }),
  (dispatch) => ({
    noErrors: () => dispatch(noErrors()),
    handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
  }),
)(PartnerHeader);
