/* eslint-disable import/prefer-default-export */
import Moment from 'moment';
import find from 'lodash/find';

import {
  LIABILITY_MORTGAGE_OPTION,
  LIABILITY_MORTGAGE_INVEST_OPTION,
  LIABILITY_ASSET_LOAN_OPTION,
  LIABILITY_VEHICAL_LOAN_OPTION,
  LIABILITY_CREDIT_CARD_OPTION,
  LIABILITY_DEBT_AGREEMENT_OPTION,
  LIABILITY_PERSONAL_LOAN_OPTION,
  LIABILITY_TAX_DEBT_OPTION,
  LIABILITY_OVERDRAFT_OPTION,
  HOME_PROPERTY,
  INVESTMENT_PROPERTY,
  HOME_OWNER,
  MARRIED,
} from '../util/constants';

export const isLessThanTwo = (date) => {
  if (!date) return false;
  const today = new Moment();
  const momentCurrDate = new Moment(date || null);
  return today.diff(momentCurrDate, 'years') >= 0 && today.diff(momentCurrDate, 'years') < 2;
};

export const previousForLiability = (liabilities = []) =>
  !!find(
    liabilities,
    (liability) =>
      liability.category === LIABILITY_MORTGAGE_OPTION ||
      liability.category === LIABILITY_MORTGAGE_INVEST_OPTION ||
      liability.category === LIABILITY_ASSET_LOAN_OPTION ||
      liability.category === LIABILITY_VEHICAL_LOAN_OPTION ||
      liability.category === LIABILITY_PERSONAL_LOAN_OPTION,
  );

export const showAskPreviousLoan = (liabilities = []) => {
  if (
    liabilities.length === 0 ||
    (!previousForLiability(liabilities) &&
      find(
        liabilities,
        (liability) =>
          liability.category === LIABILITY_CREDIT_CARD_OPTION ||
          liability.category === LIABILITY_DEBT_AGREEMENT_OPTION ||
          liability.category === LIABILITY_TAX_DEBT_OPTION ||
          liability.category === LIABILITY_OVERDRAFT_OPTION,
      ))
  ) {
    return true;
  }

  return false;
};

export const showAskPartnerPropDetails = (residentialStatus, relationshipStatus, assets) => {
  const assetDetails = find(
    assets,
    (assetData) =>
      assetData.assetType === HOME_PROPERTY ||
      assetData.assetType === INVESTMENT_PROPERTY ||
      assetData.category === HOME_PROPERTY ||
      assetData.category === INVESTMENT_PROPERTY,
  );
  if (relationshipStatus === MARRIED && residentialStatus !== HOME_OWNER && !assetDetails) {
    return true;
  }
  return false;
};

export const capitaliseFirstletter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
export const convertToLowercase = (string) => string.toLowerCase();
