// reducer
import { RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM } from '../Actions/applicants';
import {
  SET_STEPPER_DETAILS,
  REMOVE_STEPPER_DETAILS,
  RESET_ALL_STEPPER,
} from '../Actions/stepperDetails';

const initialState = {};

function stepperDetails(state = initialState, action) {
  switch (action.type) {
    case SET_STEPPER_DETAILS:
      return { ...state, [action.stepperName]: true };
    case REMOVE_STEPPER_DETAILS:
      return { ...state, [action.stepperName]: false };
    case RESET_ALL_STEPPER:
    case RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM:
      return { ...initialState };
    default:
      return state;
  }
}

export default stepperDetails;
