export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_COMPLETE_TAB = 'SET_COMPLETE_TAB';
export const SET_INCOMPLETE_TAB = 'SET_INCOMPLETE_TAB';
export const RESET_ALL_TAB = 'RESET_ALL_TAB';

export function setCurrentTab(tabName) {
  return { type: SET_CURRENT_TAB, tabName };
}

export function setCompleteTab(tabName) {
  return { type: SET_COMPLETE_TAB, tabName };
}

export function setIncompleteTab(tabName) {
  return { type: SET_INCOMPLETE_TAB, tabName };
}

export const resetAllTabs = (clearCompleteData) => (dispatch) => {
  dispatch({ type: RESET_ALL_TAB });
  dispatch({ type: 'RESET_ALL_DATA_CAR' });
  dispatch({ type: 'RESET_ALL_DATA_APPLICANTS' });
  dispatch({ type: 'RESET_ALL_DATA_ESTIMATION' });

  if (clearCompleteData) {
    dispatch({ type: 'RESET_ALL_DATA_ACCOUNT' });
  }
};
