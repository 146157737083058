import { connect } from 'react-redux';
import { change } from 'redux-form';
import { RetrieveOpportunity, reQuote } from '../../../../Actions/car';
import {
  addExpense,
  updateExpense,
  deleteExpense,
  completeExpenseDetails,
  expenses,
  deleteGeneric,
  saveExpenses,
} from '../../../../Actions/finances';
import { scrollToNextClicked } from '../../../../Actions/ScrollToNextClicked';
import { validationErrors, noErrors } from '../../../../Actions/errorHandler';
import scrollToNextField from '../../../../util/ScrollToNextField';
import ExpenseDetailsNew from './ExpenseDetailsNew';

const mapStateToProps = (state) => ({
  expenses: state.applicants.expenses || [],
  expenseDetails: state.applicants.expenseDetails,
  expenseCompleted: state.applicants.expenseCompleted,
  liabilities: state.applicants.liabilities || [],
  residentialStatus: state.applicants.residentialStatus,
  errorMessage: state.errorHandler.errorMessage,
  ValidationErrorFlag:
    state.errorHandler.ValidationErrorFlag && state.errorHandler.errorCategory === 'EXPENSES',
});

const mapDispatchToProps = (dispatch) => ({
  validationCheck: (error) => {
    dispatch(validationErrors(error, 'EXPENSES'));
    scrollToNextField('expense-details', true, true);
  },
  noErrors: () => dispatch(noErrors()),
  addExpense: (expenseData) => {
    dispatch(addExpense(expenseData));
  },
  updateExpense: (expenseData) => {
    dispatch(updateExpense(expenseData));
  },
  deleteExpense: (expenseData) => {
    dispatch(deleteExpense(expenseData));
  },
  completeExpenseDetails: () => {
    dispatch(completeExpenseDetails());
    dispatch(scrollToNextClicked('finance_details', 'policy-confirmation', [], false, false, true));
  },
  expenseApi: (expenseData) => dispatch(expenses(expenseData)),
  saveExpensesApi: (residentialStatus) => dispatch(saveExpenses(residentialStatus)),
  retrieveOpportunity: () => dispatch(RetrieveOpportunity()),
  reQuote: () => dispatch(reQuote()),
  change: (formName, field, val) => dispatch(change(formName, field, val)),
  deleteExpApi: (expenseData) => dispatch(deleteGeneric(expenseData)),
});

const ExpenseFinanceDetailsNew = connect(mapStateToProps, mapDispatchToProps)(ExpenseDetailsNew);

export default ExpenseFinanceDetailsNew;
