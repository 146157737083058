/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { useStore } from 'react-redux';
import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Alert from 'react-bootstrap/Alert';
import IncomeForm from './IncomeForm';
import IncomeTableDetails from './IncomeTableDetails';
import SpouseIncomeForm from './SpouseIncomeForm';
import rightnormal from '../../../../assets/image/right-normal.svg';
import { WEEK_OPTION } from '../../../util/constants';
import processGTMevent from '../../../util/processGTMEvent';
import { validateIncomeErrors } from '../../../util/validateIncomeErrors';
import './incomedetails.scss';

const ACTION_NEW = 'NEW';
export const PAY_G = 'PAYG Income';
export const SELF_EMPLOYED = 'Self-Employed Income';
export const RENTAL_INCOME = 'rentalincome';

export const commonIncomeTypes = [
  { name: 'Rental Income', value: RENTAL_INCOME },
  { name: 'Government benefits', value: 'government' },
  { name: 'Investments', value: 'Investments' },
  { name: 'Superannuation', value: 'superannuation' },
  { name: 'PAYG (After Tax)', value: PAY_G },
  { name: 'Self Employed (NET)', value: SELF_EMPLOYED },
];
export const DOCTYPE = [
  { name: 'Bank Statement (PDF)', value: 'Bank Statement (PDF)' },
  { name: 'Payslip', value: 'Payslip' },
  { name: 'Tax Return', value: 'Tax Return' },
  { name: 'Notice of Assessment', value: 'Notice of Assessment' },
  { name: 'Rental Statement', value: 'Rental Statement' },
];

export const IncomeTypes = [...commonIncomeTypes];

export const SpouseIncomeTypes = [...commonIncomeTypes];

const tabTypes = Object.freeze({
  primary: 'PRIMARY',
  spouse: 'SPOUSE',
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      textTransform: 'none',
      borderTop: '1px solid #dee2e6',
      paddingLeft: 24,
    },
    appbarRoot: {
      color: '#25282a',
      fontFamily: 'Poppins-SemiBold',
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    tabRoot: {
      '&:active': {
        outline: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    tabWrapper: {
      textTransform: 'none',
      fontFamily: 'Poppins-SemiBold',
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: '16px',
      color: '#25282a',
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',

      '& > button': {
        marginLeft: 8,
        marginBottom: '0 !important',
      },
    },
    tabsIndicator: {
      borderColor: '#00aabb',
      backgroundColor: '#00aabb',
      height: '4px',
    },
    tab: {
      '& .MuiBox-root': {
        padding: '0px',
      },
    },
  }),
);

const IncomeDetails = ({
  income,
  addIncome,
  updateIncome,
  deleteIncome,
  spouseincome,
  addSpouseIncome,
  updateSpouseIncome,
  deleteSpouseIncome,
  setTab,
  pendingIncomeSave,
  initAssetList,
  completeIncomeDetails,
  initialiseIncomeDetails,
  validationCheck,
  employmentStatus,
  ValidationErrorFlag,
  errorMessage,
  noErrors,
  incomeApi,
  deleteIncomeApi,
  coApplicantdeleteApi,
  reQuote,
  financeValidationerrors,
  employerNameOption1,
  currentEmpId,
  employerNameOption2,
  currentEmpId2,
  spouseCoApplicantId,
  purchaseUse,
}) => {
  const [showForm, updateShowForm] = useState(false);
  const classes = useStyles();
  const [currentTab, setCurrentTab] = React.useState(tabTypes.primary);
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const store = useStore();

  useEffect(() => {
    setTab();
    processGTMevent('finances', 'pageLoad', 'financesPageLoad');
  }, [setTab]);

  let incometotal = 0;
  incometotal = reduce(
    income,
    (calTotal, incomeData) => calTotal + Number(incomeData.amount),
    incometotal,
  );

  const handleAddIncome = () => {
    updateShowForm(ACTION_NEW);
  };

  const employerNameOptions = [];
  if (employerNameOption1) {
    employerNameOptions.push({
      name: employerNameOption1,
      value: currentEmpId,
    });
  }

  if (employerNameOption2) {
    employerNameOptions.push({
      name: employerNameOption2,
      value: currentEmpId2,
    });
  }
  const editIncome = (incomeData) => () => {
    updateShowForm({ ...incomeData });
  };

  const handleDeleteIncome = (incomeData) => () => {
    if (currentTab === tabTypes.primary) {
      deleteIncomeApi({ ...incomeData })
        .then(() => {
          deleteIncome({ ...incomeData });
        })
        .catch(() => {
          console.log('error');
        });
    } else {
      coApplicantdeleteApi({ ...incomeData })
        .then(() => {
          deleteSpouseIncome({ ...incomeData });
        })
        .catch(() => {
          console.log('error');
        });
    }
  };

  const handleCancel = () => {
    updateShowForm('');
  };

  const handleUpdate = (incomeData) => {
    noErrors();
    if (showForm === ACTION_NEW) {
      if (currentTab === tabTypes.primary) {
        addIncome(incomeData);
      } else {
        addSpouseIncome(incomeData);
      }
    } else if (currentTab === tabTypes.primary) {
      updateIncome(incomeData);
    } else {
      updateSpouseIncome(incomeData);
    }
    handleCancel();
  };
  useEffect(() => {
    if (!spouseincome) {
      initialiseIncomeDetails();
    }
    window.location.hash = '#income-details';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteFile = (incomeData) => {
    handleUpdate(incomeData);
    updateShowForm({ ...incomeData });
  };

  const handleIncomeNext = () => {
    noErrors();
    reQuote();
    const ValidateIncomeData = validateIncomeErrors({ store });
    if (ValidateIncomeData !== null) {
      financeValidationerrors(ValidateIncomeData);
    } else {
      financeValidationerrors('');
      noErrors();
      completeIncomeDetails();
    }
    processGTMevent('finances', 'totalincome', { value: incometotal });
  };

  const primaryIncomeTypes = [...IncomeTypes];

  return (
    <Card className="content-body finances-details-container finances-details income-details">
      <Card.Header className="col-12 page-header-container finances-details-header-container scrollable-anchor-tag with-sidebar">
        <a name="finance-details" href="#income-details">
          &nbsp;
        </a>
        <h1 className="finances-details-header"> Your income details</h1>
        <div className="finances-details-description">
          We need your income details so we can calculate how much you can borrow
        </div>
        <div className={classes.root}>
          <AppBar position="static" classes={{ root: classes.appbarRoot }}>
            {/* {spouseCoApplicantId && ( */}
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="simple tabs example"
              classes={{ indicator: classes.tabsIndicator }}
            >
              <Tab
                label="Primary applicant"
                classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }}
                value={tabTypes.primary}
                {...a11yProps(tabTypes.primary)}
              />
              {purchaseUse !== 'Business' && (
                <Tab
                  wrapped
                  label="Spouse details"
                  classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper }}
                  value={tabTypes.spouse}
                  {...a11yProps(tabTypes.spouse)}
                />
              )}
            </Tabs>
            {/* )} */}
          </AppBar>
        </div>
      </Card.Header>
      <TabPanel value={currentTab} index={tabTypes.primary} classes={{ root: classes.tab }}>
        {income.length === 0 && errorMessage && (
          <div id="errormessage">
            <Alert variant="danger" className="error-alert mx-2">
              <p className="error-message">{errorMessage}</p>
            </Alert>
          </div>
        )}
        <Card.Body className="px-0 py-0 finances-body-container">
          {income.length === 0 && (
            <div className="netincome">
              <div className="netincome-label">
                <button type="button" className="btn btn-add-income" onClick={handleAddIncome}>
                  + Add Income
                </button>
              </div>
            </div>
          )}

          {income.length !== 0 && (
            <IncomeTableDetails
              incomeData={income}
              onEdit={editIncome}
              onDelete={handleDeleteIncome}
              onAddIncome={handleAddIncome}
              spouseCoApplicantId={spouseCoApplicantId}
              incomeTypesList={primaryIncomeTypes}
              validationError={errorMessage}
              ValidationErrorFlag={ValidationErrorFlag}
              employerNameOptions={employerNameOptions}
            />
          )}
        </Card.Body>
      </TabPanel>
      {purchaseUse !== 'Business' && (
        <TabPanel value={currentTab} index={tabTypes.spouse} classes={{ root: classes.tab }}>
          {spouseincome.length === 0 && errorMessage && (
            <div id="errormessage">
              <Alert variant="danger" className="error-alert mx-2">
                <p className="error-message">{errorMessage}</p>
              </Alert>
            </div>
          )}
          <Card.Body className="px-0 py-0 finances-body-container">
            {spouseincome.length === 0 && (
              <div className="netincome">
                <div className="netincome-label">
                  <button type="button" className="btn btn-add-income" onClick={handleAddIncome}>
                    + Add income
                  </button>
                </div>
              </div>
            )}
            {spouseincome.length !== 0 && (
              <IncomeTableDetails
                incomeData={spouseincome}
                onEdit={editIncome}
                onAddIncome={handleAddIncome}
                onDelete={handleDeleteIncome}
                validationError={errorMessage}
                ValidationErrorFlag={ValidationErrorFlag}
                incomeTypesList={SpouseIncomeTypes}
              />
            )}
          </Card.Body>
        </TabPanel>
      )}

      {!!showForm &&
        (currentTab === tabTypes.primary ? (
          <IncomeForm
            incomeData={income}
            incomeTypesList={primaryIncomeTypes}
            isAdd={showForm === ACTION_NEW}
            initialValues={
              showForm === ACTION_NEW
                ? { frequency: WEEK_OPTION, category: '', amount: '' }
                : showForm
            }
            incomeDetails={showForm === ACTION_NEW ? '' : showForm}
            onUpdateIncome={handleUpdate}
            onCancel={handleCancel}
            onDelete={handleDeleteFile}
          />
        ) : (
          <SpouseIncomeForm
            list={spouseincome}
            incomeTypesList={SpouseIncomeTypes}
            isAdd={showForm === ACTION_NEW}
            initialValues={
              showForm === ACTION_NEW
                ? { frequency: WEEK_OPTION, category: '', amount: '' }
                : showForm
            }
            spouseincomeDetails={showForm === ACTION_NEW ? '' : showForm}
            onUpdateSpouseIncome={handleUpdate}
            onCancel={handleCancel}
            onDelete={handleDeleteFile}
          />
        ))}
      <div className="container-footer py-3 px-3  border-top">
        <div className="col-12 d-flex justify-content-end px-0">
          <button
            type="button"
            className="btn btn-primary btn-next-page income-next-section"
            disabled={incometotal <= 0}
            hidden={initAssetList && !pendingIncomeSave}
            onClick={handleIncomeNext}
          >
            Next
            <span className="px-3">
              <img src={rightnormal} alt="right" className="right-icon" />
            </span>
          </button>
          <input
            type="button"
            id="btnsubmit"
            className="btn-dummy-page"
            style={{ visibility: 'hidden' }}
          />
        </div>
      </div>
    </Card>
  );
};

export default IncomeDetails;
