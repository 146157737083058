// reducer
import { RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM } from '../Actions/applicants';
import { POPULATE_CARMAKE, POPULATE_CARMODEL } from '../Actions/car';
import { getAllMakes, getModelsForMake } from '../lib/vehicleHelpers';

const initialState = {
  makes: [],
  models: [],
};

const formatData = (data) => {
  const returnVal = data.map((make) => ({
    name: make.T || make,
    value: make.V || make,
  }));
  returnVal.push({ name: 'Other', value: 'Other' });
  return returnVal;
};

function car(state = initialState, action) {
  switch (action.type) {
    case POPULATE_CARMAKE:
      return { ...state, makes: formatData(getAllMakes()) };
    case POPULATE_CARMODEL:
      return { ...state, models: formatData(getModelsForMake(action.vehicleMake)) };
    case RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM:
      return { ...initialState };
    default:
      return state;
  }
}
export default car;
