/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import React, { useState } from 'react';
import cls from 'classnames';
import Eye from '../../../assets/image/Eye.svg';
import Tooltip from '../Tooltip';

import './style.scss';

const renderField = ({
  input,
  isPassword,
  name,
  label,
  type,
  placeholder,
  required,
  PrependComponent,
  marginFlag,
  image,
  meta: { touched, error },
}) => {
  const [showPassword, togglePassword] = useState(false);

  return (
    <Form.Group
      controlId={name}
      isInvalid={touched && error}
      required={required}
      className="redux-render-field"
    >
      <Form.Label className="finances-form-label">
        {label}{' '}
        {label && label === 'Payout' ? (
          <Tooltip message="If this car is going to be used as a trade in for this applicants." />
        ) : null}
      </Form.Label>

      {isPassword ? (
        <InputGroup className={cls('mb-3', touched && error && 'is-invalid')}>
          {PrependComponent && <PrependComponent />}
          <Form.Control
            {...input}
            className="redux-input mr-0"
            name={name}
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            isInvalid={touched && error}
            required={required}
          />
          <InputGroup.Append
            className="redux-input-append"
            onClick={() => {
              togglePassword(!showPassword);
            }}
          >
            <img src={Eye} alt="password icon" className="px-3" />
          </InputGroup.Append>
        </InputGroup>
      ) : (
        <InputGroup className={cls(touched && error && 'is-invalid')}>
          {PrependComponent && <PrependComponent />}
          <Form.Control
            className={cls(marginFlag && 'mr-4')}
            {...input}
            name={name}
            type={type}
            placeholder={placeholder}
            isInvalid={touched && error}
            required={required}
          />
        </InputGroup>
      )}

      {touched && error && (
        <>
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </>
      )}
    </Form.Group>
  );
};

export default renderField;
