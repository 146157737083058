import React, { useEffect, useState } from 'react';
import './estimation_details.scss';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import logo from '../../../assets/image/stratton-logo-transparent.png';
import Copyright from '../../components/Copyright';
import loanOptionsImgSrc from '../../../assets/image/searching-lenders.svg';

const useStyles = makeStyles(() =>
  createStyles({
    colorspinner: {
      color: '#1de9b6',
    },
  }),
);
const EstimateLenderPage = (props) => {
  const { loanOptions } = props;

  const classes = useStyles();

  const onLogoClick = () => {
    window.open('https://www.strattonfinance.com.au/');
  };
  return (
    <>
      <div className="estimationLoadingPage-container">
        <div className="estimationComplete-section col-12 col-md-4 col-lg-4">
          <div className="stratton-info-nav-container">
            <img
              src={logo}
              alt="Stratton"
              className="pt-3 stratton-info-logo"
              onClick={onLogoClick}
            />
          </div>
          <div className="stratton-info-footer-container stratton-info-footer-loadingpage">
            <div className="estimatedetails_loanImage estimatedetails_loadImagecenter">
              <img src={loanOptionsImgSrc} className="nav-loan" alt="loan" />
            </div>
            <Copyright />
          </div>
        </div>

        <div className="estimationLoadingPage-page-details col-12 col-md-8 col-lg-8">
          <div className="Need-help py-3">
            Call us on
            <div className="number-font">1300 787 288</div>
          </div>
          <div className="estimationLoadingPage-message">
            <div className="loading-headers">
              <div className="lenders-header">
                <CircularProgress
                  size={50}
                  thickness={3.6}
                  classes={{ colorPrimary: classes.colorspinner }}
                />

                <div className="estimationLoadingPage-name ">
                  We’re calculating your repayments...
                </div>
              </div>
            </div>
            <div className="lenders-logo-container">
              <div className="angle_finance" />
              <div className="PepperMoney" />
              <div className="metro-finance" />
              <div className="Westpac" />
              <div className="liberty-financial" />
              <div className="BOQcolour" />
              <div className="latititude" />
              <div className="firstMac" />
              <div className="anz " />
              <div className="plenti" />
              <div className="money3" />
              <div className="moneyPlace " />
            </div>
          </div>
        </div>
      </div>
      <Copyright mobileFooter />
    </>
  );
};

export default connect((state) => ({
  loanOptions: state.estimation.loanOptions,
}))(withRouter(EstimateLenderPage));
