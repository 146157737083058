export const HTTP_404_ERROR = 'HTTP_404_ERROR';
export const HTTP_500_ERROR = 'HTTP_500_ERROR';
export const HTTP_OTHER_ERROR = 'HTTP_OTHER_ERROR';
export const VALIDATION_ERRORS = 'VALIDATION_ERRORS';
export const FINANCE_VALIDATION_ERRORS = 'FINANCE_VALIDATION_ERRORS';
export const NO_ERRORS = 'NO_ERRORS';

export const execute404Handler = (props) => ({
  type: HTTP_404_ERROR,
  props,
});

export const execute500Handler = (props) => ({
  type: HTTP_500_ERROR,
  props,
});

const executeOtherErrorHandler = (error) => ({
  type: HTTP_OTHER_ERROR,
  error,
});

export const handleHTTPError = (error /* , props */) => {
  if (!error) {
    return executeOtherErrorHandler(error);
  }
  /*
  if (error.status === 404) {
    return execute404Handler(props);
  }
  if (error.status === 500) {
    return execute500Handler(props);
  } */
  return executeOtherErrorHandler(error);
};

export const validationErrors = (error, category) => ({
  type: VALIDATION_ERRORS,
  error,
  category,
});

export const financeValidationerrors = (error) => ({
  type: FINANCE_VALIDATION_ERRORS,
  error,
});

export const noErrors = () => ({
  type: NO_ERRORS,
});
