
import React from 'react';
import {
    useTheme,
    useMediaQuery,
    CircularProgress,
} from '@material-ui/core';
import "./FooterNextPrevious.scss"
import leftnormal from '../../../assets/image/left-normal.svg';
import rightnormal from '../../../assets/image/right-normal.svg';
import Progress from '../../components/Progress';
const FooterNextPrevious = ({ loading, nextClick, previousClick, disabled, progressComplete, progressPending }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(768));
    return (
        <>
            {mobileView ? (
                <>
                    <div className="btn footer-actions btn-upload-next">
                        <button
                            type="button"
                            onClick={previousClick}
                            className="btn btn-primary  btn-prev-page"
                        >
                            <span className="pr-2">
                                <img src={leftnormal} alt="right" className="right-icon" />
                            </span>
                            Previous
                        </button>

                        <button
                            type="button"
                            onClick={nextClick}
                            disabled={!disabled }
                            className="btn btn-primary btn-next-page"
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            {loading ? (
                                <CircularProgress color="inherit" size={24} thickness={2.6} />
                            ) : (
                                <>
                                    {!progressPending?'Apply':'Next'}
                                    <img src={rightnormal} alt="right" className="right-icon" />
                                </>
                            )}
                        </button>
                    </div>
                </>
            ) : (
                <div className="btn  footer-actions btn-upload-next">
                    <button
                        type="button"
                        onClick={previousClick}
                        className="btn btn-primary  btn-next-page"
                    >
                        <span className="pr-3">
                            <img src={leftnormal} alt="right" className="right-icon" />
                        </span>
                        Previous
                    </button>

                    <Progress completed={progressComplete} pending={progressPending} />

                    <button
                        type="button"
                        onClick={nextClick}
                        disabled={!disabled}
                        className="btn btn-primary btn-next-page"
                    >
                        {loading ? (
                            <CircularProgress color="inherit" size={24} thickness={2.6} />
                        ) : !progressPending?'Apply':'Next'}

                        <span className="px-3">
                            <img src={rightnormal} alt="right" className="right-icon" />
                        </span>
                    </button>
                </div>
            )}
        </>
    );
}

export default FooterNextPrevious;



