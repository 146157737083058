/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { connect } from 'react-redux';
import { deleteAllEmployments } from '../../Actions/applicants';
import {
  carDetails,
  createQuote,
  getAllVehicleYears,
  getfactoryOptions,
  loanDetails,
  populateCarMake,
  populateCarModel,
  populateCarSeries,
  populateCarVariants,
  purposeUseFieldChanged,
  regoNumberSearch,
  reQuote,
} from '../../Actions/car';
import { handleHTTPError, noErrors, validationErrors } from '../../Actions/errorHandler';
import fieldChanged, { liteFieldChanged, updateValidationErrors } from '../../Actions/FieldChanged';
import { resetEmploymentSection } from '../../Actions/finances';
import { nextClicked } from '../../Actions/NextClicked';
import { scrollToNextClicked } from '../../Actions/ScrollToNextClicked';
import { setCompleteTab, setCurrentTab } from '../../Actions/tab';
import minmaxRuleEvaluator from '../../RuleEvaluators/MinMaxRuleEvaluator';
import { ASSET_ROUTES, otherVehicle } from '../../util/constants';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import minmaxRule from '../../ValidationRules/MinMaxRule';
import CarFinance from './CarFinance';
import CarFinanceFullScreen from './CarFinanceFullScreen';
import CommercialEquipmentAssetType from './CommercialEquipmentAssetType';
import CommercialEquipmentAssetTypeFullScreen from './CommercialEquipmentAssetTypeFullScreen';
import OtherVehicleAssetType from './OtherVehicleAssetType';
import OtherVehicleAssetTypeFullScreen from './OtherVehicleAssetTypeFullScreen';
import PersonalLoanAssetType from './PersonalLoanAssetType';
import PersonalLoanAssetTypeFullScreen from './PersonalLoanAssetTypeFullScreen';

const RenderContainer = ({ isInitComplete, ...restProps }) => {
  let pathName = window.location.pathname.split('/').pop();
  const isOtherVehicle =
    otherVehicle.find((e) => e.toLowerCase() + 'MainPage' === pathName) || false;
  if (isInitComplete) {
    if (pathName === ASSET_ROUTES.car) {
      return <CarFinance {...restProps} />;
    } else if (pathName === ASSET_ROUTES.commercialAssetType) {
      return <CommercialEquipmentAssetType {...restProps} />;
    } else if (pathName === ASSET_ROUTES.personalLoan) {
      return <PersonalLoanAssetType {...restProps} />;
    } else if (isOtherVehicle) {
      return <OtherVehicleAssetType {...restProps} />;
    } else if (pathName == ASSET_ROUTES.commercialequipment) {
      return <CommercialEquipmentAssetType {...restProps} />;
    } else {
      return <CarFinance {...restProps} />;
    }
  }

  if (pathName === ASSET_ROUTES.car) {
    return <CarFinanceFullScreen {...restProps} />;
  } else if (pathName === ASSET_ROUTES.commercialAssetType) {
    return <CommercialEquipmentAssetTypeFullScreen {...restProps} />;
  } else if (pathName === ASSET_ROUTES.personalLoan) {
    return <PersonalLoanAssetTypeFullScreen {...restProps} />;
  } else if (isOtherVehicle) {
    return <OtherVehicleAssetTypeFullScreen {...restProps} />;
  } else if (pathName == ASSET_ROUTES.commercialequipment) {
    return <CommercialEquipmentAssetTypeFullScreen {...restProps} />;
  } else {
    return <CarFinanceFullScreen {...restProps} />;
  }
};

const mapStateToProps = (state) => ({
  currentTab: state.tab.tabName,
  isInitComplete: state.estimation.isInitComplete,
  regoNumber: state.car.regoNumber,
  regoState: state.car.regoState,
  searchCar: state.car.searchCar,
  searchComplete: state.car.searchComplete,
  purchaseSource: state.car.purchaseSource,
  purchaseUse: state.car.purchaseUse,
  purchasePrice: state.car.purchasePrice,
  depositAmount: state.car.depositAmount,
  loanAmount: state.car.loanAmount,
  carCondition: state.car.carCondition,
  manufacturingYear: state.car.manufacturingYear,
  vehicleMake: state.car.vehicleMake,
  vehicleModel: state.car.vehicleModel,
  vehicleSeries: state.car.vehicleSeries,
  loanOptions: state.estimation.loanOptions,
  vehicleBodyType: state.car.vehicleBodyType,
  vehicleVariant: state.car.vehicleVariant,
  skippedVehicleDetails: state.car.skippedVehicleDetails,
  skippedActionTriggered: state.car.skippedActionTriggered,
  validationErrors: state.car.validationErrors,
  nextFieldNameToFocus: state.car.nextFieldNameToFocus,
  vehicleMakesData: state.car.makes,
  vehicleModelsData: state.car.models,
  vehicleSeriesdata: state.car.series,
  vehicleVariantsdata: state.car.variants,
  nextQuestion: state.car.nextQuestion,
  vehicleEngine: state.car.vehicleEngine,
  factoryOptions: state.car.factoryOptions,
  transmission: state.car.transmission,
  manualEnter: state.car.manualEnter,
  noOfYearsOld: state.car.noOfYearsOld,
  depositPriceComplete: state.car.depositPriceComplete,
  purchasePriceComplete: state.car.purchasePriceComplete,
  estimatedLoanComplete: state.car.estimatedLoanComplete,
  progressValue: state.car.progress,
  skipVariant: state.car.skipVariant,
  skipSeries: state.car.skipSeries,
  skipModel: state.car.skipModel,
  errorMessage: state.errorHandler.errorMessage,
  ValidationErrorFlag: state.errorHandler.ValidationErrorFlag,
  getValidationErrorsForField: getValidationErrorsForFieldFunc(state.car.validationErrors),
  vehicleYears: state.car.years,
  nvicCode: state.car.nvicCode,
  inProgress: state.estimation.inProgress,
  quoteInProgress: state.account.quoteInProgress,
  factoryOptionsComplete: state.car.factoryOptionsComplete,
  nvicMake: state.car.nvicMake,
  nvicModel: state.car.nvicModel,
  nvicVariant: state.car.nvicVariant,
  nvicSeries: state.car.nvicSeries,
  isLoadingcarmakes: state.car.isLoadingcarmakes,
  isLoadingcarseries: state.car.isLoadingcarseries,
  regoSearchInProgress: state.car.regoSearchInProgress,
  regoSearchError: state.car.regoSearchError,
  opportunityId: state.applicants.opportunityId,
  nofactoryOptions: state.car.nofactoryOptions,
  carDetailsChanged: state.car.carDetailsChanged,
  carPurposehasChanged: state.car.carPurposehasChanged,
  prevEmpId: state.applicants.prevEmpId,
  currentEmpId: state.applicants.currentEmpId,
  termVal: state.estimation.termVal,
  isAuthenticated: state.account.isAuthenticated,
  forceNewApplication: state.account.forceNewApplication,
  completedTabs: state.tab.completedTabs,
  carSalesOppCreated: state.car.carSalesOppCreated,
  isCarsalesAccount: state.account.isCarsalesAccount,
  assetType: state.car.assetType,
  assetSubType: state.car.assetSubType,
  commercialEquipment: state.car.commercialEquipment,
  carSalesContact: state.applicants.carSalesContact,
  skipcommercialType: state.car.skipcommercialType,
  estimatedLoan: state.car.estimatedLoan,
  commercialType: state.car.commercialType,
  vehicleMakeModel: state.car.vehicleMakeModel,
  skippedManufacturingYear: state.car.skippedManufacturingYear,
  skipVehicleMakeModel: state.car.skipVehicleMakeModel,
  acceptPrivacyPolicy: state.applicants.acceptPrivacyPolicy,
  partnername: state.car.referralChannelSource,
  condition: state.car.condition,
  make: state.car.make,
  model: state.car.model,
  year: state.car.year,
  depositAmountUrl: state.car.depositAmountUrl, 
  purchasePriceUrl: state.car.purchasePriceUrl,
  purchaseSourceUrl: state.car.purchaseSourceUrl,
  purchaseUseUrl: state.car.purchaseUseUrl,
  term: state.car.term,
  prefillRecords: state.car.prefillRecords
});

const mapDispatchToProps = (dispatch) => ({
  getAllVehicleYears: (carCondition) => dispatch(getAllVehicleYears(carCondition)),
  getAllVehicleMakes: () => dispatch(populateCarMake()),
  getModelForVehicleMakes: () => dispatch(populateCarModel()),
  getSeriesForVehicleModel: () => dispatch(populateCarSeries()),
  getVariantsForVehicleSeries: () => dispatch(populateCarVariants()),
  nextButtonClick: () => {
    dispatch(nextClicked('car'));
  },
  sectionComplete: (
    fieldName = 'isRegoKnown',
    validateFields = [
      'isRegoKnown',
      'carCondition',
      'regoNumber',
      'regoState',
      'odometer',
      'purchaseSource',
      'purchaseUse',
      'purchasePrice',
      'manufacturingYear',
    ],
  ) => {
    dispatch(scrollToNextClicked('car', fieldName, validateFields));
  },
  scrollToNext: (fieldName, ignoreHeaderOffset, ignoreParentOffset) => {
    dispatch(
      scrollToNextClicked('car', fieldName, [], ignoreHeaderOffset, ignoreParentOffset, true),
    );
  },
  onLoadPageAsset: (value) => dispatch(liteFieldChanged('car', 'assetType', value, false)),
  onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(fieldChanged('car', fieldName, value, scrollToNextFieldOnChage));
    dispatch(liteFieldChanged('car', 'carDetailsChanged', true, false));
  },
  purposeOfUseChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(fieldChanged('car', fieldName, value, scrollToNextFieldOnChage));
    dispatch(purposeUseFieldChanged());
  },
  resetEmploymentSection: () => dispatch(resetEmploymentSection()),
  flagChange: (fieldName, value) => dispatch(liteFieldChanged('car', fieldName, value)),
  onEstimateChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(fieldChanged('estimation', fieldName, value, scrollToNextFieldOnChage));
  },
  onInputWithMinMaxRuleChange: (
    fieldName,
    minValue,
    maxValue,
    message,
    value,
    scrollToNextFieldOnChage,
  ) => {
    const valueValidator = minmaxRule(fieldName, minValue, maxValue, message);
    const errors = minmaxRuleEvaluator(valueValidator, value);

    if (errors) {
      dispatch(updateValidationErrors('car', fieldName, errors));
    } else {
      dispatch(fieldChanged('car', fieldName, value, scrollToNextFieldOnChage));
    }
  },
  setTabComplete: () => {
    dispatch(setCompleteTab('car'));
  },
  validationCheck: (error) => dispatch(validationErrors(error)),
  setTab: (tabName) => dispatch(setCurrentTab(tabName)),
  createQuote: () => dispatch(createQuote()),
  carDetails: () => dispatch(carDetails()),
  loanDetails: () => dispatch(loanDetails()),
  handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
  noErrors: () => dispatch(noErrors()),
  regoNumberSearch: () => dispatch(regoNumberSearch()),
  getfactoryOptions: () => dispatch(getfactoryOptions()),
  reQuote: () => dispatch(reQuote()),
  deleteAllEmployments: () => dispatch(deleteAllEmployments(true)),
});

const CarFinanceContainer = connect(mapStateToProps, mapDispatchToProps)(RenderContainer);

export default CarFinanceContainer;
