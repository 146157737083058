import { combineReducers } from 'redux';
// redux call
import { reducer as reduxFormReducer } from 'redux-form';
import CarReducer from './car';
import tab from './tab';
import stepperDetails from './stepperDetails';
import carDataReducer from './carData';
import EstimationReducer from './estimate';
import AccountReducer from './account';
import errorHandlerReducer from './errorhandlerReducer';
import ApplicantReducer from './applicants';
import LoanOptionsReducer from './loan';
import { RESET_ALL_TAB } from '../Actions/tab';
import SettlementReducer from './settlement';

function createFilteredReducer(reducerObject, reducerPredicate) {
  return (state, action) => {
    const isInitializationCall = state === undefined;
    const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall;
    return shouldRunWrappedReducer ? reducerObject.reduce(state, action) : state;
  };
}

const appReducer = combineReducers({
  form: reduxFormReducer,
  carData: carDataReducer,
  tab,
  stepperDetails,
  applicants: createFilteredReducer(
    new ApplicantReducer(),
    (action) => action.type.endsWith('_APPLICANTS'),
    // This allows these reducers to respond only to specific types of actions that end with certain suffixes like _APPLICANTS, _CAR, _SETTLEMENT, etc.
    // Refactor Tip: directly passing the reducer instances without using createFilteredReducer. eg car: new CarReducer(),
  ),
  car: createFilteredReducer(new CarReducer(), (action) => action.type.endsWith('_CAR')),
  settlement: createFilteredReducer(new SettlementReducer(), (action) =>
    action.type.endsWith('_SETTLEMENT'),
  ),
  account: createFilteredReducer(new AccountReducer(), (action) =>
    action.type.endsWith('_ACCOUNT'),
  ),
  estimation: createFilteredReducer(new EstimationReducer(), (action) =>
    action.type.endsWith('_ESTIMATION'),
  ),
  loan: createFilteredReducer(new LoanOptionsReducer(), (action) => action.type.endsWith('_LOAN')),
  errorHandler: errorHandlerReducer,
});

const RootReducer = (state, action) => {
  if (action.type === RESET_ALL_TAB) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default RootReducer;
