/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import cls from 'classnames';
import { CircularProgress } from '@material-ui/core';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import processGTMevent from '../../util/processGTMEvent';
import '../../components/input-field.scss';
import InputButton from '../../components/InputButton';
import TextField from '../../components/TextField';
import {
  ROUTES,
  PURCHASE_SOURCE,
  PURCHASE_USE,
} from '../../util/constants';
import FindMakeFilter from '../../components/FindMakeFilter';
import Tooltip from '../../components/Tooltip';
import rightnormal from '../../../assets/image/right-normal.svg';
import AmountRangeSelector from '../../components/AmountRangeSelector';
import './carfinance.scss';
import { capFirstLetter } from '../../util/helpers';

const useStyles = makeStyles(() =>
  createStyles({
    amountRangeRoot: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    sliderContainer: {
      marginLeft: 0,
      marginRight: 0,
      width: '100%',
    },
    sliderThumb: {
      marginLeft: '-4px',
    },
    manualCarQueryContainer: {},
  }),
);

const OtherVehicleAssetType = (props) => {
  const {
    setTab,
    currentTab,
    purchaseSource,
    purchaseUse,
    purchasePrice,
    depositAmount,
    carCondition,
    manufacturingYear,
    vehicleMake,
    vehicleModel,
    validationErrors,
    getValidationErrorsForField,
    onInputChange,
    flagChange,
    purchasePriceComplete,
    setTabComplete,
    nextFieldNameToFocus,
    scrollToNext,
    depositPriceComplete,
    createQuote,
    handleHTTPError,
    carDetails,
    noErrors,
    validationCheck,
    getAllVehicleYears,
    vehicleYears,
    opportunityId,
    loanDetails,
    quoteInProgress,
    reQuote,
    purposeOfUseChange,
    carPurposehasChanged,
    resetEmploymentSection,
    deleteAllEmployments,
    termVal,
    onEstimateChange,
    completedTabs,
    carSalesOppCreated,
    isCarsalesAccount,
    carSalesContact,
    assetType,
  } = props;

  const history = useHistory();
  const classes = useStyles({});

  const updatedAssetType = assetType?.toLowerCase().replace(/\s+/g, '-');
  useEffect(() => {
    processGTMevent(assetType, 'PQ-PageLoad', 'Page Load');
    reQuote();
    getAllVehicleYears('');

    if (setTab) {
      if (
        completedTabs.car &&
        completedTabs.estimation &&
        completedTabs.applicants &&
        completedTabs.finance_details
      ) {
        setTab('compare_loan_options');
      } else if (completedTabs.car && completedTabs.estimation && completedTabs.applicants) {
        setTab('finance_details');
      } else {
        setTab('car');
      }

      scrollToNext(`${updatedAssetType}~fullScreen`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextFieldNameToFocus) {
      if (nextFieldNameToFocus === 'purchasePrice' && carCondition === 'Unsure' && purchaseUse) {
        return;
      }
      if (
        nextFieldNameToFocus === 'purchase-price-selector' &&
        carCondition === 'Unsure' &&
        purchaseUse
      ) {
        return;
      }
      scrollToNext(nextFieldNameToFocus, true, true);
    } else if (!carCondition) {
      scrollToNext(`${updatedAssetType}~fullScreen`);
    }
  }, [carCondition, nextFieldNameToFocus, purchaseUse, scrollToNext]);
  // eslint-disable-next-line max-len
  const vehicleYearsformatted = reverse(sortBy(vehicleYears));

  const currentData = new Date();
  const currentYear = currentData.getFullYear();
  const vehicleYearsMax10 = vehicleYearsformatted.reduce((accum, yrOption, idx) => {
    if (idx > 9) return accum;
    accum.push({ name: String(yrOption), value: yrOption });
    return accum;
  }, []);
  vehicleYearsMax10.push({ name: 'Older', value: String(currentYear - 10) });
  const handleClick = () => {
    processGTMevent(assetType, 'termValue', termVal);
    processGTMevent(assetType, 'seeContact', 'seeContactPage');
    if (carPurposehasChanged) {
      deleteAllEmployments();
      resetEmploymentSection();
      flagChange('carPurposehasChanged', false);
    }

    flagChange('carDetailsChanged', false);
    if (validationErrors && validationErrors.length) {
      validationCheck();
      return;
    }
    if (purchasePrice - depositAmount < 5000) {
      validationCheck('Loan Amount cannot be less than $5000.');
      return;
    }
    noErrors();
    if (opportunityId) {
      carDetails();
      loanDetails()
        .then(() => {
          reQuote();
          setTabComplete(currentTab);
          localStorage.setItem(`pageCompleted${opportunityId}`, 'car');
          if ((carSalesOppCreated || isCarsalesAccount) && !carSalesContact) {
            history.push(ROUTES.contactDetails);
          } else {
            history.push(ROUTES.personal);
          }
        })
        .catch((error) => {
          handleHTTPError(error, history);
        });
    } else {
      createQuote()
        .then(() => {
          setTabComplete(currentTab);
          history.push(ROUTES.personal);
        })
        .catch((error) => {
          handleHTTPError(error, history);
        });
    }
  };

  const handleFieldChange = (fieldName, value, scrollToNextFieldOnChage) => {
    onInputChange(fieldName, value, scrollToNextFieldOnChage);
    if (
      fieldName === 'purchaseUse' ||
      fieldName === 'purchaseSource' ||
      fieldName === 'purchasePrice' ||
      fieldName === 'depositAmount'
    ) {
      createQuote();
    }
  };

  const maxTermVal = 7;
  return (
    <>
      <div>
        <div className="container-section car-applicants-main-container">
          <div
            className="content-body car-applicants-main-tab-container car-subscreen-container"
            id="car"
          >
            <div className="car-details-header">
              <div className="car-details-title">
                Tell us about the {assetType.toLowerCase()} you're looking to buy
              </div>
              <div className="car-details-subtitle">
                Providing more information will improve the accuracy of your rate and repayments
              </div>
            </div>
            <hr className="car-details-border" />
            <div className="car-applicants-main-subcontainer car-details-content-block">
              <div className="row mx-0">
                <div className="col-12 car-applicants-main-section px-0">
                  <Form
                    noValidate
                    className={cls(
                      {
                        'car-applicants-footer-wrap':
                          (!!purchasePrice && purchasePriceComplete && carCondition !== 'Unsure') ||
                          (carCondition === 'Unsure' && !!purchaseUse),
                      },
                      'car-applicants-main-form pb-0',
                    )}
                  >
                    <FindMakeFilter
                      label={
                        <>
                          <span className="label">When was the {assetType.toLowerCase()} manufactured?</span>
                        </>
                      }
                      fields={vehicleYearsformatted.map((yr) => ({
                        name: String(yr),
                        value: yr,
                      }))}
                      onChange={handleFieldChange}
                      fieldName="manufacturingYear"
                      value={manufacturingYear}
                      loadmore
                      withSideNav
                      isreturnObjectval
                      getvalidationerrors={getValidationErrorsForField}
                      id="car-series"
                    />

                    <div className="centered-field small vehicleMakeModel ">
                      <div className="title">What's the make and model of the {assetType.toLowerCase()}?</div>
                      <div className="container small" style={{ padding: 0 }}>
                        <br />
                        <TextField
                          fieldName={`vehicleMake`}
                          value={vehicleMake?.name ? vehicleMake?.name : vehicleMake}
                          label={`${capFirstLetter(updatedAssetType)} make`}
                          placeholder="Enter make"
                          getvalidationerrors={getValidationErrorsForField}
                          onChange={handleFieldChange}
                          inline
                          id="vehicleMake"
                          style={{ padding: 0 }}
                        />
                        <br />
                        <TextField
                          fieldName={`vehicleModel`}
                          value={vehicleModel?.name ? vehicleModel?.name : vehicleModel}
                          label={`${capFirstLetter(updatedAssetType)} model`}
                          placeholder="Enter model"
                          getvalidationerrors={getValidationErrorsForField}
                          onChange={handleFieldChange}
                          inline
                          id="vehicleModel"
                          style={{ padding: 0 }}
                        />
                        <br />
                      </div>
                    </div>

                    {carCondition !== 'New' && (<InputButton
                      label={
                        <>
                          <span>Where are you planning to purchase the {assetType.toLowerCase()} from?</span>
                        </>
                      }
                      fields={PURCHASE_SOURCE}
                      onChange={handleFieldChange}
                      fieldName="purchaseSource"
                      value={purchaseSource}
                      formGroupClass="car-purchase-from"
                      id="car-purchase-from"
                      getvalidationerrors={getValidationErrorsForField}
                    />
                    )}


                    {nextFieldNameToFocus === 'purchaseUse' && (
                      <InputButton
                        label={
                          <div className="title-with-sub-label">
                            <div className="label">
                              How will the car be used for the majority of the time?
                            </div>
                            <span className="title-sub-label">
                              Select business if you use your car for work more than 50% of the time
                            </span>
                            <Tooltip message="Using your car for business includes carrying tools to multiple job sites, driving between clients etc. You will need to provide proof of business use." />
                          </div>
                        }
                        fields={PURCHASE_USE}
                        onChange={purposeOfUseChange}
                        fieldName="purchaseUse"
                        value={purchaseUse}
                        formGroupClass="car-usage"
                        getvalidationerrors={getValidationErrorsForField}
                        id="car-usage"
                      />
                    )}

                    {purchaseUse && carCondition !== 'Unsure' && (
                      <div
                        className={cls(
                          'amount-range-container purchase-price-selector car-purchase-price',
                          'scrollable-anchor-tag',
                          // !purchasePriceComplete && 'fit-screen',
                        )}
                      >
                        <a name="car-purchase-price" href="#car-purchase-price">
                          &nbsp;
                        </a>
                        <div className="label">
                          What is the estimated purchase price of the {assetType.toLowerCase()}?
                        </div>
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="range-selector-label">Purchase price</span>
                            </>
                          }
                          onChange={handleFieldChange}
                          fieldName="purchasePrice"
                          defaultValue={purchasePrice}
                          colsm={12}
                          colmd={8}
                          // oncreateQuote={createQuote}
                          getvalidationerrors={getValidationErrorsForField}
                          minValue={5000}
                          maxValue={250000}
                          step={1000}
                          classes={{
                            root: classes.amountRangeRoot,
                            sliderContainer: classes.sliderContainer,
                          }}
                          sliderClasses={{
                            thumb: classes.sliderThumb,
                          }}
                        />
                      </div>
                    )}
                    {purchasePriceComplete && carCondition !== 'Unsure' && (
                      <>
                        <div className="deposit scrollable-anchor-tag">
                          <a name="deposit" href="#deposit">
                            &nbsp;
                          </a>
                        </div>
                        <div className="amount-range-container deposit-price-selector">
                          <AmountRangeSelector
                            label={
                              <>
                                <span className="range-selector-label">Deposit / Trade-in</span>
                              </>
                            }
                            onChange={handleFieldChange}
                            fieldName="depositAmount"
                            defaultValue={depositAmount}
                            colsm={12}
                            colmd={8}
                            minValue={0}
                            // oncreateQuote={createQuote}
                            maxValue={purchasePrice}
                            step={1000}
                            tooltip="Enter the combined amount for the trade in value of your current car, and any cash deposit you will be contributing to the purchase"
                            getvalidationerrors={getValidationErrorsForField}
                            classes={{
                              root: classes.amountRangeRoot,
                              sliderContainer: classes.sliderContainer,
                            }}
                            sliderClasses={{
                              thumb: classes.sliderThumb,
                            }}
                          />
                          <div className="form-row pb-4">
                            <div className=" car-applicants-loan-form col-md-5 pr-5 ">
                              <div className="car-applicants-main-loanamount">
                                <NumberFormat
                                  displayType="text"
                                  value={purchasePrice - depositAmount}
                                  className="loan-amount-value car-details-loan-amt"
                                  id="loan-amount-value"
                                  prefix="$"
                                  thousandSeparator
                                />
                              </div>
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label
                                className="car-applicants-main-loanamount-label car-details-loan-amount-label"
                                htmlFor="loan-amount-value"
                              >
                                Estimated loan amount
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {depositPriceComplete && purchaseUse && (
                      <div
                        className={cls(
                          'amount-range-container loan-term-selector car-loan-term',
                          'scrollable-anchor-tag',
                        )}
                      >
                        <div className="label">Over what term do you want the loan?</div>
                        <div className="term scrollable-anchor-tag">
                          <a name="term" href="#term">
                            &nbsp;
                          </a>
                        </div>

                        <div className="amount-range-container term-val-selector">
                          <AmountRangeSelector
                            label={
                              <>
                                <span className="label">Loan term</span>
                              </>
                            }
                            // formGroupClass="estimation-range-selector col-12 col-sm-6 col-md-4"
                            onChange={onEstimateChange}
                            fieldName="termVal"
                            defaultValue={termVal}
                            oncreateQuote={createQuote}
                            minValue={1}
                            maxValue={maxTermVal}
                            step={1}
                            termSlider
                            classes={{
                              root: classes.amountRangeRoot,
                              sliderContainer: classes.sliderContainer,
                            }}
                            sliderClasses={{
                              thumb: classes.sliderThumb,
                            }}
                            getvalidationerrors={getValidationErrorsForField}
                          />
                        </div>
                      </div>
                    )}

                    <div className="footer-actions">
                      <button
                        type="button"
                        className="btn btn-primary btn-next-page"
                        hidden={!purchaseUse || (depositPriceComplete && purchaseUse)}
                        disabled={validationErrors && validationErrors.length}
                        onClick={() => {
                         
                          onInputChange('depositPriceComplete', true, false);
                          if (purchasePrice && !purchasePriceComplete) {
                            processGTMevent(assetType, 'purchasePriceValue', purchasePrice);
                          } else if (
                            (depositAmount || depositAmount === 0 || depositAmount === null) &&
                            !depositPriceComplete
                          ) {
                            processGTMevent(assetType, 'depositAmountValue', depositAmount);
                          }
                        }}
                      >
                        Next
                        <span className="pl-4">
                          <img src={rightnormal} alt="right" className="right-icon" />
                        </span>
                      </button>

                      <div className="row container-footer  car-details-footer ">
                        <div className="col-12 d-flex justify-content-end px-0 car-details-next-btn">
                          <button
                            type="button"
                            data-test="carDetails-Next"
                            className="btn btn-primary btn-next-page"
                            onClick={handleClick}
                            disabled={quoteInProgress}
                          >
                            {quoteInProgress ? (
                              <CircularProgress color="inherit" size={24} thickness={2.6} />
                            ) : (
                              <>
                                Next
                                <span className="pl-4">
                                  <img src={rightnormal} alt="right" className="right-icon" />
                                </span>
                              </>
                            )}
                          </button>
                          {/*   dummy button addded to make the focus point there */}
                          <input
                            type="button"
                            id="btnsubmit"
                            className="btn-dummy-page"
                            style={{ visibility: 'hidden' }}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherVehicleAssetType;
