import Pepper from '../../assets/image/Peppermoney.svg';
import Volkswagen from '../../assets/image/Volkswagon.svg';
import Plenti from '../../assets/image/Plenti.svg';
import Macquarie from '../../assets/image/Macquarie.svg';
import Metro from '../../assets/image/Metro.svg';
import Liberty from '../../assets/image/Liberty.svg';

const getLenderLogo = (lenderName) => {
  let lenderLogo;
  switch (lenderName) {
    case 'Liberty':
      lenderLogo = Liberty;
      break;
    case 'Pepper':
      lenderLogo = Pepper;
      break;
    case 'RateSetter':
      lenderLogo = Plenti;
      break;
    case 'Plenti':
      lenderLogo = Plenti;
      break;
    case 'Metro':
      lenderLogo = Metro;
      break;
    case 'Volkswagen':
      lenderLogo = Volkswagen;
      break;
    default:
      break;
  }

  return lenderLogo;
};

export default getLenderLogo;
