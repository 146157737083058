import postcodes from '../util/PostCodes';

const postcodeRuleEvaluator = (rule, value) => {
  if (!value || postcodes.indexOf(value) < 0) {
    return rule.errorMessage;
  }
  return null;
};

export default postcodeRuleEvaluator;
