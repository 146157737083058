import moment from 'moment';

const date18YearsRuleEvaluator = (rule, value) => {
  if (
    moment()
      .startOf('day')
      .diff(value, 'year') < 18
  ) {
    return rule.errorMessage;
  }

  return null;
};

export default date18YearsRuleEvaluator;
