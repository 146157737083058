/* eslint-disable import/no-cycle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Modal, Row, Col, Form, Container } from 'react-bootstrap';
import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import differenceWith from 'lodash/differenceWith';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import InputGroup from 'react-bootstrap/InputGroup';
import renderNumberFormat from '../../../components/ReduxRenderField/renderNumberFormat';
import renderCheckboxField from '../../../components/ReduxRenderField/renderCheckboxField';
import renderSelectField from '../../../components/ReduxRenderField/renderSelectField';
import {
  AssetTypes,
  SAVINGS,
  ASSET_OTHER,
  HOMECONTENTS,
  SUPERANNUATION,
  ASSET_RELATED_LIABILITIES,
  LIABILITIES_LABEL_VALUE_MAP,
} from '../../../util/constants';
import './assetdetails.scss';
import { RetrieveOpportunity } from '../../../Actions/car';
import { formatAmount, normalizeAmount } from '../../../lib/formatter';
import { assets } from '../../../Actions/finances';
import processGTMevent from '../../../util/processGTMEvent';

export const getFilteredLiabilitiesByAsset = (liabilities, relatedAsset) =>
  filter(liabilities, ['category', ASSET_RELATED_LIABILITIES[relatedAsset]]);

const AssetForm = ({
  assetDetails,
  assetsLists,
  liabilities,
  onUpdateAsset,
  assetsApi,
  handleSubmit,
  pristine,
  submitting,
  isAdd,
  category,
  onCancel,
  retrieveOpportunity,
  change,
  opportunityId,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  
  const availableCategories = differenceWith(
    AssetTypes,
    assetsLists,
    (allOption, addedOption) => allOption.value === addedOption.category,
  );

  const Title = get(find(AssetTypes, ['value', assetDetails?.category]), 'name', 'N/A');
  const relatedLiabilities = getFilteredLiabilitiesByAsset(
    liabilities,
    assetDetails?.category || category,
  );

  const relatedLiabilitiesOptions = relatedLiabilities.map((liability, idx) => ({
    value: liability.id,
    name: `${LIABILITIES_LABEL_VALUE_MAP[liability.category]} - $${formatAmount(
      liability.outstanding,
    )}`,
    selected: idx === 0,
  }));

  const submit = (data) => {
    processGTMevent('finances', 'assets', 'addAssets');
    setSubmitting(true);
    if (assetsApi) {
      assetsApi({ ...assetDetails, ...data })
        .then((response) => {
          onUpdateAsset({ ...data, id: response.data[0].id });
          setSubmitting(false);
          retrieveOpportunity();
          localStorage.setItem(`pageCompleted${opportunityId}`, 'asset-details');
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          setSubmitting(false);
        });
    }
  };

  const isUnderfinanceNotApplicable = (assetCategory) =>
    assetCategory === SAVINGS ||
    assetCategory === HOMECONTENTS ||
    assetCategory === SUPERANNUATION ||
    assetCategory === ASSET_OTHER;

  return (
    <div>
      <Modal show onHide={onCancel} size="md" className="modal-wrapper finances-modal-wrapper">
        <Modal.Header closeButton className="finances-header-container">
          <Row className="ml-1">
            <Modal.Title className="finances-header-section">
              {isAdd ? 'Add asset' : Title}
            </Modal.Title>
          </Row>
        </Modal.Header>

        <Modal.Body className="p-0">
          <Container>
            <Row className="show-grid">
              <Col className="income-column">
                <Form noValidate onSubmit={handleSubmit(submit)}>
                  {isAdd && (
                    <>
                      <Form.Group>
                        <Form.Label className="finances-form-label">Asset type</Form.Label>
                        <Field
                          type="select"
                          placeholder="Select"
                          label="Asset type"
                          name="category"
                          options={availableCategories}
                          component={renderSelectField}
                          onChange={(event) => {
                            const { value } = event.target;
                            if (isUnderfinanceNotApplicable(value)) {
                              change('underfinance', false);
                            }
                          }}
                          required
                        />
                      </Form.Group>
                    </>
                  )}
                  {!!relatedLiabilitiesOptions.length && (
                    <Form.Group>
                      <Form.Label className="finances-form-label">Related Liability</Form.Label>
                      <Field
                        type="select"
                        placeholder="Select"
                        label="Related Liability"
                        name="relatedLiability"
                        options={relatedLiabilitiesOptions}
                        component={renderSelectField}
                        required
                      />
                    </Form.Group>
                  )}
                  <Field
                    label="Estimated value"
                    name="estimateVal"
                    type="text"
                    placeholder="0.00"
                    required
                    PrependComponent={() => (
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                      </InputGroup.Prepend>
                    )}
                    component={renderNumberFormat}
                    normalize={normalizeAmount}
                  />
                  { !isUnderfinanceNotApplicable(category) && (
                      <Field
                      label="Currently financed?"
                      name="underfinance"
                      type="checkbox"
                      component={renderCheckboxField}
                      checked={!isUnderfinanceNotApplicable(category)}
                    />
                  )}
                  <hr className="horizantal-line" />
                  <Modal.Footer className="footer-container px-0">
                    {/*   <ButtonToolbar> */}

                    <Button type="button" className="footer-cancel" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting || pristine || submitting}
                      className="footer-btn mr-0"
                      block
                    >
                      {isAdd ? 'Save' : 'Update'}
                    </Button>
                    {/*   </ButtonToolbar> */}
                  </Modal.Footer>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const FORM_NAME = 'assetDetails';

const EnhancedAssetForm = reduxForm({
  form: FORM_NAME,
  validate: (values, { liabilities, assetDetails }) => {
    const errors = {};
    const relatedLiabilities = getFilteredLiabilitiesByAsset(
      liabilities,
      assetDetails?.category || values.category,
    );

    if (!values.category) {
      errors.category = 'Required';
    }
    if (!values.estimateVal) {
      errors.estimateVal = 'Required';
    } else if (Number.isNaN(values.estimateVal)) {
      errors.estimateVal = 'Must be a number';
    } else if (Number(values.estimateVal) < 1) {
      errors.estimateVal = 'estimate  value should be greater than one';
    }
    if (relatedLiabilities.length && !values.relatedLiability) {
      errors.relatedLiability = 'Required';
    }
    if (values.relatedLiability && !values.underfinance) {
      errors.underfinance = 'must be currently financed for the added liability';
    }
    return errors;
  },
})(AssetForm);

const selector = formValueSelector(FORM_NAME); // <-- same as form name

export default connect(
  (state) => {
    // can select values individually
    const category = selector(state, 'category');
    return {
      assetsLists: state.applicants.assets,
      liabilities: state.applicants.liabilities || [],
      category,
      opportunityId: state.applicants.opportunityId,
    };
  },
  (dispatch) => ({
    assetsApi: (assetData) => dispatch(assets(assetData)),
    retrieveOpportunity: () => dispatch(RetrieveOpportunity()),
  }),
)(EnhancedAssetForm);
