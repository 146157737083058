import find from 'lodash/find';

export const getValidationErrorsForFieldFunc = (stateValidationErrors) => (fieldName) => {
  const errorForTheField =
    stateValidationErrors &&
    stateValidationErrors.find((element) => element.fieldName === fieldName);
  if (errorForTheField) {
    return errorForTheField.messages;
  }
  return [];
};

export const getNameByValue = (list = [], value) => {
  const foundObj = find(list, ['value', value]);

  return (foundObj && foundObj.name) || '';
};
