import { API } from 'aws-amplify';
import apiUris from '../../apiUris';
import { API_NAMES } from '../../awsconfig';
import { liteFieldChanged } from './FieldChanged';
import * as log from '../util/log';
import { validationErrors } from './errorHandler';
import { SETTLEMENT } from '../util/constants';
import { POPULATE_CARMAKES, POPULATE_YEARS_SETTLEMENT } from './car';
import { orderBy, take } from 'lodash';

export const CLEAR_REGOVEHICLEDETAILS_SETTLEMENT = 'CLEAR_REGOVEHICLEDETAILS_SETTLEMENT';
export const POPULATE_REGOVEHICLEDETAILS_SETTLEMENT = 'POPULATE_REGOVEHICLEDETAILS_SETTLEMENT';
export const POPULATE_CARMODELS_SETTLEMENT = 'POPULATE_CARMODELS_SETTLEMENT';

export const regoNumberSearchForSettlement = () => (dispatch, getState) => {
  const state = getState();
  const { regoNumber, regoState } = state.settlement;
  const formattedRegoNumber = regoNumber.replace(/\W+/g, '');
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch({ type: CLEAR_REGOVEHICLEDETAILS_SETTLEMENT });
  dispatch(liteFieldChanged(SETTLEMENT, 'searchCar', false));
  dispatch(liteFieldChanged(SETTLEMENT, 'regoSearchError', false));
  dispatch(liteFieldChanged(SETTLEMENT, 'regoSearchInProgress', true));
  dispatch(liteFieldChanged('account', 'quoteInProgress', true));
  return API.get(
    API_NAMES.REGOVEHICLEDETAILS,
    apiUris.GET_REGODETAILS(formattedRegoNumber, regoState),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.REGOVEHICLEDETAILS}. rego: ${regoNumber}, state: ${regoState}`,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false));
      dispatch({
        type: POPULATE_REGOVEHICLEDETAILS_SETTLEMENT,
        factorydata: response.data,
      });
      dispatch(liteFieldChanged(SETTLEMENT, 'searchCar', true));
      dispatch(liteFieldChanged(SETTLEMENT, 'regoSearchInProgress', false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.REGOVEHICLEDETAILS}. rego: ${regoNumber}, state: ${regoState}`,
        error,
      );
      dispatch(liteFieldChanged('account', 'quoteInProgress', false));
      dispatch(liteFieldChanged(SETTLEMENT, 'regoSearchError', true));
      if (error.response && error.response.status) {
        dispatch(
          validationErrors(
            'We could not find the car you are looking for, make sure you have entered correct details',
          ),
        );
      } else {
        dispatch(
          validationErrors(
            "We couldn't find your registration at this time. Please try again soon.",
          ),
        );
      }
      dispatch(liteFieldChanged(SETTLEMENT, 'regoSearchInProgress', false));
      return Promise.reject(error.message);
    });
};

export const populateCarMakeSettlement = () => (dispatch, getState) => {
  const state = getState();
  const { manufacturingYear } = state.settlement;
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  dispatch(liteFieldChanged(SETTLEMENT, 'isLoadingcarmakes', true, false));
  return API.get(API_NAMES.GET_CARMAKES, apiUris.GET_CARMAKES(manufacturingYear), myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.GET_CARMAKES}, year: ${manufacturingYear}`);
      dispatch(liteFieldChanged(SETTLEMENT, 'isLoadingcarmakes', false, false));
      dispatch({
        type: POPULATE_CARMAKES,
        vehicleMakesData: response.data.makes,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.GET_CARMAKES}. year: ${manufacturingYear}`, error);
      dispatch(liteFieldChanged('car', 'isLoadingcarmakes', false, false));
      Promise.reject(error.message);
    });
};
export const getAllVehicleYears = () => (dispatch) => {
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  return API.get(API_NAMES.GET_YEARS, apiUris.GET_YEARS, myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.GET_YEARS}`);
      const arryDataYears = response.data.years;
      const sortedarray = take(orderBy(arryDataYears, null, ['desc']), 20);
      dispatch({
        type: POPULATE_YEARS_SETTLEMENT,
        yearData: sortedarray,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.GET_YEARS}`, error);
      return Promise.reject(error.message);
    });
};
export const populateCarModel = () => (dispatch, getState) => {
  const state = getState();
  const { manufacturingYear, vehicleMake } = state.settlement;
  const myInit = {
    response: true,
    headers: {},
    timeout: 1000 * 10,
  };
  return API.get(
    API_NAMES.GET_CARMODELS,
    apiUris.GET_CARMODELS(manufacturingYear, vehicleMake && vehicleMake.value),
    myInit,
  )
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.GET_CARMODELS}, year: ${manufacturingYear}`);
      dispatch({
        type: POPULATE_CARMODELS_SETTLEMENT,
        vehicleModelsData: response.data.models,
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${
          API_NAMES.GET_CARMODELS
        }. year: ${manufacturingYear}, make: ${vehicleMake && vehicleMake.value}`,
        error,
      );
      return Promise.reject(error.message);
    });
};
