const minmaxRuleEvaluator = (rule, value) => {
  if (!value) {
    return null;
  }

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value) || Number(value) < rule.min || Number(value) > rule.max) {
    return rule.errorMessage;
  }
  return null;
};

export default minmaxRuleEvaluator;
