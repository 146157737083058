/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import AnimatedNumber from 'animated-number-react';
import cls from 'classnames';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import editImg from '../../assets/image/edit.svg';
import fieldChanged from '../Actions/FieldChanged';
import EditLoanAmount from './EditLoanAmount';
import getLenderLogo from '../util/getLenderLogo';
import { upperCase } from 'lodash';

import { validationErrors, noErrors, handleHTTPError } from '../Actions/errorHandler';
import { formatRepaymentValue, formatNumberValue } from '../lib/formatter';

const Footer = ({
  depositAmount,
  purchasePrice,
  selectFreq,
  currentTab,
  editLoanAmt,
  onInputChange,
  minRepaymentVal,
  maxRepaymentVal,
  loanOptions,
  selectedLender,
  monthlyRepayments,
  hideEdit,
  assetType,
}) => {
  // eslint-disable-next-line no-nested-ternary
  const freqVal = selectFreq || 'Monthly';
  const [showDetails, toggleDetails] = useState(false);
  const toggleFooterDetails = () => {
    toggleDetails(!showDetails);
  };
  const editLoanAmtbtnClick = () => {
    onInputChange('editLoanAmt', true, false);
  };

  const loanAmount = purchasePrice - depositAmount;

  if (upperCase(assetType) !== 'CAR') {
    return '';
  }

  return (
    <>
      <footer className="footer-content py-3">
        <Container className="footer-content-body">
          <Row className="footer-section">
            {!!selectedLender && (
              <Col className="display-logo repayment-container" xs={6} md={3}>
                <img
                  className="lender-logo"
                  src={getLenderLogo(selectedLender)}
                  alt={selectedLender}
                />
              </Col>
            )}
            <Col className="repayment-container" xs={6} md={3}>
              <NumberFormat
                displayType="text"
                value={loanAmount || '-'}
                thousandSeparator
                prefix="$"
                decimalScale={0}
                className="range-value"
              />
              <div className="label-color">Loan amount</div>
            </Col>
            <IconButton
              type="button"
              className={cls('toggle-details-action', showDetails && 'is-expanded')}
              onClick={toggleFooterDetails}
            >
              <MoreHorizIcon />
            </IconButton>
            <Col
              className={cls(
                'repayment-container controlled-show-section',
                showDetails && 'override-show',
              )}
              xs={6}
              md={4}
            >
              {!selectedLender ? (
                <div className="range-value">
                  <AnimatedNumber
                    value={formatNumberValue(minRepaymentVal)}
                    formatValue={formatRepaymentValue}
                  />
                  <span className="mx-2">-</span>
                  <AnimatedNumber
                    value={formatNumberValue(maxRepaymentVal)}
                    delay={100}
                    formatValue={formatRepaymentValue}
                  />
                </div>
              ) : (
                <div className="range-value">
                  <AnimatedNumber
                    value={formatNumberValue(monthlyRepayments)}
                    formatValue={formatRepaymentValue}
                  />
                </div>
              )}
              <div className="label-color">{`${freqVal} Repayments`}</div>
            </Col>
         
            {!selectedLender && (
              <Col
                className={cls('repayment-container', showDetails && 'override-show')}
                xs={3}
                md={3}
              />
            )}

            {currentTab !== 'car' && !hideEdit && !selectedLender && (
              <Col
                className={cls(
                  'align-center controlled-show-section',
                  showDetails && 'override-show',
                )}
                xs={6}
                md={2}
              >
                <Button onClick={editLoanAmtbtnClick} className="editloanamountbtn">
                  <img src={editImg} alt="edit" />
                  <span> Edit</span>
                </Button>
              </Col>
            )}

            {editLoanAmt && <EditLoanAmount />}
          </Row>
        </Container>
      </footer>
    </>
  );
};
export default connect(
  (state) => ({
    purchasePrice: state.car.purchasePrice,
    depositAmount: state.car.depositAmount,
    selectFreq: state.estimation.selectFreq,
    editLoanAmt: state.car.editLoanAmt,
    loanOptions: state.estimation.loanOptions,
    minRepaymentVal: state.estimation.minRepaymentVal,
    maxRepaymentVal: state.estimation.maxRepaymentVal,
    errorMessage: state.errorHandler.errorMessage,
    monthlyRepayments: state.estimation.monthlyRepayments,
    selectedLender: state.applicants.selectedLender,
    ValidationErrorFlag: state.errorHandler.ValidationErrorFlag,
    assetType: state.car.assetType,
  }),
  (dispatch) => ({
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('car', fieldName, value, scrollToNextFieldOnChage));
    },
    noErrors: () => dispatch(noErrors()),
    handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
  }),
)(Footer);
