import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Progress = (props) => {
  const { pending, completed } = props;
  return (
    <div className="addition-info-progress">
      {[...Array(completed).keys()].map((e) => {
        return <div className="completed" />;
      })}

      {[...Array(pending).keys()].map((e) => {
        return <div className="pending" />;
      })}
    </div>
  );
};

Progress.defaultProps = {
  pending: 0,
  completed: 0,
};

Progress.propTypes = {
  pending: PropTypes.number,
  completed: PropTypes.number,
};

export default Progress;
