/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Table, Form, Alert, Card } from 'react-bootstrap'
import { Grid } from '@material-ui/core';
import find from 'lodash/find';
import NumberFormat from 'react-number-format';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import round from 'lodash/round';
import unionBy from 'lodash/unionBy';
import _ from 'lodash';
import cls from 'classnames';
import { CircularProgress } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputButton from '../../../../components/InputButton';
import processGTMevent from '../../../../util/processGTMEvent';
import {
  DELETE_CONFIRM,
  AssetTypes,
  AssetTypesWithoutSaving,
  HOME_PROPERTY,
  INVESTMENT_PROPERTY,
  SAVINGS,
  ASSET_OTHER,
  HOMECONTENTS,
  SUPERANNUATION,
  ASSET_RELATED_LIABILITIES,
  LIABILITIES_LABEL_VALUE_MAP,
  MOTORVEHICLE,
  RECREATIONAL,
  LIABILITY_MORTGAGE_OPTION,
} from '../../../../util/constants';
import deleteImg from '../../../../../assets/image/delete.svg';
import rightnormal from '../../../../../assets/image/right-normal.svg';
import { getNameByValue } from '../../../../util/ContainerUtilities';
import { showAskPartnerPropDetails } from '../../../../lib/utils';
import './assetdetailsNew.scss';
import '../../finances-common.scss';
import ConfirmDeleteRow from '../../../../components/ConfirmDeleteRow';
import DropDownSelect from '../../../../components/DropDownSelect';
import { formatAmount } from '../../../../lib/formatter';
import { isAssetsValidation } from '../../../../util/helpers';
import NumberInput from '../../../../components/NumberInput';

export const getFilteredLiabilitiesByAsset = (liabilities, relatedAsset) => filter(liabilities, ['liabilityType', ASSET_RELATED_LIABILITIES[relatedAsset]]);

const AssetDetails = ({
  assets,
  pendingAssetSave,
  initLiabList,
  addAsset,
  updateAsset,
  deleteAsset,
  completeAssetDetails,
  validationCheck,
  ValidationErrorFlag,
  errorMessage,
  noErrors,
  residentialStatus,
  deleteAssetApi,
  reQuote,
  onLiteInputChange,
  getValidationErrorsForField,
  isSpousePropOwner,
  relationshipStatus,
  applicants,
  assetSubmitInprogress,
  assetSubmit,
  liabilities,
  assetsSave,
  retrieveOpportunity,
  addLiability
}) => {
  const [assetsList, updateList] = useState(assets || []);
  const [relatedLiabilities, setRelatedLiabilities] = useState([]);
  let showPartnerProperty;

  useEffect(() => {
    const filteredList = reduce(
      assetsList,
      (accum, stateAssets) => {
        const sourceIncome = find(assets, ['id', stateAssets.id]);
        if (sourceIncome) {
          accum.push({ ...stateAssets, ...sourceIncome, flagDelete: stateAssets.flagDelete });
        }
        return accum;
      },
      [],
    );

    updateList(unionBy(filteredList, assets, 'id'));

    const relatedAsset = assets.filter((item) => {
      return item.assetType === MOTORVEHICLE || item.assetType === HOME_PROPERTY || item.assetType === INVESTMENT_PROPERTY || item.assetType === RECREATIONAL
    })

    let relatedLiabilitiesArr = [];
    relatedAsset.forEach(({ assetType = '' }) => {
      let relatedLiabilities = getFilteredLiabilitiesByAsset(
        liabilities,
        assetType,
      );
      relatedLiabilities.length > 0 && relatedLiabilitiesArr.push({
        ...relatedLiabilities[0], assetType: assetType
      });
    });
    setRelatedLiabilities(relatedLiabilitiesArr)

    const foundHomePropertyAssets = assets.some(item => item.assetType === HOME_PROPERTY && item.currentlyFinanced === true);
    const foundHomePropertyLiability = liabilities.some(item => item.liabilityType === LIABILITY_MORTGAGE_OPTION);
    if (foundHomePropertyAssets && !foundHomePropertyLiability) {
      setTimeout(() => {
        addLiability({
          liabilityType: LIABILITY_MORTGAGE_OPTION,
          financier: "",
          outstanding: "",
          limit: "",
          repayments: "",
          relatedAsset: "",
          payout: false,
          frequency: "Weekly"
        })
      }, 100);
    }

  }, [assets]);

  useEffect(() => {
    const arrUpdateAssets = [...checkMatches(assetsList, relatedLiabilities)]
    updateList(arrUpdateAssets)
  }, [relatedLiabilities])

  const checkMatches = (arr1, arr2) => {
    return arr1.map(itemAssets => arr2.find(item => item.assetType === itemAssets.assetType) ? ({ ...itemAssets, relatable: true }) : ({ ...itemAssets, relatable: false }));
  }

  const flagToDelete = (id, flag = false) => () => {
    updateList(
      reduce(
        assetsList,
        (accum, option) => {
          const updatedOption = { ...option };
          if (option.id === id) {
            updatedOption.flagDelete = flag;
          }
          accum.push(updatedOption);
          return accum;
        },
        [],
      ),
    );
  };

  const assettotal = round(
    reduce(
      assets,
      (calTotal, assetData) =>
        calTotal +
        Number(assetData.brokerUpdated ? assetData.verifiedMonthlyAmount : assetData.estimateVal),
      0,
    ),
  );


  const handleAddAsset = () => {
    if (!isAssetsValidation(assets, updateAsset)) {
      addAsset({ assetType: "", currentlyFinanced: false, estimateVal: "", relatedLiability: "", underfinance: false });
    }
  };

  const handleDeleteAsset = (assetData) => () => {
    if (assetData?.id?.length > 10) {
      deleteAssetApi({ ...assetData })
        .then(() => {
          deleteAsset({ ...assetData });
        })
        .catch(() => {
          console.log('error');
        });
    }
    else {
      deleteAsset({ ...assetData });
    }
  };

  const handleAssetDetails = () => {
    processGTMevent('finances', 'totalassets', { value: assettotal });
    reQuote();
    const assetHomeproperty = _.some(assets, { assetType: HOME_PROPERTY });
    const investmentAssets = find(assets, ['assetType', INVESTMENT_PROPERTY]);
    const checkAmountval = _.some(assets, { estimateVal: '0.00' });
    const assetSaving = _.some(assets, { assetType: SAVINGS });

    if (!assetSaving) {
      validationCheck(`${getNameByValue(AssetTypes, SAVINGS)} is mandatory`);
      return false;
    }
    if (residentialStatus === 'PropertyOwner' && !assetHomeproperty) {
      validationCheck(
        `${getNameByValue(
          AssetTypes,
          HOME_PROPERTY,
        )} is mandatory due to your curent living Situation`,
      );
    } else if (checkAmountval) {
      validationCheck('Amount should be greater than zero');
    } else if (!checkAmountval) {

      if (!isAssetsValidation(assets, updateAsset)) {
        onLiteInputChange('assetSubmitInprogress', true, false);
        applicants()
          .then(() => {
            reQuote();
            noErrors();
            assetsSave().then(() => {
              completeAssetDetails();
              onLiteInputChange('assetSubmit', true, false);
              retrieveOpportunity().then(() => {
                onLiteInputChange('assetSubmitInprogress', false, false);
              })
                .catch(() => {
                  onLiteInputChange('assetSubmitInprogress', false, false);
                })
            })

          })
          .catch((error) => {
            console.log(error);
            onLiteInputChange('assetSubmitInprogress', false, false);
          });

      }
    }
  };

  const handleOnChangeCategory = (data, val) => {
    noErrors();
    showPartnerProperty = showAskPartnerPropDetails(residentialStatus, relationshipStatus, assets);

    if (isUnderfinanceNotApplicable(val)) {
      updateAsset({
        ...data,
        assetType: val,
        errorMsgAssets: '',
        currentlyFinanced: false,
      });
    }
    else {
      updateAsset({
        ...data,
        assetType: val,
        errorMsgAssets: ''
      });
    }
  }

  const handleOnChangeRelatedLiabiltiy = (data, val) => {
    updateAsset({
      ...data,
      relatedLiability: val,
      errorMsgRelatedLiability: ''
    });
  }

  const handleChange = (data, values) => {
    updateAsset({
      ...data,
      estimateVal: values.value,
      errorMsgEstmate: ''
    })
  }
  const handleCheckboxChange = (data, e) => {
    updateAsset({
      ...data,
      currentlyFinanced: e.target.checked,
    });
  }

  const isUnderfinanceNotApplicable = (assetCategory) =>
    assetCategory === SAVINGS ||
    assetCategory === HOMECONTENTS ||
    assetCategory === SUPERANNUATION ||
    assetCategory === ASSET_OTHER ||
    assetCategory === "";

  const relatedLiabilitiesOptions = relatedLiabilities.filter(liability => parseInt(liability.outstanding) > 0).map((item, idx) => {
    return {
      value: item.id,
      name: `${LIABILITIES_LABEL_VALUE_MAP[item.liabilityType]} - $${formatAmount(
        item.outstanding,
      )}`,
      selected: idx === 0,
    }
  })
  
  const smScreen = useMediaQuery('@media (max-width: 767.95px)');
  showPartnerProperty = showAskPartnerPropDetails(residentialStatus, relationshipStatus, assets);

  return (
    <Card className="content-body asset-details finances-details-container asset-details">
      <Card.Header className="col-12 page-header-container finances-details-header-container scrollable-anchor-tag with-sidebar">
        <a name="asset-details" href="#asset-details">
          &nbsp;
        </a>
        <h1 className="finances-details-header">Your assets details</h1>
        <div className="finances-details-description">
          We need your asset details to strengthen your borrowing profile
        </div>
      </Card.Header>

      <Card.Body className={cls('finances-body-container', !ValidationErrorFlag && 'pt-0')}>
        {ValidationErrorFlag && (
          <Alert variant="danger" className="error-alert">
            <p className="error-message">{errorMessage}</p>
          </Alert>
        )}
        <Table hover responsive className="finances-body-table">
          <tbody className="finances-card-table-body">
            {assetsList.map((task, index) => (
              <tr className="finances-card-table-row" key={task.id || task.defaultId}>
                <td colSpan={5}>
                  {!(task.assetType === SAVINGS || task.assetType === HOME_PROPERTY) && (<Grid item xs={12} md={12}>
                    {!task.flagDelete && (
                      <div className={cls(
                        'finances-card-table-detail-new',
                        'btn-delete'
                      )}>
                        <img
                          src={deleteImg}
                          alt="delete"
                          onClick={flagToDelete(task.id, true)}
                        />
                        <button
                          type="button"
                          className="px-0 btn btn-link finances-card-table-delete"
                          onClick={flagToDelete(task.id, true)}
                        >
                          Delete
                        </button>
                      </div>)}

                    <ConfirmDeleteRow
                      task={task}
                      deleteConfirmText={DELETE_CONFIRM}
                      onDelete={handleDeleteAsset}
                      flagToDelete={flagToDelete}
                      colSpan={5}
                    />
                  </Grid>)}

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <>
                        {(task.assetType === SAVINGS || task.assetType === HOME_PROPERTY) ? <DropDownSelect
                          fieldName="assetType"
                          value={task.assetType || ''}
                          label="Asset type"
                          errorMsg={task.errorMsgAssets}
                          onChange={(val) => handleOnChangeCategory(task, val)}
                          colxs="12"
                          colsm="6"
                          colmd="6"
                          isDisable={true}
                          options={
                            <>
                              <option value="">Select</option>
                              {AssetTypes?.map((state) => (
                                <option key={state.value} value={state.value}>
                                  {state.name}
                                </option>
                              ))}
                            </>
                          }
                        /> : <DropDownSelect
                          fieldName="assetType"
                          value={task.assetType || ''}
                          label="Asset type"
                          errorMsg={task.errorMsgAssets}
                          onChange={(val) => handleOnChangeCategory(task, val)}
                          colxs="12"
                          colsm="6"
                          colmd="6"
                          isDisable={task.isNotDelete}
                          options={
                            <>
                              <option value="">Select</option>
                              {AssetTypesWithoutSaving?.map((state) => (
                                <option key={state.value} value={state.value}>
                                  {state.name}
                                </option>
                              ))}
                            </>
                          }
                        />}
                        {!isUnderfinanceNotApplicable(task.assetType) && (<Form.Group className="redux-render-field" controlId="reduxformCheckbox">
                          <div className="redux-render-checkboxfield">
                            <label className={cls('checkbox-container finances-form-label')}>
                              {"Currently financed?"}
                              <input checked={task.currentlyFinanced} onChange={(val) => handleCheckboxChange(task, val)} type="checkbox" name="currentlyFinanced"
                                id="currentlyFinanced" />
                              <span className="checkmark ml-2  mt-1 " />
                            </label>
                          </div>
                        </Form.Group>)}
                      </>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <NumberInput name={"Estimated value"}
                        value={task.estimateVal}
                        onChange={(values) => handleChange(task, values)}
                        error={task.errorMsgEstmate} />
                    </Grid>

                    {(!!relatedLiabilitiesOptions.length && task.relatable) && (
                      <Grid item xs={12} md={4}>
                        <DropDownSelect
                          fieldName="relatedLiability"
                          value={task.relatedLiability || ''}
                          label="Related Liability"
                          errorMsg={task.errorMsgRelatedLiability}
                          onChange={(val) => handleOnChangeRelatedLiabiltiy(task, val)}
                          colxs="12"
                          colsm="6"
                          colmd="6"
                          options={
                            <>
                              <option value="">Select</option>
                              {relatedLiabilitiesOptions?.map((state) => (
                                <option key={state.value} value={state.value}>
                                  {state.name}
                                </option>
                              ))}
                            </>
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </td>
              </tr>
            ))}
            {assets.length > 0 && (
              <tr className="finances-card-table-row">
                <td className="finances-card-table-total label-font" colSpan={(smScreen && 2) || 1}>
                  Total assets
                </td>
                <td
                  className="finances-card-table-total finance-card-total-align"
                  colSpan={(smScreen && 2) || 1}
                >
                  <NumberFormat
                    displayType="text"
                    value={assettotal}
                    prefix="$"
                    thousandSeparator
                    decimalScale={2}
                  />
                </td>
              </tr>
            )}
          </tbody>
          {assets && assets.length !== 0 && (
            <button
              type="button"
              className=" btn btn-primary finances-details-button"
              onClick={handleAddAsset}
            >
              <span className="btn-income add-assets">+ Add asset</span>
            </button>
          )}
        </Table>
        {showPartnerProperty && (
          <InputButton
            label={
              <>
                <span>Does your partner own property?</span>
              </>
            }
            fields={[
              { name: 'Yes', value: true },
              { name: 'No', value: false },
            ]}
            onChange={onLiteInputChange}
            fieldName="isSpousePropOwner"
            formGroupClass="spouse-partner-owner"
            scrollToNextFieldOnChage
            value={isSpousePropOwner}
            getvalidationerrors={getValidationErrorsForField}
          />
        )}

        <div className=" container-footer py-3 px-3  border-top">
          <div className="col-12 d-flex justify-content-end px-0">
            <button
              type="button"
              className="btn btn-primary btn-next-page asset-next-section"
              disabled={
                assettotal <= 0 ||
                (showPartnerProperty && typeof isSpousePropOwner !== 'boolean') ||
                assetSubmitInprogress
              }
              hidden={initLiabList && !pendingAssetSave && assetSubmit}
              onClick={handleAssetDetails}
            >
              {assetSubmitInprogress ? (
                <CircularProgress color="inherit" size={24} thickness={2.6} />
              ) : (
                'Next'
              )}
              <span className="px-3">
                <img src={rightnormal} alt="right" className="right-icon" />
              </span>
            </button>
            <input
              type="button"
              id="btnsubmit"
              className="btn-dummy-page"
              style={{ visibility: 'hidden' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AssetDetails;
