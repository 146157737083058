/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import cls from 'classnames';
import BoxSkeletons from './Skeleton/BoxSkeletons';
import './input-button.scss';
import getErrorMessageRows from './FormControlFeedback';

const initLimit = 12;

const FindMakeFilter = ({
  id,
  label,
  fields,
  formGroupClass,
  btnGroupClass,
  btnClass,
  value,
  onChange,
  fieldName,
  getvalidationerrors,
  scrollToNextFieldOnChage,
  isFullScreen,
  isreturnObjectval,
  enableSearch,
  rootContainerClass,
  isLoading,
  loadmore,
  withSideNav,
  labelClass,
  filtermake,
  removeLeftMargin,
}) => {
  const [filterMake, setMakeFilter] = useState(filtermake || '');
  const [searchResults, setSearchResults] = useState([]);
  const [loadLimit, setLoadLimit] = useState(loadmore ? 11 : initLimit);
  useEffect(() => {
    // eslint-disable-next-line max-len
    const results = fields.filter((item) =>
      Object.keys(item).some((key) => item[key].toLowerCase().includes(filterMake.toLowerCase())),
    );
    setSearchResults(results);
  }, [fields, filterMake]);

  const carmakes = (filterMake ? searchResults : fields).slice(0, loadLimit);

  const errorMessageRows = getErrorMessageRows(getvalidationerrors, fieldName);

  const handleSelect = (selectedObject) => {
    if (isreturnObjectval) {
      onChange(fieldName, selectedObject.value, scrollToNextFieldOnChage);
    } else {
      onChange(fieldName, selectedObject, scrollToNextFieldOnChage);
    }
  };

  const loading = isLoading || !fields || (fields && !fields.length);
  return (
    <Form.Row
      className={cls('grouped-list-container', 'scrollable-anchor-tag', rootContainerClass)}
    >
      <a name={id} href={`#${id}`}>
        &nbsp;
      </a>
      <FormGroup
        as={Col}
        md={isFullScreen ? 10 : 12}
        lg={isFullScreen ? 9 : 12}
        className={cls(withSideNav && 'pl-0 mb-0')}
      >
        <Form.Label className={cls('select-field-label', labelClass)}>{label}</Form.Label>
        {!loading && enableSearch && (
          <InputGroup
            className={cls(
              'select-label',
              withSideNav && 'wide-side-nav',
              carmakes && carmakes.length >= 3 && 'label-search-largecontainer',
              carmakes && carmakes.length > 2 && 'label-search-mediumcontainer',
            )}
            style={{ marginLeft: removeLeftMargin && '0' }}
          >
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">
                <span className="oi oi-magnifying-glass input-group-text maginifying-btn p-0" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              name="searchVehicleMake"
              type="text"
              placeholder="Start typing..."
              aria-describedby="inputGroupPrepend"
              value={filterMake}
              onChange={(ev) => {
                setMakeFilter(ev.target.value);
                ev.preventDefault();
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />
          </InputGroup>
        )}
      </FormGroup>

      <FormGroup
        name={fieldName}
        as={Col}
        xs={12}
        md={isFullScreen ? 10 : 12}
        lg={isFullScreen ? 9 : 12}
        className={cls('select-field-container', formGroupClass)}
      >
        {/* <Form.Label className={cls('select-field-label', labelClass)}>{label}</Form.Label> */}
        {errorMessageRows}
        <div
          className={cls(
            'select-field-section',
            carmakes && carmakes.length >= 3 && 'select-field-largecontainer',
            carmakes && carmakes.length > 2 && 'select-field-mediumcontainer',
          )}
        >
          {loading && (
            <BoxSkeletons
              btnGroupClass={btnGroupClass}
              btnClass={`vehicle-make-select ${btnClass}`}
            />
          )}
          {!loading &&
            carmakes.map((radioField) => (
              /*   const backgrdImg = radioField.imgSrc
              || `https://assets.stratton.com.au/quickapp/${radioField.value
                .replace(/\s|-/g, '')
                .toLowerCase()}.svg`; */
              <ButtonGroup
                key={radioField.name}
                className={cls('select-field-group', 'pr-3', 'pb-3', btnGroupClass)}
                size="lg"
              >
                <Button
                  name={radioField.name}
                  onClick={() => handleSelect(radioField)}
                  className={cls(
                    'select-field',
                    'vehicle-make-select',
                    radioField.value === value && 'is-selected',
                    btnClass,
                    radioField.name &&
                      radioField.name.indexOf(' ') === -1 &&
                      radioField.value.length > 7 &&
                      'lengthy-label',
                  )}
                >
                  <span
                    value={radioField.value}
                    src={radioField.iconSrc}
                    className="vehicle-make btn-icon"
                  />
                  {radioField.name}
                </Button>
              </ButtonGroup>
            ))}

          {!loading &&
            loadmore &&
            searchResults.length > initLimit &&
            searchResults.length !== (carmakes && carmakes.length) && (
              <ButtonGroup
                className={cls('select-field-group', 'pr-3', 'pb-3', btnGroupClass)}
                size="lg"
              >
                <Button
                  name={loadLimit >= fields.length ? 'Load More' : null}
                  onClick={() => setLoadLimit(fields.length)}
                  className={cls('select-field', 'vehicle-make-select', btnClass, 'load-others')}
                >
                  {fields.length >= loadLimit ? 'Load More' : null}
                </Button>
              </ButtonGroup>
            )}
        </div>
      </FormGroup>
    </Form.Row>
  );
};

FindMakeFilter.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default FindMakeFilter;
