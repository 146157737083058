import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import cls from 'classnames';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import SearchIcon from '../../assets/image/Search-address.svg';
import { BASIC_AUTO_COMPLETE_SUGGESTIONS_COUNT } from '../util/constants';
import './basic-auto-complete.scss';

const renderInput = (props) => {
  const {
    label,
    fieldName,
    placeholder,
    value,
    onClear,
    columnCount = 3,
    getvalidationerrors,
    ...restProps
  } = props;
  const isInvalid = false;

  return (
    <div className={`content-prepend col-12 px-0`}>
      <Form.Label>{label}</Form.Label>
      <InputGroup className="basic-auto-complete-input-search">
        <InputGroup.Prepend className={`${fieldName}-input-prepend`}>
          <InputGroup.Text
            id={fieldName}
            className={cls(`${fieldName}-input-text`, isInvalid ? 'is-invalid' : '')}
          >
            <img className=" p-0" src={SearchIcon} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          {...restProps}
          className="input-root auto-suggest-input"
          name={fieldName}
          value={value}
          placeholder={placeholder}
          margin="none"
          isInvalid={isInvalid}
        />
        <InputGroup.Append className="input-clear-group">
          <Button
            type="button"
            variant="link"
            className={cls('clear-action', isInvalid ? 'is-invalid' : '')}
            onClick={onClear}
          >
            <span className="oi oi-circle-x" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {/* {errorMessageRows} */}
    </div>
  );
};
const renderSuggestionsContainer = (options) => {
  const { containerProps, children } = options;
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
};
const styles = (theme) => ({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  suggestionsContainerOpen: {
    marginTop: '5px',
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
    fontSize: '1rem',
    boxSizing: 'border-box',
    minHeight: '48px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '200',
    lineHeight: '2',
    padding: '6px 0px 6px 10px ',
    whiteSpace: 'nowrap',
    letterSpacing: '0.00938em',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    cursor: 'pointer',
  },
  underline: {
    '&:before': {
      height: 2,
    },
  },
});

const BasicAutoComplete = (props) => {
  const {
    classes,
    columnCount,
    fieldName,
    value,
    placeholder,
    onChange,
    clearField = null,
    data = [],
    suggestionsCount = BASIC_AUTO_COMPLETE_SUGGESTIONS_COUNT,
  } = props;
  const escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const getSuggestions = (value) => {
    const escapedValue = escapeRegexCharacters(value.trim());
    const regex = new RegExp('^' + escapedValue, 'i');
    return data
      .filter((item) => regex.test(item.value))
      .slice(0, value === '' ? 3 : suggestionsCount);
  };

  const getSuggestionValue = (suggestion) => suggestion.value;

  const renderSuggestion = (suggestion) => <span>{suggestion.value}</span>;

  const [fieldValue, setFieldValue] = useState(value || '');
  const [suggestions, setSuggestions] = useState([]);

  const onChangeFn = (event, { newValue, method }) => {
    setFieldValue(newValue);
  };
  const resetAutoSuggest = () => {
    clearField();
    setFieldValue('');
    setSuggestions([]);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    if (onChange) {
      onChange(fieldName, suggestion.name);
    }
  };
  const inputProps = {
    fieldName,
    placeholder,
    value: fieldValue,
    onChange: onChangeFn,
    onClear: resetAutoSuggest,
  };
  const shouldRenderSuggestions = () => {
    return true;
  };

  return (
    <div
      className={cls(
        `form-group col-md-${columnCount}`,
        `row ${fieldName}-search-container px-0 ${fieldName}-auto-suggest-container`,
      )}
    >
      <Autosuggest
        theme={{
          container: `${classes.container} col-12`,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={renderInput}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        shouldRenderSuggestions={shouldRenderSuggestions}
        renderSuggestionsContainer={renderSuggestionsContainer}
        onSuggestionSelected={onSuggestionSelected}
      />
    </div>
  );
};

BasicAutoComplete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default withStyles(styles)(BasicAutoComplete);
