/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { API } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Autosuggest from 'react-autosuggest';
import cls from 'classnames';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { MenuItem, Paper } from '@material-ui/core';
import SearchIcon from '../../assets/image/Search-address.svg';
import getErrorMessageRows from './FormControlFeedback';
import './address-auto-complete.scss';

import { API_NAMES } from '../../awsconfig';
import apiUris from '../../apiUris';
import * as log from '../util/log';

function renderInput(inputProps) {
  const { outerProps, value, onClear, ...restProps } = inputProps;

  const errorMessageRows = getErrorMessageRows(
    outerProps.getvalidationerrors,
    outerProps.fieldName,
  );
  const isInvalid = errorMessageRows && errorMessageRows.length > 0;

  return (
    <div className="content-prepend col-12 px-0">
      <Form.Label>{outerProps.label}</Form.Label>
      <InputGroup className="address-input-search">
        <InputGroup.Prepend className="address-input-prepend">
          <InputGroup.Text
            id="address"
            className={cls('address-input-text', isInvalid ? 'is-invalid' : '')}
          >
            <img className=" p-0" src={SearchIcon} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          {...restProps}
          className="input-root auto-suggest-input"
          name={outerProps.fieldName}
          value={value}
          placeholder={outerProps.placeholder}
          margin="none"
          isInvalid={isInvalid}
        />
        <InputGroup.Append>
          <Button
            type="button"
            variant="link"
            className={cls('clear-action', isInvalid ? 'is-invalid' : '')}
            onClick={onClear}
          >
            <span className="oi oi-circle-x" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {errorMessageRows}
    </div>
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const suggestionText = suggestion.suggestion;

  const matches = match(suggestionText, query);
  const parts = parse(suggestionText, matches);

  const suggestionElement = parts.map((part, index) =>
    part.highlight ? (
      <span key={String(index)} style={{ fontWeight: 300 }}>
        {part.text}
      </span>
    ) : (
      <strong key={String(index)} style={{ fontWeight: 500 }}>
        {part.text}
      </strong>
    ),
  );

  return (
    <MenuItem className="suggestion-text-container" selected={isHighlighted} component="div">
      <div className="suggestion-text">{suggestionElement}</div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}
const styles = (theme) => ({
  container: {
    flexGrow: 1,
    padding: 0,
    // position: 'relative',
    // zIndex: 999,
  },
  suggestionsContainerOpen: {
    // position: 'absolute',
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  underline: {
    '&:before': {
      height: 2,
    },
  },
});

function getSuggestionValue(suggestion) {
  return suggestion.suggestion;
}

const fetchAddressSuggestions = (query) => {
  const myInit = {
    response: true,
    queryStringParameters: {
      query,
    },
    headers: {},
    timeout: 1000 * 10,
  };
  return API.get(API_NAMES.ADDRESS, apiUris.ADDRESSAUTOCOMPLETE, myInit)
    .then((response) => {
      // Add your code here
      log.info(`api call successfull ${API_NAMES.ADDRESS}, query: ${query}`);
      return Promise.resolve(response);
    })

    .catch((error) => {
      log.error(`api call failed ${API_NAMES.ADDRESS}. query: ${query}`, error);
      return Promise.reject(error.message);
    });
};
export const retrieveAddress = (recordId) => {
  const myInit = {
    response: true,
    queryStringParameters: {
      id: recordId,
    },
    timeout: 1000 * 10,
    headers: {},
  };
  return API.get(API_NAMES.FORMATADDRESS, apiUris.RETRIEVEADDRESS, myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.FORMATADDRESS}, id: ${recordId}`);
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.FORMATADDRESS}. id: ${recordId}`, error);
      return Promise.reject(error.message);
    });
};

class AddressAutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || '',
      suggestions: [],
    };

    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetAutoSuggest = this.resetAutoSuggest.bind(this);
  }

  handleChange(e, { newValue, method }) {
    e.preventDefault();

    if (method === 'escape') {
      this.resetAutoSuggest();
    } else {
      this.setState({
        value: newValue,
      });
    }
  }

  onSuggestionsFetchRequested({ value }) {
    fetchAddressSuggestions(value).then((matches) => {
      const suggestions = (matches && matches.data) || [];
      this.setState({ suggestions });
    });
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  onSuggestionSelected(event, { suggestion }) {
    event.preventDefault();
    if (this.props.onChange) {
      this.props.onChange(
        this.props.fieldName,
        suggestion.suggestion,
        this.props.scrollToNextFieldOnChage,
      );
    }

    retrieveAddress(suggestion.formatId).then((addressRetrived) => {
      const formattedAddress = (addressRetrived && addressRetrived.data) || null;
      if (formattedAddress && this.props.onAddressSelection) {
        this.props.onAddressSelection({ ...formattedAddress, postcode: formattedAddress.postCode });
      }
    });
  }

  resetAutoSuggest() {
    this.props.clearAddress();
    this.setState({
      value: '',
      suggestions: [],
    });
  }

  render() {
    const { classes, columnCount, addressClass } = this.props;
    const { suggestions, value } = this.state;

    return (
      // <div className="row address-search-container  px-0 col-md-5 col-12 address-auto-suggest-container">
      <div
        className={cls(
          `form-group col-md-${columnCount} col-12`,
          addressClass && addressClass,
          'row address-search-container  px-0 address-auto-suggest-container',
        )}
      >
        <Autosuggest
          theme={{
            container: `${classes.container} col-12`,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={renderInput}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          shouldRenderSuggestions={(keyword) => keyword.trim().length > 4}
          inputProps={{
            classes,
            placeholder: 'Search address',
            value,
            onChange: this.handleChange,
            outerProps: { ...this.props },
            onBlur: this.handleBlur,
            onClear: this.resetAutoSuggest,
          }}
        />
      </div>
    );
  }
}

AddressAutoComplete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressAutoComplete);
