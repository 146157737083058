/* eslint-disable import/no-cycle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { CircularProgress } from '@material-ui/core';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';

import { abnNumberSearch } from '../../../Actions/finances';
import TextField from '../../../components/TextField';
import risk from '../../../../assets/image/risk.svg';

const ABNlookUpDetails = ({
  fieldName,
  abnRespDetails,
  defaultEntityType,
  defaultEntityName,
  defaultAbnStatus,
  defaultGstRegistrationstatus,
  defaultAbnNumber,
  handleInvalidAbn,
  handleInvalidAbnSearch,
  handleSearchAbn,
  isdisabled,
}) => {
  const dispatch = useDispatch();
  const [{ entityType, entityName, abnStatus, gstRegistrationstatus }, setAbnDetails] = useState({
    entityType: defaultEntityType,
    entityName: defaultEntityName,
    abnStatus: defaultAbnStatus,
    gstRegistrationstatus: defaultGstRegistrationstatus,
  });
  const [abnNumber, setAbnNumber] = useState(defaultAbnNumber);
  const [abnSearchInProgress, setAbnSearchInProgress] = useState(false);
  const [searchAbn, setSearchAbn] = useState(!!defaultAbnNumber);
  const [serverError, setServerError] = useState(false);
  const [invalidAbn, setInvalidAbn] = useState(false);
  const [invalidAbnSearch, setInvalidAbnSearch] = useState(false);

  useEffect(() => {
    if (handleInvalidAbn) {
      handleInvalidAbn(invalidAbn);
    }
    if (handleInvalidAbnSearch) {
      handleInvalidAbnSearch(invalidAbnSearch);
    }
    if (handleSearchAbn) {
      handleSearchAbn(searchAbn);
    }
  }, [invalidAbn, invalidAbnSearch, searchAbn]);

  const onChange = (_abnFieldName, value) => {
    if (!value || value.length < 11) setSearchAbn(false);
    setAbnNumber(value);
  };

  const searchAbnnumber = () => {
    setSearchAbn(false);
    if (abnNumber && abnNumber.length !== 11) {
      setInvalidAbn(true);
      return;
    }
    setAbnSearchInProgress(true);
    setInvalidAbn(false);
    dispatch(abnNumberSearch(abnNumber))
      .then((resp) => {
        setAbnSearchInProgress(false);
        const formattedAbnData = {
          ...resp.data.abnDetails,
          abnNumber,
          abn: abnNumber,
          gstRegistrationdate: resp.data.abnDetails?.gstRegisteredDate,
          abnRegistrationDate: resp.data.abnDetails?.abnRegisteredDate,
          gstRegistrationstatus: resp.data.abnDetails?.gstStatus,
        };
        setAbnDetails(formattedAbnData);
        abnRespDetails(fieldName, formattedAbnData);
        setInvalidAbnSearch(false);
        setServerError(false);
        setSearchAbn(true);
      })
      .catch((error) => {
        setAbnSearchInProgress(false);
        if (error.response && error.response.status === 404) {
          setServerError(false);
          setInvalidAbnSearch(true);
          setSearchAbn(false);
        } else {
          setSearchAbn(false);
          setServerError(true);
        }
      });
  };

  return (
    <>
      <div className="abn-number">
        <TextField
          fieldName={`${fieldName || 'abnNumber'}`}
          value={abnNumber}
          className="px-0"
          label="Enter your ABN number"
          placeholder="e.g 123 456 789"
          colmd={6}
          getvalidationerrors={() => []}
          onChange={onChange}
          updateOnChange
          inline
          isdisabled={isdisabled}
          required="*"
        />
        <Button
          className="abn-serach-btn"
          disabled={
            !abnNumber ||
            (abnNumber && abnNumber.length !== 11) ||
            searchAbn ||
            abnSearchInProgress ||
            isdisabled
          }
          onClick={searchAbnnumber}
        >
          {abnSearchInProgress ? (
            <CircularProgress color="inherit" size={24} thickness={2.6} />
          ) : (
            'Search'
          )}
        </Button>
      </div>

      {searchAbn && !serverError && (
        <Table bordered className="table-container">
          <tbody className="table-body">
            <tr className="table-row">
              <td className="table-detail">Entity name</td>
              <td className="table-detail-val">{entityName}</td>
            </tr>
            <tr className="table-row">
              <td className="table-detail">ABN status</td>
              <td className="table-detail-val">{abnStatus}</td>
            </tr>
            <tr className="table-row">
              <td className="table-detail">Entity type</td>
              <td className="table-detail-val">{entityType}</td>
            </tr>
            <tr className="table-row">
              <td className="table-detail">Goods & services tax(GST)</td>
              <td className="table-detail-val">{gstRegistrationstatus}</td>
            </tr>
          </tbody>
        </Table>
      )}

      {invalidAbn && (
        <Alert variant="danger" className="error-alert">
          <img src={risk} className="risk-img" alt="risk-img" />
          <p className="error-message">
            ABN has 11 digits and an ACN has 9. The search text entered has {abnNumber.length}{' '}
            digits. You may need to verify and re-enter the search text.
          </p>
        </Alert>
      )}
      {invalidAbnSearch && (
        <Alert variant="danger" className="error-alert">
          <img src={risk} className="risk-img" alt="risk-img" />
          <p className="error-message">That ABN appears to be incorrect, please try again</p>
        </Alert>
      )}
      {serverError && (
        <Alert variant="danger" className="error-alert">
          <img src={risk} className="risk-img" alt="risk-img" />
          <p className="error-message">
            We could not find your ABN at this time, please try again soon.
          </p>
        </Alert>
      )}
    </>
  );
};

export default ABNlookUpDetails;
