/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import ApplicantDetails from './ApplicantDetails';
import LivingDetails from './LivingDetails';
// import PrivacyAndPolicy from './PrivacyAndPolicy';
import IdentityDetails from './IdentityDetails';
import CreditInfo from './CreditInfo';
import LivingArrangement from './LivingArrangement';
import EmploymentDetails from './EmploymentDetails';
import PersonalDetailsVerification from './PersonalDetailsVerification';
// import PasswordCreation from './PasswordCreation';
import EmailPasswordCreation from './EmailPasswordCreation';

export const HASH_PATHS = {
  applicantsDetails: 'personal-details',
  passwordCreation: 'password-creation',
  identityDetails: 'drivers-license',
  livingDetails: 'address-details',
  livingArrangements: 'living-arrangements',
  employmentDetails: 'employment',
  incomeDetails: 'income-details',
  assetDetails: 'asset-details',
  liabilityDetails: 'liability-details',
  expenseDetails: 'expense-details',
  policyConfirmation: 'policy-confirmation',
};

const PersonalDetailsContainer = ({
  applicantDetails,
  // submitsuccess,
  identityDetails,
  livingDetails,
  verificationComplete,
  livingArrangement,
  employmentDetails,
  PersonalDetails,
  empdetailscomplete,
  passwordCreated,
  isAuthenticated,
  isExistingUser,
  forceNewApplication,
  personalDetailsContextpage,
  opportunityId,
  ...restProps
}) => {
  const applicantsDetailsVisible =
    (!forceNewApplication && !isAuthenticated) ||
    (personalDetailsContextpage && !forceNewApplication);

  useEffect(() => {
    window.location.hash = `#${
      applicantsDetailsVisible ? HASH_PATHS.applicantsDetails : HASH_PATHS.identityDetails
    }`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-section">
      <div className="sub-container-section tab-container" id="personalDetails">
        {/* {applicantsDetailsVisible && (
         <ApplicantDetails
            applicantDetails={applicantDetails}
            isAuthenticated={isAuthenticated}
            isExistingUser={isExistingUser}
            forceNewApplication={forceNewApplication}
            hashPath={HASH_PATHS.applicantsDetails}
            nextHashPath={HASH_PATHS.passwordCreation}
            {...restProps}
          />
        )} */}

        {!isAuthenticated && !forceNewApplication && (
          <div className="question-section">
            <EmailPasswordCreation
              passwordCreated={passwordCreated}
              {...restProps}
              hashPath={HASH_PATHS.passwordCreation}
              nextHashPath={HASH_PATHS.identityDetails}
            />
          </div>
        )}

        {(passwordCreated || isAuthenticated || forceNewApplication) && (
          <IdentityDetails
            identityDetails={identityDetails}
            {...restProps}
            hashPath={HASH_PATHS.identityDetails}
            opportunityId={opportunityId}
            nextHashPath={HASH_PATHS.livingDetails}
          />
        )}
        {(identityDetails ||
          localStorage.getItem(`pageCompleted${opportunityId}`) === 'drivers-Licence') && (
          <div className="question-section">
            <LivingDetails
              livingDetails={livingDetails}
              opportunityId={opportunityId}
              {...restProps}
              hashPath={HASH_PATHS.livingDetails}
              nextHashPath={HASH_PATHS.livingArrangements}
            />
          </div>
        )}
        {(livingDetails ||
          localStorage.getItem(`pageCompleted${opportunityId}`) === 'address-details') && (
          <div className="question-section">
            <LivingArrangement
              employmentDetails={employmentDetails}
              opportunityId={opportunityId}
              {...restProps}
              hashPath={HASH_PATHS.livingArrangements}
              nextHashPath={HASH_PATHS.employmentDetails}
            />
          </div>
        )}
        {(employmentDetails ||
          localStorage.getItem(`pageCompleted${opportunityId}`) === 'dependent-details') && (
          <div className="question-section">
            <EmploymentDetails
              opportunityId={opportunityId}
              {...restProps}
              empdetailscomplete={empdetailscomplete}
              hashPath={HASH_PATHS.employmentDetails}
            />
          </div>
        )}
        {/*         {empdetailscomplete && <CreditInfo {...restProps}  />} */}
      </div>
    </div>
  );
};
export default PersonalDetailsContainer;
