// import applicants from '../Reducers/applicants';
import { API } from 'aws-amplify';
import { API_NAMES } from '../../awsconfig';
import apiUris from '../../apiUris';
import fieldChanged, { liteFieldChanged } from './FieldChanged';
import { validationErrors } from './errorHandler';
import * as log from '../util/log';
import { APPLICANT_TYPE } from '../util/constants';

export const ADD_INCOME_APPLICANTS = 'ADD_INCOME_APPLICANTS';
export const UPDATE_INCOME_APPLICANTS = 'UPDATE_INCOME_APPLICANTS';
export const DELETE_INCOME_APPLICANTS = 'DELETE_INCOME_APPLICANTS';
export const ADD_SPOUSEINCOME_APPLICANTS = 'ADD_SPOUSEINCOME_APPLICANTS';
export const UPDATE_SPOUSEINCOME_APPLICANTS = 'UPDATE_SPOUSEINCOME_APPLICANTS';
export const DELETE_SPOUSEINCOME_APPLICANTS = 'DELETE_SPOUSEINCOME_APPLICANTS';
export const INIT_ASSET_APPLICANTS = 'INIT_ASSET_APPLICANTS';
export const INIT_INCOME_APPLICANTS = 'INIT_INCOME_APPLICANTS';
export const ADD_ASSET_APPLICANTS = 'ADD_ASSET_APPLICANTS';
export const UPDATE_ASSET_APPLICANTS = 'UPDATE_ASSET_APPLICANTS';
export const DELETE_ASSET_APPLICANTS = 'DELETE_ASSET_APPLICANTS';
export const INIT_LIAB_APPLICANTS = 'INIT_LIAB_APPLICANTS';
export const ADD_LIAB_APPLICANTS = 'ADD_LIAB_APPLICANTS';
export const UPDATE_LIAB_APPLICANTS = 'UPDATE_LIAB_APPLICANTS';
export const DELETE_LIAB_APPLICANTS = 'DELETE_LIAB_APPLICANTS';
export const INIT_EXPENSE_APPLICANTS = 'INIT_EXPENSE_APPLICANTS';
export const PREV_FINANCE_APPLICANTS = 'PREV_FINANCE_APPLICANTS';
export const INIT_POLICY_APPLICANTS = 'INIT_POLICY_APPLICANTS';
export const ADD_EXP_APPLICANTS = 'ADD_EXP_APPLICANTS';
export const UPDATE_EXP_APPLICANTS = 'UPDATE_EXP_APPLICANTS';
export const DELETE_EXP_APPLICANTS = 'DELETE_EXP_APPLICANTS';
export const FORESEE_SELECTED_APPLICANTS = 'FORESEE_SELECTED_APPLICANTS';
export const EXP_SEC_COMPLETE_APPLICANTS = 'EXP_SEC_COMPLETE_APPLICANTS';
export const TERMSNCONDITION_CHECKED_APPLICANTS = 'TERMSNCONDITION_CHECKED_APPLICANTS';
export const UPLOAD_IDENTITY_APPLICANTS = 'UPLOAD_IDENTITY_APPLICANTS';
export const UPLOAD_INCOMEDOC_APPLICANTS = 'UPLOAD_INCOMEDOC_APPLICANTS';
export const ADD_ASSETID = 'ADD_ASSETID_APPLICANTS';
export const ADD_LIABID = 'ADD_LIABID_APPLICANTS';
export const ADD_EXPID = 'ADD_EXPID_APPLICANTS';
export const ADD_INCOMEID = 'ADD_INCOMEID_APPLICANTS';
export const ADD_SPOUSEINCOMEID = 'ADD_SPOUSEINCOMEID_APPLICANTS';
export const POPULATE_ABN_DETAILS = 'POPULATE_ABN_DETAILS_APPLICANTS';
export const RESET_EMPLOYMENT_DATA = 'RESET_EMPLOYMENT_DATA_APPLICANTS';

export const resetEmploymentSection = () => ({
  type: RESET_EMPLOYMENT_DATA,
});
export function addIncome(incomeData) {
  return { type: ADD_INCOME_APPLICANTS, payload: incomeData };
}

export function updateIncome(incomeData) {
  return { type: UPDATE_INCOME_APPLICANTS, payload: incomeData };
}

export function deleteIncome(incomeData) {
  return { type: DELETE_INCOME_APPLICANTS, payload: incomeData };
}
export function addIncomeId(incomeid) {
  return { type: ADD_INCOMEID, payload: incomeid };
}

export function addSpouseIncome(spouseIncomeData) {
  return { type: ADD_SPOUSEINCOME_APPLICANTS, payload: spouseIncomeData };
}

export function updateSpouseIncome(spouseIncomeData) {
  return { type: UPDATE_SPOUSEINCOME_APPLICANTS, payload: spouseIncomeData };
}

export function deleteSpouseIncome(spouseIncomeData) {
  return { type: DELETE_SPOUSEINCOME_APPLICANTS, payload: spouseIncomeData };
}
export function addSpouseIncomeId(spouseincomeid) {
  return { type: ADD_SPOUSEINCOMEID, payload: spouseincomeid };
}

export function uploadIdentityDoc(document) {
  return { type: UPLOAD_IDENTITY_APPLICANTS, payload: document };
}
export function uploadIncomeDoc(document) {
  return { type: UPLOAD_INCOMEDOC_APPLICANTS, payload: document };
}

export const initialiseIncomeDetails = () => (dispatch, getState) => {
  const {
    applicants: { relationship },
  } = getState();
  dispatch({ type: INIT_INCOME_APPLICANTS, payload: { relationship } });
};
export const completeIncomeDetails = () => (dispatch, getState) => {
  const {
    applicants: { residentialStatus },
  } = getState();
  dispatch({ type: INIT_ASSET_APPLICANTS, payload: { residentialStatus } });
};

export const completeAssetDetails = () => (dispatch, getState) => {
  const {
    applicants: { residentialStatus },
  } = getState();
  dispatch({ type: INIT_LIAB_APPLICANTS, payload: { residentialStatus } });
};
export const completeLiabDetails = () => (dispatch, getState) => {
  const {
    applicants: { residentialStatus },
  } = getState();
  dispatch({ type: INIT_EXPENSE_APPLICANTS, payload: { residentialStatus } });
};

export function completeExpenseDetails() {
  return { type: INIT_POLICY_APPLICANTS };
}
export function completeExpenseSection() {
  return { type: EXP_SEC_COMPLETE_APPLICANTS };
}
export function foreseeChanges(fieldName, value) {
  return { type: FORESEE_SELECTED_APPLICANTS, payload: value };
}
export function checkboxChange(value) {
  return { type: TERMSNCONDITION_CHECKED_APPLICANTS, payload: value };
}
export function prevFinanceLiab(fieldName, value) {
  return { type: PREV_FINANCE_APPLICANTS, payload: value };
}
export function addAsset(assetData) {
  return { type: ADD_ASSET_APPLICANTS, payload: assetData };
}
export function addAssetId(assetId) {
  return { type: ADD_ASSETID, payload: assetId };
}
export function updateAsset(assetData) {
  return { type: UPDATE_ASSET_APPLICANTS, payload: assetData };
}

export function deleteAsset(assetData) {
  return { type: DELETE_ASSET_APPLICANTS, payload: assetData };
}
export function addLiability(liabilityData) {
  return { type: ADD_LIAB_APPLICANTS, payload: liabilityData };
}

export function updateLiability(liabilityData) {
  return { type: UPDATE_LIAB_APPLICANTS, payload: liabilityData };
}

export function deleteLiability(liabilityData) {
  return { type: DELETE_LIAB_APPLICANTS, payload: liabilityData };
}
export function addLiabId(liabId) {
  return { type: ADD_LIABID, payload: liabId };
}
export function addExpense(expenseData) {
  return { type: ADD_EXP_APPLICANTS, payload: expenseData };
}

export function updateExpense(expenseData) {
  return { type: UPDATE_EXP_APPLICANTS, payload: expenseData };
}

export function deleteExpense(expenseData) {
  return { type: DELETE_EXP_APPLICANTS, payload: expenseData };
}
export function addExpId(expId) {
  return { type: ADD_EXPID, payload: expId };
}
export const income = () => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId, income } = state.applicants;

  let arrIncome = []

  income.forEach(({ category, amount, frequency, applicantEmployerName, employmentId, id = '' }) => {
    arrIncome.push({
      id: `${id}`,
      incomeOf: 'Primary Borrower',
      employmentId: employmentId || '',
      applicantEmployerName: applicantEmployerName || '',
      incomeType: category,
      amount: amount,
      frequency: frequency,
    })
  });

  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Primary',
        income: arrIncome
      },
    ],
    headers: {},
  };
  return API.post(
    API_NAMES.OPP_INCOME_CREATE,
    apiUris.GET_INCOME(opportunityId, applicantId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_INCOME_CREATE}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'incomeid', response.data[0].id, false));
      // dispatch(addIncomeId(response.data));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_INCOME_CREATE}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const incomePrimary = (incomeData) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;
  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Primary',
        income: [{
          id: incomeData.id ? `${incomeData.id}` : '',
          incomeOf: 'Primary Borrower',
          employmentId: incomeData.employmentId || '',
          applicantEmployerName: incomeData.applicantEmployerName || '',
          incomeType: incomeData.category,
          amount: incomeData.amount,
          frequency: incomeData.frequency,
        }]
      },
    ],
    headers: {},
  };
  return API.post(
    API_NAMES.OPP_INCOME_CREATE,
    apiUris.GET_INCOME(opportunityId, applicantId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_INCOME_CREATE}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'incomeid', response.data[0].id, false));
      // dispatch(addIncomeId(response.data));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_INCOME_CREATE}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const deleteGeneric = (deleteincomelist) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;
  const myInit = {
    response: true,
    body: {
      id: deleteincomelist.id || null,
    },

    headers: {},
  };
  return API.post(
    API_NAMES.DELETE_GENERIC,
    apiUris.DELETE_GENERIC(opportunityId, applicantId),
    myInit,
  )
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.DELETE_GENERIC}. opportunityId: ${opportunityId}`);
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.DELETE_GENERIC}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const coApplicantdeleteApi = (deleteincomelist) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, spouseCoApplicantId } = state.applicants;
  const myInit = {
    response: true,
    body: {
      id: deleteincomelist.id || null,
    },
    headers: {},
  };

  return API.post(
    API_NAMES.DELETE_GENERIC,
    apiUris.DELETE_GENERIC(opportunityId, spouseCoApplicantId),
    myInit,
  )
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.DELETE_GENERIC}. opportunityId: ${opportunityId}`);
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.DELETE_GENERIC}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const coApplicantIncome = () => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, spouseCoApplicantId, spouseincome } = state.applicants;

  let arrSpouseIncome = []

  spouseincome.forEach(({ category, amount, frequency, applicantEmployerName, employmentId, id = '' }) => {
    arrSpouseIncome.push({
      id: `${id}`,
      incomeType: category,
      employmentId: employmentId || '',
      applicantEmployerName: applicantEmployerName || '',
      amount: amount,
      frequency: frequency,
    })
  });


  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Spouse',
        income: arrSpouseIncome,
      },
    ],
    headers: {},
  };
  return API.post(
    API_NAMES.CO_APPLICANT_INCOME,
    apiUris.GET_CO_APPLICANT_INCOME(opportunityId, spouseCoApplicantId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.CO_APPLICANT_INCOME}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'coApplicantIncomeid', response.data[0].id, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.CO_APPLICANT_INCOME}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const coApplicantIncomeEmp = (incomeData) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, spouseCoApplicantId } = state.applicants;

  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Spouse',
        income: [{
          id: incomeData.id || '',
          incomeType: incomeData.incomeType,
          employmentId: incomeData.employmentId || '',
          applicantEmployerName: incomeData.applicantEmployerName || '',
          amount: incomeData.amount,
          frequency: incomeData.frequency,
        }],
      },
    ],
    headers: {},
  };
  return API.post(
    API_NAMES.CO_APPLICANT_INCOME,
    apiUris.GET_CO_APPLICANT_INCOME(opportunityId, spouseCoApplicantId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.CO_APPLICANT_INCOME}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'coApplicantIncomeid', response.data[0].id, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.CO_APPLICANT_INCOME}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const coSpouseApplicantIncome = (incomeData) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, spouseCoApplicantId } = state.applicants;

  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Spouse',
        income: [{
          id: incomeData.id ? `${incomeData.id}` : '',
          incomeOf: 'Primary Borrower',
          employmentId: incomeData.employmentId || '',
          applicantEmployerName: incomeData.applicantEmployerName || '',
          incomeType: incomeData.category,
          amount: incomeData.amount,
          frequency: incomeData.frequency,
        }]
      },
    ],
    headers: {},
  };
  return API.post(
    API_NAMES.CO_APPLICANT_INCOME,
    apiUris.GET_CO_APPLICANT_INCOME(opportunityId, spouseCoApplicantId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.CO_APPLICANT_INCOME}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'coApplicantIncomeid', response.data[0].id, false));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.CO_APPLICANT_INCOME}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const assets = (assetlist) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;

  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Primary',
        assets: [
          {
            id: assetlist.id || '',
            assetType: assetlist.category,
            value: assetlist.estimateVal,
            currentlyFinanced: Boolean(assetlist.underfinance),
            relatedLiability: assetlist.relatedLiability || '',
          },
        ],
      },
    ],
    headers: {},
  };
  return API.post(API_NAMES.OPP_ASSET_CREATE, apiUris.GET_ASSET(opportunityId, applicantId), myInit)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_ASSET_CREATE}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'assetid', response.data[0].id, false));
      // dispatch(addAssetId(response.data));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_ASSET_CREATE}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const assetsSave = () => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId, assets } = state.applicants;

  let arrAssets = []

  assets.forEach(({ assetType, estimateVal, currentlyFinanced, relatedLiability, id = '' }) => {
    arrAssets.push({
      id: id.length > 10 ? id : '',
      assetType: assetType,
      value: estimateVal,
      currentlyFinanced: Boolean(currentlyFinanced),
      relatedLiability: relatedLiability || '',
    })
  });


  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Primary',
        assets: arrAssets,
      },
    ],
    headers: {},
  };
  return API.post(API_NAMES.OPP_ASSET_CREATE, apiUris.GET_ASSET(opportunityId, applicantId), myInit)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_ASSET_CREATE}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'assetid', response.data[0].id, false));
      // dispatch(addAssetId(response.data));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_ASSET_CREATE}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};


export const liabilitiesApi = () => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId, liabilities } = state.applicants;

  let arrLiability = []

  liabilities.forEach(({ liabilityType, financier, frequency, outstanding, repayments, limit, payout, relatedAsset, id = '' }) => {
    arrLiability.push({
      id: id.length > 10 ? id : '',
      liabilityType: liabilityType,
      financier: financier,
      outstandingBalance: outstanding,
      repaymentAmount: repayments,
      repaymentFrequency: frequency,
      toBePaidOutLimit: limit || '',
      payout: Boolean(payout),
      relatedAsset: relatedAsset || '',
    },)
  });

  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Primary',
        liabilities: arrLiability,
      },
    ],
    headers: {},
  };
  return API.post(
    API_NAMES.OPP_LIAB_CREATE,
    apiUris.GET_LIABILITY(opportunityId, applicantId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_LIAB_CREATE}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'liabilityid', response.data[0].id, false));
      // dispatch(addLiabId(response.data));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_LIAB_CREATE}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};


export const liabilities = (liablist) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;
  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Primary',
        liabilities: [
          {
            id: liablist.id || '',
            liabilityType: liablist.category,
            financier: liablist.financier,
            outstandingBalance: liablist.outstanding,
            repaymentAmount: liablist.repayments,
            repaymentFrequency: liablist.frequency,
            toBePaidOutLimit: liablist.limit || '',
            payout: Boolean(liablist.payout),
            relatedAsset: liablist.relatedAsset || '',
          },
        ],
      },
    ],
    headers: {},
  };
  return API.post(
    API_NAMES.OPP_LIAB_CREATE,
    apiUris.GET_LIABILITY(opportunityId, applicantId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_LIAB_CREATE}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'liabilityid', response.data[0].id, false));
      // dispatch(addLiabId(response.data));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_LIAB_CREATE}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const expenses = (expenselist) => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;
  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Primary',
        expenses: [
          {
            id: expenselist.id || '',
            expenseType: expenselist.category,
            amount: expenselist.amount,
            frequency: expenselist.frequency,
          },
        ],
      },
    ],
    headers: {},
  };
  return API.post(
    API_NAMES.OPP_EXPENSE_CREATE,
    apiUris.GET_EXPENSE(opportunityId, applicantId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_EXPENSE_CREATE}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'expenseid', response.data[0].id, false));
      // dispatch(addExpId(response.data));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_EXPENSE_CREATE}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const saveExpenses = (residentialStatus = '') => (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId, expenses } = state.applicants;
  let arrExpenses = [];

  expenses.forEach(({ id, category, amount, frequency }) => {
    if (residentialStatus === 'Renting' && category === 'rent') {
      arrExpenses.push({
        id: id || '',
        expenseType: category,
        amount: amount,
        frequency: frequency,
      });
    } else if (category !== 'rent') {
      arrExpenses.push({
        id: id || '',
        expenseType: category,
        amount: amount,
        frequency: frequency,
      });
    }
  });

  const myInit = {
    response: true,
    body: [
      {
        applicantType: 'Primary',
        expenses: arrExpenses,
      },
    ],
    headers: {},
  };

  return API.post(
    API_NAMES.OPP_EXPENSE_CREATE,
    apiUris.GET_EXPENSE(opportunityId, applicantId),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.OPP_EXPENSE_CREATE}. opportunityId: ${opportunityId}`,
      );
      dispatch(fieldChanged('account', 'expenseid', response.data[0].id, false));
      // dispatch(addExpId(response.data));
      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.OPP_EXPENSE_CREATE}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const abnNumberSearch = (abnNumber) => (dispatch, getState) => {
  const myInit = {
    response: true,
    headers: {},
  };
  dispatch(liteFieldChanged('applicants', 'searchAbn', false));
  dispatch(liteFieldChanged('applicants', 'abnSearchInProgress', true));

  return API.get(API_NAMES.ABNLOOKUP_DETAILS, apiUris.GET_ABNDETAILS(abnNumber), myInit)
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.ABNLOOKUP_DETAILS}. abn: ${abnNumber}`);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false));
      // dispatch({
      //   type: POPULATE_ABN_DETAILS,
      //   abnDetails: response.data.abnDetails,
      //   fieldSuffix,
      // });
      dispatch(liteFieldChanged('applicants', 'searchAbn', true));
      dispatch(liteFieldChanged('applicants', 'abnSearchInProgress', false));

      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(`api call failed ${API_NAMES.ABNLOOKUP_DETAILS}. abn: ${abnNumber}`, error);
      dispatch(liteFieldChanged('account', 'quoteInProgress', false));
      if (error.response && error.response.status) {
        dispatch(liteFieldChanged('applicants', 'abnVerified', false));
        // dispatch(
        //   validationErrors(
        //     'We could not find the abn Number you are looking for, make sure you have entered correct details',
        //   ),
        // );
      } else {
        dispatch(liteFieldChanged('applicants', 'abnVerified', false));
        // dispatch(

        //   validationErrors("We couldn't find your ABN number at this time. Please try again soon."),
        // );
      }
      dispatch(liteFieldChanged('applicants', 'abnSearchInProgress', false));

      return Promise.reject(error);
    });
};

export const getDocumentList = (isPartner = false) => async (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId, partner } = state.applicants;

  const myInit = {
    response: true,
    headers: {},
  };

  return API.get(
    API_NAMES.GET_DOCUMENT_LIST,
    apiUris.GET_DOCUMENT_LIST(
      opportunityId,
      isPartner ? partner.coApplicantId || partner.spouseApplicantId : applicantId,
    ),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.GET_DOCUMENT_LIST}. opportunityId: ${opportunityId}`,
      );

      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.GET_DOCUMENT_LIST}. opportunityId: ${opportunityId}`,
        error,
      );

      return Promise.reject(error);
    });
};

export const getDocument = (documentId) => async (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId } = state.applicants;

  const myInit = {
    response: true,
    headers: {},
  };

  return API.get(
    API_NAMES.GET_DOCUMENT_LIST,
    apiUris.GET_DOCUMENT(opportunityId, applicantId, documentId),
    myInit,
  )
    .then((response) => {
      log.info(`api call successfull ${API_NAMES.GET_DOCUMENT}. opportunityId: ${opportunityId}`);

      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.GET_DOCUMENT}. opportunityId: ${opportunityId}`,
        error,
      );

      return Promise.reject(error);
    });
};

export const uploadDocument = (doc, isPartner = false) => async (dispatch, getState) => {
  const state = getState();
  const { opportunityId, applicantId, partner } = state.applicants;
  const myInit = {
    response: true,
    body: doc,
    headers: {},
  };

  return API.post(
    API_NAMES.UPLOAD_DOCUMENT,
    apiUris.UPLOAD_DOCUMENT(
      opportunityId,
      isPartner ? partner.coApplicantId || partner.spouseApplicantId : applicantId,
    ),
    myInit,
  )
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.UPLOAD_DOCUMENT}. opportunityId: ${opportunityId}`,
      );

      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.UPLOAD_DOCUMENT}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};

export const pqFinalSubmissionUploadDocument = (payload) => async (dispatch, getState) => {
  const state = getState();
  const { opportunityId } = state.applicants;
  const myInit = {
    response: true,
    body: {
      isPQSubmittedToLender: payload.pqSubmittedToLender,
      isPQCallbackRequested: payload.pqCallbackRequested,
    },
    headers: {},
  };

  return API.post(API_NAMES.PQ_FINAL_SUBMISSION, apiUris.PQ_FINAL_SUBMISSION(opportunityId), myInit)
    .then((response) => {
      log.info(
        `api call successfull ${API_NAMES.PQ_FINAL_SUBMISSION}. opportunityId: ${opportunityId}`,
      );

      return Promise.resolve(response);
    })
    .catch((error) => {
      log.error(
        `api call failed ${API_NAMES.PQ_FINAL_SUBMISSION}. opportunityId: ${opportunityId}`,
        error,
      );
      return Promise.reject(error.message);
    });
};
