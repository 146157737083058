/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import cls from 'classnames';
import filter from 'lodash/filter';
import get from 'lodash/get';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';

import {
  AppBar,
  Box,
  Button,
  makeStyles,
  Tab,
  Tabs,
  useTheme,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CustomDropZoneField from '../../components/FileUpload/CustomDropzoneField';
import {
  documentFieldMap,
  uploadedVerifiedStatus,
  uploadFileSize,
  DocumentTypeMapping,
  documentTypeValue,
  allowedFileTypes,
  DOCUMENT_TYPE,
} from '../../util/constants';

import DocumentImg from '../../../assets/image/Document.svg';
import { borderStyling, primaryTextColor, secondaryTextColor } from '../../../styles/constant';
import { getFileFromBase64, removeByAttribute } from '../../util/helpers';
import DocumentTitle from '../../components/DocumentTitle';
import IdentityDocUpload from '../../components/IdentityDocUpload';
import IncomeDocUpload from '../../components/IncomeDocUpload';
import AssetDocUpload from '../../components/AssetDocUpload';
import TrustDocUpload from '../../components/TrustDocUpload/TrustDocUpload';

const fileTypes = ['jpg', 'pdf', 'jpeg', '.'];
// const allowedFileTypes = ['application/pdf', 'image/jpeg'];
const removeFileTypes = fileTypes.join('|');

const requiredMessage = 'This field is required';

const paygOptions = [
  { name: documentFieldMap.taxReturn, value: documentFieldMap.taxReturn, image: DocumentImg },
  {
    name: documentFieldMap.noticeOfAssessment,
    value: documentFieldMap.noticeOfAssessment,
    image: DocumentImg,
  },
];

export const removeDataType = (fileType, base64String) => {
  if (fileType === 'jpeg' || fileType === 'jpg') {
    return base64String.replace('data:image/jpeg;base64,', '');
  } else if (fileType === 'png') {
    return base64String.replace('data:image/png;base64,', '');
  } else if (fileType === 'heic') {
    return base64String.replace(/^data:application\/[^;]+;base64,/, '');
  } else {
    return base64String.replace('data:application/pdf;base64,', '');
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CustomButton = withStyles((theme) => ({
  root: {
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#EEF2F5',
    // '&:hover': {
    //   backgroundColor: purple[700],
    // },
  },
}))(Button);
const CustomButtonNext = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#330072',
    // '&:hover': {
    //   backgroundColor: purple[700],
    // },
  },
}))(Button);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: { margin: '0px 43px' },
  appBar: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'white',
    boxShadow: 'none',
    padding: '24px',
    flex: 1,
    zIndex: 1,
  },
  appBarMob: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'white',
    boxShadow: 'none',
    padding: '0px',
    flex: 1,
    zIndex: 1,
  },

  wrapper: {
    textTransform: 'none',
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#25282A',
    '&$flexContainer': {
      backgroundColor: '#f1f2f2',
    },
  },
  wrapperMob: {
    textTransform: 'none',
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#25282A',
    '&$flexContainer': {
      backgroundColor: '#f1f2f2',
    },
  },
  containerWrapper: {
    flexBasis: 'calc(70% - 30px)',
    backgroundColor: '#fff',
  },
  flexContainer: {},
  textColorInherit: {
    color: '#25282A',
  },
  selected: {
    fontFamily: 'Poppins-Bold',
    textTransform: 'none',
    // fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#25282A',
  },
  selectedMob: {
    fontFamily: 'Poppins-Bold',
    textTransform: 'none',
    // fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#25282A',
  },
  tabsIndicator: {
    borderColor: '#00aabb',
    backgroundColor: '#00aabb',
    height: '4px',
  },
}));

const DocumentsVerification = ({
  incomelistVal,
  uploadDocument,
  getDocumentList,
  getDocument,
  partner,
  licenceNumber,
  licenceState,
  licenceType,
  dlflag,
  partnerIncome,
}) => {
  const paygIncomeData = filter(incomelistVal, { incomeType: 'PAYG Income' }).sort((a) => a.id);
  const paygIncomeDataPartner = filter(partnerIncome, { incomeType: 'PAYG Income' }).sort(
    (a, b) => b.uploadDate - a.uploadDate,
  );

  const selfEmployedIncomeDataPartner = filter(partnerIncome, {
    incomeType: 'Self-Employed Income',
  }).sort((a, b) => b.uploadDate - a.uploadDate);

  const selfEmployedIncomeData = filter(incomelistVal, { incomeType: 'Self-Employed Income' }).sort(
    (a, b) => b.uploadDate - a.uploadDate,
  );
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [imageFiles, setImage] = useState();
  const [imageError, setImageError] = useState({});
  const [imagePartnerError, setImagePartnerError] = useState({});
  const [otherFileCount, setOtherFileCount] = useState(0);
  const [documentList, setDocumentList] = useState([]);
  const [displayTrustDeed, setDisplayTrustDeed] = useState(false);
  const [selfEmployedDocType, setSelfEmployedDocType] = useState({
    [documentFieldMap.taxReturn]: Array(selfEmployedIncomeData?.length || 0),
    [documentFieldMap.noticeOfAssessment]: Array(selfEmployedIncomeData?.length || 0),
  });

  const [imageFilesPartner, setImagePartner] = useState();
  const [otherFileCountPartner, setOtherFileCountPartner] = useState(0);
  const [documentListPartner, setDocumentListPartner] = useState([]);
  const [selfEmployedDocTypePartner, setSelfEmployedDocTypePartner] = useState({
    [documentFieldMap.taxReturn]: Array(selfEmployedIncomeData?.length || 0),
    [documentFieldMap.noticeOfAssessment]: Array(selfEmployedIncomeData?.length || 0),
  });

  const partnerId = partner?.coApplicantId || partner?.spouseApplicantId;

  const dlflagPartner = partner?.internationalLicense_coApplicant || false;
  const partnerDriversLicenseNumber = partner?.driversLicenseNumber_coApplicant || null;
  const partnerDriversLicenseType = partner?.driversLicenseType_coApplicant || null;
  const partnerDriversLicenseState = partner?.driversLicenseState_coApplicant || null;
  const partnerApplicantType = partner?.applicantType_spouse ? 'Spouse' : 'Co-Applicant';

  const imageOtherCount = imageFiles?.[documentFieldMap.other]
    ? imageFiles?.[documentFieldMap.other]?.length
    : 0;

  const imageOtherCountPartner = imageFilesPartner?.[documentFieldMap.other]
    ? imageFilesPartner?.[documentFieldMap.other]?.length
    : 0;

  const [additionalDoclistIdentity, setAdditionalDoclistIdentity] = useState([]);
  const [additionalDoclistIdentityPartner, setAdditionalDoclistIdentityPartner] = useState([]);
  const [additionalDoclistIncome, setAdditionalDoclistIncome] = useState([]);
  const [additionalDoclistIncomePartner, setAdditionalDoclistIncomePartner] = useState([]);
  const [additionalDoclistAsset, setAdditionalDoclistAsset] = useState([]);
  const [trustDocList, setTrustDocList] = useState([]);
  const [additionalDoclistAssetPartner, setAdditionalDoclistAssetPartner] = useState([]);
  const [fetchingInprogress, setFetchingInprogress] = useState(true);

  useEffect(() => {
    fetchDocumentUploadedList();
    if (partnerId) {
      fetchDocumentUploadedList(true);
    }
  }, []);
  useEffect(() => {
    setDisplayTrustDeed(Object.keys(trustDocList).length !== 0);
  }, [trustDocList]);

  const remapDocType = (docList, isPartner = false) => {
    let currentLinkedEntityRelatedIncExId = '';
    const arrPaygIncome = isPartner ? paygIncomeDataPartner : paygIncomeData;
    const arrSelfIncome = isPartner ? selfEmployedIncomeDataPartner : selfEmployedIncomeData;
    let arrSelfIncomeList = [];
    let arrPayslipList = [];
    for (let i = 0; i < arrPaygIncome.length; i++) {
      const arrSorting = sortBy(
        docList.filter((item) => {
          return (
            item.documentSubtype !== null && item.linkedEntityRelatedIncExId === arrPaygIncome[i].id
          );
        }),
        ['documentSubtype'],
      );
      arrPayslipList.push(...arrSorting);
    }
    for (let i = 0; i < arrSelfIncome.length; i++) {
      const arrSelfIncomeSorting = sortBy(
        docList.filter((item) => {
          return item.linkedEntityRelatedIncExId === arrSelfIncome[i].id;
        }),
        ['documentSubtype'],
      );
      arrSelfIncomeList.push(...arrSelfIncomeSorting);
    }
    if (arrPayslipList?.length !== 0) {
      for (let i = 0; i < documentTypeValue.length; i++) {
        removeByAttribute(docList, 'documentType', documentTypeValue[i]);
      }
    }
    if (arrSelfIncomeList?.length !== 0) {
      for (let i = 0; i < documentTypeValue.length; i++) {
        removeByAttribute(docList, 'documentType', documentTypeValue[i]);
      }
    }
    const docListUpdated = docList.concat(arrPayslipList);
    const docListIncomeUpdated = docListUpdated.concat(arrSelfIncomeList);
    const arrayDocList =
      arrPayslipList.length || arrSelfIncomeList.length
        ? docListIncomeUpdated
        : sortBy(sortBy(docListIncomeUpdated, ['uploadedDate']), ['linkedEntityRelatedIncExId']);

    const updatedList = arrayDocList?.map((item) => {
      if (item.documentType === DOCUMENT_TYPE.PAYSLIP) {
        item.documentType = item?.documentSubtype !== null ? item?.documentSubtype : '';
      }
      return { ...item };
    });
    return updatedList;
  };

  const remapAssetData = (docList) => {
    const arrayDocList = docList
      .map((a) => {
        const matchedObject = DocumentTypeMapping.find(
          (obj) => obj.documentType === a.documentType,
        );
        return { ...a, ...matchedObject };
      })
      .filter(
        (obj) =>
          obj.PQ_Category === 'Asset' &&
          (obj.status === 'Requested' ||
            obj.status === 'Uploaded' ||
            obj.status === 'Required' ||
            obj.status === 'Sent to Lender' ||
            obj.status === 'Broker Verified'),
      );
    return arrayDocList;
  };

  const remapIdentityData = (docList) => {
    const arrayDocList = docList
      .map((a) => {
        const matchedObject = DocumentTypeMapping.find(
          (obj) => obj.documentType === a.documentType,
        );
        return { ...a, ...matchedObject };
      })
      .filter(
        (obj) =>
          obj.PQ_Category === 'Identity' &&
          (obj.status === 'Requested' ||
            obj.status === 'Uploaded' ||
            obj.status === 'Required' ||
            obj.status === 'Sent to Lender' ||
            obj.status === 'Broker Verified'),
      );
    return arrayDocList;
  };
  const remapTrustData = (docList) => {
    const arrayDocList = docList
      .map((a) => {
        const matchedObject = DocumentTypeMapping.find(
          (obj) => obj.documentType === a.documentType,
        );
        return { ...a, ...matchedObject };
      })
      .filter(
        (obj) =>
          obj.PQ_Category === 'Trust' &&
          (obj.status === 'Requested' ||
            obj.status === 'Uploaded' ||
            obj.status === 'Required' ||
            obj.status === 'Sent to Lender' ||
            obj.status === 'Broker Verified'),
      );
    return arrayDocList;
  };

  const remapIncomeData = (docList) => {
    const arrayDocList = docList
      .map((a) => {
        const matchedObject = DocumentTypeMapping.find(
          (obj) => obj.documentType === a.documentType,
        );
        return { ...a, ...matchedObject };
      })
      .filter(
        (obj) =>
          obj.PQ_Category === 'Income' &&
          (obj.status === 'Requested' ||
            obj.status === 'Uploaded' ||
            obj.status === 'Required' ||
            obj.status === 'Sent to Lender' ||
            obj.status === 'Broker Verified'),
      );
    return arrayDocList;
  };
  const fetchDocumentUploadedList = (ispartner = false) => {
    getDocumentList(ispartner)
      .then((res) => {
        const updatedDocList = remapDocType(res.data, ispartner);
        setTrustDocList(remapTrustData(res.data));
        ispartner ? setDocumentListPartner(updatedDocList) : setDocumentList(updatedDocList);
        const additionalDoclistIdentityMapData = remapIdentityData(res.data);
        ispartner
          ? setAdditionalDoclistIdentityPartner(additionalDoclistIdentityMapData)
          : setAdditionalDoclistIdentity(additionalDoclistIdentityMapData);
        const additionalDoclistIncomeMapData = remapIncomeData(updatedDocList);
        ispartner
          ? setAdditionalDoclistIncomePartner(additionalDoclistIncomeMapData)
          : setAdditionalDoclistIncome(additionalDoclistIncomeMapData);
        const additionalDoclistAssetMapData = remapAssetData(res.data);
        ispartner
          ? setAdditionalDoclistAssetPartner(additionalDoclistAssetMapData)
          : setAdditionalDoclistAsset(additionalDoclistAssetMapData);
        setFetchingInprogress(false);
        handleOtherCount(res.data, ispartner);
        handleTransformDownloadListToimageFiles(updatedDocList, ispartner);
      })
      .catch((error) => {
        setLoading(false);
        setFetchingInprogress(false);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOtherCount = (documentList, isPartner = false) => {
    const count = filter(documentList, { documentType: 'Other' })?.length;

    if (isPartner) {
      setOtherFileCountPartner(count || 0);
    } else {
      setOtherFileCount(count || 0);
    }
  };

  const handleDeleteFile = (category) => (file) => {
    const updatedList = [...(imageFiles?.[category] || [])];
    remove(updatedList, { path: file.path, name: file.name });
    setImage({ ...imageFiles, [category]: updatedList });
    // this.props.onDelete({ ...this.props.document, imageFiles: [...updatedList] });
  };

  function resolvebase64(fileReader) {
    // just used to resolve a promise and return it asynchronously
    return new Promise((resolve) => {
      // Onload of file read the file content
      fileReader.onload = function(fileLoadedEvent) {
        resolve(fileLoadedEvent.target.result); // your base64 string
      };
    });
  }

  const handleOnDrop = (
    category,
    isOther = false,
    isPartner = false,
    idx = 0,
    incomeId = '',
  ) => async (newimagefile = []) => {
    const categoryDocList = isPartner ? imageFilesPartner?.[category] : imageFiles?.[category];
    let updatedList = [...(categoryDocList || []), ...newimagefile];
    setLoading({ category, isPartner, idx });
    if (categoryDocList && categoryDocList.length > idx) {
      updatedList = [...categoryDocList];
      updatedList[idx] = { ...categoryDocList[idx], ...newimagefile, status: 'Uploaded' };
    }

    let file = null;
    let fileName = '';
    let base64String = '';
    let approvedFileType = allowedFileTypes.find((e) => e === newimagefile[0]?.type);
    //convert file to base64
    if (newimagefile.length > 0) {
      // Select the very first file from list
      let fileToLoad = newimagefile[0];
      if (fileToLoad.size < uploadFileSize && approvedFileType !== undefined) {
        setImageError({});
        setImagePartnerError({});
        if (isPartner) {
          setImagePartner({ ...imageFilesPartner, [category]: updatedList });
        } else {
          setImage({ ...imageFiles, [category]: updatedList });
        }

        fileName = fileToLoad.name;
        // FileReader function for read the file.
        let fileReader = new FileReader();

        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);

        base64String = await resolvebase64(fileReader);

        let fileTypeExtension = newimagefile[0].type.replace(/(.*)\//g, '');

        //PDF
        const payload = {
          fileName: fileToLoad.name,
          documentType:
            category === documentFieldMap.payslip1 || category === documentFieldMap.payslip2
              ? 'Payslip'
              : documentFieldMap.category || category,
          documentContents: removeDataType(fileTypeExtension, base64String),
        };

        //sups changes
        const doclistByType = filter(isPartner ? documentListPartner : documentList, [
          'documentType',
          category,
        ]);

        payload['documentId'] =
          doclistByType?.[idx]?.status === 'Requested'
            ? doclistByType?.[idx]?.contentDocumentId || ''
            : '';
        payload['linkedEntityRelatedIncExId'] = incomeId;
        //API call
        if (isPartner) {
          uploadDocument(payload, true).then(() => {
            getDocumentList(true).then((res) => {
              const { data } = res;
              const updatedDocList = remapDocType(data, isPartner);
              setDocumentListPartner(updatedDocList);
              // handleOtherCount(data, true);
              handleTransformDownloadListToimageFiles(updatedDocList, true);
              setLoading(false);
            });
          });
        } else {
          uploadDocument(payload).then(() => {
            getDocumentList().then((res) => {
              const { data } = res;
              const updatedDocList = remapDocType(data, isPartner);
              // handleOtherCount(data);
              setDocumentList(updatedDocList);
              handleTransformDownloadListToimageFiles(updatedDocList);
              setLoading(false);
            });
          });
        }
      } else if (fileToLoad.size >= uploadFileSize) {
        setLoading(false);
        const newImageErrorFiles = {};
        const formattedData = {
          fileName: fileToLoad.name,
          linkedEntityRelatedIncExId: incomeId,
          category,
          error: 'Document size should be less than 8MB',
        };
        if (isPartner) {
          newImageErrorFiles[category] = [{ ...formattedData }];
          setImagePartnerError(newImageErrorFiles);
        } else {
          newImageErrorFiles[category] = [{ ...formattedData }];
          setImageError(newImageErrorFiles);
        }
        return;
      } else if (approvedFileType === undefined) {
        setLoading(false);
        const newImageErrorFiles = {};
        const formattedData = {
          fileName: fileToLoad.name,
          linkedEntityRelatedIncExId: incomeId,
          category,
          error: 'Incorrect file format',
        };
        if (isPartner) {
          newImageErrorFiles[category] = [{ ...formattedData }];
          setImagePartnerError(newImageErrorFiles);
        } else {
          newImageErrorFiles[category] = [{ ...formattedData }];
          setImageError(newImageErrorFiles);
        }
        return;
      } else {
        return;
      }
    }
  };

  const onClickAddDocument = (isPartner = false) => {
    const setOtherFileCountAdd = isPartner ? otherFileCountPartner : otherFileCount;

    if (setOtherFileCountAdd < 5) {
      isPartner
        ? setOtherFileCountPartner(setOtherFileCountAdd + 1)
        : setOtherFileCount(setOtherFileCountAdd + 1);
    }
  };

  const handleSelfEmployedType = (idx) => (_, category) => {
    // const docTypes = { ...(partnerId ? selfEmployedDocTypePartner : selfEmployedDocType) };
    const docTypes = { ...selfEmployedDocType };

    docTypes[category][idx] = true;

    const resetCategory =
      category === documentFieldMap.taxReturn
        ? documentFieldMap.noticeOfAssessment
        : documentFieldMap.taxReturn;

    docTypes[resetCategory][idx] = undefined;

    // partnerId ? setSelfEmployedDocTypePartner(docTypes) : setSelfEmployedDocType(docTypes);
    setSelfEmployedDocType(docTypes);
  };

  const handleTransformDownloadListToimageFiles = (allDocList, isPartner = false) => {
    // note: future use of this line (basically just transform it on how it is being used for the dropzone components)
    //const newImageFiles = { ...(isPartner ? imageFilesPartner : imageFiles) };
    const uploadedDocList = filter(allDocList, (doc) =>
      uploadedVerifiedStatus.includes(doc?.status),
    );

    const newImageFiles = {};

    uploadedDocList.forEach((a) => {
      const n = a.fileName.lastIndexOf('.');
      const fileExtension = a.fileName.substring(n + 1);
      const name = a.fileName.substring(0, n);
      let documentType = a.documentType;

      const formattedData = {
        path: a.fileName,
        fileExtension,
        documentId: a.documentId,
        linkedEntityRelatedIncExId: a.linkedEntityRelatedIncExId,
        name,
        documentType,
        status: a.status,
      };
      if (newImageFiles?.[documentType]) {
        newImageFiles[documentType].push({ ...formattedData });
      } else {
        newImageFiles[documentType] = [{ ...formattedData }];
      }
    });

    isPartner ? setImagePartner(newImageFiles) : setImage(newImageFiles);
  };

  const licenseExistPrimary = () => {
    return licenceNumber || licenceState || licenceType;
  };

  const licenseExistPartner = () => {
    return partnerDriversLicenseNumber || partnerDriversLicenseState || partnerDriversLicenseType;
  };

  const incomeExistPrimary = () => {
    return (
      get(imageFiles, `${documentFieldMap.payslip1}[0].path`, false) &&
      get(imageFiles, `${documentFieldMap.payslip2}[0].path`, false)
    );
  };

  const incomeExistPartner = () => {
    return (
      get(imageFilesPartner, `${documentFieldMap.payslip1}[0].path`, false) &&
      get(imageFilesPartner, `${documentFieldMap.payslip2}[0].path`, false)
    );
  };

  const onClickUploadedFile = (fileInfo) => {
    let file = null;
    const fileDesc = fileInfo[0];
    // convert base 64 string into file

    // call api to get document details and base64 string
    getDocument(fileDesc.documentId).then((res) => {
      const base64String = res.data;
      const file = getFileFromBase64(base64String, fileDesc.name, fileDesc.fileExtension);

      // after converting . download the file to user
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(file);
      a.download = `${fileDesc.name}.${
        fileDesc.fileExtension === 'jpeg' || fileDesc.fileExtension === 'jpg'
          ? 'jpg'
          : fileDesc.fileExtension === 'png'
          ? 'png'
          : fileDesc.fileExtension === 'heic'
          ? 'heic'
          : 'pdf'
      }`;
      a.click();
    });
  };

  // let uspdateList = [...DOCVERIFYLIST];
  // updateList = remove(updateList, (docCat) => find(incomelistVal, ['category', docCat.type]));
  return (
    <Box>
      {/* <div className="dashboard-doc-verification-container dashboard-doc-container"> */}
      {/* NEW Document Verification */}
      <Box
        sx={{
          fontSize: '32px',
          lineHeight: '48px',
          padding: '24px',
        }}
      >
        Upload Documents
      </Box>
      {/* DO NOT SHOW TABS if there's no partner   */}
      {partnerId && (
        <AppBar position="static" className={classes.appBar} color="inherit">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example1"
            classes={{
              indicator: classes.tabsIndicator,
              textColorInherit: classes.textColorInherit,
            }}
            scrollButtons="auto"
            variant="scrollable"
          >
            <Tab
              label="Primary Applicant"
              classes={{
                root: classes.wrapper,
                selected: classes.selected,
              }}
              {...a11yProps(0)}
            />

            <Tab
              label={partnerApplicantType === 'Spouse' ? 'Spouse' : 'Co-Applicant'}
              classes={{
                indicator: classes.tabsIndicator,
                textColorInherit: classes.textColorInherit,
              }}
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
      )}

      <DocumentTitle />
      {/* MAIN APPLICANT */}
      <>
        {fetchingInprogress ? (
          <div className="text-center mt-3">
            <CircularProgress />
          </div>
        ) : (
          <>
            <TabPanel
              value={value}
              index={0}
              className={cls(classes.containerWrapper, 'customer-dashboard-container')}
            >
              <>
                {/* IDENTITY SECTION */}
                <IdentityDocUpload
                  loading={loading}
                  // dlflag={dlflag}
                  setLoading={setLoading}
                  imageFiles={imageFiles}
                  setImage={setImage}
                  imageError={imageError}
                  setImageError={setImageError}
                  documentList={documentList}
                  setDocumentList={setDocumentList}
                  additionalDoclistIdentity={additionalDoclistIdentity}
                  applicantType="Primary"
                />

                {/* INCOME SECTION */}
                <IncomeDocUpload
                  loading={loading}
                  setLoading={setLoading}
                  imageFiles={imageFiles}
                  setImage={setImage}
                  documentList={documentList}
                  setDocumentList={setDocumentList}
                  imageError={imageError}
                  setImageError={setImageError}
                  paygIncomeData={paygIncomeData}
                  selfEmployedIncomeData={selfEmployedIncomeData}
                  additionalDoclistIncome={additionalDoclistIncome}
                  applicantType="Primary"
                />

                {/* ASSET SECTION */}
                <AssetDocUpload
                  loading={loading}
                  setLoading={setLoading}
                  imageFiles={imageFiles}
                  setImage={setImage}
                  setImageError={setImageError}
                  documentList={documentList}
                  setDocumentList={setDocumentList}
                  imageError={imageError}
                  additionalDoclistAsset={additionalDoclistAsset}
                />
                {/* Trust  */}
                {displayTrustDeed && (
                  <TrustDocUpload
                    trustDocList={trustDocList}
                    loading={loading}
                    setLoading={setLoading}
                    imageFiles={imageFiles}
                    setImage={setImage}
                    documentList={documentList}
                    setDocumentList={setDocumentList}
                    imageError={imageError}
                    setImageError={setImageError}
                  />
                )}
                {/* OTHER SECTION */}
                <Box
                  sx={{
                    padding: '48px 24px 24px',
                    paddingTop: '24px',
                    borderBottom: borderStyling,
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontWeight: 400,
                      color: secondaryTextColor,
                    }}
                  >
                    OTHER
                  </Box>
                  <div className="doc-label">Upload additional documents here</div>
                  {Array.from(Array(otherFileCount).keys()).map((idx) => {
                    return (
                      <Box>
                        <CustomDropZoneField
                          handleOnClick={onClickUploadedFile}
                          handleOnDrop={handleOnDrop(documentFieldMap.other, false, false, idx)}
                          handleDeleteFile={handleDeleteFile(documentFieldMap.other)}
                          imagefile={
                            imageFiles?.[documentFieldMap.other]?.[idx]
                              ? [imageFiles[documentFieldMap.other]?.[idx]]
                              : undefined
                          }
                          disabled={imageFiles?.[documentFieldMap.other]?.length > idx}
                          error={get(imageError, `${documentFieldMap.other}[${idx}].error`, false)}
                          loading={
                            loading.category === documentFieldMap.other &&
                            loading.isPartner === false &&
                            loading.idx === idx
                          }
                        />
                      </Box>
                    );
                  })}
                  <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                      {otherFileCount < 5 && imageOtherCount == otherFileCount && (
                        <CustomButton
                          variant="contained"
                          disableElevation
                          startIcon={<AddIcon />}
                          onClick={(_) => onClickAddDocument(false)}
                        >
                          <Box
                            sx={{
                              fontSize: '16px',
                              lineHeight: '24px',
                              fontWeight: 600,
                              color: primaryTextColor,
                              textTransform: 'none',
                            }}
                          >
                            Add document
                          </Box>
                        </CustomButton>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            </TabPanel>
          </>
        )}
      </>

      {/* PARTNER */}
      <TabPanel
        value={value}
        index={1}
        className={cls(classes.containerWrapper, 'customer-dashboard-container')}
      >
        <>
          {/* IDENTITY SECTION */}

          {/* {partnerApplicantType !== 'Spouse' && ( */}
          <IdentityDocUpload
            loading={loading}
            setLoading={setLoading}
            imageFiles={imageFilesPartner}
            setImage={setImagePartner}
            setImageError={setImagePartnerError}
            imageError={imagePartnerError}
            documentList={documentListPartner}
            setDocumentList={setDocumentListPartner}
            isPartner={partnerId ? true : false}
            additionalDoclistIdentity={additionalDoclistIdentityPartner}
            applicantType={partnerApplicantType}
          />
          {/* // )} */}

          {/* INCOME SECTION */}
          {(paygIncomeDataPartner.length > 0 || selfEmployedIncomeDataPartner.length > 0) &&
            value != 0 && (
              <IncomeDocUpload
                loading={loading}
                setLoading={setLoading}
                imageFiles={imageFilesPartner}
                setImage={setImagePartner}
                imageError={imagePartnerError}
                setImageError={setImagePartnerError}
                documentList={documentListPartner}
                setDocumentList={setDocumentListPartner}
                paygIncomeData={paygIncomeDataPartner}
                selfEmployedIncomeData={selfEmployedIncomeDataPartner}
                isPartner={partnerId ? true : false}
                additionalDoclistIncome={additionalDoclistIncomePartner}
                applicantType={partnerApplicantType}
              />
            )}

          {/* ASSET SECTION */}
          <AssetDocUpload
            loading={loading}
            setLoading={setLoading}
            imageFiles={imageFilesPartner}
            setImage={setImagePartner}
            imageError={imagePartnerError}
            setImageError={setImagePartnerError}
            documentList={documentListPartner}
            setDocumentList={setDocumentListPartner}
            isPartner={partnerId ? true : false}
            additionalDoclistAsset={additionalDoclistAssetPartner}
          />

          {/* OTHER SECTION */}
          <Box sx={{ padding: '48px 24px 24px', paddingTop: '24px', borderBottom: borderStyling }}>
            <Box
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
                color: secondaryTextColor,
              }}
            >
              OTHER
            </Box>
            <div className="doc-label">Upload additional documents here</div>
            {Array.from(Array(otherFileCountPartner).keys()).map((idx) => {
              return (
                <Box>
                  <CustomDropZoneField
                    handleOnClick={onClickUploadedFile}
                    handleOnDrop={handleOnDrop(documentFieldMap.other, false, true, idx)}
                    handleDeleteFile={handleDeleteFile(documentFieldMap.other)}
                    imagefile={
                      imageFilesPartner?.[documentFieldMap.other]?.[idx]
                        ? [imageFilesPartner[documentFieldMap.other]?.[idx]]
                        : undefined
                    }
                    disabled={imageFilesPartner?.[documentFieldMap.other]?.length > idx}
                    error={get(imagePartnerError, `${documentFieldMap.other}[${idx}].error`, false)}
                    loading={
                      loading.category === documentFieldMap.other &&
                      loading.isPartner &&
                      loading.idx === idx
                    }
                  />
                </Box>
              );
            })}

            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                {otherFileCountPartner < 5 && imageOtherCountPartner == otherFileCountPartner && (
                  <CustomButton
                    variant="contained"
                    disableElevation
                    startIcon={<AddIcon />}
                    onClick={() => onClickAddDocument(true)}
                  >
                    <Box
                      sx={{
                        fontSize: '16px',
                        lineHeight: '24px',
                        fontWeight: 600,
                        color: primaryTextColor,
                        textTransform: 'none',
                      }}
                    >
                      Add document
                    </Box>
                  </CustomButton>
                )}
              </Box>
            </Box>
          </Box>
        </>
      </TabPanel>
    </Box>
  );
};

export default DocumentsVerification;
