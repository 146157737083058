import { connect } from 'react-redux';
import CompareLoanOptions from './CompareLoanOptions';
import fieldChanged from '../../Actions/FieldChanged';
import { setCompleteTab, setCurrentTab } from '../../Actions/tab';
import { handleHTTPError, noErrors } from '../../Actions/errorHandler';
import {
  getAllVehicleYears,
  updateQuoteLenderParams,
  reQuote,
  selectProductDetails,
  topNLatestReQuote,
} from '../../Actions/car';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import { setApplicationtructure } from '../../Actions/applicants';
import { setApplicationStrucutureStepper } from '../../Actions/stepperDetails';

const mapStateToProps = (state) => ({
  fullName:
    state.applicants.firstName && `${state.applicants.firstName} ${state.applicants.lastName}`,
  isAuthenticated: state.account.isAuthenticated,
  depositUpdated:
    state.estimation.depositUpdated ||
    (state.estimation.depositUpdated === '0'
      ? state.estimation.depositUpdated
      : state.car.depositAmount),
  purchasePriceUpdated:
    state.estimation.purchasePriceUpdated ||
    (state.estimation.purchasePriceUpdated === '0'
      ? state.estimation.purchasePriceUpdated
      : state.car.purchasePrice),
  depositAmount: state.car.depositAmount || '0',
  purchasePrice: state.car.purchasePrice,
  loanAmount: state.estimation.loanAmount,
  residualUpdated: state.estimation.residualUpdated,
  termVal: state.estimation.termVal,
  quoteInProgress: state.account.quoteInProgress,
  termValUpdated: state.estimation.termValUpdated || state.estimation.termVal,
  manufacturingYear: state.car.manufacturingYear,
  carCondition: state.car.carCondition,
  vehicleYears: state.car.years,
  purposeOfuse: state.car.purchaseUse,
  updatedYear: state.car.updatedYear || state.car.manufacturingYear,
  selectFreq: state.estimation.selectFreq,
  currentTab: state.tab.tabName,
  lenderSelected: state.estimation.lenderSelected,
  residualSwitch: state.estimation.residualSwitch,
  opportunityId: state.applicants.opportunityId,
  getValidationErrorsForField: getValidationErrorsForFieldFunc(state.car.validationErrors),
  assetType: state.car.assetType,
});

const mapDispatchToProps = (dispatch) => ({
  getAllVehicleYears: (carCondition) => dispatch(getAllVehicleYears(carCondition)),
  setApplicationtructure: (applicationStructure) =>
    dispatch(setApplicationtructure(applicationStructure)),
  setApplicationStrucutureStepper: (applicationStructure) =>
    dispatch(setApplicationStrucutureStepper(applicationStructure)),

  onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(fieldChanged('estimation', fieldName, value, scrollToNextFieldOnChage));
  },
  onInputChangeSelectedLender: (value) => {
    dispatch(fieldChanged('applicants', 'selectedLender', value, false));
  },
  onCarFieldChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(fieldChanged('car', fieldName, value, scrollToNextFieldOnChage));
  },
  setTab: (tabName) => {
    dispatch(setCurrentTab(tabName));
  },
  updateQuoteLenderParams: (qualifyingDetails) =>
    dispatch(updateQuoteLenderParams(qualifyingDetails)),
  selectProductDetails: (selectedQuoteData) => dispatch(selectProductDetails(selectedQuoteData)),
  reQuote: () => dispatch(reQuote()),
  handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
  setTabComplete: () => {
    dispatch(setCompleteTab('compare_loan_options'));
  },
  noErrors: () => dispatch(noErrors()),
  topNLatestReQuote: () => dispatch(topNLatestReQuote()),
});
const CompareLoanOptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompareLoanOptions);

export default CompareLoanOptionsContainer;
