import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const ModalWindow = ({ handleClose, errorImg, message, showModal }) => (
  <Modal show={showModal} centered onHide={handleClose}>
    <Modal.Header closeButton />
    <Card className="login-modal">
      <Card.Body className="login-modal-body">
        <div className="login-modal-content">
          <img alt={errorImg} src={errorImg} className="login-img" />
          <div className="login-text-msg">{message}</div>
        </div>
        <div className="login-modal-btn">
          <Button className="login-continue px-3  mx-3" onClick={handleClose}>
            okay
          </Button>
        </div>
      </Card.Body>
    </Card>
  </Modal>
);

export default ModalWindow;
