import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import NumberFormat from 'react-number-format';
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles';
import cls from 'classnames';
import { CircularProgress } from '@material-ui/core';
import Alert from 'react-bootstrap/Alert';
import fieldChanged from '../Actions/FieldChanged';
import Copyright from './Copyright';
import logo from '../../assets/image/stratton-logo-transparent.png';
import { ROUTES } from '../util/constants';
import '../containers/ContextPages/context-pages.scss';
import Tooltip from './Tooltip';
import { handleHTTPError, noErrors } from '../Actions/errorHandler';
import AmountRangeSelector from './AmountRangeSelector';
import { createQuote, reQuote, loanDetails } from '../Actions/car';
import './update-loan-details.scss';
import processGTMevent from '../util/processGTMEvent';
import { setCompleteTab } from '../Actions/tab';
import CheckSuccess from '../../assets/image/nav-check.svg';
import Car from '../../assets/image/Car.svg';
import LoanFailed from '../../assets/image/Loan-Failed.svg';

const UpdateLoanOptions = (props) => {
  const {
    updateLoanAmt,
    onInputChange,
    depositAmount,
    purchasePrice,
    getValidationErrorsForField,
    termVal,
    purchasePriceUpdated,
    depositUpdated,
    residualSwitch,
    createQuote,
    quoteInProgress,
    termValUpdated,
    onUpdateChange,
    opportunityId,
    loanDetails,
    purchaseUse,
    assetType,
  } = props;
  const history = useHistory();
  const onLogoClick = () => {
    window.open('https://www.strattonfinance.com.au/');
  };
  const handleResidualChange = (e) => {
    onInputChange('residualSwitch', e.target.checked, false);
  };
  const [validationError, showError] = useState('');

  const handleClose = () => {
    onUpdateChange('updateLoanAmt', false, false);
    onUpdateChange('depositUpdated', null, false);
    onUpdateChange('purchasePriceUpdated', null, false);
    onInputChange('termValUpdated', null, false);
  };

  const updateLoanAmount = () => {
    processGTMevent('estimation', 'update', 'UpdateLoanDetails');
    if (purchasePriceUpdated - depositUpdated < 5000) {
      handleHTTPError({ customMsg: 'Loan Amount cannot be less than $5000.' });
    } else {
      onUpdateChange('depositAmount', depositUpdated, false);
      onUpdateChange('purchasePrice', purchasePriceUpdated, false);
      onInputChange('termVal', termValUpdated, false);
      onInputChange('updateLoanAmt', false, false);
      history.push(ROUTES.loan);
    }
    if (opportunityId) {
      loanDetails()
        .then(() => {
          reQuote()
            .then(() => {
              handleClose();
            })
            .catch(() => {
              handleClose();
            });
        })
        .catch(() => {
          handleClose();
          showError('Something went wrong, please try again soon.');
        });
    } else {
      createQuote()
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          showError('Something went wrong, please try again soon.');
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
    processGTMevent('estimation', 'updateConfirm', 'UpdatedLoan');
  };
  const maxTermVal = 7;
  return (
    <>
      <div className="estimateRepayments-container">
        <div
          className={cls(
            updateLoanAmt && 'update-loan-amount-bgimg',
            'estimateRepayments-section finance_details_bgImage col-12 col-md-4 col-lg-4',
          )}
        >
          <div className="stratton-info-nav-container">
            <img
              src={logo}
              alt="Stratton"
              className="pt-3 stratton-info-logo"
              onClick={onLogoClick}
            />
            <div className="step-content-section">
              <div className="nav-animation">
                <div className="nav-animation-line">
                  <div className="checksuccess-circle">
                    <img src={CheckSuccess} className="nav-checksuccess" alt="car" />
                  </div>
                  <img src={Car} className="nav-animation-car with-animation" alt="car" />
                </div>
              </div>
              <div className="stepper-contents">
                <div className="steps-section">
                  <div className="step enabled">STEP 1</div>
                  <div className="step-message enabled ">Repayments estimate</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 2</div>
                  <div className="step-message disabled">Compare loan options</div>
                </div>
                <div className="steps-section">
                  <div className="step disabled">STEP 3</div>
                  <div className="step-message disabled">Get approved</div>
                </div>{' '}
                <div className="steps-section">
                  <div className="step disabled">STEP 4</div>
                  <div className="step-message disabled">Prepare for Settlement</div>
                </div>
              </div>
            </div>
          </div>
          <div className="stratton-info-footer-container">
            <div className="finance_details_bgImage">
              <img src={LoanFailed} className="nav-finances" alt="loan-update" />
            </div>

            <Copyright />
          </div>
        </div>

        <div className="estimateRepayments-page-details col-12 col-md-8 col-lg-8">
          <div className="user-dropdown">
            <div className="Need-help py-3">
              Call us on
              <div className="number-font">1300 787 288</div>
            </div>
          </div>

          <>
            <div className="update-loan-details-form">
              <div className="update-loan-details-title">
                Update your <span className="loan-details-highlight">loan details</span>{' '}
              </div>
              <fieldset className="update-loan-details-fieldset">
                <div className="amount-range-selector">
                  <AmountRangeSelector
                    label={
                      <>
                        <span className="label">Purchase price</span>
                      </>
                    }
                    onChange={onInputChange}
                    fieldName="purchasePriceUpdated"
                    // defaultValue={purchasePrice}
                    getvalidationerrors={getValidationErrorsForField}
                    defaultValue={purchasePrice}
                    minValue={5000}
                    maxValue={250000}
                    step={1000}
                    formGroupClass="mx-0 amount-range-slider"
                  />
                  <AmountRangeSelector
                    label={
                      <>
                        <span className="label">Deposit</span>
                      </>
                    }
                    onChange={onInputChange}
                    fieldName="depositUpdated"
                    // defaultValue={depositAmount}
                    defaultValue={depositAmount}
                    minValue={0}
                    maxValue={purchasePriceUpdated - 5000}
                    step={1000}
                    getvalidationerrors={getValidationErrorsForField}
                    formGroupClass="mx-0 amount-range-slider"
                  />
                </div>
                <div className="estimate-range-slider">
                  <div className="estimate-loan-amount col-12 col-sm-6 col-md-6 ">
                    <NumberFormat
                      displayType="text"
                      value={purchasePriceUpdated - depositUpdated}
                      thousandSeparator
                      prefix="$"
                    />
                    <div className="loan-amt-label py-2">Loan amount</div>
                  </div>
                  <AmountRangeSelector
                    label={
                      <>
                        <span className="label">Loan term</span>
                      </>
                    }
                    formGroupClass="estimation-range-selector col-12 col-sm-6 col-md-4"
                    onChange={onInputChange}
                    fieldName="termValUpdated"
                    defaultValue={termVal}
                    oncreateQuote={createQuote}
                    minValue={1}
                    maxValue={maxTermVal}
                    step={1}
                    getvalidationerrors={getValidationErrorsForField}
                    termSlider
                  />
                </div>
                <div className="estimate-slider col-12">
                  {assetType == 'Car' && (
                    <div className="custom-control custom-switch residual-switch residual-switch_update col-12 mt-2">
                      <label htmlFor="residualSwitch" className="residual-switch-label">
                        <div className="include-residual">
                          <div>Include Residual?</div>
                          <div className="">
                            <Tooltip
                              label="What's this?"
                              message="Adding a residual (or balloon) will reduce your repayments, and at the end of your loan you will pay the residual amount as a lump sum. You may have the option to refinance the residual payment."
                            />
                          </div>
                        </div>
                      </label>
                      <input
                        type="checkbox"
                        className="custom-control-input "
                        checked={residualSwitch}
                        value={residualSwitch}
                        onChange={handleResidualChange}
                        id="residualSwitch"
                      />
                      <label
                        className={cls('custom-control-label', residualSwitch && 'checked')}
                        htmlFor="residualSwitch"
                      />
                    </div>
                  )}
                </div>
                {validationError && (
                  <Alert variant="danger" className="error-alert mx-2">
                    <p className="error-message">{validationError}</p>
                  </Alert>
                )}
                <hr className="horizantal" />
                <div className="update-loandetails-footer">
                  <Button
                    className="update-cancel"
                    onClick={() => {
                      onInputChange('updateLoanAmt', false, false);
                      onInputChange('depositUpdated', null, false);
                      onInputChange('purchasePriceUpdated', null, false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="update-loanamt"
                    onClick={updateLoanAmount}
                    disabled={quoteInProgress}
                  >
                    {quoteInProgress ? (
                      <>
                        <CircularProgress color="inherit" size={24} thickness={2.6} />
                        <span> Updating</span>
                      </>
                    ) : (
                      'Update'
                    )}
                  </Button>
                </div>
              </fieldset>
            </div>
          </>
        </div>
      </div>

      <Copyright mobileFooter />
    </>
  );
};

export default connect(
  (state) => ({
    assetType: state.car.assetType,
    loanOptions: state.estimation.loanOptions,
    fullName:
      state.applicants.firstName && `${state.applicants.firstName} ${state.applicants.lastName}`,
    isAuthenticated: state.account.isAuthenticated,
    updateLoanAmt: state.estimation.updateLoanAmt,
    depositUpdated:
      state.estimation.depositUpdated ||
      (state.estimation.depositUpdated === 0
        ? state.estimation.depositUpdated
        : state.car.depositAmount),
    purchasePriceUpdated:
      state.estimation.purchasePriceUpdated ||
      (state.estimation.purchasePriceUpdated === 0
        ? state.estimation.purchasePriceUpdated
        : state.car.purchasePrice),
    depositAmount: state.car.depositAmount,
    purchasePrice: state.car.purchasePrice,
    termVal: state.estimation.termVal,
    residualSwitch: state.estimation.residualSwitch,
    quoteInProgress: state.account.quoteInProgress,
    addSpouse: state.applicants.addSpouse,
    termValUpdated: state.estimation.termValUpdated || state.estimation.termVal,
  }),

  (dispatch) => ({
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('estimation', fieldName, value, scrollToNextFieldOnChage));
    },
    setAuthenticationlogOut: () =>
      dispatch(fieldChanged('account', 'isAuthenticated', false, false)),
    setTabComplete: (tabName) => {
      dispatch(setCompleteTab(tabName));
    },
    onUpdateChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('car', fieldName, value, scrollToNextFieldOnChage));
    },
    addSpouseDetails: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage));
    },
    handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
  }),
)(UpdateLoanOptions);
