/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useCallback } from 'react';
import './PersonalDetails.scss';
import { useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import cls from 'classnames';
import Email from '../../../assets/image/Email.svg';
import Edit from '../../../assets/image/edit.svg';
// import ReactPasswordStrength from 'react-password-strength';
import rightnormal from '../../../assets/image/right-normal.svg';
import view from '../../../assets/image/view.svg';
import processGTMevent from '../../util/processGTMEvent';
import { ROUTES } from '../../util/constants';

import PersonalDetailsVerification from './PersonalDetailsVerification';
import {
  UPPERCASE_CHARS,
  LOWERCASE_CHARS,
  NUMERIC_CHARS,
  PASSWORD_LENGTH,
} from '../../util/regexpConstants';

const PasswordCreation = (props) => {
  const {
    password,
    onInputChange,
    email,
    phoneNumber,
    onScrollToNextClick,
    submittedMobileOtp,
    isExistingUser,
    hashPath,
    leadCreation,
    noErrors,
    isAuthenticated,
    handleHTTPError,
    error,
    mobileVerficationPage,
    setTab,
    currentTab,
  } = props;
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [prevEmailVal, setPrevEmail] = useState(email);
  const [enableEmail, setEnableEmail] = useState(!!email);

  const history = useHistory();
  let formattedphoneNumber = phoneNumber && phoneNumber.replace(/ /g, '');
  if (phoneNumber && phoneNumber.slice(0, 1) === '+') {
    formattedphoneNumber = formattedphoneNumber.substring(3);
  }
  if (phoneNumber && phoneNumber.slice(0, 1) === '0') {
    formattedphoneNumber = formattedphoneNumber.substring(1);
  }

  const RedirectLoginpage = () => {
    history.push(ROUTES.login);
  };

  useEffect(() => {
    setTab();
    // processGTMevent('applicants', 'passwordpageLoad', 'seepasswordPage');
  }, [currentTab, setTab]);

  const emailUpdate = (e) => {
    const emailValue = e.target.value;
    if (!emailValue) {
      onInputChange('error', 'email address cannot be null', false);
      return;
    }
    onInputChange('email', emailValue, false);
    noErrors();

    setEnableEmail(true);
  };

  const fieldChange = (e) => {
    setPrevEmail(email);
    const emailValue = e.target.value;
    onInputChange('email', emailValue, false);
  };

  const verifyMobileDetails = () => {
    processGTMevent('applicants', 'passwordCreateNext', 'passwordCreateNext');
    if (!isExistingUser && !isAuthenticated && prevEmailVal !== email) {
      setTimeout(
        leadCreation()
          .then((response) => {
            onInputChange('leadCreationInProgress', true, false);
            if (response.isExisting) {
              onInputChange('leadCreationInProgress', false, false);
              onInputChange('error', 'An account with this email address already exists.', false);
              return false;
            }
            onInputChange('leadCreationInProgress', false, false);
          })
          .catch((err) => {
            onInputChange('leadCreationInProgress', false, false);
            handleHTTPError(err);
          }),
        3000,
      );
    }
    onInputChange('applicantDetails', true, true);
    onInputChange('mobileVerficationPage', true, false).then(() => {
      onScrollToNextClick('verifyyourself-body-section', [], true, true);
    });
    if (isAuthenticated) {
      onInputChange('applicantDetails', true, true);
    }
  };
  return (
    <>
      {!mobileVerficationPage && (
        <Card className={`content-body personal-details-container ${hashPath}`}>
          <Card.Header className="col-12 page-header-container personal-details-header-container scrollable-anchor-tag with-sidebar">
            <a name={hashPath} href={`#${hashPath}`}>
              &nbsp;
            </a>
            <h1 className="personal-details-header">Create your Stratton Finance account</h1>
            <div className="personal-details-description">
              Securely access all your infomation at anytime
            </div>
          </Card.Header>
          <Card.Body className="pb-0 applicant-body-container">
            <div className="row  applicant-body-section1">
              <div className="col-12 p-0 applicant-body-section2">
                <div className="form-column email-password">
                  <Form.Group className="login-input-group " controlId="email">
                    <Form.Label className="input-label-font">Email</Form.Label>
                    {!error ? (
                      <InputGroup
                        className={cls('email-input-group-append', enableEmail && 'disabled')}
                      >
                        <InputGroup.Prepend className="email-icon-input-append">
                          <img src={Email} alt="email" />
                        </InputGroup.Prepend>
                        <Form.Control
                          autoFocus
                          type="email"
                          disabled={enableEmail}
                          placeholder="Email address"
                          className="email-border"
                          value={email}
                          onBlur={emailUpdate}
                          onChange={fieldChange}
                        />

                        <InputGroup.Append
                          className="input-append"
                          onClick={() => {
                            processGTMevent('applicants', 'editEmail', 'editEmail');
                            setEnableEmail(false);
                          }}
                        >
                          <img src={Edit} alt="edit" />
                        </InputGroup.Append>
                      </InputGroup>
                    ) : (
                      <InputGroup className="email-input-group-append invalid">
                        <InputGroup.Prepend className="email-icon-input-append invalid ">
                          <img src={Email} alt="email" />
                        </InputGroup.Prepend>
                        <Form.Control
                          autoFocus
                          type="email"
                          disabled={enableEmail}
                          placeholder="Email address"
                          className="email-border invalid "
                          value={email}
                          onBlur={emailUpdate}
                          onChange={fieldChange}
                        />

                        <InputGroup.Append
                          className="input-append invalid "
                          onClick={() => {
                            setEnableEmail(false);
                          }}
                        >
                          <img src={Edit} alt="edit" />
                        </InputGroup.Append>
                      </InputGroup>
                    )}
                  </Form.Group>
                  <div className="error-message">
                    <div className="invalid-feedback">{error}</div>
                    {email && error && (
                      <button
                        type="button"
                        onClick={RedirectLoginpage}
                        className="btn btn-link account-exist-login"
                      >
                        Login?
                      </button>
                    )}
                  </div>

                  {}
                </div>
              </div>
            </div>
            <div className="row container-footer py-3 px-3 mt-2 border-top">
              <div className="col-12 d-flex justify-content-end px-0">
                <button
                  type="button"
                  className="btn btn-primary btn-next-page"
                  onClick={verifyMobileDetails}
                  disabled={error}
                >
                  Next
                  <span className="px-3">
                    <img src={rightnormal} alt="right" className="right-icon" />
                  </span>
                </button>
                <input
                  type="button"
                  id="btnsubmit"
                  className="btn-dummy-page"
                  style={{ visibility: 'hidden' }}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
      {mobileVerficationPage && <PersonalDetailsVerification {...props} />}
    </>
  );
};

export default PasswordCreation;
