import React from 'react';
import loanFailed from '../../assets/image/Loan-Failed.svg';
import ContainerLayoutWrapper from '../containers/Layouts/ContainerLayoutWrapper';

const ServerErrorPage = () => (
  <>
    <ContainerLayoutWrapper>
      <div className="serverErrorblock">
        <img src={loanFailed} alt="servererror" className="serverError" />
        <div className="server-error-message">Could not load your loan information.</div>
        <div className="server-error-message">Please contact your broker.</div>
      </div>
    </ContainerLayoutWrapper>
  </>
);
export default ServerErrorPage;
