import validateField from './ValidateField';

const validateFields = (fields, tabName, state) => {
  const errors = [];

  fields.forEach((fieldName) => {
    const rulesForTheField = state[tabName].validationRules.find(
      (rule) => rule.fieldName === fieldName,
    );

    let value = null;

    const sharedMapping = state[tabName].sharedFields.find(
      (mapping) => mapping.fieldName === fieldName,
    );

    if (sharedMapping) {
      value = state[sharedMapping.tabName.toLowerCase()][sharedMapping.fieldName];
    } else {
      value = state[tabName][fieldName];
    }

    if (rulesForTheField && rulesForTheField.rules.length > 0) {
      const errorsForTheField = validateField(fieldName, value, rulesForTheField.rules);

      if (errorsForTheField) {
        errors.push({
          fieldName,
          messages: errorsForTheField,
        });
      }
    }
  });

  return errors;
};

export default validateFields;
