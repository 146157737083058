/* eslint-disable class-methods-use-this */
import { RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM } from '../Actions/applicants';
import MergeValidationErrorsImmutable from '../util/MergeValidationErrorsImmutable';

class BaseReducer {
  constructor(tabName) {
    this.baseUrl = process.env.REACT_APP_BASEURL;

    this.tabName = tabName;
  }

  getInitialActiveFields() {
    return [];
  }

  getInitialState() {
    return {
      validationRules: [],
      activeFields: this.getInitialActiveFields(),
      sharedFields: [],
      validationErrors: [],
      validationRemoveDependantFields: [],
    };
  }

  reduce(state = this.getInitialState(), action) {
    const { tabName } = this;

    switch (action.type) {
      case `CHANGE_VALUE_${tabName}`:
        return this.processChangeValue(state, action);

      case `LITE_CHANGE_VALUE_${tabName}`:
        return this.updateChangedValue(state, action.fieldName, action.value);

      case `UPDATE_VALIDATION_ERRORS_FOR_FIELD_${tabName}`:
        return {
          ...state,
          validationErrors: MergeValidationErrorsImmutable(
            state.validationErrors,
            action.errors,
            action.fieldName,
          ),
        };

      case `UPDATE_VALIDATION_ERRORS_FOR_TAB_${tabName}`:
        return { ...state, validationErrors: action.errors };

      case `NAVIGATE_TO_NEXT_TAB_${tabName}`:
        return { ...state, navigateTo: this.getNextTabUrl() };

      case `NAVIGATE_TO_URL_${tabName}`:
        return { ...state, navigateTo: action.url };

      case `RESET_NAVIGATE_TAB_${tabName}`:
        return { ...state, navigateTo: null };

      case `OPEN_DIALOG_${tabName}`:
        return { ...state, open: true };

      case `CLOSE_DIALOG_${tabName}`:
        return { ...state, open: false };

      case `INVALIDATE_FIELD_${tabName}`:
        return { ...state, open: true };

      case `RESET_ALL_DATA_${tabName}`:
        return this.getInitialState();

      // case RESET_ALL_APPLICANTS_DATA_FOR_NEW_FORM:
      //   return this.getInitialState();

      default:
        return state;
    }
  }

  processChangeValue(state, action) {
    let nextState = { ...state };
    nextState = this.updateChangedValue(nextState, action.fieldName, action.value);
    nextState = this.resetDependentFields(nextState, action.fieldName, state);
    nextState = this.setNextFieldToFocus(nextState, action.fieldName, action.value);
    nextState = this.setActiveFields(nextState);
    nextState = this.removeValidationErrorsForNonApplicableFields(nextState);
    return nextState;
  }

  updateChangedValue(nextState, fieldName, value) {
    return { ...nextState, [fieldName]: value };
  }

  resetDependentFields(nextState, fieldName, oldState) {
    const returnState = { ...nextState };
    if (oldState[fieldName] === nextState[fieldName]) return returnState;

    const dependentFields = this.getDependentFields(fieldName);

    if (dependentFields) {
      dependentFields.forEach((field) => {
        returnState[field] = null;
      });
    }

    return returnState;
  }

  setNextFieldToFocus(nextState, fieldName, fieldValue) {
    return {
      ...nextState,
      nextFieldNameToFocus: this.getNextFieldNameToFocus(fieldName, fieldValue, nextState),
    };
  }

  setActiveFields(nextState) {
    return { ...nextState, nextActiveFields: this.getNewActiveFields() };
  }

  removeValidationErrorsForNonActiveFields(nextState) {
    const returnState = { ...nextState };
    if (returnState.validationErrors.length < 1) return returnState;

    const fieldNames = returnState.validationErrors.map((element) => element.fieldName);

    for (let i = fieldNames.length - 1; i > -1; i -= 1) {
      const fieldName = fieldNames[i];

      if (returnState.activeFields.indexOf(fieldName) < 0) {
        const indexOfField = fieldNames.indexOf(fieldName);
        returnState.validationErrors.splice(indexOfField, 1);
        fieldNames.splice(indexOfField, 1);
      }
    }

    return returnState;
  }

  removeValidationErrorsForNonApplicableFields(nextState) {
    const returnState = { ...nextState };
    if (returnState.validationErrors.length < 1) return returnState;

    const fieldNames = returnState.validationErrors.map((element) => element.fieldName);

    const dependentValidations = returnState.validationRemoveDependantFields || [];

    dependentValidations.forEach((dependency) => {
      if (
        dependency.parentField &&
        dependency.dependentFields &&
        returnState[dependency.parentField]
      ) {
        dependency.dependentFields.forEach((dependentField) => {
          const indexOfField = fieldNames.indexOf(dependentField);
          if (indexOfField > -1) {
            returnState.validationErrors.splice(indexOfField, 1);
          }
        });
      }
    });

    return returnState;
  }

  getNextFieldNameToFocus() {
    return null;
  }

  getDependentFields() {
    return null;
  }

  getNewActiveFields() {
    return this.getInitialActiveFields();
  }

  getNextTabUrl() {
    return null;
  }
}

export default BaseReducer;
