import React from 'react';
import Container from 'react-bootstrap/Container';

const ContainerLayoutWrapper = ({ children }) => (
  <Container className="container-layout">
    <div className="content-wrapper">
      <div className="content-container content-container-wrapper">{children}</div>
    </div>
  </Container>
);

export default ContainerLayoutWrapper;
