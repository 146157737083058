import React, { useState } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';
import { CircularProgress } from '@material-ui/core';

const ConfirmDeleteRow = (props) => {
  const { onDelete, flagToDelete, deleteExp, deleteConfirmText, task, colSpan = 3 } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    onDelete(task)();
    !deleteExp && setIsLoading(true);
  };

  return (
    <>
      {task.flagDelete && (
        <td
          className={cls(
            'finances-card-table-detail-new',
            'delete-confirmation-col',
            'delete-confirmation',
          )}
          colSpan={colSpan}
        >
          <span className="confirm-msg">{deleteConfirmText}</span>
          <button type="button" className="btn btn-confirm" onClick={handleDelete}>
            {deleteExp || isLoading ? (
              <CircularProgress color="inherit" size={13} thickness={2.6} />
            ) : (
              'Yes, Delete'
            )}
          </button>
          <button
            type="button"
            disabled={deleteExp || isLoading}
            className="btn btn-cancel"
            onClick={flagToDelete(task.id)}
          >
            Cancel
          </button>
        </td>
      )}
    </>
  );
};

ConfirmDeleteRow.propTypes = {
  task: PropTypes.object.isRequired,
  deleteConfirmText: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  flagToDelete: PropTypes.func.isRequired,
};

export default ConfirmDeleteRow;
