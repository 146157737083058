import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Steps, { Step } from 'rc-steps';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import cls from 'classnames';
import SubSteps from './SubSteps';
import SideBarWrapper from '../SideBarWrapper';
import { APPLICANT_TYPE, ASSET_TYPES, ROUTES } from '../../util/constants';
import CheckSuccess from '../../../assets/image/nav-check.svg';
import { capitalize } from 'lodash';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

const getFilteredDynamicRoutes = (steps, stepperDetails) => {
  const stepperFormatted = [];
  steps.forEach((step) => {
    const formattedStep = { ...step };
    const validRoute = formattedStep.dynamicRoute
      ? stepperDetails && stepperDetails[formattedStep.dynamicRoute]
      : true;
    if (validRoute) {
      if (step.children) {
        formattedStep.children = getFilteredDynamicRoutes([...step.children], stepperDetails);
      }
      stepperFormatted.push(formattedStep);
    }
  });
  return stepperFormatted;
};

export const getCurrentStep = (steps, completedTabs, pqsubmitToLenderDate) => {
  let currIndex = 0;
  let currSubStep = 0;
  let completed = true;

  while (currIndex < steps.length && completed && !steps[currIndex].disabled) {
    const stepContainer = steps[currIndex];
    currSubStep = 0;
    if (stepContainer.children) {
      while (currSubStep < stepContainer.children.length && completed) {
        const currSubStepDetails = stepContainer.children[currSubStep];
        if (!completedTabs[currSubStepDetails.tabName] || currSubStepDetails.disabled) {
          completed = false;
        }
        currSubStep += 1;
      }
      currIndex += 1;
    }
  }
  return [
    currIndex === 0
      ? 0
      : pqsubmitToLenderDate === undefined || pqsubmitToLenderDate === ''
      ? currIndex - 1
      : currIndex,
    currSubStep === 0
      ? 0
      : pqsubmitToLenderDate === undefined || pqsubmitToLenderDate === ''
      ? currSubStep - 1
      : currSubStep,
  ];
};

const Stepper = ({
  disableSteps,
  disableSubSteps,
  validationErrors,
  noErrors,
  completedTabs,
  stepperDetails,
  hideSidebar,
  className,
  applicantType,
  contactDetails,
  isCarsalesAccount,
  carSalesOppCreated,
  assetType,
  pqsubmitToLenderDate,
}) => {
  const location = useLocation();
  const pathname = location?.pathname;
  const updatedAssetType = assetType?.toLowerCase().replace(/\s+/g, '');

  const findAssetType = ASSET_TYPES.find((e) => {
    return e.value?.toLocaleLowerCase() === assetType?.toLowerCase();
  });
  useEffect(() => {
    if (
      pathname === `${ROUTES.contactDetails}/${APPLICANT_TYPE.coApplicant}` ||
      pathname === `${ROUTES.contactDetails}/${APPLICANT_TYPE.spouse}` ||
      contactDetails
    ) {
      // for coApplicant and spouse . tab is enabled when form validation from primary is completed
      setPartnerTab(true);
    } else if (
      pathname === `${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.spouse}` ||
      pathname === `${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.coApplicant}`
    ) {
      setUploadPartnerTab(true);
    }
  }, [pathname]);
  const [partnerTab, setPartnerTab] = useState(false);
  const [uploadPartnerTab, setUploadPartnerTab] = useState(false);
  const configuredSteps = [
    {
      label: 'Repayment Estimation',
      subTitle: '10 mins',
      children: [
        {
          label: capitalize(findAssetType?.name.toLowerCase()),
          path: ROUTES[updatedAssetType],
          tabName: 'car',
        },
        {
          label: 'Personal',
          path: isCarsalesAccount || carSalesOppCreated ? ROUTES.contactDetails : ROUTES.personal,
          tabName: 'applicants',
        },
        { label: 'Finances', path: ROUTES.applicants, tabName: 'finance_details' },
      ],
      sectionCompleted:
        completedTabs.car &&
        completedTabs.estimation &&
        completedTabs.applicants &&
        completedTabs.finance_details,
    },
    {
      label: 'Compare loan options',
      subTitle: '5 mins',
      children: [
        {
          label: 'Compare',
          path: ROUTES.compareLoanOptions,
          tabName: 'compare_loan_options',
        },
      ],
      sectionCompleted: completedTabs.compare_loan_options,
    },
    {
      label: 'Get Approved',
      subTitle: '1-2 Days',
      children: [
        {
          label: 'Additional Information',
          path: `${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.primary}`,
          tabName: 'addtional_information',
          children: [
            {
              label: 'Primary Applicant',
              path: `${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.primary}`,
              tabName: 'address_details',
              completed: 2,
              pending: 0,
            },
            {
              label: 'Spouse',
              dynamicRoute: 'spouseDynamicLabel',
              path: !contactDetails
                ? `${ROUTES.contactDetails}/${APPLICANT_TYPE.spouse}`
                : `${ROUTES.employmentDetailsUri}/${APPLICANT_TYPE.spouse}`,
              tabName: 'address_details',
              completed: 5,
              pending: 0,
              disabled: !partnerTab,
            },
            {
              label: 'Co-Applicant',
              dynamicRoute: 'coApplicantDynamicLabel',
              path: !contactDetails
                ? `${ROUTES.contactDetails}/${APPLICANT_TYPE.coApplicant}`
                : `${ROUTES.addressDetailsUri}/${APPLICANT_TYPE.coApplicant}`,
              tabName: 'address_details',
              completed: 5,
              pending: 0,
              disabled: !partnerTab,
            },
          ],
        },
        {
          label: 'Upload documents',
          path: `${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.primary}`,
          disabled: !completedTabs.addtional_information,
          tabName: 'upload_document',
          children: [
            {
              label: 'Primary Applicant',
              path: `${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.primary}`,
              tabName: 'upload_document',
              completed: 1,
              pending: 0,
            },
            {
              label: 'Spouse',
              dynamicRoute: 'spouseDynamicLabel',
              path: `${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.spouse}`,
              tabName: 'upload_document',
              completed: 2,
              pending: 0,
              disabled: !partnerTab,
            },
            {
              label: 'Co-Applicant',
              dynamicRoute: 'coApplicantDynamicLabel',
              path: `${ROUTES.uploadDocDetailsUri}/${APPLICANT_TYPE.coApplicant}`,
              tabName: 'upload_document',
              completed: 2,
              pending: 0,
              disabled: !partnerTab,
            },
          ],
        },
      ],
      sectionCompleted: completedTabs.upload_document,
    },
    {
      label: 'Prepare for Settlement',
      disabled: true,
      sectionCompleted: false,
    },
  ];
  const steps = getFilteredDynamicRoutes(configuredSteps, stepperDetails);
  const [currentStep, currSubStep] = getCurrentStep(steps, completedTabs, pqsubmitToLenderDate);
  const [expanded, setExpand] = useState(0);
  const [currChildStep, updateChildStep] = useState(currSubStep || 0);

  useEffect(() => {
    setExpand(currentStep);
  }, [currentStep]);

  const handleStepChange = (idx) => {
    if (!disableSteps) setExpand(idx);
  };

  const handleSubStepChange = (idx) => {
    if (!disableSubSteps) updateChildStep(idx);
  };

  return (
    <SideBarWrapper
      applicantType={applicantType}
      pathName={pathname}
      stepNumber={expanded + 1}
      currentStep={steps[expanded]}
      currentSubStep={
        steps[expanded] && steps[expanded].children && steps[expanded].children[currChildStep]
      }
      hideSidebar={hideSidebar}
    >
      <div className={cls(className, 'navigation-stepper')}>
        <Steps
          current={currentStep}
          className="stepper"
          direction="vertical"
          // onChange={handleStepChange}
        >
          {steps.map((step, idx) => (
            <Step
              icon={
                idx < currentStep ? (
                  <img src={CheckSuccess} className="nav-checksuccess" alt="car" />
                ) : (
                  idx + 1
                )
              }
              key={step.label || idx}
              title={<div className="step-title">{step.label}</div>}
              subTitle={step.subTitle}
              disabled={step.disabled || disableSteps || idx > currentStep}
              onClick={(event) => {
                event.preventDefault();
                if (!step.disabled && !disableSteps) {
                  if (step.sectionCompleted || idx <= currentStep) {
                    handleStepChange(idx);
                  } else if (currentStep < idx && !step.sectionCompleted) {
                  }
                }
              }}
              description={
                step.children &&
                !step.disabled &&
                expanded === idx &&
                !step.sectionCompleted && (
                  <SubSteps
                    steps={step.children}
                    currentStep={idx < currentStep ? step.children.length - 1 : currChildStep}
                    substepOnChange={handleSubStepChange}
                    disableSubSteps={disableSubSteps}
                    validationErrors={validationErrors}
                    completedTabs={completedTabs}
                    noErrors={noErrors}
                  />
                )
              }
            />
          ))}
        </Steps>
      </div>
    </SideBarWrapper>
  );
};

Stepper.defaultProps = {
  className: '',
};

Stepper.propTypes = {
  onChange: PropTypes.func,
  substepOnChange: PropTypes.func,
  currentStep: PropTypes.string,
  currentSubStep: PropTypes.string,
  disableSubSteps: PropTypes.bool.isRequired,
  className: PropTypes.string,
  applicantType: PropTypes.string,
};

export default connect((state) => ({
  completedTabs: state.tab.completedTabs,
  stepperDetails: state.stepperDetails,
  contactDetails: state.applicants.contactDetails,
  isCarsalesAccount: state.account.isCarsalesAccount,
  carSalesOppCreated: state.car.carSalesOppCreated,
  assetType: state.car.assetType,
  pqsubmitToLenderDate: state.applicants.pqsubmitToLenderDate,
}))(Stepper);
