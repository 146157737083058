import moment from 'moment';
import invert from 'lodash/invert';

import passportDocImg from '../../assets/image/Union.svg';
import driverLicenceDocImg from '../../assets/image/Document.svg';
import Pepper from '../../assets/image/Peppermoney.svg';
import Volkswagen from '../../assets/image/Volkswagon.svg';
import Plenti from '../../assets/image/Plenti.svg';
import Macquarie from '../../assets/image/Macquarie.svg';
import Metro from '../../assets/image/Metro.svg';
import Liberty from '../../assets/image/Liberty.svg';
import Westpac from '../../assets/image/Westpac.svg';
import AngleAutoFinance from '../../assets/image/AngleAutoFinance.svg';
import Anz from '../../assets/image/Anz.svg';
import Boq from '../../assets/image/Boq.svg';
import FinanceOne from '../../assets/image/FinanceOne.svg';
import Firstmac from '../../assets/image/Firstmac.svg';
import FlexCommercial from '../../assets/image/FlexCommercial.svg';
import Latitude from '../../assets/image/Latitude.svg';
import Money3 from '../../assets/image/Money3.svg';
import Moneyplace from '../../assets/image/Moneyplace.svg';
import NowFinance from '../../assets/image/NowFinance.svg';
import AffordableCarLoans from '../../assets/image/AffordableCarLoans.svg';
import CarExpert from '../../assets/image/CarExpert.svg';
import Clearscore from '../../assets/image/Clearscore.svg';
import Drive from '../../assets/image/Drive.svg';
import Finder from '../../assets/image/Finder.svg';
import GetCreditScore from '../../assets/image/GetCreditScore.svg';
import RateCity from '../../assets/image/RateCity.svg';
import GumtreeCars from '../../assets/image/GumtreeCars.svg';
import ClearscoreTransparent from '../../assets/image/ClearscoreTransparent.svg';
import CarExpertTransparent from '../../assets/image/CarExpertTransparent.svg';
import GetCreditScoreTransparent from '../../assets/image/GetCreditScoreTransparent.svg';
import Carsales from '../../assets/image/carsales.svg';
import Boatsales from '../../assets/image/boatsales.svg';
import Caravancampingsales from '../../assets/image/caravancampingsales.svg';
import NewAgeCaravansSvg from '../../assets/image/New_Age_Caravans.svg';

export const PARTNER_IMAGE_LIST = [
  { id: 'carexpert', value: CarExpert },
  { id: 'finder', value: Finder },
  { id: 'ratecity', value: RateCity },
  { id: 'get_credit_score_home_page', value: GetCreditScore },
  { id: 'Clearscore', value: Clearscore },
];
export const DOCUMENT_TYPE = {
  TRUST_DEED: 'Trust deed',
  PAYSLIP: 'Payslip',
};
export const SETTLEMENT = 'settlement';
export const NEW_PARTNER_IMAGE_LIST = [
  {
    id: 'drive',
    imageSrc: Drive,
    headerText:
      'We have a panel of lenders who offer secured car loan comparison rates from 6.95% p.a. to 18.00% p.a.^',
    description:
      'Information is current at 01/12/2023. All applications for credit are subject to lender assessment and eligibility criteria. Terms, conditions, fees and charges apply. Comparison rate is calculated on a secured loan of $30,000 for a term of 5 years.',
    descrptionMore:
      'This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. The actual interest rate will depend on the applicant’s circumstances and the information verified during the application assessment.',
  },
  {
    id: 'carsales',
    imageSrc: Carsales,
    headerText:
      'We have a panel of lenders who offer secured car loan comparison rates from 6.95% p.a. to 18.00% p.a.^',
    description:
      'Information is current at 01/12/2023. All applications for credit are subject to lender assessment and eligibility criteria. Terms, conditions, fees and charges apply. Comparison rate is calculated on a secured loan of $30,000 for a term of 5 years.',
    descrptionMore:
      'This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. The actual interest rate will depend on the applicant’s circumstances and the information verified during the application assessment.',
  },
  {
    id: 'caravancampingsales',
    imageSrc: Caravancampingsales,
    headerText:
      'We have a panel of lenders who offer secured caravan loan comparison rates from 8.18% p.a. to 18.00% p.a.^',
    description:
      'We have a panel of lenders who offer secured caravan loan comparison rates from 8.18% p.a. to 18.00% p.a. Information is current at 15/9/23. All applications for credit are subject to lender assessment and eligibility criteria. Terms, conditions, fees and charges apply. Comparison rate is calculated on a secured loan of $30,000 for a term of 5 years.',
    descrptionMore:
      'This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. The actual interest rate will depend on the applicant’s circumstances and the information verified during the application assessment.',
  },
  {
    id: 'boatsales',
    imageSrc: Boatsales,
    headerText:
      'We have a panel of lenders who offer secured marine loan comparison rates from 8.18% p.a. to 18.00% p.a.^',
    description:
      'Information is current at 15/9/23. All applications for credit are subject to lender assessment and eligibility criteria. Terms, conditions, fees and charges apply. Comparison rate is calculated on a secured loan of $30,000 for a term of 5 years.',
    descrptionMore:
      'This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. The actual interest rate will depend on the applicant’s circumstances and the information verified during the application assessment.',
  },
  {
    id: 'finder',
    imageSrc: Finder,
    headerText:
      'We have a panel of lenders who offer secured car loan comparison rates from 6.95% p.a to 18.00% p.a.^',
    description:
      'Information is current at 01/12/2023. All applications for credit are subject to lender assessment and eligibility criteria. Terms, conditions, fees and charges apply. Comparison rate is calculated on a secured loan of $30,000 for a term of 5 years.',
    descrptionMore:
      'This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. The actual interest rate will depend on the applicant’s circumstances and the information verified during the application assessment.',
  },
  {
    id: 'ratecity',
    imageSrc: RateCity,
    headerText:
      'We have a panel of lenders who offer secured caravan loan comparison rates from 6.95% p.a to 18.00% p.a.^',
    description:
      'Information is current at 01/12/2023. All applications for credit are subject to lender assessment and eligibility criteria. Terms, conditions, fees and charges apply. Comparison rate is calculated on a secured loan of $30,000 for a term of 5 years.',
    descrptionMore:
      'This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. The actual interest rate will depend on the applicant’s circumstances and the information verified during the application assessment.',
  },
  {
    id: 'Gumtree_Cars',
    imageSrc: GumtreeCars,
    headerText:
      'We have a panel of lenders who offer secured car loan comparison rates from 6.95% p.a. to 18.00% p.a.^',
    description:
      'Information is current at 01/12/2023. All applications for credit are subject to lender assessment and eligibility criteria. Terms, conditions, fees and charges apply. Comparison rate is calculated on a secured loan of $30,000 for a term of 5 years.',
    descrptionMore:
      'This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. The actual interest rate will depend on the applicant’s circumstances and the information verified during the application assessment.',
  },
  {
    id: 'facebook',
    imageSrc: null,
    headerText:
      'We have a panel of lenders who offer secured car loan comparison rates from 6.95% p.a. to 18.00% p.a.^',
    description:
      'Information is current at 26/04/2024. All applications for credit are subject to lender assessment and eligibility criteria. Terms, conditions, fees and charges apply. Comparison rate is calculated on a secured loan of $30,000 for a term of 5 years.',
    descrptionMore:
      'This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. The actual interest rate will depend on the applicant’s circumstances and the information verified during the application assessment.',
  },
  {
    id: 'New_Age_Caravans',
    imageSrc: NewAgeCaravansSvg,
    headerText:
      'We have a panel of lenders who offer secured car loan comparison rates from 4.99% p.a. to 18.00% p.a.^',
    description:
      'Information is current at 01/04/2024. All applications for credit are subject to lender assessment and eligibility criteria. Terms, conditions, fees and charges apply. Comparison rate is calculated on a secured loan of $30,000 for a term of 5 years.',
    descrptionMore:
      'This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate. The actual interest rate will depend on the applicant’s circumstances and the information verified during the application assessment.',
  },
];

export const PARTNER_IMAGE_LISTS = [
  { id: 'drive', value: Drive },
  { id: 'carexpert', value: CarExpertTransparent },
  { id: 'finder', value: Finder },
  { id: 'ratecity', value: RateCity },
  { id: 'get_credit_score_home_page', value: GetCreditScoreTransparent },
  { id: 'Clearscore', value: ClearscoreTransparent },
];

export const CAPTECHA_SCORE_VAL = 0.4;

export const STATES = ['NSW', 'ACT', 'VIC', 'QLD', 'WA', 'NT', 'SA', 'TAS'];
export const TITLE = ['Mr', 'Mrs', 'Miss', 'Ms'];
const currentData = new Date();
const currentYear = currentData.getFullYear();
export const formattedCurrentDate = moment().format('YYYY-MM-DD');
export const YEARS = [
  String(currentYear),
  String(currentYear - 1),
  String(currentYear - 2),
  String(currentYear - 3),
];

export const uploadFileSize = 8388608; // maximum size of file upload parameter.
export const allowedFileTypes = ['application/pdf', 'image/jpeg', 'image/heic', 'image/png'];

export const otherVehicle = ['Boat', 'Caravan', 'Bike'];

export const DLTYPE = ['Full', 'Provisional', 'Learner'];
export const DLSTATE = ['NSW', 'ACT', 'VIC', 'QLD', 'WA', 'NT', 'SA', 'TAS'];
export const NoOfYearsOld = [
  { name: '0-3 Years', value: 3 },
  { name: '4-6 Years', value: 6 },
  { name: '7-10 Years', value: 10 },
];
export const TRUSTEE_TYPES = [
  { name: 'Individual', value: 'Individual' },
  { name: 'Partnership', value: 'Partnership' },
  { name: 'Company', value: 'Company' },
];
export const CAR_CONDITION = [
  { name: 'New', value: 'New' },
  { name: 'Used', value: 'Used' },
  { name: 'Demo', value: 'Demo' },
  { name: 'Unsure', value: 'Unsure' },
];
export const PURCHASE_SOURCE = [
  { name: 'Private Seller', value: 'privateSeller' },
  { name: 'Dealer', value: 'Dealer' },
  { name: 'Auction', value: 'Auction' },
];
export const PURCHASE_USE = [
  { name: 'Personal', value: 'Personal' },
  { name: 'Business', value: 'Business' },
];

export const BUSINESS = 'Business';

export const HOME_OWNER = 'PropertyOwner';
export const RENTER = 'Renting';
export const BOARDER = 'Boarding';
export const OTHER = 'LivingWithRelatives';
export const ASSET_OTHER = 'other';

export const LIVING_SITUATION = [
  { name: 'Property owner', value: HOME_OWNER },
  { name: 'Renting', value: RENTER },
  { name: 'Boarding', value: BOARDER },
  { name: 'Living with relatives', value: OTHER },
];

export const CITIZEN = 'Citizen';
export const PERMANENT_RESIDENT = 'Permanent Resident';
export const WORKING_VISA = 'WorkVisa';
export const OTHER_VISA = 'Other Visa';

export const COMMERCIAL = 'COMMERCIAL EQUIPMENT';
export const CAR = 'CAR';
export const BOAT = 'BOAT';
export const CARAVAN = 'CARAVAN';
export const BIKE = 'BIKE';
export const UNSECUREDLOAN = 'UNSECURED LOAN';

export const CITIZENSHIP_STATUS = [
  { name: 'Australian citizen', value: CITIZEN },
  { name: 'Permanent Visa', value: PERMANENT_RESIDENT },
  { name: 'Working Visa', value: WORKING_VISA },
  { name: 'Other Visa', value: OTHER_VISA },
];

export const DEPENDENTS = [
  { name: '0', value: '0' },
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5+', value: '5' },
];

export const TERM = [
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5', value: '5' },
  { name: '6', value: '6' },
  { name: '7', value: '7' },
];

export const SINGLE = 'Single';
export const MARRIED = 'Married';
export const DEFACTO = 'Defacto';
export const OTHER_VAL = 'Other';

export const RELATIONSHIP = [
  { name: 'Single', value: SINGLE },
  { name: 'Married', value: MARRIED },
  { name: 'Defacto', value: DEFACTO },
  { name: 'Other', value: OTHER_VAL },
];
export const COAPP_RELATIONSHIP = [
  { name: 'Married', value: MARRIED },
  { name: 'Defacto', value: DEFACTO },
];
export const TOP_CAR_MAKES = [
  'AUDI',
  'BMW',
  'FORD',
  'HYUNDAI',
  'MAZDA',
  'MERCEDES-BENZ',
  'MITSUBISHI',
  'NISSAN',
  'SUBARU',
  'TOYOTA',
  'VOLVO',
  'VOLKSWAGEN',
];
export const CARMODEL = ['Accent', 'Elantra', 'I30', 'Imax', 'Ioniq', 'Kona', 'Santafe'];
export const CARVARIENT = ['Active', 'Electric', 'Elite', 'Go', 'highlander', 'Ironman Edition'];
export const CARSERIES = ['Active', 'Electric', 'Elite', 'Go', 'highlander', 'Ironman Edition'];
export const CARBODYTYPE = ['4 cylinder Petrol Aspirated 2.0L', '4 cylinder Petrol Aspirated 1.8L'];
const ADDRESS_DETAILS_URI = '/details/address';
const CONTACT_DETAILS_URI = '/details/contactDetails';
const UPLOAD_DOCUMENT_URI = '/details/uploadDocuments';
const IDENTITY_DETAILS_URI = '/details/identity';
const LIVING_DETAILS_URI = '/details/living';
const EMPLOYMENTS_DETAILS_URI = '/details/employmentDetails';
export const APPLICATION_STRUCTURE = {
  singleApplicant: 'SingleApplicant',
  singleApplicantSpouse: 'SingleApplicantSpouse',
  coApplicant: 'CoApplicant',
};
export const APPLICANT_TYPE = {
  primary: 'primary',
  spouse: 'spouse',
  coApplicant: 'coApplicant',
};
export const ROUTES = {
  initial: '/',
  initialDetails: '/details/carMainpage',
  car: '/details/carMainPage',
  estimation: '/details/estimation',
  estimateLoading: '/details/estimation/loading',
  personal: '/details/personal',
  applicants: '/details/finances',
  loan: '/details/loan-details',
  dashboardverification: '/details/dashboardverification',
  verifyOTP: '/details/verifyOTP',
  login: '/login',
  signUp: '/signup',
  commercialAssetType: '/details/commercialAssetsMainpage',
  commercialequipment: '/details/commercialequipment',
  boat: '/details/boatMainPage',
  caravan: '/details/caravanMainPage',
  bike: '/details/bikeMainPage',
  unsecuredloan: '/details/unsecuredloanMainPage',
  loginReset: '/login/reset',
  dashboard: '/dashboard',
  financeComplete: '/financeComplete',
  edmenquiry: '/edmenquiry',
  initialPersonalDetails: '/details/personalStart',
  initialFinanceDetails: '/details/financeStart',
  compareLoanOptions: '/details/loan/compare',
  updateLoanAmount: '/details/update',
  customerdashboard: '/dashboard/loandetails',
  verification: '/details/verification',
  contactDetails: CONTACT_DETAILS_URI,
  personalDetails: `${CONTACT_DETAILS_URI}/:applicantType`,
  coApplicantIdentityDetailsUri: IDENTITY_DETAILS_URI,
  coApplicantIdentityDetails: `${IDENTITY_DETAILS_URI}/:applicantType`,
  coApplicantPersonalDetailsUri: LIVING_DETAILS_URI,
  coApplicantPersonalDetails: `${LIVING_DETAILS_URI}/:applicantType`,
  addressDetailsUri: ADDRESS_DETAILS_URI,
  addressDetails: `${ADDRESS_DETAILS_URI}/:applicantType`,
  employmentDetailsUri: EMPLOYMENTS_DETAILS_URI,
  employmentDetails: `${EMPLOYMENTS_DETAILS_URI}/:applicantType`,
  uploadDocDetailsUri: `${UPLOAD_DOCUMENT_URI}`,
  uploadDocuments: `${UPLOAD_DOCUMENT_URI}/:applicantType`,
};

export const ASSET_ROUTES = {
  car: 'car',
  commercialAssetType: 'commercialAssetsMainpage',
  personalLoan: 'unsecuredloanMainPage',
  commercialequipment: 'commercialequipment',
};

const APP_NAME = 'Stratton Finance Quote';
export const PAGE_TITLE = {
  default: `${APP_NAME} Estimate`,
  [ROUTES.car]: `${APP_NAME} - Car Details`,
  [ROUTES.applicants]: `${APP_NAME} - Finance Details`,
  [ROUTES.personal]: `${APP_NAME} - Personal Details`,
};

export const DOCTYPE = [
  { name: 'Bank Statement (PDF)', value: 'Bank Statement (PDF)' },
  { name: 'Payslip 1 - Primary Applicant', value: 'Payslip 1 - Primary Applicant' },
  { name: 'Payslip 2- Primary Applicant', value: 'Payslip 2- Primary Applicant' },
  { name: 'Tax Return', value: 'Tax Return' },
  { name: 'Notice of Assessment', value: 'Notice of Assessment' },
  { name: 'Payslip 1 - Spouse', value: 'Payslip 1 - Spouse' },
  { name: 'Payslip 2 - Spouse', value: 'Payslip 2 - Spouse' },
  { name: 'Rental Statement', value: 'Rental Statement' },
];

export const HOME_PROPERTY = 'homeproperty';
export const INVESTMENT_PROPERTY = 'investmentproperty';
export const SAVINGS = 'savings';
export const SUPERANNUATION = 'superannuation';
export const HOMECONTENTS = 'homecontent';
export const RECREATIONAL = 'recreational';
export const MOTORVEHICLE = 'motorvehicle';
export const ASSETS_LABEL_VALUE_MAP = {
  [HOME_PROPERTY]: 'Property (Home)',
  [INVESTMENT_PROPERTY]: 'Property (Investment)',
  [SAVINGS]: 'Savings',
  [OTHER]: 'Shares, Trusts, Funds & Financial deposits',
  [MOTORVEHICLE]: 'Motor Vehicle',
  [SUPERANNUATION]: 'Superannuation',
  [RECREATIONAL]: 'Recreational Assets',
  [HOMECONTENTS]: 'Home & Contents',
};

export const CAR_ASSET = 'Car';
export const COMMERCIAL_EQUIPMENT = 'Commercial Equipment';
export const UNSECURED_LOAN = 'Unsecured Loan';

export const COMMERCIAL_EQUIPMENT_TYPES = [
  { name: 'Agricultural Equipment', value: 'Agricultural Equipment' },
  { name: 'Aircraft', value: 'Aircraft' },
  { name: 'Commercial Facility', value: 'Commercial facility' },
  { name: 'Commercial Fitout', value: 'Commercial fitout' },
  { name: 'Commercial property', value: 'Commercial property' },
  { name: 'Commercial Heavy Vehicles', value: 'Commercial heavy vehicles' },
  { name: 'Gym Equipment', value: 'Gym equipment' },
  { name: 'Industrial Equipment', value: 'Industrial Equipment' },
  { name: 'IT - Hardware', value: 'IT hardware' },
  { name: 'IT - Software', value: 'IT software' },
  { name: 'Medical Equipment', value: 'Medical equipment' },
  { name: 'Office Equipment', value: 'Office equipment' },
  { name: 'Plant and Machinery', value: 'Plant and machinery' },
  { name: 'Solar & Green', value: 'Solar and Green' },
  { name: 'Trailer', value: 'Trailer' },
  { name: 'Yellow Goods', value: 'Yellow goods' },
  { name: 'Other equipment', value: 'Other equipment' },
];

export const AssetTypes = [
  { name: ASSETS_LABEL_VALUE_MAP[HOME_PROPERTY], value: HOME_PROPERTY },
  { name: ASSETS_LABEL_VALUE_MAP[INVESTMENT_PROPERTY], value: INVESTMENT_PROPERTY },
  { name: ASSETS_LABEL_VALUE_MAP[SAVINGS], value: SAVINGS },
  { name: ASSETS_LABEL_VALUE_MAP[OTHER], value: 'other' },
  { name: ASSETS_LABEL_VALUE_MAP[MOTORVEHICLE], value: MOTORVEHICLE },
  { name: ASSETS_LABEL_VALUE_MAP[SUPERANNUATION], value: SUPERANNUATION },
  { name: ASSETS_LABEL_VALUE_MAP[RECREATIONAL], value: RECREATIONAL },
  { name: ASSETS_LABEL_VALUE_MAP[HOMECONTENTS], value: HOMECONTENTS },
];

export const AssetTypesWithoutSaving = [
  { name: ASSETS_LABEL_VALUE_MAP[INVESTMENT_PROPERTY], value: INVESTMENT_PROPERTY },
  { name: ASSETS_LABEL_VALUE_MAP[OTHER], value: 'other' },
  { name: ASSETS_LABEL_VALUE_MAP[MOTORVEHICLE], value: MOTORVEHICLE },
  { name: ASSETS_LABEL_VALUE_MAP[SUPERANNUATION], value: SUPERANNUATION },
  { name: ASSETS_LABEL_VALUE_MAP[RECREATIONAL], value: RECREATIONAL },
  { name: ASSETS_LABEL_VALUE_MAP[HOMECONTENTS], value: HOMECONTENTS },
];

export const LIABILITY_MORTGAGE_OPTION = 'Property(Home) Mortgage';
export const LIABILITY_MORTGAGE_INVEST_OPTION = 'Property(Investment)Mortgage';
export const LIABILITY_PERSONAL_LOAN_OPTION = 'Personal loan';
export const LIABILITY_CREDIT_CARD_OPTION = 'Credit Card';
export const LIABILITY_OVERDRAFT_OPTION = 'Overdraft';
export const LIABILITY_VEHICAL_LOAN_OPTION = 'Motor Vehicle loan';
export const LIABILITY_ASSET_LOAN_OPTION = 'Recreational asset loan';
export const LIABILITY_DEBT_AGREEMENT_OPTION = 'Debt agreement';
export const LIABILITY_TAX_DEBT_OPTION = 'Tax debt';
export const PAYSLIP1 = 'Pay slip 1';
export const PAYSLIP2 = 'Pay slip 2';
export const LIABILITIES_LABEL_VALUE_MAP = {
  [LIABILITY_MORTGAGE_OPTION]: 'Mortgage (Home)',
  [LIABILITY_MORTGAGE_INVEST_OPTION]: 'Mortgage (Investment)',
  [LIABILITY_PERSONAL_LOAN_OPTION]: 'Personal Loan',
  [LIABILITY_CREDIT_CARD_OPTION]: 'Credit Card',
  [LIABILITY_OVERDRAFT_OPTION]: 'Overdraft',
  [LIABILITY_VEHICAL_LOAN_OPTION]: 'Motor Vehicle Loan',
  [LIABILITY_ASSET_LOAN_OPTION]: 'Recreational Assets',
  [LIABILITY_DEBT_AGREEMENT_OPTION]: 'Debt Agreement',
  [LIABILITY_TAX_DEBT_OPTION]: 'Tax Debt',
};
export const LIABILITIES_TYPES = [
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_MORTGAGE_OPTION],
    value: LIABILITY_MORTGAGE_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_MORTGAGE_INVEST_OPTION],
    value: LIABILITY_MORTGAGE_INVEST_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_PERSONAL_LOAN_OPTION],
    value: LIABILITY_PERSONAL_LOAN_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_CREDIT_CARD_OPTION],
    value: LIABILITY_CREDIT_CARD_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_OVERDRAFT_OPTION],
    value: LIABILITY_OVERDRAFT_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_VEHICAL_LOAN_OPTION],
    value: LIABILITY_VEHICAL_LOAN_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_ASSET_LOAN_OPTION],
    value: LIABILITY_ASSET_LOAN_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_DEBT_AGREEMENT_OPTION],
    value: LIABILITY_DEBT_AGREEMENT_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_TAX_DEBT_OPTION],
    value: LIABILITY_TAX_DEBT_OPTION,
  },
];

export const LIABILITIES_TYPES_WITHOUT_MORTGAGE = [
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_MORTGAGE_INVEST_OPTION],
    value: LIABILITY_MORTGAGE_INVEST_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_PERSONAL_LOAN_OPTION],
    value: LIABILITY_PERSONAL_LOAN_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_CREDIT_CARD_OPTION],
    value: LIABILITY_CREDIT_CARD_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_OVERDRAFT_OPTION],
    value: LIABILITY_OVERDRAFT_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_VEHICAL_LOAN_OPTION],
    value: LIABILITY_VEHICAL_LOAN_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_ASSET_LOAN_OPTION],
    value: LIABILITY_ASSET_LOAN_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_DEBT_AGREEMENT_OPTION],
    value: LIABILITY_DEBT_AGREEMENT_OPTION,
  },
  {
    name: LIABILITIES_LABEL_VALUE_MAP[LIABILITY_TAX_DEBT_OPTION],
    value: LIABILITY_TAX_DEBT_OPTION,
  },
];

export const ASSET_RELATED_LIABILITIES = {
  [MOTORVEHICLE]: LIABILITY_VEHICAL_LOAN_OPTION,
  [HOME_PROPERTY]: LIABILITY_MORTGAGE_OPTION,
  [INVESTMENT_PROPERTY]: LIABILITY_MORTGAGE_INVEST_OPTION,
  [RECREATIONAL]: LIABILITY_ASSET_LOAN_OPTION,
};

export const LIABILITIES_RELATED_ASSET = invert(ASSET_RELATED_LIABILITIES);

export const IdentityDoc = [
  { name: 'Drivers Licence', value: 'drivinglicence', image: driverLicenceDocImg },
  { name: 'Passport', value: 'passport', image: passportDocImg },
];

export const MONTH_OPTION = 'Monthly';
export const FORTNIGHT_OPTION = 'Fortnightly';
export const WEEK_OPTION = 'Weekly';

export const FREQ_OPTIONS = [
  { name: 'Weekly', value: WEEK_OPTION },
  { name: 'Fortnightly', value: FORTNIGHT_OPTION },
  { name: 'Monthly', value: MONTH_OPTION },
];

export const EMPSTATUS_FULL = 'Full Time';
export const EMPSTATUS_PART = 'Part Time';
export const EMPSTATUS_CASUAL = 'Casual';
export const EMPTYPE_SELF = 'Self Employed';
export const EMPSTATUS_RETIRED = 'Retired';
export const EMPSTATUS_UNEMPLOYED = 'Unemployed';

export const EMPLOYMENT_STATUS_PERSONAL = [
  { name: 'Full time', value: EMPSTATUS_FULL, isDisabled: false },
  { name: 'Part time', value: EMPSTATUS_PART, isDisabled: false },
  { name: 'Casual', value: EMPSTATUS_CASUAL, isDisabled: false },
  { name: 'Self Employed', value: EMPTYPE_SELF, isDisabled: false },
  { name: 'Retired', value: EMPSTATUS_RETIRED, isDisabled: false },
  { name: 'Unemployed', value: EMPSTATUS_UNEMPLOYED, isDisabled: false },
];
export const SELF_EMPLOYMENT_STATUS = [
  { name: 'Full time', value: EMPSTATUS_FULL, isDisabled: true },
  { name: 'Part time', value: EMPSTATUS_PART, isDisabled: true },
  { name: 'Casual', value: EMPSTATUS_CASUAL, isDisabled: true },
  { name: 'Self Employed', value: EMPTYPE_SELF, isDisabled: false },
  { name: 'Retired', value: EMPSTATUS_RETIRED, isDisabled: true },
  { name: 'Unemployed', value: EMPSTATUS_UNEMPLOYED, isDisabled: true },
];
export const PAYG_EMPLOYMENT_STATUS = [
  { name: 'Full time', value: EMPSTATUS_FULL, isDisabled: false },
  { name: 'Part time', value: EMPSTATUS_PART, isDisabled: false },
  { name: 'Casual', value: EMPSTATUS_CASUAL, isDisabled: false },
  { name: 'Self Employed', value: EMPTYPE_SELF, isDisabled: true },
  { name: 'Retired', value: EMPSTATUS_RETIRED, isDisabled: true },
  { name: 'Unemployed', value: EMPSTATUS_UNEMPLOYED, isDisabled: true },
];
export const EMPLOYMENT_STATUS_ADDITIONAL = [
  { name: 'Full time', value: EMPSTATUS_FULL },
  { name: 'Part time', value: EMPSTATUS_PART },
  { name: 'Casual', value: EMPSTATUS_CASUAL },
  { name: 'Self Employed', value: EMPTYPE_SELF },
];
export const EMPLOYMENT_STATUS_SKIP_SELFEMP = [
  { name: 'Full time', value: EMPSTATUS_FULL },
  { name: 'Part time', value: EMPSTATUS_PART },
  { name: 'Casual', value: EMPSTATUS_CASUAL },
  { name: 'Retired', value: EMPSTATUS_RETIRED },
  { name: 'Unemployed', value: EMPSTATUS_UNEMPLOYED },
];
export const EMPLOYMENT_STATUS_BUSINESS = [
  { name: 'Full time', value: EMPSTATUS_FULL },
  { name: 'Part time', value: EMPSTATUS_PART },
  { name: 'Casual', value: EMPSTATUS_CASUAL },
];
export const EMPLOYMENT_TYPE = [
  { name: 'Full time', value: EMPSTATUS_FULL },
  { name: 'Part time', value: EMPSTATUS_PART },
  { name: 'Casual', value: EMPSTATUS_CASUAL },
  { name: 'Self Employed', value: EMPTYPE_SELF },
  { name: 'Unemployed', value: EMPSTATUS_UNEMPLOYED },
];
export const MAIN_EMPLOYMENT_SELECT = [
  { name: 'Yes', value: 'Yes' },
  { name: 'No', value: 'No' },
];
export const INCOME_TYPES = [
  { name: 'Rental Income', value: 'rentalincome' },
  // { name: 'Shares and Bonds', value: 'sharesandbonds' },
  { name: 'Government benefits', value: 'government' },
  // { name: 'Trust', value: 'trust' },
  { name: 'Investments', value: 'Investments' },
  { name: 'Superannuation', value: 'superannuation' },
  { name: 'Centrelink', value: 'centrelink' },
  { name: 'Other', value: 'other' },
];

const RENT_EXPENSE = 'rent';
export const ExpenseTypes = [
  { name: 'Rent', value: RENT_EXPENSE },
  { name: 'Groceries', value: 'Groceries' },
  { name: 'Utilities', value: 'Utilities' },
  { name: 'Phone & Internet', value: 'PhoneInternet' },
  { name: 'Entertainment', value: 'Entertainment' },
  { name: 'Personal & Clothing', value: 'PersonalClothing' },
  { name: 'Transportation', value: 'Transportation' },
  { name: 'Insurance', value: 'Insurance' },
  { name: 'Childcare & Education', value: 'child&Education' },
  { name: 'Other', value: 'other' },
];
export const DELETE_CONFIRM = 'Are you sure?';

export const CO_APPLICANT = 'Co-Applicant';
export const SPOUSE_APPLICANT = 'Spouse';
export const PRIMARY_APPLICANT = 'Primary Applicant';
export const ACTION_NEW = 'NEW';

export const LENDER_IMAGE_LIST = [
  {
    id: 'Pepper',
    value: Pepper,
  },
  {
    id: 'Volkswagen',
    value: Volkswagen,
  },
  {
    id: 'Plenti',
    value: Plenti,
  },
  {
    id: 'Metro',
    value: Metro,
  },
  {
    id: 'Liberty',
    value: Liberty,
  },
  {
    id: 'Westpac',
    value: Westpac,
  },
  {
    id: 'AngleAutoFinance',
    value: AngleAutoFinance,
  },
  {
    id: 'Anz',
    value: Anz,
  },
  {
    id: 'Boq',
    value: Boq,
  },
  {
    id: 'FinanceOne',
    value: FinanceOne,
  },
  {
    id: 'Firstmac',
    value: Firstmac,
  },
  {
    id: 'FlexCommercial',
    value: FlexCommercial,
  },
  {
    id: 'Latitude',
    value: Latitude,
  },
  {
    id: 'Money3',
    value: Money3,
  },
  {
    id: 'Moneyplace',
    value: Moneyplace,
  },
  {
    id: 'NowFinance',
    value: NowFinance,
  },
  {
    id: 'Affordable Car Loans Pty Ltd',
    value: AffordableCarLoans,
  },
];

export const COUNTRY_LIST = [
  {
    id: 'Afghanistan',

    value: 'Afghanistan',
  },
  {
    id: 'Aland',

    value: 'Aland',
  },
  {
    id: 'Albania',

    value: 'Albania',
  },
  {
    id: 'Algeria',

    value: 'Algeria',
  },
  {
    id: 'American Samoa',

    value: 'American Samoa',
  },
  {
    id: 'Andorra',

    value: 'Andorra',
  },
  {
    id: 'Angola',

    value: 'Angola',
  },
  {
    id: 'Anguilla',

    value: 'Anguilla',
  },
  {
    id: 'Antarctica',

    value: 'Antarctica',
  },
  {
    id: 'Antigua and Barbuda',

    value: 'Antigua and Barbuda',
  },
  {
    id: 'Argentina',

    value: 'Argentina',
  },
  {
    id: 'Armenia',

    value: 'Armenia',
  },
  {
    id: 'Aruba',

    value: 'Aruba',
  },
  {
    id: 'Australia',

    value: 'Australia',
  },
  {
    id: 'Austria',

    value: 'Austria',
  },
  {
    id: 'Azerbaijan',

    value: 'Azerbaijan',
  },
  {
    id: 'Bahamas',

    value: 'Bahamas',
  },
  {
    id: 'Bahrain',

    value: 'Bahrain',
  },
  {
    id: 'Bangladesh',

    value: 'Bangladesh',
  },
  {
    id: 'Barbados',

    value: 'Barbados',
  },
  {
    id: 'Belarus',

    value: 'Belarus',
  },
  {
    id: 'Belgium',

    value: 'Belgium',
  },
  {
    id: 'Belize',

    value: 'Belize',
  },
  {
    id: 'Benin',

    value: 'Benin',
  },
  {
    id: 'Bermuda',

    value: 'Bermuda',
  },
  {
    id: 'Bhutan',

    value: 'Bhutan',
  },
  {
    id: 'Bolivia',

    value: 'Bolivia',
  },
  {
    id: 'Bosnia and Herzegovina',

    value: 'Bosnia and Herzegovina',
  },
  {
    id: 'Botswana',

    value: 'Botswana',
  },
  {
    id: 'Bouvet Island',

    value: 'Bouvet Island',
  },
  {
    id: 'Brazil',

    value: 'Brazil',
  },
  {
    id: 'British Indian Ocean Territory',

    value: 'British Indian Ocean Territory',
  },
  {
    id: 'Brunei Darussalam',

    value: 'Brunei Darussalam',
  },
  {
    id: 'Bulgaria',

    value: 'Bulgaria',
  },
  {
    id: 'Burkina Faso',

    value: 'Burkina Faso',
  },
  {
    id: 'Burundi',

    value: 'Burundi',
  },
  {
    id: 'Cambodia',

    value: 'Cambodia',
  },
  {
    id: 'Cameroon',

    value: 'Cameroon',
  },
  {
    id: 'Canada',

    value: 'Canada',
  },
  {
    id: 'Cape Verde',

    value: 'Cape Verde',
  },
  {
    id: 'Cayman Islands',

    value: 'Cayman Islands',
  },
  {
    id: 'Central African Republic',

    value: 'Central African Republic',
  },
  {
    id: 'Chad',

    value: 'Chad',
  },
  {
    id: 'Chile',

    value: 'Chile',
  },
  {
    id: 'China',

    value: 'China',
  },
  {
    id: 'Christmas Island',

    value: 'Christmas Island',
  },
  {
    id: 'Cocos (Keeling) Islands',

    value: 'Cocos (Keeling) Islands',
  },
  {
    id: 'Colombia',

    value: 'Colombia',
  },
  {
    id: 'Comoros',

    value: 'Comoros',
  },
  {
    id: 'Congo (Brazzaville)',

    value: 'Congo (Brazzaville)',
  },
  {
    id: 'Congo (Kinshasa)',

    value: 'Congo (Kinshasa)',
  },
  {
    id: 'Cook Islands',

    value: 'Cook Islands',
  },
  {
    id: 'Costa Rica',

    value: 'Costa Rica',
  },
  {
    id: 'Cote d Ivoire',

    value: 'Cote d Ivoire',
  },
  {
    id: 'Croatia',

    value: 'Croatia',
  },
  {
    id: 'Cuba',

    value: 'Cuba',
  },
  {
    id: 'Cyprus',

    value: 'Cyprus',
  },
  {
    id: 'Czech Republic',

    value: 'Czech Republic',
  },
  {
    id: 'Denmark',

    value: 'Denmark',
  },
  {
    id: 'Djibouti',

    value: 'Djibouti',
  },
  {
    id: 'Dominica',

    value: 'Dominica',
  },
  {
    id: 'Dominican Republic',

    value: 'Dominican Republic',
  },
  {
    id: 'Ecuador',

    value: 'Ecuador',
  },
  {
    id: 'Egypt',

    value: 'Egypt',
  },
  {
    id: 'El Salvador',

    value: 'El Salvador',
  },
  {
    id: 'Equatorial Guinea',

    value: 'Equatorial Guinea',
  },
  {
    id: 'Eritrea',

    value: 'Eritrea',
  },
  {
    id: 'Estonia',

    value: 'Estonia',
  },
  {
    id: 'Ethiopia',

    value: 'Ethiopia',
  },
  {
    id: 'Falkland Islands',

    value: 'Falkland Islands',
  },
  {
    id: 'Faroe Islands',

    value: 'Faroe Islands',
  },
  {
    id: 'Fiji',

    value: 'Fiji',
  },
  {
    id: 'Finland',

    value: 'Finland',
  },
  {
    id: 'France',

    value: 'France',
  },
  {
    id: 'French Guiana',

    value: 'French Guiana',
  },
  {
    id: 'French Polynesia',

    value: 'French Polynesia',
  },
  {
    id: 'French Southern Lands',

    value: 'French Southern Lands',
  },
  {
    id: 'Gabon',

    value: 'Gabon',
  },
  {
    id: 'Gambia',

    value: 'Gambia',
  },
  {
    id: 'Georgia',

    value: 'Georgia',
  },
  {
    id: 'Germany',

    value: 'Germany',
  },
  {
    id: 'Ghana',

    value: 'Ghana',
  },
  {
    id: 'Gibraltar',

    value: 'Gibraltar',
  },
  {
    id: 'Greece',

    value: 'Greece',
  },
  {
    id: 'Greenland',

    value: 'Greenland',
  },
  {
    id: 'Grenada',

    value: 'Grenada',
  },
  {
    id: 'Guadeloupe',

    value: 'Guadeloupe',
  },
  {
    id: 'Guam',

    value: 'Guam',
  },
  {
    id: 'Guatemala',

    value: 'Guatemala',
  },
  {
    id: 'Guernsey',

    value: 'Guernsey',
  },
  {
    id: 'Guinea',

    value: 'Guinea',
  },
  {
    id: 'Guinea-Bissau',

    value: 'Guinea-Bissau',
  },
  {
    id: 'Guyana',

    value: 'Guyana',
  },
  {
    id: 'Haiti',

    value: 'Haiti',
  },
  {
    id: 'Heard and McDonald Islands',

    value: 'Heard and McDonald Islands',
  },
  {
    id: 'Honduras',

    value: 'Honduras',
  },
  {
    id: 'Hong Kong',

    value: 'Hong Kong',
  },
  {
    id: 'Hungary',

    value: 'Hungary',
  },
  {
    id: 'Iceland',

    value: 'Iceland',
  },
  {
    id: 'India',

    value: 'India',
  },
  {
    id: 'Indonesia',

    value: 'Indonesia',
  },
  {
    id: 'Iran',

    value: 'Iran',
  },
  {
    id: 'Iraq',

    value: 'Iraq',
  },
  {
    id: 'Ireland',

    value: 'Ireland',
  },
  {
    id: 'Isle of Man',

    value: 'Isle of Man',
  },
  {
    id: 'Israel',

    value: 'Israel',
  },
  {
    id: 'Italy',

    value: 'Italy',
  },
  {
    id: 'Jamaica',

    value: 'Jamaica',
  },
  {
    id: 'Japan',

    value: 'Japan',
  },
  {
    id: 'Jersey',

    value: 'Jersey',
  },
  {
    id: 'Jordan',

    value: 'Jordan',
  },
  {
    id: 'Kazakhstan',

    value: 'Kazakhstan',
  },
  {
    id: 'Kenya',

    value: 'Kenya',
  },
  {
    id: 'Kiribati',

    value: 'Kiribati',
  },
  {
    id: 'Korea (North)',

    value: 'Korea (North)',
  },
  {
    id: 'Korea (South)',

    value: 'Korea (South)',
  },
  {
    id: 'Kuwait',

    value: 'Kuwait',
  },
  {
    id: 'Kyrgyzstan',

    value: 'Kyrgyzstan',
  },
  {
    id: 'Laos',

    value: 'Laos',
  },
  {
    id: 'Latvia',

    value: 'Latvia',
  },
  {
    id: 'Lebanon',

    value: 'Lebanon',
  },
  {
    id: 'Lesotho',

    value: 'Lesotho',
  },
  {
    id: 'Liberia',

    value: 'Liberia',
  },
  {
    id: 'Libya',

    value: 'Libya',
  },
  {
    id: 'Liechtenstein',

    value: 'Liechtenstein',
  },
  {
    id: 'Lithuania',

    value: 'Lithuania',
  },
  {
    id: 'Luxembourg',

    value: 'Luxembourg',
  },
  {
    id: 'Macau',

    value: 'Macau',
  },
  {
    id: 'Macedonia',

    value: 'Macedonia',
  },
  {
    id: 'Madagascar',

    value: 'Madagascar',
  },
  {
    id: 'Malawi',

    value: 'Malawi',
  },
  {
    id: 'Malaysia',

    value: 'Malaysia',
  },
  {
    id: 'Maldives',

    value: 'Maldives',
  },
  {
    id: 'Mali',

    value: 'Mali',
  },
  {
    id: 'Malta',

    value: 'Malta',
  },
  {
    id: 'Marshall Islands',

    value: 'Marshall Islands',
  },
  {
    id: 'Martinique',

    value: 'Martinique',
  },
  {
    id: 'Mauritania',

    value: 'Mauritania',
  },
  {
    id: 'Mauritius',

    value: 'Mauritius',
  },
  {
    id: 'Mayotte',

    value: 'Mayotte',
  },
  {
    id: 'Mexico',

    value: 'Mexico',
  },
  {
    id: 'Micronesia',

    value: 'Micronesia',
  },
  {
    id: 'Moldova',

    value: 'Moldova',
  },
  {
    id: 'Monaco',

    value: 'Monaco',
  },
  {
    id: 'Mongolia',

    value: 'Mongolia',
  },
  {
    id: 'Montenegro',

    value: 'Montenegro',
  },
  {
    id: 'Montserrat',

    value: 'Montserrat',
  },
  {
    id: 'Morocco',

    value: 'Morocco',
  },
  {
    id: 'Mozambique',

    value: 'Mozambique',
  },
  {
    id: 'Myanmar',

    value: 'Myanmar',
  },
  {
    id: 'Namibia',

    value: 'Namibia',
  },
  {
    id: 'Nauru',

    value: 'Nauru',
  },
  {
    id: 'Nepal',

    value: 'Nepal',
  },
  {
    id: 'Netherlands',

    value: 'Netherlands',
  },
  {
    id: 'Netherlands Antilles',

    value: 'Netherlands Antilles',
  },
  {
    id: 'New Caledonia',

    value: 'New Caledonia',
  },
  {
    id: 'New Zealand',

    value: 'New Zealand',
  },
  {
    id: 'Nicaragua',

    value: 'Nicaragua',
  },
  {
    id: 'Niger',

    value: 'Niger',
  },
  {
    id: 'Nigeria',

    value: 'Nigeria',
  },
  {
    id: 'Niue',

    value: 'Niue',
  },
  {
    id: 'Norfolk Island',

    value: 'Norfolk Island',
  },
  {
    id: 'Northern Mariana Islands',

    value: 'Northern Mariana Islands',
  },
  {
    id: 'Norway',

    value: 'Norway',
  },
  {
    id: 'Oman',

    value: 'Oman',
  },
  {
    id: 'Pakistan',

    value: 'Pakistan',
  },
  {
    id: 'Palau',

    value: 'Palau',
  },
  {
    id: 'Palestine',

    value: 'Palestine',
  },
  {
    id: 'Panama',

    value: 'Panama',
  },
  {
    id: 'Papua New Guinea',

    value: 'Papua New Guinea',
  },
  {
    id: 'Paraguay',

    value: 'Paraguay',
  },
  {
    id: 'Peru',

    value: 'Peru',
  },
  {
    id: 'Philippines',

    value: 'Philippines',
  },
  {
    id: 'Pitcairn',

    value: 'Pitcairn',
  },
  {
    id: 'Poland',

    value: 'Poland',
  },
  {
    id: 'Portugal',

    value: 'Portugal',
  },
  {
    id: 'Puerto Rico',

    value: 'Puerto Rico',
  },
  {
    id: 'Qatar',

    value: 'Qatar',
  },
  {
    id: 'Reunion',

    value: 'Reunion',
  },
  {
    id: 'Romania',

    value: 'Romania',
  },
  {
    id: 'Russian Federation',

    value: 'Russian Federation',
  },
  {
    id: 'Rwanda',

    value: 'Rwanda',
  },
  {
    id: 'Saint Barthelemy',

    value: 'Saint Barthelemy',
  },
  {
    id: 'Saint Helena',

    value: 'Saint Helena',
  },
  {
    id: 'Saint Kitts and Nevis',

    value: 'Saint Kitts and Nevis',
  },
  {
    id: 'Saint Lucia',

    value: 'Saint Lucia',
  },
  {
    id: 'Saint Martin (French part)',

    value: 'Saint Martin (French part)',
  },
  {
    id: 'Saint Pierre and Miquelon',

    value: 'Saint Pierre and Miquelon',
  },
  {
    id: 'Saint Vincent and the Grenadines',

    value: 'Saint Vincent and the Grenadines',
  },
  {
    id: 'Samoa',

    value: 'Samoa',
  },
  {
    id: 'San Marino',

    value: 'San Marino',
  },
  {
    id: 'Sao Tome and Principe',

    value: 'Sao Tome and Principe',
  },
  {
    id: 'Saudi Arabia',

    value: 'Saudi Arabia',
  },
  {
    id: 'Senegal',

    value: 'Senegal',
  },
  {
    id: 'Serbia',

    value: 'Serbia',
  },
  {
    id: 'Seychelles',

    value: 'Seychelles',
  },
  {
    id: 'Sierra Leone',

    value: 'Sierra Leone',
  },
  {
    id: 'Singapore',

    value: 'Singapore',
  },
  {
    id: 'Slovakia',

    value: 'Slovakia',
  },
  {
    id: 'Slovenia',

    value: 'Slovenia',
  },
  {
    id: 'Solomon Islands',

    value: 'Solomon Islands',
  },
  {
    id: 'Somalia',

    value: 'Somalia',
  },
  {
    id: 'South Africa',

    value: 'South Africa',
  },
  {
    id: 'South Georgia and South Sandwich Islands',

    value: 'South Georgia and South Sandwich Islands',
  },
  {
    id: 'Spain',

    value: 'Spain',
  },
  {
    id: 'Sri Lanka',

    value: 'Sri Lanka',
  },
  {
    id: 'Sudan',

    value: 'Sudan',
  },
  {
    id: 'Suriname',

    value: 'Suriname',
  },
  {
    id: 'Svalbard and Jan Mayen Islands',

    value: 'Svalbard and Jan Mayen Islands',
  },
  {
    id: 'Swaziland',

    value: 'Swaziland',
  },
  {
    id: 'Sweden',

    value: 'Sweden',
  },
  {
    id: 'Switzerland',

    value: 'Switzerland',
  },
  {
    id: 'Syria',

    value: 'Syria',
  },
  {
    id: 'Taiwan',

    value: 'Taiwan',
  },
  {
    id: 'Tajikistan',

    value: 'Tajikistan',
  },
  {
    id: 'Tanzania',

    value: 'Tanzania',
  },
  {
    id: 'Thailand',

    value: 'Thailand',
  },
  {
    id: 'Timor-Leste',

    value: 'Timor-Leste',
  },
  {
    id: 'Togo',

    value: 'Togo',
  },
  {
    id: 'Tokelau',

    value: 'Tokelau',
  },
  {
    id: 'Tonga',

    value: 'Tonga',
  },
  {
    id: 'Trinidad and Tobago',

    value: 'Trinidad and Tobago',
  },
  {
    id: 'Tunisia',

    value: 'Tunisia',
  },
  {
    id: 'Turkey',

    value: 'Turkey',
  },
  {
    id: 'Turkmenistan',

    value: 'Turkmenistan',
  },
  {
    id: 'Turks and Caicos Islands',

    value: 'Turks and Caicos Islands',
  },
  {
    id: 'Tuvalu',

    value: 'Tuvalu',
  },
  {
    id: 'Uganda',

    value: 'Uganda',
  },
  {
    id: 'Ukraine',

    value: 'Ukraine',
  },
  {
    id: 'United Arab Emirates',

    value: 'United Arab Emirates',
  },
  {
    id: 'United Kingdom',

    value: 'United Kingdom',
  },
  {
    id: 'United States Minor Outlying Islands',

    value: 'United States Minor Outlying Islands',
  },
  {
    id: 'United States of America',

    value: 'United States of America',
  },
  {
    id: 'Uruguay',

    value: 'Uruguay',
  },
  {
    id: 'Uzbekistan',

    value: 'Uzbekistan',
  },
  {
    id: 'Vanuatu',

    value: 'Vanuatu',
  },
  {
    id: 'Vatican City',

    value: 'Vatican City',
  },
  {
    id: 'Venezuela',

    value: 'Venezuela',
  },
  {
    id: 'Vietnam',

    value: 'Vietnam',
  },
  {
    id: 'Virgin Islands (British)',

    value: 'Virgin Islands (British)',
  },
  {
    id: 'Virgin Islands (US)',

    value: 'Virgin Islands (US)',
  },
  {
    id: 'Wallis and Futuna Islands',

    value: 'Wallis and Futuna Islands',
  },
  {
    id: 'Western Sahara',

    value: 'Western Sahara',
  },
  {
    id: 'Yemen',

    value: 'Yemen',
  },
  {
    id: 'Zambia',

    value: 'Zambia',
  },
  {
    id: 'Zimbabwe',

    value: 'Zimbabwe',
  },
];

export const EMPLOYMENT_INDUSTRY = [
  { name: 'Agribusiness', value: 'Agribusiness' },
  { name: 'AssetWealthManagement', value: 'Asset Wealth Management' },
  { name: 'BankingAndCapitalMarkets', value: 'Banking And Capital Markets' },
  { name: 'ConstructionTransportation', value: 'Construction' },
  { name: 'Defence', value: 'Defence' },
  { name: 'Education', value: 'Education' },
  { name: 'EnergyOilGas', value: 'Energy Oil Gas' },
  { name: 'EntertainmentMedia', value: 'Entertainment Media' },
  { name: 'FinancialServices', value: 'Financial Services' },
  { name: 'Government', value: 'Government' },
  { name: 'Healthcare', value: 'Healthcare' },
  { name: 'Infrastructure', value: 'Infrastructure' },
  { name: 'Insurance', value: 'Insurance' },
  { name: 'Mining', value: 'Mining' },
  { name: 'PowerUtilities', value: 'Power Utilities' },
  { name: 'RealEstate', value: 'Real Estate' },
  { name: 'RetailConsumer', value: 'Retail Consumer' },
  { name: 'Superannuation', value: 'Superannuation' },
  { name: 'Technology', value: 'Technology' },
  { name: 'Other', value: 'Other' },
  { name: 'Transportation', value: 'Transportation' },
];

export const EMPLOYER_OCCUPATION = [
  {
    name: 'Aboriginal and Torres Strait Islander Education Worker',
    value: 'Aboriginal and Torres Strait Islander Education Worker',
  },
  {
    name: 'Aboriginal and Torres Strait Islander Health Worker',
    value: 'Aboriginal and Torres Strait Islander Health Worker',
  },
  {
    name: 'Accommodation and Hospitality Managers nec',
    value: 'Accommodation and Hospitality Managers nec',
  },
  { name: 'Accountant (General)', value: 'Accountant (General)' },
  { name: 'Accounts Clerk', value: 'Accounts Clerk' },
  { name: 'Actor', value: 'Actor' },
  {
    name: 'Actors, Dancers and Other Entertainers nec',
    value: 'Actors, Dancers and Other Entertainers nec',
  },
  { name: 'Actuary', value: 'Actuary' },
  { name: 'Acupuncturist', value: 'Acupuncturist' },
  { name: 'Admissions Clerk', value: 'Admissions Clerk' },
  { name: 'Advertising Manager', value: 'Advertising Manager' },
  { name: 'Advertising Specialist', value: 'Advertising Specialist' },
  { name: 'Aeronautical Engineer', value: 'Aeronautical Engineer' },
  { name: 'Aeroplane Pilot', value: 'Aeroplane Pilot' },
  { name: 'Aged or Disabled Carer', value: 'Aged or Disabled Carer' },
  {
    name: 'Agricultural and Horticultural Mobile Plant Operator',
    value: 'Agricultural and Horticultural Mobile Plant Operator',
  },
  { name: 'Agricultural Consultant', value: 'Agricultural Consultant' },
  { name: 'Agricultural Engineer', value: 'Agricultural Engineer' },
  { name: 'Agricultural Scientist', value: 'Agricultural Scientist' },
  { name: 'Agricultural Technician', value: 'Agricultural Technician' },
  { name: 'Air Traffic Controller', value: 'Air Traffic Controller' },
  {
    name: 'Air Transport Professionals nec',
    value: 'Air Transport Professionals nec',
  },
  {
    name: 'Airconditioning and Mechanical Services Plumber',
    value: 'Airconditioning and Mechanical Services Plumber',
  },
  {
    name: 'Airconditioning and Refrigeration Mechanic',
    value: 'Airconditioning and Refrigeration Mechanic',
  },
  {
    name: 'Aircraft Baggage Handler and Airline Ground Crew',
    value: 'Aircraft Baggage Handler and Airline Ground Crew',
  },
  {
    name: 'Aircraft Maintenance Engineer (Avionics)',
    value: 'Aircraft Maintenance Engineer (Avionics)',
  },
  {
    name: 'Aircraft Maintenance Engineer (Mechanical)',
    value: 'Aircraft Maintenance Engineer (Mechanical)',
  },
  {
    name: 'Aircraft Maintenance Engineer (Structures)',
    value: 'Aircraft Maintenance Engineer (Structures)',
  },
  { name: 'Aircraft Refueller', value: 'Aircraft Refueller' },
  {
    name: 'Alarm, Security or Surveillance Monitor',
    value: 'Alarm, Security or Surveillance Monitor',
  },
  { name: 'Ambulance Officer', value: 'Ambulance Officer' },
  { name: 'Amusement Centre Manager', value: 'Amusement Centre Manager' },
  { name: 'Anaesthetic Technician', value: 'Anaesthetic Technician' },
  { name: 'Anaesthetist', value: 'Anaesthetist' },
  { name: 'Analyst Programmer', value: 'Analyst Programmer' },
  {
    name: 'Animal Attendants and Trainers nec',
    value: 'Animal Attendants and Trainers nec',
  },
  { name: 'Antique Dealer', value: 'Antique Dealer' },
  { name: 'Apiarist', value: 'Apiarist' },
  { name: 'Apparel Cutter', value: 'Apparel Cutter' },
  { name: 'Aquaculture Farmer', value: 'Aquaculture Farmer' },
  { name: 'Aquaculture Worker', value: 'Aquaculture Worker' },
  { name: 'Arborist', value: 'Arborist' },
  { name: 'Archaeologist', value: 'Archaeologist' },
  { name: 'Architect', value: 'Architect' },
  {
    name: 'Architectural Draftsperson',
    value: 'Architectural Draftsperson',
  },
  {
    name: 'Architectural, Building and Surveying Technicians nec',
    value: 'Architectural, Building and Surveying Technicians nec',
  },
  { name: 'Archivist', value: 'Archivist' },
  { name: 'Armoured Car Escort', value: 'Armoured Car Escort' },
  {
    name: 'Art Director (Film, Television or Stage)',
    value: 'Art Director (Film, Television or Stage)',
  },
  {
    name: 'Art Teacher (Private Tuition)',
    value: 'Art Teacher (Private Tuition)',
  },
  { name: 'Artistic Director', value: 'Artistic Director' },
  {
    name: 'Arts Administrator or Manager',
    value: 'Arts Administrator or Manager',
  },
  { name: 'Auctioneer', value: 'Auctioneer' },
  { name: 'Audiologist', value: 'Audiologist' },
  { name: 'Author', value: 'Author' },
  { name: 'Autoglazier', value: 'Autoglazier' },
  { name: 'Automobile Drivers nec', value: 'Automobile Drivers nec' },
  { name: 'Automotive Electrician', value: 'Automotive Electrician' },
  { name: 'Backhoe Operator', value: 'Backhoe Operator' },
  { name: 'Baker', value: 'Baker' },
  { name: 'Baking Factory Worker', value: 'Baking Factory Worker' },
  { name: 'Bank Worker', value: 'Bank Worker' },
  { name: 'Bar Attendant', value: 'Bar Attendant' },
  { name: 'Bar Useful or Busser', value: 'Bar Useful or Busser' },
  { name: 'Barista', value: 'Barista' },
  { name: 'Barrister', value: 'Barrister' },
  { name: 'Beauty Therapist', value: 'Beauty Therapist' },
  {
    name: 'Bed and Breakfast Operator',
    value: 'Bed and Breakfast Operator',
  },
  { name: 'Beef Cattle Farm Worker', value: 'Beef Cattle Farm Worker' },
  { name: 'Beef Cattle Farmer', value: 'Beef Cattle Farmer' },
  {
    name: 'Betting Agency Counter Clerk',
    value: 'Betting Agency Counter Clerk',
  },
  { name: 'Betting Agency Manager', value: 'Betting Agency Manager' },
  { name: 'Betting Clerks nec', value: 'Betting Clerks nec' },
  { name: 'Bicycle Mechanic', value: 'Bicycle Mechanic' },
  { name: 'Biochemist', value: 'Biochemist' },
  { name: 'Biomedical Engineer', value: 'Biomedical Engineer' },
  { name: 'Biotechnologist', value: 'Biotechnologist' },
  { name: 'Blacksmith', value: 'Blacksmith' },
  {
    name: 'Boarding Kennel or Cattery Operator',
    value: 'Boarding Kennel or Cattery Operator',
  },
  { name: 'Boat Builder and Repairer', value: 'Boat Builder and Repairer' },
  { name: 'Body Artist', value: 'Body Artist' },
  { name: 'Boiler or Engine Operator', value: 'Boiler or Engine Operator' },
  { name: 'Book or Script Editor', value: 'Book or Script Editor' },
  { name: 'Bookkeeper', value: 'Bookkeeper' },
  { name: 'Bookmaker', value: 'Bookmaker' },
  { name: "Bookmaker's Clerk", value: "Bookmaker's Clerk" },
  { name: 'Botanist', value: 'Botanist' },
  { name: 'Brewery Worker', value: 'Brewery Worker' },
  { name: 'Bricklayer', value: 'Bricklayer' },
  {
    name: 'Broadcast Transmitter Operator',
    value: 'Broadcast Transmitter Operator',
  },
  { name: "Builder's Labourer", value: "Builder's Labourer" },
  {
    name: 'Building and Engineering Technicians nec',
    value: 'Building and Engineering Technicians nec',
  },
  { name: 'Building Associate', value: 'Building Associate' },
  { name: 'Building Inspector', value: 'Building Inspector' },
  {
    name: 'Building Insulation Installer',
    value: 'Building Insulation Installer',
  },
  {
    name: 'Bulk Materials Handling Plant Operator',
    value: 'Bulk Materials Handling Plant Operator',
  },
  { name: 'Bulldozer Operator', value: 'Bulldozer Operator' },
  { name: 'Bungy Jump Master', value: 'Bungy Jump Master' },
  { name: 'Bus Driver', value: 'Bus Driver' },
  { name: 'Business Broker', value: 'Business Broker' },
  { name: 'Business Machine Mechanic', value: 'Business Machine Mechanic' },
  {
    name: 'Butcher or Smallgoods Maker',
    value: 'Butcher or Smallgoods Maker',
  },
  { name: 'Cabinetmaker', value: 'Cabinetmaker' },
  {
    name: 'Cabler (Data and Telecommunications)',
    value: 'Cabler (Data and Telecommunications)',
  },
  {
    name: 'Cafe or Restaurant Manager',
    value: 'Cafe or Restaurant Manager',
  },
  { name: 'Cafe Worker', value: 'Cafe Worker' },
  {
    name: 'Call or Contact Centre Manager',
    value: 'Call or Contact Centre Manager',
  },
  {
    name: 'Call or Contact Centre Operator',
    value: 'Call or Contact Centre Operator',
  },
  {
    name: 'Call or Contact Centre Team Leader',
    value: 'Call or Contact Centre Team Leader',
  },
  {
    name: 'Camera Operator (Film, Television or Video)',
    value: 'Camera Operator (Film, Television or Video)',
  },
  { name: 'Canvas Goods Fabricator', value: 'Canvas Goods Fabricator' },
  { name: 'Car Detailer', value: 'Car Detailer' },
  { name: 'Car Park Attendant', value: 'Car Park Attendant' },
  {
    name: 'Caravan Park and Camping Ground Manager',
    value: 'Caravan Park and Camping Ground Manager',
  },
  { name: 'Cardiac Technician', value: 'Cardiac Technician' },
  { name: 'Cardiologist', value: 'Cardiologist' },
  { name: 'Cardiothoracic Surgeon', value: 'Cardiothoracic Surgeon' },
  { name: 'Careers Counsellor', value: 'Careers Counsellor' },
  { name: 'Caretaker', value: 'Caretaker' },
  { name: 'Carpenter', value: 'Carpenter' },
  { name: 'Carpenter and Joiner', value: 'Carpenter and Joiner' },
  { name: 'Carpet Cleaner', value: 'Carpet Cleaner' },
  { name: 'Cartographer', value: 'Cartographer' },
  { name: 'Cash Van Salesperson', value: 'Cash Van Salesperson' },
  {
    name: 'Cement and Concrete Plant Worker',
    value: 'Cement and Concrete Plant Worker',
  },
  {
    name: 'Cement Production Plant Operator',
    value: 'Cement Production Plant Operator',
  },
  {
    name: 'Charter and Tour Bus Driver',
    value: 'Charter and Tour Bus Driver',
  },
  { name: 'Chauffeur', value: 'Chauffeur' },
  { name: 'Checkout Operator', value: 'Checkout Operator' },
  { name: 'Chef', value: 'Chef' },
  { name: 'Chemical Engineer', value: 'Chemical Engineer' },
  { name: 'Chemical Plant Operator', value: 'Chemical Plant Operator' },
  { name: 'Chemical Plant Worker', value: 'Chemical Plant Worker' },
  {
    name: 'Chemical Production Machine Operator',
    value: 'Chemical Production Machine Operator',
  },
  { name: 'Chemist', value: 'Chemist' },
  { name: 'Chemistry Technician', value: 'Chemistry Technician' },
  {
    name: 'Chief Executive or Managing Director',
    value: 'Chief Executive or Managing Director',
  },
  { name: 'Chief Information Officer', value: 'Chief Information Officer' },
  { name: 'Child Care Centre Manager', value: 'Child Care Centre Manager' },
  { name: 'Child Care Worker', value: 'Child Care Worker' },
  {
    name: 'Child or Youth Residential Care Assistant',
    value: 'Child or Youth Residential Care Assistant',
  },
  { name: 'Chiropractor', value: 'Chiropractor' },
  { name: 'Chocolate Packer', value: 'Chocolate Packer' },
  { name: 'Cinema or Theatre Manager', value: 'Cinema or Theatre Manager' },
  { name: 'Civil Celebrant', value: 'Civil Celebrant' },
  { name: 'Civil Engineer', value: 'Civil Engineer' },
  {
    name: 'Civil Engineering Draftsperson',
    value: 'Civil Engineering Draftsperson',
  },
  {
    name: 'Civil Engineering Technician',
    value: 'Civil Engineering Technician',
  },
  {
    name: 'Classified Advertising Clerk',
    value: 'Classified Advertising Clerk',
  },
  {
    name: 'Clay Processing Factory Worker',
    value: 'Clay Processing Factory Worker',
  },
  {
    name: 'Clay Products Machine Operator',
    value: 'Clay Products Machine Operator',
  },
  {
    name: 'Clay, Concrete, Glass and Stone Processing Machine Operators nec',
    value: 'Clay, Concrete, Glass and Stone Processing Machine Operators nec',
  },
  { name: 'Cleaners nec', value: 'Cleaners nec' },
  {
    name: 'Clerical and Administrative Workers nec',
    value: 'Clerical and Administrative Workers nec',
  },
  {
    name: 'Clerical and Office Support Workers nec',
    value: 'Clerical and Office Support Workers nec',
  },
  { name: 'Clerk of Court', value: 'Clerk of Court' },
  { name: 'Clinical Coder', value: 'Clinical Coder' },
  { name: 'Clinical Haematologist', value: 'Clinical Haematologist' },
  { name: 'Clinical Psychologist', value: 'Clinical Psychologist' },
  { name: 'Clothing Patternmaker', value: 'Clothing Patternmaker' },
  {
    name: 'Clothing Trades Workers nec',
    value: 'Clothing Trades Workers nec',
  },
  { name: 'Commercial Cleaner', value: 'Commercial Cleaner' },
  { name: 'Commercial Housekeeper', value: 'Commercial Housekeeper' },
  {
    name: 'Commissioned Defense Force Officer',
    value: 'Commissioned Defense Force Officer',
  },
  { name: 'Commissioned Fire Officer', value: 'Commissioned Fire Officer' },
  {
    name: 'Commissioned Police Officer',
    value: 'Commissioned Police Officer',
  },
  { name: 'Commodities Trader', value: 'Commodities Trader' },
  { name: 'Communications Operator', value: 'Communications Operator' },
  { name: 'Community Arts Worker', value: 'Community Arts Worker' },
  { name: 'Community Worker', value: 'Community Worker' },
  { name: 'Company Secretary', value: 'Company Secretary' },
  {
    name: 'Complementary Health Therapists nec',
    value: 'Complementary Health Therapists nec',
  },
  { name: 'Composer', value: 'Composer' },
  {
    name: 'Computer Network and Systems Engineer',
    value: 'Computer Network and Systems Engineer',
  },
  {
    name: 'Concrete Batching Plant Operator',
    value: 'Concrete Batching Plant Operator',
  },
  {
    name: 'Concrete Products Machine Operator',
    value: 'Concrete Products Machine Operator',
  },
  { name: 'Concrete Pump Operator', value: 'Concrete Pump Operator' },
  { name: 'Concreter', value: 'Concreter' },
  { name: 'Confectionery Maker', value: 'Confectionery Maker' },
  {
    name: 'Conference and Event Organiser',
    value: 'Conference and Event Organiser',
  },
  { name: 'Conservation Officer', value: 'Conservation Officer' },
  { name: 'Conservator', value: 'Conservator' },
  { name: 'Construction Estimator', value: 'Construction Estimator' },
  {
    name: 'Construction Project Manager',
    value: 'Construction Project Manager',
  },
  { name: 'Construction Rigger', value: 'Construction Rigger' },
  { name: 'Container Filler', value: 'Container Filler' },
  { name: 'Contract Administrator', value: 'Contract Administrator' },
  { name: 'Conveyancer', value: 'Conveyancer' },
  { name: 'Cook', value: 'Cook' },
  { name: 'Copywriter', value: 'Copywriter' },
  { name: 'Corporate General Manager', value: 'Corporate General Manager' },
  {
    name: 'Corporate Services Manager',
    value: 'Corporate Services Manager',
  },
  { name: 'Corporate Treasurer', value: 'Corporate Treasurer' },
  { name: 'Cost Clerk', value: 'Cost Clerk' },
  { name: 'Cotton Grower', value: 'Cotton Grower' },
  { name: 'Counsellors nec', value: 'Counsellors nec' },
  { name: 'Courier', value: 'Courier' },
  {
    name: 'Court Bailiff or Sheriff (Aus) Court Collections Officer (NZ)',
    value: 'Court Bailiff or Sheriff (Aus) Court Collections Officer (NZ)',
  },
  {
    name: 'Court Orderly (Aus) Court Registry Officer (NZ)',
    value: 'Court Orderly (Aus) Court Registry Officer (NZ)',
  },
  { name: 'Crane Chaser', value: 'Crane Chaser' },
  {
    name: 'Crane, Hoist or Lift Operator',
    value: 'Crane, Hoist or Lift Operator',
  },
  {
    name: 'Credit or Loans Officer (Aus) Finance Clerk (NZ)',
    value: 'Credit or Loans Officer (Aus) Finance Clerk (NZ)',
  },
  { name: 'Crop Farm Workers nec', value: 'Crop Farm Workers nec' },
  { name: 'Crop Farmers nec', value: 'Crop Farmers nec' },
  { name: 'Crossing Supervisor', value: 'Crossing Supervisor' },
  { name: 'Crowd Controller', value: 'Crowd Controller' },
  { name: 'Customer Service Manager', value: 'Customer Service Manager' },
  { name: 'Customs Officer', value: 'Customs Officer' },
  { name: 'Dairy Cattle Farm Worker', value: 'Dairy Cattle Farm Worker' },
  { name: 'Dairy Cattle Farmer', value: 'Dairy Cattle Farmer' },
  { name: 'Dairy Products Maker', value: 'Dairy Products Maker' },
  {
    name: 'Dance Teacher (Private Tuition)',
    value: 'Dance Teacher (Private Tuition)',
  },
  { name: 'Dancer or Choreographer', value: 'Dancer or Choreographer' },
  { name: 'Data Entry Operator', value: 'Data Entry Operator' },
  { name: 'Database Administrator', value: 'Database Administrator' },
  { name: 'Debt Collector', value: 'Debt Collector' },
  { name: 'Deck Hand', value: 'Deck Hand' },
  { name: 'Deer Farmer', value: 'Deer Farmer' },
  {
    name: 'Defence Force Member - Other Ranks',
    value: 'Defence Force Member - Other Ranks',
  },
  {
    name: 'Defence Force Senior Officer',
    value: 'Defence Force Senior Officer',
  },
  { name: 'Delivery Driver', value: 'Delivery Driver' },
  { name: 'Dental Assistant', value: 'Dental Assistant' },
  { name: 'Dental Hygienist', value: 'Dental Hygienist' },
  { name: 'Dental Prosthetist', value: 'Dental Prosthetist' },
  { name: 'Dental Specialist', value: 'Dental Specialist' },
  { name: 'Dental Technician', value: 'Dental Technician' },
  { name: 'Dental Therapist', value: 'Dental Therapist' },
  { name: 'Dentist', value: 'Dentist' },
  { name: 'Dermatologist', value: 'Dermatologist' },
  {
    name: 'Despatching and Receiving Clerk',
    value: 'Despatching and Receiving Clerk',
  },
  { name: 'Detective', value: 'Detective' },
  { name: 'Developer Programmer', value: 'Developer Programmer' },
  {
    name: 'Diagnostic and Interventional Radiologist',
    value: 'Diagnostic and Interventional Radiologist',
  },
  { name: 'Diesel Motor Mechanic', value: 'Diesel Motor Mechanic' },
  { name: 'Dietitian', value: 'Dietitian' },
  {
    name: 'Director (Film, Television, Radio or Stage)',
    value: 'Director (Film, Television, Radio or Stage)',
  },
  { name: 'Director of Photography', value: 'Director of Photography' },
  {
    name: 'Disabilities Services Officer',
    value: 'Disabilities Services Officer',
  },
  { name: 'Diver', value: 'Diver' },
  { name: 'Diversional Therapist', value: 'Diversional Therapist' },
  {
    name: 'Diving Instructor (Open Water)',
    value: 'Diving Instructor (Open Water)',
  },
  { name: 'Dog Handler or Trainer', value: 'Dog Handler or Trainer' },
  {
    name: 'Dog or Horse Racing Official',
    value: 'Dog or Horse Racing Official',
  },
  { name: 'Domestic Cleaner', value: 'Domestic Cleaner' },
  { name: 'Domestic Housekeeper', value: 'Domestic Housekeeper' },
  { name: 'Door-to-door Salesperson', value: 'Door-to-door Salesperson' },
  {
    name: 'Doorperson or Luggage Porter',
    value: 'Doorperson or Luggage Porter',
  },
  {
    name: 'Drainage, Sewerage and Stormwater Labourer',
    value: 'Drainage, Sewerage and Stormwater Labourer',
  },
  {
    name: 'Drainer (Aus) Drainlayer (NZ)',
    value: 'Drainer (Aus) Drainlayer (NZ)',
  },
  {
    name: 'Drama Teacher (Private Tuition)',
    value: 'Drama Teacher (Private Tuition)',
  },
  { name: 'Dressmaker or Tailor', value: 'Dressmaker or Tailor' },
  { name: 'Driller', value: 'Driller' },
  { name: "Driller's Assistant", value: "Driller's Assistant" },
  { name: 'Driving Instructor', value: 'Driving Instructor' },
  {
    name: 'Drug and Alcohol Counsellor',
    value: 'Drug and Alcohol Counsellor',
  },
  { name: 'Drycleaner', value: 'Drycleaner' },
  {
    name: 'Early Childhood (Pre-primary School) Teacher',
    value: 'Early Childhood (Pre-primary School) Teacher',
  },
  { name: 'Earth Science Technician', value: 'Earth Science Technician' },
  { name: 'Earthmoving Labourer', value: 'Earthmoving Labourer' },
  {
    name: 'Earthmoving Plant Operator (General)',
    value: 'Earthmoving Plant Operator (General)',
  },
  { name: 'Economist', value: 'Economist' },
  { name: 'Education Adviser', value: 'Education Adviser' },
  { name: 'Education Managers nec', value: 'Education Managers nec' },
  { name: 'Education Reviewer', value: 'Education Reviewer' },
  { name: 'Educational Psychologist', value: 'Educational Psychologist' },
  { name: 'Electorate Officer', value: 'Electorate Officer' },
  { name: 'Electrical Engineer', value: 'Electrical Engineer' },
  {
    name: 'Electrical Engineering Draftsperson',
    value: 'Electrical Engineering Draftsperson',
  },
  {
    name: 'Electrical Engineering Technician',
    value: 'Electrical Engineering Technician',
  },
  {
    name: 'Electrical Linesworker (Aus) Electrical Line Mechanic (NZ)',
    value: 'Electrical Linesworker (Aus) Electrical Line Mechanic (NZ)',
  },
  {
    name: 'Electrical or Telecommunications Trades Assistant',
    value: 'Electrical or Telecommunications Trades Assistant',
  },
  { name: 'Electrician (General)', value: 'Electrician (General)' },
  {
    name: 'Electrician (Special Class)',
    value: 'Electrician (Special Class)',
  },
  {
    name: 'Electronic Engineering Draftsperson',
    value: 'Electronic Engineering Draftsperson',
  },
  {
    name: 'Electronic Engineering Technician',
    value: 'Electronic Engineering Technician',
  },
  {
    name: 'Electronic Equipment Trades Worker',
    value: 'Electronic Equipment Trades Worker',
  },
  {
    name: 'Electronic Instrument Trades Worker (General)',
    value: 'Electronic Instrument Trades Worker (General)',
  },
  {
    name: 'Electronic Instrument Trades Worker (Special Class)',
    value: 'Electronic Instrument Trades Worker (Special Class)',
  },
  { name: 'Electronics Engineer', value: 'Electronics Engineer' },
  { name: 'Electroplater', value: 'Electroplater' },
  {
    name: 'Emergency Medicine Specialist',
    value: 'Emergency Medicine Specialist',
  },
  { name: 'Emergency Service Worker', value: 'Emergency Service Worker' },
  { name: 'Endocrinologist', value: 'Endocrinologist' },
  { name: 'Engineering Manager', value: 'Engineering Manager' },
  { name: 'Engineering Patternmaker', value: 'Engineering Patternmaker' },
  {
    name: 'Engineering Production Worker',
    value: 'Engineering Production Worker',
  },
  {
    name: 'Engineering Professionals nec',
    value: 'Engineering Professionals nec',
  },
  { name: 'Engineering Technologist', value: 'Engineering Technologist' },
  { name: 'Engraver', value: 'Engraver' },
  { name: 'Enrolled Nurse', value: 'Enrolled Nurse' },
  {
    name: 'Entertainer or Variety Artist',
    value: 'Entertainer or Variety Artist',
  },
  { name: 'Environmental Consultant', value: 'Environmental Consultant' },
  { name: 'Environmental Engineer', value: 'Environmental Engineer' },
  {
    name: 'Environmental Health Officer',
    value: 'Environmental Health Officer',
  },
  { name: 'Environmental Manager', value: 'Environmental Manager' },
  {
    name: 'Environmental Research Scientist',
    value: 'Environmental Research Scientist',
  },
  {
    name: 'Environmental Scientists nec',
    value: 'Environmental Scientists nec',
  },
  { name: 'Equipment Hire Manager', value: 'Equipment Hire Manager' },
  { name: 'Excavator Operator', value: 'Excavator Operator' },
  { name: 'Exercise Physiologist', value: 'Exercise Physiologist' },
  {
    name: 'Exhaust and Muffler Repairer',
    value: 'Exhaust and Muffler Repairer',
  },
  { name: 'External Auditor', value: 'External Auditor' },
  {
    name: 'Fabric and Textile Factory Worker',
    value: 'Fabric and Textile Factory Worker',
  },
  { name: 'Facilities Administrator', value: 'Facilities Administrator' },
  { name: 'Facilities Manager', value: 'Facilities Manager' },
  {
    name: 'Factory Process Workers nec',
    value: 'Factory Process Workers nec',
  },
  { name: 'Faculty Head', value: 'Faculty Head' },
  {
    name: 'Family and Marriage Counsellor',
    value: 'Family and Marriage Counsellor',
  },
  { name: 'Family Day Care Worker', value: 'Family Day Care Worker' },
  { name: 'Family Support Worker', value: 'Family Support Worker' },
  {
    name: 'Farm, Forestry and Garden Workers nec',
    value: 'Farm, Forestry and Garden Workers nec',
  },
  { name: 'Farrier', value: 'Farrier' },
  { name: 'Fashion Designer', value: 'Fashion Designer' },
  { name: 'Fast Food Cook', value: 'Fast Food Cook' },
  { name: 'Fencer', value: 'Fencer' },
  { name: 'Fibrous Plasterer', value: 'Fibrous Plasterer' },
  { name: 'Filing or Registry Clerk', value: 'Filing or Registry Clerk' },
  { name: 'Film and Video Editor', value: 'Film and Video Editor' },
  {
    name: 'Film, Television, Radio and Stage Directors nec',
    value: 'Film, Television, Radio and Stage Directors nec',
  },
  { name: 'Finance Broker', value: 'Finance Broker' },
  { name: 'Finance Manager', value: 'Finance Manager' },
  { name: 'Financial Brokers nec', value: 'Financial Brokers nec' },
  { name: 'Financial Dealers nec', value: 'Financial Dealers nec' },
  {
    name: 'Financial Institution Branch Manager',
    value: 'Financial Institution Branch Manager',
  },
  {
    name: 'Financial Investment Adviser',
    value: 'Financial Investment Adviser',
  },
  {
    name: 'Financial Investment Manager',
    value: 'Financial Investment Manager',
  },
  { name: 'Financial Market Dealer', value: 'Financial Market Dealer' },
  { name: 'Fire Fighter', value: 'Fire Fighter' },
  {
    name: 'Fire Protection Equipment Technician',
    value: 'Fire Protection Equipment Technician',
  },
  { name: 'First Aid Trainer', value: 'First Aid Trainer' },
  { name: 'Fisheries Officer', value: 'Fisheries Officer' },
  { name: 'Fishing Guide', value: 'Fishing Guide' },
  { name: 'Fishing Hand', value: 'Fishing Hand' },
  { name: 'Fitness Centre Manager', value: 'Fitness Centre Manager' },
  { name: 'Fitness Instructor', value: 'Fitness Instructor' },
  { name: 'Fitter (General)', value: 'Fitter (General)' },
  { name: 'Fitter and Turner', value: 'Fitter and Turner' },
  { name: 'Fitter-Welder', value: 'Fitter-Welder' },
  { name: 'Fleet Manager', value: 'Fleet Manager' },
  { name: 'Flight Attendant', value: 'Flight Attendant' },
  { name: 'Floor Finisher', value: 'Floor Finisher' },
  { name: 'Florist', value: 'Florist' },
  { name: 'Flower Grower', value: 'Flower Grower' },
  { name: 'Flying Instructor', value: 'Flying Instructor' },
  {
    name: 'Food and Drink Factory Workers nec',
    value: 'Food and Drink Factory Workers nec',
  },
  { name: 'Food Technologist', value: 'Food Technologist' },
  {
    name: 'Food Trades Assistants nec',
    value: 'Food Trades Assistants nec',
  },
  { name: 'Footballer', value: 'Footballer' },
  { name: 'Footwear Factory Worker', value: 'Footwear Factory Worker' },
  {
    name: 'Footwear Production Machine Operator',
    value: 'Footwear Production Machine Operator',
  },
  {
    name: 'Forester (Aus) Forest Scientist (NZ)',
    value: 'Forester (Aus) Forest Scientist (NZ)',
  },
  { name: 'Forestry Worker', value: 'Forestry Worker' },
  { name: 'Forklift Driver', value: 'Forklift Driver' },
  {
    name: 'Freight Handler (Rail or Road)',
    value: 'Freight Handler (Rail or Road)',
  },
  {
    name: 'Fruit and Vegetable Factory Worker',
    value: 'Fruit and Vegetable Factory Worker',
  },
  {
    name: 'Fruit and Vegetable Packer',
    value: 'Fruit and Vegetable Packer',
  },
  { name: 'Fruit or Nut Farm Worker', value: 'Fruit or Nut Farm Worker' },
  { name: 'Fruit or Nut Grower', value: 'Fruit or Nut Grower' },
  { name: 'Fruit or Nut Picker', value: 'Fruit or Nut Picker' },
  { name: 'Funeral Director', value: 'Funeral Director' },
  { name: 'Funeral Workers nec', value: 'Funeral Workers nec' },
  { name: 'Furniture Finisher', value: 'Furniture Finisher' },
  { name: 'Furniture Removalist', value: 'Furniture Removalist' },
  { name: 'Futures Trader', value: 'Futures Trader' },
  { name: 'Gallery or Museum Curator', value: 'Gallery or Museum Curator' },
  { name: 'Gallery or Museum Guide', value: 'Gallery or Museum Guide' },
  {
    name: 'Gallery or Museum Technician',
    value: 'Gallery or Museum Technician',
  },
  { name: 'Gaming Worker', value: 'Gaming Worker' },
  { name: 'Garden Labourer', value: 'Garden Labourer' },
  { name: 'Gardener (General)', value: 'Gardener (General)' },
  { name: 'Gas or Petroleum Operator', value: 'Gas or Petroleum Operator' },
  { name: 'Gasfitter', value: 'Gasfitter' },
  { name: 'Gastroenterologist', value: 'Gastroenterologist' },
  { name: 'General Clerk', value: 'General Clerk' },
  { name: 'General Practitioner', value: 'General Practitioner' },
  { name: 'Geologist', value: 'Geologist' },
  { name: 'Geophysicist', value: 'Geophysicist' },
  { name: 'Geotechnical Engineer', value: 'Geotechnical Engineer' },
  { name: 'Glass Processing Worker', value: 'Glass Processing Worker' },
  {
    name: 'Glass Production Machine Operator',
    value: 'Glass Production Machine Operator',
  },
  { name: 'Glazier', value: 'Glazier' },
  { name: 'Goat Farmer', value: 'Goat Farmer' },
  { name: 'Golfer', value: 'Golfer' },
  { name: 'Grader Operator', value: 'Grader Operator' },
  { name: 'Grain Mill Worker', value: 'Grain Mill Worker' },
  {
    name: 'Grain, Oilseed or Pasture Farm Worker (Aus) Field Crop Farm Worker (NZ)',
    value: 'Grain, Oilseed or Pasture Farm Worker (Aus) Field Crop Farm Worker (NZ)',
  },
  {
    name: 'Grain, Oilseed or Pasture Grower (Aus) Field Crop Grower (NZ)',
    value: 'Grain, Oilseed or Pasture Grower (Aus) Field Crop Grower (NZ)',
  },
  { name: 'Grape Grower', value: 'Grape Grower' },
  { name: 'Graphic Designer', value: 'Graphic Designer' },
  {
    name: 'Graphic Pre-press Trades Worker',
    value: 'Graphic Pre-press Trades Worker',
  },
  { name: 'Greenkeeper', value: 'Greenkeeper' },
  { name: 'Gunsmith', value: 'Gunsmith' },
  {
    name: 'Gymnastics Coach or Instructor',
    value: 'Gymnastics Coach or Instructor',
  },
  {
    name: 'Hair or Beauty Salon Assistant',
    value: 'Hair or Beauty Salon Assistant',
  },
  {
    name: 'Hair or Beauty Salon Manager',
    value: 'Hair or Beauty Salon Manager',
  },
  { name: 'Hairdresser', value: 'Hairdresser' },
  { name: 'Handyperson', value: 'Handyperson' },
  { name: 'Hardware Technician', value: 'Hardware Technician' },
  {
    name: 'Health and Welfare Services Managers nec',
    value: 'Health and Welfare Services Managers nec',
  },
  {
    name: 'Health Diagnostic and Promotion Professionals nec',
    value: 'Health Diagnostic and Promotion Professionals nec',
  },
  {
    name: 'Health Information Manager',
    value: 'Health Information Manager',
  },
  { name: 'Health Practice Manager', value: 'Health Practice Manager' },
  { name: 'Health Promotion Officer', value: 'Health Promotion Officer' },
  { name: 'Helicopter Pilot', value: 'Helicopter Pilot' },
  { name: 'Herbalist (Western)', value: 'Herbalist (Western)' },
  {
    name: 'Hide and Skin Processing Machine Operator',
    value: 'Hide and Skin Processing Machine Operator',
  },
  {
    name: 'Hide and Skin Processing Worker',
    value: 'Hide and Skin Processing Worker',
  },
  { name: 'Historian', value: 'Historian' },
  {
    name: 'Home Improvement Installer',
    value: 'Home Improvement Installer',
  },
  { name: 'Homoeopath', value: 'Homoeopath' },
  { name: 'Horse Breeder', value: 'Horse Breeder' },
  {
    name: 'Horse Riding Coach or Instructor',
    value: 'Horse Riding Coach or Instructor',
  },
  { name: 'Horse Trainer', value: 'Horse Trainer' },
  {
    name: 'Horticultural Nursery Assistant',
    value: 'Horticultural Nursery Assistant',
  },
  { name: 'Hospital Orderly', value: 'Hospital Orderly' },
  { name: 'Hospital Pharmacist', value: 'Hospital Pharmacist' },
  { name: 'Hospitality Workers nec', value: 'Hospitality Workers nec' },
  {
    name: 'Hospitality, Retail and Service Managers nec',
    value: 'Hospitality, Retail and Service Managers nec',
  },
  { name: 'Hostel Parent', value: 'Hostel Parent' },
  { name: 'Hotel or Motel Manager', value: 'Hotel or Motel Manager' },
  {
    name: 'Hotel or Motel Receptionist',
    value: 'Hotel or Motel Receptionist',
  },
  { name: 'Hotel Service Manager', value: 'Hotel Service Manager' },
  { name: 'Human Resource Adviser', value: 'Human Resource Adviser' },
  { name: 'Human Resource Clerk', value: 'Human Resource Clerk' },
  { name: 'Human Resource Manager', value: 'Human Resource Manager' },
  { name: 'Hunter-Trapper', value: 'Hunter-Trapper' },
  { name: 'Hunting Guide', value: 'Hunting Guide' },
  { name: 'Hydrogeologist', value: 'Hydrogeologist' },
  { name: 'Hydrographer', value: 'Hydrographer' },
  { name: 'ICT Account Manager', value: 'ICT Account Manager' },
  { name: 'ICT Business Analyst', value: 'ICT Business Analyst' },
  {
    name: 'ICT Business Development Manager',
    value: 'ICT Business Development Manager',
  },
  {
    name: 'ICT Customer Support Officer',
    value: 'ICT Customer Support Officer',
  },
  { name: 'ICT Managers nec', value: 'ICT Managers nec' },
  { name: 'ICT Project Manager', value: 'ICT Project Manager' },
  {
    name: 'ICT Quality Assurance Engineer',
    value: 'ICT Quality Assurance Engineer',
  },
  { name: 'ICT Sales Assistant', value: 'ICT Sales Assistant' },
  { name: 'ICT Sales Representative', value: 'ICT Sales Representative' },
  { name: 'ICT Security Specialist', value: 'ICT Security Specialist' },
  {
    name: 'ICT Support and Test Engineers nec',
    value: 'ICT Support and Test Engineers nec',
  },
  { name: 'ICT Support Engineer', value: 'ICT Support Engineer' },
  {
    name: 'ICT Support Technicians nec',
    value: 'ICT Support Technicians nec',
  },
  { name: 'ICT Systems Test Engineer', value: 'ICT Systems Test Engineer' },
  { name: 'ICT Trainer', value: 'ICT Trainer' },
  { name: 'Illustrator', value: 'Illustrator' },
  { name: 'Immigration Officer', value: 'Immigration Officer' },
  { name: 'Import-Export Clerk', value: 'Import-Export Clerk' },
  { name: 'Importer or Exporter', value: 'Importer or Exporter' },
  { name: 'Industrial Designer', value: 'Industrial Designer' },
  { name: 'Industrial Engineer', value: 'Industrial Engineer' },
  { name: 'Industrial Pharmacist', value: 'Industrial Pharmacist' },
  { name: 'Industrial Spraypainter', value: 'Industrial Spraypainter' },
  {
    name: 'Information and Organisation Professionals nec',
    value: 'Information and Organisation Professionals nec',
  },
  { name: 'Information Officer', value: 'Information Officer' },
  {
    name: 'Inspectors and Regulatory Officers nec',
    value: 'Inspectors and Regulatory Officers nec',
  },
  { name: 'Insurance Agent', value: 'Insurance Agent' },
  { name: 'Insurance Broker', value: 'Insurance Broker' },
  { name: 'Insurance Consultant', value: 'Insurance Consultant' },
  { name: 'Insurance Investigator', value: 'Insurance Investigator' },
  { name: 'Insurance Loss Adjuster', value: 'Insurance Loss Adjuster' },
  { name: 'Insurance Risk Surveyor', value: 'Insurance Risk Surveyor' },
  { name: 'Integration Aide', value: 'Integration Aide' },
  {
    name: 'Intellectual Property Lawyer',
    value: 'Intellectual Property Lawyer',
  },
  { name: 'Intelligence Officer', value: 'Intelligence Officer' },
  {
    name: 'Intensive Care Ambulance Paramedic (Aus) Ambulance Paramedic (NZ)',
    value: 'Intensive Care Ambulance Paramedic (Aus) Ambulance Paramedic (NZ)',
  },
  { name: 'Intensive Care Specialist', value: 'Intensive Care Specialist' },
  { name: 'Interior Decorator', value: 'Interior Decorator' },
  { name: 'Interior Designer', value: 'Interior Designer' },
  { name: 'Internal Auditor', value: 'Internal Auditor' },
  { name: 'Interpreter', value: 'Interpreter' },
  { name: 'Ironer or Presser', value: 'Ironer or Presser' },
  { name: 'Jeweller', value: 'Jeweller' },
  { name: 'Jewellery Designer', value: 'Jewellery Designer' },
  { name: 'Jockey', value: 'Jockey' },
  { name: 'Joiner', value: 'Joiner' },
  {
    name: 'Journalists and Other Writers nec',
    value: 'Journalists and Other Writers nec',
  },
  { name: 'Judge', value: 'Judge' },
  {
    name: 'Judicial and Other Legal Professionals nec',
    value: 'Judicial and Other Legal Professionals nec',
  },
  {
    name: 'Kaiako Kohanga Reo (Maori Language Nest Teacher)',
    value: 'Kaiako Kohanga Reo (Maori Language Nest Teacher)',
  },
  {
    name: 'Kaiako Kura Kaupapa Maori (Maori-medium Primary School Teacher)',
    value: 'Kaiako Kura Kaupapa Maori (Maori-medium Primary School Teacher)',
  },
  {
    name: 'Kaiawhina (Hauora) (Maori Health Assistant)',
    value: 'Kaiawhina (Hauora) (Maori Health Assistant)',
  },
  {
    name: 'Kaiawhina Kohanga Reo (Maori Language Nest Assistant)',
    value: 'Kaiawhina Kohanga Reo (Maori Language Nest Assistant)',
  },
  {
    name: 'Kaiawhina Kura Kaupapa Maori (Maori-medium School Assistant)',
    value: 'Kaiawhina Kura Kaupapa Maori (Maori-medium School Assistant)',
  },
  { name: 'Kennel Hand', value: 'Kennel Hand' },
  { name: 'Kitchenhand', value: 'Kitchenhand' },
  { name: 'Knitting Machine Operator', value: 'Knitting Machine Operator' },
  { name: 'Laboratory Manager', value: 'Laboratory Manager' },
  { name: 'Labourers nec', value: 'Labourers nec' },
  { name: 'Lagger', value: 'Lagger' },
  { name: 'Land Economist', value: 'Land Economist' },
  { name: 'Landscape Architect', value: 'Landscape Architect' },
  { name: 'Landscape Gardener', value: 'Landscape Gardener' },
  { name: 'Laundry Worker (General)', value: 'Laundry Worker (General)' },
  { name: 'Law Clerk', value: 'Law Clerk' },
  {
    name: 'Leaflet or Newspaper Deliverer',
    value: 'Leaflet or Newspaper Deliverer',
  },
  { name: 'Leather Goods Maker', value: 'Leather Goods Maker' },
  { name: 'Legal Executive', value: 'Legal Executive' },
  { name: 'Legal Secretary', value: 'Legal Secretary' },
  { name: 'Legislators nec', value: 'Legislators nec' },
  { name: 'Liaison Officer', value: 'Liaison Officer' },
  { name: 'Librarian', value: 'Librarian' },
  { name: 'Library Assistant', value: 'Library Assistant' },
  { name: 'Library Technician', value: 'Library Technician' },
  { name: 'Licensed Club Manager', value: 'Licensed Club Manager' },
  { name: 'Life Science Technician', value: 'Life Science Technician' },
  { name: 'Life Scientist (General)', value: 'Life Scientist (General)' },
  { name: 'Life Scientists nec', value: 'Life Scientists nec' },
  { name: 'Lifeguard', value: 'Lifeguard' },
  { name: 'Lift Mechanic', value: 'Lift Mechanic' },
  { name: 'Light Technician', value: 'Light Technician' },
  { name: 'Linemarker', value: 'Linemarker' },
  {
    name: 'Livestock Farm Workers nec',
    value: 'Livestock Farm Workers nec',
  },
  { name: 'Livestock Farmers nec', value: 'Livestock Farmers nec' },
  { name: 'Loader Operator', value: 'Loader Operator' },
  {
    name: 'Local Government Legislator',
    value: 'Local Government Legislator',
  },
  { name: 'Locksmith', value: 'Locksmith' },
  { name: 'Logging Assistant', value: 'Logging Assistant' },
  { name: 'Logging Plant Operator', value: 'Logging Plant Operator' },
  { name: 'Machine Operators nec', value: 'Machine Operators nec' },
  {
    name: 'Machine Shorthand Reporter',
    value: 'Machine Shorthand Reporter',
  },
  { name: 'Magistrate', value: 'Magistrate' },
  { name: 'Mail Clerk', value: 'Mail Clerk' },
  { name: 'Maintenance Planner', value: 'Maintenance Planner' },
  { name: 'Make Up Artist', value: 'Make Up Artist' },
  { name: 'Management Accountant', value: 'Management Accountant' },
  { name: 'Management Consultant', value: 'Management Consultant' },
  { name: 'Manufacturer', value: 'Manufacturer' },
  { name: 'Marine Biologist', value: 'Marine Biologist' },
  { name: 'Marine Surveyor', value: 'Marine Surveyor' },
  {
    name: 'Marine Transport Professionals nec',
    value: 'Marine Transport Professionals nec',
  },
  { name: 'Market Research Analyst', value: 'Market Research Analyst' },
  { name: 'Marketing Specialist', value: 'Marketing Specialist' },
  { name: 'Massage Therapist', value: 'Massage Therapist' },
  { name: 'Master Fisher', value: 'Master Fisher' },
  { name: 'Materials Engineer', value: 'Materials Engineer' },
  { name: 'Materials Recycler', value: 'Materials Recycler' },
  { name: 'Mathematician', value: 'Mathematician' },
  { name: 'Meat Boner and Slicer', value: 'Meat Boner and Slicer' },
  { name: 'Meat Inspector', value: 'Meat Inspector' },
  { name: 'Meat Packer', value: 'Meat Packer' },
  { name: 'Meat Process Worker', value: 'Meat Process Worker' },
  { name: "Mechanic's Assistant", value: "Mechanic's Assistant" },
  { name: 'Mechanical Engineer', value: 'Mechanical Engineer' },
  {
    name: 'Mechanical Engineering Draftsperson',
    value: 'Mechanical Engineering Draftsperson',
  },
  {
    name: 'Mechanical Engineering Technician',
    value: 'Mechanical Engineering Technician',
  },
  {
    name: 'Media Producer (excluding Video)',
    value: 'Media Producer (excluding Video)',
  },
  {
    name: 'Medical Administrator (Aus) Medical Superintendent (NZ)',
    value: 'Medical Administrator (Aus) Medical Superintendent (NZ)',
  },
  {
    name: 'Medical Diagnostic Radiographer',
    value: 'Medical Diagnostic Radiographer',
  },
  {
    name: 'Medical Laboratory Scientist',
    value: 'Medical Laboratory Scientist',
  },
  {
    name: 'Medical Laboratory Technician',
    value: 'Medical Laboratory Technician',
  },
  { name: 'Medical Oncologist', value: 'Medical Oncologist' },
  { name: 'Medical Practitioners nec', value: 'Medical Practitioners nec' },
  {
    name: 'Medical Radiation Therapist',
    value: 'Medical Radiation Therapist',
  },
  { name: 'Medical Receptionist', value: 'Medical Receptionist' },
  { name: 'Medical Technicians nec', value: 'Medical Technicians nec' },
  { name: 'Member of Parliament', value: 'Member of Parliament' },
  {
    name: 'Metal Casting Trades Worker',
    value: 'Metal Casting Trades Worker',
  },
  {
    name: 'Metal Engineering Process Worker',
    value: 'Metal Engineering Process Worker',
  },
  { name: 'Metal Fabricator', value: 'Metal Fabricator' },
  {
    name: 'Metal Fitters and Machinists nec',
    value: 'Metal Fitters and Machinists nec',
  },
  {
    name: 'Metal Machinist (First Class)',
    value: 'Metal Machinist (First Class)',
  },
  { name: 'Metal Polisher', value: 'Metal Polisher' },
  {
    name: 'Metallurgical or Materials Technician',
    value: 'Metallurgical or Materials Technician',
  },
  { name: 'Metallurgist', value: 'Metallurgist' },
  { name: 'Meteorologist', value: 'Meteorologist' },
  { name: 'Meter Reader', value: 'Meter Reader' },
  { name: 'Microbiologist', value: 'Microbiologist' },
  {
    name: 'Middle School Teacher (Aus) Intermediate School Teacher (NZ)',
    value: 'Middle School Teacher (Aus) Intermediate School Teacher (NZ)',
  },
  { name: 'Midwife', value: 'Midwife' },
  {
    name: 'Migration Agent (Aus) Immigration Consultant (NZ)',
    value: 'Migration Agent (Aus) Immigration Consultant (NZ)',
  },
  { name: 'Mine Deputy', value: 'Mine Deputy' },
  { name: 'Miner', value: 'Miner' },
  {
    name: 'Mining Engineer (excluding Petroleum)',
    value: 'Mining Engineer (excluding Petroleum)',
  },
  { name: 'Mining Support Worker', value: 'Mining Support Worker' },
  { name: 'Minister of Religion', value: 'Minister of Religion' },
  {
    name: 'Mixed Crop and Livestock Farm Worker',
    value: 'Mixed Crop and Livestock Farm Worker',
  },
  {
    name: 'Mixed Crop and Livestock Farmer',
    value: 'Mixed Crop and Livestock Farmer',
  },
  { name: 'Mixed Crop Farmer', value: 'Mixed Crop Farmer' },
  {
    name: 'Mixed Livestock Farm Worker',
    value: 'Mixed Livestock Farm Worker',
  },
  { name: 'Mixed Livestock Farmer', value: 'Mixed Livestock Farmer' },
  {
    name: 'Mobile Plant Operators nec',
    value: 'Mobile Plant Operators nec',
  },
  { name: 'Model', value: 'Model' },
  { name: 'Money Market Clerk', value: 'Money Market Clerk' },
  { name: 'Mothercraft Nurse', value: 'Mothercraft Nurse' },
  {
    name: 'Motion Picture Projectionist',
    value: 'Motion Picture Projectionist',
  },
  { name: 'Motor Mechanic (General)', value: 'Motor Mechanic (General)' },
  {
    name: 'Motor Vehicle Licence Examiner',
    value: 'Motor Vehicle Licence Examiner',
  },
  {
    name: 'Motor Vehicle or Caravan Salesperson',
    value: 'Motor Vehicle or Caravan Salesperson',
  },
  {
    name: 'Motor Vehicle Parts and Accessories Fitter (General)',
    value: 'Motor Vehicle Parts and Accessories Fitter (General)',
  },
  {
    name: 'Motor Vehicle Parts Interpreter (Aus) Automotive Parts Salesperson (NZ)',
    value: 'Motor Vehicle Parts Interpreter (Aus) Automotive Parts Salesperson (NZ)',
  },
  { name: 'Motorcycle Mechanic', value: 'Motorcycle Mechanic' },
  { name: 'Mountain or Glacier Guide', value: 'Mountain or Glacier Guide' },
  { name: 'Multimedia Designer', value: 'Multimedia Designer' },
  { name: 'Multimedia Specialist', value: 'Multimedia Specialist' },
  { name: 'Mushroom Picker', value: 'Mushroom Picker' },
  { name: 'Music Director', value: 'Music Director' },
  { name: 'Music Professionals nec', value: 'Music Professionals nec' },
  {
    name: 'Music Teacher (Private Tuition)',
    value: 'Music Teacher (Private Tuition)',
  },
  {
    name: 'Musical Instrument Maker or Repairer',
    value: 'Musical Instrument Maker or Repairer',
  },
  { name: 'Musician (Instrumental)', value: 'Musician (Instrumental)' },
  { name: 'Nanny', value: 'Nanny' },
  {
    name: 'Natural and Physical Science Professionals nec',
    value: 'Natural and Physical Science Professionals nec',
  },
  { name: 'Natural Remedy Consultant', value: 'Natural Remedy Consultant' },
  { name: 'Naturopath', value: 'Naturopath' },
  {
    name: 'Naval Architect (Aus) Marine Designer (NZ)',
    value: 'Naval Architect (Aus) Marine Designer (NZ)',
  },
  { name: 'Network Administrator', value: 'Network Administrator' },
  { name: 'Network Analyst', value: 'Network Analyst' },
  { name: 'Neurologist', value: 'Neurologist' },
  { name: 'Neurosurgeon', value: 'Neurosurgeon' },
  {
    name: 'Newspaper or Periodical Editor',
    value: 'Newspaper or Periodical Editor',
  },
  {
    name: 'Noxious Weeds and Pest Inspector',
    value: 'Noxious Weeds and Pest Inspector',
  },
  {
    name: 'Nuclear Medicine Technologist',
    value: 'Nuclear Medicine Technologist',
  },
  { name: 'Nurse Educator', value: 'Nurse Educator' },
  { name: 'Nurse Manager', value: 'Nurse Manager' },
  { name: 'Nurse Practitioner', value: 'Nurse Practitioner' },
  { name: 'Nurse Researcher', value: 'Nurse Researcher' },
  { name: 'Nurseryperson', value: 'Nurseryperson' },
  { name: 'Nursing Clinical Director', value: 'Nursing Clinical Director' },
  { name: 'Nursing Support Worker', value: 'Nursing Support Worker' },
  { name: 'Nutritionist', value: 'Nutritionist' },
  {
    name: 'Obstetrician and Gynaecologist',
    value: 'Obstetrician and Gynaecologist',
  },
  {
    name: 'Occupational Health and Safety Adviser',
    value: 'Occupational Health and Safety Adviser',
  },
  { name: 'Occupational Therapist', value: 'Occupational Therapist' },
  { name: 'Office Cashier', value: 'Office Cashier' },
  { name: 'Office Manager', value: 'Office Manager' },
  {
    name: 'Operating Theatre Technician',
    value: 'Operating Theatre Technician',
  },
  { name: 'Ophthalmologist', value: 'Ophthalmologist' },
  {
    name: 'Optical Dispenser (Aus) Dispensing Optician (NZ)',
    value: 'Optical Dispenser (Aus) Dispensing Optician (NZ)',
  },
  { name: 'Optical Mechanic', value: 'Optical Mechanic' },
  { name: 'Optometrist', value: 'Optometrist' },
  { name: 'Order Clerk', value: 'Order Clerk' },
  {
    name: 'Organisation and Methods Analyst',
    value: 'Organisation and Methods Analyst',
  },
  {
    name: 'Organisational Psychologist',
    value: 'Organisational Psychologist',
  },
  { name: 'Orthopaedic Surgeon', value: 'Orthopaedic Surgeon' },
  { name: 'Orthoptist', value: 'Orthoptist' },
  { name: 'Orthotist or Prosthetist', value: 'Orthotist or Prosthetist' },
  { name: 'Osteopath', value: 'Osteopath' },
  {
    name: 'Other Sales Support Worker',
    value: 'Other Sales Support Worker',
  },
  { name: 'Other Spatial Scientist', value: 'Other Spatial Scientist' },
  {
    name: 'Other Sports Coach or Instructor',
    value: 'Other Sports Coach or Instructor',
  },
  { name: 'Other Sports Official', value: 'Other Sports Official' },
  {
    name: 'Other Wood Processing Machine Operator',
    value: 'Other Wood Processing Machine Operator',
  },
  { name: 'Otorhinolaryngologist', value: 'Otorhinolaryngologist' },
  {
    name: 'Out of School Hours Care Worker',
    value: 'Out of School Hours Care Worker',
  },
  {
    name: 'Outdoor Adventure Guides nec',
    value: 'Outdoor Adventure Guides nec',
  },
  {
    name: 'Outdoor Adventure Instructor',
    value: 'Outdoor Adventure Instructor',
  },
  { name: 'Packers nec', value: 'Packers nec' },
  { name: 'Paediatric Surgeon', value: 'Paediatric Surgeon' },
  { name: 'Paediatrician', value: 'Paediatrician' },
  { name: 'Painter (Visual Arts)', value: 'Painter (Visual Arts)' },
  { name: 'Painting Trades Worker', value: 'Painting Trades Worker' },
  { name: 'Panelbeater', value: 'Panelbeater' },
  {
    name: 'Paper and Pulp Mill Operator',
    value: 'Paper and Pulp Mill Operator',
  },
  {
    name: 'Paper and Pulp Mill Worker',
    value: 'Paper and Pulp Mill Worker',
  },
  {
    name: 'Paper Products Machine Operator',
    value: 'Paper Products Machine Operator',
  },
  { name: 'Park Ranger', value: 'Park Ranger' },
  { name: 'Parking Inspector', value: 'Parking Inspector' },
  {
    name: 'Parole or Probation Officer',
    value: 'Parole or Probation Officer',
  },
  { name: 'Passenger Coach Driver', value: 'Passenger Coach Driver' },
  { name: 'Pastrycook', value: 'Pastrycook' },
  { name: "Pastrycook's Assistant", value: "Pastrycook's Assistant" },
  { name: 'Patents Examiner', value: 'Patents Examiner' },
  { name: 'Pathologist', value: 'Pathologist' },
  {
    name: 'Pathology Collector (Aus) Phlebotomist (NZ)',
    value: 'Pathology Collector (Aus) Phlebotomist (NZ)',
  },
  {
    name: 'Paving and Surfacing Labourer',
    value: 'Paving and Surfacing Labourer',
  },
  { name: 'Paving Plant Operator', value: 'Paving Plant Operator' },
  { name: 'Payroll Clerk', value: 'Payroll Clerk' },
  {
    name: 'Performing Arts Technicians nec',
    value: 'Performing Arts Technicians nec',
  },
  { name: 'Personal Assistant', value: 'Personal Assistant' },
  { name: 'Personal Care Assistant', value: 'Personal Care Assistant' },
  {
    name: 'Personal Service Workers nec',
    value: 'Personal Service Workers nec',
  },
  { name: 'Pest Controller', value: 'Pest Controller' },
  { name: 'Pet Groomer', value: 'Pet Groomer' },
  { name: 'Petroleum Engineer', value: 'Petroleum Engineer' },
  { name: 'Pharmacy Sales Assistant', value: 'Pharmacy Sales Assistant' },
  { name: 'Pharmacy Technician', value: 'Pharmacy Technician' },
  { name: 'Photographer', value: 'Photographer' },
  { name: "Photographer's Assistant", value: "Photographer's Assistant" },
  {
    name: 'Photographic Developer and Printer',
    value: 'Photographic Developer and Printer',
  },
  { name: 'Physicist', value: 'Physicist' },
  { name: 'Physiotherapist', value: 'Physiotherapist' },
  { name: 'Picture Framer', value: 'Picture Framer' },
  { name: 'Pig Farmer', value: 'Pig Farmer' },
  {
    name: 'Plastic and Reconstructive Surgeon',
    value: 'Plastic and Reconstructive Surgeon',
  },
  {
    name: 'Plastic Cablemaking Machine Operator',
    value: 'Plastic Cablemaking Machine Operator',
  },
  {
    name: 'Plastic Compounding and Reclamation Machine Operator',
    value: 'Plastic Compounding and Reclamation Machine Operator',
  },
  {
    name: 'Plastics and Rubber Production Machine Operators nec',
    value: 'Plastics and Rubber Production Machine Operators nec',
  },
  {
    name: 'Plastics Fabricator or Welder',
    value: 'Plastics Fabricator or Welder',
  },
  { name: 'Plastics Factory Worker', value: 'Plastics Factory Worker' },
  {
    name: 'Plastics Production Machine Operator (General)',
    value: 'Plastics Production Machine Operator (General)',
  },
  { name: 'Plastics Technician', value: 'Plastics Technician' },
  { name: 'Plumber (General)', value: 'Plumber (General)' },
  { name: "Plumber's Assistant", value: "Plumber's Assistant" },
  { name: 'Plumbing Inspector', value: 'Plumbing Inspector' },
  { name: 'Podiatrist', value: 'Podiatrist' },
  { name: 'Police Officer', value: 'Police Officer' },
  { name: 'Policy Analyst', value: 'Policy Analyst' },
  {
    name: 'Policy and Planning Manager',
    value: 'Policy and Planning Manager',
  },
  { name: 'Post Office Manager', value: 'Post Office Manager' },
  { name: 'Postal Delivery Officer', value: 'Postal Delivery Officer' },
  { name: 'Postal Sorting Officer', value: 'Postal Sorting Officer' },
  { name: 'Potter or Ceramic Artist', value: 'Potter or Ceramic Artist' },
  {
    name: 'Pouako Kura Kaupapa Maori (Maori-medium Primary School Senior Teacher)',
    value: 'Pouako Kura Kaupapa Maori (Maori-medium Primary School Senior Teacher)',
  },
  { name: 'Poultry Farm Worker', value: 'Poultry Farm Worker' },
  { name: 'Poultry Farmer', value: 'Poultry Farmer' },
  { name: 'Poultry Process Worker', value: 'Poultry Process Worker' },
  {
    name: 'Power Generation Plant Operator',
    value: 'Power Generation Plant Operator',
  },
  { name: 'Practice Managers nec', value: 'Practice Managers nec' },
  {
    name: 'Precision Instrument Maker and Repairer',
    value: 'Precision Instrument Maker and Repairer',
  },
  { name: 'Preschool Aide', value: 'Preschool Aide' },
  { name: 'Pressure Welder', value: 'Pressure Welder' },
  {
    name: 'Primary Health Organisation Manager',
    value: 'Primary Health Organisation Manager',
  },
  {
    name: 'Primary Products Inspectors nec',
    value: 'Primary Products Inspectors nec',
  },
  { name: 'Primary School Teacher', value: 'Primary School Teacher' },
  { name: 'Print Finisher', value: 'Print Finisher' },
  { name: 'Print Journalist', value: 'Print Journalist' },
  { name: "Printer's Assistant", value: "Printer's Assistant" },
  { name: 'Printing Machinist', value: 'Printing Machinist' },
  { name: 'Printing Table Worker', value: 'Printing Table Worker' },
  { name: 'Prison Officer', value: 'Prison Officer' },
  { name: 'Private Investigator', value: 'Private Investigator' },
  {
    name: 'Private Tutors and Teachers nec',
    value: 'Private Tutors and Teachers nec',
  },
  { name: 'Procurement Manager', value: 'Procurement Manager' },
  { name: 'Product Assembler', value: 'Product Assembler' },
  { name: 'Product Examiner', value: 'Product Examiner' },
  { name: 'Product Grader', value: 'Product Grader' },
  { name: 'Product Tester', value: 'Product Tester' },
  {
    name: 'Production Assistant (Film, Television, Radio or Stage)',
    value: 'Production Assistant (Film, Television, Radio or Stage)',
  },
  { name: 'Production Clerk', value: 'Production Clerk' },
  {
    name: 'Production Manager (Forestry)',
    value: 'Production Manager (Forestry)',
  },
  {
    name: 'Production Manager (Manufacturing)',
    value: 'Production Manager (Manufacturing)',
  },
  {
    name: 'Production Manager (Mining)',
    value: 'Production Manager (Mining)',
  },
  {
    name: 'Production or Plant Engineer',
    value: 'Production or Plant Engineer',
  },
  {
    name: 'Program Director (Television or Radio)',
    value: 'Program Director (Television or Radio)',
  },
  {
    name: 'Program or Project Administrator',
    value: 'Program or Project Administrator',
  },
  { name: 'Project Builder', value: 'Project Builder' },
  { name: 'Proof Reader', value: 'Proof Reader' },
  { name: 'Property Manager', value: 'Property Manager' },
  { name: 'Psychiatrist', value: 'Psychiatrist' },
  { name: 'Psychologists nec', value: 'Psychologists nec' },
  { name: 'Psychotherapist', value: 'Psychotherapist' },
  { name: 'Public Relations Manager', value: 'Public Relations Manager' },
  {
    name: 'Public Relations Professional',
    value: 'Public Relations Professional',
  },
  { name: 'Purchasing Officer', value: 'Purchasing Officer' },
  { name: 'Quality Assurance Manager', value: 'Quality Assurance Manager' },
  { name: 'Quantity Surveyor', value: 'Quantity Surveyor' },
  { name: 'Quarantine Officer', value: 'Quarantine Officer' },
  { name: 'Radiation Oncologist', value: 'Radiation Oncologist' },
  { name: 'Radiator Repairer', value: 'Radiator Repairer' },
  { name: 'Radio Despatcher', value: 'Radio Despatcher' },
  { name: 'Radio Journalist', value: 'Radio Journalist' },
  { name: 'Radio Presenter', value: 'Radio Presenter' },
  {
    name: 'Radiocommunications Technician',
    value: 'Radiocommunications Technician',
  },
  { name: 'Railway Signal Operator', value: 'Railway Signal Operator' },
  { name: 'Railway Station Manager', value: 'Railway Station Manager' },
  {
    name: 'Railway Track Plant Operator',
    value: 'Railway Track Plant Operator',
  },
  { name: 'Railway Track Worker', value: 'Railway Track Worker' },
  { name: 'Railways Assistant', value: 'Railways Assistant' },
  {
    name: 'Real Estate Agency Principal (Aus) Real Estate Agency Licensee (NZ)',
    value: 'Real Estate Agency Principal (Aus) Real Estate Agency Licensee (NZ)',
  },
  { name: 'Real Estate Agent', value: 'Real Estate Agent' },
  {
    name: 'Real Estate Representative',
    value: 'Real Estate Representative',
  },
  { name: 'Receptionist (General)', value: 'Receptionist (General)' },
  { name: 'Records Manager', value: 'Records Manager' },
  {
    name: 'Recreation Officer (Aus) Recreation Coordinator (NZ)',
    value: 'Recreation Officer (Aus) Recreation Coordinator (NZ)',
  },
  { name: 'Recruitment Consultant', value: 'Recruitment Consultant' },
  {
    name: 'Recycling or Rubbish Collector',
    value: 'Recycling or Rubbish Collector',
  },
  { name: 'Recycling Worker', value: 'Recycling Worker' },
  { name: 'Refuge Worker', value: 'Refuge Worker' },
  {
    name: 'Regional Education Manager',
    value: 'Regional Education Manager',
  },
  {
    name: 'Registered Nurse (Aged Care)',
    value: 'Registered Nurse (Aged Care)',
  },
  {
    name: 'Registered Nurse (Child and Family Health)',
    value: 'Registered Nurse (Child and Family Health)',
  },
  {
    name: 'Registered Nurse (Community Health)',
    value: 'Registered Nurse (Community Health)',
  },
  {
    name: 'Registered Nurse (Critical Care and Emergency)',
    value: 'Registered Nurse (Critical Care and Emergency)',
  },
  {
    name: 'Registered Nurse (Developmental Disability)',
    value: 'Registered Nurse (Developmental Disability)',
  },
  {
    name: 'Registered Nurse (Disability and Rehabilitation)',
    value: 'Registered Nurse (Disability and Rehabilitation)',
  },
  {
    name: 'Registered Nurse (Medical Practice)',
    value: 'Registered Nurse (Medical Practice)',
  },
  {
    name: 'Registered Nurse (Medical)',
    value: 'Registered Nurse (Medical)',
  },
  {
    name: 'Registered Nurse (Mental Health)',
    value: 'Registered Nurse (Mental Health)',
  },
  {
    name: 'Registered Nurse (Paediatrics)',
    value: 'Registered Nurse (Paediatrics)',
  },
  {
    name: 'Registered Nurse (Perioperative)',
    value: 'Registered Nurse (Perioperative)',
  },
  {
    name: 'Registered Nurse (Surgical)',
    value: 'Registered Nurse (Surgical)',
  },
  { name: 'Registered Nurses nec', value: 'Registered Nurses nec' },
  { name: 'Rehabilitation Counsellor', value: 'Rehabilitation Counsellor' },
  {
    name: 'Reinforced Plastic and Composite Production Worker',
    value: 'Reinforced Plastic and Composite Production Worker',
  },
  { name: 'Religious Assistant', value: 'Religious Assistant' },
  { name: 'Renal Medicine Specialist', value: 'Renal Medicine Specialist' },
  { name: 'Rental Salesperson', value: 'Rental Salesperson' },
  {
    name: 'Research and Development Manager',
    value: 'Research and Development Manager',
  },
  { name: 'Resident Medical Officer', value: 'Resident Medical Officer' },
  { name: 'Residential Care Officer', value: 'Residential Care Officer' },
  { name: 'Retail Buyer', value: 'Retail Buyer' },
  {
    name: 'Retail Loss Prevention Officer',
    value: 'Retail Loss Prevention Officer',
  },
  { name: 'Retail Manager (General)', value: 'Retail Manager (General)' },
  { name: 'Retail Pharmacist', value: 'Retail Pharmacist' },
  { name: 'Retail Supervisor', value: 'Retail Supervisor' },
  {
    name: 'Retirement Village Manager',
    value: 'Retirement Village Manager',
  },
  { name: 'Rheumatologist', value: 'Rheumatologist' },
  { name: 'Road Roller Operator', value: 'Road Roller Operator' },
  { name: 'Road Traffic Controller', value: 'Road Traffic Controller' },
  { name: 'Roof Plumber', value: 'Roof Plumber' },
  { name: 'Roof Tiler', value: 'Roof Tiler' },
  { name: 'Rubber Factory Worker', value: 'Rubber Factory Worker' },
  {
    name: 'Rubber Production Machine Operator',
    value: 'Rubber Production Machine Operator',
  },
  { name: 'Safety Inspector', value: 'Safety Inspector' },
  { name: 'Sail Maker', value: 'Sail Maker' },
  {
    name: 'Sales and Marketing Manager',
    value: 'Sales and Marketing Manager',
  },
  { name: 'Sales Assistant (General)', value: 'Sales Assistant (General)' },
  {
    name: 'Sales Assistants and Salespersons nec',
    value: 'Sales Assistants and Salespersons nec',
  },
  { name: 'Sales Demonstrator', value: 'Sales Demonstrator' },
  {
    name: 'Sales Representative (Building and Plumbing Supplies)',
    value: 'Sales Representative (Building and Plumbing Supplies)',
  },
  {
    name: 'Sales Representative (Business Services)',
    value: 'Sales Representative (Business Services)',
  },
  {
    name: 'Sales Representative (Industrial Products)',
    value: 'Sales Representative (Industrial Products)',
  },
  {
    name: 'Sales Representative (Medical and Pharmaceutical Products)',
    value: 'Sales Representative (Medical and Pharmaceutical Products)',
  },
  {
    name: 'Sales Representative (Motor Vehicle Parts and Accessories)',
    value: 'Sales Representative (Motor Vehicle Parts and Accessories)',
  },
  {
    name: 'Sales Representative (Personal and Household Goods)',
    value: 'Sales Representative (Personal and Household Goods)',
  },
  { name: 'Sales Representatives nec', value: 'Sales Representatives nec' },
  { name: 'Sand Blaster', value: 'Sand Blaster' },
  { name: 'Saw Maker and Repairer', value: 'Saw Maker and Repairer' },
  {
    name: 'Sawmill or Timber Yard Worker',
    value: 'Sawmill or Timber Yard Worker',
  },
  { name: 'Sawmilling Operator', value: 'Sawmilling Operator' },
  { name: 'Scaffolder', value: 'Scaffolder' },
  {
    name: 'School Laboratory Technician',
    value: 'School Laboratory Technician',
  },
  { name: 'School Principal', value: 'School Principal' },
  { name: 'Science Technicians nec', value: 'Science Technicians nec' },
  { name: 'Screen Printer', value: 'Screen Printer' },
  { name: 'Sculptor', value: 'Sculptor' },
  { name: 'Seafood Packer', value: 'Seafood Packer' },
  { name: 'Seafood Process Worker', value: 'Seafood Process Worker' },
  { name: 'Secondary School Teacher', value: 'Secondary School Teacher' },
  { name: 'Secretary (General)', value: 'Secretary (General)' },
  { name: 'Security Consultant', value: 'Security Consultant' },
  { name: 'Security Officer', value: 'Security Officer' },
  {
    name: 'Security Officers and Guards nec',
    value: 'Security Officers and Guards nec',
  },
  {
    name: 'Senior Non-commissioned Defence Force Member',
    value: 'Senior Non-commissioned Defence Force Member',
  },
  { name: 'Service Station Attendant', value: 'Service Station Attendant' },
  { name: 'Sewing Machinist', value: 'Sewing Machinist' },
  { name: 'Sex Worker or Escort', value: 'Sex Worker or Escort' },
  { name: 'Shearer', value: 'Shearer' },
  { name: 'Sheep Farm Worker', value: 'Sheep Farm Worker' },
  { name: 'Sheep Farmer', value: 'Sheep Farmer' },
  { name: 'Sheetmetal Trades Worker', value: 'Sheetmetal Trades Worker' },
  { name: 'Shelf Filler', value: 'Shelf Filler' },
  { name: "Ship's Engineer", value: "Ship's Engineer" },
  { name: "Ship's Master", value: "Ship's Master" },
  { name: "Ship's Officer", value: "Ship's Officer" },
  { name: 'Shipwright', value: 'Shipwright' },
  { name: 'Shoemaker', value: 'Shoemaker' },
  { name: 'Shot Firer', value: 'Shot Firer' },
  { name: 'Sign Erector', value: 'Sign Erector' },
  { name: 'Signwriter', value: 'Signwriter' },
  { name: 'Singer', value: 'Singer' },
  { name: 'Slaughterer', value: 'Slaughterer' },
  { name: 'Small Engine Mechanic', value: 'Small Engine Mechanic' },
  { name: 'Small Offset Printer', value: 'Small Offset Printer' },
  { name: 'Snowsport Instructor', value: 'Snowsport Instructor' },
  { name: 'Social Professionals nec', value: 'Social Professionals nec' },
  { name: 'Social Security Assessor', value: 'Social Security Assessor' },
  { name: 'Social Worker', value: 'Social Worker' },
  {
    name: 'Software and Applications Programmers nec',
    value: 'Software and Applications Programmers nec',
  },
  { name: 'Software Engineer', value: 'Software Engineer' },
  { name: 'Software Tester', value: 'Software Tester' },
  { name: 'Solicitor', value: 'Solicitor' },
  { name: 'Solid Plasterer', value: 'Solid Plasterer' },
  { name: 'Sonographer', value: 'Sonographer' },
  { name: 'Sound Technician', value: 'Sound Technician' },
  {
    name: 'Special Education Teachers nec',
    value: 'Special Education Teachers nec',
  },
  { name: 'Special Needs Teacher', value: 'Special Needs Teacher' },
  { name: 'Specialist Managers nec', value: 'Specialist Managers nec' },
  {
    name: 'Specialist Physician (General Medicine)',
    value: 'Specialist Physician (General Medicine)',
  },
  { name: 'Specialist Physicians nec', value: 'Specialist Physicians nec' },
  {
    name: 'Speech Pathologist (Aus) Speech Language Therapist (NZ)',
    value: 'Speech Pathologist (Aus) Speech Language Therapist (NZ)',
  },
  { name: 'Sports Administrator', value: 'Sports Administrator' },
  { name: 'Sports Centre Manager', value: 'Sports Centre Manager' },
  {
    name: 'Sports Development Officer',
    value: 'Sports Development Officer',
  },
  { name: 'Sports Umpire', value: 'Sports Umpire' },
  { name: 'Sportspersons nec', value: 'Sportspersons nec' },
  { name: 'Stablehand', value: 'Stablehand' },
  { name: 'Stage Manager', value: 'Stage Manager' },
  {
    name: 'Stationary Plant Operators nec',
    value: 'Stationary Plant Operators nec',
  },
  { name: 'Statistical Clerk', value: 'Statistical Clerk' },
  { name: 'Statistician', value: 'Statistician' },
  { name: 'Steel Fixer', value: 'Steel Fixer' },
  { name: 'Sterilisation Technician', value: 'Sterilisation Technician' },
  { name: 'Stock and Station Agent', value: 'Stock and Station Agent' },
  { name: 'Stock Clerk', value: 'Stock Clerk' },
  { name: 'Stockbroking Dealer', value: 'Stockbroking Dealer' },
  {
    name: 'Stone Processing Machine Operator',
    value: 'Stone Processing Machine Operator',
  },
  { name: 'Stonemason', value: 'Stonemason' },
  { name: 'Storeperson', value: 'Storeperson' },
  { name: 'Street Vendor', value: 'Street Vendor' },
  { name: 'Streetsweeper Operator', value: 'Streetsweeper Operator' },
  { name: 'Structural Engineer', value: 'Structural Engineer' },
  { name: 'Structural Steel Erector', value: 'Structural Steel Erector' },
  { name: 'Student Counsellor', value: 'Student Counsellor' },
  { name: 'Sugar Cane Grower', value: 'Sugar Cane Grower' },
  { name: 'Sugar Mill Worker', value: 'Sugar Mill Worker' },
  {
    name: 'Supply and Distribution Manager',
    value: 'Supply and Distribution Manager',
  },
  { name: 'Surgeon (General)', value: 'Surgeon (General)' },
  { name: 'Survey Interviewer', value: 'Survey Interviewer' },
  {
    name: 'Surveying or Spatial Science Technician',
    value: 'Surveying or Spatial Science Technician',
  },
  { name: 'Surveyor', value: 'Surveyor' },
  { name: "Surveyor's Assistant", value: "Surveyor's Assistant" },
  {
    name: 'Swimming Coach or Instructor',
    value: 'Swimming Coach or Instructor',
  },
  { name: 'Switchboard Operator', value: 'Switchboard Operator' },
  { name: 'Systems Administrator', value: 'Systems Administrator' },
  { name: 'Systems Analyst', value: 'Systems Analyst' },
  { name: 'Tanker Driver', value: 'Tanker Driver' },
  { name: 'Taxation Accountant', value: 'Taxation Accountant' },
  { name: 'Taxation Inspector', value: 'Taxation Inspector' },
  { name: 'Taxi Driver', value: 'Taxi Driver' },
  {
    name: 'Teacher of English to Speakers of Other Languages',
    value: 'Teacher of English to Speakers of Other Languages',
  },
  {
    name: 'Teacher of the Hearing Impaired',
    value: 'Teacher of the Hearing Impaired',
  },
  {
    name: 'Teacher of the Sight Impaired',
    value: 'Teacher of the Sight Impaired',
  },
  { name: "Teachers' Aide", value: "Teachers' Aide" },
  { name: 'Technical Cable Jointer', value: 'Technical Cable Jointer' },
  { name: 'Technical Director', value: 'Technical Director' },
  {
    name: 'Technical Sales Representatives nec',
    value: 'Technical Sales Representatives nec',
  },
  { name: 'Technical Writer', value: 'Technical Writer' },
  {
    name: 'Technicians and Trades Workers nec',
    value: 'Technicians and Trades Workers nec',
  },
  {
    name: 'Telecommunications Cable Jointer',
    value: 'Telecommunications Cable Jointer',
  },
  {
    name: 'Telecommunications Engineer',
    value: 'Telecommunications Engineer',
  },
  {
    name: 'Telecommunications Field Engineer',
    value: 'Telecommunications Field Engineer',
  },
  {
    name: 'Telecommunications Linesworker (Aus) Telecommunications Line Mechanic (NZ)',
    value: 'Telecommunications Linesworker (Aus) Telecommunications Line Mechanic (NZ)',
  },
  {
    name: 'Telecommunications Network Engineer',
    value: 'Telecommunications Network Engineer',
  },
  {
    name: 'Telecommunications Network Planner',
    value: 'Telecommunications Network Planner',
  },
  {
    name: 'Telecommunications Technical Officer or Technologist',
    value: 'Telecommunications Technical Officer or Technologist',
  },
  {
    name: 'Telecommunications Technician',
    value: 'Telecommunications Technician',
  },
  { name: 'Telemarketer', value: 'Telemarketer' },
  { name: 'Telephone Betting Clerk', value: 'Telephone Betting Clerk' },
  {
    name: 'Television Equipment Operator',
    value: 'Television Equipment Operator',
  },
  { name: 'Television Journalist', value: 'Television Journalist' },
  { name: 'Television Presenter', value: 'Television Presenter' },
  { name: 'Tennis Coach', value: 'Tennis Coach' },
  {
    name: 'Textile and Footwear Production Machine Operators nec',
    value: 'Textile and Footwear Production Machine Operators nec',
  },
  {
    name: 'Textile Dyeing and Finishing Machine Operator',
    value: 'Textile Dyeing and Finishing Machine Operator',
  },
  {
    name: 'Textile, Clothing and Footwear Mechanic',
    value: 'Textile, Clothing and Footwear Mechanic',
  },
  { name: 'Therapy Aide', value: 'Therapy Aide' },
  {
    name: 'Thoracic Medicine Specialist',
    value: 'Thoracic Medicine Specialist',
  },
  { name: 'Ticket Collector or Usher', value: 'Ticket Collector or Usher' },
  { name: 'Ticket Seller', value: 'Ticket Seller' },
  { name: 'Toolmaker', value: 'Toolmaker' },
  { name: 'Tour Guide', value: 'Tour Guide' },
  {
    name: 'Tourist Information Officer',
    value: 'Tourist Information Officer',
  },
  { name: 'Tow Truck Driver', value: 'Tow Truck Driver' },
  {
    name: 'Traditional Chinese Medicine Practitioner',
    value: 'Traditional Chinese Medicine Practitioner',
  },
  {
    name: 'Traditional Maori Health Practitioner',
    value: 'Traditional Maori Health Practitioner',
  },
  { name: 'Train Controller', value: 'Train Controller' },
  { name: 'Train Driver', value: 'Train Driver' },
  { name: 'Train Examiner', value: 'Train Examiner' },
  {
    name: 'Training and Development Professional',
    value: 'Training and Development Professional',
  },
  { name: 'Tram Driver', value: 'Tram Driver' },
  { name: 'Translator', value: 'Translator' },
  { name: 'Transport Company Manager', value: 'Transport Company Manager' },
  { name: 'Transport Conductor', value: 'Transport Conductor' },
  { name: 'Transport Engineer', value: 'Transport Engineer' },
  {
    name: 'Transport Operations Inspector',
    value: 'Transport Operations Inspector',
  },
  { name: 'Travel Agency Manager', value: 'Travel Agency Manager' },
  { name: 'Travel Attendants nec', value: 'Travel Attendants nec' },
  { name: 'Travel Consultant', value: 'Travel Consultant' },
  { name: 'Tree Faller', value: 'Tree Faller' },
  { name: 'Trekking Guide', value: 'Trekking Guide' },
  { name: 'Tribunal Member', value: 'Tribunal Member' },
  { name: 'Trolley Collector', value: 'Trolley Collector' },
  { name: 'Truck Driver (General)', value: 'Truck Driver (General)' },
  { name: "Truck Driver's Offsider", value: "Truck Driver's Offsider" },
  { name: 'Trust Officer', value: 'Trust Officer' },
  { name: 'Turf Grower', value: 'Turf Grower' },
  { name: 'Tyre Fitter', value: 'Tyre Fitter' },
  { name: 'University Lecturer', value: 'University Lecturer' },
  { name: 'University Tutor', value: 'University Tutor' },
  { name: 'Upholsterer', value: 'Upholsterer' },
  {
    name: 'Urban and Regional Planner',
    value: 'Urban and Regional Planner',
  },
  { name: 'Urologist', value: 'Urologist' },
  { name: 'Valuer', value: 'Valuer' },
  { name: 'Vascular Surgeon', value: 'Vascular Surgeon' },
  {
    name: 'Vegetable Farm Worker (Aus) Market Garden Worker (NZ)',
    value: 'Vegetable Farm Worker (Aus) Market Garden Worker (NZ)',
  },
  {
    name: 'Vegetable Grower (Aus) Market Gardener (NZ)',
    value: 'Vegetable Grower (Aus) Market Gardener (NZ)',
  },
  { name: 'Vegetable Picker', value: 'Vegetable Picker' },
  { name: 'Vehicle Body Builder', value: 'Vehicle Body Builder' },
  { name: 'Vehicle Painter', value: 'Vehicle Painter' },
  { name: 'Vehicle Trimmer', value: 'Vehicle Trimmer' },
  { name: 'Vending Machine Attendant', value: 'Vending Machine Attendant' },
  { name: 'Veterinary Nurse', value: 'Veterinary Nurse' },
  { name: 'Video Producer', value: 'Video Producer' },
  { name: 'Vineyard Worker', value: 'Vineyard Worker' },
  {
    name: 'Visual Arts and Crafts Professionals nec',
    value: 'Visual Arts and Crafts Professionals nec',
  },
  { name: 'Visual Merchandiser', value: 'Visual Merchandiser' },
  {
    name: 'Vocational Education Teacher (Aus) Polytechnic Teacher (NZ)',
    value: 'Vocational Education Teacher (Aus) Polytechnic Teacher (NZ)',
  },
  { name: 'Waiter', value: 'Waiter' },
  { name: 'Wall and Floor Tiler', value: 'Wall and Floor Tiler' },
  { name: 'Warehouse Administrator', value: 'Warehouse Administrator' },
  {
    name: 'Waste Water or Water Plant Operator',
    value: 'Waste Water or Water Plant Operator',
  },
  {
    name: 'Watch and Clock Maker and Repairer',
    value: 'Watch and Clock Maker and Repairer',
  },
  { name: 'Water Inspector', value: 'Water Inspector' },
  { name: 'Waterside Worker', value: 'Waterside Worker' },
  { name: 'Weaving Machine Operator', value: 'Weaving Machine Operator' },
  { name: 'Web Administrator', value: 'Web Administrator' },
  { name: 'Web Designer', value: 'Web Designer' },
  { name: 'Web Developer', value: 'Web Developer' },
  { name: 'Weighbridge Operator', value: 'Weighbridge Operator' },
  { name: 'Weight Loss Consultant', value: 'Weight Loss Consultant' },
  {
    name: 'Welder (First Class) (Aus) Welder (NZ)',
    value: 'Welder (First Class) (Aus) Welder (NZ)',
  },
  { name: 'Welfare Centre Manager', value: 'Welfare Centre Manager' },
  { name: 'Welfare Worker', value: 'Welfare Worker' },
  { name: 'Whitewater Rafting Guide', value: 'Whitewater Rafting Guide' },
  { name: 'Wholesaler', value: 'Wholesaler' },
  { name: 'Window Cleaner', value: 'Window Cleaner' },
  { name: 'Wine Maker', value: 'Wine Maker' },
  { name: 'Winery Cellar Hand', value: 'Winery Cellar Hand' },
  {
    name: 'Wood and Wood Products Factory Worker',
    value: 'Wood and Wood Products Factory Worker',
  },
  { name: 'Wood Machinist', value: 'Wood Machinist' },
  {
    name: 'Wood Machinists and Other Wood Trades Workers nec',
    value: 'Wood Machinists and Other Wood Trades Workers nec',
  },
  { name: 'Wood Turner', value: 'Wood Turner' },
  { name: 'Wool Buyer', value: 'Wool Buyer' },
  { name: 'Wool Classer', value: 'Wool Classer' },
  { name: 'Wool Handler', value: 'Wool Handler' },
  { name: 'Word Processing Operator', value: 'Word Processing Operator' },
  {
    name: 'Workplace Relations Adviser',
    value: 'Workplace Relations Adviser',
  },
  {
    name: 'Yarn Carding and Spinning Machine Operator',
    value: 'Yarn Carding and Spinning Machine Operator',
  },
  { name: 'Youth Worker', value: 'Youth Worker' },
  { name: 'Zookeeper', value: 'Zookeeper' },
  { name: 'Zoologist', value: 'Zoologist' },
];

export const EMPLOYER_OCCUPATION_OLD = [
  { name: 'AccountantFinancialAnalys', value: 'AccountantFinancialAnalys' },
  {
    name: 'Administrator',

    value: 'Administrator',
  },

  {
    name: 'CabinCrew',

    value: 'CabinCrew',
  },

  {
    name: 'CeoChairman',

    value: 'CeoChairman',
  },

  {
    name: 'Chef',

    value: 'Chef',
  },

  {
    name: 'Clergy',

    value: 'Clergy',
  },

  {
    name: 'ContractorConsultant',

    value: 'ContractorConsultant',
  },

  {
    name: 'DefenceWorker',

    value: 'DefenceWorker',
  },

  {
    name: 'Diplomat',

    value: 'Diplomat',
  },

  {
    name: 'Driver',

    value: 'Driver',
  },

  {
    name: 'Engineer',

    value: 'Engineer',
  },

  {
    name: 'FactoryWorker',

    value: 'FactoryWorker',
  },

  {
    name: 'GovernmentOfficial',

    value: 'GovernmentOfficial',
  },

  {
    name: 'HousePerson',

    value: 'HousePerson',
  },

  {
    name: 'Journalist',

    value: 'Journalist',
  },

  {
    name: 'Labourer',

    value: 'Labourer',
  },

  {
    name: 'LegalProfessional',

    value: 'LegalProfessional',
  },

  {
    name: 'Manager',

    value: 'Manager',
  },

  {
    name: 'ManagingDirector',

    value: 'ManagingDirector',
  },

  {
    name: 'MusicianActor',

    value: 'MusicianActor',
  },

  {
    name: 'Nurse',

    value: 'Nurse',
  },

  {
    name: 'OfficeWorker',

    value: 'OfficeWorker',
  },

  {
    name: 'Pensioner',

    value: 'Pensioner',
  },

  {
    name: 'Pilot',

    value: 'Pilot',
  },

  {
    name: 'PoliceLawEnforcement',

    value: 'PoliceLawEnforcement',
  },

  {
    name: 'Professional',

    value: 'Professional',
  },

  {
    name: 'Sales',

    value: 'Sales',
  },

  {
    name: 'Scientist',

    value: 'Scientist',
  },

  {
    name: 'SelfEmployed',

    value: 'SelfEmployed',
  },

  {
    name: 'SelfFundedRetiree',

    value: 'SelfFundedRetiree',
  },

  {
    name: 'ServiceWorker',

    value: 'ServiceWorker',
  },

  {
    name: 'SystemsAnalystProgrammer',

    value: 'SystemsAnalystProgrammer',
  },

  {
    name: 'TeacherEducator',

    value: 'TeacherEducator',
  },

  {
    name: 'TeamLeaderSupervisor',

    value: 'TeamLeaderSupervisor',
  },

  {
    name: 'Other',

    value: 'Other',
  },
];

export const ASSET_TYPES = [
  {
    name: 'Car',
    value: CAR_ASSET,
    tooltipMessage: 'New/used cars or refinancing on existing loan.',
  },
  { name: 'Boat', value: 'Boat' },
  { name: 'Caravan', value: 'Caravan' },
  { name: 'Motorbike', value: 'Bike' },
  {
    name: 'Commercial Finance',
    value: COMMERCIAL_EQUIPMENT,
    tooltipMessage: 'Trucks, trailers or any commercial equipment',
  },
  {
    name: 'Personal Loan', value: UNSECURED_LOAN,
  },
];

export const RESET_ALL_DATA_APPLICANTS = 'RESET_ALL_DATA_APPLICANTS';
export const RESET_ALL_DATA_ESTIMATION = 'RESET_ALL_DATA_ESTIMATION';

export const ROUTES_ASSET_TYPES = [
  ROUTES.initialDetails,
  ROUTES.commercialAssetType,
  ROUTES.boat,
  ROUTES.caravan,
  ROUTES.bike,
  ROUTES.unsecuredloan,
];

export const documentFieldMap = {
  driverLicenseFront: 'Drivers Licence (Front)',
  driverLicenseBack: 'Drivers Licence (Back)',
  passport: 'Passport',
  internationalDriverLicense: 'International Drivers Licence',
  payslip1: 'Payslip1',
  payslip2: 'Payslip2',
  taxReturn: 'Tax Return',
  other: 'Other',
  noticeOfAssessment: 'Notice Of Assessment',
  payslip: 'Payslip',
  trustDeed: 'Trust deed',
};

export const uploadedVerifiedStatus = ['Uploaded', 'Broker Verified', 'Sent to Lender'];

export const LABELS = {
  Industry: 'Industry',
  Occupation: 'Occupation',
};

export const BASIC_AUTO_COMPLETE_SUGGESTIONS_COUNT = 5;
export const LENDER_PEPPER_TYPE = 'Pepper';
export const MIN_DATE_ERROR_MESSAGE =
  'Passport expiry date should be later than today and should not be before today';

export const TITTLE_APPLICATON_TEXT = 'Application Submission Confirmation';
export const APPLICATION_DESC_TEXT =
  'There is just one more step left to do. You can choose to submit your application directly now or have one of our friendly brokers call you back.';
export const HIGHLIGHTED_DESC_TEXT = 'Well done! Your Application is nearly complete,';
export const APPLICATION_FOOTER_DESC_TEXT =
  'Please select your preferred option below for application submission';
export const DIRECT_APPLICATION_TEXT =
  'Clicking submit will prepare your application for approval with the Selected Lender. During the Credit Assessment process the lender will check your credit file and put an enquiry on your file.';
export const DIRECT_APPLICATION_TEXT_SECOND =
  'Submitting multiple applications for approval with different lenders may negatively impact your credit file. For more information you can speak to a finance expert on ';
export const CALLBACK_APPLICATION_TEXT =
  'Clicking submit will connect you with one of our finance experts. They will be in contact within the next business day to help you complete your finance application and any questions you might have. For more information you can speak to a finance expert on ';
export const COMPLETE_DOCUMENT_TEXT =
  'Your application is being reviewed for Approval. If we require more information/documents a finance expert will be in touch.';
export const FINANCE_COMPLETE_TEXT = 'One of our expert staff will be in contact with you shortly';
export const GET_APPROVED_TEXT = `Let's get you approved!`;
export const HELP_LINE_NUMBER = '1300 416 707.';

export const DocumentTypeMapping = [
  {
    documentType: 'Loan Statement',
    PQ_Category: 'Asset',
    PQ_Header_Text: 'Upload Loan Statement',
    PQ_Field_Text: 'Loan Statement',
  },
  {
    documentType: 'Bank Deposit Slip',
    PQ_Category: 'Asset',
    PQ_Header_Text: 'Upload Bank Deposit Slip',
    PQ_Field_Text: 'Bank Deposit Slip',
  },
  {
    documentType: 'Payout Confirmation Trade In',
    PQ_Category: 'Asset',
    PQ_Header_Text: 'Upload Payout Confirmation Trade in',
    PQ_Field_Text: 'Payout Confirmation Trade In',
  },
  {
    documentType: 'Certificate Of Currency',
    PQ_Category: 'Asset',
    PQ_Header_Text: 'Upload Certificate of Currency',
    PQ_Field_Text: 'Certificate of Currency',
  },
  {
    documentType: 'Payout Confirmation Financed Asset',
    PQ_Category: 'Asset',
    PQ_Header_Text: 'Upload Payout Confirmation Financed Asset',
    PQ_Field_Text: 'Payout Confirmation Financed Asset',
  },
  {
    documentType: 'Roadworthy Certificate',
    PQ_Category: 'Asset',
    PQ_Header_Text: 'Upload Roadworthy Certificate',
    PQ_Field_Text: 'Roadworthy Certificate',
  },
  {
    documentType: 'Vehicle Registration',
    PQ_Category: 'Asset',
    PQ_Header_Text: 'Upload Vehicle Registration',
    PQ_Field_Text: 'Vehicle Registration',
  },
  {
    documentType: 'Payout Letter Financed Asset',
    PQ_Category: 'Asset',
    PQ_Header_Text: 'Upload Payout letter financed asset',
    PQ_Field_Text: 'Payout Letter Financed Asset',
  },
  {
    documentType: 'Payout Letter Trade In',
    PQ_Category: 'Asset',
    PQ_Header_Text: 'Upload Payout letter trade-in',
    PQ_Field_Text: 'Payout Letter Trade In',
  },
  {
    documentType: 'Benefit Statement',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload Benefit Statement',
    PQ_Field_Text: 'Benefit Statement',
  },
  {
    documentType: 'Superannuation Statement',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload Superannuation Statement',
    PQ_Field_Text: 'Superannuation Statement',
  },
  {
    documentType: 'Commitment Schedule',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload Commitment Schedule',
    PQ_Field_Text: 'Commitment Schedule',
  },
  {
    documentType: 'Government Income Statement',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload Government Income Statement',
    PQ_Field_Text: 'Government Income Statement',
  },
  {
    documentType: 'Rental Statement',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload Rental Statement',
    PQ_Field_Text: 'Rental Statement',
  },
  {
    documentType: 'Tax Return',
    PQ_Category: 'Income',
    PQ_Main_Header_Text: 'Upload income documents for:',
    PQ_Header_Text: 'Upload Tax Return',
    PQ_Field_Text: 'Tax Return',
    order: 3,
  },
  {
    documentType: 'ATO Portals',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload ATO Portals',
    PQ_Field_Text: 'ATO Portals',
  },
  {
    documentType: 'Quarterly BAS Statement',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload Quarterly BAS Statement',
    PQ_Field_Text: 'Quarterly BAS Statement',
  },
  {
    documentType: 'Balance Sheet',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload Balance Sheet',
    PQ_Field_Text: 'Balance Sheet',
  },
  {
    documentType: 'Profit And Loss Statement',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload Profit & Loss Statement',
    PQ_Field_Text: 'Profit & Loss Statement',
  },
  {
    documentType: 'Notice Of Assessment',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload Notice of Assessment',
    PQ_Field_Text: 'Notice of Assessment',
    order: 4,
  },
  {
    documentType: 'Bank Statement',
    PQ_Category: 'Income',
    PQ_Header_Text: 'Upload Bank Statement',
    PQ_Field_Text: 'Bank Statement',
  },
  {
    documentType: 'Visa Document',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Visa Document',
    PQ_Field_Text: 'Visa Document',
  },
  {
    documentType: 'Professional Certificate',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Professional Certificate',
    PQ_Field_Text: 'Professional Certificate',
  },
  {
    documentType: 'Employment Contract',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Employment Contract',
    PQ_Field_Text: 'Employment Contract',
  },
  {
    documentType: 'Rental Agreement Or Contract',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Rental Agreement or Contract',
    PQ_Field_Text: 'Rental Agreement / Contract',
  },
  {
    documentType: 'Utility Bill',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Utility bill',
    PQ_Field_Text: 'Utility bill',
  },
  {
    documentType: 'Land Title Or Rates Notice',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Land Title/Rates Notice',
    PQ_Field_Text: 'Land Title/Rates Notice',
  },
  {
    documentType: 'Drivers Licence (Back)',
    PQ_Category: 'Identity',
    PQ_Header_Text: '',
    PQ_Field_Text: 'Back of Drivers License',
    order: 2,
  },
  {
    documentType: 'Drivers Licence (Front)',
    PQ_Category: 'Identity',
    PQ_Main_Header_Text: 'Upload Drivers License',
    PQ_Header_Text: '',
    PQ_Field_Text: 'Front of Drivers License',
    order: 1,
  },
  {
    documentType: 'Certified Drivers Licence',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Certified Drivers Licence',
    PQ_Field_Text: 'Certified Drivers Licence',
  },
  {
    documentType: 'Medicare Card',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Medicare Card',
    PQ_Field_Text: 'Medicare Card',
  },
  {
    documentType: 'Trust deed',
    PQ_Category: 'Trust',
    PQ_Header_Text: 'Upload Trust Deed',
    PQ_Field_Text: 'Trust deed',
  },
  {
    documentType: 'Passport',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Passport',
    PQ_Field_Text: 'Passport',
    order: 3,
  },
  {
    documentType: 'International Drivers Licence',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload International Drivers License',
    PQ_Field_Text: 'International Drivers Licence',
    order: 4,
  },
  {
    documentType: 'Payslip1',
    PQ_Category: 'Income',
    PQ_Main_Header_Text: 'Upload 2 of you most recent payslips for:',
    PQ_Header_Text: '',
    PQ_Field_Text: 'Payslip 1',
    order: 1,
  },
  {
    documentType: 'Payslip2',
    PQ_Category: 'Income',
    PQ_Header_Text: '',
    PQ_Field_Text: 'Payslip 2',
    order: 2,
  },
  {
    documentType: 'Payslip3',
    PQ_Category: 'Income',
    PQ_Header_Text: '',
    PQ_Field_Text: 'Payslip 3',
    order: 3,
  },
  {
    documentType: 'Payslip4',
    PQ_Category: 'Income',
    PQ_Header_Text: '',
    PQ_Field_Text: 'Payslip 4',
    order: 4,
  },
  {
    documentType: 'Payslip5',
    PQ_Category: 'Income',
    PQ_Header_Text: '',
    PQ_Field_Text: 'Payslip 5',
    order: 5,
  },
  {
    documentType: 'Payslip6',
    PQ_Category: 'Income',
    PQ_Header_Text: '',
    PQ_Field_Text: 'Payslip 6',
    order: 6,
  },
  {
    documentType: 'Proof of Address',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Proof of address',
    PQ_Field_Text: 'Proof of address',
  },
  {
    documentType: 'References',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload References',
    PQ_Field_Text: 'References',
  },
  {
    documentType: 'Marriage Certificate',
    PQ_Category: 'Identity',
    PQ_Header_Text: 'Upload Marriage certificate',
    PQ_Field_Text: 'Marriage certificate',
  },
];

export const documentTypeValue = [
  'Payslip',
  'Bank Statement',
  'Notice Of Assessment',
  'Profit And Loss Statement',
  'Balance Sheet',
  'Quarterly BAS Statement',
  'ATO Portals',
  'Tax Return',
  'Rental Statement',
  'Government Income Statement',
  'Commitment Schedule',
  'Superannuation Statement',
  'Benefit Statement',
];

export const assetMappingSF = (assetsType) => {
  switch (assetsType.toLowerCase()) {
    case 'car':
      return 'Car';
    case 'agriculturalequipment':
      return 'Commercial Equipment';
    case 'aircraft':
      return 'Commercial Equipment';
    case 'bloodstock':
      return 'Commercial Equipment';
    case 'campertrailer':
      return 'Caravan';
    case 'caravan':
      return 'Caravan';
    case 'cashflowfunding':
      return 'Commercial Equipment';
    case 'commercialfacility':
      return 'Commercial Equipment';
    case 'debtorfinance':
      return 'Commercial Equipment';
    case 'debtconsolidation':
      return 'Commercial Equipment';
    case 'depositbond':
      return 'Commercial Equipment';
    case 'fineart':
      return 'Commercial Equipment';
    case 'heavyvehicles':
      return 'Commercial Equipment';
    case 'industrialequipment':
      return 'Commercial Equipment';
    case 'informationtechnology':
      return 'Commercial Equipment';
    case 'insurancepremiumFunding':
      return 'Commercial Equipment';
    case 'lifestylevehicle':
      return 'Boat';
    case 'marineboat':
      return 'Boat';
    case 'marineother':
      return 'Boat';
    case 'medical':
      return 'Commercial Equipment';
    case 'motorbike':
      return 'Bike';
    case 'motorhomerv':
      return 'Caravan';
    case 'officeequipment':
      return 'Commercial Equipment';
    case 'officefitout':
      return 'Commercial Equipment';
    case 'otherequipment':
      return 'Commercial Equipment';
    case 'overdraft':
      return 'Commercial Equipment';
    case 'plantandmachinery':
      return 'Commercial Equipment';
    case 'propertycommercial':
      return 'Commercial Equipment';
    case 'propertydevelopment':
      return 'Commercial Equipment';
    case 'propertyland':
      return 'Commercial Equipment';
    case 'propertyresidential':
      return 'Commercial Equipment';
    case 'software':
      return 'Commercial Equipment';
    case 'solarandgreen':
      return 'Commercial Equipment';
    case 'trailer':
      return 'Commercial Equipment';
    case 'yellowgoods':
      return 'Commercial Equipment';
    case 'marine':
      return 'Boat';
    case 'unsecured':
      return 'Unsecured Loan';
    case 'other':
      return 'Commercial Equipment';
    case 'gymequipment':
      return 'Commercial Equipment';
    case 'boat':
      return 'Boat';
    case 'bike':
      return 'Bike';
    case 'caravan':
      return 'Caravan';
    case 'commercialequipment':
      return 'Commercial Equipment';
    default:
      return '';
  }
};
