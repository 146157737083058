import moment from 'moment';

const requiredRuleEvaluator = (rule, value) => {
  if (value == null || value === '') {
    return rule.errorMessage;
  }

  if (moment.isMoment(value)) {
    if (!value.isValid()) {
      return rule.errorMessage;
    }

    return null;
  }

  return null;
};

export default requiredRuleEvaluator;
