import React from 'react';
import './privacy-consent.scss';

const PrivacyConsent = () => (
  <fieldset className="fieldset-section">
    <div className="privacy-consent">
      <div className="privacy-collection-header">Privacy Collection Notice and Consent Form</div>

      <div className="privacy-collection">
        Collection of personal information including Credit Related Personal Information
      </div>
      <p className="privacy-content">
        The information you have or may provide during this process in connection with your
        application is being collected by Stratton Finance Pty Ltd (ACN 070 636 903/Australian
        Credit Licence no 364340) or one of its related bodies corporate or franchisees{' '}
        <b>(Stratton, we, our, us)</b>.
      </p>
      <p className="privacy-content">
        Your information is being collected in connection with your inquiry and with the finance and
        insurance products and services that may be provided by Stratton's financiers{' '}
        <b>(Financiers)</b>. We may collect your first name, last name, mobile number, address,
        email address, date of birth, drivers licence and address as evidence of your identity,
        Australian residency status, relationship status, employment details, credit history and
        financial details like income, assets, household liabilities and assets.
      </p>
      <p className="privacy-content privacy-nextsection">
        We otherwise collect, use, disclose, hold and handle your personal information and
        credit-related information&nbsp;
        <b>(Credit Related Personal Information)</b>
        &nbsp;in accordance with our Privacy & Credit Reporting Policy (available on our website at
        <a
          className="btn-policy-path"
          href="https://www.strattonfinance.com.au/privacy-policy"
          target="_blank"
        >
          &nbsp;www.strattonfinance.com.au/privacy-policy
        </a>
        )&nbsp;
        <b>(Privacy & Credit Reporting Policy).</b>&nbsp; Our Privacy Policy also contains
        information regarding how to opt-out of marketing communications from us, the contact
        details of our Compliance Officer, information about Stratton Financiers, and how you can
        seek access to and correct your personal information or raise a privacy concern with us.
      </p>
      <div className="privacy-collection">
        Why do we collect your personal information including Credit Related Personal Information?
      </div>
      <p className="privacy-content">
        The Credit Related Personal Information and other personal information we collect about you
        is primarily used to assess your credit situation and application. We will use your
        information for that purpose, for the purposes to which you have consented on this form (see
        below), and we may also use it in the manner described in our Privacy & Credit Policy.
      </p>
      <p className="privacy-content privacy-nextsection">
        From time-to-time we also use the personal information you have provided to send you
        carefully selected materials which we think might be of interest to you such as our
        newsletter, information about our products and services, reminders about your inquiry and
        online dealings with us and special deals and promotions offered by us. Of course you can
        opt-out of receiving these messages at any time by unsubscribing or following the opt-out
        process set out in our [
        <a
          className="btn-policy-path"
          href="https://www.strattonfinance.com.au/privacy-policy"
          target="_blank"
        >
          Privacy & Credit Reporting Policy
        </a>
        ] You also have the right to request credit related bodies not to use your credit reporting
        information for the purposes of pre-screening or direct marketing by us.
      </p>
      <div className="privacy-collection">
        What happens if your personal information including Credit Related Personal Information is
        not collected?
      </div>
      <p className="privacy-content privacy-nextsection">
        If you do not provide all of the information requested by us on this form or otherwise as
        part of the application process, we may not be able to process your application or assess or
        approve your application for credit.
      </p>
      <div className="privacy-collection">Where to find more details?</div>
      <p className="privacy-content">
        Our [{' '}
        <a
          className="btn-policy-path"
          href="https://www.strattonfinance.com.au/privacy-policy"
          target="_blank"
        >
          Privacy & Credit Reporting Policy
        </a>
        ] contains more detailed information about credit reporting and how we handle your Credit
        Related Personal Information and other personal information, including:
      </p>
      <p className="privacy-content">
        § the types of personal information we seek from and disclose to third parties (including
        information about you that we obtain from credit reporting bodies and credit providers), the
        purposes for which we collect such information, and how we hold, use and disclose that
        information;
      </p>
      <p className="privacy-content">
        § the credit reporting bodies to which we may disclose your Credit Related Personal
        Information in order to obtain information about your credit worthiness, how you may obtain
        information about their management of your Credit Related Personal Information, the fact
        that they may include information we provide them in reports obtained and used by credit
        providers to assess individuals’ credit worthiness, and requests you are entitled to make of
        such credit providers in relation to their use of your information; and
      </p>
      <p className="privacy-content">
        {' '}
        § your rights to seek access to and correction of Credit Related Personal Information and
        other personal information, make complaints about how we manage your Credit Related Personal
        Information and other personal information, and how we will deal with such requests and
        complaints.
      </p>
      <p className="privacy-content privacy-nextsection">
        This Privacy Collection Notice and Consent Form is designed to be read in conjunction with
        our{' '}
        <a
          className="btn-policy-path"
          href="https://www.strattonfinance.com.au/privacy-policy"
          target="_blank"
        >
          Privacy & Credit Reporting Policy
        </a>
        . We recommend that you take the time to review it. By accepting this form, you are also
        agreeing to the terms of our{' '}
        <a
          className="btn-policy-path"
          href="https://www.strattonfinance.com.au/privacy-policy"
          target="_blank"
        >
          Privacy & Credit Reporting Policy
        </a>
        .
      </p>
      <div className="general-consent">General Consents and Acknowledgements</div>
      <p className="privacy-content">
        In connection with the provision of credit to the credit applicant named above (Credit
        Applicant), each person who signs above (you, their or them):
      </p>
      <ol className="privacy-content">
        <li className="privacy-content">
          understands and consents to Stratton disclosing their personal information and
          Credit-Related Personal Information to Financiers and third-party service providers (as
          applicable) who will treat such information in accordance with their own privacy policies;
        </li>
        <li className="privacy-content">
          acknowledges that, Stratton, its Financiers, and/or third-party service providers, may, in
          respect of their personal information or Credit-Related Personal Information, hold that
          information in, or disclose that information to third parties (including their respective
          related parties) located in, the overseas countries listed Stratton's{' '}
          <a
            className="btn-policy-path"
            href="https://www.strattonfinance.com.au/privacy-policy"
            target="_blank"
          >
            Privacy & Credit Reporting Policy
          </a>
          &nbsp;or the privacy policies of the Financiers or third-party service providers
        </li>

        <li className="privacy-content">
          consents to Stratton and/or its Financiers obtaining a credit report from a credit
          reporting body or collecting Credit-Related Personal Information from other credit
          providers, for the purpose of assessing the credit worthiness of the Credit Applicant/s
          (or their capacity to guarantee any credit provided to the Credit Applicant/s) and for
          collecting (or engaging any third party to collect) any overdue payments in connection
          with such credit and consents to any credit reporting body or credit provider providing
          this information;
        </li>
        <li className="privacy-content">
          consents to the information in the credit application made by the Credit Applicant
          (together with any other credit-related information held by Stratton) being disclosed to a
          credit reporting body and other credit providers for the purpose of retrieving the
          information
        </li>
        <li className="privacy-content">
          where they are the Credit Applicant, consents to the disclosure by Stratton and/or its
          Financiers of Credit-Related Personal Information relating to them to any persons signing
          above who are nominated as guarantors of the credit provided to them;
        </li>
        <li className="privacy-content">
          authorises Stratton and/or its Financiers to make any additional periodical credit checks
          that Stratton and/or its Financiers thinks fit with regard to the continuing capacity of
          the Credit Applicant to repay the credit provided to it;
        </li>
        <li className="privacy-content">
          acknowledges that when communicating with Stratton representatives over the phone, calls
          may be recorded by Stratton to improve our service, for training and monitoring, and for
          quality assurance purposes. If you would prefer any of your calls not to be recorded,
          please simply advise us at the beginning of the call, or at the point which you would
          prefer not to be recorded;
        </li>
        <li className="privacy-content">
          agrees that the consents, permissions and authorisations given by them continue in force
          at all times while the Credit Applicant's application is being considered by Stratton
          and/or its Financiers and (if the application is accepted) continues for the period for
          which any credit is being provided to the Credit Applicant; and
        </li>
        <li className="privacy-content">
          gives these acknowledgements, consents and agreements for the benefit of Stratton, its
          third-party service providers, its Financiers and any credit reporting body and other
          credit provider that Stratton and/or its Financiers disclose personal or Credit-Related
          Personal Information to.
        </li>
      </ol>
    </div>
  </fieldset>
);

export default PrivacyConsent;
