/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import NumberFormat from 'react-number-format';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import cls from 'classnames';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Tooltip from './Tooltip';
import { FREQ_OPTIONS } from '../util/constants';
import { handleHTTPError, noErrors, validationErrors } from '../Actions/errorHandler';
import fieldChanged from '../Actions/FieldChanged';
import './editloanamount.scss';
import { getValidationErrorsForFieldFunc } from '../util/ContainerUtilities';
import AmountRangeSelector from './AmountRangeSelector';
import { createQuote, reQuote, loanDetails } from '../Actions/car';
import * as log from '../util/log';
const MINIMUM_LOAN_AMOUNT = 5000; // use for minimum load amount value in future regrenece to update only one place

const useStyles = makeStyles(() =>
  createStyles({
    amountRangeRoot: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    sliderContainer: {
      marginLeft: 0,
      marginRight: 0,
      width: '100%',
    },
    sliderThumb: {
      marginLeft: '-4px',
    },
    manualCarSearchContainer: {},
    manualCarQueryContainer: {},
  }),
);

const EditLoanAmount = (props) => {
  const {
    onUpdateChange,
    onInputChange,
    getValidationErrorsForField,
    purchasePrice,
    residualSwitch,
    termVal,
    depositAmount,
    termValUpdated,
    purchasePriceUpdated,
    depositUpdated,
    minRepaymentVal,
    maxRepaymentVal,
    minInterestVal,
    maxInterestVal,
    createQuote,
    loanOptions,
    validationCheck,
    quoteInProgress,
    selectFreq,
    loanDetails,
    reQuote,
    opportunityId,
    liteFieldChanged,
    purchaseUse,
    // UpdateQuoteDetails,
    assetType,
  } = props;
  const classes = useStyles({});

  const [show, setShow] = useState(true);
  const [validationError, showError] = useState('');
  const maxTermVal = 7;
  const loanAmount = purchasePriceUpdated - depositUpdated;

  useEffect(() => {
    onInputChange('depositUpdated', depositAmount, false);
    onInputChange('purchasePriceUpdated', purchasePrice, false);
    onInputChange('termValUpdated', termVal, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setShow(false);
    onUpdateChange('editLoanAmt', false, false);
    onUpdateChange('updateLoanAmt', false, false);
    onUpdateChange('depositUpdated', null, false);
    onUpdateChange('purchasePriceUpdated', null, false);
    onInputChange('termValUpdated', null, false);
  };
  const handleSelect = (selectedObject) => {
    onInputChange('selectFreq', selectedObject.value, false);

    createQuote()
      .then(() => {
        // onInputChange('selectFreq', selectedObject.value, false);
      })
      .catch((error) => {
        log.error('EditLoanAmount.createQuote', error);
        handleHTTPError(error);
      });
  };
  const handleSubmit = () => {
    if (purchasePriceUpdated - depositUpdated < MINIMUM_LOAN_AMOUNT) {
      showError(`Loan Amount cannot be less than $${MINIMUM_LOAN_AMOUNT}.`);
      return;
    }

    onInputChange('termVal', termValUpdated, false);
    onUpdateChange('purchasePrice', purchasePriceUpdated, false);
    onUpdateChange('depositAmount', depositUpdated, false);
    onUpdateChange('purchasePriceComplete', true, false);
    onUpdateChange('depositPriceComplete', true, false);

    if (opportunityId) {
      loanDetails()
        .then(() => {
          reQuote()
            .then(() => {
              handleClose();
            })
            .catch((e) => {
              log.error('EditLoanAmount.handleSubmit', e);
              handleClose();
            });
        })
        .catch((e) => {
          log.error('EditLoanAmount.loanDetails', e);
          handleClose();
          showError('Something went wrong, please try again soon.');
        });
    } else {
      createQuote()
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          log.error('EditLoanAmount.createQuote.else', error);
          showError('Something went wrong, please try again soon.');
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  };
  const handleResidualChange = (e) => {
    onInputChange('residualSwitch', e.target.checked, false);
  };
  return (
    <Modal
      show={show}
      size="lg"
      /* onHide={handleClose} */
      className="modal-wrapper login-modal-wrapper edit-loan-amount-container"
    >
      <div className="editloan-header-container">
        <div className="">
          <div className="editloan-header-section">Update your loan amount</div>
        </div>
        <button
          type="button"
          data-test="edit-loanamt-close"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <Modal.Body className="p-0 editloan-body">
        <Row className="edit-price mx-0">
          <AmountRangeSelector
            label={
              <>
                <span className="label">Purchase price</span>
              </>
            }
            onChange={onInputChange}
            fieldName="purchasePriceUpdated"
            defaultValue={purchasePrice}
            getvalidationerrors={getValidationErrorsForField}
            minValue={MINIMUM_LOAN_AMOUNT}
            maxValue={250000}
            step={1000}
            formGroupClass="mx-0 purchase-price-slider"
          />

          <AmountRangeSelector
            label={
              <>
                <span className="label">Deposit/Trade-in</span>
              </>
            }
            onChange={onInputChange}
            fieldName="depositUpdated"
            defaultValue={depositAmount}
            getvalidationerrors={getValidationErrorsForField}
            minValue={0}
            maxValue={purchasePriceUpdated - MINIMUM_LOAN_AMOUNT}
            // oncreateQuote={UpdateQuoteDetails}
            step={1000}
            formGroupClass="mx-0 deposit-price-slider"
          />
        </Row>
        <Row className="edit-term mx-0">
          <AmountRangeSelector
            label={
              <>
                <span className="label">Loan term</span>
              </>
            }
            formGroupClass="estimation-range-selector"
            onChange={onInputChange}
            fieldName="termValUpdated"
            defaultValue={termVal}
            minValue={1}
            maxValue={maxTermVal}
            step={1}
            getvalidationerrors={getValidationErrorsForField}
            termSlider
          />

          <div className="edit-loan-amount">
            <NumberFormat
              displayType="text"
              className="edit-loan-amount-height"
              decimalScale={2}
              value={
                depositUpdated === 0
                  ? purchasePriceUpdated
                  : purchasePriceUpdated
                  ? purchasePriceUpdated - depositUpdated
                  : purchasePrice - depositAmount
              }
              thousandSeparator
              prefix="$"
            />
            <div className="loan-amt-label ">Loan amount</div>
          </div>
        </Row>
        <Row className="residual-section3">
          {assetType == 'Car' && (
            <Col className="custom-control custom-switch residual-switch">
              <label htmlFor="residualSwitch" className="residual-switch-label">
                Include residual?
                <div className="ml-2">
                  <Tooltip
                    label="What's this?"
                    toolTipCommon
                    message="Adding a residual (or balloon) will reduce your repayments, and at the end of your loan you will pay the residual amount as a lump sum. You may have the option to refinance the residual payment."
                  />
                </div>
              </label>
              <input
                type="checkbox"
                className="custom-control-input"
                checked={residualSwitch}
                value={residualSwitch}
                onChange={(e) => handleResidualChange(e)}
                id="residualSwitch"
              />
              <label
                className={cls('custom-control-label', residualSwitch && 'checked')}
                htmlFor="residualSwitch"
              />
            </Col>
          )}

          {/* <div className="repayment-freq ">
            <div className="repayment-freq-label">Select your repayments</div>
            {FREQ_OPTIONS.map((radioField) => (
              <ButtonGroup className={cls(' mb-2', 'btn-group-freg')} key={radioField.name}>
                <Button
                  onClick={() => handleSelect(radioField)}
                  className={cls('btn-freq', selectFreq === radioField.value && 'is-selected')}
                >
                  <span className="freq-name">{radioField.name}</span>
                </Button>
              </ButtonGroup>
            ))}
          </div> */}
        </Row>
        {/* <UpdateLoanAmount {...props} handleResidualChange={handleResidualChange} /> */}

        {validationError && (
          <Alert variant="danger" className="error-alert mx-2">
            <p className="error-message">{validationError}</p>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer className="editloan-footer">
        <Button type="button" className="footer-close" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          className="footer-update"
          onClick={handleSubmit}
          disabled={quoteInProgress || loanAmount < MINIMUM_LOAN_AMOUNT}
        >
          {quoteInProgress ? (
            <CircularProgress color="inherit" size={24} thickness={2.6} />
          ) : (
            <>Update</>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default connect(
  (state) => ({
    purchasePrice: state.car.purchasePrice,
    depositAmount: state.car.depositAmount,
    selectFreq: state.estimation.selectFreq,
    termVal: state.estimation.termVal,
    depositUpdated: state.estimation.depositUpdated,
    purchasePriceUpdated: state.estimation.purchasePriceUpdated,
    termValUpdated: state.estimation.termValUpdated,
    validationErrors: state.car.validationErrors,
    minRepaymentVal: state.estimation.minRepaymentVal,
    maxRepaymentVal: state.estimation.maxRepaymentVal,
    minInterestVal: state.estimation.minInterestVal,
    maxInterestVal: state.estimation.maxInterestVal,
    residualSwitch: state.estimation.residualSwitch,
    loanOptions: state.estimation.loanOptions,
    quoteInProgress: state.account.quoteInProgress,
    errorMessage: state.errorHandler.errorMessage,
    purchaseUse: state.car.purchaseUse,
    ValidationErrorFlag: state.errorHandler.ValidationErrorFlag,
    opportunityId: state.applicants.opportunityId,
    getValidationErrorsForField: getValidationErrorsForFieldFunc(state.car.validationErrors),
    assetType: state.car.assetType,
  }),
  (dispatch) => ({
    onUpdateChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('car', fieldName, value, scrollToNextFieldOnChage));
    },
    validationCheck: (error) => dispatch(validationErrors(error)),
    onInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('estimation', fieldName, value, scrollToNextFieldOnChage));
    },
    liteFieldChanged: (fieldName, value, scrollToNextFieldOnChage) => {
      dispatch(fieldChanged('account', fieldName, value, scrollToNextFieldOnChage));
    },
    // UpdateQuoteDetails: () => dispatch(UpdateQuoteDetails()),
    loanDetails: () => dispatch(loanDetails()),
    createQuote: () => dispatch(createQuote()),
    reQuote: () => dispatch(reQuote()),
  }),
)(EditLoanAmount);
