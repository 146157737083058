/* eslint-disable new-cap */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Tooltip from '../../components/Tooltip';
import './PersonalDetails.scss';
import './EmploymentDetails.scss';
import InputButton from '../../components/InputButton';
import DatePickerWrapper from '../../components/DatePickerWrapper';
import TextField from '../../components/TextField';
import {
  ROUTES,
  EMPLOYMENT_STATUS_PERSONAL,
  EMPLOYMENT_STATUS_ADDITIONAL,
  EMPSTATUS_FULL,
  EMPSTATUS_PART,
  EMPSTATUS_CASUAL,
  EMPTYPE_SELF,
  EMPLOYMENT_STATUS_BUSINESS,
  EMPLOYMENT_STATUS_SKIP_SELFEMP,
  formattedCurrentDate,
  TRUSTEE_TYPES,
  MAIN_EMPLOYMENT_SELECT,
  EMPSTATUS_RETIRED,
  EMPSTATUS_UNEMPLOYED,
} from '../../util/constants';
import rightnormal from '../../../assets/image/right-normal.svg';
import deleteIcon from '../../../assets/image/delete.svg';
import processGTMevent from '../../util/processGTMEvent';
import PreviousEmploymentForm from '../Finances/Income/PreviousEmployment';
import ABNlookUpDetails from '../Finances/Income/ABNlookUpDetails';
import { isLessThanTwo } from '../../lib/utils';

const EmploymentDetails = (props) => {
  const {
    employmentStatus,
    employmentStartDate,
    prevEmpType,
    prevEmpStartDt,
    prevEmpEndDt,
    isAbnknown,
    currentTab,
    setTab,
    employeeDuration = false,
    purchaseUse,
    lastFYNetProfit,
    getValidationErrorsForField,
    onInputChange,
    employmentInsUpd,
    abnNumber,
    abnNumberPrevious,
    entityName,
    entityNamePrevious,
    abnStatus,
    abnStatusPrevious,
    entityType,
    entityTypePrevious,
    gstRegistrationstatus,
    gstRegistrationstatusPrevious,
    validationErrors,
    validationCheck,
    noErrors,
    setTabComplete,
    reQuote,
    income,
    validateDateField,
    behalfOfCompany,
    trusteeType,
    quoteInProgress,
    onLiteInputChange,
    deleteCurrEmploymentSecondary,
    deleteAllEmployments,
    serverError,
    hashPath,
    employerName,
    isSavedEmployerName,
    mainEmployment,
    employmentStatus2,
    employerName2,
    isSavedEmployerName2,
    employmentStartDate2,
    addEmployment,
    abnRegistrationDate,
    deleteEmployerIncome,
    employmentCreationInProgress,
    livingDetailsUpdate,
    retrieveOpportunity,
    opportunityId,
    dateOfBirth,
    assetType
  } = props;
  useEffect(() => {
    setTab();
  }, [currentTab, setTab]);
  const history = useHistory();
  const [imageFile, setimageFile] = useState([]);
  const [InvalidAbn, setInvalidAbn] = useState(false);
  const [InvalidAbnNumber, setInvalidAbnNumber] = useState(false);
  const [searchAbn, setSearchAbn] = useState(false);

  // console.log(
  //   `abnNumber,
  // abnNumberPrevious,
  // entityName,
  // entityNamePrevious,
  // abnStatus,
  // abnStatusPrevious,
  // entityType,
  // entityTypePrevious,
  // gstRegistrationstatus,
  // gstRegistrationstatusPrevious,`,
  //   abnNumber,
  //   abnNumberPrevious,
  //   entityName,
  //   entityNamePrevious,
  //   abnStatus,
  //   abnStatusPrevious,
  //   entityType,
  //   entityTypePrevious,
  //   gstRegistrationstatus,
  //   gstRegistrationstatusPrevious,
  // );

  const handleABN = (_fieldName, abnValues) => {
    Object.keys(abnValues).forEach((attrName) => {
      onInputChange(attrName, abnValues[attrName]);
    });
  };

  const handlePreviousABN = (_fieldName, abnValues) => {
    Object.keys(abnValues).forEach((attrName) => {
      onInputChange(`${attrName}Previous`, abnValues[attrName]);
    });
  };

  const handleEmploymentInsert = () => {
    onLiteInputChange('employmentCreationInProgress', true, false);
    onLiteInputChange('personaldetailsChanged', false, false);
    if (validationErrors && validationErrors.length) {
      validationCheck();
      return;
    }
    noErrors();
    // commented as it is deletig self employed income all the time
    // if (
    //   employmentStatus !== EMPSTATUS_FULL &&
    //   employmentStatus !== EMPSTATUS_PART &&
    //   employmentStatus !== EMPSTATUS_CASUAL
    // ) {
    //   deleteEmployerIncome(employerName, true);
    //   onLiteInputChange('employerName', '', false);
    // }
    // if (
    //   employmentStatus2 !== EMPSTATUS_FULL &&
    //   employmentStatus2 !== EMPSTATUS_PART &&
    //   employmentStatus2 !== EMPSTATUS_CASUAL
    // ) {
    //   deleteEmployerIncome(employerName2, true);
    //   onLiteInputChange('employerName2', '', false);
    // }
    // if (employmentStatus !== EMPTYPE_SELF && employmentStatus2 !== EMPTYPE_SELF) {
    //   deleteEmployerIncome(employerName, false);
    // }
    // deleteAllEmployments().then(() => {
    employmentInsUpd()
      .then(() => {
        reQuote();
        retrieveOpportunity();
        onLiteInputChange('employmentCreationInProgress', false, false);
        onLiteInputChange('empdetailscomplete', true, false);
        localStorage.setItem(`pageCompleted${opportunityId}`, 'employment-details');
        if (income && income.length) {
          setTabComplete('applicants');
          onLiteInputChange('personaldetailsChanged', false, false);
          history.push(ROUTES.applicants);
        } else {
          onLiteInputChange('personaldetailsChanged', false, false);
          history.push(ROUTES.initialFinanceDetails);
        }
        processGTMevent('applicants', 'inlineNext', 'employmentDetailsComplete');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        onLiteInputChange('employmentCreationInProgress', false, false);
      });
  };

  const nextDisabled =
    (validationErrors && validationErrors.length) ||
    InvalidAbn ||
    InvalidAbnNumber ||
    (purchaseUse === 'Business' && isAbnknown === true && searchAbn !== true) ||
    (purchaseUse === 'Business' &&
      isAbnknown === false &&
      (!employmentStatus || (employeeDuration !== true && employeeDuration !== false))) ||
    (purchaseUse === 'Business' &&
      isAbnknown === false &&
      (!employmentStartDate ||
        !employerName ||
        !employerName.match(/^[ a-zA-Z]+$/) ||
        (employeeDuration === true &&
          (!prevEmpType ||
            ((prevEmpType === EMPSTATUS_FULL ||
              prevEmpType === EMPSTATUS_PART ||
              prevEmpType === EMPSTATUS_CASUAL) &&
              (!prevEmpEndDt || !prevEmpStartDt)))))) ||
    (purchaseUse === 'Personal' &&
      (!employmentStatus ||
        (employmentStatus && employmentStatus === EMPTYPE_SELF && searchAbn !== true))) ||
    (purchaseUse === 'Personal' &&
      (employmentStatus === EMPSTATUS_FULL ||
        employmentStatus === EMPSTATUS_PART ||
        employmentStatus === EMPSTATUS_CASUAL) &&
      (!employmentStartDate ||
        !employerName ||
        !employerName.match(/^[ a-zA-Z]+$/) ||
        (employeeDuration === true &&
          !addEmployment &&
          (!mainEmployment || mainEmployment === 'No') &&
          (!prevEmpType ||
            ((prevEmpType === EMPSTATUS_FULL ||
              prevEmpType === EMPSTATUS_PART ||
              prevEmpType === EMPSTATUS_CASUAL) &&
              (!prevEmpEndDt || !prevEmpStartDt)))))) ||
    (purchaseUse === 'Business' &&
      entityType &&
      (entityType === 'Private Limited' || entityType === 'Public Company') &&
      !behalfOfCompany) ||
    (behalfOfCompany === 'Yes' && (lastFYNetProfit === null || lastFYNetProfit === '')) ||
    (purchaseUse === 'Business' && entityType && entityType === 'Trust' && !trusteeType) ||
    (purchaseUse === 'Personal' &&
      addEmployment &&
      (!employmentStatus2 ||
        !mainEmployment ||
        (mainEmployment === 'Yes' &&
          (employmentStatus2 === EMPSTATUS_FULL ||
            employmentStatus2 === EMPSTATUS_PART ||
            employmentStatus2 === EMPSTATUS_CASUAL) &&
          (!employmentStartDate2 ||
            !employerName2 ||
            !employerName2.match(/^[ a-zA-Z.]+$/) ||
            employerName === employerName2 ||
            (employeeDuration === true &&
              employmentStatus2 !== EMPTYPE_SELF &&
              (!prevEmpType ||
                ((prevEmpType === EMPSTATUS_FULL ||
                  prevEmpType === EMPSTATUS_PART ||
                  prevEmpType === EMPSTATUS_CASUAL) &&
                  (!prevEmpEndDt || !prevEmpStartDt)))))) ||
        (mainEmployment === 'No' &&
          (employmentStatus2 === EMPSTATUS_FULL ||
            employmentStatus2 === EMPSTATUS_PART ||
            employmentStatus2 === EMPSTATUS_CASUAL) &&
          (!employmentStartDate2 || !employerName2)) ||
        employerName === employerName2 ||
        (employmentStatus2 === EMPTYPE_SELF && searchAbn !== true)));

  const addEmploymentClick = () => {
    onInputChange('addEmployment', true, true);
  };

  const deleteEmploymentClick = () => {
    deleteCurrEmploymentSecondary().then(() => {
      onInputChange('addEmployment', false, true);
    });
  };

  return (
    <Card className={`content-body personal-details-container ${hashPath}`}>
      <Card.Header className="col-12 page-header-container personal-details-header-container scrollable-anchor-tag with-sidebar">
        <a name={hashPath} href={`#${hashPath}`}>
          &nbsp;
        </a>
        <h1 className="personal-details-header">Your employment details</h1>
        <div className="personal-details-description">
          We&apos;ll use this information to better match you with the right loan options
        </div>
      </Card.Header>
      <Card.Body className="pb-0 identity-details-body-container personal-body-container personal-body-section1 personal-body-section2">
        <Form noValidate className="col-12 identity-form-section py-2 px-1">
          <div className="col-12 identity-form-section px-0">
            {purchaseUse !== 'Business' && (
              <InputButton
                label={
                  <>
                    <span>How would you describe your current employment?</span>
                    <Tooltip message="Please be accurate with your employment details. If you are working casually (don't receive leave entitlements) but work full-time hours, please select “Casual” employment. If your only source of income is Government benefits or you are a self-funded retiree, please select Unemployed." />
                  </>
                }
                fields={
                  employmentStatus2 === EMPTYPE_SELF
                    ? EMPLOYMENT_STATUS_SKIP_SELFEMP
                    : EMPLOYMENT_STATUS_PERSONAL
                }
                onChange={onInputChange}
                fieldName="employmentStatus"
                value={employmentStatus}
                getvalidationerrors={getValidationErrorsForField}
                scrollToNextFieldOnChage
              />
            )}

            {purchaseUse === 'Business' && (
              <>
                <InputButton
                  label={
                    <>
                      <span>Are you an ABN holder?</span>
                    </>
                  }
                  fields={[
                    { name: 'Yes', value: true },
                    { name: 'No', value: false },
                  ]}
                  onChange={onInputChange}
                  fieldName="isAbnknown"
                  value={isAbnknown}
                  scrollToNextFieldOnChage
                  btnClass="abnKnown"
                  getvalidationerrors={getValidationErrorsForField}
                />
              </>
            )}
            {((isAbnknown && purchaseUse === 'Business') || employmentStatus === EMPTYPE_SELF) && (
              <ABNlookUpDetails
                fieldName="employmentPersonalPrimaryABN"
                abnRespDetails={handleABN}
                defaultAbnNumber={abnNumber}
                defaultEntityType={entityType}
                defaultEntityName={entityName}
                defaultAbnStatus={abnStatus}
                defaultGstRegistrationstatus={gstRegistrationstatus}
                handleInvalidAbn={setInvalidAbn}
                handleInvalidAbnSearch={setInvalidAbnNumber}
                handleSearchAbn={setSearchAbn}
              />
            )}

            {isAbnknown &&
              purchaseUse === 'Business' &&
              entityType &&
              (entityType === 'Private Limited' || entityType === 'Public Company') && (
                <>
                  <div className="form-row">
                    <>
                      <InputButton
                        label={
                          <>
                            <span>Are you applying on behalf a Company?</span>
                          </>
                        }
                        fields={[
                          { name: 'Yes', value: 'Yes' },
                          { name: 'No', value: 'No' },
                        ]}
                        onChange={onInputChange}
                        fieldName="behalfOfCompany"
                        value={behalfOfCompany}
                        scrollToNextFieldOnChage
                        getvalidationerrors={getValidationErrorsForField}
                      />
                    </>
                    {behalfOfCompany === 'Yes' && (
                      <TextField
                        fieldName="lastFYNetProfit"
                        value={lastFYNetProfit}
                        // required="*"
                        label="What was your Annual Net Profit for the last FY?"
                        placeholder="e.g 200,000"
                        scrollToNextFieldOnChage
                        getvalidationerrors={getValidationErrorsForField}
                        onChange={onInputChange}
                        currency
                        colmd={8}
                        colsm={8}
                        inline
                      />
                    )}
                  </div>
                </>
              )}
            {isAbnknown && purchaseUse === 'Business' && entityType && entityType === 'Trust' && (
              <>
                <div className="form-row">
                  <InputButton
                    label={
                      <>
                        <span>What is the Trustee Type?</span>
                      </>
                    }
                    fields={TRUSTEE_TYPES}
                    onChange={onInputChange}
                    fieldName="trusteeType"
                    value={trusteeType}
                    scrollToNextFieldOnChage
                    getvalidationerrors={getValidationErrorsForField}
                  />
                </div>
              </>
            )}

            {isAbnknown === false && purchaseUse === 'Business' && (
              <>
                <InputButton
                  label={
                    <>
                      <span>What's your employment status?</span>
                    </>
                  }
                  fields={EMPLOYMENT_STATUS_BUSINESS}
                  onChange={onInputChange}
                  fieldName="employmentStatus"
                  value={employmentStatus}
                  scrollToNextFieldOnChage
                  getvalidationerrors={getValidationErrorsForField}
                />
              </>
            )}
            {/* {InvalidAbn && (
              <Alert variant="danger" className="error-alert">
                <img src={risk} className="risk-img" />
                <p className="error-message">
                  ABN has 11 digits and an ACN has 9. The search text entered has {abnNumber.length}{' '}
                  digits. You may need to verify and re-enter the search text.
                </p>
              </Alert>
            )}
            {InvalidAbnNumber && (
              <Alert variant="danger" className="error-alert">
                <img src={risk} className="risk-img" />
                <p className="error-message">That ABN appears to be incorrect, please try again</p>
              </Alert>
            )}
            {serverError && (
              <Alert variant="danger" className="error-alert">
                <img src={risk} className="risk-img" />
                <p className="error-message">
                  We could not find your ABN at this time, please try again soon.
                </p>
              </Alert>
            )} */}

            {(employmentStatus === EMPSTATUS_FULL ||
              employmentStatus === EMPSTATUS_PART ||
              employmentStatus === EMPSTATUS_CASUAL) &&

              ((isAbnknown === false && purchaseUse === 'Business') ||
                purchaseUse === 'Personal' || purchaseUse === null && assetType === 'Commercial Equipment') && (
                <div className="current-emp-details ">
                  <TextField
                    fieldName="employerName"
                    value={employerName}
                    className="px-0 employer-name"
                    label="Employer Name"
                    placeholder="e.g Stratton"
                    required="*"
                    colmd={4}
                    scrollToNextFieldOnChage
                    getvalidationerrors={getValidationErrorsForField}
                    onChange={onInputChange}
                    isdisabled={isSavedEmployerName}
                    updateOnChange
                    inline
                  />
                  <DatePickerWrapper
                    value={employmentStartDate}
                    onChange={onInputChange}
                    minDateMessage="Date cannot be less than your date of birth"
                    maxDateMessage="Date cannot be greater than todays date  or equal to prev end date"
                    fieldName="employmentStartDate"
                    maxDtVal={formattedCurrentDate}
                    validateDateField={validateDateField}
                    label="Start date"
                    required="*"
                    dateFormat="dd/MM/yyyy"
                    columnCount="4"
                    getValidationErrors={getValidationErrorsForField}
                    className="emp-curr-start-dt"
                    inline
                    minDtVal={dateOfBirth}
                  />
                </div>
              )}
            {/* sups changes starts */}
            {purchaseUse === 'Personal' &&
              !addEmployment &&
              employmentStatus !== EMPSTATUS_RETIRED &&
              employmentStatus !== EMPSTATUS_UNEMPLOYED && (
                <>
                  <hr className="border-top-add-employment" />
                  <Button
                    className="btn-add-employment"
                    data-test="add employment"
                    onClick={addEmploymentClick}
                  >
                    <span className="add-emp-label">+ Add Employment</span>
                  </Button>
                </>
              )}

            {addEmployment && (
              <>
                <div className="add-employment-delete-block">
                  <div className="add-employment-title">Employment details</div>
                  <Button variant="danger" className="delete-btn" onClick={deleteEmploymentClick}>
                    Delete
                  </Button>
                  <img
                    src={deleteIcon}
                    alt="delete-icon"
                    onClick={deleteEmploymentClick}
                    className="delete-icon"
                  />
                </div>
                <InputButton
                  label={
                    <>
                      <span>How would you describe your current employment?</span>
                    </>
                  }
                  fields={
                    employmentStatus === EMPTYPE_SELF
                      ? EMPLOYMENT_STATUS_BUSINESS
                      : EMPLOYMENT_STATUS_ADDITIONAL
                  }
                  onChange={onInputChange}
                  fieldName="employmentStatus2"
                  value={employmentStatus2}
                  getvalidationerrors={getValidationErrorsForField}
                  scrollToNextFieldOnChage
                />

                {employmentStatus2 && (
                  <InputButton
                    label={
                      <>
                        <span>Is this your main employment?</span>
                      </>
                    }
                    fields={MAIN_EMPLOYMENT_SELECT}
                    onChange={onInputChange}
                    fieldName="mainEmployment"
                    value={mainEmployment}
                    getvalidationerrors={getValidationErrorsForField}
                    scrollToNextFieldOnChage
                  />
                )}
                {employmentStatus2 === EMPTYPE_SELF && (
                  <ABNlookUpDetails
                    fieldName="employmentPreviousABN"
                    abnRespDetails={handleABN}
                    // abnRespDetails={handlePreviousABN}
                    // defaultAbnNumber={abnNumberPrevious}
                    // defaultEntityType={entityTypePrevious}
                    // defaultEntityName={entityNamePrevious}
                    // defaultAbnStatus={abnStatusPrevious}
                    // defaultGstRegistrationstatus={gstRegistrationstatusPrevious}
                    defaultAbnNumber={abnNumber}
                    defaultEntityType={entityType}
                    defaultEntityName={entityName}
                    defaultAbnStatus={abnStatus}
                    defaultGstRegistrationstatus={gstRegistrationstatus}
                    handleInvalidAbn={setInvalidAbn}
                    handleInvalidAbnSearch={setInvalidAbnNumber}
                    handleSearchAbn={setSearchAbn}
                  />
                )}

                {(employmentStatus2 === EMPSTATUS_FULL ||
                  employmentStatus2 === EMPSTATUS_PART ||
                  employmentStatus2 === EMPSTATUS_CASUAL) &&
                  purchaseUse === 'Personal' && (
                    <div className="current-emp-details ">
                      <TextField
                        fieldName="employerName2"
                        value={employerName2}
                        className="px-0 employer-name"
                        label="Employer Name"
                        placeholder="e.g Stratton"
                        colmd={4}
                        required="*"
                        scrollToNextFieldOnChage
                        getvalidationerrors={getValidationErrorsForField}
                        onChange={onInputChange}
                        updateOnChange
                        isdisabled={isSavedEmployerName2}
                        inline
                      />
                      <DatePickerWrapper
                        value={employmentStartDate2}
                        onChange={onInputChange}
                        minDateMessage="date cannot be less than 01/01/1900"
                        maxDateMessage="Date cannot be greater than todays date"
                        fieldName="employmentStartDate2"
                        maxDtVal={formattedCurrentDate}
                        validateDateField={validateDateField}
                        label="Start date"
                        required="*"
                        dateFormat="dd/MM/yyyy"
                        columnCount="4"
                        getValidationErrors={getValidationErrorsForField}
                        className="emp-curr-start-dt"
                        inline
                      />
                    </div>
                  )}
              </>
            )}
            <div className="row container-footer employment-details-footer py-3 px-3  border-top">
              <div className="col-12 d-flex justify-content-end px-0">
                <button
                  type="button"
                  className="btn btn-primary btn-next-page"
                  disabled={nextDisabled || employmentCreationInProgress}
                  onClick={handleEmploymentInsert}
                >
                  {employmentCreationInProgress ? (
                    <CircularProgress color="inherit" size={24} thickness={2.6} />
                  ) : (
                    'Next'
                  )}
                  <span className="px-3">
                    <img src={rightnormal} alt="right" className="right-icon" />
                  </span>
                </button>
                {/*   dummy button addded to make the focus point there */}
                <input
                  type="button"
                  id="btnsubmit"
                  className="btn-dummy-page"
                  style={{ visibility: 'hidden' }}
                />
              </div>
            </div>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EmploymentDetails;
