import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { createBrowserHistory } from 'history';
import awsconfig from '../awsconfig';
import store from './store';
import AuthHandler from './AuthHandler';
import Header from './components/Header/Header';
import Routes from './Routes';
import SaveQuoteModal from './containers/Estimation/SaveQuoteModal';
import SaveAccount from './containers/Estimation/SaveAccount';
import ErrorBoundary from './components/ErrorPages/ErrorBoundary';
import PageTitle from './components/PageTitle';
import PartnerHeader from './components/PartnerHeader/PartnerHeader';
import '../styles/app.scss';
import { ThemeProvider } from '@material-ui/core';
import Theme from './providers/Theme';

const history = createBrowserHistory();
// your Cognito Hosted UI configuration

Amplify.configure(awsconfig);

const App = () => (
  <div className="body-wrapper">
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <Router history={history}>
          <div className="header-container container-fluid sticky-top">
            <Header />
            <PartnerHeader />
          </div>
          <AuthHandler />
          <PageTitle />
          <ErrorBoundary>
            <Routes />
            <SaveQuoteModal />
            <SaveAccount />
          </ErrorBoundary>
        </Router>
      </ThemeProvider>
    </Provider>
  </div>
);
export default App;
