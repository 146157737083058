import React from 'react';
import Button from 'react-bootstrap/Button';
import { STATES } from '../../util/constants';
import PrePopulateCarDetails from '../../containers/PrePopulateCarDetails';
import TextField from '../TextField';
import DropDown from '../DropDown';
import { CircularProgress, Grid } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const RegoSearch = ({
  regoNumber,
  getValidationErrorsForField,
  handleFieldChange,
  regoState,
  quoteInProgress,
  regoVehicleSearch,
  regoSearchError,
  searchCar,
  regoSearchInProgress,
  onInputChange,
  showFactoryOptions,
  props,
}) => {
  return (
    <Grid item>
      <div className="car-applicants-main-rego">
        <TextField
          fieldName="regoNumber"
          value={regoNumber}
          label="Rego number"
          placeholder="e.g GRF324"
          className="rego-number"
          getvalidationerrors={getValidationErrorsForField}
          onChange={handleFieldChange}
          inline
        />
        <DropDown
          fieldName="regoState"
          value={regoState}
          label="State"
          getvalidationerrors={getValidationErrorsForField}
          onChange={handleFieldChange}
          className="rego-state"
          colxs="12"
          colsm="6"
          colmd="6"
          options={
            <>
              <option value="" hidden>
                Choose...
              </option>
              {STATES.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </>
          }
        />
        <div className="search-action">
          <Button
            variant="primary"
            disabled={!regoNumber || !regoState || quoteInProgress}
            onClick={regoVehicleSearch}
          >
            {quoteInProgress ? (
              <CircularProgress color="inherit" size={24} thickness={2.6} />
            ) : (
              <>
                <Search /> Search
              </>
            )}
          </Button>
        </div>

        {!regoSearchError && (searchCar || regoSearchInProgress) && (
          <div className="car-applicants-main-prepopulate" name="carDetailsPrePopulate">
            <PrePopulateCarDetails
              isLoading={regoSearchInProgress}
              onClear={() => {
                onInputChange('searchCar', false, false);
                onInputChange('regoNumber', null, false);
                onInputChange('regoState', null, false);
              }}
              showFactoryOptions={showFactoryOptions}
              onManualEnter={() => {
                onInputChange('searchCar', false, false);
                onInputChange('regoNumber', null, false);
                onInputChange('regoState', null, false);
                onInputChange('isRegoKnown', false, false);
                onInputChange('manualEnter', true, false);
              }}
              {...props}
            />
          </div>
        )}
      </div>
    </Grid>
  );
};

export default RegoSearch;
