import { connect } from 'react-redux';
import EstimateLoadingpage from './EstimateLoadingpage';
import fieldChanged from '../../Actions/FieldChanged';
import { setCompleteTab, setCurrentTab } from '../../Actions/tab';
import { getValidationErrorsForFieldFunc } from '../../util/ContainerUtilities';
import { createQuote, updateQuote } from '../../Actions/car';
import { handleHTTPError, noErrors } from '../../Actions/errorHandler';

const mapStateToProps = (state) => ({
  currentTab: state.tab.tabName,
  depositAmount: state.car.depositAmount,
  progressValue: state.car.progress,
  minRepaymentVal: state.estimation.minRepaymentVal,
  maxRepaymentVal: state.estimation.maxRepaymentVal,
  purchasePrice: state.car.purchasePrice,
  isInitComplete: state.estimation.isInitComplete,
  selectFreq: state.estimation.selectFreq,
  minInterestVal: state.estimation.minInterestVal,
  maxInterestVal: state.estimation.maxInterestVal,
  termVal: state.estimation.termVal,
  residualSwitch: state.estimation.residualSwitch,
  updateLoanAmt: state.estimation.updateLoanAmt,
  quoteInProgress: state.account.quoteInProgress,
  loanOptions: state.estimation.loanOptions,
  termValUpdated: state.estimation.termValUpdated || state.estimation.termVal,
  depositUpdated:
    state.estimation.depositUpdated ||
    (state.estimation.depositUpdated === 0
      ? state.estimation.depositUpdated
      : state.car.depositAmount),
  purchasePriceUpdated:
    state.estimation.purchasePriceUpdated ||
    (state.estimation.purchasePriceUpdated === 0
      ? state.estimation.purchasePriceUpdated
      : state.car.purchasePrice),
  purchaseUse: state.car.purchaseUse,
  getValidationErrorsForField: getValidationErrorsForFieldFunc(state.car.validationErrors),
});
const mapDispatchToProps = (dispatch) => ({
  setTab: (tabName) => dispatch(setCurrentTab(tabName)),
  setTabComplete: () => {
    dispatch(setCompleteTab('estimation'));
  },
  onInputChange: (fieldName, value, scrollToNextFieldOnChage) =>
    dispatch(fieldChanged('estimation', fieldName, value, scrollToNextFieldOnChage)),
  onUpdateChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(fieldChanged('car', fieldName, value, scrollToNextFieldOnChage));
  },
  onSaveQuote: () => {
    dispatch(fieldChanged('account', 'showSaveQuote', true, false));
  },
  createQuote: () => dispatch(createQuote()),
  clearHTTPErrors: () => dispatch(noErrors()),
  handleHTTPError: (error, props) => dispatch(handleHTTPError(error, props)),
});

const EstimationContainer = connect(mapStateToProps, mapDispatchToProps)(EstimateLoadingpage);

export default EstimationContainer;
