const LicenceCardRuleEvaluator = (rule, value) => {
    const regexLicenceCard = new RegExp(/^([A-Za-z0-9]+)$/,);
    const result = regexLicenceCard.test(value);

    if (!result) {
        return rule.errorMessage;
    }

    return null;
};

export default LicenceCardRuleEvaluator;
