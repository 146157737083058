import React, { Fragment } from 'react';
import filter from 'lodash/filter';
import { findIndex, get } from 'lodash';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import { size } from 'lodash';
import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  borderStyling,
  primaryTextColor,
  secondaryTextColor,
  greyTextColor,
} from '../../../styles/constant';
import {
  documentFieldMap,
  uploadFileSize,
  documentTypeValue,
  uploadedVerifiedStatus,
  allowedFileTypes,
  DOCUMENT_TYPE,
} from '../../util/constants';
import { getDocument, getDocumentList, uploadDocument } from '../../Actions/finances';
import IncomeUpload from '../../containers/Dashboard/IncomeUpload';
import { getFileFromBase64, removeByAttribute } from '../../util/helpers';
import CustomDropZoneField from '../FileUpload/CustomDropzoneField';
import '../../containers/UploadDocumentContainer/upload-document.scss';

const styleContainer = { padding: '48px 24px', paddingTop: '24px', borderBottom: borderStyling };
const styleTitle = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  color: secondaryTextColor,
};
const styleUploadText = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 600,
  color: primaryTextColor,
  marginTop: '18px',
};
const styleUploadSubText = {
  fontSize: '15px',
  lineHeight: '22.5px',
  fontWeight: 500,
  color: greyTextColor,
  marginTop: '18px',
};

const IncomeDocUpload = ({
  uploadDocument,
  getDocumentList,
  getDocument,
  loading,
  setLoading,
  imageFiles,
  setImage,
  imageError,
  setImageError,
  documentList,
  setDocumentList,
  selfEmployedIncomeData,
  paygIncomeData = {},
  isPartner = false,
  additionalDoclistIncome,
  employmentStatus,
  applicantEmployerName,
  applicantType,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(960));
  additionalDoclistIncome = sortBy(additionalDoclistIncome, ['order']);
  const additionalDocListFlag = size(additionalDoclistIncome) > 0 ? true : false;
  const handleTransformDownloadListToimageFiles = (allDocList, isPartner = false) => {
    const uploadedDocList = filter(allDocList, (doc) =>
      uploadedVerifiedStatus.includes(doc?.status),
    );

    const newImageFiles = {};

    uploadedDocList.forEach((a) => {
      const n = a.fileName.lastIndexOf('.');
      const fileExtension = a.fileName.substring(n + 1);
      const name = a.fileName.substring(0, n);
      let documentType = a.documentType;

      const formattedData = {
        path: a.fileName,
        fileExtension,
        documentId: a.documentId,
        linkedEntityRelatedIncExId: a.linkedEntityRelatedIncExId,
        name,
        documentType,
        status: a.status,
      };
      if (newImageFiles?.[documentType]) {
        newImageFiles[documentType].push({ ...formattedData });
      } else {
        newImageFiles[documentType] = [{ ...formattedData }];
      }
    });
    setImage(newImageFiles);
  };

  const removeDataType = (fileType, base64String) => {
    if (fileType === 'jpeg' || fileType === 'jpg') {
      return base64String.replace('data:image/jpeg;base64,', '');
    } else if (fileType === 'png') {
      return base64String.replace('data:image/png;base64,', '');
    } else if (fileType === 'heic') {
      return base64String.replace(/^data:application\/[^;]+;base64,/, '');
    } else {
      return base64String.replace('data:application/pdf;base64,', '');
    }
  };

  const handleOnDrop = (
    category,
    isOther = false,
    isPartner = false,
    documentSubtype = '',
    idx = 0,
    incomeId = '',
  ) => async (newimagefile = []) => {
    const categoryDocList = imageFiles?.[category];
    let doclistByType = [];
    let updatedList = [...(categoryDocList || []), ...newimagefile];
    setLoading({ category, isPartner, idx, incomeId });
    if (categoryDocList && categoryDocList.length > idx) {
      updatedList = [...categoryDocList];
      updatedList[idx] = { ...categoryDocList[idx], ...newimagefile, status: 'Uploaded' };
    }

    let base64String = '';
    let approvedFileType = allowedFileTypes.find((e) => e === newimagefile[0]?.type);
    //convert file to base64
    if (newimagefile.length > 0) {
      // Select the very first file from list
      let fileToLoad = newimagefile[0];
      if (fileToLoad.size < uploadFileSize && approvedFileType !== undefined) {
        setImageError({});
        setImage({ ...imageFiles, [category]: updatedList });
        let fileNametoUpload = fileToLoad.name;
        const imagesFilesKeys = Object.keys(imageFiles);
        imagesFilesKeys.forEach((key, index) => {
          const valueFoundArr = imageFiles[key]?.filter((item) => item.path === fileNametoUpload);
          if (valueFoundArr.length > 0) {
            fileNametoUpload = fileNametoUpload + `${Math.floor(Math.random() * 100)}`;
          }
        });

        let fileReader = new FileReader();
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
        base64String = await resolvebase64(fileReader);
        let fileTypeExtension = newimagefile[0].type.replace(/(.*)\//g, '');

        //PDF
        const payload = {
          fileName: fileNametoUpload,
          documentType:
            documentSubtype !== '' && documentSubtype.includes(documentFieldMap.payslip)
              ? 'Payslip'
              : category,
          documentContents: removeDataType(fileTypeExtension, base64String),
        };
        if (category.includes(documentFieldMap.payslip)) {
          doclistByType = documentList.filter(
            (obj) => obj.documentType === category && obj.linkedEntityRelatedIncExId === incomeId,
          );
        } else if (
          category === documentFieldMap.taxReturn ||
          category === documentFieldMap.noticeOfAssessment
        ) {
          doclistByType = documentList.filter(
            (obj) => obj.documentType === category && obj.linkedEntityRelatedIncExId === incomeId,
          );
        } else {
          doclistByType = filter(documentList, ['documentType', category]);
        }
        payload['documentId'] =
          doclistByType?.[idx]?.status === 'Requested' ||
          doclistByType?.[idx]?.status === 'Required'
            ? doclistByType?.[idx]?.contentDocumentId || ''
            : '';
        payload['linkedEntityRelatedIncExId'] = incomeId;
        uploadDocument(payload, isPartner).then(() => {
          getDocumentList(isPartner).then((res) => {
            const { data } = res;
            const updatedDocList = remapDocType(data);
            setDocumentList(updatedDocList);
            handleTransformDownloadListToimageFiles(updatedDocList, isPartner);
            setLoading(false);
          });
        });
      } else if (fileToLoad.size >= uploadFileSize) {
        setLoading(false);
        const newImageErrorFiles = {};
        const formattedData = {
          fileName: fileToLoad.name,
          linkedEntityRelatedIncExId: incomeId,
          category,
          error: 'Document size should be less than 8MB',
        };
        newImageErrorFiles[category] = [{ ...formattedData }];
        setImageError(newImageErrorFiles);
        return;
      } else if (approvedFileType === undefined) {
        setLoading(false);
        const newImageErrorFiles = {};
        const formattedData = {
          fileName: fileToLoad.name,
          linkedEntityRelatedIncExId: incomeId,
          category,
          error: 'Incorrect file format',
        };
        newImageErrorFiles[category] = [{ ...formattedData }];
        setImageError(newImageErrorFiles);
        return;
      } else {
        return;
      }
    }
  };

  const handleDeleteFile = (category) => (file) => {
    const updatedList = [...(imageFiles?.[category] || [])];
    remove(updatedList, { path: file.path, name: file.name });
    setImage({ ...imageFiles, [category]: updatedList });
  };

  const remapDocType = (docList, isPartner = false) => {
    let currentLinkedEntityRelatedIncExId = '';
    let arrPayslipList = [];
    let arrSelfIncomeList = [];
    for (let i = 0; i < paygIncomeData.length; i++) {
      const arrSorting = sortBy(
        docList.filter((item) => {
          return (
            item.documentSubtype !== null &&
            item.linkedEntityRelatedIncExId === paygIncomeData[i].id
          );
        }),
        ['documentSubtype'],
      );
      arrPayslipList.push(...arrSorting);
    }
    for (let i = 0; i < selfEmployedIncomeData.length; i++) {
      const arrSelfIncomeSorting = sortBy(
        docList.filter((item) => {
          return item.linkedEntityRelatedIncExId === selfEmployedIncomeData[i].id;
        }),
        ['documentSubtype'],
      );
      arrSelfIncomeList.push(...arrSelfIncomeSorting);
    }
    if (arrPayslipList?.length !== 0) {
      for (let i = 0; i < documentTypeValue.length; i++) {
        removeByAttribute(docList, 'documentType', documentTypeValue[i]);
      }
    }
    if (arrSelfIncomeList?.length !== 0) {
      for (let i = 0; i < documentTypeValue.length; i++) {
        removeByAttribute(docList, 'documentType', documentTypeValue[i]);
      }
    }

    const docListUpdated = docList.concat(arrPayslipList);
    const docListIncomeUpdated = docListUpdated.concat(arrSelfIncomeList);
    const arrayDocList =
      arrPayslipList.length || arrSelfIncomeList.length
        ? docListIncomeUpdated
        : sortBy(sortBy(docListIncomeUpdated, ['uploadedDate']), ['linkedEntityRelatedIncExId']);
    const updatedList = arrayDocList?.map((item) => {
      if (item.documentType === DOCUMENT_TYPE.PAYSLIP) {
        item.documentType = item?.documentSubtype !== null ? item?.documentSubtype : '';
      }
      return { ...item };
    });
    return updatedList;
  };

  function resolvebase64(fileReader) {
    return new Promise((resolve) => {
      // Onload of file read the file content
      fileReader.onload = function(fileLoadedEvent) {
        resolve(fileLoadedEvent.target.result); // your base64 string
      };
    });
  }
  const onClickDownloadFile = (fileInfo) => {
    const fileDesc = fileInfo[0];
    // convert base 64 string into file

    // call api to get document details and base64 string
    getDocument(fileDesc.documentId).then((res) => {
      const base64String = res.data;
      const file = getFileFromBase64(base64String, fileDesc.name, fileDesc.fileExtension);

      // after converting . download the file to user
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(file);
      a.download = `${fileDesc.name}.${
        fileDesc.fileExtension === 'jpeg' || fileDesc.fileExtension === 'jpg'
          ? 'jpg'
          : fileDesc.fileExtension === 'png'
          ? 'png'
          : fileDesc.fileExtension === 'heic'
          ? 'heic'
          : 'pdf'
      }`;
      a.click();
    });
  };

  const paySlipArrayElements = additionalDoclistIncome
    .filter((obj) => obj.documentType.includes(documentFieldMap.payslip))
    .filter((obj) => paygIncomeData.some((item) => obj.linkedEntityRelatedIncExId === item.id));
  const _ = require('lodash');
  const grouplist = _.groupBy(paySlipArrayElements, 'linkedEntityRelatedIncExId');

  const selfEmployedArrayElements = additionalDoclistIncome
    .filter(
      (obj) =>
        obj.documentType.includes('Tax Return') ||
        obj.documentType.includes('Notice Of Assessment'),
    )
    .filter((obj) =>
      selfEmployedIncomeData.some((item) => obj.linkedEntityRelatedIncExId === item.id),
    );
  const selfEmployedGroupList = _.groupBy(selfEmployedArrayElements, 'linkedEntityRelatedIncExId');

  const getIncomeType = (incomeData, linkedEntityRelatedIncExId) => {
    const matchedObject = incomeData.find((obj) => obj.id === linkedEntityRelatedIncExId);
    return matchedObject.incomeType;
  };
  const getEmployerName = (incomeData, linkedEntityRelatedIncExId) => {
    const matchedObject = incomeData.find((obj) => obj.id === linkedEntityRelatedIncExId);
    return matchedObject.applicantEmployerName;
  };

  const getIndex = (docType, linkedEntityRelatedIncExId) => {
    const doclist = imageFiles?.[docType];
    const index = doclist.findIndex(
      (obj) => obj.linkedEntityRelatedIncExId === linkedEntityRelatedIncExId,
    );
    return index;
  };
  const getErrorIndex = (docType, linkedEntityRelatedIncExId) => {
    const doclist = imageError?.[docType];
    const index = doclist?.findIndex(
      (obj) => obj.linkedEntityRelatedIncExId === linkedEntityRelatedIncExId,
    );
    return index;
  };

  const isDocUploaded = (status) => {
    return uploadedVerifiedStatus.includes(status);
  };

  return (
    <Box sx={styleContainer}>
      {additionalDocListFlag && <Box sx={styleTitle}>INCOME</Box>}
      {paygIncomeData.length >= 1 && (
        <div>
          {Object.keys(grouplist).map((linkedEntityRelatedIncExId) => (
            <Fragment key={linkedEntityRelatedIncExId}>
              <Box sx={styleUploadText}>
                {mobileView ? (
                  <Grid item xs={12} className="pb-2 pr-3">
                    Upload 2 of you most recent payslips for:
                  </Grid>
                ) : (
                  <span>Upload 2 of you most recent payslips for:</span>
                )}
                <Box
                  sx={{
                    background: '#F2F2F2',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    display: 'inline',
                  }}
                >
                  {getIncomeType(paygIncomeData, linkedEntityRelatedIncExId)}
                </Box>
                {getEmployerName(paygIncomeData, linkedEntityRelatedIncExId) != '' && (
                  <Box
                    sx={{
                      background: '#F2F2F2',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      display: 'inline',
                    }}
                  >
                    ({getEmployerName(paygIncomeData, linkedEntityRelatedIncExId)})
                  </Box>
                )}
              </Box>
              <div className="payslip-wrapper">
                {grouplist[linkedEntityRelatedIncExId].map((grouplistVal) => (
                  <Grid item xs={mobileView ? 12 : 6}>
                    <Box sx={styleUploadSubText}>{grouplistVal.PQ_Field_Text}</Box>
                    <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={11}>
                          <CustomDropZoneField
                            handleOnDrop={handleOnDrop(
                              grouplistVal.documentType,
                              false,
                              isPartner,
                              grouplistVal.documentSubtype,
                              0,
                              grouplistVal.linkedEntityRelatedIncExId,
                            )}
                            handleOnClick={onClickDownloadFile}
                            handleDeleteFile={handleDeleteFile(
                              grouplistVal.documentType,
                              false,
                              isPartner,
                            )}
                            error={get(
                              imageError,
                              `${grouplistVal.documentType}[${getErrorIndex(
                                grouplistVal.documentType,
                                linkedEntityRelatedIncExId,
                              )}].error`,
                              false,
                            )}
                            loading={
                              loading.category === grouplistVal.documentType &&
                              loading.isPartner === isPartner &&
                              loading.incomeId === linkedEntityRelatedIncExId
                            }
                            imagefile={
                              isDocUploaded(
                                imageFiles?.[grouplistVal.documentType]?.[
                                  getIndex(grouplistVal.documentType, linkedEntityRelatedIncExId)
                                ]?.status,
                              )
                                ? [
                                    imageFiles?.[grouplistVal.documentType]?.[
                                      getIndex(
                                        grouplistVal.documentType,
                                        linkedEntityRelatedIncExId,
                                      )
                                    ],
                                  ]
                                : undefined
                            }
                            disabled={isDocUploaded(
                              imageFiles?.[grouplistVal.documentType]?.[
                                getIndex(grouplistVal.documentType, linkedEntityRelatedIncExId)
                              ]?.status,
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </div>
            </Fragment>
          ))}
        </div>
      )}

      {selfEmployedIncomeData.length >= 1 && (
        <div>
          {Object.keys(selfEmployedGroupList).map((linkedEntityRelatedIncExId) => (
            <>
              <Box sx={styleUploadText}>
                {mobileView ? (
                  <Grid item xs={12} className="pb-2 pr-3">
                    Upload income documents for:
                  </Grid>
                ) : (
                  <span>Upload income documents for:</span>
                )}
                <Box
                  sx={{
                    background: '#F2F2F2',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    display: 'inline',
                  }}
                >
                  {getIncomeType(selfEmployedIncomeData, linkedEntityRelatedIncExId)}
                </Box>
                {getEmployerName(selfEmployedIncomeData, linkedEntityRelatedIncExId) != '' && (
                  <Box
                    sx={{
                      background: '#F2F2F2',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      display: 'inline',
                    }}
                  >
                    ({getEmployerName(selfEmployedIncomeData, linkedEntityRelatedIncExId)})
                  </Box>
                )}
              </Box>
              <div className="payslip-wrapper">
                {selfEmployedGroupList[linkedEntityRelatedIncExId].map((grouplistVal) => (
                  <Grid item xs={mobileView ? 12 : 6}>
                    <Box sx={styleUploadSubText}>{grouplistVal.PQ_Field_Text}</Box>
                    <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={11}>
                          <CustomDropZoneField
                            handleOnDrop={handleOnDrop(
                              grouplistVal.documentType,
                              false,
                              isPartner,
                              grouplistVal.documentSubtype,
                              0,
                              grouplistVal.linkedEntityRelatedIncExId,
                            )}
                            handleOnClick={onClickDownloadFile}
                            handleDeleteFile={handleDeleteFile(
                              grouplistVal.documentType,
                              false,
                              isPartner,
                            )}
                            error={get(
                              imageError,
                              `${grouplistVal.documentType}[${getErrorIndex(
                                grouplistVal.documentType,
                                linkedEntityRelatedIncExId,
                              )}].error`,
                              false,
                            )}
                            loading={
                              loading.category === grouplistVal.documentType &&
                              loading.isPartner === isPartner &&
                              loading.incomeId === linkedEntityRelatedIncExId
                            }
                            imagefile={
                              isDocUploaded(
                                imageFiles?.[grouplistVal.documentType]?.[
                                  getIndex(grouplistVal.documentType, linkedEntityRelatedIncExId)
                                ]?.status,
                              )
                                ? [
                                    imageFiles?.[grouplistVal.documentType]?.[
                                      getIndex(
                                        grouplistVal.documentType,
                                        linkedEntityRelatedIncExId,
                                      )
                                    ],
                                  ]
                                : undefined
                            }
                            disabled={isDocUploaded(
                              imageFiles?.[grouplistVal.documentType]?.[
                                getIndex(grouplistVal.documentType, linkedEntityRelatedIncExId)
                              ]?.status,
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </div>
            </>
          ))}
        </div>
      )}
      {additionalDocListFlag && (
        <div className="additional-document-list-wrapper">
          {additionalDoclistIncome.map(
            (item) =>
              item.documentType !== 'Tax Return' &&
              item.documentType !== 'Notice Of Assessment' &&
              !item.documentType.includes(documentFieldMap.payslip) && (
                <>
                  <Grid item xs={mobileView ? 12 : 6}>
                    <Box sx={styleUploadText}>{item.PQ_Header_Text}</Box>
                    <Box sx={styleUploadSubText}>{item.PQ_Field_Text}</Box>
                    <Box>
                      <Grid container spacing={3}>
                        <Grid item xs={11}>
                          <CustomDropZoneField
                            handleOnDrop={handleOnDrop(
                              item.documentType,
                              false,
                              isPartner,
                              item.documentSubtype,
                            )}
                            handleOnClick={onClickDownloadFile}
                            handleDeleteFile={handleDeleteFile(item.documentType, false, isPartner)}
                            error={get(imageError, `${item.documentType}[0].error`, false)}
                            loading={
                              loading.category === item.documentType &&
                              loading.isPartner === isPartner
                            }
                            imagefile={imageFiles?.[item.documentType]}
                            disabled={imageFiles?.[item.documentType]?.length}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              ),
          )}
        </div>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  employmentStatus: state.applicants.employmentStatus,
  applicantEmployerName: state.applicants.employerName,
});

const mapDispatchToProps = (dispatch) => ({
  uploadDocument: (doc, isPartner) => dispatch(uploadDocument(doc, isPartner)),
  getDocumentList: (isPartner) => dispatch(getDocumentList(isPartner)),
  getDocument: (documentId) => dispatch(getDocument(documentId)),
});

IncomeDocUpload.propTypes = {
  incomedata: PropTypes.objectOf(
    PropTypes.shape({
      incomeType: PropTypes.string,
      id: PropTypes.string,
      applicantEmployerName: PropTypes.string,
    }),
  ),
  imageFiles: PropTypes.object,
  loading: PropTypes.bool,
  imageError: PropTypes.object,
  documentList: PropTypes.object,
  selfEmployedIncomeData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomeDocUpload);
