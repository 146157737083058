import { connect } from 'react-redux';
import LiabilityDetailsNew from './LiabilityDetailsNew'
import {
  addLiability,
  updateLiability,
  deleteLiability,
  completeLiabDetails,
  prevFinanceLiab,
  liabilities,
  deleteGeneric,
  liabilitiesApi,
} from '../../../../Actions/finances';
import { getValidationErrorsForFieldFunc } from '../../../../util/ContainerUtilities';
import { scrollToNextClicked } from '../../../../Actions/ScrollToNextClicked';
import { validationErrors, noErrors } from '../../../../Actions/errorHandler';
import { RetrieveOpportunity, reQuote } from '../../../../Actions/car';
import scrollToNextField from '../../../../util/ScrollToNextField';
import { StaticRouter } from 'react-router';
import { liteFieldChanged } from '../../../../Actions/FieldChanged';

const mapStateToProps = (state) => ({
  assets: state.applicants.assets || [],
  liabilities: state.applicants.liabilities || [],
  liabilityDetails: state.applicants.liabilityDetails,
  previousFinance: state.applicants.previousFinance,
  pendingLiabilitySave: state.applicants.pendingLiabilitySave,
  initExpList: state.applicants.initExpList,
  liabilitySubmitInprogress:state.applicants.liabilitySubmitInprogress,
  getValidationErrorsForField: getValidationErrorsForFieldFunc(),
  errorMessage: state.errorHandler.errorMessage,
  opportunityId: state.applicants.opportunityId,
  ValidationErrorFlag:
    state.errorHandler.ValidationErrorFlag && state.errorHandler.errorCategory === 'LIABILITIES',
  purchaseSource: state.car.purchaseSource,
  assetsLists: state.applicants.assets,
});

const mapDispatchToProps = (dispatch) => ({
  addLiability: (liabilityData) => {
    dispatch(addLiability(liabilityData));
  },
  updateLiability: (liabilityData) => {
    dispatch(updateLiability(liabilityData));
  },
  deleteLiability: (liabilityData) => {
    dispatch(deleteLiability(liabilityData));
  },
  onInputChange: (fieldName, value) => {
    dispatch(prevFinanceLiab(fieldName, value, false));
  },
  completeLiabDetails: () => {
    dispatch(completeLiabDetails());
    dispatch(scrollToNextClicked('finance_details', 'expense-details', [], false, false, true));
  },

  liabilityNewApi: () => dispatch(liabilitiesApi()),
  liabilityApi: (liabData) => dispatch(liabilities(liabData)),
  validationCheck: (error) => {
    dispatch(validationErrors(error, 'LIABILITIES'));
    scrollToNextField('liability-details', true, true);
  },
  noErrors: () => dispatch(noErrors()),
  deleteLiabApi: (liabData) => dispatch(deleteGeneric(liabData)),
  reQuote: () => dispatch(reQuote()),
  onLiteInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(liteFieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage));
  },
  retrieveOpportunity: () => dispatch(RetrieveOpportunity()),
});

const LiabilityFinanceDetailsNew = connect(mapStateToProps, mapDispatchToProps)(LiabilityDetailsNew);

export default LiabilityFinanceDetailsNew;
