import { connect } from 'react-redux';
import AssetDetailsNew from './AssetDetailsNew';
import {
  addAsset,
  updateAsset,
  deleteAsset,
  completeAssetDetails,
  assets,
  deleteGeneric,
  assetsSave,
  addLiability,
} from '../../../../Actions/finances';
import { liteFieldChanged } from '../../../../Actions/FieldChanged';
import { scrollToNextClicked } from '../../../../Actions/ScrollToNextClicked';
import { validationErrors, noErrors } from '../../../../Actions/errorHandler';
import { reQuote, applicants, RetrieveOpportunity } from '../../../../Actions/car';
import scrollToNextField from '../../../../util/ScrollToNextField';
import { getValidationErrorsForFieldFunc } from '../../../../util/ContainerUtilities';

const mapStateToProps = (state) => ({
  assets: state.applicants.assets || [],
  assetDetails: state.applicants.assetDetails,
  pendingAssetSave: state.applicants.pendingAssetSave,
  initLiabList: state.applicants.initLiabList,
  assetid: state.account.assetid,
  isSpousePropOwner: state.applicants.isSpousePropOwner,
  residentialStatus: state.applicants.residentialStatus,
  income: state.applicants.income,
  relationshipStatus: state.applicants.relationship,
  errorMessage: state.errorHandler.errorMessage,
  assetSubmitInprogress: state.applicants.assetSubmitInprogress,
  assetSubmit: state.applicants.assetSubmit,
  liabilities: state.applicants.liabilities || [],
  ValidationErrorFlag:
    state.errorHandler.ValidationErrorFlag && state.errorHandler.errorCategory === 'ASSETS',
  getValidationErrorsForField: getValidationErrorsForFieldFunc(state.applicants.validationErrors),
});

const mapDispatchToProps = (dispatch) => ({
  addAsset: (assetData) => {
    dispatch(addAsset(assetData));
  },
  addLiability: (liabilityData) => {
    dispatch(addLiability(liabilityData));
  },
  updateAsset: (assetData) => {
    dispatch(updateAsset(assetData));
  },
  deleteAsset: (assetData) => {
    dispatch(deleteAsset(assetData));
  },
  assetsSave: () => dispatch(assetsSave()),
  completeAssetDetails: () => {
    dispatch(completeAssetDetails());
    dispatch(scrollToNextClicked('finance_details', 'liability-details', [], true, true, true));
  },
  validationCheck: (error) => {
    dispatch(validationErrors(error, 'ASSETS'));
    scrollToNextField('asset-details', true, true);
  },
  assetsApi: (assetData, assetId) => dispatch(assets(assetData, assetId)),
  noErrors: () => dispatch(noErrors()),
  reQuote: () => dispatch(reQuote()),
  onLiteInputChange: (fieldName, value, scrollToNextFieldOnChage) => {
    dispatch(liteFieldChanged('applicants', fieldName, value, scrollToNextFieldOnChage));
  },
  deleteAssetApi: (assetData) => dispatch(deleteGeneric(assetData)),
  applicants: () => dispatch(applicants()),
  retrieveOpportunity: () => dispatch(RetrieveOpportunity()),
});

const AssetFinanceDetailsNew = connect(mapStateToProps, mapDispatchToProps)(AssetDetailsNew);

export default AssetFinanceDetailsNew;
