/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';

import cls from 'classnames';

import './input-button.scss';
import getErrorMessageRows from './FormControlFeedback';
import BoxSkeletons from './Skeleton/BoxSkeletons';

import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { Box } from '@material-ui/core';

const InputButton = ({
  id,
  label,
  fields,
  formGroupClass,
  labelClass,
  btnGroupClass,
  btnClass,
  value,
  onChange,
  fieldName,
  getvalidationerrors,
  scrollToNextFieldOnChage,
  ignoreHeaderOffset,
  returnObject,
  sectionContainerClass,
  sublabel,
  isdisabled,
}) => {
  const errorMessageRows = getErrorMessageRows(getvalidationerrors, fieldName);

  const handleSelect = (selectedObject) => {
    onChange(
      fieldName,
      returnObject ? selectedObject : selectedObject.value,
      scrollToNextFieldOnChage,
      ignoreHeaderOffset,
    );
  };

  const loading = !fields || (fields && !fields.length);

  return (
    <FormGroup
      name={fieldName}
      className={cls('select-field-container', 'scrollable-anchor-tag', formGroupClass)}
    >
      <a name={id} href={`#${id}`}>
        &nbsp;
      </a>
      <Form.Label className={cls('select-field-label', labelClass)}>{label}</Form.Label>
      <Form.Label className="">{sublabel}</Form.Label>
      {errorMessageRows}
      <div
        className={cls(
          'select-field-section',
          sectionContainerClass,
          fields && fields.length > 3 && 'select-field-mediumcontainer',
          //  fields && fields.length > 4 && 'select-field-largecontainer',
        )}
      >
        {loading && <BoxSkeletons btnGroupClass={btnGroupClass} btnClass={btnClass} />}
        {!loading &&
          fields.map((radioField) => (
            <ButtonGroup
              key={radioField.value || radioField.name}
              className={cls('select-field-group', 'pr-3', 'pb-3', btnGroupClass)}
              size="lg"
            >
              <Button
                onClick={() => handleSelect(radioField)}
                data-test={fieldName}
                className={cls(
                  'select-field',
                  radioField.value === value && 'is-selected',
                  btnClass,
                  radioField.description && 'with-description',
                  radioField.image && 'select-btn-img',
                  radioField.name &&
                    radioField.name.indexOf(' ') === -1 &&
                    radioField.value.length > 7 &&
                    'lengthy-label',
                )}
                disabled={radioField?.isDisabled || isdisabled}
              >
                {radioField.image && (
                  <img src={radioField.image} className="icon-width" alt="documentImg" />
                )}
                <span>
                  {radioField.name}
                  {radioField.tooltipMessage && (
                    <Tooltip title={radioField.tooltipMessage}>
                      <HelpIcon
                        style={{
                          height: '18px',
                          width: '18px',
                          marginLeft: '5px',
                          color: '#4F4F4F',
                        }}
                      />
                    </Tooltip>
                  )}
                </span>

                {radioField.description && (
                  <>
                    <hr />
                    <span className="select-field-btn-desc">{radioField.description}</span>
                  </>
                )}
              </Button>
            </ButtonGroup>
          ))}
      </div>
    </FormGroup>
  );
};

InputButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default InputButton;
