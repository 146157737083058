/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';

import cls from 'classnames';

import './styles.scss';
import getErrorMessageRows from '../FormControlFeedback';

const ButtonGroupCustom = ({
  label,
  fields,
  formGroupClass,
  labelClass,
  btnGroupClass,
  btnClass,
  value,
  onChange,
  fieldName,
  getvalidationerrors,
  scrollToNextFieldOnChage,
  isdisabled,
  required,
}) => {
  const errorMessageRows = getErrorMessageRows(getvalidationerrors, fieldName);

  const handleSelect = (selectedObject) => {
    onChange(fieldName, selectedObject.value, scrollToNextFieldOnChage);
  };

  return (
    <FormGroup name={fieldName} className={cls('button-field-container ', formGroupClass)}>
      <Form.Label className={cls('button-field-label', labelClass)}>
        {label} <span className="mandatory">{required}</span>
      </Form.Label>
      {errorMessageRows}
      <div className={cls('')}>
        {fields.map((radioField) => (
          <ButtonGroup
            key={radioField.name}
            className={cls('button-field-group', 'pb-2', btnGroupClass)}
            size="lg"
          >
            <Button
              onClick={() => handleSelect(radioField)}
              className={cls(
                'button-field',
                radioField.value === value && 'is-selected',
                btnClass,
                radioField.name &&
                  radioField.name.indexOf(' ') === -1 &&
                  radioField.value.length > 7 &&
                  'lengthy-label',
              )}
              disabled={isdisabled}
            >
              {radioField.name}
            </Button>
          </ButtonGroup>
        ))}
      </div>
    </FormGroup>
  );
};

ButtonGroupCustom.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default ButtonGroupCustom;
