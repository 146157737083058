import fieldChanged from './FieldChanged';
import * as log from '../util/log';

export const SET_SIGNUP_COGNITOID_ACCOUNT = 'SET_SIGNUP_COGNITOID_ACCOUNT';
export const SET_SIGNUP_DETAILS_ACCOUNT = 'SET_SIGNUP_DETAILS_ACCOUNT';
export const SET_SIGNUP_ERROR_ACCOUNT = 'SET_SIGNUP_ERROR_ACCOUNT';

export const setAuthenticated = (userName) => (dispatch) => {
  dispatch(fieldChanged('estimation', 'isInitComplete', true, false));
  dispatch(fieldChanged('account', 'userName', userName, false));
  dispatch(fieldChanged('account', 'isAuthenticated', true, false));

  return Promise.resolve();
};

export const setAuthenticationFailure = (error) => (dispatch) => {
  dispatch(fieldChanged('estimation', 'isInitComplete', false, false));
  dispatch(fieldChanged('account', 'userName', '', false));
  dispatch(fieldChanged('account', 'isAuthenticated', false, false));
  dispatch(fieldChanged('account', 'loginError', error, false));

  return Promise.resolve();
};

export const setApplicantId = (token) => {
  try {
    const parsedToken = JSON.parse(atob(token)) || {};
    return {
      type: SET_SIGNUP_DETAILS_ACCOUNT,
      payload: {
        mobile: parsedToken.mobile,
        applicantId: parsedToken.applicantId,
        leadId: parsedToken.leadId,
        isSalesforceCreatedOpportunity:
          parsedToken.applicantId && parsedToken.leadId ? true : false,
      },
    };
  } catch (err) {
    log.error('parse error', err);
    return {
      type: SET_SIGNUP_ERROR_ACCOUNT,
    };
  }
};

export const setCognitoId = (id) => ({
  type: SET_SIGNUP_COGNITOID_ACCOUNT,
  id,
});
