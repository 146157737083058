import { createTheme } from '@material-ui/core';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#330072',
      contrastText: '#ffffff',
    },
    action: {
      disabledBackground: '#C6C5C6',
      disabled: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Poppins-Medium',
    button: {
      fontFamily: 'Poppins-Medium',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22px',
      textTransform: 'capitalize',
    },
    body1: {
      color: '#7A7E80',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0px',
      textAlign: 'left',
    },
  },
});

export default Theme;
