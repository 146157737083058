import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles({
  root: {
    height: 4,
    backgroundColor: 'transparent',
  },
  bar: {
    borderRadius: 3.5,
    backgroundColor: '#05bd7f',
  },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0),
  },
}));

export default function ProgressBar({ progressValue = 0 }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        color="secondary"
        value={progressValue}
      />
    </div>
  );
}

ProgressBar.propTypes = {
  progressValue: PropTypes.number.isRequired,
};
