/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable linebreak-style */
import React from 'react';
import { Switch, Route, Redirect, withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import cls from 'classnames';
import Stepper from '../components/Stepper';
import CarFinanceContainer from './CarFinance';
import EstimationDetails from './Estimation/index';
import PersonalDetailsContainer from './PersonalDetails';
import FinanceDetailsContainer from './Finances/FinanceDetailsContainer';
import Footer from '../components/Footer';
import { ROUTES } from '../util/constants';
import fieldChanged from '../Actions/FieldChanged';
import { validationErrors, noErrors } from '../Actions/errorHandler';
import ErrorBoundary from '../components/ErrorPages/ErrorBoundary';
import AddressDetailsContainer from './AddressDetails';
import CompareLoanOptionsContainer from './CompareLoanOptions/index';
import EmploymentDetailsContainer from './EmploymentDetails';
import ContactDetails from './ContactDetails/ContactDetails';
import SpouseCoappPersonalDetailsContainer from './SpouseCoApplicantDetails/PersonalDetails';
import CoApplicantDetails from './SpouseCoApplicantDetails/CoApplicantDetails';
import CoApplicantLicenceDetails from './SpouseCoApplicantDetails/CoApplicantLicenceDetails';
import UploadDoDocumentContainer from './UploadDocumentContainer';

const ContentAreaContainer = ({
  currentTab,
  completedTabs,
  personaldetailserror,
  financedetailserror,
  cardetailserror,
  isInitComplete,
  disableSideBar,
  isAuthenticated,
  forceNewApplication,
  estimationCompleted,
  applicantType,
  spouseCoApplicantId,
  selectedLender,
  contactDetails,
  carSalesOppCreated,
  assetType,
}) => {
  const location = useLocation();

  let assetTypePathValue = ROUTES[assetType];
  const isCompareLoanOptionsScreen = location.pathname === ROUTES.compareLoanOptions;
  return (
    <>
      <Container
        className={cls(
          { 'estimate-conatiner': ROUTES.estimation === location.pathname },
          'view-port-container',
        )}
      >
        <div
          className={cls(isCompareLoanOptionsScreen && 'compare-loan-wrapper', 'content-wrapper')}
        >
          {(isAuthenticated || isInitComplete) &&
            (!forceNewApplication || (forceNewApplication && estimationCompleted)) && (
              <>
                {location.pathname === ROUTES.estimation ? null : (
                  <div
                    className={cls(
                      location.pathname === ROUTES.compareLoanOptions
                        ? 'stepper-compare-loan'
                        : 'other-stepper-sections',
                    )}
                  >
                    <Stepper
                      applicantType={applicantType}
                      disableSubSteps={disableSideBar}
                      validationErrors={validationErrors}
                      noErrors={noErrors}
                      hideSidebar={location.pathname === ROUTES.estimation}
                    />
                  </div>
                )}
              </>
            )}

          <section
            className={cls(
              { 'estimation-content-section': ROUTES.estimation === location.pathname },
              'content-container',
            )}
            id="content"
            role="main"
          >
            <div className="col-12 px-0 mb-0 py-0 content">
              <ErrorBoundary>
                <Switch>
                  <Route exact path={assetTypePathValue} component={CarFinanceContainer} />
                  {completedTabs.car && (
                    <Route path={ROUTES.estimation} exact component={EstimationDetails} />
                  )}
                  {!completedTabs.estimation && completedTabs.car && (
                    <Route path={ROUTES.contactDetails} exact component={ContactDetails} />
                  )}
                  {(completedTabs.estimation || completedTabs.car) && (
                    <Route path={ROUTES.personal} exact component={PersonalDetailsContainer} />
                  )}
                  {completedTabs.applicants && (
                    <Route path={ROUTES.applicants} exact component={FinanceDetailsContainer} />
                  )}
                  {completedTabs.finance_details && (
                    <Route
                      path={ROUTES.compareLoanOptions}
                      exact
                      component={CompareLoanOptionsContainer}
                    />
                  )}
                  {completedTabs.compare_loan_options && (
                    <Route path={ROUTES.addressDetails} exact component={AddressDetailsContainer} />
                  )}
                  {completedTabs.address_details && (
                    <Route
                      path={ROUTES.personalDetails}
                      exact
                      component={SpouseCoappPersonalDetailsContainer}
                    />
                  )}
                  {(completedTabs.address_details || contactDetails) && (
                    <Route
                      path={ROUTES.employmentDetails}
                      exact
                      component={EmploymentDetailsContainer}
                    />
                  )}
                  {(completedTabs.address_details ||
                    completedTabs.coApplicant_address_details ||
                    completedTabs.coApplicant_personalDetails) && (
                      <Route
                        path={ROUTES.uploadDocuments}
                        exact
                        component={UploadDoDocumentContainer}
                      />
                    )}
                  {completedTabs.coApplicant_address_details && (
                    <Route
                      path={ROUTES.coApplicantPersonalDetails}
                      exact
                      component={CoApplicantDetails}
                    />
                  )}
                  {completedTabs.coApplicant_personalDetails && (
                    <Route
                      path={ROUTES.coApplicantIdentityDetails}
                      exact
                      component={CoApplicantLicenceDetails}
                    />
                  )}

                  <Redirect
                    to={
                      isAuthenticated && !forceNewApplication && !carSalesOppCreated
                        ? ROUTES.login
                        : ROUTES.initial
                    }
                  />
                </Switch>
              </ErrorBoundary>
            </div>
          </section>
        </div>
      </Container>
      {isInitComplete && currentTab === 'car' && !selectedLender && (
        <div className="form-footer footer-main container-fluid sticky-bottom">
          {/* <Footer currentTab="car" nextTabname="applicants" validationErrors={cardetailserror} /> */}
        </div>
      )}
      {completedTabs.car && currentTab === 'applicants' && !selectedLender && (
        <div className="form-footer footer-main container-fluid sticky-bottom">
          {/* <Footer currentTab="applicants" validationErrors={personaldetailserror} /> */}
        </div>
      )}
      {completedTabs.car && currentTab === 'finance_details' && !selectedLender && (
        <div className="form-footer footer-main container-fluid sticky-bottom">
          {/* <Footer currentTab="finance_details" validationErrors={financedetailserror} /> */}
        </div>
      )}
      {completedTabs.car && !!selectedLender && (
        <div className="form-footer footer-main container-fluid sticky-bottom">
          {/* <Footer hideEdit selectedLender={selectedLender} currentTab="" validationErrors="" /> */}
        </div>
      )}
    </>
  );
};

ContentAreaContainer.propTypes = {
  currentTab: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  completedTabs: PropTypes.shape({
    car: PropTypes.bool,
    estimation: PropTypes.bool,
    applicants: PropTypes.bool,
  }).isRequired,
};

export default connect(
  (state, ownProps) => {
    const { applicantType } = ownProps?.computedMatch?.params || ownProps?.match?.params || false;
    return {
      applicantType,
      currentTab: state.tab.tabName,
      completedTabs: state.tab.completedTabs,
      personaldetailserror: state.applicants.validationErrors,
      cardetailserror: state.car.validationErrors,
      financedetailserror: state.applicants.validationErrors,
      AcnNumber: state.applicants.AcnNumber,
      abnNumber: state.applicants.abnNumber,
      gstfromDT: state.applicants.gstfromDT,
      prevEmpEndDt: state.applicants.prevEmpEndDt,
      isInitComplete: state.estimation.isInitComplete,
      maxloanamt: state.car.maxloanamt,
      isAuthenticated: state.account.isAuthenticated,
      depositAmount: state.car.depositAmount,
      purchasePrice: state.car.purchasePrice,
      forceNewApplication: state.account.forceNewApplication,
      estimationCompleted: state.tab.completedTabs && state.tab.completedTabs.estimation,
      disableSideBar: false,
      spouseCoApplicantId: state.applicants.spouseCoApplicantId,
      selectedLender: state.applicants.selectedLender,
      contactDetails: state.applicants.contactDetails,
      carSalesOppCreated: state.car.carSalesOppCreated,
      assetType: state.car.assetType?.replace(/\s+/g, '').toLowerCase() || 'car',
    };
  },
  (dispatch) => ({
    setAuthenticationlogOut: () =>
      dispatch(fieldChanged('account', 'isAuthenticated', false, false)),
    validationErrors: () => dispatch(validationErrors()),
    noErrors: () => dispatch(noErrors()),
  }),
)(withRouter(ContentAreaContainer));
