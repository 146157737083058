const nameFieldsRuleEvaluator = (rule, value) => {
  if (!value) {
    return rule.errorMessage;
  }

  const valueFormatted = value.trim().replace(/^[a-zA-Z ]*$/);

  const rex = new RegExp(/^[a-zA-Z ]*$/);

  const result = rex.test(valueFormatted);

  if (!result) {
    return rule.errorMessage;
  }

  return null;
};
export default nameFieldsRuleEvaluator;
