/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
  Button, Modal, Row, Col, Form, Container, ButtonToolbar,
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ReduxRenderField from '../../components/ReduxRenderField';
import fieldChanged from '../../Actions/FieldChanged';
import SaveAccountLogout from './SaveAccountLogout';
import './saveQuote.scss';

class SaveAccount extends Component {
  constructor() {
    super();

    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
  }

  close() {
    const { closeForm } = this.props;
    closeForm();
  }

  submit(data) {
    const { onSaveAccount } = this.props;
    onSaveAccount(data);
    /*   alert('submitted successfully'); */
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      showForm,
      saveAccount,
      onSaveAccountLogout,
    } = this.props;

    return (
      <div>
        {saveAccount && <SaveAccountLogout onSaveAccountLogout={onSaveAccountLogout} />}
        <Modal
          size="lg"
          show={showForm}
          onHide={this.close}
          className="modal-wrapper login-modal-wrapper"
        >
          <Modal.Body className="p-0">
            <Container>
              <Row className="show-grid">
                <Col xs={6} md={6} className="p-5">
                  <div className="save-quote-col pb-5">
                    <h4 className="save-quote-body-h1">Set up your account</h4>
                    <div className="save-quote-header-desc">
                      Setting up your password helps you to protect your details.
                    </div>
                  </div>
                  <Form noValidate onSubmit={handleSubmit(this.submit)}>
                    <Field
                      label="Password"
                      name="password"
                      type="password"
                      required
                      component={ReduxRenderField}
                    />
                    <Field
                      label="Re-enter Password"
                      name="confirmPassword"
                      type="password"
                      required
                      component={ReduxRenderField}
                    />

                    <ButtonToolbar>
                      <Button
                        type="submit"
                        disabled={pristine || submitting}
                        variant="primary"
                        block
                      >
                        Submit
                      </Button>
                      <Button
                        type="button"
                        disabled={submitting}
                        variant="link"
                        color="secondary"
                        onClick={this.close}
                        block
                      >
                        Cancel
                      </Button>
                    </ButtonToolbar>
                  </Form>
                </Col>
                <Col xs={6} className="account-review-section">
                  <Modal.Header className="save-account-header" closeButton />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const SaveAccountForm = reduxForm({
  form: 'saveAccount',
  validate: (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Required';
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Password does not match';
    }
    return errors;
  },
})(SaveAccount);

export default connect(
  (state) => ({
    showForm: state.account.showSaveAccount,
    saveAccount: state.account.saveAccount,
  }),
  (dispatch) => ({
    closeForm: () => {
      dispatch(fieldChanged('account', 'showSaveAccount', false, false));
    },
    onSaveAccount: (reduxStatedata) => {
      dispatch(fieldChanged('account', 'saveAccount', true, false));
      dispatch(fieldChanged('account', 'showSaveAccount', false, false));
      dispatch(fieldChanged('account', 'reduxFormAccvalues', reduxStatedata, false));
    },
    onSaveAccountLogout: () => {
      dispatch(fieldChanged('account', 'SaveAccountLogout', true, false));
      dispatch(fieldChanged('account', 'saveAccount', false, false));
    },
  }),
)(SaveAccountForm);
